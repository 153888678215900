import { Directive, OnInit, ElementRef } from '@angular/core';
import { Analytics } from '../../../common/interfaces';
import { LoginSignupService } from '../../../services/login-signup.service';

@Directive({ selector: 'a[href]' })
export class Anchor implements OnInit {
  constructor(private element: ElementRef<HTMLAnchorElement>, private loginSignupService: LoginSignupService) {
  }

  ngOnInit() {
    const a = this.element.nativeElement;

    if (/^(https?|mailto):/.test(a.href) && !a.hasAttribute('download')) {
      // open all external links in new tab
      if (!a.hasAttribute('target')) {
        a.setAttribute('target', '_blank');
      }

      // allow referrer for our sites
      if (!a.hasAttribute('rel') && !/^https:\/\/magmastudio\.io/.test(a.href)) {
        a.setAttribute('rel', 'noopener noreferrer');
      }

      a.addEventListener('click', () => {
        let url = a.href;

        if (a.getAttribute('data-remove-hash') && url && url.indexOf('#') > -1) {
          url = url.split('#')[0];
        }

        this.loginSignupService.trackEvent(Analytics.OpenExternalLink, { url });
      });
    }
  }
}
