import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { USER_COLORS } from '../../../common/constants';
import { getExtensionUrl } from '../../../common/extension';
import {
  faPaintBrush, faEdge, faInternetExplorer, faChrome, faOpera, faApple,
  faEye, faArrowLeft, faArrowRight, faArrowDown, faArrowUp, faFirefox
} from '../../../common/icons';
import { createLayer } from '../../../common/layer';
import { createUser } from '../../../common/user';
import { Model } from '../../../services/model';

@Component({
  selector: 'help-content',
  templateUrl: 'help-content.pug',
  styleUrls: ['help-content.scss'],
})
export class HelpContent implements OnDestroy {
  readonly brushIcon = faPaintBrush;
  readonly edgeIcon = faEdge;
  readonly ieIcon = faInternetExplorer;
  readonly chromeIcon = faChrome;
  readonly firefoxIcon = faFirefox;
  readonly operaIcon = faOpera;
  readonly appleIcon = faApple;
  readonly eyeIcon = faEye;
  readonly leftIcon = faArrowLeft;
  readonly rightIcon = faArrowRight;
  readonly downIcon = faArrowDown;
  readonly upIcon = faArrowUp;
  readonly extensionLink = getExtensionUrl();
  ownActiveLayer = createLayer(0, 'Layer #1');
  ownInactiveLayer = createLayer(0, 'Layer #2');
  freeLayer = createLayer(0, 'Layer #3');
  otherLeftLayer = createLayer(0, 'Layer #4');
  otherActiveLayer = createLayer(0, 'Layer #5');
  otherInactiveLayer = createLayer(0, 'Layer #6');
  mock: any[] = [];
  private subscription: Subscription;
  constructor(private model: Model) {
    this.subscription = model.userChanged.subscribe(() => this.setupLayers());
    this.setupLayers();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  private setupLayers() {
    this.ownActiveLayer.owner = this.model.user;
    this.ownInactiveLayer.owner = this.model.user;
    const other = createUser('', 0, 'Someone');
    // pick farthest possible color from user's color
    const colorIndex = ((USER_COLORS.indexOf(this.model.user.color) + USER_COLORS.length / 2) | 0) % USER_COLORS.length;
    other.color = USER_COLORS[colorIndex];
    this.otherActiveLayer.owner = other;
    this.otherInactiveLayer.owner = other;
    this.otherLeftLayer.layerOwner = { ...other, left: true };
  }
}
