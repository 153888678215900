import { toPromise } from 'shared/utils';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Invitee, TeamInvite, TeamInviteToken, UpdateTeamInviteToken } from 'shared/interfaces';
import { InvitationStore } from './invitation.store';
import { LocalForage } from './localforage.class';

export interface InvitationDataToken {
  email?: string;
  isInviteeSignedUp?: boolean;
  team?: string;
  project?: string;
  entity?: string;
  token?: string;
  message?: string;
}

@Injectable({ providedIn: 'root' })
export class InvitationService {
  constructor(
    private httpClient: HttpClient,
    private localForage: LocalForage,
    private invitationStore: InvitationStore,
  ) { }

  getInviteToken(teamId: string) {
    return this.httpClient.get<TeamInviteToken>(`/api/teams/${teamId}/invite`).toPromise();
  }

  updateInviteToken(teamId: string, update: UpdateTeamInviteToken) {
    return toPromise(this.httpClient.put<TeamInviteToken>(`/api/teams/${teamId}/invite`, update));
  }

  refreshInviteToken(teamId: string) {
    return toPromise(this.httpClient.post<TeamInviteToken>(`/api/teams/${teamId}/refreshInvite`, null));
  }

  getInviteTokenById(token: string) {
    return toPromise(this.httpClient.get<TeamInvite | undefined>(`/api/teams/invite/${token}`));
  }

  joinTeam(token: string) {
    return toPromise(this.httpClient.post(`/api/teams/join/${token}`, {}));
  }

  // remove code bellow ?
  openTwoTeamsModal(id: string) {
    return this.invitationStore.update({ id, showTwoTeamsModal: true, error: true });
  }

  async resetInvitationData() {
    this.invitationStore.update({ data: {} });
    const appParams = await this.localForage.getItem<any>('appParams');
    if (appParams && appParams.joinTeam) {
      const { joinTeam, ...rest } = appParams;
      if (joinTeam) {
        await this.localForage.setItem<any>('appParams', rest);
      }
    } else {
      await this.localForage.setItem<any>('appParams', {});
    }
  }

  closeTwoTeamsInvitationModal() {
    this.invitationStore.update({ id: '', showTwoTeamsModal: false, error: false });
  }

  inviteTeamsMembers(teamId: string, invitees: Invitee[]) {
    return this.httpClient.post(`/api/teams/${teamId}/invite`, { invitees });
  }

  getUserInformationByToken(invitationToken: string) {
    return this.httpClient.get<InvitationDataToken>(`/invitation/${invitationToken}`)
      .pipe(catchError(error => of(error.error)));
  }
}
