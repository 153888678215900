import { CommentTool } from './tools/commentTool';
import { ITool, IToolEditor, IToolModel, User, ToolId, IToolData } from './interfaces';
import { RefTool } from './tools/refTool';
import { MoveTool } from './tools/moveTool';
import { HandTool } from './tools/handTool';
import { ZoomTool } from './tools/zoomTool';
import { BrushTool } from './tools/brushTool';
import { LayerToolData, LayerTool, Action as LayerAction } from './tools/layerTool';
import { LayerUpdateTool } from './tools/layerUpdateTool';
import { PencilTool } from './tools/pencilTool';
import { EraserTool } from './tools/eraserTool';
import { EllipseTool } from './tools/ellipseTool';
import { SelectionTool } from './tools/selectionTool';
import { CircleSelectionTool } from './tools/circleSelectionTool';
import { LassoSelectionTool } from './tools/lassoSelectionTool';
import { LassoBrushTool } from './tools/lassoBrushTool';
//import { PolygonSelectionTool } from './tools/polygonSelectionTool';
import { IData, SelectionHelperAction, SelectionHelperTool } from './tools/selectionHelperTool';
import { RectangleTool } from './tools/rectangleTool';
import { EyedropperTool } from './tools/eyedropperTool';
import { RotateViewTool } from './tools/rotateViewTool';
import { TransformTool } from './tools/transformTool';
import { PaintbucketTool } from './tools/paintbucketTool';
import { DeleteSelectionTool } from './tools/deleteSelectionTool';
import { invalidEnum } from './baseUtils';
import { PasteTool } from './tools/pasteTool';
import { TextTool, TextToolData } from './tools/textTool';
import { ShapeTool } from './tools/shapeTool';
import { toolIdToString } from './toolIdUtils';
import { AiTool } from './tools/aiTool';
import { GaussianBlurTool } from './tools/gaussianBlurTool';
import { HueSaturationLightnessTool } from './tools/hueSaturationLightnessTool';
import { BrightnessContrastTool } from './tools/brightnessContrastTool';
import { CurvesTool } from './tools/curvesTool';
import { AiPasteToolData } from './aiInterfaces';
import { BehaviorSubject } from 'rxjs';

export function createTool(id: ToolId, editor: IToolEditor, model: IToolModel): ITool {
  switch (id) {
    case ToolId.Brush: return new BrushTool(editor, model);
    case ToolId.Pencil: return new PencilTool(editor, model);
    case ToolId.Eraser: return new EraserTool(editor, model);
    case ToolId.Move: return new MoveTool(editor, model);
    case ToolId.Rect: return new RectangleTool(editor, model);
    case ToolId.Ellipse: return new EllipseTool(editor, model);
    case ToolId.Eyedropper: return new EyedropperTool(editor, model);
    case ToolId.Layer: return new LayerTool(editor, model);
    case ToolId.LayerUpdate: return new LayerUpdateTool(editor, model);
    case ToolId.Selection: return new SelectionTool(editor, model);
    case ToolId.SelectionHelper: return new SelectionHelperTool(editor, model);
    case ToolId.CircleSelection: return new CircleSelectionTool(editor, model);
    case ToolId.LassoSelection: return new LassoSelectionTool(editor, model);
    case ToolId.LassoBrush: return new LassoBrushTool(editor, model);
    case ToolId.DeleteSelection: return new DeleteSelectionTool(editor, model);
    case ToolId.Hand: return new HandTool(editor, model);
    case ToolId.RotateView: return new RotateViewTool(editor, model);
    case ToolId.Zoom: return new ZoomTool(editor, model);
    case ToolId.Ref: return new RefTool(editor, model);
    case ToolId.Transform: return new TransformTool(editor, model);
    case ToolId.Paintbucket: return new PaintbucketTool(editor, model);
    case ToolId.Paste: return new PasteTool(editor, model);
    case ToolId.Text: return new TextTool(editor, model);
    case ToolId.Shape: return new ShapeTool(editor, model);
    case ToolId.None: throw new Error('Trying to create None tool');
    case ToolId.COUNT: throw new Error('Trying to create COUNT tool');
    case ToolId.Comment: return new CommentTool(editor, model);
    case ToolId.AI: return new AiTool(editor, model);
    case ToolId.GaussianBlur: return new GaussianBlurTool(editor, model);
    case ToolId.HueSaturationLightness: return new HueSaturationLightnessTool(editor, model);
    case ToolId.BrightnessContrast: return new BrightnessContrastTool(editor, model);
    case ToolId.Curves: return new CurvesTool(editor, model);
    default: return invalidEnum(id, 'No tool with id');
  }
}

export function createAllTools(editor: IToolEditor, model: IToolModel): ITool[] {
  return [
    new MoveTool(editor, model),
    new SelectionTool(editor, model),
    new CircleSelectionTool(editor, model),
    new LassoSelectionTool(editor, model),
    //new PolygonSelectionTool(editor, model),
    new TransformTool(editor, model),
    new EyedropperTool(editor, model),
    new PencilTool(editor, model),
    new BrushTool(editor, model),
    new EraserTool(editor, model),
    new PaintbucketTool(editor, model),
    new LassoBrushTool(editor, model),
    new TextTool(editor, model),
    new RectangleTool(editor, model),
    new EllipseTool(editor, model),
    ...((SERVER || IS_PORTAL || DEVELOPMENT) ? [new ShapeTool(editor, model)] : []),
    ...((SERVER || IS_PORTAL || DEVELOPMENT) ? [new AiTool(editor, model)] : []),
    new HandTool(editor, model),
    new RotateViewTool(editor, model),
    new ZoomTool(editor, model),
    ...((SERVER || IS_PORTAL || DEVELOPMENT) ? [new CommentTool(editor, model)] : []),
    ...(DEVELOPMENT ? [new RefTool(editor, model)] : []),
  ];
}

export function getToolFullName(tool: IToolData | undefined) {
  if (!tool) return '<undefined>';
  let result = toolIdToString(tool.id);
  if (tool.id === ToolId.Layer) result += `:${LayerAction[(tool as LayerToolData).action]?.toLowerCase()}`;
  if (tool.id === ToolId.Text) result += `:${(tool as TextToolData).mode}`;
  if (tool.id === ToolId.SelectionHelper) result += `:${SelectionHelperAction[(tool as IData).action]?.toLowerCase()}`;
  if (tool.id === ToolId.AI) result += `:${(tool as AiPasteToolData).pipeline}`;
  return result;
}

export function createFakeModel(user: User): IToolModel {
  return {
    type: 'remote',
    user,
    drawingSamples: 0,
    threadService: undefined,
    connectedStateChanged: new BehaviorSubject(true),
    doTool() { },
    async doToolWithData() { },
    startToolView() { },
    nextTool() { },
    endTool() { },
    cancelTool() { },
    errorWithData() { },

    setTaskName() { },

    beginTool() { },
    updateTool() { },
    finishTool() { }
  };
}
