import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'prompt-modal',
  templateUrl: 'prompt-modal.pug',
})
export class PromptModal implements OnInit {
  @Output() close = new EventEmitter<string>();
  @Input() data = { message: '', value: undefined as string | undefined };
  value = '';
  ngOnInit() {
    this.value = this.data.value ?? '';
  }
  ok() {
    this.close.emit(this.value);
  }
  cancel() {
    this.data.value = undefined;
    this.close.emit(undefined);
  }
}
