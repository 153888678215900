import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { EntitiesState, EntitiesStore, SharedEntitiesStore } from './entities.store';
import { FlowchartListData } from 'shared/interfaces';

@Injectable({ providedIn: 'root' })
export class EntitiesQuery extends QueryEntity<EntitiesState, FlowchartListData> {
  constructor(protected store: EntitiesStore) {
    super(store);
  }
}

// tslint:disable-next-line:max-classes-per-file
@Injectable({ providedIn: 'root' })
export class ShardEntitiesQuery extends QueryEntity<EntitiesState, FlowchartListData> {
  constructor(protected store: SharedEntitiesStore) {
    super(store);
  }
}
