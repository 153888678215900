import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

const doubleTapDelay = 500;

@Directive({ selector: '[agDoubleTap]' })
export class AgDoubleTap {
  @Output() agDoubleTap = new EventEmitter();
  private lastTap = 0;
  @HostListener('touchend')
  tap() {
    const now = performance.now();

    if ((now - this.lastTap) < doubleTapDelay) {
      this.lastTap = 0;
      this.agDoubleTap.emit();
    } else {
      this.lastTap = now;
    }
  }
}
