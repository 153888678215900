import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { drawingsPath } from 'magma/common/data';
import { ToastService } from 'magma/services/toast.service';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private toastService: ToastService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const next$ = next.handle(req);
    if (!req.url.match(/^\/api/)) {
      return next$;
    }
    return next$.pipe(map(event => {
      if (event instanceof HttpResponse && event.body && ('processingTime' in event.body)) {
        if (!DEVELOPMENT && event.body.hash && event.body.hash !== GIT_HASH) {
          DEVELOPMENT && console.warn('Reloading due to hash change', event.body.hash, GIT_HASH);

          setTimeout(() => {
            if (!location.pathname.startsWith(drawingsPath)) {
              location.reload();
            }
          }, 5000);
        }

        event = event.clone({ body: event.body.data });

      }
      return event;
    }), catchError((errorResponse: HttpErrorResponse) => {
      if (errorResponse.status === 500) {
        this.toastService.error({
          message: 'Looks like your last request did not complete successfully.',
          subtitle: 'If you think this was not right or if the problem persists, please contact support',
        });
      }

      if (errorResponse.error?.name === 'TEAM_EXPIRED') {
        void this.router.navigate(['error']);
      }

      return throwError(errorResponse);
    }));
  }
}
