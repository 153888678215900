import { invalidEnumReturn } from './baseUtils';
import { adminIcon, emptyIcon, faCheck, ownerIcon } from './icons';
import { defaultDrawingPermissions, Drawing, DrawingPermissions, User, UserRole } from './interfaces';

export function hasPro(account: { pro?: boolean | number; }) {
  return IS_HOSTED || !!account.pro;
}

export function getRoleIcon(user: { role?: UserRole; }) {
  switch (user.role) {
    case 'owner': return ownerIcon;
    case 'admin': return adminIcon;
    case 'approved': return faCheck;
    case 'all':
    case undefined: return emptyIcon;
    default: return invalidEnumReturn(user.role, emptyIcon);
  }
}

export function getRoleTitle(user: { role?: UserRole; }): string | undefined {
  switch (user.role) {
    case 'owner': return 'Drawing Owner';
    case 'admin': return 'Drawing Admin';
    case 'approved': return 'Approved';
    case 'all':
    case undefined: return undefined;
    default: return invalidEnumReturn(user.role, undefined);
  }
}

const roleOrder = {
  owner: 0,
  admin: 1,
  approved: 2,
  all: 3,
};

export function compareRoles(a: UserRole | undefined, b: UserRole | undefined) {
  return roleOrder[a ?? 'all'] - roleOrder[b ?? 'all'];
}

export function isUserRoleValid(role: UserRole, minimumRole: UserRole) {
  switch (minimumRole) {
    case 'owner': return role === 'owner';
    case 'admin': return role === 'admin' || role === 'owner';
    case 'approved': return role === 'approved' || role === 'admin' || role === 'owner';
    default: return true;
  }
}

export function hasDrawingRole(user: User, minimumRole: UserRole) {
  return isUserRoleValid(user.role, minimumRole);
}

export function validPermission(requiredRole: UserRole, userRole: UserRole | undefined): boolean {
  return userRole === 'owner' || userRole === 'admin' || requiredRole === 'all' || userRole === requiredRole;
}

export function validPermissionKey(permissions: DrawingPermissions | undefined, key: keyof DrawingPermissions, role: UserRole) {
  return validPermission(permissions?.[key] ?? defaultDrawingPermissions[key], role);
}

export function hasPermission(drawing: Drawing, user: { role: UserRole; isSuperAdmin: boolean; }, key: keyof DrawingPermissions) {
  return user.isSuperAdmin || validPermissionKey(drawing.permissions, key, user.role);
}
