import { createCanvas, getContext2d } from '../common/canvasUtils';
import { colorToFloatArray } from '../common/color';
import { TRANSPARENT } from '../common/constants';
import { Shader, Texture, TextureFormat, WebGLResources } from '../common/interfaces';
import { invalidEnum } from '../common/baseUtils';
import { isChromeOS, isiPad } from '../common/userAgentUtils';
import { flushBatch, pushQuad } from './webglBatch';

export interface Mesh {
  vertexBuffer: WebGLBuffer;
  indexBuffer: WebGLBuffer;
  elements: number;
}

type Context = WebGLRenderingContext;

export const FLOATS_PER_VERTEX = 10; // x, y, tx, ty, custom1, custom2, r, g, b, a
export const STRIDE = FLOATS_PER_VERTEX * 4;

export const MESH_FLOATS_PER_VERTEX = 8; // x, y, z, nx, ny, nz, tx, ty
export const MESH_STRIDE = MESH_FLOATS_PER_VERTEX * 4;

const GL_ERRORS: (keyof Context)[] = [
  'NO_ERROR',
  'INVALID_ENUM',
  'INVALID_VALUE',
  'INVALID_OPERATION',
  'INVALID_FRAMEBUFFER_OPERATION',
  'OUT_OF_MEMORY',
  'CONTEXT_LOST_WEBGL',
];

function getError(gl: Context) {
  const error = gl.getError();

  for (const e of GL_ERRORS) {
    if (error === gl[e]) return e;
  }

  return `${error}`;
}

export function findPowerOf2(originalSize: number) {
  return Math.max(1, Math.pow(2, Math.ceil(Math.log2(originalSize))));
}

export function findMultipleOf256(originalSize: number) {
  return (((originalSize + 256 - 1) / 256) | 0) * 256;
}

export interface ShaderSource {
  vertex: string;
  fragment: string;
}

export function initUniforms(gl: Context, program: WebGLProgram, source: ShaderSource) {
  gl.useProgram(program);

  const uniforms: { [key: string]: WebGLUniformLocation; } = {};
  const samplerNames: string[] = [];
  const combinedSource = `${source.vertex}\n${source.fragment}`;
  const matches = combinedSource.match(/uniform [a-z0-9_]+ [a-z_][a-z0-9_]*/ig) || [];

  for (const match of matches) {
    const [, type, name] = match.split(' ');
    const location = gl.getUniformLocation(program, name);

    if (location) {
      uniforms[name] = location;

      if (type === 'sampler2D') {
        samplerNames.push(name);
      }
    }
  }

  // TODO: better
  samplerNames.forEach(name => gl.uniform1i(uniforms[name], parseInt(name.substr('sampler'.length), 10) - 1));
  return uniforms;
}

export function createShaderProgram(gl: Context, vertexShader: WebGLShader, fragmentShader: WebGLShader, vertexSource: string): Shader {
  const program = gl.createProgram();
  if (!program) throw new Error(`Failed to create program (${getError(gl)})`);

  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);

  const matches = vertexSource.match(/attribute [a-z0-9_]+ [a-z_][a-z0-9_]*/ig) || [];

  for (let i = 0; i < matches.length; i++) {
    const parts = matches[i].split(' ');
    gl.bindAttribLocation(program, i, parts[2]);
  }

  gl.linkProgram(program);

  if (DEVELOPMENT && !gl.getProgramParameter(program, gl.LINK_STATUS)) {
    throw new Error(`Could not create program: ${gl.getProgramInfoLog(program)}`);
  }

  return { program, uniforms: {} };
}

export function createShader(gl: Context, source: ShaderSource): Shader {
  const vertexShader = createWebGLShader(gl, gl.VERTEX_SHADER, source.vertex, true);
  const fragmentShader = createWebGLShader(gl, gl.FRAGMENT_SHADER, source.fragment, isiPad);
  const shader = createShaderProgram(gl, vertexShader, fragmentShader, source.vertex);
  gl.deleteShader(vertexShader);
  gl.deleteShader(fragmentShader);
  return shader;
}

export function createWebGLShader(gl: Context, type: number, source: string, highp: boolean) {
  const supports = highp && !!gl.getShaderPrecisionFormat(type, gl.HIGH_FLOAT)?.precision;
  const precision = supports ? 'highp' : 'mediump';
  source = `precision ${precision} float;\n${source}`;

  const shader = gl.createShader(type);
  if (!shader) throw new Error(`Failed to create shader (${getError(gl)})`);

  gl.shaderSource(shader, source);
  gl.compileShader(shader);

  if (DEVELOPMENT) {
    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
      console.log(source.split(/\n/g).map((l, i) => `${i + 1}: ${l}`).join('\n'));
      if (SERVER) console.error(gl.getShaderInfoLog(shader) || 'Shader error');
      throw new Error(gl.getShaderInfoLog(shader) || 'Shader error');
    }
  }

  return shader;
}

// when disabled - screen blinking on Chrome OS
// when enabled - performance issues on iOS14 and some other mobile devices
export const preserveDrawingBuffer = isChromeOS;

// import { makeDebugContext, glEnumToString } from 'webgl-debug';

let webglId = 1;

export function getWebGLContext(canvas: HTMLCanvasElement): { gl: Context; webgl2: boolean; } {
  const alpha = !isChromeOS; // some old devices have issues with alpha: false, override for old contexts
  const options = {
    alpha: false, // TODO: maybe force always true instead ? why do we even set it to false ?
    antialias: false,
    // disabled for Chrome OS to check if this helps with blinking screen
    desynchronized: !isChromeOS, // this causes flicker when resizing but also provides considerable perf improvement
    premultipliedAlpha: true,
    preserveDrawingBuffer,
    powerPreference: 'high-performance',
    // failIfMajorPerformanceCaveat: true,
  };

  let webgl2 = true;
  let gl: any = canvas.getContext('webgl2', options);

  if (!gl) {
    gl = canvas.getContext('webgl', { ...options, alpha }) ||
      canvas.getContext('experimental-webgl', { ...options, alpha });
    webgl2 = false;
  }

  // if (DEVELOPMENT) {
  //   gl = makeDebugContext(gl, (err: any, funcName: any, _args: any) => {
  //     throw new Error(`${glEnumToString(err)} in ${funcName}`);
  //   });
  // }

  if (!gl) throw new Error('WebGL not supported');

  (gl as any).webglId = webglId++;

  if (!webgl2) {
    const blendMinMax = gl.getExtension('EXT_blend_minmax');

    if (blendMinMax) {
      gl.MIN = blendMinMax.MIN_EXT;
      gl.MAX = blendMinMax.MAX_EXT;
    }
  }

  return { gl, webgl2 };
}

export let allocatedTextures = 0;
export let allocatedBuffers = 0;

export function resetResourceCounters() {
  allocatedTextures = 0;
  allocatedBuffers = 0;
}

export function allocBuffer(gl: Context): WebGLBuffer {
  const buffer = gl.createBuffer();
  if (buffer == null) throw new Error(`Failed to create buffer (${getError(gl)})`);
  allocatedBuffers++;
  return buffer;
}

export function deleteBuffer(gl: Context, buffer: WebGLBuffer | null) {
  if (buffer) {
    gl.deleteBuffer(buffer);
    allocatedBuffers--;
  }
}

function allocTextureHandle(gl: Context): WebGLTexture {
  const handle = gl.createTexture();
  if (handle == null) throw new Error(`Failed to create texture (${getError(gl)})`);
  allocatedTextures++;
  return handle;
}

export function deleteTexture(gl: Context, texture: Texture | undefined) {
  if (texture) {
    gl.deleteTexture(texture.handle);
    allocatedTextures--;
  }
}

export function toGLFormat(gl: Context, format: TextureFormat) {
  switch (format) {
    case TextureFormat.RGBA: return gl.RGBA;
    case TextureFormat.Alpha: return gl.ALPHA;
    default: invalidEnum(format);
  }
}

// assumes `data` to be premultiplied
export function createEmptyTexture(
  gl: Context, width: number, height: number, data: Uint8Array | null = null, format = TextureFormat.RGBA
): Texture {
  if (width <= 0 || height <= 0 || width > 16384 || height > 16384) throw new Error(`Invalid texture size ${width}x${height}`);

  const glFormat = toGLFormat(gl, format);
  const handle = allocTextureHandle(gl);
  gl.bindTexture(gl.TEXTURE_2D, handle);
  gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, 0);
  gl.texImage2D(gl.TEXTURE_2D, 0, glFormat, width, height, 0, glFormat, gl.UNSIGNED_BYTE, data);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.bindTexture(gl.TEXTURE_2D, null);
  return { id: '', width, height, format, handle, webglId: (gl as any).webglId };
}

export function bindFrameBufferAndTexture({ gl, frameBuffer, width, height }: WebGLResources, texture: Texture) {
  if (DEVELOPMENT && (!texture || !frameBuffer)) throw new Error('Invalid arguments');

  gl.bindFramebuffer(gl.FRAMEBUFFER, frameBuffer);
  gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture.handle, 0);
  gl.viewport(0, 0, width, height); // here for safety, to avoid missing it on server where each drawing is different size
}

export function bindAndClearBuffer(webgl: WebGLResources, texture: Texture, color: number[] | Float32Array) {
  const { gl } = webgl;
  bindFrameBufferAndTexture(webgl, texture);
  gl.clearColor(color[0], color[1], color[2], color[3]);
  gl.clear(gl.COLOR_BUFFER_BIT);
}

export function unbindFrameBufferAndTexture({ gl }: WebGLResources) {
  gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, null, 0);
  gl.bindFramebuffer(gl.FRAMEBUFFER, null);
}

export function clearTextureRect(webgl: WebGLResources, texture: Texture, x: number, y: number, w: number, h: number) {
  const { gl } = webgl;
  bindFrameBufferAndTexture(webgl, texture);
  gl.enable(gl.SCISSOR_TEST);
  gl.scissor(x, y, w, h);
  gl.clearColor(0, 0, 0, 0);
  gl.clear(gl.COLOR_BUFFER_BIT);
  gl.disable(gl.SCISSOR_TEST);
  unbindFrameBufferAndTexture(webgl);
}

export function unbindTexture(gl: WebGLRenderingContext, unit: number) {
  gl.activeTexture(gl.TEXTURE0 + unit);
  gl.bindTexture(gl.TEXTURE_2D, null);
}

export function bindTexture(gl: WebGLRenderingContext, unit: number, texture: Texture) {
  gl.activeTexture(gl.TEXTURE0 + unit);
  gl.bindTexture(gl.TEXTURE_2D, texture.handle);
}

const transparentColor = colorToFloatArray(TRANSPARENT);

export function clearTexture(webgl: WebGLResources, texture: Texture, color = transparentColor) {
  bindAndClearBuffer(webgl, texture, color);
  unbindFrameBufferAndTexture(webgl);
}

export function resizeTexture(gl: WebGLRenderingContext, texture: Texture, width: number, height: number, format = TextureFormat.RGBA) {
  if (texture.width !== width || texture.height !== height || texture.format !== format) {
    texture.width = width;
    texture.height = height;
    texture.format = format;
    const glFormat = toGLFormat(gl, format);
    gl.bindTexture(gl.TEXTURE_2D, texture.handle);
    gl.texImage2D(gl.TEXTURE_2D, 0, glFormat, width, height, 0, glFormat, gl.UNSIGNED_BYTE, null);
    gl.bindTexture(gl.TEXTURE_2D, null);
  }
}

export function setupAttribPointers(gl: WebGLRenderingContext) {
  gl.enableVertexAttribArray(0); // these are here to work around headless-gl issue
  gl.enableVertexAttribArray(1);
  gl.enableVertexAttribArray(2);
  gl.vertexAttribPointer(0, 2, gl.FLOAT, false, STRIDE, 0); // position
  gl.vertexAttribPointer(1, 4, gl.FLOAT, false, STRIDE, 2 * 4); // texcoord
  gl.vertexAttribPointer(2, 4, gl.FLOAT, false, STRIDE, 6 * 4); // color
}

export function setupMeshAttribPointers(gl: WebGLRenderingContext) {
  gl.enableVertexAttribArray(0); // these are here to work around headless-gl issue
  gl.enableVertexAttribArray(1);
  gl.enableVertexAttribArray(2);
  gl.vertexAttribPointer(0, 3, gl.FLOAT, false, MESH_STRIDE, 0); // position
  gl.vertexAttribPointer(1, 3, gl.FLOAT, true, MESH_STRIDE, 3 * 4); // normal
  gl.vertexAttribPointer(2, 2, gl.FLOAT, true, MESH_STRIDE, 6 * 4); // texcoord
}

export function drawMesh(gl: WebGLRenderingContext, mesh: Mesh) {
  gl.bindBuffer(gl.ARRAY_BUFFER, mesh.vertexBuffer);
  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, mesh.indexBuffer);
  setupMeshAttribPointers(gl);
  gl.drawElements(gl.TRIANGLES, mesh.elements, gl.UNSIGNED_SHORT, 0);
  gl.bindBuffer(gl.ARRAY_BUFFER, null);
  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
}

export function drawQuad({ batch, width, height, textureWidth, textureHeight }: WebGLResources) {
  pushQuad(batch, 0, 0, width, height, 0, 0, width / textureWidth, height / textureHeight, 0, 0, 1, 1, 1, 1);
  flushBatch(batch);
}

export function copyTextureRect(
  webgl: WebGLResources, src: Texture, dst: Texture, sx: number, sy: number, w: number, h: number, dx: number, dy: number
) {
  if (DEVELOPMENT && (
    sx < 0 || (sx + w) > src.width || sy < 0 || (sy + h) > src.height ||
    dx < 0 || (dx + w) > dst.width || dy < 0 || (dy + h) > dst.height)
  ) {
    throw new Error(`Invalid copy rect (${sx}, ${sy}, ${w}, ${h}) [${src.width}x${src.height}] -> ` +
      `(${dx}, ${dy}, ${w}, ${h}) [${dst.width}x${dst.height}]`);
  }

  const { gl } = webgl;
  bindFrameBufferAndTexture(webgl, src);
  gl.bindTexture(gl.TEXTURE_2D, dst.handle);
  gl.copyTexSubImage2D(gl.TEXTURE_2D, 0, dx, dy, sx, sy, w, h);
  gl.bindTexture(gl.TEXTURE_2D, null);
  unbindFrameBufferAndTexture(webgl);
}

export function textureToCanvas(webgl: WebGLResources, texture: Texture) {
  const { gl } = webgl;
  const canvas = createCanvas(texture.width, texture.height);
  const context = getContext2d(canvas);
  const data = context.createImageData(canvas.width, canvas.height);
  const buffer = new Uint8Array(data.data.buffer);
  bindFrameBufferAndTexture(webgl, texture);
  gl.readPixels(0, 0, texture.width, texture.height, gl.RGBA, gl.UNSIGNED_BYTE, buffer);
  unbindFrameBufferAndTexture(webgl);
  context.putImageData(data, 0, 0);
  return canvas;
}

export function debugTexture(webgl: WebGLResources, texture: Texture) {
  if (DEVELOPMENT && typeof document !== 'undefined') {
    const canvas = textureToCanvas(webgl, texture);
    canvas.style.position = 'fixed';
    canvas.style.left = '0';
    canvas.style.top = '0';
    canvas.style.zIndex = '10000';
    canvas.style.width = '50%';
    canvas.style.border = 'solid 1px red';
    document.body.appendChild(canvas);
  }
}
