import { ITool, IToolEditor, IToolModel, TabletEvent, ToolId, hasShiftKey } from '../interfaces';
import { faUndoAlt } from '../icons';
import { getViewportAngle, rotateViewportXY } from '../viewport';
import { logAction } from '../actionLog';

export class RotateViewTool implements ITool {
  id = ToolId.RotateView;
  name = 'Rotate View Tool';
  description = 'Rotate your entire canvas either clockwise or anticlockwise';
  learnMore = 'https://help.magma.com/en/articles/6871511-rotate';
  video = { url: '/assets/videos/rotate-view.mp4', width: 374, height: 210 };
  icon = faUndoAlt;
  cursor = 'cursor-rotate';
  navigation = true;
  // usesModifiers = true;
  private startAngle = 0;
  constructor(public editor: IToolEditor, public model: IToolModel) {
  }
  start(_x: number, _y: number, _pressure: number, e: TabletEvent) {
    logAction('[local] start: rotate');

    this.startAngle = this.editor.view.rotation - getViewportAngle(this.editor.view, e.x, e.y);
  }
  move(_x: number, _y: number, _pressure: number, e: TabletEvent) {
    rotateViewportXY(this.editor.view, e.x, e.y, this.startAngle, hasShiftKey(e));
  }
  end(x: number, y: number, pressure: number, e: TabletEvent) {
    this.move(x, y, pressure, e);
  }
}
