import {
  Component, Directive, TemplateRef, Input, ContentChild, ContentChildren, QueryList, Output, EventEmitter
} from '@angular/core';
import { Key } from '../../../common/input';

@Directive({ selector: '[tabTitle]' })
export class TabTitle {
  constructor(public templateRef: TemplateRef<any>) {
  }
}

@Directive({ selector: '[tabContent]' })
export class TabContent {
  constructor(public templateRef: TemplateRef<any>) {
  }
}

let id = 1;

@Directive({ selector: 'tab' })
export class Tab {
  @Input() id = `tabset-tab-${++id}`;
  @Input() title?: string;
  @Input() className?: string;
  @Input() disabled = false;
  @ContentChild(TabContent, { static: true }) contentTpl?: TabContent;
  @ContentChild(TabTitle, { static: true }) titleTpl?: TabTitle;
}

@Component({
  selector: 'tabset',
  templateUrl: 'tabset.pug',
})
export class Tabset {
  justifyClass?: string;
  @ContentChildren(Tab) tabs!: QueryList<Tab>;
  @Input() label = '';
  @Input() destroyOnHide = true;
  @Input()
  set justify(className: 'start' | 'center' | 'end' | 'fill' | 'justified') {
    if (className === 'fill' || className === 'justified') {
      this.justifyClass = `nav-${className}`;
    } else {
      this.justifyClass = `justify-content-${className}`;
    }
  }
  @Input() orientation: 'horizontal' | 'vertical' = 'horizontal';
  @Input() type: 'tabs' | 'pills' = 'tabs';
  @Input() activeIndex = 0;
  @Output() activeIndexChange = new EventEmitter<number>();
  constructor() {
    this.justify = 'start';
  }
  get navClass() {
    return `nav-${this.type}${this.orientation === 'horizontal' ? ` ${this.justifyClass}` : ' flex-column'}`;
  }
  select(index: number) {
    if (this.activeIndex !== index) {
      this.activeIndex = index;
      this.activeIndexChange.emit(index);
    }
  }
  keydown(e: KeyboardEvent) {
    const index = this.handleKey(e.keyCode);

    if (index !== undefined) {
      e.preventDefault();
      document.getElementById(this.tabs.toArray()[index].id)?.focus();
      this.select(index);
    }
  }
  private handleKey(keyCode: number) {
    if (keyCode === Key.Left) {
      return this.activeIndex === 0 ? this.tabs.length - 1 : this.activeIndex - 1;
    } else if (keyCode === Key.Right) {
      return this.activeIndex === this.tabs.length - 1 ? 0 : this.activeIndex + 1;
    } else if (keyCode === Key.Home) {
      return 0;
    } else if (keyCode === Key.End) {
      return this.tabs.length - 1;
    } else {
      return undefined;
    }
  }
}
