/**
 * Used for creating class-like injeciton tokens for DI, without the need to
 * include the whole original class in the output javascript.
 *
 * import type { RealFooService } from 'services/real-foo.service';;
 * export abstract class FooService extends injectionTokenBase<RealFooService>
 *
 * now if you { provide: FooService, useClass RealFooService } you can then use
 * FooService throughout your application without importing the whole implementation
 * in every file its used.
 *
 * @returns empty abstract base blass pretending to implement interface T
 */
export function injectionTokenBase<T>() {
  abstract class InjectionToken {
    constructor() {
      throw new Error(`You can't instantiate a class that is an InjectionToken. Use the class T.`);
    }
  }
  return InjectionToken as any as (new() => T);
}
