import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LicenseJson } from '@codecharm/license-tools';
import { allowAnonymousUsers, htmlJson, signUps } from '../../../../common/data';
import { faArrowRight, faDiscord, faEnvelope, faGoogle, faSpinner, faTwitter, faGithub, farExclamationCircle, faUser, faInfoCircle, faLinkedin } from '../../../../common/icons';
import { Analytics, CreateAccountData, ResetPasswordData } from '../../../../common/interfaces';
import { isIE } from '../../../../common/userAgentUtils';
import { includes } from '../../../../common/baseUtils';
import { sendGAEvent } from '../../../../common/utilsAnalytics';
import { CouponService } from '../../../../services/couponService';
import { LoginSignupService } from '../../../../services/login-signup.service';
import { handleAutoClose } from '../../../../services/popups';
import { storageGetItem, storageSetItem } from '../../../../services/storage';
import { randomAvatar } from '../../../../common/utils';
import { isAnonymousName } from '../../../../common/userUtils';

const SOCIALS = isIE ? [] : [
  { id: 'linkedin', name: 'LinkedIn', icon: faLinkedin },
  { id: 'google', name: 'Google', icon: faGoogle },
  { id: 'discord', name: 'Discord', icon: faDiscord },
  { id: 'twitter', name: 'Twitter', icon: faTwitter },
  { id: 'email', name: 'email', icon: faEnvelope },
  { id: 'github', name: 'Github', icon: faGithub },
  { id: 'saml', name: 'SAML SSO', icon: faUser },
].filter(x => includes(signUps, x.id));

@Component({
  selector: 'sign-up-box',
  templateUrl: 'sign-up-box.pug',
  styleUrls: ['sign-up-box.scss'],
})
export class SignUpBox implements OnInit {
  readonly arrowIcon = faArrowRight;
  readonly mailIcon = faEnvelope;
  readonly spinnerIcon = faSpinner;
  readonly errorIcon = farExclamationCircle;
  readonly infoIcon = faInfoCircle;
  // TODO: get these from some config ?
  readonly termsUrl = 'https://magma.com/terms';
  readonly privacyUrl = 'https://magma.com/privacy-policy';
  readonly hosted = IS_HOSTED;
  readonly terms = IS_PORTAL;
  readonly socialsSignUp = SOCIALS.filter(item => item.id !== 'twitter');
  readonly socialsWithoutEmail = SOCIALS.filter(item => item.id !== 'email');
  readonly onlyEmail = SOCIALS.length === 1 && includes(signUps, 'email') && !allowAnonymousUsers;
  readonly signUpWithEmail = includes(signUps, 'email');
  readonly samlActive = includes(signUps, 'saml');
  readonly license = htmlJson<LicenseJson>('license');
  @Output() close = new EventEmitter<void>();
  @Output() modeChange = new EventEmitter<string>();
  @Input() allowAnon = true;
  @Input() noTitle = false;
  @Input() title = 'Create account';
  @Input() GALabel = '';
  @Input() forced = false;
  @Input() inModal = true;
  @Input() isPublicDemoDrawing = false;
  @Input() extras: any = {};
  email = '';
  password = '';
  data: CreateAccountData = {
    name: '',
    email: '',
    avatar: '',
    password: '',
    color: '',
    userJob: undefined,
    receiveEmails: undefined,
    repeatPassword: undefined,
  };
  resetPasswordData: ResetPasswordData = {
    password: '',
    repeatPassword: '',
  };
  private _mode = '';
  @Input() get mode() {
    return this._mode;
  }
  set mode(value) {
    if (this._mode !== value) {
      this._mode = value;
      this.modeChange.emit(value);
    }
  }
  auth = '';
  startAnon = false;
  anonName = '';
  error: string | undefined = undefined;
  passwordRecovered = false;
  working = false;
  constructor(
    public service: LoginSignupService,
    private zone: NgZone,
    private couponService: CouponService,
    private router: Router
  ) {
    if (!isAnonymousName(service.user.name)) {
      this.anonName = service.user.name;
    }

    this.data.color = service.user.color;
    this.mode = '';
  }
  get loginValid() {
    return this.email && this.password;
  }
  get createWithEmailValid() {
    return !!(this.data.email && this.data.password && this.data.name);
  }
  ngOnChanges() {
    if (this.extras.invite?.team.requireSamlAuthentication) {
      this.mode = 'organizationInvite';
    }
  }
  ngOnInit() {
    if (!storageGetItem('sign-up-time2')) {
      storageSetItem('sign-up-time2', Date.now().toString());
    }

    if (this.isPublicDemoDrawing) this.mode = 'public-demo-drawing-mode';
    if (this.couponService.getCoupon()) this.allowAnon = false;
    if (this.forced) this.data.forced = true;

    const label = this.GALabel || (this.allowAnon ? 'popup-modal' : 'popup-modal-returning');
    const fromOrigin = this.GALabel || (this.allowAnon ? 'welcome-modal' : 'welcome-modal-forced');

    if (this.mode === '') {
      sendGAEvent('Sign up', 'Viewed signup methods', label);
      this.service.trackEvent(Analytics.ViewCreateAccountScreen, { fromOrigin, methods: SOCIALS.map(s => s.id) });
      this.service.trackEvent(Analytics.StartAuthFlow, { trigger: fromOrigin });
    } else if (this.mode === 'login') {
      sendGAEvent('Sign in', 'Viewed signin form', null);
      this.service.trackEvent(Analytics.ViewSigninFormScreen, { formOrigin: this.inModal ? 'user-menu' : 'login-page' });
      this.service.trackEvent(Analytics.StartAuthFlow, { trigger: fromOrigin });
    } else if (this.mode === 'resetPassword') {
      sendGAEvent('Reset password', 'Viewed change password form', null);
      this.service.trackEvent(Analytics.ViewChangePasswordFormScreen, { formOrigin: this.inModal ? 'user-menu' : 'login-page' });
    } else if (this.mode === 'notFeaturePro') {
      const { name, avatar = '', color } = this.service.user;
      this.data.name = isAnonymousName(name) ? '' : name;
      this.data.avatar = avatar;
      this.data.color = color;
      this.data.email = '';
      this.data.password = '';
      this.data.userJob = undefined;
      this.data.receiveEmails = undefined;
    } else if (this.mode === 'finish-registration') {
      sendGAEvent('Finish registration', 'Viewed finish registration form', null);
      const { name = '', email = '', token = '' } = this.extras;
      this.data.name = name;
      this.data.email = email;
      this.data.token = token;
    }
  }
  createAccount() {
    this.error = undefined;
    this.mode = '';

    const { name, avatar = randomAvatar(), color } = this.service.user;
    this.data.name = name;
    this.data.avatar = avatar;
    this.data.color = color;
  }
  continueWithoutAccount() {
    this.error = undefined;
    this.startAnon = true;
    setTimeout(() => document.getElementById('nickname-input')?.focus(), 100);
  }
  cancelTellUsAboutYourself() {
    sendGAEvent('Sign up', 'Canceled sign up', this.mode === 'auth' ? this.auth : this.mode);
    this.service.trackEvent(Analytics.CancelSignup, { method: this.mode === 'auth' ? 'oauth' : 'email' });
    this.service.clearPendingProfile().catch(e => DEVELOPMENT && console.error(e));
    this.mode = '';
    this.error = undefined;
  }
  cancelSignUpByEmail() {
    if (this.onlyEmail) {
      this.mode = 'login';
      this.close.emit();
    } else {
      this.mode = '';
      this.error = undefined;
    }
  }
  cancelNotFeaturePro() {
    this.mode = 'login';
    this.close.emit();
  }
  openOrganizationLogin() {
    this.mode = 'organizationLogin';
  }
  loginWithOtherMethods() {
    this.error = undefined;
    this.mode = 'login';
  }
  async loginToOrganization(name: string) {
    const notFoundError = 'We couldn\'t find any organization matching this name';
    this.error = '';
    try {
      const teamId = await this.service.getTeamIdForOrganization(name);
      if (teamId) {
        this.loginToTeamOrganization(teamId);
      } else {
        this.error = notFoundError;
      }
    } catch (e) {
      this.error = notFoundError;
      DEVELOPMENT && console.error(e);
    }
  }
  loginToTeamOrganization(teamId: string) {
    window.location.href = `/auth/organization/${teamId}`;
  }
  async signIn(id: string) {
    if (this.working) return;

    if (id === 'twitter') {
      window.open('https://magm.ai/twitter-login');
      return;
    }

    this.error = undefined;
    this.startAnon = false;

    if (id === 'email') {
      this.mode = 'email';

      // prefill initial values
      const user = this.service.user;
      this.data = {
        name: user.name,
        email: user.email ?? '',
        avatar: user.avatar ?? randomAvatar(),
        password: '',
        color: user.color ?? '',
        userJob: user.userJob,
        receiveEmails: user.receiveEmails,
      };

      sendGAEvent('Sign up', 'Viewed signup form', 'with-email');
      this.service.trackEvent(Analytics.ViewSignupFormScreen, {
        method: 'email',
        receiveEmailsFieldDisplayed: true,
        userJobFieldDisplayed: true,
      });
    } else { // oauth
      const sessionId = storageGetItem('sessionId');
      if (this.service.sessionIdRequired && !sessionId) return;

      if (id === 'saml') {
        window.location.href = '/auth/saml';
        return;
      }

      try {
        await this.service.initAuth();
        const popup = this.service.openAuthPopup(id, sessionId);
        if (!popup) throw new Error(`Could not open oauth popup for ${id} provider!`);

        await handleAutoClose(popup, this.zone);

        if (IS_PORTAL) {
          const response = await this.service.getPendingProfile();

          if (response) {
            const { name = '', avatar = '', provider } = response;
            this.mode = 'auth';
            this.data.name = name;
            this.data.avatar = avatar;
            this.auth = id;
            sendGAEvent('Sign up', 'Viewed signup form', 'without-email');
            this.service.trackEvent(Analytics.ViewSignupFormScreen, {
              method: provider,
              receiveEmailsFieldDisplayed: true,
              userJobFieldDisplayed: true
            });
          } else {
            this.service.reloadSession();
            this.mode = '';
            this.close.emit();
          }
        } else {
          this.mode = '';
          this.close.emit();
        }

        setTimeout(() => {
          if (!this.service.user.anonymous) {
            sendGAEvent('Sign in', 'Signed in', id);
            this.service.trackEvent(Analytics.FinishAuthFlow, { effect: 'logged-in' });
          }
        }, 2000); // wait for session to reload
      } catch (e) {
        if (e.message !== 'Cancelled') {
          DEVELOPMENT && console.error(e);
          this.error = e.message;
          this.mode = '';
        }
      }

      this.service.reloadSession();
    }
  }
  submitEmail() {
    return this.submitForm(async () => {
      const { needsEmailConfirmation } = await this.service.createAccount(this.data, false);
      sendGAEvent('Sign up', `${this.data.receiveEmails ? 'Given' : 'Not given'} marketing consent`, null);
      sendGAEvent('Sign up', 'Selected role', this.data.userJob);
      sendGAEvent('Sign up', 'Signed up', 'email');
      this.service.trackEvent(Analytics.FinishAuthFlow, { effect: 'signed-up' });
      if (needsEmailConfirmation) {
        this.mode = 'emailConfirmation';
      } else {
        this.service.reloadSession();
        this.close.emit();
      }
    });
  }
  submitOAuth() {
    return this.submitForm(async () => {
      await this.service.createAccount(this.data, true);
      sendGAEvent('Sign up', `${this.data.receiveEmails ? 'Given' : 'Not given'} marketing consent`, null);
      sendGAEvent('Sign up', 'Selected role', this.data.userJob);
      this.service.trackEvent(Analytics.FinishAuthFlow, { effect: 'signed-up' });
      this.service.reloadSession();
      this.close.emit();
    });
  }
  submitAnonymous() {
    return this.submitForm(async () => {
      if (!this.anonName) return;

      await this.service.updateAccount({ name: this.anonName });
      sendGAEvent('Sign up', 'Joined as anonymous', null);
      this.service.trackEvent(Analytics.JoinAsAnonymous);
      this.service.trackEvent(Analytics.FinishAuthFlow, { effect: 'joined-as-guest' });
      this.service.reloadSession();
      this.close.emit();
    });
  }
  submitNotFeaturePro() {
    return this.submitForm(async () => {
      const { needsEmailConfirmation } = await this.service.createAccount(this.data, false);
      sendGAEvent('Sign up', 'Signed up', 'email');
      this.service.trackEvent(Analytics.FinishAuthFlow, { effect: 'signed-up' });
      this.service.createdAccount = true;
      if (needsEmailConfirmation) {
        this.mode = 'emailConfirmation';
      } else {
        this.service.reloadSession();
        this.close.emit();
      }
    });
  }
  async submitLogin() {
    await this.submitForm(async () => {
      if (!this.loginValid) return;

      await this.service.signIn(this.email, this.password);
      this.service.reloadSession();
      this.close.emit();
      sendGAEvent('Sign in', 'Signed in', 'email');
      this.service.trackEvent(Analytics.FinishAuthFlow, { effect: 'logged-in' });
    });
  }
  resetPassword() {
    return this.submitForm(async () => {
      if (!this.email) throw new Error('Enter your email address to reset the password');

      await this.service.resetPassword(this.email);
      this.passwordRecovered = true;
    });
  }
  submitPassword() {
    return this.submitForm(async () => {
      if (!this.resetPasswordData.password) {
        throw new Error('Password cannot be empty');
      }

      if (this.resetPasswordData.password !== this.resetPasswordData.repeatPassword) {
        throw new Error('Passwords must match');
      }

      await this.service.changePassword(this.resetPasswordData);

      if (IS_PORTAL) {
        void this.router.navigate(['my']);
      }
    });
  }
  submitInviteForm() {
    return this.submitForm(async () => {
      if (!this.data.password) {
        throw new Error('Password cannot be empty');
      }

      if (this.data.password !== this.data.repeatPassword) {
        throw new Error('Passwords must match');
      }
      await this.service.finishInviteSignup(this.data);
      void this.router.navigate(['my']);
    });
  }
  private async submitForm(action: () => Promise<void>) {
    if (this.working) return;

    try {
      this.working = true;
      this.error = undefined;
      await action();
    } catch (e) {
      if (e.message !== 'Cancelled') {
        DEVELOPMENT && console.error(e);
        this.error = e.message;
      }
    } finally {
      this.working = false;
    }
  }
}
