import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ErrorReporter } from 'magma/services/errorReporter';
import { BehaviorSubject, from, merge, NEVER, Observable, of, pipe, timer } from 'rxjs';
import { catchError, delayWhen, map, publishReplay, refCount, retryWhen, switchMap, take, tap } from 'rxjs/operators';
import { memoize } from 'shared/decorators';
import { EntityData } from 'shared/interfaces';
import { EntityPresence, UserPresence } from 'shared/rpc-interface';
import { fromRpc } from 'shared/rxjs';
import { updateParticipantsFromPresence } from 'shared/utils';
import { RpcService } from './rpc.service';

export function entityPresenceToUserPresenceMap(presence: EntityPresence) {
  return Object.values(presence).reduce((acc, presence) => {
    if (!acc[presence.entityId]) {
      acc[presence.entityId] = [presence];
    } else {
      acc[presence.entityId].push(presence);
    }
    return acc;
  }, {} as { [id: string]: UserPresence[] });
}

export function updateEntityDataWithPresence(entityData: EntityData[], presence: EntityPresence) {
  const presenceUsers = entityPresenceToUserPresenceMap(presence);
  for (const entity of entityData) {
    if (presenceUsers[entity._id]) {
      updateParticipantsFromPresence(entity.participants || [], presenceUsers[entity._id]);
    }
  }
  return entityData;
}

@Injectable()
export class PresenceService {
  constructor(private rpcService: RpcService, private errorReporter: ErrorReporter) { }

  mapLivePresence = () => pipe(
    switchMap((entityData: EntityData[]) => merge(of(entityData), this.monitorPresenceOnEntities(entityData.map(e => e._id))
      .pipe(map(([_, presence]) => {
        // TODO: optimize by using the scan operator
        const dataCopy = cloneDeep(entityData);
        updateEntityDataWithPresence(dataCopy, presence);
        return dataCopy;
      }))
    )),
  );

  @memoize({})
  monitorPresenceOnTeam(teamId: string) {
    // TEMP: remove when we find out if this is an issue
    if (typeof teamId !== 'string') {
      this.errorReporter.reportError('Invalid TeamId in monitorPresenceOnTeam', new Error('Invalid teamId'), { teamId });
      return NEVER;
    }

    if (!teamId) {
      DEVELOPMENT && console.error('teamId needs to be specified when listening for presence');
      return NEVER;
    }

    return this.rpcService.trackConnection$
      .pipe(
        switchMap((isConnected): Observable<[string, EntityPresence]> => {
          if (!isConnected) {
            return of([teamId, {}]);
          }
          return fromRpc(this.rpcService.presence.monitorPresenceOnTeam(teamId));
        }),
        // TODO: handle errors
        publishReplay(1),
        refCount(),
      );
  }

  monitorRecentEntities(initialEntityIds: string[], teamId: string | undefined) {
    return this.rpcService.trackConnection$
      .pipe(
        switchMap(connected => connected ? fromRpc(this.rpcService.presence.monitorRecentEntities(initialEntityIds, teamId)) : NEVER),
        // TODO: handle errors
      );
  }

  monitorEntitiesCount(teamId: string | undefined) {
    return this.rpcService.trackConnection$
      .pipe(
        switchMap(connected => connected ? fromRpc(this.rpcService.presence.monitorEntitiesCount(teamId)) : NEVER),
        // TODO: handle errors
      );
  }

  @memoize({})
  monitorPresenceOnEntities(entityIds: string[]) {
    if (!entityIds || entityIds.length === 0) {
      console.error('entityIds needs to be specified when listening for presence');
    }
    return this.rpcService.trackConnection$
      .pipe(
        switchMap((isConnected) => {
          if (!isConnected) {
            return from(entityIds.map(entityId => [entityId, {}] as [string, EntityPresence]));
          }
          return fromRpc(this.rpcService.presence.monitorPresenceOnEntities(entityIds));
        }),
        // TODO: handle errors
        publishReplay(1),
        refCount(),
      );
  }

  readonly lastPublishedPresence$ = new BehaviorSubject<string | undefined>(undefined);

  clearLastPublishedPresence() {
    this.lastPublishedPresence$.next(undefined);
  }

  get lastPublishedPresence() {
    return this.lastPublishedPresence$.value;
  }

  publishPresence(entityId: string) {
    return this.rpcService.trackConnection$
      .pipe(
        switchMap(isConnected => {
          if (!isConnected) {
            return NEVER;
          }
          this.lastPublishedPresence$.next(entityId);
          return fromRpc(this.rpcService.presence.publishPresence(entityId, {}))
            .pipe(catchError(error => {
              DEVELOPMENT && console.error(error);
              return of(false);
            }));
        }),
        retryWhen(errors => errors.pipe(
          tap(error => DEVELOPMENT && console.error(error)),
          delayWhen(() => timer(1000)),
          take(3),
        )),
      );
  }
}
