import {
  ITool, IToolEditor, IToolModel, TabletEvent, ToolId, hasAltKey, hasShiftKey, getTabletEventButton,
  hasCtrlOrMetaKey
} from '../interfaces';
import { faHandPaper } from '../icons';
import { getViewportAngle, zoomViewportAt, moveViewportTo, rotateViewportXY } from '../viewport';
import { invalidEnumReturn, invalidEnum } from '../baseUtils';
import { DRAG_ZOOM_RATIO } from '../constants';
import { clickZoom } from './zoomTool';
import { logAction } from '../actionLog';

const enum Mode {
  Move,
  Rotate,
  ZoomIn,
  ZoomOut,
}

function modeToCursor(mode: Mode) {
  switch (mode) {
    case Mode.Move: return 'cursor-hand';
    case Mode.Rotate: return 'cursor-rotate';
    case Mode.ZoomIn: return 'cursor-zoom-in';
    case Mode.ZoomOut: return 'cursor-zoom-out';
    default: return invalidEnumReturn(mode, 'cursor-hand');
  }
}

function modeFromEvent(e: TabletEvent) {
  if (hasCtrlOrMetaKey(e)) {
    return hasAltKey(e) ? Mode.ZoomOut : Mode.ZoomIn;
  } else if (hasAltKey(e)) {
    return Mode.Rotate;
  } else {
    return Mode.Move;
  }
}

export class HandTool implements ITool {
  id = ToolId.Hand;
  name = 'Hand Tool';
  description = 'Navigation tool to pan around your canvas';
  learnMore = 'https://help.magma.com/en/articles/6871508-hand';
  video = { url: '/assets/videos/hand.mp4', width: 374, height: 210 };
  icon = faHandPaper;
  cursor = 'cursor-hand';
  navigation = true;
  usesModifiers = true;
  private startX = 0;
  private startY = 0;
  private startViewX = 0;
  private startViewY = 0;
  private startAngle = 0;
  private startScale = 1;
  private mode = Mode.Move;
  private moved = false;
  private button = 0;
  constructor(public editor: IToolEditor, public model: IToolModel) {
  }
  hover(_x: number, _y: number, e: TabletEvent) {
    this.cursor = modeToCursor(modeFromEvent(e));
  }
  start(_x: number, _y: number, _pressure: number, e?: TabletEvent) {
    if (!e) return;

    logAction('[local] start: hand');
    this.init(e, modeFromEvent(e));
  }
  move(_x: number, _y: number, _pressure: number, e?: TabletEvent) {
    if (!e) return;

    const shift = hasShiftKey(e);
    const mode = modeFromEvent(e);
    if (this.mode !== mode) this.init(e, mode);

    this.moved = this.moved || this.startX !== e.x || this.startY !== e.y;

    switch (mode) {
      case Mode.Move:
        moveViewportTo(this.editor.view, this.startViewX + (e.x - this.startX), this.startViewY + (e.y - this.startY));
        break;
      case Mode.Rotate:
        rotateViewportXY(this.editor.view, e.x, e.y, this.startAngle, shift);
        break;
      case Mode.ZoomIn:
      case Mode.ZoomOut:
        this.editor.view.x = this.startViewX;
        this.editor.view.y = this.startViewY;
        this.editor.view.scale = this.startScale;
        zoomViewportAt(this.editor.view, (e.x - this.startX) * DRAG_ZOOM_RATIO, this.startX, this.startY);
        break;
      default: invalidEnum(mode);
    }
  }
  end(x: number, y: number, pressure: number, e?: TabletEvent) {
    this.move(x, y, pressure, e);

    if (!this.moved && (this.mode === Mode.ZoomIn || this.mode === Mode.ZoomOut)) {
      clickZoom(this.editor.view, this.startX, this.startY, this.button, this.mode === Mode.ZoomOut);
    }
  }
  wheel(x: number, y: number, _deltaX: number, deltaY: number, e: TabletEvent) {
    logAction('[local] hand:wheel', true);
    zoomViewportAt(this.editor.view, deltaY, x, y);
    this.init(e, this.mode);
  }
  private init(e: TabletEvent, mode: Mode) {
    this.button = getTabletEventButton(e);
    this.startX = e.x;
    this.startY = e.y;
    this.startViewX = this.editor.view.x;
    this.startViewY = this.editor.view.y;
    this.startAngle = this.editor.view.rotation - getViewportAngle(this.editor.view, e.x, e.y);
    this.startScale = this.editor.view.scale;
    this.mode = mode;
    this.moved = false;
  }
}
