import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { navigateToDrawing } from '../../../common/clientUtils';
import { DEFAULT_BACKGROUND_COLOR, DEFAULT_CANVAS_HEIGHT, DEFAULT_CANVAS_WIDTH, DEFAULT_DRAWING_NAME, doesImageSizeRequirePro } from '../../../common/constants';
import { DocumentSize } from '../../../common/interfaces';
import { faFile, faPlus, faRectangleLandscape, faRectanglePortrait, faSquare } from '../../../generated/fa-icons';
import { blazeIcon } from '../../../common/icons';
import { ManageService } from '../../../services/manageService';
import { Modals } from '../../../services/modals';
import { Model } from '../../../services/model';

@Component({
  selector: 'new-canvas-button',
  templateUrl: './new-canvas-button.pug',
  styleUrls: ['./new-canvas-button.scss'],
})
export class NewCanvasButton {
  readonly blazeIcon = blazeIcon;
  readonly newCanvasIcon = faFile;
  readonly plusIcon = faPlus;
  readonly documentSizes: DocumentSize[] = [
    { width: 1920, height: 1080, name: 'Landscape drawing', icon: faRectangleLandscape },
    { width: 1080, height: 1920, name: 'Vertical drawing', icon: faRectanglePortrait },
    { width: 800, height: 600, name: 'Small drawing', icon: faRectangleLandscape, size: '2x' },
    { width: 3840, height: 2160, name: 'High resolution landscape', icon: faRectangleLandscape, size: '3_5x' },
    { width: 4096, height: 4096, name: 'High resolution square', icon: faSquare, size: '3_5x' },
  ];
  @Output() completed = new EventEmitter<void>();
  @Output() failed = new EventEmitter<Error>();
  @Input() small = false;
  @Input() hideTextOnSmallScreen = false;
  @Input() buttonClass = 'btn-prime';
  @Input() team: string | undefined;
  @Input() project: string | undefined;
  @Input() folder: string | undefined;
  constructor(
    private modals: Modals,
    private model: Model,
    private router: Router,
    private manage: ManageService,
  ) {
  }
  requiresPro({ width, height }: Pick<DocumentSize, 'width' | 'height'>) {
    const canHaveLargeCanvas = IS_HOSTED || this.model.user.pro || this.model.user.isSuperAdmin;
    return IS_PORTAL && !canHaveLargeCanvas && doesImageSizeRequirePro(width, height);
  }
  createCustomCanvas() {
    if (this.storageLimitReached()) return;

    this.modals.createDrawing(this.model.allowedFolderProjectTeam(this));
  }
  async createCanvas(width = DEFAULT_CANVAS_WIDTH, height = DEFAULT_CANVAS_HEIGHT) {
    try {
      if (this.storageLimitReached()) return;

      if (IS_PORTAL && !IS_HOSTED && this.requiresPro({ width, height })) {
        this.manage.upgrade('create-large-canvas');
        return;
      }

      const id = await this.model.createDrawing({
        name: DEFAULT_DRAWING_NAME,
        background: DEFAULT_BACKGROUND_COLOR,
        width, height,
        ...this.model.allowedFolderProjectTeam(this),
      });

      this.completed.emit();
      navigateToDrawing(this.router, id, 'new-canvas-button');
    } catch (e) {
      this.manage.handleErrorMessages(e.message);
      this.failed.emit(e);
    }
  }
  private storageLimitReached() {
    const team = this.team ? this.manage.team(this.team) : undefined;
    if (this.manage.isStorageLimitExceeded(team)) {
      this.manage.reachedStorageLimit();
      return true;
    } else {
      return false;
    }
  }
}
