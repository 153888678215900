<em class="browsers-recommend" *ngIf="!isMobile">We recommend Google Chrome with our pressure extension for best experience.</em><div class="browsers-tabs"><button class="browser-tab" type="button" (click)="browser = 'chrome'" [class.active]="browser === 'chrome'"><img [svgSrc]="chromeLogo" alt="Chrome"></button><button class="browser-tab" type="button" (click)="browser = 'firefox'" [class.active]="browser === 'firefox'"><img [svgSrc]="firefoxLogo" alt="Firefox"></button><button class="browser-tab" type="button" (click)="browser = 'edge'" [class.active]="browser === 'edge'"><img [svgSrc]="edgeLogo" alt="Edge"></button><button class="browser-tab" type="button" (click)="browser = 'safari'" [class.active]="browser === 'safari'"><img [svgSrc]="safariLogo" alt="Safari"></button><button class="browser-tab" type="button" (click)="browser = 'opera'" [class.active]="browser === 'opera'"><img [svgSrc]="operaLogo" alt="Opera"></button><button class="browser-tab" type="button" (click)="browser = 'ios'" [class.active]="browser === 'ios'"><img [svgSrc]="iosLogo" alt="iOS"></button></div><div class="browsers-content" [ngSwitch]="browser"><div class="browsers-content-tab" *ngSwitchCase="'chrome'"><h3>Google Chrome</h3><div class="item"><div class="item-header"><div class="item-icon item-icon-success"><svg-icon [icon]="checkIcon"></svg-icon></div><div class="item-title">Native stylus support on <b>Windows</b>, <b>Linux</b> and <b>Mac</b></div></div><ul class="item-content"><li>make sure you have <b>Windows Ink</b> / <b>Tablet PC</b> / <b>Digital Ink</b> option enabled in your tablet settings.<br>See settings screenshot for: 
<a class="link" [href]="'images/pressure-wacom.png' | rev" target="_blank">Wacom</a>, 
<a class="link" [href]="'images/pressure-xppen.png' | rev" target="_blank">XP-Pen</a>, 
<a class="link" [href]="'images/pressure-huion.png' | rev" target="_blank">Huion</a>, 
<a class="link" [href]="'images/pressure-ugee.png' | rev" target="_blank">Ugee</a>, 
<a class="link" [href]="'images/pressure-veikk.png' | rev" target="_blank">VEIKK</a>.</li><li>make sure you have <b>Tip Double Click Distance</b> turned off in your tablet settings.</li><li>in case of any issues you can use pressure plugin instead (see below).</li></ul></div><div class="item"><div class="item-header"><div class="item-icon item-icon-warning"><svg-icon [icon]="downloadIcon"></svg-icon></div><div class="item-title">Plugin is required for stylus support on <b>older Windows systems</b></div></div><ul class="item-content"><li>stylus pressure is supported on any version of Windows systems using our custom 
<a class="link" [href]="extensionLink" target="_blank">Extension for Google Chrome</a></li><li>when using the extension make sure <b>Windows Ink</b> option is <b>NOT</b> enabled in your tablet settings.</li><li><span>pressure is supported for all WinTab compatible tablets (Huion tablets are not supported)</span><div class="text-faded mb-1 mt-2">Check with your tablet vendor for compatibility with WinTab drivers.</div><div class="text-faded">After installing the extension, make sure the extension bar in your browser has small gray
<b class="mr-1 text-nowrap">brush <svg-icon [icon]="brushIcon"></svg-icon></b> icon on it. If the icon is red you need to click
on it and download additional software, from the link provided, to finish the installation.
</div></li></ul></div><div class="item"><div class="item-header"><div class="item-icon item-icon-danger"><svg-icon [icon]="alertIcon"></svg-icon></div><div class="item-title">Issues</div></div><ul class="item-content"><li>On <b>Mac</b>, an external mouse can sometimes interfere with stylus pressure.
Try disconnecting or reconnecting your mouse if you're losing pressure sensitivity while drawing.</li><li>If you encounter any issues with stylus pressure, please <a href="mailto:support@magma.com">contact our support</a>.</li></ul></div></div><div class="browsers-content-tab" *ngSwitchCase="'firefox'"><h3>Firefox</h3><div class="item"><div class="item-header"><div class="item-icon item-icon-success"><svg-icon [icon]="checkIcon"></svg-icon></div><div class="item-title">Native stylus support on <b>Windows</b> and <b>Mac</b></div></div><ul class="item-content"><li>make sure you have <b>Windows Ink</b> / <b>Tablet PC</b> / <b>Digital Ink</b> option enabled in your tablet settings.<br>See settings screenshot for: 
<a class="link" [href]="'images/pressure-wacom.png' | rev" target="_blank">Wacom</a>, 
<a class="link" [href]="'images/pressure-xppen.png' | rev" target="_blank">XP-Pen</a>, 
<a class="link" [href]="'images/pressure-huion.png' | rev" target="_blank">Huion</a>, 
<a class="link" [href]="'images/pressure-ugee.png' | rev" target="_blank">Ugee</a>, 
<a class="link" [href]="'images/pressure-veikk.png' | rev" target="_blank">VEIKK</a>.</li><li>make sure you have <b>Tip Double Click Distance</b> turned off in your tablet settings.</li></ul></div><div class="item"><div class="item-header"><div class="item-icon item-icon-danger"><svg-icon [icon]="alertIcon"></svg-icon></div><div class="item-title">Known issues</div></div><ul class="item-content"><li>Firefox browser doesn't support copy &amp; paste yet.</li><li><kbd>Alt</kbd> key works inconsistently on Firefox browser.</li></ul></div></div><div class="browsers-content-tab" *ngSwitchCase="'edge'"><h3>Microsoft Edge</h3><div class="item"><div class="item-header"><div class="item-icon item-icon-success"><svg-icon [icon]="checkIcon"></svg-icon></div><div class="item-title">Native stylus support on <b>Windows</b></div></div><ul class="item-content"><li>make sure you have <b>Windows Ink</b> / <b>Tablet PC</b> / <b>Digital Ink</b> option enabled in your tablet settings.<br>See settings screenshot for: 
<a class="link" [href]="'images/pressure-wacom.png' | rev" target="_blank">Wacom</a>, 
<a class="link" [href]="'images/pressure-xppen.png' | rev" target="_blank">XP-Pen</a>, 
<a class="link" [href]="'images/pressure-huion.png' | rev" target="_blank">Huion</a>, 
<a class="link" [href]="'images/pressure-ugee.png' | rev" target="_blank">Ugee</a>, 
<a class="link" [href]="'images/pressure-veikk.png' | rev" target="_blank">VEIKK</a>.</li><li>make sure you have <b>Tip Double Click Distance</b> turned off in your tablet settings.</li><li>stylus is also natively supported on <b>Internet Explorer 11</b>.</li></ul></div><div class="item"><div class="item-header"><div class="item-icon item-icon-danger"><svg-icon [icon]="alertIcon"></svg-icon></div><div class="item-title">Known issues</div></div><ul class="item-content"><li>Some older versions of Edge browser don't support copy &amp; paste.</li></ul></div></div><div class="browsers-content-tab" *ngSwitchCase="'safari'"><h3>Safari</h3><div class="item"><div class="item-header"><div class="item-icon item-icon-danger"><svg-icon [icon]="alertIcon"></svg-icon></div><div class="item-title">No stylus support on <b>Mac</b>.</div></div><ul class="item-content"><li>Safari browser doesn't support stylus input yet, use <b>Chrome</b> or <b>Firefox</b> instead.</li><li>Safari browser doesn't support copy &amp; paste yet.</li></ul></div></div><div class="browsers-content-tab" *ngSwitchCase="'opera'"><h3>Opera</h3><div class="item"><div class="item-header"><div class="item-icon item-icon-success"><svg-icon [icon]="checkIcon"></svg-icon></div><div class="item-title">Native stylus support on <b>Windows</b> and <b>Mac</b></div></div><ul class="item-content"><li>make sure you have <b>Windows Ink</b> / <b>Tablet PC</b> / <b>Digital Ink</b> option enabled in your tablet settings.<br>See settings screenshot for: 
<a class="link" [href]="'images/pressure-wacom.png' | rev" target="_blank">Wacom</a>, 
<a class="link" [href]="'images/pressure-xppen.png' | rev" target="_blank">XP-Pen</a>, 
<a class="link" [href]="'images/pressure-huion.png' | rev" target="_blank">Huion</a>, 
<a class="link" [href]="'images/pressure-ugee.png' | rev" target="_blank">Ugee</a>, 
<a class="link" [href]="'images/pressure-veikk.png' | rev" target="_blank">VEIKK</a>.</li><li>make sure you have <b>Tip Double Click Distance</b> turned off in your tablet settings.</li><li>in case of any issues you can use pressure plugin instead (see below).</li></ul></div><div class="item"><div class="item-header"><div class="item-icon item-icon-warning"><svg-icon [icon]="downloadIcon"></svg-icon></div><div class="item-title">Plugin is required for stylus support on <b>older Windows systems</b></div></div><ul class="item-content"><li>stylus pressure is supported on Windows systems using our custom extension for Google Chrome</li><li>in order to install our extension on Opera, install 
<a class="link" href="https://addons.opera.com/en/extensions/details/download-chrome-extension-9/">Download Chrome Extension</a>
that allows you to install Chrome extensions on Opera.</li><li>now you can install our <a class="link" [href]="extensionLink" target="_blank">Extension for Google Chrome</a></li><li><span>pressure is supported for all WinTab compatible tablets (Huion tablets are not supported)</span><div class="text-faded mb-1 mt-2">Check with your tablet vendor for compatibility with WinTab drivers.</div><div class="text-faded">After installing the extension, make sure the extension bar in your browser has small gray
<b class="mr-1 text-nowrap">brush <svg-icon [icon]="brushIcon"></svg-icon></b> icon on it. If the icon is red you need to click
on it and download additional software, from the link provided, to finish the installation.
</div></li></ul></div><div class="item"><div class="item-header"><div class="item-icon item-icon-danger"><svg-icon [icon]="alertIcon"></svg-icon></div><div class="item-title">Known issues</div></div><ul class="item-content"><li>Opera browser doesn't allow web applications to use <b>right mouse button</b> and <kbd>Alt</kbd> key,
all shortcuts using these buttons will not work correctly.
</li></ul></div></div><div class="browsers-content-tab" *ngSwitchCase="'ios'"><h3>iOS</h3><div class="item"><div class="item-header"><div class="item-icon item-icon-success"><svg-icon [icon]="checkIcon"></svg-icon></div><div class="item-title">Native stylus support on iOS Safari</div></div><ul class="item-content"><li>stylus pressure is supported for <a class="link" href="http://www.apple.com/apple-pencil/">Apple Pencil</a> and
any other compatible stylus device.</li></ul></div><div class="item"><div class="item-header"><div class="item-icon item-icon-danger"><svg-icon [icon]="alertIcon"></svg-icon></div><div class="item-title">Known issues</div></div><ul class="item-content"><li>iOS Safari browser requires graphics acceleration to be enabled, disabling graphics acceleration will
result in frequent application crashes.</li><li>We recommend that you disable <b>Scribble</b> feature when using stylus with iOS devices.
Scribble feature is interfering with internet applications and may cause issues when drawing.</li><li>We also recommend that you disable <b>Low Power</b> mode as it slows down drawing and can cause
noticeable lag.</li></ul></div></div></div>