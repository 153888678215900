import {
  ITool, IToolEditor, IToolModel, TabletEvent, IToolData, ToolId, hasShiftKey, hasAltKey, CursorType, Mask,
  SelectionToolMode, SelectionToolModes
} from '../interfaces';
import { isMaskEmpty } from '../mask';
import { SelectionToolAction } from './selectionTool';
import { lassoSelectionIcon } from '../icons';
import { screenToDocumentXY } from '../viewport';
import { userSelectionContainsPoint } from '../selectionUtils';
import { safeBoolean } from '../toolUtils';
import { LassoSelectionBaseTool } from './lassoSelectionBaseTool';

export interface LassoSelectionData extends IToolData {
  shift: boolean;
  alt: boolean;
  mode: SelectionToolMode;
}

export class LassoSelectionTool extends LassoSelectionBaseTool implements ITool {
  id = ToolId.LassoSelection;
  name = 'Lasso Selection';
  description = 'Draw out your own custom selection';
  learnMore = 'https://help.magma.com/en/articles/6790152-lasso-selection';
  video = { url: '/assets/videos/lasso-selection.mp4', width: 374, height: 210 };
  icon = lassoSelectionIcon;
  cursor = 'cursor-selection';
  canvasCursor = CursorType.Crosshair;
  syntheticCursor = true;
  updatesCursor = true;
  handleView = true;
  scrollView = false;
  navigation = false;
  selection = true;
  continuousRedraw = true;
  usesModifiers = true;
  action = SelectionToolAction.Select;
  fields = ['mode'];
  constructor(public editor: IToolEditor, public model: IToolModel) {
    super(editor, model);
  }
  getSelection(): Mask {
    return this.model.user.selection;
  }
  hover(x: number, y: number, e: TabletEvent) {
    const user = this.model.user;

    if (!isMaskEmpty(user.selection) && (hasAltKey(e) || hasShiftKey(e))) {
      this.cursor = hasAltKey(e) ? 'cursor-selection-subtract' : 'cursor-selection-add';
    } else if (userSelectionContainsPoint(user, x, y)) {
      this.cursor = 'cursor-selection-move';
    } else {
      this.cursor = 'cursor-selection';
    }
  }
  setup(data?: LassoSelectionData) {
    this.shift = data ? safeBoolean(data.shift) : false;
    this.alt = data ? safeBoolean(data.alt) : false;
    this.mode = (data && SelectionToolModes.includes(data.mode)) ? data.mode : this.mode;
  }
  start(x: number, y: number, pressure: number, e?: TabletEvent) {
    this.startSelection(x, y, pressure, true, e);
  }
  move(x: number, y: number, pressure: number) {
    screenToDocumentXY(this.temp, x, y, this.view);
    this.doMove(this.temp.x, this.temp.y);
    this.model.nextTool(x, y, pressure);
  }
  end(x: number, y: number, pressure: number) {
    this.endSelection(x, y, pressure, true);
  }
  cancel() {
    this.undo?.();
  }
}
