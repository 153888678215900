import { Injectable } from '@angular/core';
import { RpcWebSocketClient } from '@deepkit/rpc';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastService } from 'magma/services/toast.service';
import { combineLatest, defer, NEVER } from 'rxjs';
import { skip, startWith, distinctUntilChanged, switchMap } from 'rxjs/operators';
import {
  PresenceRPCInterface, ThreadsRPC, ThreadsRPCInterface, AppNotificationRPC, AppNotificationsRPCInterface, OnlineRPCInterface, OnlineRPC, AiRPCInterface, AiRPC,
} from 'shared/rpc-interface';
import { AuthService } from './auth.service';
import { TeamsQuery } from './team.query';

@Injectable()
@UntilDestroy()
export class RpcService {
  private deepkitClient = RpcWebSocketClient.fromCurrentHost('/api/teams/rpc');
  presence = this.deepkitClient.controller(PresenceRPCInterface);
  threads = this.deepkitClient.controller<ThreadsRPCInterface>(ThreadsRPC);
  notifications = this.deepkitClient.controller<AppNotificationsRPCInterface>(AppNotificationRPC);
  online = this.deepkitClient.controller<OnlineRPCInterface>(OnlineRPC);
  ai = this.deepkitClient.controller<AiRPCInterface>(AiRPC);

  private autoReconnect = false;
  private reconnecting: NodeJS.Timeout | undefined;

  reconnected$ = this.deepkitClient.transporter.reconnected;
  isConnected$ = this.deepkitClient.transporter.connection;
  disconnected$ = this.deepkitClient.transporter.disconnected;

  trackConnection$ = this.isConnected$.pipe(skip(1), startWith(true));

  constructor(authService: AuthService, private toastService: ToastService, private teamsQuery: TeamsQuery) {
    authService.token$.pipe(skip(1), untilDestroyed(this)).subscribe((newToken) => {
      if (this.reconnecting) {
        clearTimeout(this.reconnecting);
        this.reconnecting = undefined;
      }
      this.autoReconnect = false;
      this.deepkitClient.disconnect();
    });

    combineLatest([this.isConnected$, this.teamsQuery.selectCount().pipe(distinctUntilChanged())])
      .pipe(
        switchMap(([status, teamCount]) => {
          if (status) {
            this.autoReconnect = true;
          }
          if (status && teamCount > 0) {
            return defer(async () => this.online.online()).pipe(switchMap(o => o));
          }
          return NEVER;
        }),
        untilDestroyed(this),
      ).subscribe();

    this.disconnected$.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.autoReconnect) {
        //  this.toastService.error({
        //    message: 'We lost the connection with the server. Some features may be degraded. Reconnecting ...',
        //    timeout: 3000,
        //  });
        this.tryToConnect();
      }
    });

    this.reconnected$.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.reconnecting) {
        // this.toastService.success({
        //   message: 'Connection restored',
        //   timeout: 2000,
        // });
        DEVELOPMENT && console.info('Connection restored');
        clearTimeout(this.reconnecting);
        this.reconnecting = undefined;
      }
    });
  }

  tryToConnect() {
    this.deepkitClient.connect().catch(() => {
      this.reconnecting = setTimeout(() => {
        this.tryToConnect();
      }, 2_000);
    });
  }

  ngOnDestroy() {
    this.autoReconnect = false;
    this.reconnecting && clearTimeout(this.reconnecting);
    this.deepkitClient.disconnect();
  }
}
