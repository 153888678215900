import { Subject } from 'rxjs';
import { ALT_KEY, SHIFT_KEY, CMD_KEY } from './utils';
import { isMac, isiOS } from './userAgentUtils';
import { includes } from './baseUtils';
import { removeAllNodes, appendText } from './htmlUtils';
import { getLocale } from './keyboardUtils';

export interface Locales {
  en: string;
  gb?: string; // UK
  fr?: string; // french
  ru?: string; // russian
  ja?: string; // japanese
  kr?: string; // korean
  de?: string; // german
  cs?: string; // czech
  sk?: string; // slovak
  es?: string; // spanish
  el?: string; // greek
  it?: string; // italian
  fi?: string; // finnish
  pt?: string; // portuguese
  mx?: string; // latin america
  mac?: string; // override for special mac keys
}

export const KEYBOARD_LAYOUTS: { id: keyof Locales; name: string; }[] = [];
export let keyboardLayout = getDefaultLayout();
let keyboardLayoutIsDefault = true;
const keyCodeToKey = new Map<number, string>();
const codeToKey = new Map<string, string>();
const keyToNameMap = new Map<string, Locales>();

export function getDefaultLayout() {
  const lang = getLocale();
  const la = lang.substring(0, 2).toLowerCase();
  if (lang === 'en-GB') return 'gb';
  if (la === 'be' || la === 'uk' || la === 'kk' || la === 'mo' || la === 'ky' || la === 'uz' || la === 'tg') return 'ru';
  if (la === 'sv' || lang === 'no') return 'fi';
  if (lang.indexOf('es-') === 0) return 'mx';
  return la;
}

// keyCode - browser numeric keyCode
// code    - browser string code
// key     - our own internal key name
// name    - user facing name of the key
function addKey(keyCode: number, code: string, key: string, name: string | undefined, locales?: Partial<Locales>) {
  if (DEVELOPMENT && codeToKey.has(code)) console.error(`code already added: ${code}`);
  codeToKey.set(code, key);
  if (name) keyToNameMap.set(key, { en: name, ...locales });
  if (keyCode) keyCodeToKey.set(keyCode, key);
}

addKey(192, 'Backquote', '`', '`');
addKey(223, 'Backquote_', '`', undefined); // edge <= 18
addKey(189, 'Minus', '-', '-');
addKey(63, 'Minus_', '-', undefined); // ß
addKey(187, 'Equal', '=', '=');
addKey(61, 'Equal_', '=', undefined); // firefox (keyCode fallback)
addKey(160, 'Equal__', '=', undefined); // ^ (ja)
addKey(219, 'BracketLeft', '[', '[');
addKey(221, 'BracketRight', ']', ']');
addKey(186, 'Semicolon', ';', ';');
addKey(59, 'Semicolon_', ';', undefined); // firefox (keyCode fallback)
addKey(222, 'Quote', `'`, `'`);
addKey(191, 'Slash', '/', '/');
addKey(220, 'Backslash', '\\', '\\');
addKey(226, 'IntlBackslash', '\\2', 'Left \\');
addKey(188, 'Comma', ',', ',');
addKey(190, 'Period', '.', '.');

addKey(27, 'Escape', 'esc', 'Esc');
addKey(8, 'Backspace', 'backspace', 'Backspace', { mac: '⌫' });
addKey(9, 'Tab', 'tab', 'Tab'); // ⇥
addKey(13, 'Enter', 'enter', 'Enter', { mac: 'Return' }); // ↵
addKey(32, 'Space', 'space', 'Space');
addKey(20, 'CapsLock', 'capslock', 'CapsLock'); // ⇪
addKey(19, 'Pause', 'pause', 'Pause Break');
addKey(145, 'ScrollLock', 'scrolllock', 'Scroll Lock');
addKey(44, 'PrintScreen', 'printscreen', 'Print Screen');
addKey(42, 'PrintScreen_', 'printscreen', undefined); // firefox (keyCode fallback)
addKey(0, 'KanaMode', 'kanamode', 'Kana Mode', { ja: 'カタカナ/ひらがな' });
addKey(0, 'IntlRo', 'ro', 'ろ');
addKey(0, 'IntlYen', 'yen', '¥', { ru: '\\/' });
addKey(177, 'MediaTrackPrevious', 'prevtrack', 'Previous Track');
addKey(176, 'MediaTrackNext', 'nexttrack', 'Next Track');
addKey(173, 'AudioVolumeMute', 'volumemute', 'Volume Mute');
addKey(174, 'AudioVolumeDown', 'volumedown', 'Volume Down');
addKey(175, 'AudioVolumeUp', 'volumeup', 'Volume Up');
addKey(0, 'VolumeMute', 'volumemute', undefined); // < chrome 50, < firefox 49
addKey(0, 'VolumeDown', 'volumedown', undefined); // < chrome 50, < firefox 49
addKey(0, 'VolumeUp', 'volumeup', undefined); // < chrome 50, < firefox 49
addKey(179, 'MediaPlayPause', 'playpause', 'Play/Pause');
addKey(0, 'MediaPlay', 'playpause', undefined); // CrOS
addKey(0, 'MediaPause', 'playpause', undefined); // sometimes this is returned
addKey(227, 'MediaRewind', 'mediarewind', 'Rewind'); // tv
addKey(178, 'MediaStop', 'stop', 'Stop');
addKey(417, 'MediaFastForward', 'fastforward', 'Fast Forward'); // tv
addKey(0, 'Eject', 'eject', 'Eject'); // ⏏
addKey(47, 'Help', 'help', 'Help');
addKey(6, 'Help_', 'help', undefined); // edge <= 18, firefox
addKey(37, 'ArrowLeft', 'left', '🡄');
addKey(38, 'ArrowUp', 'up', '🡅');
addKey(39, 'ArrowRight', 'right', '🡆');
addKey(40, 'ArrowDown', 'down', '🡇');
addKey(33, 'PageUp', 'pageup', 'Page Up'); // ⇞
addKey(34, 'PageDown', 'pagedown', 'Page Down'); // ⇟
addKey(36, 'Home', 'home', 'Home');
addKey(35, 'End', 'end', 'End');
addKey(45, 'Insert', 'insert', 'Insert');
addKey(46, 'Delete', 'delete', 'Delete'); // ⌦
addKey(93, 'ContextMenu', 'menu', 'Menu');
addKey(0, 'Power', 'power', 'Power');
addKey(95, 'Sleep', 'sleep', 'Sleep');
addKey(0, 'WakeUp', 'wakeup', 'Wake Up');
addKey(172, 'BrowserHome', 'browserhome', 'Browser Home');
addKey(170, 'BrowserSearch', 'browsersearch', 'Browser Search');
addKey(171, 'BrowserFavorites', 'browserfavourites', 'Browser Favourites');
addKey(168, 'BrowserRefresh', 'browserrefresh', 'Browser Refresh');
addKey(169, 'BrowserStop', 'browserstop', 'Browser Stop');
addKey(167, 'BrowserForward', 'browserforward', 'Browser Forward');
addKey(166, 'BrowserBack', 'browserback', 'Browser Back');
addKey(180, 'LaunchMail', 'mail', 'Mail');
addKey(181, 'LaunchMediaPlayer', 'mediaplayer', 'Media Player');
addKey(182, 'LaunchApp1', 'app1', 'My Computer');
addKey(0, 'SelectTask', 'app1', undefined); // CrOS
addKey(183, 'LaunchApp2', 'app2', 'Calculator');
addKey(154, 'LaunchControlPanel', 'launchcontrolpanel', 'Launch Control Panel');
addKey(0, 'MediaSelect', 'mediaplayer', undefined); // < firefox 49
addKey(0, 'Fn', 'fn', 'Fn');
addKey(0, 'FnLock', 'fn', 'FnLock');
addKey(3, 'Cancel', 'cancel', 'Cancel'); // browser-stop ? non-standard

addKey(91, 'MetaLeft', 'lmeta', 'Left OS', { mac: 'Left ⌘' });
addKey(224, 'OSLeft', 'lmeta', undefined); // firefox
addKey(92, 'MetaRight', 'rmeta', 'Right OS', { mac: 'Right ⌘' });
addKey(0, 'OSRight', 'rmeta', undefined); // firefox
addKey(17, 'ControlLeft', 'lctrl', 'Left Ctrl');
addKey(0, 'ControlRight', 'rctrl', 'Right Ctrl');
addKey(16, 'ShiftLeft', 'lshift', 'Left Shift', { mac: 'Left ⇧' }); // ⇧
addKey(0, 'ShiftRight', 'rshift', 'Right Shift', { mac: 'Right ⇧' }); // ⇧
addKey(18, 'AltLeft', 'lalt', 'Left Alt', { mac: 'Left ⌥' });
addKey(58, 'AltLeft_', 'lalt', undefined);
addKey(0, 'AltRight', 'ralt', 'Right Alt', { mac: 'Right ⌥' }); // AltGr
addKey(225, 'AltGraph', 'ralt', undefined); // AltGr

// for displaying modifiers alone
addKey(0, 'alt', 'alt', 'Alt', { mac: '⌥' });
addKey(0, 'cmd', 'cmd', 'Cmd', { mac: '⌘' });
addKey(0, 'shift', 'shift', 'Shift', { mac: '⇧' });

addKey(144, 'NumLock', 'numlock', 'NumLock');
addKey(106, 'NumpadMultiply', 'num*', 'Numpad *');
addKey(0, 'NumpadStar', 'num*', undefined); // phones/remotes ?
addKey(107, 'NumpadAdd', 'num+', 'Numpad +');
addKey(109, 'NumpadSubtract', 'num-', 'Numpad -');
addKey(110, 'NumpadDecimal', 'num.', 'Numpad .'); // may be , for some locales
addKey(111, 'NumpadDivide', 'num/', 'Numpad /');
addKey(108, 'NumpadComma', 'num,', 'Numpad ,'); // may be . for some locales
addKey(0, 'NumpadEqual', 'num=', 'Numpad =');
addKey(0, 'NumpadEnter', 'numenter', 'Numpad Enter');
addKey(0, 'NumpadHash', 'num#', 'Numpad #');
addKey(0, 'NumpadParenLeft', 'num(', 'Numpad (');
addKey(0, 'NumpadParenRight', 'num)', 'Numpad )');
addKey(0, 'NumpadBackspace', 'numbackspace', 'Numpad Backspace');
addKey(0, 'NumpadClear', 'numclear', 'Numpad Clear');
addKey(0, 'NumpadClearEntry', 'numclearentry', 'Numpad CE');
addKey(0, 'NumpadMemoryAdd', 'nummem+', 'M+');
addKey(0, 'NumpadMemoryClear', 'nummemclear', 'MC');
addKey(0, 'NumpadMemoryRecall', 'nummemrecall', 'MR');
addKey(0, 'NumpadMemoryStore', 'nummemstore', 'MS');
addKey(0, 'NumpadMemorySubtract', 'nummem-', 'M-');

addKey(242, 'Lang1', 'lang1', 'Lang 1', { ja: 'かな', kr: '한/영' });
addKey(22, 'Lang2', 'lang2', 'Lang 2', { ja: '英数', kr: '한자' });
addKey(241, 'Lang3', 'lang3', 'Katakana', { ja: 'カタカナ' });
addKey(0, 'Lang4', 'lang4', 'Hiragana', { ja: 'ひらがな' });
addKey(243, 'Lang5', 'lang5', 'Zenkaku/Hankaku');
addKey(21, 'HangulMode', 'hangul', 'Hangul Mode', { kr: '한/영' });
addKey(25, 'Hanja', 'hanja', 'Hanja', { kr: '한자' });
addKey(0, 'Katakana', 'katakana', 'Katakana', { ja: 'カタカナ' });
addKey(0, 'Hiragana', 'hiragana', 'Hiragana', { ja: 'ひらがな' });
addKey(28, 'Convert', 'convert', 'Convert', { ja: '変換' });
addKey(29, 'NonConvert', 'nonconvert', 'Non Convert', { ja: '無変換' });

// legacy modifier keys
addKey(0, 'Hyper', 'hyper', 'Hyper'); // works like ctrl ?
addKey(0, 'Super', 'super', 'Super');
addKey(0, 'Turbo', 'turbo', 'Turbo');

// legacy sun keyboard
addKey(0, 'Again', 'again', 'Again');
addKey(0, 'Copy', 'copy', 'Copy');
addKey(0, 'Cut', 'cut', 'Cut');
addKey(0, 'Find', 'find', 'Find');
addKey(0, 'Open', 'open', 'Open');
addKey(0, 'Paste', 'paste', 'Paste');
addKey(0, 'Props', 'props', 'Props');
addKey(0, 'Select', 'select', 'Select');
addKey(0, 'Undo', 'undo', 'Undo');

// other non-standard keys
addKey(216, 'BrightnessDown', 'brightnessdown', 'Brightness Down');
addKey(0, 'BrightnessUp', 'brightnessup', 'Brightness Up');
addKey(251, 'ZoomToggle', 'zoomtoggle', 'Zoom Toggle'); // CrOS

for (let i = 1; i <= 32; i++) addKey(111 + i, `F${i}`, `f${i}`, `F${i}`);
for (let i = 0; i < 10; i++) addKey(48 + i, `Digit${i}`, `${i}`, `${i}`);
for (let i = 0; i < 10; i++) addKey(96 + i, `Numpad${i}`, `num${i}`, `Numpad ${i}`);

const en = 'A B C D E F G H I J K L M N O P Q R S T U V W X Y Z'.split(' ');
for (let i = 0; i < en.length; i++) addKey(65 + i, `Key${en[i]}`, en[i].toLowerCase(), en[i]);

function parseKeys(value: string) {
  return value.trim().split(/\n\r?/g).map(x => x.split(' ').map(x => x.replace('_', ' ')));
}

function addLayout(id: keyof Locales, name: string, keys: string) {
  KEYBOARD_LAYOUTS.push({ id, name });
  const parsed = parseKeys(keys);

  for (let i = 0; i < parsed.length; i++) {
    for (let j = 0; j < parsed[i].length; j++) {
      const base = parsedBase[i][j];
      const locales = keyToNameMap.get(base) || { en: '???' };
      locales[id] = parsed[i][j];
      keyToNameMap.set(base, locales);
    }
  }
}

const parsedBase = parseKeys(`
\` 1 2 3 4 5 6 7 8 9 0 - = yen
q w e r t y u i o p [ ]
a s d f g h j k l ; ' \\
\\2 z x c v b n m , . / ro`);

addLayout('en', 'US standard', `
\` 1 2 3 4 5 6 7 8 9 0 - = yen
Q W E R T Y U I O P [ ]
A S D F G H J K L ; ' \\
Left_\\ Z X C V B N M , . / ro`);

addLayout('gb', 'United Kingdom', `
\` 1 2 3 4 5 6 7 8 9 0 - =
Q W E R T Y U I O P [ ]
A S D F G H J K L ; ' #
Left_\\ Z X C V B N M , . /`);

addLayout('ru', 'Russia/Belarus/Ukraine', `
Ë 1 2 3 4 5 6 7 8 9 0 - = \\
Й Ц У К Е Н Г Ш Щ З Х Ъ
Ф Ы В А П Р О Л Д Ж Э
Left_\\ Я Ч С М И Т Ь Б Ю .`);

addLayout('it', 'Italian', `
\\ 1 2 3 4 5 6 7 8 9 0 ' ì
Q W E R T Y U I O P è +
A S D F G H J K L ò à ù
< Z X C V B N M , . /`);

addLayout('es', 'Spanish', `
° 1 2 3 4 5 6 7 8 9 0 ' ¡
Q W E R T Y U I O P \` +
A S D F G H J K L Ñ ´ Ç
< Z X C V B N M , . -`);

addLayout('mx', 'Spanish (Latin America)', `
| 1 2 3 4 5 6 7 8 9 0 ' ¿
Q W E R T Y U I O P ´ +
A S D F G H J K L Ñ { }
< Z X C V B N M , . -`);

addLayout('pt', 'Portuguese', `
' 1 2 3 4 5 6 7 8 9 0 - =
Q W E R T Y U I O P ´ [
A S D F G H J K L Ç ~ ]
Left_\\ Z X C V B N M , . ; /`);

addLayout('de', 'German (QWERTZ)', `
^ 1 2 3 4 5 6 7 8 9 0 ß ´
Q W E R T Z U I O P Ü +
A S D F G H J K L Ö Ä #
< Y X C V B N M , . -`);

addLayout('cs', 'Czech (QWERTZ)', `
; 1 2 3 4 5 6 7 8 9 0 % ˇ
Q W E R T Z U I O P / (
A S D F G H J K L " ! '
< Y X C V B N M , . -`);

addLayout('sk', 'Slovak (QWERTZ)', `
; 1 2 3 4 5 6 7 8 9 0 % ˇ
Q W E R T Z U I O P / (
A S D F G H J K L " ! )
< Y X C V B N M , . -`);

addLayout('fr', 'French (AZERTY)', `
² 1 2 3 4 5 6 7 8 9 0 ) =
A Z E R T Y U I O P ^ $
Q S D F G H J K L M ù *
< W X C V B N , ; : !`);

addLayout('fi', 'Finland/Sweden/Norway', `
§ 1 2 3 4 5 6 7 8 9 0 + ´
Q W E R T Y U I O P Å ^
A S D F G H J K L Ø Æ '
< Z X C V B N M , . -`);

addLayout('ja', 'Japanese (日本語)', `
半角/全角 1 2 3 4 5 6 7 8 9 0 - ^ ¥
Q W E R T Y U I O P @ [
A S D F G H J K L ; : ]
Left_\\ Z X C V B N M , . / ろ`);

addLayout('kr', 'Korean (한국어)', `
\` 1 2 3 4 5 6 7 8 9 0 - = \\
Q W E R T Y U I O P [ ]
A S D F G H J K L ; ' \\
Left_\\ Z X C V B N M , . /`);

addLayout('el', 'Greek (Ελληνικά)', `
\` 1 2 3 4 5 6 7 8 9 0 - =
; ς Ε Ρ Τ Υ Θ Ι Ο Π [ ]
Α Σ Δ Φ Γ Η Ξ Κ Λ ä ' \\
< Ζ Χ Ψ Ω Β Ν Μ , . /`);

export enum Key {
  Backspace = 8,
  Tab = 9,
  Enter = 13,
  Shift = 16,
  Ctrl = 17,
  Alt = 18,
  CapsLock = 20,
  Esc = 27,
  Space = 32,
  PageUp = 33,
  PageDown = 34,
  End = 35,
  Home = 36,
  Left = 37,
  Up = 38,
  Right = 39,
  Down = 40,
  Delete = 46,
  A = 65,
  B = 66,
  C = 67,
  I = 73,
  J = 74,
  U = 85,
  V = 86,
  X = 88,
  Y = 89,
  Z = 90,
  Meta = 91,
}

export const enum MouseButton {
  Left = 0,
  Middle = 1,
  Right = 2,
}

export function keyToName(id: string): string {
  const names = keyToNameMap.get(id);
  if (!names) return id;
  if (isMac && names.mac) return names.mac;
  return (names as any)[keyboardLayout] || names.en;
}

export function isArrowKey(key: string) {
  return key === 'left' || key === 'up' || key === 'right' || key === 'down';
}

function isModifierKey(key: string) {
  return key === 'lctrl' || key === 'rctrl' || key === 'lshift' || key === 'rshift' ||
    key === 'lalt' || key === 'ralt' || key === 'lmeta' || key === 'rmeta' ||
    key === 'hyper' || key === 'super' || key === 'turbo'; // these seem to function like ctrl
}

export function isAzerty(layout: string) {
  return layout === 'fr';
}

export function isQwertz(layout: string) {
  return layout === 'de' || layout === 'cs' || layout === 'sk';
}

const IOS_KEYS = ['esc', 'up', 'left', 'right', 'down'];
const IOS_KEYS_MAP: { [key: string]: string | undefined; } = {
  UIKeyInputEscape: 'esc',
  UIKeyInputUpArrow: 'up',
  UIKeyInputLeftArrow: 'left',
  UIKeyInputRightArrow: 'right',
  UIKeyInputDownArrow: 'down',
};

export function keyFromEvent({ type, code, key, keyCode }: KeyboardEvent, stack?: (string | undefined)[]): string | undefined {
  // automatically switch layout between AZERTY/QWERTZ/QWERTY
  if (keyboardLayoutIsDefault) {
    let switchTo: string | undefined = undefined;

    if (isAzerty(keyboardLayout)) {
      if (code === 'KeyY' && (key === 'z' || key === 'Z')) switchTo = 'de';
      if (code === 'KeyZ' && (key === 'y' || key === 'Y')) switchTo = 'de';
      if (code === 'KeyQ' && (key === 'q' || key === 'Q')) switchTo = 'en';
      if (code === 'KeyA' && (key === 'a' || key === 'A')) switchTo = 'en';
      if (code === 'KeyW' && (key === 'w' || key === 'W')) switchTo = 'en';
      if (code === 'KeyZ' && (key === 'z' || key === 'Z')) switchTo = 'en';
    } else if (isQwertz(keyboardLayout)) {
      if (code === 'KeyY' && (key === 'y' || key === 'Y')) switchTo = 'en';
      if (code === 'KeyZ' && (key === 'z' || key === 'Z')) switchTo = 'en';
      if (code === 'KeyQ' && (key === 'a' || key === 'A')) switchTo = 'fr';
      if (code === 'KeyA' && (key === 'q' || key === 'Q')) switchTo = 'fr';
      if (code === 'KeyW' && (key === 'z' || key === 'Z')) switchTo = 'fr';
      if (code === 'KeyZ' && (key === 'w' || key === 'W')) switchTo = 'fr';
    } else { // QWERTY
      if (code === 'KeyY' && (key === 'z' || key === 'Z')) switchTo = 'de';
      if (code === 'KeyZ' && (key === 'y' || key === 'Y')) switchTo = 'de';
      if (code === 'KeyQ' && (key === 'a' || key === 'A')) switchTo = 'fr';
      if (code === 'KeyA' && (key === 'q' || key === 'Q')) switchTo = 'fr';
      if (code === 'KeyW' && (key === 'z' || key === 'Z')) switchTo = 'fr';
      if (code === 'KeyZ' && (key === 'w' || key === 'W')) switchTo = 'fr';
    }

    if (switchTo) {
      setKeyboardLayout(switchTo);
      keyboardLayoutIsDefault = true;
    }
  }

  let result: string | undefined;

  if (
    (code === 'ControlRight' && keyCode !== 17) ||
    (code === 'ControlLeft' && keyCode !== 17 && keyCode !== 162) ||
    (code === 'ShiftLeft' && keyCode !== 16) ||
    (code === 'AltLeft' && keyCode !== 18 && keyCode !== 164)
  ) {
    // some tablets incorrectly send simulated keyboard shortcuts, use keyCode instead
    result = keyCodeToKey.get(keyCode);
  } else if (code && code !== 'Hyper' && code !== 'Unidentified' && codeToKey.has(code)) {
    // Hyper seems to show sometimes for regular keys for no reason
    result = codeToKey.get(code);
  } else if (isiOS && !keyCode && type === 'keyup') {
    // on iOS bluetooth keyboard keyCode is always 0 on keyup
    result = IOS_KEYS_MAP[key];

    if (!result && stack) {
      result = stack.pop();
    }
  } else {
    result = keyCodeToKey.get(keyCode);
  }

  if (isiOS && stack && result) {
    if (!includes(stack, result) && !includes(IOS_KEYS, result)) {
      stack.push(result);
    }
  }

  return result;
}

export function shortcutFromEvent(e: KeyboardEvent): string | undefined {
  let key = keyFromEvent(e);

  if (!key || isModifierKey(key)) return undefined;

  if (e.shiftKey) key = `shift+${key}`;
  if (e.altKey) key = `alt+${key}`;
  if (e.ctrlKey) key = `ctrl+${key}`;
  if (e.metaKey) key = `cmd+${key}`;

  return key;
}

const shortcutsCache = new Map<string, string>(); // TODO: clear when changing locale

export function shortcutToTitle(value: string) {
  let result = shortcutsCache.get(value);

  if (!result) {
    let offset = 0;
    const alt = isMac ? ALT_KEY : 'Alt';
    const shift = isMac ? SHIFT_KEY : 'Shift';
    result = '';
    if (value.indexOf('cmd+') !== -1) { result += `${CMD_KEY}+`; offset += 4; }
    if (value.indexOf('ctrl+') !== -1) { result += `Ctrl+`; offset += 5; }
    if (value.indexOf('alt+') !== -1) { result += `${alt}+`; offset += 4; }
    if (value.indexOf('shift+') !== -1) { result += `${shift}+`; offset += 6; }
    result += keyToName(value.substring(offset));
    shortcutsCache.set(value, result);
  }

  return result;
}

export function updateShortcut(element: HTMLElement, value: string | undefined) {
  removeAllNodes(element);

  if (!value) return;

  let offset = 0;
  const alt = isMac ? ALT_KEY : 'Alt';
  const shift = isMac ? SHIFT_KEY : 'Shift';

  if (value.indexOf('cmd+') !== -1) { appendModifier(element, CMD_KEY); offset += 4; }
  if (value.indexOf('ctrl+') !== -1) { appendModifier(element, 'Ctrl'); offset += 5; }
  if (value.indexOf('alt+') !== -1) { appendModifier(element, alt); offset += 4; }
  if (value.indexOf('shift+') !== -1) { appendModifier(element, shift); offset += 6; }

  const key = value.substring(offset);
  appendKbd(element, keyToName(key)); // TODO: use arrows / other special symbols for special keys ?
}

function appendKbd(element: HTMLElement, text: string) {
  const kbd = document.createElement('kbd');
  appendText(kbd, text);
  if (text === CMD_KEY) kbd.title = 'Mac Command key';
  element.appendChild(kbd);
}

function appendModifier(element: HTMLElement, text: string) {
  appendKbd(element, text);
  appendText(element, ' + ');
}

export const keyboarLayoutChanged = new Subject<void>();

export function setKeyboardLayout(layoutId: string | undefined) {
  keyboardLayout = layoutId ?? getDefaultLayout();
  keyboardLayoutIsDefault = !layoutId;
  shortcutsCache.clear();
  keyboarLayoutChanged.next();
}

export interface MagmaKeyboardEvent extends KeyboardEvent {
  allowKeyboardService?: boolean;
}
