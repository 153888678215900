import { truncate } from 'lodash';
import { BitmapData, CompositeOp, CopyMode, Cursor, CursorsMode, CursorType, defaultDrawingPermissions, Drawing, DrawingDataFlags, DrawOptions, ExtraLoader, HistoryBufferEntry, ICanvasProvider, IErrorReporter, IFiltersValues, IRenderer, Layer, Mask, Mat2d, Rect, RendererApi, RendererSettings, TextLayer, ToolId, ToolSurface, User, Vec2, Viewport } from '../common/interfaces';
import { findByLocalId, findIndexById, getPixelRatio, removeAtFast } from '../common/utils';
import { clamp, distance, round5 } from '../common/mathUtils';
import { invalidEnum, invalidEnumReturn, quadraticEasing } from '../common/baseUtils';
import { applyTransform, clearRect, clearRectRect, createCanvas, defaultImageLoaders, drawImage, drawImageRect, drawImageRectShifted, fillRect, getBlendMode, getContext2d, getPixelContext, imageDataToBitmapData, textWidth } from '../common/canvasUtils';
import { isLayerVisible, isTextLayer, layerChanged, loadLayerImages, redrawLayerThumb, shouldRedrawLayerThumb } from '../common/layer';
import { addRect, clipRect, cloneRect, copyRect, createRect, haveNonEmptyIntersection, intersectRect, isIntegerRect, isRectEmpty, makeIntegerRect, outsetRect, rectContainsRect, rectContainsXY, rectsIntersection, rectToString, resetRect, scaleRect, setRect, rectIncludesRect } from '../common/rect';
import { applyViewportTransform, createViewportMatrix2d, documentToScreenPoint, documentToScreenXY, screenToDocumentRect } from '../common/viewport';
import { getSurfaceBounds, getTransformBounds, getTransformedRectBounds, getTransformOrigin, hasZeroTransform, isSurfaceEmpty, rectToBounds, resetSurface, transformBounds } from '../common/toolSurface';
import { createRenderingContext, fillRectWithPattern, fillWithCanvasPattern } from './renderingContext';
import { CURSOR_AVATAR_LARGE_HEIGHT, CURSOR_VIDEO_HEIGHT, DEFAULT_FONT, LAYER_THUMB_SIZE, SEQUENCE_THUMB_HEIGHT, SEQUENCE_THUMB_WIDTH, SHOW_CURSOR_UNMOVING_TIMEOUT, SHOW_CURSORS, USER_CURSOR_RADIUS, USER_NAME_HEIGHT, USER_NAME_OFFSET, USER_NAME_WIDTH, WHITE, WHITE_STR } from '../common/constants';
import { pointToSurface } from '../common/selectionUtils';
import { copyPoint, createPoint, setPoint } from '../common/point';
import { isPolySegmentEmpty } from '../common/poly';
import { colorFromRGBA, colorToCSS, rgbToGray } from '../common/color';
import { clipMask, cloneMask, createMask, cutMaskFromRect, fillMask, isMaskEmpty, isMaskingWholeRect, rectMaskIntersectionBoundsInt, transformAndClipMask, transformMask } from '../common/mask';
import { createMat2d, getMat2dX, getMat2dY, isMat2dIdentity, isMat2dIntegerTranslation, isMat2dTranslation, multiplyMat2d } from '../common/mat2d';
import { cloneBounds, createVec2, createVec2FromValues, outsetBounds, setVec2, transformVec2ByMat2d } from '../common/vec2';
import { SelectionTool } from '../common/tools/selectionTool';
import { getLayerRect, isLayerEmpty, layerHasNonEmptyToolSurface } from '../common/layerUtils';
import { LassoSelectionTool } from '../common/tools/lassoSelectionTool';
import { CircleSelectionTool } from '../common/tools/circleSelectionTool';
import { hasDrawingRole } from '../common/userRole';
import { pickRegion } from '../common/tools/transformTool';
import { DRAW_TEXTURE_RECT, drawTextareaTextureRect, preprocessTextLayersForDrawing, shouldRenderTextareaBaselineIndicator, shouldRenderTextareaBoundaries, shouldRenderTextareaControlPoints, shouldRenderTextareaCursor, shouldRenderTextareaOverflowIndicator, TextTool, TextToolMode } from '../common/tools/textTool';
import { AutoWidthTextarea, getBaselineIndicatorAlignmentSquareSize, MAX_TEXTAREA_CURSOR_WIDTH, MIN_TEXTAREA_CURSOR_WIDTH, Textarea, TEXTAREA_BASELINE_INDICATOR_HOVERED_LINE_THICKNESS, TEXTAREA_BASELINE_INDICATOR_UNHOVERED_LINE_THICKNESS, TEXTAREA_BOUNDARIES_COLOR, TEXTAREA_HOVERED_BOUNDARIES_WIDTH, TEXTAREA_OVERFLOW_INDICATOR_PLUS_SIZE, TEXTAREA_OVERFLOW_INDICATOR_PLUS_THICKNESS, TEXTAREA_OVERFLOW_INDICATOR_RED_STR, TEXTAREA_OVERFLOW_INDICATOR_SQUARE_SIZE, TEXTAREA_SELECTION_RECT_COLOR, TEXTAREA_UNHOVERED_BOUNDARIES_WIDTH, TextareaType } from '../common/text/textarea';
import { applyHueSaturationLightness } from '../common/hueSaturationLightness';
import { applyBrightnessContrast } from '../common/brightnessContrast';
import { clipToDrawingRect } from '../common/drawing';
import { AiTool } from '../common/tools/aiTool';
import { applyCurves } from '../common/curves';
import { toolIncompatibleWithTextLayers } from '../common/update';
import { cacheTextareaInLayer, canDrawTextLayer, shouldCacheTextareaInLayer, shouldDrawTextarea } from '../common/text/text-utils';
import { logActionInDebug } from '../common/actionLog';
import { AI_BOUNDING_BOX_COLOR_1_ACTIVE_STR, AI_BOUNDING_BOX_COLOR_2_ACTIVE_STR, AI_BOUNDING_BOX_MASK_ALPHA, AI_SELECTION_COLOR_1, getAiSelectionPatternCanvas, updateAiMaskTransform } from '../common/aiInterfaces';
import { applyGaussianBlur } from '../common/gaussianBlur';


type Canvas = HTMLCanvasElement;
type Context = CanvasRenderingContext2D;

const tempPt = createPoint(0, 0);

let noSelfVideoTime = 0;

export function smoothScaling(settings: RendererSettings, view: Viewport) {
  return !settings.sharpZoom || view.scale < (view.rotation ? 6 : 3);
}

export function smoothScalingWebgl(settings: RendererSettings, view: Viewport) {
  return !settings.sharpZoom || view.scale < 3;
}

export class Renderer implements IRenderer {
  name: RendererApi;
  canvas: HTMLCanvasElement | undefined = undefined;
  private tempCanvas: HTMLCanvasElement | undefined;
  private tempCanvasContext: CanvasRenderingContext2D | undefined;
  private tempDOMMatrix: DOMMatrix | undefined;
  private pattern: CanvasPattern | undefined = undefined;
  private _tempContext: Context | undefined = undefined;
  private context: CanvasRenderingContext2D | undefined = undefined;
  private drawingWidth = 0;
  private drawingHeight = 0;
  private drawingRect = createRect(0, 0, 0, 0);
  private emptySelection = createMask();

  constructor(public id: string, afterFail: boolean, private canvasProvider: ICanvasProvider, private errorReporter: IErrorReporter) {
    this.name = afterFail ? '2d-fail' : '2d-off';
  }
  addRedrawRect(user: User, targetDirtyRect: Rect, _options: DrawOptions) {
    let status = false;
    if (_options.settings.includeVideo) {
      if (user.avatarVideo) {
        addRect(targetDirtyRect, rectForCursorVideo({ x: user.cursorX, y: user.cursorY }, user.avatarVideo));
        status = true;
      }
      for (const user of _options.users) {
        if (user.avatarVideo) {
          addRect(targetDirtyRect, rectForCursorVideo({ x: user.cursorX, y: user.cursorY }, user.avatarVideo));
          status = true;
        }
      }
    }
    return status;
  }
  canvases() {
    return this.canvasProvider.used;
  }
  stats() {
    return this.canvasProvider.stats;
  }
  init(drawing: Drawing, canvas?: HTMLCanvasElement) {
    this.releaseTemp();
    this.drawingWidth = drawing.width;
    this.drawingHeight = drawing.height;
    this.drawingRect.w = drawing.width;
    this.drawingRect.h = drawing.height;

    if (canvas) {
      this.canvas = canvas;
      this.context = getContext2d(canvas, {
        alpha: false,
        desynchronized: true,
        preserveDrawingBuffer: true,
      });
    }
  }
  release() {
    this.releaseTemp();
    this.context = undefined;
  }
  releaseTemp() {
    if (this._tempContext) {
      this.canvasProvider.release(this._tempContext.canvas);
      this._tempContext = undefined;
    }
  }
  private get tempContext() {
    if (!this._tempContext) {
      this._tempContext = getContext2d(this.canvasProvider.create(`${this.id}.tempCanvas`, this.drawingWidth, this.drawingHeight));
    }

    return this._tempContext;
  }
  releaseLayer(layer: Layer | undefined) {
    if (layer) {
      layer.canvas = this.canvasProvider.release(layer.canvas);
      resetRect(layer.rect);
      layerChanged(layer);
    }
  }
  releaseDrawing(drawing: Drawing) {
    drawing.canvas = this.canvasProvider.release(drawing.canvas);
    drawing.layers.forEach(l => this.releaseLayer(l));
  }
  // used in paintbucket tool
  getDrawingImageData(drawing: Drawing, flags: DrawingDataFlags) {
    if (flags === DrawingDataFlags.NoBackground) {
      const canvas = this.createSurface('temp', drawing.width, drawing.height);
      const temp: Drawing = { ...drawing, background: '', canvas };
      this.drawDrawing(temp, temp.rect);
      const data = getContext2d(canvas).getImageData(0, 0, canvas.width, canvas.height);
      this.releaseSurface(canvas);
      return data;
    } else if (drawing.canvas) {
      return getContext2d(drawing.canvas).getImageData(0, 0, drawing.width, drawing.height);
    } else {
      DEVELOPMENT && console.error('getDrawingImageData(): Missing drawing canvas');
      return this.createImageData(drawing.width, drawing.height, undefined);
    }
  }
  // used in paintbucket tool
  getLayerImageData(layer: Layer) {
    if (layer.canvas) {
      return getContext2d(layer.canvas).getImageData(0, 0, this.drawingWidth, this.drawingHeight);
    } else {
      return this.createImageData(this.drawingWidth, this.drawingHeight, undefined);
    }
  }
  // used for exporting to PSD
  getDrawingThumbnail(drawing: Drawing, maxSize: number): HTMLCanvasElement {
    if (!drawing.canvas) throw new Error('Drawing canvas is not initialized');

    let width = 0, height = 0;

    if (drawing.width > drawing.height) {
      width = maxSize;
      height = Math.max(Math.floor(drawing.height * (width / drawing.width)), 1);
    } else {
      height = maxSize;
      width = Math.max(Math.floor(drawing.width * (height / drawing.height)), 1);
    }

    const canvas = createCanvas(width, height);
    const context = getContext2d(canvas);
    context.drawImage(drawing.canvas, 0, 0, drawing.width, drawing.height, 0, 0, width, height);
    return canvas;
  }
  // used in worker service and psd sync
  getLayerRawData(layer: Layer): BitmapData {
    if (!layer.canvas || isRectEmpty(layer.rect)) throw new Error('Layer is empty');

    const { x, y, w, h } = layer.rect;
    const imageData = getContext2d(layer.canvas).getImageData(x, y, w, h);
    return imageDataToBitmapData(imageData);
  }
  // used in paste / paintbucket
  createImageData(width: number, height: number, data: Uint8ClampedArray | undefined) {
    if (width === 0 || height === 0) throw new Error(`Invalid size for image data (${width}x${height})`);
    const imageData = getPixelContext().createImageData(width, height);
    if (data) imageData.data.set(data);
    return imageData;
  }
  // used in paste (not used at the moment)
  putImage(user: User, image: HTMLImageElement | HTMLCanvasElement | ImageBitmap, x = 0, y = 0) {
    this.ensureSurface(user.surface, image.width, image.height);
    const context = getContext2d(user.surface.canvas!);
    const w = image.width;
    const h = image.height;
    drawImage(context, image, 0, 0, w, h, x, y, w, h);
    if (user.surface.layer) redrawLayerThumb(user.surface.layer);
  }
  // used in paste / paintbucket
  putImageData(user: User, image: ImageData, x = 0, y = 0) {
    this.ensureSurface(user.surface, image.width, image.height);
    const context = getContext2d(user.surface.canvas!);
    context.putImageData(image, x, y);
    if (user.surface.layer) redrawLayerThumb(user.surface.layer);
  }
  loadLayerImages(drawing: Drawing, extraLoader?: ExtraLoader, onProgress?: (progress: number) => void, ignoreErrors = false) {
    return loadLayerImages(
      drawing, defaultImageLoaders(extraLoader),
      (layer, img) => this.initLayer(layer, img), onProgress, ignoreErrors);
  }
  private ensureSurface(surface: ToolSurface, minWidth: number, minHeight: number) {
    const width = Math.max(this.drawingWidth, minWidth);
    const height = Math.max(this.drawingHeight, minHeight);

    if (!surface.canvas) {
      surface.canvas = this.canvasProvider.create('toolCanvas', width, height);
    } else if (surface.canvas.width !== width || surface.canvas.height !== height) {
      // this can happen when pasting image larger than canvas
      surface.canvas.width = width;
      surface.canvas.height = height;
    } else if (SERVER) {
      // this is less expensive in node-canvas
      const context = getContext2d(surface.canvas);
      context.clearRect(0, 0, surface.canvas.width, surface.canvas.height);
    } else {
      surface.canvas.width = surface.canvas.width;
    }
  }
  releaseUserCanvas({ surface }: User) {
    if (DEVELOPMENT && surface.context)
      throw new Error('Rendering context not released');

    if (surface.layer && !isSurfaceEmpty(surface)) {
      redrawLayerThumb(surface.layer, true);
    }

    surface.canvas = this.canvasProvider.release(surface.canvas);
    resetSurface(surface);
  }
  initLayer(layer: Layer, image: HTMLImageElement | ImageBitmap) {
    if (!image) return;

    this.ensureLayerCanvas(layer);

    fixLayerRect(layer, image, this.errorReporter);
    getContext2d(layer.canvas!).drawImage(image, layer.rect.x, layer.rect.y);
  }
  // used only for tests
  initLayerFromBitmap(layer: Layer, image: BitmapData) {
    this.ensureLayerCanvas(layer);

    const context = getContext2d(layer.canvas!);
    const imageData = context.createImageData(image.width, image.height);
    imageData.data.set(new Uint8ClampedArray(image.data.buffer, image.data.byteOffset, image.data.byteLength));
    context.putImageData(imageData, layer.rect.x, layer.rect.y);
  }
  // used in copy and save, returns data for full size or selection bounds
  getLayerSnapshot(layer: Layer, selection?: Mask, outBounds?: Rect) {
    const width = this.drawingWidth;
    const height = this.drawingHeight;
    const layerRect = getLayerRect(layer);
    clipRect(layerRect, 0, 0, width, height);
    const bounds = selection ? rectsIntersection(selection.bounds, layerRect) : createRect(0, 0, width, height);
    clipRect(bounds, 0, 0, width, height);

    if (isRectEmpty(bounds)) return undefined;
    if (!layerHasNonEmptyToolSurface(layer) && !layer.canvas) return undefined;

    let canvas: HTMLCanvasElement | undefined = undefined;

    if (layerHasNonEmptyToolSurface(layer)) {
      canvas = this.canvasProvider.create('getLayerSnapshot', this.drawingWidth, this.drawingHeight);
      const context = getContext2d(canvas);
      this.drawLayer(this.tempContext, context, layer, createRect(0, 0, canvas.width, canvas.height), false, true, true);
    } else {
      canvas = layer.canvas!;
    }

    if (selection) {
      outBounds && copyRect(outBounds, bounds);
      this.tempContext.drawImage(canvas, 0, 0);
      clipTo(this.tempContext, selection);
      const final = createCanvas(bounds.w, bounds.h);
      const finalContext = getContext2d(final);
      finalContext.drawImage(this.tempContext.canvas, -bounds.x, -bounds.y);
      canvas = final;
    } else {
      outBounds && setRect(outBounds, 0, 0, width, height);
    }

    return canvas;
  }
  getDrawingSnapshot(drawing: Drawing, selection?: Mask) {
    const { width, height } = drawing;
    const bounds = selection ? rectsIntersection(selection.bounds, drawing.rect) : createRect(0, 0, width, height);
    clipRect(bounds, 0, 0, width, height);

    if (isRectEmpty(bounds)) return undefined;

    const canvas = createCanvas(bounds.w, bounds.h);
    const context = getContext2d(canvas);

    if (drawing.background) {
      fillRect(context, drawing.background, 0, 0, canvas.width, canvas.height);
    }

    const drawingCanvas = createCanvas(width, height);
    preprocessTextLayersForDrawing(drawing, (l) => this.drawTextLayer(l, drawing), true);
    this.drawLayers(getContext2d(drawingCanvas), drawing.background, drawing.layers, bounds, this.tempContext, this.canvasProvider);

    if (selection) {
      const temp = createCanvas(drawing.width, drawing.height);
      const tempContext = getContext2d(temp);
      tempContext.drawImage(drawingCanvas, 0, 0);
      clipTo(tempContext, selection);
      context.drawImage(temp, -bounds.x, -bounds.y);
    } else {
      context.drawImage(drawingCanvas, -bounds.x, -bounds.y);
    }

    return canvas;
  }
  getDrawingCanvasForImageData(drawing: Drawing) {
    return drawing.canvas;
  }
  // used in save (psd)
  getLayerCanvasForImageData(drawing: Drawing, layer: Layer) {
    if (isLayerEmpty(layer)) return { rect: createRect(0, 0, 0, 0), canvas: undefined };

    const rect = cloneRect(getLayerRect(layer));
    clipRect(rect, 0, 0, drawing.width, drawing.height);
    const canvas = createCanvas(rect.w, rect.h);
    const context = getContext2d(canvas);
    context.save();
    context.translate(-rect.x, -rect.y);
    if (layer.canvas) context.drawImage(layer.canvas, 0, 0);
    if (layerHasNonEmptyToolSurface(layer)) drawToolSurface(context, layer.owner!.surface);
    context.restore();
    return { rect, canvas };
  }
  // used in history
  createSurface(id: string, width: number, height: number) {
    return this.canvasProvider.create(id, Math.max(this.drawingWidth, width), Math.max(this.drawingHeight, height));
  }
  // used in history
  releaseSurface(canvas: HTMLCanvasElement | undefined) {
    return this.canvasProvider.release(canvas);
  }
  // used in history
  copyToSnapshot(
    src: HTMLCanvasElement, dst: HTMLCanvasElement, sx: number, sy: number, w: number, h: number, dx: number, dy: number
  ) {
    const context = getContext2d(dst);
    context.globalAlpha = 1;
    context.globalCompositeOperation = 'source-over';
    clearRect(context, dx, dy, w, h);
    drawImage(context, src, sx, sy, w, h, dx, dy, w, h);
  }
  // used in history
  restoreSnapshotToLayer(entry: HistoryBufferEntry | undefined, layer: Layer, layerRect: Rect) {
    if (isRectEmpty(layerRect)) {
      this.releaseLayer(layer);
      return;
    }

    this.ensureLayerCanvas(layer);
    copyRect(layer.rect, layerRect);

    if (entry) {
      const { sheet, x, y, rect } = entry;
      this.copyToSnapshot(sheet.surface as HTMLCanvasElement, layer.canvas!, x, y, rect.w, rect.h, rect.x, rect.y);
    }

    layerChanged(layer);
  }
  // used in history
  restoreSnapshotToTool({ sheet, x, y, rect }: HistoryBufferEntry, user: User) {
    this.ensureSurface(user.surface, rect.x + rect.w, rect.y + rect.h);
    this.copyToSnapshot(sheet.surface as HTMLCanvasElement, user.surface.canvas!, x, y, rect.w, rect.h, rect.x, rect.y);
  }
  commitTool(user: User, lockOpacity: boolean) {
    const layer = user.activeLayer;
    if (!layer) throw new Error('No active layer');

    this.commitToolOnLayer(user, layer, lockOpacity);

    this.releaseUserCanvas(user);
  }
  commitToolOnLayer(user: User, layer: Layer, lockOpacity: boolean): void {
    const { surface } = user;
    const selection = surface.ignoreSelection ? this.emptySelection : user.selection;

    if (DEVELOPMENT && surface.context) throw new Error('Tool context not released');

    if (surface.mode === CompositeOp.None) throw new Error('Invalid surface operation');
    if (DEVELOPMENT && layer.owner !== user) throw new Error('Commiting tool to layer with different owner');
    if (!surface.canvas) throw new Error('Missing surface canvas');

    const bounds = getSurfaceBounds(surface);
    clipRect(bounds, 0, 0, this.drawingWidth, this.drawingHeight);

    if (surface.mode !== CompositeOp.Move && !isMaskEmpty(selection)) {
      copyRect(bounds, rectMaskIntersectionBoundsInt(bounds, selection));
    }

    const canSkip = isRectEmpty(bounds) ||
      (surface.mode === CompositeOp.Erase && isRectEmpty(layer.rect)) ||
      (surface.mode === CompositeOp.Erase && !haveNonEmptyIntersection(bounds, layer.rect));

    if (!canSkip) {
      this.ensureLayerCanvas(layer);
      clearRectRect(this.tempContext, bounds);

      if (composeOp(this.tempContext, layer.canvas!, layer.rect, lockOpacity, selection, surface)) {
        const context = getContext2d(layer.canvas!);
        clearRectRect(context, bounds);
        drawImageRect(context, this.tempContext.canvas, bounds);

        if (surface.mode !== CompositeOp.Erase && !(surface.mode === CompositeOp.Draw && lockOpacity)) {
          addRect(layer.rect, bounds);
        }
      }
    }

    if (isRectEmpty(layer.rect)) this.releaseLayer(layer);
    layerChanged(layer);
  }
  commitToolTransform(user: User) {
    const surface = user.surface;

    if (!surface.layer) throw new Error('Missing surface layer');
    if (DEVELOPMENT && surface.context) throw new Error('Tool context not released');

    if (!isSurfaceEmpty(surface)) {
      if (!surface.canvas) throw new Error('Missing surface canvas');
      if (DEVELOPMENT && surface.layer.owner !== user) throw new Error('Commiting tool to layer with different owner');

      const context = this.getLayerContext(surface.layer);

      if (!hasZeroTransform(surface)) {
        context.save();
        context.globalAlpha = surface.opacity;
        applyTransform(context, surface.transform);
        drawImageRect(context, surface.canvas, surface.rect);
        context.restore();
      }

      const bounds = getSurfaceBounds(surface);
      clipRect(bounds, 0, 0, this.drawingWidth, this.drawingHeight);
      addRect(surface.layer.rect, bounds);
      layerChanged(surface.layer);
    }

    transformAndClipMask(user.selection, surface);
    this.releaseUserCanvas(user);
  }
  // Assumes no active tool surface
  mergeLayers(src: Layer, dst: Layer, clip: boolean) {
    if (layerHasTool(src) || layerHasTool(dst)) throw new Error('Cannot merge layers with active tool');

    if (
      src.canvas && src.opacity !== 0 && !isRectEmpty(src.rect) &&
      !(clip && isRectEmpty(rectsIntersection(dst.rect, src.rect)))
    ) {
      let oldCanvas: HTMLCanvasElement | undefined;

      if (dst.canvas && dst.opacity !== 1) {
        oldCanvas = dst.canvas;
        dst.canvas = undefined;
      }

      const context = this.getLayerContext(dst);

      if (oldCanvas) {
        context.globalAlpha = dst.opacity;
        context.drawImage(oldCanvas, 0, 0);
        context.globalAlpha = 1;
        this.canvasProvider.release(oldCanvas);
      }

      if (clip) {
        const clippedRect = rectsIntersection(dst.rect, src.rect);

        if (!isRectEmpty(clippedRect)) {
          const clipCanvas = this.canvasProvider.create('clipCanvas', this.drawingWidth, this.drawingHeight);
          const clipContext = getContext2d(clipCanvas);

          clipContext.globalCompositeOperation = 'source-over';
          this.drawLayer(this.tempContext, clipContext, src, clippedRect, true);
          clipContext.globalCompositeOperation = 'destination-in';
          this.drawLayer(this.tempContext, clipContext, dst, clippedRect, true);

          context.globalCompositeOperation = getBlendMode(src.mode);
          drawImageRect(context, clipCanvas, clippedRect);
          context.globalCompositeOperation = 'source-over';

          this.canvasProvider.release(clipCanvas);
        }
      } else {
        drawLayerInternal(context, src.canvas, src, src.rect, false);
      }

      if (!clip) addRect(dst.rect, src.rect);
      dst.changed = true;
      dst.opacity = 1;
      redrawLayerThumb(dst, true);
    }

    this.releaseLayer(src);
    redrawLayerThumb(src, true);
  }
  splitLayer(surface: ToolSurface, layer: Layer, selection: Mask) {
    this.ensureSurface(surface, this.drawingWidth, this.drawingHeight); // TODO: don't init if not needed (have to make sure it doesn't break anything)

    const rect = cloneRect(layer.rect);
    if (!isMaskEmpty(selection)) intersectRect(rect, selection.bounds);

    if (!layer.canvas || isRectEmpty(rect)) return;

    if (isMaskEmpty(selection) || isMaskingWholeRect(layer.rect, selection)) {
      // TODO: just switch canvas ?
      drawImageRect(getContext2d(surface.canvas!), layer.canvas, layer.rect);
      clearRect(getContext2d(layer.canvas), layer.rect.x, layer.rect.y, layer.rect.w, layer.rect.h);
      this.releaseLayer(layer);
    } else {
      // TODO: can use selection poly to calculate both bounds precisely

      // TODO: use selection.bounds & layer.rect ?
      const context = getContext2d(surface.canvas!);
      drawImageRect(context, layer.canvas, layer.rect);
      context.globalCompositeOperation = 'destination-in';
      fillMask(context, selection);
      context.globalCompositeOperation = 'source-over';

      // TODO: use selection.bounds & layer.rect ?
      this.ensureLayerCanvas(layer);
      const context2 = getContext2d(layer.canvas);
      context2.globalCompositeOperation = 'destination-out';
      fillMask(context2, selection);
      context2.globalCompositeOperation = 'source-over';
      cutMaskFromRect(layer.rect, selection);
      layerChanged(layer);
    }

    copyRect(surface.rect, rect);
  }
  // Assumes no active tool surface
  cutLayer(src: Layer, selection: Mask) {
    if (!src.canvas || isRectEmpty(src.rect)) return;
    if (isMaskEmpty(selection)) return;

    clipOut(getContext2d(src.canvas), selection);
    cutMaskFromRect(src.rect, selection);
    if (isRectEmpty(src.rect)) this.releaseLayer(src);
    layerChanged(src);
  }
  copyLayerToSurface(layer: Layer, surface: ToolSurface) {
    if (!layer.canvas) throw new Error('Layer Missing Canvas');
    this.ensureSurface(surface, this.drawingWidth, this.drawingHeight);
    drawImageRect(getContext2d(surface.canvas!), layer.canvas, layer.rect);
  }
  // Assumes no active tool surface and empty `dst` layer
  copyLayer(src: Layer, dst: Layer, selection: Mask | undefined, copyMode: CopyMode) {
    if (layerHasTool(src) || layerHasTool(dst)) throw new Error('Cannot copy layers with active tool');
    if (DEVELOPMENT && (dst.canvas || !isRectEmpty(dst.rect))) throw new Error('Destination layer is not empty');
    if (!src.canvas || isRectEmpty(src.rect)) return;

    if (selection) {
      const rect = rectMaskIntersectionBoundsInt(src.rect, selection);

      if (isRectEmpty(rect)) return;

      if (copyMode === CopyMode.Copy || copyMode === CopyMode.Cut) {
        this.ensureLayerCanvas(dst);
        const context = getContext2d(dst.canvas!);
        drawImageRect(context, src.canvas, selection.bounds);
        clipTo(context, selection);

        if (copyMode === CopyMode.Cut) {
          clipOut(getContext2d(src.canvas), selection);
          cutMaskFromRect(src.rect, selection);
          if (isRectEmpty(src.rect)) this.releaseLayer(src);
          layerChanged(src, true);
        }

        copyRect(dst.rect, rect);
      } else {
        invalidEnum(copyMode);
      }

      if (isRectEmpty(dst.rect)) this.releaseLayer(dst);
      layerChanged(dst);
    } else {
      if (copyMode === CopyMode.Copy) { // copy entire layer
        this.ensureLayerCanvas(dst);
        getContext2d(dst.canvas!).drawImage(src.canvas, 0, 0);
        copyRect(dst.rect, src.rect);
        layerChanged(dst);
      } else {
        throw new Error('Invalid copyMode');
      }
    }
  }
  drawDrawing(drawing: Drawing, rect: Rect) {
    if (!drawing.canvas) drawing.canvas = this.canvasProvider.create('drawing', drawing.width, drawing.height);

    preprocessTextLayersForDrawing(drawing, (layer) => {
      this.drawTextLayer(layer, drawing);
    });

    this.drawLayers(getContext2d(drawing.canvas), drawing.background, drawing.layers, rect, this.tempContext, this.canvasProvider);
  }
  private drawLayers(
    context: Context, background: string | undefined, layers: Layer[], r: Rect,
    tempContext: Context, canvasProvider: ICanvasProvider
  ) {
    const { width, height } = context.canvas;

    r = cloneRect(r);
    makeIntegerRect(r);
    clipRect(r, 0, 0, width, height);

    if (isRectEmpty(r)) return;

    if (background) {
      fillRect(context, background, r.x, r.y, r.w, r.h);
    } else {
      clearRect(context, r.x, r.y, r.w, r.h);
    }

    for (let i = layers.length - 1; i >= 0; i--) {
      if (!layerVisibleWithCanvasOrTool(layers[i])) continue;

      if (isClippingLayerWithVisibleClippedLayers(i, layers)) {
        const clippingLayer = layers[i];

        // TODO: just use clippingLayer.canvas if no tool
        const clippingCanvas = canvasProvider.create('clipping', width, height);
        this.drawLayer(tempContext, getContext2d(clippingCanvas), clippingLayer, r, true, false, true);

        const clippedCanvas = canvasProvider.create('clipped', width, height);
        const clippedContext = getContext2d(clippedCanvas);
        drawImageRect(clippedContext, clippingCanvas, r);

        for (; i > 0 && layers[i - 1].clippingGroup; i--) {
          if (layerVisibleWithCanvasOrTool(layers[i - 1])) {
            this.drawLayer(tempContext, clippedContext, layers[i - 1], r);
          }
        }

        // TODO: this is still wrong when clippingLayer alpha !== 1
        clippedContext.globalCompositeOperation = 'destination-in';
        drawImageRect(clippedContext, clippingCanvas, r);
        clippedContext.globalCompositeOperation = 'source-over';

        context.globalAlpha = clippingLayer.opacity;
        context.globalCompositeOperation = getBlendMode(clippingLayer.mode);
        drawImageRect(context, clippedCanvas, r);
        context.globalAlpha = 1;
        context.globalCompositeOperation = 'source-over';

        canvasProvider.release(clippingCanvas);
        canvasProvider.release(clippedCanvas);
      } else {
        this.drawLayer(tempContext, context, layers[i], r, false);
      }
    }
  }
  // -----------------------------------------------------------------------------
  // TODO:
  //
  //  tool | clip     || temp |
  // ------+----------++------+-----------------------
  //  no   | none     || no   | blend on context
  //  no   | clip     || yes  | draw on temp -> clip -> blend on context (if normal layer can skip temp layer clipping)
  //  no   | clipping || no   | draw on context
  //  yes  | none     || yes  | composite on temp -> blend on context
  //  yes  | clip     || yes  | composite on temp -> clip -> blend on context
  //  yes  | clipping || no   | composite on context
  //
  // + brush composite mode
  // tool -> tool & tool.intersects(dirtyRect)
  // need special handling for normal layers to improve quality on edges
  //
  private drawLayer(
    tempContext: Context, context: Context, layer: Layer, r: Rect,
    clip = false, force = false, noOpacity = false
  ) {
    if (!force && (!isLayerVisible(layer) || isRectEmpty(r))) return;

    const user = layer.owner;
    const layerRect = rectsIntersection(layer.rect, r);

    // TODO: check if layerRect is empty ?

    if (user && user.surface.layer === layer && !isSurfaceEmpty(user.surface) && user.surface.mode !== CompositeOp.None) {
      //var toolRect = surface.rect.intersection(r); use .getToolRect()
      const surface = user.surface;

      if (!surface.canvas) throw new Error('No surface canvas');

      // TODO: check if 'r' intersects with surface bounds, skip compose if it doesn't

      clearRectRect(tempContext, r);

      const selection = surface.ignoreSelection ? this.emptySelection : user.selection;
      if (composeOp(tempContext, layer.canvas, layerRect, layer.opacityLocked, selection, surface)) {
        drawLayerInternal(context, tempContext.canvas, layer, r, clip, noOpacity);
        return;
      }
    }

    drawLayerInternal(context, layer.canvas, layer, layerRect, clip, noOpacity);
  }
  drawTextLayer(layer: TextLayer, drawing: Drawing) {
    if (!canDrawTextLayer(layer)) return;

    const textarea = layer.textarea;
    textarea.write(layer.textData.text);

    layer.canvas = undefined;
    this.ensureLayerCanvas(layer);
    const ctx = getContext2d(layer.canvas!);
    const rect = textarea.textureRect;
    clipToDrawingRect(rect, drawing);
    ctx.clearRect(rect.x, rect.y, rect.w, rect.h);

    textarea.drawOn(ctx);
    copyRect(layer.rect, rect);

    layer.invalidateCanvas = false;

    if (DEVELOPMENT && DRAW_TEXTURE_RECT) drawTextareaTextureRect(ctx, textarea);

    redrawLayerThumb(layer);
  }
  private drawLayerThumb(layer: Layer, drawingRect: Rect, tempContext: Context) {
    if (layer.thumb && shouldRedrawLayerThumb(layer)) {
      const size = Math.floor(LAYER_THUMB_SIZE * getPixelRatio());

      if (layer.thumb.width !== size || layer.thumb.height !== size) {
        layer.thumb.width = size;
        layer.thumb.height = size;
      }

      const layerRect = getLayerRect(layer);
      clipRect(layerRect, 0, 0, drawingRect.w, drawingRect.h);
      const rect = !isRectEmpty(layerRect) ? layerRect : drawingRect;

      const sx = Math.max(0, rect.x);
      const sy = Math.max(0, rect.y);
      const sw = Math.min(drawingRect.w - sx, rect.w - (sx - rect.x));
      const sh = Math.min(drawingRect.h - sy, rect.h - (sy - rect.y));

      const context = layer.thumb.getContext('2d');

      if (context) {
        fillRect(context, '#aaa', 0, 0, size, size);

        if (sw > 0 && sh > 0) {
          const aspectRatio = sw / sh;
          const dw = aspectRatio > 1 ? size : Math.round(size * aspectRatio);
          const dh = aspectRatio > 1 ? Math.round(size / aspectRatio) : size;
          const dx = Math.round((size - dw) / 2);
          const dy = Math.round((size - dh) / 2);

          clearRect(context, dx, dy, dw, dh);

          if (layer.canvas || (layer.owner && layer.owner.surface.layer === layer)) {
            tempContext.clearRect(sx, sy, sw, sh);
            this.drawLayer(tempContext, tempContext, layer, rect, false, true);
            context.save();
            context.imageSmoothingQuality = 'high';
            drawImage(context, tempContext.canvas, sx, sy, sw, sh, dx, dy, dw, dh);
            context.restore();
          }
        }

        layer.thumbDirty = 0;
        return true;
      }
    }

    return false;
  }
  draw(drawing: Drawing, user: User | undefined, view: Viewport, rect: Rect, options: DrawOptions) {
    if (!this.context || !drawing) return;

    const { cursor, settings, lastPoint, showShiftLine, users } = options;
    const context = this.context;
    const ratio = getPixelRatio();
    const bg = settings.background || '#222';
    const w = context.canvas.width;
    const h = context.canvas.height;
    const s = view.scale * ratio;

    rect = cloneRect(rect);
    scaleRect(rect, ratio, ratio);
    makeIntegerRect(rect);
    clipRect(rect, 0, 0, w, h);

    if (view.rotation === 0 && !view.flipped) {
      fillRect(context, bg, rect.x, rect.y, rect.w, rect.h);
    } else {
      fillRect(context, bg, 0, 0, w, h);
    }

    if (!drawing.canvas || !drawing.id) return;

    const smooth = smoothScaling(settings, view);
    context.imageSmoothingQuality = 'high';

    if (view.rotation === 0 && !view.flipped) {
      // snap view to screen pixels
      // TODO: snap in viewport class so map/unmap works correctly
      const ix = Math.round(view.x * ratio) | 0;
      const iy = Math.round(view.y * ratio) | 0;
      const iw = Math.round(drawing.width * s) | 0;
      const ih = Math.round(drawing.height * s) | 0;
      const p = rectsIntersection(rect, createRect(ix, iy, iw, ih));

      if (p.w > 0 && p.h > 0) {
        // draw background
        if (!drawing.background) {
          context.save();
          context.translate(ix, iy);
          context.fillStyle = this.getCheckerPattern();
          context.fillRect(p.x - ix, p.y - iy, p.w, p.h);
          context.restore();
        }

        // create source rect
        const sx = clamp((p.x - ix) / s, 0, drawing.width);
        const sy = clamp((p.y - iy) / s, 0, drawing.height);
        const sw = clamp(p.w / s, 0, drawing.width - sx);
        const sh = clamp(p.h / s, 0, drawing.height - sy);

        // draw picture
        if (sw > 0 && sh > 0 && p.w && p.h) {
          context.imageSmoothingEnabled = smooth;
          if (options.viewFilter === 'grayscale') context.filter = 'grayscale(100%)';
          drawImage(context, drawing.canvas, sx, sy, sw, sh, p.x, p.y, p.w, p.h);
          context.filter = 'none';
          context.imageSmoothingEnabled = true;

          // draw grid
          if (settings.pixelGrid && view.scale > 6) {
            drawPixelGrid(context, view, p.x, p.y, p.x + p.w, p.y + p.h, ratio);
          }
        }
      }

      drawActiveTool(context, user, view);
    } else {
      context.save();
      applyViewportTransform(context, view, ratio);

      // draw background
      if (!drawing.background) {
        // TODO: scale checker pattern properly
        context.save();
        context.scale(1 / view.scale, 1 / view.scale);
        context.fillStyle = this.getCheckerPattern();
        context.fillRect(0, 0, drawing.width * view.scale, drawing.height * view.scale);
        context.restore();
      }

      // draw picture
      context.imageSmoothingEnabled = smooth;
      if (options.viewFilter === 'grayscale') context.filter = 'grayscale(100%)';
      context.drawImage(drawing.canvas, 0, 0);
      context.filter = 'none';
      context.imageSmoothingEnabled = true;

      drawActiveTool(context, user, view);
      context.restore();

      // draw grid
      if (settings.pixelGrid && view.scale > 6) {
        const minX = Math.round(Math.max(0, view.x)) | 0;
        const maxX = Math.round(Math.min(w, view.x + drawing.width * view.scale)) | 0;
        const minY = Math.round(Math.max(0, view.y)) | 0;
        const maxY = Math.round(Math.min(h, view.y + drawing.height * view.scale)) | 0;
        drawPixelGrid(context, view, minX, minY, maxX, maxY, ratio);
      }
    }

    if (user?.selection && options.selectedTool?.id !== ToolId.AI) drawSelection(context, user.selection, view, user.surface.transform);
    if (user?.showTransform) drawTransform(context, user, drawing, view);
    if (options.selectedTool?.id === ToolId.AI && !!user) {
      const tool = options.selectedTool as AiTool;
      if (tool.showSelection && tool.results.size === 0 && !isMaskEmpty(tool.getSelection())) {
        this.fillSelectionWithPattern(context, tool.getSelection(), view, getAiSelectionPatternCanvas());
      }
      if (tool.pipeline === 'outpaint' && user.activeLayer && tool.results.size === 0) {
        this.drawAiOutpaintingMask(context, tool, user.activeLayer, view, user);
      }
      drawAiBoundingBox(context, view, drawing, options);
    }

    if (user && shouldDrawTextarea(user.activeLayer, options)) {
      drawTextarea(context, user.activeLayer.textarea, view, options);
    }

    if (showShiftLine) {
      copyPoint(tempPt, lastPoint);
      documentToScreenPoint(tempPt, view);
      context.beginPath();
      context.moveTo(tempPt.x * ratio, tempPt.y * ratio);
      context.lineTo(cursor.x * ratio, cursor.y * ratio);
      context.strokeStyle = 'rgba(255, 255, 255, 0.3)';
      context.lineWidth = 1.5;
      context.stroke();
      context.strokeStyle = 'rgba(128, 128, 128, 0.3)';
      context.lineWidth = 1;
      context.stroke();
    }

    if (SHOW_CURSORS && users.length && settings.cursors !== CursorsMode.None) {
      drawCursors(context, view, drawing, users, settings.cursors ?? 0, options.settings.includeVideo || false);
    }

    if (!isTextLayer(user?.activeLayer) || (options.selectedTool && !toolIncompatibleWithTextLayers(options.selectedTool.id))) {
      drawCursor(context, cursor, cursor.x, cursor.y, view, !!settings.showCursor);
    }

    if (options.settings.includeVideo && user && options.drawingInProgress !== true) {
      const alpha = quadraticEasing(noSelfVideoTime, performance.now(), 1000);
      drawSelfVideo(context, cursor, user, alpha);
    } else {
      noSelfVideoTime = performance.now();
    }
  }
  drawLayerThumbs(layers: Layer[], drawingRect: Rect) {
    for (const layer of layers) {
      if (this.drawLayerThumb(layer, drawingRect, this.tempContext)) {
        break;
      }
    }
  }
  drawThumb({ canvas, width, height }: Drawing, _rect: Rect, thumb: HTMLCanvasElement | undefined) {
    if (!canvas || !thumb) return;

    const context = thumb.getContext('2d');
    const scale = Math.min(SEQUENCE_THUMB_WIDTH / width, SEQUENCE_THUMB_HEIGHT / height);
    const thumbWidth = Math.ceil(width * scale);
    const thumbHeight = Math.ceil(height * scale);
    const dx = Math.floor((thumb.width - thumbWidth) / 2);
    const dy = Math.floor((thumb.height - thumbHeight) / 2);

    if (context) {
      context.save();
      context.imageSmoothingQuality = 'high';
      if (scale > 1) context.imageSmoothingEnabled = false;
      context.drawImage(canvas, 0, 0, width, height, dx, dy, thumbWidth, thumbHeight);
      context.restore();
    }

    // TODO: getImageData is super slow, can't use this method
    // const scale = Math.min(SEQUENCE_THUMB_WIDTH / drawing.width, SEQUENCE_THUMB_HEIGHT / drawing.height);
    // const thumbWidth = Math.ceil(drawing.width * scale);
    // const thumbHeight = Math.ceil(drawing.height * scale);
    // const thumbRect = makeIntegerRect(createRect(rect.x * scale, rect.y * scale, rect.w * scale, rect.h * scale));
    // this.tempContext.save();
    // this.tempContext.scale(scale, scale);
    // this.tempContext.imageSmoothingQuality = 'high';
    // this.tempContext.drawImage(drawing.canvas, 0, 0);
    // this.tempContext.restore();
    // const imageData = this.tempContext.getImageData(thumbRect.x, thumbRect.y, thumbRect.w, thumbRect.h);
    // const data = new Uint8Array(imageData.data.buffer, imageData.data.byteOffset, imageData.data.byteLength);
    // drawing.thumbUpdate = { data, width: thumbWidth, height: thumbHeight, rect: thumbRect };
  }
  pingThumb(_drawing: Drawing) {
  }
  discardThumb() {
  }
  scaleImage(image: HTMLImageElement | ImageBitmap | ImageData, scaledWidth: number, scaledHeight: number): HTMLCanvasElement {
    const canvas = createCanvas(scaledWidth, scaledHeight);
    const context = getContext2d(canvas);
    if ('data' in image) {
      // this can happen only on server with software renderer ?
      const scaledCanvas = createCanvas(image.width, image.height);
      const scaledContext = getContext2d(scaledCanvas);
      const data = scaledContext.createImageData(image.width, image.height);
      data.data.set(image.data);

      context.drawImage(scaledCanvas, 0, 0, image.width, image.height, 0, 0, scaledWidth, scaledHeight);
    } else {
      context.drawImage(image, 0, 0, image.width, image.height, 0, 0, scaledWidth, scaledHeight);
    }
    return canvas;
  }
  getScaledDrawingSnapshot(drawing: Drawing, scaledWidth: number, scaledHeight: number, selection: Mask | undefined) {
    const canvas = createCanvas(scaledWidth, scaledHeight);
    const context = getContext2d(canvas);
    const snapshot = this.getDrawingSnapshot(drawing, selection);
    if (snapshot) {
      context.imageSmoothingQuality = 'high';
      context.drawImage(snapshot, 0, 0, snapshot.width, snapshot.height, 0, 0, scaledWidth, scaledHeight);
    }
    return canvas;
  }
  getScaledLayerSnapshot(drawing: Drawing, layer: Layer, scaledWidth: number, scaledHeight: number, selection: Mask | undefined) {
    const canvas = createCanvas(scaledWidth, scaledHeight);
    const context = getContext2d(canvas);
    const bounds = selection ? selection.bounds : createRect(0, 0, drawing.width, drawing.height);
    const snapshot = this.getLayerSnapshot(layer);
    if (selection && snapshot) clipTo(context, selection);
    if (snapshot) {
      context.imageSmoothingQuality = 'high';
      context.drawImage(snapshot, bounds.x, bounds.y, bounds.w, bounds.h, 0, 0, scaledWidth, scaledHeight);
    }
    return canvas;
  }
  getScaledLayerMask(drawing: Drawing, layer: Layer, scaledWidth: number, scaledHeight: number, selection: Mask | undefined): HTMLCanvasElement {
    const canvas = createCanvas(scaledWidth, scaledHeight);
    const context = getContext2d(canvas);
    const bounds = selection ? selection.bounds : createRect(0, 0, drawing.width, drawing.height);
    const snapshot = this.getLayerSnapshot(layer);
    if (selection && snapshot) clipTo(context, selection);
    if (snapshot) {
      // make sure that mask is generated from original image (creating mask from scaled image can cause outpainting issues)
      const mask = createCanvas(snapshot.width, snapshot.height);
      const maskContext = getContext2d(mask);

      maskContext.drawImage(snapshot, 0, 0);

      maskContext.fillStyle = '#000000FF';
      maskContext.globalCompositeOperation = 'source-atop';
      maskContext.fillRect(0, 0, snapshot.width, snapshot.height);

      maskContext.fillStyle = '#FFFFFFFF';
      maskContext.globalCompositeOperation = 'destination-atop';
      maskContext.fillRect(0, 0, snapshot.width, snapshot.height);

      context.imageSmoothingQuality = 'high';
      context.drawImage(mask, bounds.x, bounds.y, bounds.w, bounds.h, 0, 0, scaledWidth, scaledHeight);
    }
    return canvas;
  }

  pickColor(drawing: Drawing, layer: Layer | undefined, x: number, y: number, activeLayer: boolean) {
    return pickColor(drawing, layer, x, y, activeLayer);
  }
  getToolRenderingContext(user: User) {
    if (DEVELOPMENT && user.surface.context)
      throw new Error('Rendering context not released');

    this.ensureSurface(user.surface, this.drawingWidth, this.drawingHeight);
    const context = getContext2d(user.surface.canvas!);
    return user.surface.context = createRenderingContext(context);
  }
  trimLayer(layer: Layer, rect: Rect) {
    if (!rectIncludesRect(layer.rect, rect)) throw new Error(`Trim layer is going to expand layer! (${rectToString(layer.rect)} -> ${rectToString(rect)})`);
    copyRect(layer.rect, rect);
  }
  // for testing
  fillSelection(layer: Layer) {
    if (!layer.owner) throw new Error('Missing layer owner');

    this.ensureLayerCanvas(layer);
    const context = getContext2d(layer.canvas!);
    context.save();
    context.fillStyle = 'cornflowerblue';
    if (!hasZeroTransform(layer.owner.surface)) {
      applyTransform(context, layer.owner.surface.transform);
      fillMask(context, layer.owner.selection);
    }
    context.restore();
    setRect(layer.rect, 0, 0, this.drawingWidth, this.drawingHeight);
    layerChanged(layer);
  }
  private getCheckerPattern() {
    if (!this.pattern) {
      const size = 16;
      const canvas = createCanvas(size, size);
      const context = getContext2d(canvas);
      fillRect(context, '#fff', 0, 0, size, size);
      fillRect(context, '#cfcfcf', 0, 0, size / 2, size / 2);
      fillRect(context, '#cfcfcf', size / 2, size / 2, size / 2, size / 2);
      this.pattern = context.createPattern(canvas, 'repeat')!;
    }

    return this.pattern;
  }
  private ensureLayerCanvas(layer: Layer) {
    layer.canvas = layer.canvas ?? this.canvasProvider.create(
      `${this.id}.layer_${layer.id}`, this.drawingWidth, this.drawingHeight);
  }
  private getLayerContext(layer: Layer) {
    this.ensureLayerCanvas(layer);
    return getContext2d(layer.canvas!);
  }

  // filters
  applyHueSaturationLightnessFilter(srcData: ImageData | undefined, surface: ToolSurface, values: IFiltersValues) {
    if (!srcData) throw new Error(`[applyHueSaturationLightnessFilter] Missing srcData`);
    const dstData = this.createImageData(srcData.width, srcData.height, undefined);
    applyHueSaturationLightness(srcData.data, dstData.data, values);
    this.ensureSurface(surface, dstData.width, dstData.height);
    getContext2d(surface.canvas!).putImageData(dstData, 0, 0);
  }
  applyBrightnessContrastFilter(srcData: ImageData | undefined, surface: ToolSurface, values: IFiltersValues) {
    if (!srcData) throw new Error(`[applyBrightnessContrastFilter] Missing srcData`);
    const dstData = this.createImageData(srcData.width, srcData.height, undefined);
    applyBrightnessContrast(srcData.data, dstData.data, values);
    this.ensureSurface(surface, dstData.width, dstData.height);
    getContext2d(surface.canvas!).putImageData(dstData, 0, 0);
  }
  applyCurvesFilter(srcData: ImageData | undefined, surface: ToolSurface, values: IFiltersValues) {
    if (!srcData) throw new Error(`[applyCurvesFilter] Missing srcData`);
    const dstData = this.createImageData(srcData.width, srcData.height, undefined);
    applyCurves(srcData.data, dstData.data, values);
    this.ensureSurface(surface, dstData.width, dstData.height);
    getContext2d(surface.canvas!).putImageData(dstData, 0, 0);
  }
  applyBlurFilter(srcData: ImageData | undefined, surface: ToolSurface, values: IFiltersValues) {
    if (!srcData) throw new Error(`[applyBlurFilter] Missing srcData`);
    const dstData = this.createImageData(srcData.width, srcData.height, undefined);
    applyGaussianBlur(srcData.data, dstData.data, srcData.width, srcData.height, values.radius || 0);
    getContext2d(surface.canvas!).putImageData(dstData, 0, 0);
  }

  drawAiOutpaintingMask(context: Context, tool: AiTool, layer: Layer, view: Viewport, user: User) {
    if (!this.canvas) return;
    const mat = createViewportMatrix2d(tempMatrix2d, view);
    const pixelRatio = getPixelRatio();
    const snapshot = layer.canvas;
    if (snapshot) {
      if (!this.tempCanvas || !this.tempCanvasContext || this.tempCanvas.width !== this.canvas.width || this.tempCanvas.height !== this.canvas.height) {
        this.tempCanvas = createCanvas(Math.ceil(this.canvas.width / pixelRatio), Math.ceil(this.canvas.height / pixelRatio));
        this.tempCanvasContext = getContext2d(this.tempCanvas!);
      }

      const r = getTransformedRectBounds(tool.bounds, mat);
      this.tempCanvasContext.clearRect(0, 0, this.tempCanvas.width, this.tempCanvas.height);
      this.tempCanvasContext.save();

      this.tempCanvasContext.fillStyle = AI_SELECTION_COLOR_1;
      this.tempCanvasContext.fillRect(r.x, r.y, r.w, r.h);

      if (!this.tempDOMMatrix) this.tempDOMMatrix = new DOMMatrix([1, 0, 0, 1, 0, 0]);
      updateAiMaskTransform(this.tempDOMMatrix);
      fillRectWithPattern(this.tempCanvasContext, r, getAiSelectionPatternCanvas(), this.tempDOMMatrix);

      this.tempCanvasContext.globalCompositeOperation = 'destination-out';
      this.tempCanvasContext.transform(mat[0], mat[1], mat[2], mat[3], mat[4], mat[5]);
      this.tempCanvasContext.drawImage(snapshot, 0, 0);

      if (user.surface.canvas) {
        const m = user.surface.transform;
        this.tempCanvasContext.transform(m[0], m[1], m[2], m[3], m[4], m[5]);
        this.tempCanvasContext.drawImage(user.surface.canvas, 0, 0);
      }
      this.tempCanvasContext.restore();

      context.save();
      context.scale(pixelRatio, pixelRatio);
      context.drawImage(this.tempCanvasContext.canvas, r.x, r.y, r.w, r.h, r.x, r.y, r.w, r.h);
      context.restore();
    }
  }
  fillSelectionWithPattern(context: CanvasRenderingContext2D, selection: Mask, view: Viewport, patternCanvas: HTMLCanvasElement) {
    const mat = createViewportMatrix2d(tempMatrix2d, view);
    const pixelRatio = getPixelRatio();
    const mask = cloneMask(selection);
    transformMask(mask, mat);

    context.save();
    context.scale(pixelRatio, pixelRatio);
    context.fillStyle = AI_SELECTION_COLOR_1;
    fillMask(context, mask);

    if (!this.tempDOMMatrix) this.tempDOMMatrix = new DOMMatrix([1, 0, 0, 1, 0, 0]);
    updateAiMaskTransform(this.tempDOMMatrix);
    fillWithCanvasPattern(context, patternCanvas, this.tempDOMMatrix);
    context.restore();
  }
}

function drawPixelGrid(context: Context, view: Viewport, minX: number, minY: number, maxX: number, maxY: number, ratio: number) {
  const alpha = Math.min(0.1 + 0.01 * (view.scale - 6), 0.2);

  if (view.rotation) {
    context.save();
    context.beginPath();

    applyViewportTransform(context, view, ratio);

    const rect = createRect(0, 0, view.width, view.height);
    screenToDocumentRect(rect, view);
    clipRect(rect, 0, 0, view.contentWidth, view.contentHeight);
    minX = rect.x | 0;
    minY = rect.y | 0;
    maxX = Math.ceil(rect.x + rect.w);
    maxY = Math.ceil(rect.y + rect.h);

    for (let x = minX; x < maxX; x++) {
      context.moveTo(x, minY);
      context.lineTo(x, maxY);
    }

    for (let y = minY; y < maxY; y++) {
      context.moveTo(minX, y);
      context.lineTo(maxX, y);
    }

    context.restore();
  } else {
    const s = view.scale * ratio;
    let x = view.x * ratio + s;
    let y = view.y * ratio + s;

    while (x < minX)
      x += s;

    while (y < minY)
      y += s;

    context.beginPath();

    for (; x < maxX; x += s) {
      context.moveTo((x | 0) + 0.5, minY);
      context.lineTo((x | 0) + 0.5, maxY);
    }

    for (; y < maxY; y += s) {
      context.moveTo(minX, (y | 0) + 0.5);
      context.lineTo(maxX, (y | 0) + 0.5);
    }
  }

  context.strokeStyle = `rgba(255, 255, 255, ${alpha})`;
  context.lineWidth = 1;
  context.lineCap = 'butt';
  context.stroke();
}

function pickColor(drawing: Drawing, layer: Layer | undefined, x: number, y: number, activeLayer: boolean): number | undefined {
  if (!rectContainsXY(drawing.rect, x, y)) {
    return undefined;
  }

  const context = getPixelContext();

  if (!activeLayer && drawing.background) {
    fillRect(context, drawing.background, 0, 0, 1, 1);
  } else {
    clearRect(context, 0, 0, 1, 1);
  }

  if (!activeLayer) {
    if (!drawing.canvas) {
      return undefined;
    } else {
      drawImage(context, drawing.canvas, x | 0, y | 0, 1, 1, 0, 0, 1, 1);
    }
  } else if (layer) {
    if (layer.canvas) {
      drawImage(context, layer.canvas, x | 0, y | 0, 1, 1, 0, 0, 1, 1);
    }

    const owner = layer.owner;

    if (owner && owner.surface.layer === layer && !isSurfaceEmpty(owner.surface) && !hasZeroTransform(owner.surface)) {
      const vec = pointToSurface(owner.surface, x, y);
      const sx = Math.floor(vec[0]);
      const sy = Math.floor(vec[1]);

      if (owner.surface.canvas && rectContainsRect(owner.surface.rect, createRect(sx, sy, 1, 1))) {
        drawImage(context, owner.surface.canvas, sx, sy, 1, 1, 0, 0, 1, 1);
      }
    }
  }

  const data = context.getImageData(0, 0, 1, 1).data;
  return data[3] === 0 ? undefined : colorFromRGBA(data[0], data[1], data[2], data[3]);
}

const temp = createPoint(0, 0);



function drawSelection(context: CanvasRenderingContext2D, mask: Mask, view: Viewport, transform: Mat2d) {
  if (!mask.poly) return;

  context.save();
  context.beginPath();

  const ratio = getPixelRatio();

  if (isMat2dTranslation(transform)) {
    // TODO: remove ? (just use matrix version)
    const x = getMat2dX(transform);
    const y = getMat2dY(transform);

    for (const segment of mask.poly) {
      if (!isPolySegmentEmpty(segment)) {
        const { items, size } = segment;
        const size2 = size << 1;

        documentToScreenXY(temp, items[0] + x, items[1] + y, view);
        context.moveTo(round5(temp.x * ratio), round5(temp.y * ratio));

        for (let j = 2; j < size2; j += 2) {
          documentToScreenXY(temp, items[j] + x, items[j + 1] + y, view);
          context.lineTo(round5(temp.x * ratio), round5(temp.y * ratio));
        }

        documentToScreenXY(temp, items[0] + x, items[1] + y, view);
        context.lineTo(round5(temp.x * ratio), round5(temp.y * ratio));
      }
    }
  } else {
    const vec = createVec2();
    const mat = createMat2d();
    createViewportMatrix2d(mat, view);
    multiplyMat2d(mat, mat, transform);

    for (const segment of mask.poly) {
      if (!isPolySegmentEmpty(segment)) {
        const { items, size } = segment;
        const size2 = size << 1;

        transformVec2ByMat2d(vec, setVec2(vec, items[0], items[1]), mat);
        context.moveTo(round5(vec[0] * ratio), round5(vec[1] * ratio));

        for (let j = 2; j < size2; j += 2) {
          transformVec2ByMat2d(vec, setVec2(vec, items[j], items[j + 1]), mat);
          context.lineTo(round5(vec[0] * ratio), round5(vec[1] * ratio));
        }

        transformVec2ByMat2d(vec, setVec2(vec, items[0], items[1]), mat);
        context.lineTo(round5(vec[0] * ratio), round5(vec[1] * ratio));
      }
    }
  }

  context.strokeStyle = '#fff';
  context.stroke();

  context.setLineDash([4 * ratio, 4 * ratio]);
  context.lineWidth = 1 * ratio;
  context.lineDashOffset = (Math.round(Date.now() / 250) % (8 * ratio)) + 0.5;
  context.strokeStyle = '#000';
  context.stroke();

  context.restore();
}

function clipTo(context: CanvasRenderingContext2D, mask: Mask) {
  const globalCompositeOperation = context.globalCompositeOperation;
  context.globalCompositeOperation = 'destination-in';
  fillMask(context, mask);
  context.globalCompositeOperation = globalCompositeOperation;
}

function clipOut(context: CanvasRenderingContext2D, mask: Mask) {
  const globalCompositeOperation = context.globalCompositeOperation;
  context.globalCompositeOperation = 'destination-out';
  fillMask(context, mask);
  context.globalCompositeOperation = globalCompositeOperation;
}

function drawToolSurface(context: CanvasRenderingContext2D, surface: ToolSurface) {
  if (surface.canvas && !hasZeroTransform(surface)) {
    // TODO: use bounds & mode
    context.save();
    context.globalAlpha = surface.opacity;
    applyTransform(context, surface.transform);
    context.drawImage(surface.canvas, 0, 0);
    context.restore();
  }
}

interface NamePlate {
  id: number;
  name: string;
  color: string;
  canvas: HTMLCanvasElement;
  avatarImage: HTMLImageElement | undefined;
  avatarVideoDimensions?: {
    width: number;
    height: number;
  }
}

const namePlates: NamePlate[] = [];
let namePlatesMode = CursorsMode.None;
let namePlatesRatio = 1;

export function truncateName(context: CanvasRenderingContext2D, name: string, maxLength: number) {
  if (textWidth(context, name) > maxLength) {
    let length = name.length;
    let truncated: string;

    do {
      length--;
      truncated = truncate(name, { length, omission: '…' });
    } while (length && textWidth(context, truncated) > maxLength);

    name = truncated;
  }

  return name;
}

export function createNamePlate(user: User, ratio: number, mode: CursorsMode, includeVideo: boolean): NamePlate {
  const { localId, name, color, colorFloat, avatarImage } = user;
  let width = Math.ceil(USER_NAME_WIDTH * ratio);
  let drawAvatar = mode === CursorsMode.PointerAvatarName || mode === CursorsMode.PointerAvatar;
  const drawName = mode === CursorsMode.PointerAvatarName || mode === CursorsMode.PointerName;
  let drawNameplate = drawName;
  const avatarVideo = user.avatarVideo;
  if (includeVideo && avatarVideo) {
    drawAvatar = false;
    drawNameplate = false;
  }

  const height = Math.round((drawAvatar && !drawName ? CURSOR_AVATAR_LARGE_HEIGHT : USER_NAME_HEIGHT) * ratio);
  const canvas = createCanvas(width, height);
  const context = getContext2d(canvas);
  let w = 0;
  let truncated = '';
  const padX = 9 * ratio;
  const font = context.font = `bold ${14 * ratio}px ${DEFAULT_FONT}`;

  if (drawAvatar) {
    w += height;
    width = height;
  }

  if (drawName) {
    const maxLength = (includeVideo && avatarVideo) ? Math.floor(CURSOR_VIDEO_HEIGHT * ratio * avatarVideo.videoWidth / avatarVideo.videoHeight) - 10 * ratio : 100 * ratio + w;
    context.font = font;
    truncated = truncateName(context, name, maxLength);
    width = Math.ceil(textWidth(context, truncated) + padX * 2 + w);
  }

  canvas.width = width;

  const brightness = drawNameplate ? rgbToGray(colorFloat[0], colorFloat[1], colorFloat[2]) : 0;

  if (drawNameplate) {
    context.fillStyle = color;
    context.fillRect(w, 0, canvas.width - w, canvas.height);
  }
  if (drawName) {
    context.fillStyle = brightness > 0.71 ? '#222' : 'white';
    context.font = font;
    context.fillText(truncated, padX + w, 19 * ratio);
  }

  if (drawAvatar && avatarImage) {
    context.drawImage(avatarImage, 0, 0, avatarImage.width, avatarImage.width, 0, 0, height, height);
  }

  const avatarVideoDimensions = (includeVideo && avatarVideo) ? {
    width: avatarVideo.videoWidth,
    height: avatarVideo.videoHeight,
  } : undefined;

  return { id: localId, name, color, canvas, avatarImage, avatarVideoDimensions };
}

function getNamePlate(user: User, users: User[], ratio: number, mode: CursorsMode, includeVideo: boolean): HTMLCanvasElement {
  if (namePlatesRatio !== ratio || namePlatesMode !== mode) {
    namePlates.length = 0;
  }

  let index = findIndexById(namePlates, user.localId);

  if (index === -1) {
    if (namePlates.length >= 64) {
      for (let i = namePlates.length - 1; i >= 0; i--) {
        if (!findByLocalId(users, namePlates[i].id)) {
          removeAtFast(namePlates, i);
        }
      }
    }

    namePlates.push(createNamePlate(user, ratio, mode, includeVideo));
    index = namePlates.length - 1;
  } else if (
    namePlates[index].name !== user.name ||
    namePlates[index].color !== user.color ||
    namePlates[index].avatarImage !== user.avatarImage ||
    namePlates[index].avatarVideoDimensions?.width !== (includeVideo ? user.avatarVideo?.videoWidth : undefined) ||
    namePlates[index].avatarVideoDimensions?.height !== (includeVideo ? user.avatarVideo?.videoHeight : undefined)
  ) {
    namePlates[index] = createNamePlate(user, ratio, mode, includeVideo);
  }

  return namePlates[index].canvas;
}

export function drawPointer(context: CanvasRenderingContext2D, x: number, y: number, color: string, cursorAlpha: number) {
  const ratio = getPixelRatio();
  context.globalAlpha = Math.max(0.5, cursorAlpha);
  context.beginPath();
  context.strokeStyle = color;
  context.arc(x, y, USER_CURSOR_RADIUS * ratio, 0, Math.PI * 2);
  context.stroke();
}

function rectForCursorVideo(cursor: { x: number, y: number }, avatarVideo: HTMLVideoElement) {
  const h = CURSOR_VIDEO_HEIGHT;
  const srcWidth = avatarVideo.videoWidth;
  const srcHeight = avatarVideo.videoHeight;
  let w = Math.floor(h * (srcWidth / srcHeight));

  const x = cursor.x + USER_NAME_OFFSET;
  const y = cursor.y + USER_NAME_OFFSET;
  return { x, y, w, h };
}

function drawSelfVideo(context: CanvasRenderingContext2D, cursor: Cursor, user: User, opacity: number) {
  if (user.avatarVideo) {
    context.save();
    context.globalAlpha = opacity;
    const { x, y, w, h } = rectForCursorVideo(cursor, user.avatarVideo);
    context.translate(x + w / 2, y + w / 2);
    context.scale(-1, 1);
    context.translate(-(x + w / 2), -(y + w / 2));
    context.drawImage(user.avatarVideo, x, y, w, h);
    context.restore();

    return { x, y, w, h };
  }
  return undefined;
}

function drawCursors(
  context: CanvasRenderingContext2D, view: Viewport, drawing: Drawing, users: User[], cursors: CursorsMode, includeVideo: boolean
) {
  const role = drawing.permissions.cursors ?? defaultDrawingPermissions.cursors;
  const ratio = getPixelRatio();
  const lastUpdateThreshold = performance.now() - SHOW_CURSOR_UNMOVING_TIMEOUT;

  // circles
  context.lineWidth = 1.5 * ratio;

  for (const u of users) {
    if (!hasDrawingRole(u, role) || u.cursorLastUpdate < lastUpdateThreshold) {
      u.cursorX = 1e9;
      u.cursorY = 1e9;
      continue;
    }

    setPoint(tempPt, u.cursorX, u.cursorY);
    documentToScreenPoint(tempPt, view);
    const cx = tempPt.x * ratio;
    const cy = tempPt.y * ratio;
    drawPointer(context, cx, cy, u.color, u.cursorAlpha);
  }

  context.lineWidth = 1;

  if (cursors === CursorsMode.PointerName || cursors === CursorsMode.PointerAvatarName || cursors === CursorsMode.PointerAvatar) { // name plates
    for (const u of users) {
      if (!hasDrawingRole(u, role) || u.cursorLastUpdate < lastUpdateThreshold) continue;

      const canvas = getNamePlate(u, users, ratio, cursors, includeVideo);
      setPoint(tempPt, u.cursorX, u.cursorY);
      documentToScreenPoint(tempPt, view);
      const x = Math.round((tempPt.x + USER_NAME_OFFSET) * ratio);
      const y = Math.round((tempPt.y + USER_NAME_OFFSET) * ratio);
      context.globalAlpha = u.cursorAlpha;
      if (u.avatarVideo && includeVideo) {
        const cv = rectForCursorVideo({ x, y }, u.avatarVideo);
        context.drawImage(u.avatarVideo, cv.x, cv.y, cv.w, cv.h);
        context.drawImage(canvas, cv.x, cv.y + cv.h - USER_NAME_HEIGHT * ratio);
      } else {
        context.drawImage(canvas, x, y);
      }
    }
  }

  for (const u of users) {
    const color = u.color;
    if (isTextLayer(u.activeLayer) && u.activeLayer.textarea?.isFocused) {
      if (shouldCacheTextareaInLayer(u.activeLayer)) cacheTextareaInLayer(u.activeLayer);
      drawTextareaBoundaries(context, view, u.activeLayer.textarea, 2, color);
    }
  }

  context.globalAlpha = 1;
}

function drawCrosshair(context: CanvasRenderingContext2D, x: number, y: number, pixelRatio: number) {
  const GAP = 5 * pixelRatio;
  const SIZE = 3 * pixelRatio;
  context.lineWidth = 1 * pixelRatio;
  context.beginPath();
  context.moveTo(x, y - GAP - SIZE); context.lineTo(x, y - GAP); // top
  context.moveTo(x, y + GAP); context.lineTo(x, y + GAP + SIZE); // bottom
  context.moveTo(x - GAP - SIZE, y); context.lineTo(x - GAP, y); // left
  context.moveTo(x + GAP, y); context.lineTo(x + GAP + SIZE, y); // right
  context.stroke();
  context.lineWidth = 1;
}

function drawFilledCrosshair(context: CanvasRenderingContext2D, x: number, y: number, pixelRatio: number) {
  const LENGTH = 11 * pixelRatio; // length of arms
  const WIDTH = 1 * pixelRatio; // half width of each arm
  const t0 = y - WIDTH, t1 = y - WIDTH - LENGTH;
  const b0 = y + WIDTH, b1 = y + WIDTH + LENGTH;
  const l0 = x - WIDTH, l1 = x - WIDTH - LENGTH;
  const r0 = x + WIDTH, r1 = x + WIDTH + LENGTH;
  context.fillStyle = 'white';
  context.globalCompositeOperation = 'difference';
  context.beginPath();
  context.moveTo(l0, t0);
  context.lineTo(l0, t1); context.lineTo(r0, t1); context.lineTo(r0, t0); // top
  context.lineTo(r1, t0); context.lineTo(r1, b0); context.lineTo(r0, b0); // right
  context.lineTo(r0, b1); context.lineTo(l0, b1); context.lineTo(l0, b0); // bottom
  context.lineTo(l1, b0); context.lineTo(l1, t0); context.lineTo(l0, t0); // left
  context.closePath();
  context.fill();
  context.globalCompositeOperation = 'source-over';
}

function drawCursor(context: CanvasRenderingContext2D, cursor: Cursor, x: number, y: number, view: Viewport, skipCrosshair: boolean) {
  const color = '#808080';
  const glow = 'rgba(255, 255, 255, 0.5)';
  const MIN_NORMAL_SIZE = 5.0;
  const MIN_CIRCLE_SIZE = 2.5;

  if (!cursor.show || cursor.type === CursorType.None) return;

  const pixelRatio = getPixelRatio();
  x *= pixelRatio;
  y *= pixelRatio;

  switch (cursor.type) {
    case CursorType.Circle: {
      const radius = Math.max(1, Math.round(cursor.size / 2)) * pixelRatio;
      context.save();

      context.strokeStyle = glow;
      context.fillStyle = glow;
      context.beginPath();
      context.arc(x, y, radius + 0.5, 0, Math.PI * 2, true);

      if (cursor.size >= MIN_CIRCLE_SIZE) {
        context.stroke();
      } else {
        context.fill();
      }

      context.strokeStyle = color;
      context.fillStyle = color;
      context.beginPath();
      context.arc(x, y, radius, 0, Math.PI * 2, true);

      if (cursor.size >= MIN_CIRCLE_SIZE) {
        context.stroke();
      } else {
        context.fill();
      }

      if (cursor.size < MIN_NORMAL_SIZE && !skipCrosshair) {
        drawCrosshair(context, x, y, pixelRatio);
      }

      context.restore();
      break;
    }
    case CursorType.Square: {
      const size = Math.max(1, Math.round(cursor.size)) * pixelRatio;
      const half = size / 2;

      if (view.rotation) {
        context.save();
        context.translate(x, y);
        context.rotate(-view.rotation);
        context.strokeStyle = 'rgba(255, 255, 255, 0.25)';
        context.strokeRect(-half - 1, -half - 1, size + 2, size + 2);
        context.strokeStyle = color;
        context.strokeRect(-half, -half, size, size);
        context.restore();
      } else {
        context.strokeStyle = 'rgba(255, 255, 255, 0.25)';
        context.strokeRect(round5(x - half) - 1, round5(y - half) - 1, size + 2, size + 2);
        context.strokeStyle = color;
        context.strokeRect(round5(x - half), round5(y - half), size, size);
      }

      if (cursor.size < MIN_NORMAL_SIZE && !skipCrosshair) {
        drawCrosshair(context, x, y, pixelRatio);
      }

      break;
    }
    case CursorType.Crosshair: {
      drawFilledCrosshair(context, x, y, pixelRatio);
      break;
    }
    default:
      invalidEnumReturn(cursor.type, undefined);
  }
}

function drawActiveTool(context: Context, user: User | undefined, view: Viewport) {
  const tool = user?.activeTool;

  if (!tool) return;

  switch (tool.id) {
    case ToolId.AI: {
      const t = tool as AiTool;
      drawAiSelectionPoly(context, t, AI_BOUNDING_BOX_COLOR_1_ACTIVE_STR, AI_BOUNDING_BOX_COLOR_2_ACTIVE_STR, 1);
      break;
    }
    case ToolId.Selection:
    case ToolId.CircleSelection:
    case ToolId.LassoSelection: {
      const selectionTool = tool as (SelectionTool | CircleSelectionTool | LassoSelectionTool);

      if (!selectionTool.isPathEmpty()) {
        const ratio = getPixelRatio();

        context.save();
        context.setTransform(1, 0, 0, 1, 0, 0);

        selectionTool.drawPath(context);

        context.strokeStyle = '#fff';
        context.stroke();

        context.setLineDash([6 * ratio, 6 * ratio]);
        context.lineWidth = ratio;
        context.lineDashOffset = (Math.round(Date.now() / 100) % (12 * ratio)) + 0.5;
        context.strokeStyle = '#000';
        context.stroke();

        context.restore();
      }
      break;
    }
    case ToolId.RotateView: {
      const ratio = getPixelRatio();
      const size = 6 * ratio;
      const crosshair = size + 3;
      const x = round5(view.width / 2);
      const y = round5(view.height / 2);

      context.save();
      context.setTransform(1, 0, 0, 1, 0, 0);
      context.lineWidth = 1 * ratio;
      context.strokeStyle = '#808080';
      context.beginPath();
      context.arc(x, y, size, 0, Math.PI * 2);
      context.moveTo(x, y - crosshair);
      context.lineTo(x, y + crosshair);
      context.moveTo(x - crosshair, y);
      context.lineTo(x + crosshair, y);
      context.stroke();
      context.restore();
      break;
    }
    case ToolId.Text: {
      const textTool = tool as TextTool;
      if (textTool.mode === TextToolMode.Creating) {
        context.save();

        const ratio = getPixelRatio();
        context.setTransform(1, 0, 0, 1, 0, 0);
        context.scale(ratio, ratio);

        const { r, g, b, a } = TEXTAREA_BOUNDARIES_COLOR;
        context.strokeStyle = `rgba(${r * 255}, ${g * 255}, ${b * 255}, ${a * 255})`;
        context.lineWidth = TEXTAREA_HOVERED_BOUNDARIES_WIDTH;

        const toolView = textTool.editor.view;
        const mat = createViewportMatrix2d(tempMatrix2d, toolView);
        context.transform(mat[0], mat[1], mat[2], mat[3], mat[4], mat[5]);

        let tmpRect = cloneRect(textTool.rect);
        if (textTool.textarea?.type === TextareaType.AutoWidth) {
          tmpRect.x += (textTool.textarea as AutoWidthTextarea).negativeOffsetForWidth;
        }

        context.strokeRect(tmpRect.x, tmpRect.y, tmpRect.w, tmpRect.h);

        context.restore();
      }
      break;
    }
    // case ToolId.Palette: {
    //   const { x, y, w, h } = (tool as PaletteTool).rect;

    //   context.save();
    //   context.setTransform(1, 0, 0, 1, 0, 0);
    //   context.strokeStyle = '#808080';
    //   context.beginPath();
    //   context.moveTo(x, y);
    //   context.lineTo(x + w, y);
    //   context.moveTo(x + w, y + h);
    //   context.lineTo(x, y + h);
    //   context.closePath();
    //   context.stroke();
    //   context.restore();
    //   break;
    // }
  }
}

function drawAiControl(context: Context, cx: number, cy: number, color: string, view: Viewport) {
  const size = 8 / view.scale;
  const x = Math.round(cx) - size / 2;
  const y = Math.round(cy) - size / 2;

  context.fillStyle = color;
  context.fillRect(x, y, size, size);

  context.fillStyle = `rgba(255, 255, 255, 1)`;
  context.fillRect(x + 1 / view.scale, y + 1 / view.scale, size - 2 / view.scale, size - 2 / view.scale);
}

function drawTransformControl(context: Context, x: number, y: number, offset: number) {
  const size = 6;
  context.rect(round5(x - size / 2) - offset, round5(y - size / 2) - offset, size + offset * 2, size + offset * 2);
}

function drawTransformAnchor(context: Context, x: number, y: number, offset: number) {
  const inner = 3 + offset;
  const outer = 6 + offset;
  context.moveTo(round5(x + inner), round5(y));
  context.arc(round5(x), round5(y), inner, 0, Math.PI * 2);
  context.moveTo(round5(x - inner), round5(y));
  context.lineTo(round5(x - outer), round5(y));
  context.moveTo(round5(x + inner), round5(y));
  context.lineTo(round5(x + outer), round5(y));
  context.moveTo(round5(x), round5(y - inner));
  context.lineTo(round5(x), round5(y - outer));
  context.moveTo(round5(x), round5(y + inner));
  context.lineTo(round5(x), round5(y + outer));
}

const strokes = [
  { offset: 1, color: '#fff' },
  { offset: 0, color: '#000' },
];

const tempMatrix2d = createMat2d();
const tempVec = createVec2();

const DRAW_TRANSFORM_REGIONS = false;

function drawTransform(context: Context, user: User, drawing: Drawing, view: Viewport) {
  const mat = createViewportMatrix2d(tempMatrix2d, view);
  const bounds = getTransformBounds(user, drawing, mat);
  const pixelRatio = getPixelRatio();

  context.save();
  context.scale(pixelRatio, pixelRatio);

  for (const { color } of strokes) {
    context.beginPath();
    context.moveTo(round5(bounds[0][0]), round5(bounds[0][1]));
    context.lineTo(round5(bounds[1][0]), round5(bounds[1][1]));
    context.lineTo(round5(bounds[2][0]), round5(bounds[2][1]));
    context.lineTo(round5(bounds[3][0]), round5(bounds[3][1]));
    context.lineTo(round5(bounds[0][0]), round5(bounds[0][1]));
    context.closePath();
    context.strokeStyle = color;
    context.stroke();
    context.setLineDash([3, 3]);
  }

  context.setLineDash([]);

  getTransformOrigin(tempVec, bounds, user, mat);

  for (const { offset, color } of strokes) {
    context.beginPath();

    for (let i = 0; i < 4; i++) {
      const pt = bounds[i];
      drawTransformControl(context, pt[0], pt[1], offset);
      const pt2 = bounds[(i + 1) % 4];
      drawTransformControl(context, (pt[0] + pt2[0]) / 2, (pt[1] + pt2[1]) / 2, offset);
    }

    drawTransformAnchor(context, Math.round(tempVec[0]), Math.round(tempVec[1]), offset);
    context.strokeStyle = color;
    context.stroke();
  }

  if (DEVELOPMENT && DRAW_TRANSFORM_REGIONS) {
    context.transform(mat[0], mat[1], mat[2], mat[3], mat[4], mat[5]);
    pickRegion(user, drawing, view, 0, 0, context);
  }

  context.restore();
}

function drawAiBoundingBox(context: Context, view: Viewport, drawing: Drawing, options: DrawOptions) {
  const tool = options.selectedTool as AiTool;
  const mat = createViewportMatrix2d(tempMatrix2d, view);
  const pixelRatio = getPixelRatio();
  const { w, h, x, y } = tool.bounds;

  context.save();
  context.scale(pixelRatio, pixelRatio);
  context.transform(mat[0], mat[1], mat[2], mat[3], mat[4], mat[5]);

  if (tool.showMask) {
    context.fillStyle = `rgba(0, 0, 0, ${AI_BOUNDING_BOX_MASK_ALPHA})`;
    context.fillRect(0, 0, x, drawing.height);
    context.fillRect(x + w, 0, drawing.width - w - x, drawing.height);
    context.fillRect(x, 0, w, y);
    context.fillRect(x, y + h, w, drawing.height - (y + h));
  }

  if (tool.isActive) {
    drawAnimatedFrame(context, view, tool.bounds, AI_BOUNDING_BOX_COLOR_1_ACTIVE_STR, AI_BOUNDING_BOX_COLOR_2_ACTIVE_STR, 2);
  } else {
    drawSolidFrame(context, view, tool.bounds, AI_BOUNDING_BOX_COLOR_2_ACTIVE_STR, WHITE_STR, 1);
  }

  if (!tool.isActive) {
    drawAiControl(context, x, y, AI_BOUNDING_BOX_COLOR_2_ACTIVE_STR, view);
    drawAiControl(context, x, y + h, AI_BOUNDING_BOX_COLOR_2_ACTIVE_STR, view);
    drawAiControl(context, x + w, y, AI_BOUNDING_BOX_COLOR_2_ACTIVE_STR, view);
    drawAiControl(context, x + w, y + h, AI_BOUNDING_BOX_COLOR_2_ACTIVE_STR, view);
  }

  context.restore();
}

const TEXTAREA_SELECTION_RECT_COLOR_STR = `rgba(${TEXTAREA_SELECTION_RECT_COLOR.r * 255}, ${TEXTAREA_SELECTION_RECT_COLOR.g * 255}, ${TEXTAREA_SELECTION_RECT_COLOR.b * 255}, ${TEXTAREA_SELECTION_RECT_COLOR.a})`;

function drawTextarea(context: Context, textarea: Textarea, view: Viewport, options: DrawOptions) {
  const pixelRatio = getPixelRatio();
  context.save();
  context.scale(pixelRatio, pixelRatio);

  if (options.selectedTool?.id === ToolId.Text) {
    const textTool = (options.selectedTool as TextTool);
    if (shouldRenderTextareaBoundaries(textarea, textTool, options)) drawTextareaBoundaries(context, view, textarea);
    if (shouldRenderTextareaCursor(textarea, textTool)) {
      const selection = textTool.getSelection();
      if (selection) {
        const { caretRect, selectionRects } = textarea.getCursorPosition(selection);
        if (caretRect) drawTextareaCaret(context, view, caretRect, textarea.transform);
        if (selectionRects.length > 0) drawTextareaSelectionRects(context, view, selectionRects, textarea.transform);
      }
    }
    if (shouldRenderTextareaBaselineIndicator(textarea, textTool)) drawTextareaBaselineIndicator(context, view, textarea);
    if (shouldRenderTextareaControlPoints(textarea, textTool)) drawTextareaControlPoints(context, view, textarea);
    if (shouldRenderTextareaOverflowIndicator(textarea, textTool)) drawTextareaOverflowIndicator(context, view, textarea);
  } else {
    if (options.selectedTool?.id !== ToolId.Transform) drawTextareaBoundaries(context, view, textarea, TEXTAREA_UNHOVERED_BOUNDARIES_WIDTH);
  }

  context.restore();
}
const TEXTAREA_BLUE_COLOR_STR = `rgba(${TEXTAREA_BOUNDARIES_COLOR.r * 255}, ${TEXTAREA_BOUNDARIES_COLOR.g * 255}, ${TEXTAREA_BOUNDARIES_COLOR.b * 255}, ${TEXTAREA_BOUNDARIES_COLOR.a * 255})`;
function drawTextareaBoundaries(context: Context, view: Viewport, textarea: Textarea, forceBoundariesThickness?: number, color?: string) {
  const ratio = getPixelRatio();
  const pixelSize = 1 / view.scale / getPixelRatio();

  context.save();

  const mat = createViewportMatrix2d(tempMatrix2d, view);
  context.transform(mat[0], mat[1], mat[2], mat[3], mat[4], mat[5]);

  const blueBounds = cloneBounds(textarea.bounds);
  const whiteBounds = cloneBounds(textarea.bounds);
  outsetBounds(whiteBounds, pixelSize);

  context.lineWidth = (forceBoundariesThickness ?? textarea.boundariesStrokeWidth) / view.scale / ratio;

  drawTextareaBoundry(context, whiteBounds, 'white');
  drawTextareaBoundry(context, blueBounds, color ?? TEXTAREA_BLUE_COLOR_STR);

  context.restore();
}
function drawTextareaBoundry(context: CanvasRenderingContext2D, bounds: Vec2[], color: string) {
  context.beginPath();
  context.moveTo(bounds[0][0], bounds[0][1]);
  context.lineTo(bounds[1][0], bounds[1][1]);
  context.lineTo(bounds[2][0], bounds[2][1]);
  context.lineTo(bounds[3][0], bounds[3][1]);
  context.lineTo(bounds[0][0], bounds[0][1]);
  context.closePath();
  context.strokeStyle = color;
  context.stroke();
}

function drawSolidFrame(context: Context, view: Viewport, r1: Rect, color1: string, color2: string, thickness: number) {
  const r2 = outsetRect(cloneRect(r1), thickness / view.scale);

  context.lineWidth = thickness / view.scale;

  context.strokeStyle = color1;
  context.strokeRect(r1.x, r1.y, r1.w, r1.h);

  context.strokeStyle = color2;
  context.strokeRect(r2.x, r2.y, r2.w, r2.h);
}

function drawAnimatedFrame(context: Context, view: Viewport, rect: Rect, color1: string, color2: string, thickness: number) {
  context.lineWidth = thickness / view.scale;

  context.setLineDash([4 / view.scale, 4 / view.scale]);
  context.lineDashOffset = performance.now() / 20 / view.scale + 4 / view.scale;
  context.strokeStyle = color1;
  context.strokeRect(rect.x, rect.y, rect.w, rect.h);

  context.setLineDash([4 / view.scale, 4 / view.scale]);
  context.lineDashOffset = performance.now() / 20 / view.scale;
  context.strokeStyle = color2;
  context.strokeRect(rect.x, rect.y, rect.w, rect.h);
}

function drawAiSelectionPoly(context: Context, t: AiTool, color1: string, color2: string, thickness: number) {
  if (!t.isPathEmpty()) {
    const pixelRatio = getPixelRatio();

    context.save();
    context.setTransform(1, 0, 0, 1, 0, 0);

    t.drawPath(context, true);

    context.lineWidth = thickness * pixelRatio;

    context.setLineDash([4 * pixelRatio, 4 * pixelRatio]);
    context.lineDashOffset = Math.floor(performance.now() / 20) + 4 * pixelRatio;
    context.strokeStyle = color1;
    context.stroke();

    context.setLineDash([4 * pixelRatio, 4 * pixelRatio]);
    context.lineDashOffset = Math.floor(performance.now() / 20);
    context.strokeStyle = color2;
    context.stroke();

    context.restore();
  }
}

function drawTextareaCaret(context: Context, view: Viewport, caret: Rect, transform: Mat2d) {
  context.save();
  const mat = createViewportMatrix2d(tempMatrix2d, view);
  context.transform(mat[0], mat[1], mat[2], mat[3], mat[4], mat[5]);
  const p1 = createVec2FromValues(caret.x + caret.w / 2, caret.y);
  const p2 = createVec2FromValues(caret.x + caret.w / 2, caret.y + caret.h);
  transformVec2ByMat2d(p1, p1, transform);
  transformVec2ByMat2d(p2, p2, transform);
  const thickness = clamp(distance(p1[0], p1[1], p2[0], p2[1]) * 0.05, MIN_TEXTAREA_CURSOR_WIDTH, MAX_TEXTAREA_CURSOR_WIDTH);
  context.strokeStyle = 'black';
  context.lineWidth = thickness;
  context.beginPath();
  context.moveTo(p1[0], p1[1]);
  context.lineTo(p2[0], p2[1]);
  context.closePath();
  context.stroke();
  context.restore();
}

function drawTextareaSelectionRects(context: Context, view: Viewport, selectionRects: Rect[], transform: Mat2d) {
  context.save();
  const mat = createViewportMatrix2d(tempMatrix2d, view);
  context.transform(mat[0], mat[1], mat[2], mat[3], mat[4], mat[5]);

  let rectBounds: Vec2[] = [];

  context.fillStyle = TEXTAREA_SELECTION_RECT_COLOR_STR;
  context.globalCompositeOperation = 'multiply';
  context.beginPath();
  for (const rect of selectionRects) {
    rectBounds = [createVec2(), createVec2(), createVec2(), createVec2()];
    rectToBounds(rectBounds, rect);
    transformBounds(rectBounds, transform);
    const [topLeft, topRight, bottomRight, bottomLeft] = rectBounds;
    context.moveTo(topLeft[0], topLeft[1]);
    context.lineTo(topRight[0], topRight[1]);
    context.lineTo(bottomRight[0], bottomRight[1]);
    context.lineTo(bottomLeft[0], bottomLeft[1]);
    context.lineTo(topLeft[0], topLeft[1]);
  }
  context.closePath();
  context.fill();
  context.restore();
}

function drawTextareaControlPoints(context: Context, view: Viewport, textarea: Textarea) {
  for (const controlPoint of textarea.controlPoints) {
    const { blueRect, whiteRect, thickness } = controlPoint.getDrawingInstructions(view);

    context.fillStyle = 'white';
    context.fillRect(whiteRect.x, whiteRect.y, whiteRect.w, whiteRect.h);

    context.fillStyle = TEXTAREA_BLUE_COLOR_STR;
    context.fillRect(blueRect.x, blueRect.y, blueRect.w, blueRect.h);

    if (!controlPoint.active) {
      context.fillStyle = 'white';
      context.fillRect(blueRect.x + thickness, blueRect.y + thickness, blueRect.w - 2 * thickness, blueRect.h - 2 * thickness);
    }
  }
}

function drawTextareaBaselineIndicator(context: Context, view: Viewport, textarea: Textarea) {
  const ratio = getPixelRatio();
  context.save();

  context.fillStyle = TEXTAREA_BLUE_COLOR_STR;
  context.strokeStyle = TEXTAREA_BLUE_COLOR_STR;
  context.lineWidth = round5((textarea.isHovering ? TEXTAREA_BASELINE_INDICATOR_HOVERED_LINE_THICKNESS : TEXTAREA_BASELINE_INDICATOR_UNHOVERED_LINE_THICKNESS) / view.scale / ratio);
  const baselineIndicator = textarea.getBaselineIndicator();

  const mat = createViewportMatrix2d(tempMatrix2d, view);
  context.transform(mat[0], mat[1], mat[2], mat[3], mat[4], mat[5]);

  for (const entry of baselineIndicator) {
    const { line, alignmentSquare } = entry;
    const [{ x: x1, y: y1 }, { x: x2, y: y2 }] = line;
    context.beginPath();
    context.moveTo(x1, y1);
    context.lineTo(x2, y2);
    context.closePath();
    context.fill();
    context.stroke();
    if (alignmentSquare) {
      const squareSize = getBaselineIndicatorAlignmentSquareSize(view);
      context.fillRect(alignmentSquare.x - squareSize / 2, alignmentSquare.y - squareSize / 2, squareSize, squareSize);
    }
  }

  context.restore();
}

function drawTextareaOverflowIndicator(context: Context, view: Viewport, textarea: Textarea) {
  const point = textarea.getOverflowIndicator();
  documentToScreenPoint(point, view);
  const { x, y } = point;

  const ratio = getPixelRatio();
  const size = TEXTAREA_OVERFLOW_INDICATOR_SQUARE_SIZE / ratio;

  context.fillStyle = 'white';
  context.fillRect(x - size / 2 - 2, y - size / 2 - 2, size + 4, size + 4);

  context.fillStyle = TEXTAREA_OVERFLOW_INDICATOR_RED_STR;
  context.fillRect(x - size / 2, y - size / 2, size, size);

  context.fillStyle = 'white';
  const plusThickness = size * TEXTAREA_OVERFLOW_INDICATOR_PLUS_THICKNESS;
  const plusSize = size * TEXTAREA_OVERFLOW_INDICATOR_PLUS_SIZE;
  context.fillRect(x - plusThickness / 2, y - plusSize / 2, plusThickness, plusSize);
  context.fillRect(x - plusSize / 2, y - plusThickness / 2, plusSize, plusThickness);
}

function compose(
  context: Context, layerCanvas: Canvas | undefined, layerRect: Rect, toolCanvas: Canvas,
  toolRect: Rect, toolColor: number, toolOpacity: number, selection: Mask, compositeOperation: GlobalCompositeOperation
) {
  const globalAlpha = context.globalAlpha;
  const globalCompositeOperation = context.globalCompositeOperation;

  drawImageRect(context, layerCanvas, layerRect);

  context.globalAlpha = toolOpacity;
  context.globalCompositeOperation = compositeOperation;

  if (!isRectEmpty(toolRect) && toolColor !== WHITE) {
    const toolContext = getContext2d(toolCanvas);
    toolContext.globalCompositeOperation = 'source-atop';
    toolContext.fillStyle = colorToCSS(toolColor);
    // TODO: this can be optimized to only apply to dirty region, or can be done in RenderingContext (on flush)
    toolContext.fillRect(toolRect.x, toolRect.y, toolRect.w, toolRect.h);
    toolContext.globalCompositeOperation = 'source-over';
  }

  if (!isMaskEmpty(selection)) {
    context.save();
    clipMask(context, selection);
    drawImageRect(context, toolCanvas, toolRect);
    context.restore();
  } else {
    drawImageRect(context, toolCanvas, toolRect);
  }

  context.globalAlpha = globalAlpha;
  context.globalCompositeOperation = globalCompositeOperation;
}

function composeOp(
  context: Context, layerCanvas: Canvas | undefined, layerRect: Rect, lockOpacity: boolean, selection: Mask, surface: ToolSurface
) {
  if (!surface.canvas) throw new Error('Missing surface.canvas');

  const toolCanvas = surface.canvas;
  const toolRect = surface.rect;
  const toolTransform = surface.transform;

  switch (surface.mode) {
    case CompositeOp.None:
      throw new Error('Cannot compose None');
    case CompositeOp.Draw: {
      if (DEVELOPMENT && !isMat2dIdentity(toolTransform)) throw new Error('Not supported');
      compose(context, layerCanvas, layerRect, toolCanvas, toolRect, surface.color, surface.opacity, selection,
        lockOpacity ? 'source-atop' : 'source-over');
      return true;
    }
    case CompositeOp.Erase: {
      if (DEVELOPMENT && !isMat2dIdentity(toolTransform)) throw new Error('Not supported');
      compose(context, layerCanvas, layerRect, toolCanvas, toolRect, surface.color, surface.opacity, selection,
        'destination-out');
      return true;
    }
    case CompositeOp.Move: {
      drawImageRect(context, layerCanvas, layerRect);

      if (!hasZeroTransform(surface)) {
        if (isMat2dIntegerTranslation(toolTransform)) {
          drawImageRectShifted(context, toolCanvas, toolRect, getMat2dX(toolTransform), getMat2dY(toolTransform));
        } else if (toolCanvas && toolRect.w && toolRect.h) {
          const m = toolTransform;
          context.save();
          context.transform(m[0], m[1], m[2], m[3], m[4], m[5]);
          context.imageSmoothingQuality = 'high';
          context.drawImage(toolCanvas, 0, 0);
          context.restore();
        }
      }

      return true;
    }
    default: invalidEnum(surface.mode);
  }
}

function drawLayerInternal(context: Context, canvas: Canvas | undefined, layer: Layer, r: Rect, clip: boolean, noOpacity = false) {
  if (canvas && !isRectEmpty(r)) {
    const globalCompositeOperation = context.globalCompositeOperation;
    const globalAlpha = context.globalAlpha;

    if (!clip && layer.mode !== 'normal') {
      context.globalCompositeOperation = getBlendMode(layer.mode);
    }

    context.globalAlpha = noOpacity ? 1 : layer.opacity;
    drawImageRect(context, canvas, r);
    context.globalAlpha = globalAlpha;
    context.globalCompositeOperation = globalCompositeOperation;
  }
}

function layerHasTool(layer: Layer) {
  const owner = layer.owner;

  return owner !== undefined &&
    owner.surface.layer === layer &&
    !isSurfaceEmpty(owner.surface) &&
    !!owner.surface.canvas;
}

function layerVisibleWithCanvasOrTool(layer: Layer) {
  return isLayerVisible(layer) && (!!layer.canvas || layerHasTool(layer) || (!!layer.textData && layer.textData.text !== ''));
}

function isClippingLayerWithVisibleClippedLayers(index: number, layers: Layer[]) {
  if (layers[index].clippingGroup) return false; // this is not clipping base

  // check if there is at least one visible clipped layer
  while (index > 0 && layers[index - 1].clippingGroup) {
    if (layerVisibleWithCanvasOrTool(layers[index - 1])) return true;
    index--;
  }

  return false;
}

export function fixLayerRect(layer: Layer, image: HTMLImageElement | ImageBitmap | BitmapData, errorReporter: IErrorReporter) {
  if (isRectEmpty(layer.rect)) {
    logActionInDebug(`Fixing layer rect (empty) (image: ${layer.image}, size: ${image.width}x${image.height})`);
    setRect(layer.rect, 0, 0, image.width, image.height);
  }

  if (!isIntegerRect(layer.rect)) {
    logActionInDebug(`Fixing layer rect (non-integer) (image: ${layer.image}, rect: ${rectToString(layer.rect)}, size: ${image.width}x${image.height})`);
    setRect(layer.rect, Math.round(layer.rect.x), Math.round(layer.rect.y), image.width, image.height);
  }

  if (!isTextLayer(layer) && (image.width !== layer.rect.w || image.height !== layer.rect.h)) {
    logActionInDebug(`Fixing layer rect (wrong size) (image: ${layer.image}, rect: ${rectToString(layer.rect)}, size: ${image.width}x${image.height})`);
    errorReporter.reportError(`Fixing layer rect (wrong size)`, undefined, {
      layerImage: layer.image,
      layerRect: { ...layer.rect },
      image: { type: image.constructor.name, width: image.width, height: image.height },
    });
    setRect(layer.rect, 0, 0, image.width, image.height);
  }
}
