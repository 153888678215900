import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { getAvatarPath } from '../../../common/utils';

@Component({
  selector: 'user-avatar',
  templateUrl: 'user-avatar.pug',
  styleUrls: ['user-avatar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[style.width.px]': 'size',
    '[style.height.px]': 'size',
    '[class.avatar-rounded]': 'rounded',
  },
})
export class UserAvatar {
  @Input() size = 32;
  @Input() image: string | undefined = undefined;
  @Input() rounded = false;
  get src() {
    if (this.image && /^https?:\/\//.test(this.image)) {
      return this.image;
    }
    return getAvatarPath(this.image, this.size);
  }
}
