import { MentionDirective } from './directives/mention.directive';
import { AdminModalsBoxComponent } from './admin-modals-box/admin-modals-box.component';
import { CommonModule } from '@angular/common';
import { NgModule, NgZone } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { initAngularZone } from '../../common/clientUtils';
import { Modals } from '../../services/modals';
import { CustomCheckbox } from './custom-checkbox/custom-checkbox';
import { CustomRadio } from './custom-radio/custom-radio';
import { ContextMenu, ContextMenuOutlet } from './directives/contextMenu';
import { UserColor } from './directives/cssVariable';
import { Dropdown, DropdownMenu, DropdownToggle } from './directives/dropdown';
import { LabelFor } from './directives/labelFor';
import { TOOLTIP_COMPONENTS } from './directives/tooltip';
import { ModalsBox } from './modals-box/modals-box';
import { DrawingSettings } from './modals/drawing-settings/drawing-settings';
import { CommentPipe, PIPES } from './pipes';
import { SvgIcon } from './svg-icon/svg-icon';
import { UserAvatar } from './user-avatar/user-avatar';
import { UserButton } from './user-button/user-button';
import { BindHtmlElement } from './bind-html-element';
import { SignUpBox } from './modals/sign-up-box/sign-up-box';
import { SignIn } from './modals/sign-in/sign-in';
import { SignUp } from './modals/sign-up/sign-up';
import { PriceTag } from './price-tag/price-tag';
import { COMMAND_COMPONENTS } from './directives/command';
import { SvgSrc } from './directives/svgSrc';
import { SettingsForm } from './modals/settings-form/settings-form';
import { FocusTrap } from './directives/focusTrap';
import { AlertModal } from './modals/alert-modal/alert-modal';
import { ConfirmModal } from './modals/confirm-modal/confirm-modal';
import { PromptModal } from './modals/prompt-modal/prompt-modal';
import { EditionModal } from './modals/edition-modal/edition-modal';
import { OverloadedModal } from './modals/overloaded-modal/overloaded-modal';
import { KbdShortcut } from './kbd-shortcut/kbd-shortcut';
import { CreateAccount } from './modals/create-account/create-account';
import { UserSettings } from './modals/user-settings/user-settings';
import { SettingsModal } from './modals/settings-modal/settings-modal';
import { HelpModal } from './modals/help-modal/help-modal';
import { ChangelogModal } from './modals/changelog-modal/changelog-modal';
import { Tab, TabContent, Tabset, TabTitle } from './tabset/tabset';
import { HelpContent } from './help-content/help-content';
import { KbdShortcuts } from './kbd-shortcuts/kbd-shortcuts';
import { LayerItem } from './layer-item/layer-item';
import { EditableText } from './editable-text/editable-text';
import { SORTABLE_COMPONENTS } from './directives/agSortable';
import { BrowserSupport } from './browser-support/browser-support';
import { RadioGroup, RadioValue } from './directives/radio';
import { UserButtonMini } from './user-button-mini/user-button-mini';
import { UserLimitModal } from './modals/user-limit-modal/user-limit-modal';
import { AutoFocus } from './directives/autoFocus';
import { AgDoubleTap } from './directives/agDoubleTap';
import { AgDrag } from './directives/agDrag';
import { Anchor } from './directives/anchor';
import { ColorInput } from './directives/colorInput';
import { CommandTooltipContainer } from './directives/command';
import { DrawingThumb } from './directives/drawingThumb';
import { DropFile } from './directives/dropFile';
import { Kbd } from './directives/kbd';
import { LinkCurrent } from './directives/linkCurrent';
import { PreventTouch } from './directives/preventTouch';
import { RevSrc } from './directives/revSrc';
import { StopEvents } from './directives/stopEvents';
import { SyncScroll } from './directives/syncScroll';
import { AgTooltipContainer } from './directives/tooltip';
import { DrawingDeleted } from './modals/drawing-deleted/drawing-deleted';
import { NewCanvasButton } from './new-canvas-button/new-canvas-button';
import { DrawingPassword } from './drawing-password/drawing-password';
import { PickFile } from './directives/pickFile';
import { OpenedBy } from './directives/openedBy';
import { ScheduleDemoBanner } from './banners/schedule-demo-banner';
import { FeaturesListModal } from './features-list-modal/features-list-modal.component';
import { EditorModalsBox } from './editor/editor-modals-box/editor-modals-box';
import { DisabledOnMobileModal } from './modals/disabled-on-mobile-modal/disabled-on-mobile-modal';
import { TagListModal } from './tag-list-modal/tag-list-modal.component';
import { ChoiceModal } from './modals/choice-modal/choice-modal';
import { QuestionModal } from './modals/question-modal/question-modal';
import { InputModal } from './modals/input-modal/input-modal';
import { CopyBox } from './copy-box/copy-box';
import { SettingsSelect } from './settings-select/settings-select';
import { ShapePreviewImg } from './shape-preview-img/shape-preview-img';
import { SliderBar } from './slider-bar/slider-bar';
import { AcceptAiEulaModal } from './modals/accept-ai-eula-modal/accept-ai-eula-modal';
import { EducationBanner } from './education-banner/education-banner';
import { CurvesGraph } from './curves-graph/curves-graph';
import { OptionsConfirmationModal } from './modals/options-confirmation-modal/options-confirmation-modal.component';
import { EditorPopupOutlet } from './directives/popup';
import { CustomSwitch } from './custom-switch/custom-switch';
import { IdleTimer } from './idle-timer/idle-timer.component';
import { FeatureBadge } from './feature-badge/feature-badge';
import { AiQuotaReachedModalComponent } from './modals/ai-quota-reached-modal/ai-quota-reached-modal';
import { BrushPreviewImg } from './brush-preview-img/brush-preview-img';
import { CustomInputComponent } from './custom-input/custom-input';

initAngularZone(NgZone);

const components = [
  PIPES,
  TOOLTIP_COMPONENTS,
  COMMAND_COMPONENTS,
  SORTABLE_COMPONENTS,
  SliderBar,
  BrowserSupport,
  CustomCheckbox,
  CustomInputComponent,
  CustomRadio,
  CustomSwitch,
  SvgIcon,
  UserButton,
  ModalsBox,
  EditorModalsBox,
  DisabledOnMobileModal,
  LabelFor,
  ContextMenu,
  ContextMenuOutlet,
  UserAvatar,
  UserColor,
  FeatureBadge,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DrawingSettings,
  DrawingPassword,
  SettingsForm,
  SignUpBox,
  SignIn,
  SignUp,
  PriceTag,
  SvgSrc,
  FocusTrap,
  AlertModal,
  ConfirmModal,
  PromptModal,
  EditionModal,
  OverloadedModal,
  ChoiceModal,
  QuestionModal,
  InputModal,
  KbdShortcut,
  KbdShortcuts,
  ShapePreviewImg,
  CreateAccount,
  UserSettings,
  SettingsSelect,
  SettingsModal,
  HelpModal,
  HelpContent,
  ChangelogModal,
  TabContent,
  TabTitle,
  Tabset,
  Tab,
  LayerItem,
  EditableText,
  RadioGroup,
  RadioValue,
  UserButtonMini,
  ColorInput,
  AgDrag,
  Anchor,
  DropFile,
  Kbd,
  RevSrc,
  LinkCurrent,
  PreventTouch,
  StopEvents,
  SyncScroll,
  DrawingThumb,
  AgDoubleTap,
  UserLimitModal,
  AutoFocus,
  AgTooltipContainer,
  CommandTooltipContainer,
  DrawingDeleted,
  NewCanvasButton,
  PickFile,
  OpenedBy,
  ScheduleDemoBanner,
  AdminModalsBoxComponent,
  FeaturesListModal,
  TagListModal,
  CopyBox,
  MentionDirective,
  CommentPipe,
  AcceptAiEulaModal,
  BindHtmlElement,
  EducationBanner,
  CurvesGraph,
  OptionsConfirmationModal,
  EditorPopupOutlet,
  AiQuotaReachedModalComponent,
  IdleTimer,
  BrushPreviewImg,
];

@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    CommonModule,
  ],
  declarations: components,
  exports: components,
  providers: [
    { provide: Modals, useClass: Modals },
  ],
})
export class PortalSharedModule {
}
