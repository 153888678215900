import { blobToArrayBuffer } from '../common/canvasUtils';
import { verifyFileForImport } from '../common/drawingUtils';
import { Model } from './model';

export async function importFile(model: Model, file: File | Blob, addToSequence: boolean) {
  model.importing = true;
  model.importingProgress = 0;

  try {
    const { pro = false, isSuperAdmin } = model.user;
    const buffer = await verifyFileForImport(file, pro, isSuperAdmin);
    const name = 'name' in file ? file.name.replace(/\.\w+$/, '') : 'image';
    return await model.importDrawing(name, buffer, addToSequence);
  } catch (e) {
    DEVELOPMENT && console.error(e);
    model.showError(e.message, false);

    if (e.message.startsWith('Failed to parse PSD file')) {
      blobToArrayBuffer(file)
        .then(buffer => buffer && model.errorWithData(e.message, '', new Uint8Array(buffer)))
        .catch(e => DEVELOPMENT && console.error(e));
    }
  } finally {
    model.importing = false;
    model.importingProgress = 0;
  }

  return undefined;
}
