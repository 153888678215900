import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { TeamMember } from 'shared/interfaces';

export interface TeamMembersState extends EntityState<TeamMember> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'teamMembers', resettable: true, idKey: '_id' })
export class TeamMembersStore extends EntityStore<TeamMembersState, TeamMember> {
  constructor() {
    super([]);
  }
}
