import { BRIGHTNESS_FILTER_MAX, BRIGHTNESS_FILTER_MIN, CONTRAST_FILTER_MAX, CONTRAST_FILTER_MIN } from '../constants';
import { IFiltersValues, IToolEditor, IToolModel, ToolId } from '../interfaces';
import { safeFloat } from '../toolUtils';
import { BaseFilterTool, IFilterData } from './baseFilterTool';

export class BrightnessContrastTool extends BaseFilterTool {
  id = ToolId.BrightnessContrast;
  name = 'Brightness / Contrast';
  constructor(editor: IToolEditor, model: IToolModel) {
    super(editor, model, 'brightnessContrast');
  }
  protected applyFilter() {
    if (!this.layer) throw new Error(`[${this.toolName}] Missing layer`);
    this.editor.renderer.applyBrightnessContrastFilter(this.srcData, this.model.user.surface, this.values);
  }
  protected parseData(data?: IFilterData): IFiltersValues {
    const brightness = safeFloat(data?.brightness, BRIGHTNESS_FILTER_MIN, BRIGHTNESS_FILTER_MAX);
    const contrast = safeFloat(data?.contrast, CONTRAST_FILTER_MIN, CONTRAST_FILTER_MAX);
    return { brightness, contrast };
  }
}
