import { Component, HostBinding, Input } from '@angular/core';
import { faTimes } from '../../../common/icons';
import { Analytics } from '../../../common/interfaces';
import { settingsColumns, settingsHideColumn } from '../../../common/settings';
import { sample } from '../../../common/utils';
import { sendGAEvent } from '../../../common/utilsAnalytics';
import { magma_logo } from '../../../generated/resources';
import { Model } from '../../../services/model';
import { storageGetItem, storageSetItem } from '../../../services/storage';

const SCHEDULE_DEMO_URL = 'https://magm.ai/schedule-demo';

const reasons = [
  'collaborative storyboarding',
  'a Miro-like digial painting board',
  'our art collaboration solution',
];

@Component({
  selector: 'schedule-demo-banner',
  templateUrl: 'schedule-demo-banner.pug',
  styleUrls: ['schedule-demo-banner.scss'],
})
export class ScheduleDemoBanner {
  readonly closeIcon = faTimes;
  readonly magmaLogo = magma_logo;
  @Input() standalone = false;
  private shownPromo = false;

  withReason?: string;

  constructor(public model: Model) {
  }
  get isValidRole() {
    return this.model.user.userJob && ['Art Director / Lead Artist', 'Director / Manager', 'C-Level Executive'].includes(this.model.user.userJob);
  }

  get isSchool() {
    return this.model.user.workTags?.includes('school');
  }

  get isValidIndustry() {
    return ['illustration', 'animation', 'games', 'comics', 'concept-art'].some(tag => this.model.user.workTags?.includes(tag));
  }

  @HostBinding('class.columns')
  get twoColumns() {
    return settingsColumns;
  }

  get showScheduleDemoBanner() {
    if (IS_HOSTED) {
      return false;
    }

    if (!this.withReason) {
      this.withReason = sample(reasons);
    }

    const show = this.standalone || (
      this.model.isConnected &&
      this.model.loaded === 1 &&
      storageGetItem('schedule-demo-banner-closed') !== 'true' &&
      (settingsHideColumn || window.innerWidth > 500) &&
      (!this.isSchool) && // do not show to school students
      (this.isValidIndustry || this.isValidRole)
    );

    if (!this.shownPromo && show) {
      this.shownPromo = true;
      sendGAEvent('Schedule demo', 'Showed banner', 'application');
      this.model.trackEvent(Analytics.ViewBanner, { label: 'schedule-demo', withReason: this.withReason });
    }

    return show;
  }
  runCtaAction() {
    sendGAEvent('Schedule demo', this.withReason!);

    const params = new URLSearchParams();
    const name = this.model.user.name;
    const email = this.model.user.email;
    const job = this.model.user.userJob;
    const organization = email?.split('@')?.[1];

    params.set('name', name || '');
    params.set('email', email || '');
    params.set('a1', job || '');
    params.set('a2', organization || '');

    this.model.trackEvent(Analytics.ClickBanner, { label: 'schedule-demo', withReason: this.withReason });

    window.open(SCHEDULE_DEMO_URL + '?' + params.toString(), '_blank');
  }
  closeBanner() {
    storageSetItem('schedule-demo-banner-closed', 'true');
    sendGAEvent('Schedule demo', 'Closed banner', 'application');
    this.model.trackEvent(Analytics.CloseBanner, { label: 'schedule-demo', withReason: this.withReason });
  }
}
