interface Offsets {
  left: number;
  right: number;
  top: number;
  bottom: number;
  marginTop: number;
  marginLeft: number;
}

interface Data {
  options: PositioningInternalOptions;
  target: HTMLElement;
  host: HTMLElement;
  arrow: HTMLElement | null;
  targetOffset: Offsets;
  hostOffset: Offsets;
  arrowStyle: { [key: string]: string; } | null;
  placement: string;
  placementAuto: boolean;
}

export interface PositioningInternalOptions {
  placement?: string;
  flip?: {
    enabled: boolean;
  };
  preventOverflow?: {
    enabled: boolean;
    boundariesElement?: 'viewport' | 'scrollParent' | 'window';
  };
  allowedPositions?: string[];
}

function widthOf(offsets: Offsets) {
  return offsets.right - offsets.left;
}

function heightOf(offsets: Offsets) {
  return offsets.bottom - offsets.top;
}

function sizeOf(offsets: Offsets, size: 'width' | 'height') {
  return size === 'width' ? widthOf(offsets) : heightOf(offsets);
}

function createOffsets(left: number, top: number, width: number, height: number): Offsets {
  return { left, top, right: left + width, bottom: top + height, marginTop: 0, marginLeft: 0 };
}

function initData(target: HTMLElement, host: HTMLElement, position: string, options: PositioningInternalOptions): Data {
  const hostOffset = getOffsetRectRelativeToArbitraryNode(host, findCommonOffsetParent(target, host));

  if (!position) position = 'auto';

  if (!/^(auto|top|right|bottom|left|((top|bottom) (right|left))|((right|left) (top|bottom)))$/.test(position)) {
    throw new Error(`Invalid placement: ${position}`);
  }

  const placementAuto = position === 'auto';
  const targetOffset = getTargetOffsets(target, hostOffset, position);
  const placement = computeAutoPlacement(position, hostOffset, target, host, options.allowedPositions);

  return { options, target, host, arrow: null, targetOffset, hostOffset, arrowStyle: null, placement, placementAuto };
}

export function positionElements(
  host: HTMLElement, target: HTMLElement, placement: string, _appendToBody?: boolean,
  options: PositioningInternalOptions = {}
) {
  let data = initData(target, host, placement, options);
  data = shift(data);
  data = flip(data);
  data = preventOverflow(data);
  data = arrow(data);

  const { left, top } = data.targetOffset;
  target.style.willChange = 'transform';
  target.style.top = '0px';
  target.style.left = '0px';
  target.style.transform = `translate3d(${Math.floor(left)}px, ${Math.round(top)}px, 0px)`;

  // apply styles to arrow
  if (data.arrow && data.arrowStyle) {
    Object.keys(data.arrowStyle)
      .forEach((prop: any) => data.arrow!.style[prop] = data.arrowStyle![prop]);
  }

  // update container
  let className = data.target.className;

  if (data.placementAuto) {
    className = className.replace(/bs-popover-auto/g, `bs-popover-${data.placement}`);
    className = className.replace(/bs-tooltip-auto/g, `bs-tooltip-${data.placement}`);
    className = className.replace(/\sauto/g, ` ${data.placement}`);
    if (className.indexOf('popover') !== -1 && className.indexOf('popover-auto') === -1) className += ' popover-auto';
    if (className.indexOf('tooltip') !== -1 && className.indexOf('tooltip-auto') === -1) className += ' tooltip-auto';
  }

  className = className.replace(/left|right|top|bottom/g, `${data.placement.split(' ')[0]}`);
  data.target.className = className;
}

// modifiers

function arrow(data: Data) {
  const arrowElement = data.target.querySelector<HTMLElement>('.arrow');
  if (!arrowElement) return data;

  const [placement, placementVariation] = data.placement.split(' ');
  const isVertical = placement === 'left' || placement === 'right';
  const len = isVertical ? 'height' : 'width';
  const side = isVertical ? 'top' : 'left';
  const altSide = isVertical ? 'left' : 'top';
  const arrowElementSize = getOuterSizes(arrowElement)[len];
  const { hostOffset, targetOffset } = data;

  if (isVertical) {
    // top / left side
    if (hostOffset.bottom - arrowElementSize < targetOffset.top) {
      setTop(targetOffset, targetOffset.top - targetOffset.top - (hostOffset.bottom - arrowElementSize));
    }
    // bottom / right side
    if ((hostOffset.top + arrowElementSize) > targetOffset.bottom) {
      setTop(targetOffset, targetOffset.top + hostOffset.top + arrowElementSize - targetOffset.bottom);
    }
  } else {
    // top / left side
    if (hostOffset.right - arrowElementSize < targetOffset.left) {
      setLeft(targetOffset, targetOffset.left - targetOffset.left - (hostOffset.right - arrowElementSize));
    }
    // bottom / right side
    if ((hostOffset.left + arrowElementSize) > targetOffset.right) {
      setLeft(targetOffset, targetOffset.left + hostOffset.left + arrowElementSize - targetOffset.right);
    }
  }

  // Compute the sideValue using the updated target offsets
  // take target margin in account because we don't have this info available
  const css = getComputedStyle(data.target);
  const targetMarginSide = parseFloat((isVertical ? css.marginTop : css.marginLeft) || '0');
  const targetBorderSide = parseFloat((isVertical ? css.borderTopWidth : css.borderLeftWidth) || '0');

  // compute center of the target
  let center: number;

  if (!placementVariation) {
    center = hostOffset[side] + sizeOf(hostOffset, len) / 2 - arrowElementSize / 2;
  } else {
    const targetBorderRadius = parseFloat(css.borderRadius || '0');
    const targetSideArrowOffset = targetMarginSide + targetBorderSide + targetBorderRadius;
    center = side === placementVariation ?
      hostOffset[side] + targetSideArrowOffset :
      hostOffset[side] + (sizeOf(hostOffset, len) - targetSideArrowOffset);
  }

  let sideValue = center - targetOffset[side] - targetMarginSide - targetBorderSide;
  // prevent arrowElement from being placed not contiguously to its target
  sideValue = Math.max(Math.min(sizeOf(targetOffset, len) - arrowElementSize, sideValue), 0);
  // make sure to unset any eventual altSide value from the DOM node
  data.arrowStyle = { [side]: `${Math.round(sideValue)}px`, [altSide]: '' };
  data.arrow = arrowElement;
  return data;
}

function flip(data: Data): Data {
  if (!data.options.flip?.enabled) {
    data.targetOffset = {
      ...data.targetOffset,
      ...getTargetOffsets(data.target, data.hostOffset, data.placement)
    };
    return data;
  }

  const boundaries = getBoundaries(data.target, data.host, 0, 'viewport');
  let [placement, variation = ''] = data.placement.split(' ');
  const offsetsHost = data.hostOffset;
  const { target, host } = data;
  const adaptivePosition = computeAutoPlacement('auto', offsetsHost, target, host, data.options.allowedPositions);
  const flipOrder = [placement, adaptivePosition];

  flipOrder.forEach((step, index) => {
    if (placement !== step || flipOrder.length === index + 1) return;

    placement = data.placement.split(' ')[0];

    const targetOffsets = data.targetOffset;
    const hostOffsets = data.hostOffset;

    // using floor because the host offsets may contain decimals we are not going to consider here
    const overlapsRef =
      (placement === 'left' && Math.floor(targetOffsets.right) > Math.floor(hostOffsets.left)) ||
      (placement === 'right' && Math.floor(targetOffsets.left) < Math.floor(hostOffsets.right)) ||
      (placement === 'top' && Math.floor(targetOffsets.bottom) > Math.floor(hostOffsets.top)) ||
      (placement === 'bottom' && Math.floor(targetOffsets.top) < Math.floor(hostOffsets.bottom));

    const overflowsLeft = Math.floor(targetOffsets.left) < Math.floor(boundaries.left);
    const overflowsRight = Math.floor(targetOffsets.right) > Math.floor(boundaries.right);
    const overflowsTop = Math.floor(targetOffsets.top) < Math.floor(boundaries.top);
    const overflowsBottom = Math.floor(targetOffsets.bottom) > Math.floor(boundaries.bottom);

    const overflowsBoundaries =
      (placement === 'left' && overflowsLeft) ||
      (placement === 'right' && overflowsRight) ||
      (placement === 'top' && overflowsTop) ||
      (placement === 'bottom' && overflowsBottom);

    // flip the variation if required
    const isVertical = ['top', 'bottom'].indexOf(placement) !== -1;
    const flippedVariation =
      ((isVertical && variation === 'left' && overflowsLeft) ||
        (isVertical && variation === 'right' && overflowsRight) ||
        (!isVertical && variation === 'left' && overflowsTop) ||
        (!isVertical && variation === 'right' && overflowsBottom));

    if (overlapsRef || overflowsBoundaries || flippedVariation) {
      if (overlapsRef || overflowsBoundaries) {
        placement = flipOrder[index + 1];
      }

      if (flippedVariation) {
        if (variation === 'right') variation = 'left';
        else if (variation === 'left') variation = 'right';
      }

      data.placement = placement + (variation ? ` ${variation}` : '');
      data.targetOffset = {
        ...data.targetOffset,
        ...getTargetOffsets(data.target, data.hostOffset, data.placement)
      };
    }
  });

  return data;
}

function shift(data: Data): Data {
  const [basePlacement, shiftVariation] = data.placement.split(' ');

  if (shiftVariation) {
    const { hostOffset, targetOffset } = data;
    const isVertical = basePlacement === 'bottom' || basePlacement === 'top';
    const side = isVertical ? 'left' : 'top';

    if (isVertical) {
      setLeft(targetOffset, hostOffset.left);
    } else {
      setTop(targetOffset, hostOffset.top);
    }

    if (side !== shiftVariation) {
      if (isVertical) {
        setLeft(targetOffset, targetOffset.left + widthOf(hostOffset) - widthOf(targetOffset));
      } else {
        setTop(targetOffset, targetOffset.top + heightOf(hostOffset) - heightOf(targetOffset));
      }
    }
  }

  return data;
}

function setTop(offsets: Offsets, value: number) {
  const height = offsets.bottom - offsets.top;
  offsets.top = value;
  offsets.bottom = offsets.top + height;
}

function setLeft(offsets: Offsets, value: number) {
  const width = offsets.right - offsets.left;
  offsets.left = value;
  offsets.right = offsets.left + width;
}

function preventOverflow(data: Data) {
  if (!data.options.preventOverflow?.enabled) return data;

  // NOTE: DOM access here
  // resets the targetOffsets's position so that the document size can be calculated excluding
  // the size of the targetOffsets element itself
  const style = data.target.style; // assignment to help minification
  const { top, left, transform } = style;
  style.top = '';
  style.left = '';
  style.transform = '';

  const boundariesElement = data.options.preventOverflow!.boundariesElement || 'scrollParent';
  const boundaries = getBoundaries(data.target, data.host, 4, boundariesElement);

  // restores the original style properties after the offsets have been computed
  style.top = top;
  style.left = left;
  style.transform = transform;
  const target = data.targetOffset;
  if (target.left < boundaries.left) setLeft(target, Math.max(target.left, boundaries.left));
  if (target.right > boundaries.right) setLeft(target, Math.min(target.left, boundaries.right - widthOf(target)));
  if (target.top < boundaries.top) setTop(target, Math.max(target.top, boundaries.top));
  if (target.bottom > boundaries.bottom) setTop(target, Math.min(target.top, boundaries.bottom - heightOf(target)));

  return data;
}

// utils

function getTargetOffsets(target: HTMLElement, hostOffsets: Offsets, position: string): Offsets {
  const placement = position.split(' ')[0];
  const targetRect = getOuterSizes(target);
  const targetOffsets = createOffsets(0, 0, targetRect.width, targetRect.height);

  if (placement === 'left' || placement === 'right') {
    setTop(targetOffsets, hostOffsets.top + heightOf(hostOffsets) / 2 - targetRect.height / 2);
    setLeft(targetOffsets, placement === 'left' ? hostOffsets.left - targetRect.width : hostOffsets.right);
  } else {
    setLeft(targetOffsets, hostOffsets.left + widthOf(hostOffsets) / 2 - targetRect.width / 2);
    setTop(targetOffsets, placement === 'top' ? hostOffsets.top - targetRect.height : hostOffsets.bottom);
  }

  return targetOffsets;
}

function getOuterSizes(element: HTMLElement) {
  const styles = element.ownerDocument.defaultView?.getComputedStyle(element);
  let x = 0, y = 0;

  if (styles) {
    x = parseFloat(styles.marginTop || '0') + parseFloat(styles.marginBottom || '0');
    y = parseFloat(styles.marginLeft || '0') + parseFloat(styles.marginRight || '0');
  }

  return { width: element.offsetWidth + y, height: element.offsetHeight + x };
}

function computeAutoPlacement(
  placement: string, refRect: Offsets, target: HTMLElement, host: HTMLElement,
  allowedPositions: string[] = ['top', 'bottom', 'right', 'left'], boundariesElement = 'viewport', padding = 0
) {
  if (placement.indexOf('auto') === -1) return placement;

  const boundaries = getBoundaries(target, host, padding, boundariesElement);
  const rects = [
    { key: 'top', width: widthOf(boundaries), height: refRect.top - boundaries.top },
    { key: 'right', width: boundaries.right - refRect.right, height: heightOf(boundaries) },
    { key: 'bottom', width: widthOf(boundaries), height: boundaries.bottom - refRect.bottom },
    { key: 'left', width: refRect.left - boundaries.left, height: heightOf(boundaries) }
  ];
  const sortedAreas = rects.sort((a, b) => b.width * b.height - a.width * a.height);
  let filteredAreas = sortedAreas.filter(({ width, height }) => width >= target.clientWidth && height >= target.clientHeight);
  filteredAreas = filteredAreas.filter(position => allowedPositions.some(allowedPosition => allowedPosition === position.key));
  const computedPlacement = filteredAreas.length > 0 ? filteredAreas[0].key : sortedAreas[0].key;
  const variation = placement.split(' ')[1];

  // for tooltip on auto position
  target.className = target.className.replace(/bs-tooltip-auto/g, `bs-tooltip-${computedPlacement}`);

  return `${computedPlacement}${variation ? ` ${variation}` : ''}`;
}

function isFixed(node: Node): boolean {
  if (node.nodeName === 'BODY' || node.nodeName === 'HTML') return false;
  if (node.nodeType === Node.ELEMENT_NODE && getComputedStyle(node as Element).position === 'fixed') return true;
  return isFixed(getParentNode(node));
}

function getBoundaries(target: HTMLElement, host: HTMLElement, padding = 0, boundariesElement: string) {
  let boundaries = createOffsets(0, 0, 0, 0);
  const offsetParent = findCommonOffsetParent(target, host);

  // Handle viewport case
  if (boundariesElement === 'viewport') {
    boundaries = getViewportOffsetRectRelativeToArtbitraryNode(offsetParent);
  } else {
    // Handle other cases based on DOM element used as boundaries
    let boundariesNode: Node;

    if (boundariesElement === 'scrollParent') {
      boundariesNode = getScrollParent(getParentNode(host));

      if (boundariesNode.nodeName === 'BODY') {
        boundariesNode = target.ownerDocument.documentElement;
      }
    } else if (boundariesElement === 'window') {
      boundariesNode = target.ownerDocument.documentElement;
    } else {
      throw new Error('Invalid boundariesElement');
      // boundariesNode = boundariesElement;
    }

    if (boundariesNode.nodeType === Node.ELEMENT_NODE) {
      const offsets = getOffsetRectRelativeToArbitraryNode(boundariesNode as HTMLElement, offsetParent);

      // In case of HTML, we need a different computation
      if (boundariesNode.nodeName === 'HTML' && !isFixed(offsetParent)) {
        const { height, width } = getWindowSizes(target.ownerDocument);
        boundaries.top += offsets.top - offsets.marginTop;
        boundaries.bottom = height + offsets.top;
        boundaries.left += offsets.left - offsets.marginLeft;
        boundaries.right = width + offsets.left;
      } else {
        boundaries = offsets; // for all the other DOM elements, this one is good
      }
    }
  }

  boundaries.left += padding;
  boundaries.top += padding;
  boundaries.right -= padding;
  boundaries.bottom -= padding;
  return boundaries;
}

function getViewportOffsetRectRelativeToArtbitraryNode(element: HTMLElement): Offsets {
  const html = element.ownerDocument.documentElement;
  const relativeOffset = getOffsetRectRelativeToArbitraryNode(element, html);
  const scrollElement = getScrollElement(html);

  return createOffsets(
    scrollElement.scrollLeft - relativeOffset.left + relativeOffset.marginLeft,
    scrollElement.scrollTop - relativeOffset.top + relativeOffset.marginTop,
    Math.max(html.clientWidth, window.innerWidth || 0),
    Math.max(html.clientHeight, window.innerHeight || 0));
}

function getOffsetRectRelativeToArbitraryNode(children: HTMLElement, parent: HTMLElement): Offsets {
  const childrenRect = getBoundingClientRect(children);
  const parentRect = getBoundingClientRect(parent);
  const scrollParent = getScrollParent(children);
  const styles = getComputedStyle(parent);
  const borderTopWidth = parseFloat(styles.borderTopWidth || '0');
  const borderLeftWidth = parseFloat(styles.borderLeftWidth || '0');
  const offsets = createOffsets(
    childrenRect.left - parentRect.left - borderLeftWidth,
    childrenRect.top - parentRect.top - borderTopWidth,
    widthOf(childrenRect),
    heightOf(childrenRect));

  if (parent === scrollParent && scrollParent.nodeName !== 'BODY') {
    const scrollElement = getScrollElement(parent);
    offsets.top += scrollElement.scrollTop;
    offsets.bottom += scrollElement.scrollTop;
    offsets.left += scrollElement.scrollLeft;
    offsets.right += scrollElement.scrollLeft;
  }

  return offsets;
}

function findCommonOffsetParent(element1: HTMLElement, element2: HTMLElement): any {
  // This check is needed to avoid errors in case one of the elements isn't defined for any reason
  if (!element1 || !element1.nodeType || !element2 || !element2.nodeType) {
    return document.documentElement;
  }

  // Here we make sure to give as "start" the element that comes first in the DOM
  const order = element1.compareDocumentPosition(element2) & Node.DOCUMENT_POSITION_FOLLOWING;
  const start = order ? element1 : element2;
  const end = order ? element2 : element1;

  // Get common ancestor container
  const range = document.createRange();
  range.setStart(start, 0);
  range.setEnd(end, 0);
  const { commonAncestorContainer } = range;

  // Both nodes are inside #document
  if ((element1 !== commonAncestorContainer && element2 !== commonAncestorContainer) || start.contains(end)) {
    if (isOffsetContainer(commonAncestorContainer as any)) {
      return commonAncestorContainer;
    }
    return getOffsetParent(commonAncestorContainer as any);
  }

  // one of the nodes is inside shadowDOM, find which one
  const element1root = getRoot(element1);
  if (element1root.host) {
    return findCommonOffsetParent(element1root.host, element2);
  } else {
    return findCommonOffsetParent(element1, getRoot(element2).host);
  }
}

function getRoot(node: Node): any {
  if (node.parentNode) return getRoot(node.parentNode);
  return node;
}

function isOffsetContainer(e: HTMLElement) {
  return e.nodeName !== 'BODY' && (e.nodeName === 'HTML' || getOffsetParent(e.firstElementChild as any) === e);
}

function getOffsetParent(element: HTMLElement | null): HTMLElement | null {
  if (!element) return document.documentElement;

  let offsetParent = element.offsetParent; // NOTE: 1 DOM access here
  let sibling: HTMLElement | null = null;  // Skip hidden elements which don't have an offsetParent

  while (offsetParent === null && element.nextElementSibling && sibling !== element.nextElementSibling) {
    sibling = element.nextElementSibling as HTMLElement;
    offsetParent = sibling.offsetParent;
  }

  const nodeName = offsetParent && offsetParent.nodeName;

  if (!nodeName || nodeName === 'BODY' || nodeName === 'HTML') {
    return sibling ? sibling.ownerDocument.documentElement : document.documentElement;
  }

  // .offsetParent will return the closest TH, TD or TABLE in case
  if (
    offsetParent && ['TH', 'TD', 'TABLE'].indexOf(offsetParent.nodeName) !== -1 &&
    getComputedStyle(offsetParent).position === 'static'
  ) {
    return getOffsetParent(offsetParent as any);
  }

  return offsetParent as HTMLElement | null;
}

function getScrollParent(element: Node): Node {
  // Return body, `getScroll` will take care to get the correct `scrollTop` from it
  if (!element) return document.body;
  if (element.nodeName === 'HTML' || element.nodeName === 'BODY') return (element.ownerDocument as any).body;
  if (element.nodeName === '#document') return (element as any).body;

  // Firefox want us to check `-x` and `-y` variations as well
  if (element.nodeType === Node.ELEMENT_NODE) {
    const { overflow, overflowX, overflowY } = getComputedStyle(element as Element);
    if (/(auto|scroll|overlay)/.test(`${overflow}${overflowY}${overflowX}`)) {
      return element;
    }
  }

  return getScrollParent(getParentNode(element));
}

function getBoundingClientRect(element: HTMLElement): Offsets {
  const clientRect = element.getBoundingClientRect();
  const result = createOffsets(clientRect.left, clientRect.top, clientRect.width, clientRect.height);

  // subtract scrollbar size from sizes
  const sizes = element.nodeName === 'HTML' ? getWindowSizes(element.ownerDocument) : { width: undefined, height: undefined };
  const width = sizes.width || element.clientWidth || (result.right - result.left);
  const height = sizes.height || element.clientHeight || (result.bottom - result.top);
  let horizScrollbar = element.offsetWidth - width;
  let vertScrollbar = element.offsetHeight - height;

  // if an hypothetical scrollbar is detected, we must be sure it's not a `border`
  if (horizScrollbar || vertScrollbar) {
    const styles = getComputedStyle(element);
    horizScrollbar -= parseFloat(styles.borderLeftWidth || '0') + parseFloat(styles.borderRightWidth || '0');
    vertScrollbar -= parseFloat(styles.borderTopWidth || '0') + parseFloat(styles.borderBottomWidth || '0');
    result.right -= horizScrollbar;
    result.bottom -= vertScrollbar;
  }

  return result;
}

function getWindowSizes(document: Document) {
  const body = document.body;
  const html = document.documentElement;
  // assume body/html are null (we get some rare errors where that's the case)
  const width = (body && html) ? Math.max(body.offsetWidth, body.scrollWidth, html.clientWidth, html.offsetWidth, html.scrollWidth, 0) : 0;
  const height = (body && html) ? Math.max(body.offsetHeight, body.scrollHeight, html.clientHeight, html.offsetHeight, html.scrollHeight, 0) : 0;
  return { width, height };
}

function getParentNode(element: Node): Node {
  return element.nodeName === 'HTML' ? element : (element.parentNode || (element as any).host);
}

function getScrollElement(element: HTMLElement): Element {
  if (element.nodeName === 'BODY' || element.nodeName === 'HTML') {
    return element.ownerDocument.scrollingElement || element.ownerDocument.documentElement;
  } else {
    return element;
  }
}
