import {
  createBinaryWriter, writeUint32, writeInt32, getWriterBuffer, writeInt16, BinaryWriter, writeUint8,
  BinaryReader, readUint8, createBinaryReader, readUint32, readInt32, readInt16
} from 'ag-sockets/dist/browser';
import { ClipType, executeClip } from './clipper';
import {
  getPolySegmentBounds, addPolySegmentPoint, createPolySegment, getPolyBounds, addPolyPoint, isPolyEmpty,
  createPoly, createPolyRect
} from './poly';
import { isRectEmpty, rectContainsXY } from './rect';
import { Rect, Poly, PolySegment, Vec2 } from './interfaces';

function getX(arr: Int32Array, index: number) {
  return arr[index << 1];
}

function getY(arr: Int32Array, index: number) {
  return arr[(index << 1) + 1];
}

export function union(a: Poly, b: Poly, simplify = false) {
  if (isPolyEmpty(a)) return b;
  if (isPolyEmpty(b)) return a;
  return executeClip(a, b, ClipType.Union, simplify);
}

export function xor(a: Poly, b: Poly, simplify = false) {
  return executeClip(a, b, ClipType.Xor, simplify);
}

export function intersection(a: Poly, b: Poly, simplify = false) {
  return executeClip(a, b, ClipType.Intersection, simplify);
}

export function difference(a: Poly, b: Poly, simplify = false) {
  return executeClip(a, b, ClipType.Difference, simplify);
}

export function cropPoly(poly: Poly, x: number, y: number, w: number, h: number) {
  const r = x + w;
  const b = y + h;

  for (const { items, size } of poly) {
    const size2 = size << 1;

    for (let i = 0; i < size2; i += 2) {
      const ix = items[i];
      const iy = items[i + 1];

      if (ix < x || ix > r || iy < y || iy > b) {
        return intersection(poly, createPolyRect(x, y, w, h), true);
      }
    }
  }

  return poly;
}

const enum Direction {
  LEFT = 0,
  UP = 1,
  RIGHT = 2,
  DOWN = 3,
}

export function createOutline(
  minX: number, minY: number, maxX: number, maxY: number, inside: (x: number, y: number) => boolean
): Poly {
  const poly = createPoly();

  // TODO: special handling for 1px

  function shouldGoRight(x: number, y: number, dir: Direction) {
    x = dir === Direction.LEFT || dir === Direction.DOWN ? x - 1 : x;
    y = dir === Direction.LEFT || dir === Direction.UP ? y - 1 : y;
    return !inside(x, y);
  }

  function shouldGoLeft(x: number, y: number, dir: Direction) {
    x = dir === Direction.LEFT || dir === Direction.UP ? x - 1 : x;
    y = dir === Direction.RIGHT || dir === Direction.UP ? y - 1 : y;
    return inside(x, y);
  }

  let x = 0;
  let y = 0;
  let done = false;

  for (y = minY; y < maxY; y++) {
    for (x = minX; x < maxX; x++) {
      if (inside(x, y)) {
        done = true;
        break;
      }
    }

    if (done) break;
  }

  if (!done) return poly;

  const firstX = x;
  const firstY = y;

  addPolyPoint(poly, x, y);
  x++;

  let dir = Direction.RIGHT;
  let len = 1000000;
  const dx = [-1, 0, 1, 0];
  const dy = [0, -1, 0, 1];

  do {
    if (shouldGoRight(x, y, dir)) {
      addPolyPoint(poly, x, y);
      dir = (dir + 1) % 4;
    } else if (shouldGoLeft(x, y, dir)) {
      addPolyPoint(poly, x, y);
      dir = (dir + 3) % 4;
    }

    x += dx[dir];
    y += dy[dir];
  } while (--len && (x !== firstX || y !== firstY));

  if (x !== firstX || y !== firstY)
    throw new Error('failed to create outline');

  return poly;
}

export function createEllipseOutline(rect: Rect): Poly {
  var cx = rect.x + rect.w / 2;
  var cy = rect.y + rect.h / 2;
  var rx = rect.w / 2;
  var ry = rect.h / 2;

  var minX = Math.floor(cx - rx + 0.5);
  var minY = Math.floor(cy - ry + 0.5);
  var maxX = Math.ceil(cx + rx - 0.5);
  var maxY = Math.ceil(cy + ry - 0.5);

  return createOutline(minX, minY, maxX, maxY, (x, y) => {
    var dx = (x + 0.5) - cx;
    var dy = (y + 0.5) - cy;
    return (dx * dx) / (rx * rx) + (dy * dy) / (ry * ry) <= 1;
  });
}

interface IPointChecker {
  (x: number, y: number): boolean;
}

function addPolyRegion(minX: number, minY: number, maxX: number, maxY: number, pointChecker: IPointChecker): Poly {
  let result = createOutline(minX, minY, maxX, maxY, (x, y) => x >= minX && x <= maxX && y >= minY && y <= maxY && pointChecker(x, y));
  const bounds = getPolyBounds(result);

  if (!isRectEmpty(bounds)) {
    const boundsRight = bounds.x + bounds.w + 1;
    const boundsBottom = bounds.y + bounds.h + 1;

    if ((maxX - boundsRight) > 2)
      result = union(result, addPolyRegion(boundsRight, bounds.y, maxX, maxY, pointChecker));

    if ((maxY - boundsBottom) > 2 && (boundsRight - minX) > 2)
      result = union(result, addPolyRegion(minX, boundsBottom, boundsRight, maxY, pointChecker));
  }

  return result;
}

export function createFastPointChecker(poly: PolySegment, bounds: Rect): IPointChecker {
  const { items, size } = poly;
  let jy = getY(items, size - 1);
  const lines: number[][] = [];
  const top = bounds.y | 0;

  for (let y = top; y < (bounds.y + bounds.h + 1); y++) {
    lines[y - top] = [];
  }

  for (let i = 0, j = 1; i < size; i++, j += 2) {
    const iy = items[j];
    const minY = Math.round(Math.min(iy, jy)) | 0;
    const maxY = Math.round(Math.max(iy, jy)) | 0;

    for (let k = minY; k < maxY; k++) {
      lines[k - top].push(i); // TODO: calc mid point here ?
    }

    jy = iy;
  }

  return function (x: number, y: number): boolean {
    if (!rectContainsXY(bounds, x + 0.5, y + 0.5))
      return false;

    const line = lines[(y - top) | 0];
    let oddNodes = false;

    x += 0.5;
    y += 0.5;

    for (let l = 0; l < line.length; l++) {
      const i = line[l];
      const j = i === 0 ? size - 1 : i - 1;
      const ix = getX(items, i);
      const iy = getY(items, i);
      const jx = getX(items, j);
      const jy = getY(items, j);

      if ((ix + (y - iy) / (jy - iy) * (jx - ix) < x)) {
        oddNodes = !oddNodes;
      }
    }

    return oddNodes;
  };
}

export function createPolyOutline(poly: Poly): Poly {
  let result = createPoly();

  for (const s of poly) {
    const bounds = getPolySegmentBounds(s);
    const minX = Math.floor(bounds.x + 0.5);
    const minY = Math.floor(bounds.y + 0.5);
    const maxX = Math.ceil(bounds.x + bounds.w - 0.5);
    const maxY = Math.ceil(bounds.y + bounds.h - 0.5);
    const pointChecker = createFastPointChecker(s, bounds);

    result = union(result, addPolyRegion(minX, minY, maxX, maxY, pointChecker));
  }

  return result;
}

function createBitmapPointChecker(bitmap: ImageData, bounds: Rect): IPointChecker {
  return (x: number, y: number) => {
    if (!rectContainsXY(bounds, x + 0.5, y + 0.5)) return false;
    return bitmap.data[4 * ((x | 0) + (y | 0) * bitmap.width) + 3] > 127; // above 50% alpha
  };
}

export function createPolyOutlineFromBitmap(bitmap: ImageData, bounds: Rect) {
  const pointChecker = createBitmapPointChecker(bitmap, bounds);
  return addPolyRegion(bounds.x, bounds.y, bounds.x + bounds.w, bounds.y + bounds.h, pointChecker);
}

export function pointInsidePolygon(x: number, y: number, polygon: number[][] | Vec2[]) {
  let inside = true;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i][0];
    const yi = polygon[i][1];
    const xj = polygon[j][0];
    const yj = polygon[j][1];

    if (((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi)) {
      inside = !inside;
    }
  }

  return !inside;
}

function writeDelta(writer: BinaryWriter, delta: number) {
  if (delta >= -63 && delta <= 64) {
    writeUint8(writer, delta + 0x3f);
  } else {
    delta += 0x3fff;
    writeUint8(writer, (delta & 0x7f) | 0x80);
    writeUint8(writer, delta >> 7);
  }
}

function readDelta(reader: BinaryReader) {
  const a = readUint8(reader);

  if ((a & 0x80) === 0) {
    return a - 0x3f;
  } else {
    const b = readUint8(reader);
    return ((a & 0x7f) | (b << 7)) - 0x3fff;
  }
}

export function compressPoly(poly: Poly) {
  const segmentCount = poly.length;
  let points = 0;

  for (const s of poly) {
    points += s.size;
  }

  const maxSize = points * 2 + segmentCount * (4 + 2 + 2) + 4;
  const writer = createBinaryWriter(maxSize);

  writeUint32(writer, segmentCount);

  for (const { items, size } of poly) {
    let lastX = items[0];
    let lastY = items[1];

    const startH = (items[2] - lastX) !== 0;
    const bit = (startH ? 1 : 0) | 0;

    writeInt32(writer, startH ? size : -size);
    writeInt16(writer, lastX);
    writeInt16(writer, lastY);

    for (let j = 1, k = 2; j < size; j++, k += 2) {
      if ((j & 1) === bit) {
        const x = items[k];
        writeDelta(writer, x - lastX);
        lastX = x;
      } else {
        const y = items[k + 1];
        writeDelta(writer, y - lastY);
        lastY = y;
      }
    }
  }

  return getWriterBuffer(writer);
}

export function decompressPoly(data: Uint8Array) {
  const reader = createBinaryReader(data);
  const segmentCount = readUint32(reader);
  const segments: PolySegment[] = [];

  for (let i = 0; i < segmentCount; i++) {
    const pointCountValue = readInt32(reader);
    const pointCount = Math.abs(pointCountValue);
    const segment = createPolySegment(pointCount);
    const startH = pointCountValue >= 0;
    const bit = (startH ? 1 : 0) | 0;
    let lastX = readInt16(reader);
    let lastY = readInt16(reader);
    addPolySegmentPoint(segment, lastX, lastY);

    for (let j = 1; j < pointCount; j++) {
      if ((j & 1) === bit) {
        lastX += readDelta(reader);
      } else {
        lastY += readDelta(reader);
      }

      addPolySegmentPoint(segment, lastX, lastY);
    }

    segments.push(segment);
  }

  return createPoly(segments);
}

export function polySize(poly: Poly | undefined) {
  let total = 0;

  if (poly) {
    for (let i = 0; i < poly.length; i++) {
      total += poly[i].size * 2 * 4;
    }
  }

  return total;
}
