import { AppClientOptions, DrawingPermissions } from './interfaces';

function attr(name: string): string | undefined {
  return typeof document !== 'undefined' ? (document.body?.getAttribute(name) || undefined) : undefined;
}

export function meta(name: string) {
  const meta = document.querySelector(`meta[name="${name}"]`);
  return meta && meta.getAttribute('content') || '';
}

export function htmlData(id: string): string | undefined {
  const element = typeof document !== 'undefined' ? document.getElementById(id) : undefined;
  return element?.innerHTML;
}

export function htmlJson<T extends object>(id: string): T | undefined {
  try {
    return JSON.parse(htmlData(id) || 'null') as T;
  } catch {
    console.error(`Error loading JSON data at html id "${id}"`);
    return undefined;
  }
}

export const options: AppClientOptions = {
  signUps: [],
};

if (!SERVER) {
  try {
    Object.assign(options, JSON.parse(htmlData('client-options') || ''));
  } catch (err) {
    DEVELOPMENT && console.error(err);
  }
}

// do not use these on the server
export const apiPath = IS_PORTAL ? '/editor-api/' : '/api/';
export const drawingsPath = IS_PORTAL ? '/d/' : '/';
export const avatarPathTemplate = `${apiPath}avatar/%SIZE%/%NAME%`;
export const signInToView = !!options.signInToView;
export const activeSessions = !!options.activeSessions;
export const allowAnonymousUsers = !!options.allowAnonymousUsers;
export const newUsersRedirectUrl = options.newUsersRedirectUrl;
export const disableMyArtdesk = !!options.disableMyArtdesk;
export const disableCreatingTeams = !!options.disableCreatingTeams;
export const disableJoiningAndLeavingTeams = !!options.disableJoiningAndLeavingTeams;
export const disableSharingAsPng = !!options.disableSharingAsPng;
export const voiceChatUrl = options.voiceChat;
export const signUps = options.signUps ?? [];
export const features = options.features ?? [];

export const isFromSocial = attr('data-social') || options.fromSocial;
export const socketOptions = htmlData('socket-options') || '';
export const socketOptions2 = htmlData('socket-options-2') || '';

export interface DrawingPermission {
  key: keyof DrawingPermissions;
  label: string;
  title: string;
}

export const DRAWING_PERMISSIONS: DrawingPermission[] = [
  { key: 'ownLayer', label: 'Own layers', title: 'Allow these users to take and own free layers' },
  { key: 'takeOver', label: 'Take over layers', title: 'Allow these users to take over layer from other users' },
  { key: 'reorderLayers', label: 'Re-order layers', title: 'Allow these users to reorder layers' },
  { key: 'addRemoveLayer', label: 'Add/remove layers', title: 'Allow these users to add and remove layers' },
  { key: 'drawingSettings', label: 'Drawing settings', title: 'Allow these users to modify drawing settings' },
  { key: 'paste', label: 'Paste', title: 'Allow these users to paste images' },
  { key: 'chat', label: 'Chat', title: 'Allow these users to use chat' },
];

if (IS_PORTAL) {
  DRAWING_PERMISSIONS.push({ key: 'cursors', label: 'Show cursors', title: 'Show cursors for these users' });
  DRAWING_PERMISSIONS.push({ key: 'createComments', label: 'Create comments', title: 'Allow users to create comments' });
}

if (voiceChatUrl) {
  DRAWING_PERMISSIONS.push({ key: 'voiceListen', label: 'Voice call - Listen', title: 'Allow these users to join and listend in voice call' });
  DRAWING_PERMISSIONS.push({ key: 'voiceTalk', label: 'Voice call - Talk', title: 'Allow these users to join and speak in voice call' });
}

if (IS_PORTAL) {
  DRAWING_PERMISSIONS.push({ key: 'startPresentation', label: 'Start Presentation', title: 'Allow these users to start presentation mode' });
  DRAWING_PERMISSIONS.push({ key: 'takeOverPresentation', label: 'Take over Presentation', title: 'Allow these users to take over presentation mode' });
}