import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { SECOND } from '../../../common/constants';


@Component({
  selector: 'idle-tracker',
  template: ''
})
export class IdleTimer {
  @Input() timeout = 10 * SECOND;
  @Input() reload = false;
  @Output() onTimeout = new EventEmitter<boolean>();

  private interval: any;
  private timeoutTracker: any;
  private eventHandler: () => void = () => {};
  expiredTime: number = Date.now() + this.timeout;

  constructor() {
    this.setup();
  }

  setup() {
    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  ngOnChanges(_changes: SimpleChanges) {
    this.cleanUp();
    this.setup();
  }

  private startInterval(): void {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      if (this.expiredTime < Date.now()) {
          this.onTimeout.emit(true);
          this.cleanUp();
      }
    }, 1000);
  }

  private updateExpiredTime(): void {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      this.expiredTime = Date.now() + this.timeout;
    }, 300);
  }

  private tracker(): void {
    window.addEventListener('mousemove', this.eventHandler);
    window.addEventListener('scroll', this.eventHandler);
    window.addEventListener('keydown', this.eventHandler);
  }

  cleanUp(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    window.removeEventListener('mousemove', this.eventHandler);
    window.removeEventListener('scroll', this.eventHandler);
    window.removeEventListener('keydown', this.eventHandler);
  }

  ngOnDestroy() {
    this.cleanUp();
  }
}