import { invalidEnumReturn } from '../common/baseUtils';
import { colorToHSVA } from '../common/color';
import { HistoryStats, IToolEditor, Layer, Rect, Texture, TextureFormat, User, WebGLResources } from '../common/interfaces';
import { isLayerVisible } from '../common/layer';
import { createRect, addRect } from '../common/rect';
import type { Editor } from './editor';

export const MAX_SIZE = 100000;
export const MAX_RECT = createRect(0, 0, MAX_SIZE, MAX_SIZE);

export function redraw(editor: IToolEditor, rect = MAX_RECT) {
  addRect(editor.dirty, rect);
}

export function redrawDrawing(editor: IToolEditor, rect = MAX_RECT) {
  addRect(editor.drawingDirty, rect);
}

export function redrawLayer(editor: IToolEditor, layer: Layer | undefined, rect?: Rect) {
  if (layer && isLayerVisible(layer)) {
    redrawDrawing(editor, rect);
  }
}

// memory measurement

function formatToBpp(format: TextureFormat) {
  switch (format) {
    case TextureFormat.RGBA: return 4;
    case TextureFormat.Alpha: return 1;
    default: return invalidEnumReturn(format, 4);
  }
}

function getImageMemoryInUse(image: Texture | HTMLCanvasElement | undefined | null) {
  const bpp = (image && 'format' in image) ? formatToBpp(image.format) : 4;
  return image ? (image.width * image.height * bpp) : 0;
}

function getUserTempMemoryInUse(user: User) {
  let memory = 0;
  memory += getImageMemoryInUse(user.surface.texture ?? user.surface.canvas);
  memory += getImageMemoryInUse(user.surface.textureMask ?? user.surface.canvasMask);
  return memory;
}

function getUserHistoryMemoryInUse(user: User) {
  const stats: HistoryStats = { used: 0, total: 0, canvases: 0 };
  user.history.stats(stats);
  return stats.total;
}

export function getMemoryInUse(editor: Editor) {
  let layers = 0;
  let layersHidden = 0;
  let temp = 0;
  let cache = 0;
  let history = 0;

  for (const layer of editor.drawing.layers) {
    if (isLayerVisible(layer)) {
      layers += getImageMemoryInUse(layer.texture ?? layer.canvas);
    } else {
      layersHidden += getImageMemoryInUse(layer.texture ?? layer.canvas);
    }
  }

  temp += getUserTempMemoryInUse(editor.model.user);
  history += getUserHistoryMemoryInUse(editor.model.user);

  for (const user of editor.model.users) {
    temp += getUserTempMemoryInUse(user);
    history += getUserHistoryMemoryInUse(user);
  }

  const webgl = (editor.renderer as any)?.webgl as WebGLResources | undefined;

  if (webgl) {
    if (webgl.textures) {
      for (const texture of webgl.textures) {
        cache += getImageMemoryInUse(texture);
      }
    }

    if (webgl.drawingTexture) {
      temp += getImageMemoryInUse(webgl.drawingTexture);
    }

    if (webgl.namePlatesTexture) {
      temp += getImageMemoryInUse(webgl.namePlatesTexture);
    }
  } else {
    temp += getImageMemoryInUse(editor.drawing.canvas);
  }

  const total = layers + layersHidden + temp + cache + history;

  return { layers, layersHidden, temp, history, cache, total };
}

export function setEditorColor(editor: Editor, color: number, primary: boolean) {
  if (primary) {
    editor.primaryColor = color;
    editor.primaryColorHue = colorToHSVA(color).h;
  } else {
    editor.secondaryColor = color;
    editor.secondaryColorHue = colorToHSVA(color).h;
  }
}
