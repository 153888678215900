import { ITool, IToolEditor, IToolModel, ToolError, ToolId } from '../interfaces';
import { BaseShapeTool, DrawShape } from './baseShapeTool';
import { faSplotch } from '../icons';
import { shapeShapesMap } from '../shapes';
import { keys } from '../baseUtils';

export class ShapeTool extends BaseShapeTool implements ITool {
  id = ToolId.Shape;
  name = 'Shape';
  description = 'Quickly build compositions and character silhouettes from pre-defined shapes.';
  learnMore = 'https://help.magma.com/en/articles/6871501-custom-shape';
  video = { url: '/assets/videos/shape.mp4', width: 374, height: 210 };
  icon = faSplotch;
  drawShape = DrawShape.Custom;
  fields = keys<ShapeTool>(['shapeType', 'strokeWidth', 'opacity', 'angle', 'shape', 'fixedRatio']);
  constructor(public editor: IToolEditor, public model: IToolModel) {
    super(editor, model);
  }
  canUseStroke() {
    const shape = shapeShapesMap.get(this.shape);
    // stroke mode is really slow on very complicated paths
    return !shape || !shape.path || shape.path.path.length < 10000;
  }
  canStart() {
    return !shapeShapesMap.get(this.shape)?.path ? ToolError.MissingShape : ToolError.NoError;
  }
}
