import { StorageUsageData, UnsubRecentEntity } from './interfaces';
import { ControllerSymbol } from '@deepkit/rpc';
import { AsyncJob, DreamboothInput, AsyncJobStatusUpdate, AiModel, AiModelUpdate, AsyncJobStatusClientUpdate } from 'magma/common/aiInterfaces';
import {
  AdminDrawingData, ErrorData, ExportAccountsOptions, ImportAccountsOptions, OtherStats, ServerInfo, ServerSettings,
  Thread, Comment, UpdateComment, NewThread, UpdateThread, NewComment, OAuthData, Feature, AppNotificationStatus,
  AppNotification, NewAppNotification, AppNotificationSettingsOption, Role, AppNotificationTeamCounter, ImportTeamsOptions,
  UserRole, Suggestion, AdminDrawingUpdate, SequenceDrawingData, AdminRevisionData, AdminAiModel, AdminAiModelUpdate,
} from 'magma/common/interfaces';
import { Observable } from 'rxjs';
import { DeleteMode } from 'server/services/entity.service';
import { LinkData, NewShortenedLinkData } from 'server/services/link.service';
import {
  BillingData, CreateRole, EmailChangeType, ProjectDataBasic, RecentEntity, TeamData, TeamRole, UnsetTeamData,
  UnsetUserData, UpdateProjectData, UpdateRole, UserData, UserTypes,
} from 'shared/interfaces';

export interface UserPresence {
  entityId: string;
  user: Pick<UserData, '_id' | 'avatar' | 'userType' | 'name'>;
  metadata: Record<string, string>;
}

export interface EntityPresence {
  [sessionId: string]: UserPresence;
}

export interface PresenceRPCInterface {
  monitorPresenceOnTeam(teamId: string): Observable<[string, EntityPresence]>;
  monitorPresenceOnEntities(entityIds?: string[]): Observable<[string, EntityPresence]>;
  publishPresence(entityId: string, metadata: Record<string, string>): Observable<boolean>;
  monitorRecentEntities(initialEntityIds: string[], teamId: string | undefined): Observable<RecentEntity | UnsubRecentEntity>;
  monitorEntitiesCount(teamId: string | undefined): Observable<number>;
}

export const PresenceRPCInterface = ControllerSymbol<PresenceRPCInterface>('rpc', []);

export interface CreateTeamData {
  _id?: string;
  name: string;
  slug: string;
  isPublic: boolean;
  owner?: string;
  avatar: string;
  featureFlags?: Feature[];
  tags?: string[];
  cloning?: boolean;
  clonedTeamId?: string;
  organization?: string;
  requireSamlAuthentication?: boolean;
}

export interface UpdateTeamData {
  _id: string;
  name?: string;
  slug?: string;
  isPublic?: boolean;
  avatar?: string;
  featureFlags?: Feature[];
  forcePro?: boolean;
  forceProUntil?: Date;
  storageLimit?: number;
  tags?: string[];
  cloning?: boolean;
  clonedTeamId?: string;
  owner?: string;
  organization?: string;
  requireSamlAuthentication?: boolean;
}

export interface IOrganizationDocument {
  _id: string;
  name: string;
  provider: string;
  applicationId: string;
  applicationSecret?: string;
  tenantId: string;
}

export interface ITeamDocument {
  _id: string;
  name: string;
  slug: string;
  isPublic: boolean;
  avatar?: string;
  createdAt?: string;
  billing?: BillingData;
  pro?: boolean;
  forcePro?: boolean;
  forceProUntil?: Date;
  featureFlags?: Feature[];
  tags?: string[];
  storageLimit?: number;
  flags?: number;
  organization?: IOrganizationDocument;
  requireSamlAuthentication: boolean;
  // extra fields for admin panel
  memberCount?: number;
  activeMembersCounts?: number;
  projectCount?: number;
  entityCount?: number;
  isRemoved?: boolean;
  removedAt?: Date;
  owner?: string;
  deletionDate?: Date;
  storageUsage?: StorageUsageData;
}

export interface ITeamMemberDocument {
  user?: IUserDocument;
  userId?: string;
  type: string;
  roles: TeamRole[];
}

export type ITeamData = Pick<TeamData, '_id' | 'name' | 'pro'> & { avatar?: string, role?: UserRole; };

// TODO: share with BaseUserDocument ?
export interface IUserDocument {
  _id: string;
  email: string;
  unverifiedEmail?: string;
  salt?: string;
  hash?: string;
  name: string;
  avatar?: string;
  oauth?: OAuthData;
  createdAt?: Date;
  verified?: boolean;
  userType: UserTypes;
  resetPasswordToken?: string;
  resetPasswordExpires?: Date;
  verificationToken?: string;
  newsletter?: boolean;
  hideNewFeatureNotifications: boolean;
  teams?: any[]; // TODO: TeamDocument[];
  billing?: BillingData;
  checkoutSessionId?: string;
  isSuperAdmin?: boolean;
  color?: string;
  pro?: boolean;
  proSources?: string[];
  forcePro?: boolean;
  forceProUntil?: Date;
  roles?: Role[];
  workRole?: string;
  workTags?: string[];
  settings?: string;
  lastVisit?: Date;
  lastActive?: Date;
  lastOrigin?: string;
  lastUserAgent?: string;
  featureFlags?: Feature[];
  tags: string[];
  createdDrawings?: number;
  participatedDrawings?: number;
  storageUsage?: number;
}

export interface IUserDocumentForAdminPanel extends IUserDocument {
  recentDrawings?: { id: string, name: string, cacheId: string }[];
  ownsTeams?: number;
  inTeams?: number;
  unreadNotificationCount?: number;
  notificationCount?: number;
  ownedDrawings?: number;
  usage?: StorageUsageData;
}

export interface AdminEmailData {
  emailChangeType?: EmailChangeType;
  oldEmail?: string;
  user?: string;
  teamName?: string;
  token?: string;
  shareLink?: string;
  name?: string;
  teamSlug?: string;
  effectiveDate?: string;
  changedBy?: string;
  oldStatus?: string;
  newStatus?: string;
  message?: string;

  entity?: {
    _id: string;
    name: string;
    type: string;
  };
}

export interface BatchUsersUpdate {
  set?: { tags?: string[], featureFlags?: Feature[] };
  clear?: { tags?: string[], featureFlags?: Feature[] };
}

export interface AdminRPCInterface {
  servers(): Observable<ServerInfo[]>;
  graph(): Promise<Uint8Array>;
  addServer(): Promise<void>;
  releaseAssignedDrawings(id: string): Promise<void>;
  teams(search: string, skip: number, limit: number): Promise<{ items: ITeamDocument[]; count: number; }>;
  getSequence(_id: string): Promise<any>;
  organizations(search: string, skip: number, limit: number): Promise<{ items: IOrganizationDocument[]; count: number; }>;
  createOrganization(data: Partial<IOrganizationDocument>): Promise<string>;
  updateOrganization(id: string, data: Partial<IOrganizationDocument>): Promise<string>;
  findTeam(query: string): Promise<ITeamDocument | undefined>;
  getTeam(id: string): Promise<ITeamDocument | undefined>;
  createTeam(data: CreateTeamData): Promise<string>;
  updateTeam(data: UpdateTeamData & UnsetTeamData): Promise<void>;
  updateProject(id: string, data: UpdateProjectData): Promise<void>;
  removeTeam(id: string, forceDelete: boolean): Promise<void>;
  restoreTeam(id: string): Promise<void>;
  unmarkDeletion(id: string): Promise<void>;
  cloneTeam(sourceTeamId: string, targetTeamId: string): Promise<void>;
  setTeamOwner(teamId: string, userId: string): Promise<void>;
  teamMembers(teamId: string): Promise<ITeamMemberDocument[]>;
  teamRoles(teamId: string): Promise<TeamRole[]>;
  teamContributors(teamId: string): Promise<TeamRole[]>;
  teamProjects(teamId: string): Promise<ProjectDataBasic[]>;
  teamMemberUpdate(teamId: string, memberId: string, roleIds: string[]): Promise<void>;
  teamRoleCreate(teamId: string, role: CreateRole): Promise<void>;
  teamRoleUpdate(teamId: string, roleId: string, update: UpdateRole): Promise<void>;
  teamRoleRemove(roleId: string): Promise<void>;
  addTeamMember(teamId: string, userId: string): Promise<void>;
  removeTeamMember(teamId: string, userId: string): Promise<void>;
  getTeamsForUser(userId: string): Promise<ITeamData[]>;
  users(search: string, skip: number, limit: number): Promise<{ items: IUserDocumentForAdminPanel[]; count: number; }>;
  userIds(search: string): Promise<string[]>;
  getUser(id: string): Promise<IUserDocument | undefined>;
  findUser(query: string): Promise<IUserDocument | undefined>;
  updateUser(id: string, update: Partial<IUserDocument> | UnsetUserData): Promise<IUserDocument | undefined>;
  updateUsers(userIdsOrEmails: string[], update: BatchUsersUpdate): Promise<number>;
  setUserStripeId(id: string, stripeId: string): Promise<void>;
  setTeamStripeId(id: string, stripeId: string): Promise<void>;
  refreshBillingForUser(id: string): Promise<void>;
  refreshBillingForTeam(id: string): Promise<void>;
  revalidateUserPro(id: string): Promise<void>;
  revalidateTeamPro(id: string): Promise<void>;
  assignUsersToTeam(userIdsOrEmails: string[], teamIdOrSlug: string): Promise<number>;
  resetUserPassword(id: string, password: string): Promise<void>;
  removeUser(id: string): Promise<void>;
  mergeUsers(targetId: string, sourceId: string): Promise<void>;
  importUsers(csv: string, options: ImportAccountsOptions): Promise<string>;
  importTeams(csv: string, options: ImportTeamsOptions): Promise<string>;
  exportUsersAsCSV(search: string, options: ExportAccountsOptions): Promise<string>;
  getUserName(id: string): Promise<string>;
  drawings(search: string, skip: number, limit: number): Promise<{ items: AdminDrawingData[]; count: number; }>;
  getDrawing(drawingId: string): Promise<AdminDrawingData | undefined>;
  removeDrawing(drawingId: string, deleteMode: DeleteMode): Promise<void>;
  restoreDrawing(drawingId: string): Promise<void>;
  updateDrawings(drawingIds: string[], update: Partial<AdminDrawingUpdate>): Promise<void>;
  updateDrawingFeatureFlags(drawingId: string, flags: Feature[]): Promise<void>;
  duplicateDrawing(drawingId: string, mode: 'admin' | 'user'): Promise<string>;
  getSequenceDrawings(sequenceId: string): Promise<SequenceDrawingData[]>;
  getRevisions(drawingId: string): Promise<AdminRevisionData[]>;
  createRevision(drawingId: string): Promise<void>
  restoreRevision(revisionId: string): Promise<void>
  removeRevision(revisionId: string): Promise<void>
  findErrors(from: number, limit: number, q: string): Promise<{ items: ErrorData[]; count: number; }>;
  removeError(id: string): Promise<void>;
  removeErrorsByMessage(message: string): Promise<void>;
  removeErrorsByStart(start: string): Promise<void>;
  removeErrors(query: any): Promise<void>;
  removeAllErrors(): Promise<void>;
  getLogs(): Promise<string[]>;
  getLog(id: string): Promise<string>;
  clearLog(id: string): Promise<void>;
  deleteLog(id: string): Promise<void>;
  watchSettings(): Observable<ServerSettings>;
  updateSettings(settings: Partial<ServerSettings>): Promise<ServerSettings>;
  getOtherStats(): Promise<OtherStats>;
  unfallback(id: string): Promise<string>;

  getShortenerUrl(): Promise<string>;
  listShortenedLinks(): Promise<LinkData[]>;
  createShortenedLink(linkData: NewShortenedLinkData): Promise<void>;
  updateShortenedLink(id: string, link: NewShortenedLinkData): Promise<void>;
  removeShortenedLink(id: string): Promise<boolean>;
  sendEmail(template: string, email: string, data: AdminEmailData): Promise<void>;
  sendNotification(notification: NewAppNotification, userId: string): Promise<void>;

  getDemoStartingDrawing(teamId: string): Promise<AdminDrawingData | undefined>;

  aiModels(search: string, skip: number, limit: number): Promise<{ items: AdminAiModel[]; count: number; }>;
  updateAiModel(rId: string, update: AdminAiModelUpdate): Promise<void>;
}

export const AdminRPCInterface = ControllerSymbol<AdminRPCInterface>('admin-rpc', []);

export interface ThreadsRPCInterface {
  observeThreads(entityId: string): Observable<Thread[]>;

  observeComments(threadId: string): Observable<Comment[]>;

  getThreads(entityId: string): Promise<Thread[]>;

  createThread(newThread: NewThread): Promise<string>;
  updateThread(threadId: string, updatedThread: UpdateThread): Promise<void>;
  deleteThread(threadId: string): Promise<void>;

  markThreadAsRead(entityId: string, threadId: string): Promise<void>;
  markAllThreadsAsRead(entityId: string): Promise<void>;

  createComment(threadId: string, newComment: NewComment): Promise<void>;
  updateComment(threadId: string, commentId: string, updatedComment: UpdateComment): Promise<void>;
  deleteComment(threadId: string, commentId: string): Promise<void>;

  getMentionSuggestions(entityShortId: string): Promise<Suggestion[]>;
}
export const ThreadsRPC = ControllerSymbol<ThreadsRPCInterface>('rpc-threads', []);

export interface AppNotificationsRPCInterface {
  observeNotifications(): Observable<AppNotification>;
  getNotifications(teamId: string, skip: number, limit: number): Promise<AppNotification[]>;
  updateNotificationStatus(shortId: string, status: AppNotificationStatus): Promise<void>;
  markAllAsRead(teamId: string | null): Promise<void>;
  hasUnread(teamId: string | null): Promise<boolean>;
  markUnreadAsViewed(notificationIds: string[]): Promise<void>;
  updateNotificationSettings(entityId: string, setting: AppNotificationSettingsOption): Promise<void>;
  getNotificationSettings(entityId: string): Promise<AppNotificationSettingsOption>;

  getNotificationTeamCounters(): Promise<AppNotificationTeamCounter[]>;
}
export const AppNotificationRPC = ControllerSymbol<AppNotificationsRPCInterface>('rpc-notifications', []);

export interface OnlineRPCInterface {
  online(): Observable<void>;
  observeContext(context: string): Observable<string[]>;
}
export const OnlineRPC = ControllerSymbol<OnlineRPCInterface>('rpc-online', []);

export interface AiRPCInterface {
  enableAiModel(rId: string, enabled: boolean): Promise<void>;
  createAiModel(model: AiModel, isFromJob: boolean): Promise<void>;
  uploadAilFile(file: Uint8Array): Promise<string>;
  updateAiModel(rId: string, update: AiModelUpdate): Promise<void>;
  deleteAiModel(rId: string): Promise<void>;

  getAiModels(teamId: string | null): Promise<AiModel[]>;

  queueDreamboothJob(name: string, input: DreamboothInput, inputImageMetadata: { [key: string]: string }, teamId: string | null): Promise<void>;
  getJobs(teamId: string | null): Promise<AsyncJob[]>;
  cancelJob(jobId: string): Promise<void>;
  removeJob(jobId: string): Promise<void>;
  observeJobs(teamId: string | null): Observable<AsyncJobStatusClientUpdate>;
}
export const AiRPC = ControllerSymbol<AiRPCInterface>('rpc-ai', []);
