import { FLOATS_PER_VERTEX, setupAttribPointers, allocBuffer, deleteBuffer } from './webgl';
import { TriangleBatch, Mat2d } from '../common/interfaces';
import { times } from '../common/utils';
import { createVec2, setVec2, transformVec2ByMat2d } from '../common/vec2';

const VERTICES_PER_TRIANGLE = 3;

export function createBuffer(gl: WebGLRenderingContext, data: ArrayBufferView, index = false) {
  const buffer = allocBuffer(gl);
  const type = index ? gl.ELEMENT_ARRAY_BUFFER : gl.ARRAY_BUFFER;
  gl.bindBuffer(type, buffer);
  gl.bufferData(type, data, gl.STATIC_DRAW);
  gl.bindBuffer(type, null);
  return buffer;
}

export function createBatch(
  gl: WebGLRenderingContext, capacity: number, largeBufferCount = 16, smallBufferCount = 16
): TriangleBatch {
  const vertices = new Float32Array(FLOATS_PER_VERTEX * VERTICES_PER_TRIANGLE * capacity);
  const smallVertices = vertices.subarray(0, FLOATS_PER_VERTEX * VERTICES_PER_TRIANGLE * 2 * 16);
  const largeBuffers = times(largeBufferCount, () => createBuffer(gl, vertices));
  const smallBuffers = times(smallBufferCount, () => createBuffer(gl, smallVertices));

  return {
    gl, capacity, largeBuffers, smallBuffers, activeLargeBuffer: 0, activeSmallBuffer: 0,
    count: 0, index: 0, verticesF32: vertices, smallBufferSize: smallVertices.length,
  };
}

export function releaseBatch({ gl, largeBuffers, smallBuffers }: TriangleBatch) {
  for (const buffer of largeBuffers) deleteBuffer(gl, buffer);
  for (const buffer of smallBuffers) deleteBuffer(gl, buffer);
}

function pushVertex(
  verticesF32: Float32Array, index: number, x: number, y: number,
  tx: number, ty: number,
  c1: number, c2: number, r: number, g: number, b: number, a: number
) {
  verticesF32[index + 0] = x;
  verticesF32[index + 1] = y;
  verticesF32[index + 2] = tx;
  verticesF32[index + 3] = ty;
  verticesF32[index + 4] = c1;
  verticesF32[index + 5] = c2;
  verticesF32[index + 6] = r;
  verticesF32[index + 7] = g;
  verticesF32[index + 8] = b;
  verticesF32[index + 9] = a;
}

function pushVertexTransformed(
  verticesF32: Float32Array, index: number, transform: Mat2d, x: number, y: number,
  tx: number, ty: number,
  c1: number, c2: number, r: number, g: number, b: number, a: number
) {
  verticesF32[index + 0] = transform[0] * x + transform[2] * y + transform[4];
  verticesF32[index + 1] = transform[1] * x + transform[3] * y + transform[5];
  verticesF32[index + 2] = tx;
  verticesF32[index + 3] = ty;
  verticesF32[index + 4] = c1;
  verticesF32[index + 5] = c2;
  verticesF32[index + 6] = r;
  verticesF32[index + 7] = g;
  verticesF32[index + 8] = b;
  verticesF32[index + 9] = a;
}

export function canPush(batch: TriangleBatch, triangles: number) {
  return (batch.count + triangles) <= batch.capacity;
}

export function pushQuad(
  batch: TriangleBatch, x: number, y: number, w: number, h: number,
  tx: number, ty: number, tw: number, th: number, c1: number, c2: number,
  r: number, g: number, b: number, a: number
) {
  pushQuadXXYY(
    batch, x, y, x + w, y + h,
    tx, ty, tx + tw, ty + th,
    c1, c2, r, g, b, a);
}

export function pushQuad2(
  batch: TriangleBatch, x: number, y: number, w: number, h: number,
  tx: number, ty: number, tw: number, th: number,
  cx: number, cy: number, cw: number, ch: number,
  r: number, g: number, b: number, a: number
) {
  pushQuadXXYY2(
    batch, x, y, x + w, y + h,
    tx, ty, tx + tw, ty + th,
    cx, cy, cx + cw, cy + ch,
    r, g, b, a);
}

export function pushQuad3(
  batch: TriangleBatch, x: number, y: number, w: number, h: number,
  tx: number, ty: number, tw: number, th: number,
  cx: number, cy: number, cw: number, ch: number,
  dx: number, dy: number, dw: number, dh: number,
  b: number, a: number
) {
  pushQuadXXYY3(
    batch, x, y, x + w, y + h,
    tx, ty, tx + tw, ty + th,
    cx, cy, cx + cw, cy + ch,
    dx, dy, dx + dw, dy + dh,
    b, a);
}

export function pushQuad4(
  batch: TriangleBatch, x: number, y: number, w: number, h: number,
  tx: number, ty: number, tw: number, th: number,
  cx: number, cy: number, cw: number, ch: number,
  dx: number, dy: number, dw: number, dh: number,
  ex: number, ey: number, ew: number, eh: number,
) {
  pushQuadXXYY4(
    batch, x, y, x + w, y + h,
    tx, ty, tx + tw, ty + th,
    cx, cy, cx + cw, cy + ch,
    dx, dy, dx + dw, dy + dh,
    ex, ey, ex + ew, ey + eh);
}

export function pushQuadXXYY(
  batch: TriangleBatch,
  x0: number, y0: number, x1: number, y1: number,
  tx0: number, ty0: number, tx1: number, ty1: number,
  c1: number, c2: number,
  r: number, g: number, b: number, a: number
) {
  if ((batch.count + 2) > batch.capacity) flushBatch(batch);

  const { verticesF32, index } = batch;

  pushVertex(verticesF32, index + 0, x0, y0, tx0, ty0, c1, c2, r, g, b, a);
  pushVertex(verticesF32, index + 10, x1, y0, tx1, ty0, c1, c2, r, g, b, a);
  pushVertex(verticesF32, index + 20, x0, y1, tx0, ty1, c1, c2, r, g, b, a);

  pushVertex(verticesF32, index + 30, x1, y0, tx1, ty0, c1, c2, r, g, b, a);
  pushVertex(verticesF32, index + 40, x1, y1, tx1, ty1, c1, c2, r, g, b, a);
  pushVertex(verticesF32, index + 50, x0, y1, tx0, ty1, c1, c2, r, g, b, a);

  batch.count += 2;
  batch.index += 6 * FLOATS_PER_VERTEX;
}

export function pushQuadXXYY2(
  batch: TriangleBatch,
  x0: number, y0: number, x1: number, y1: number,
  tx0: number, ty0: number, tx1: number, ty1: number,
  cx0: number, cy0: number, cx1: number, cy1: number,
  r: number, g: number, b: number, a: number
) {
  if ((batch.count + 2) > batch.capacity) flushBatch(batch);

  const { verticesF32, index } = batch;

  pushVertex(verticesF32, index + 0, x0, y0, tx0, ty0, cx0, cy0, r, g, b, a);
  pushVertex(verticesF32, index + 10, x1, y0, tx1, ty0, cx1, cy0, r, g, b, a);
  pushVertex(verticesF32, index + 20, x0, y1, tx0, ty1, cx0, cy1, r, g, b, a);

  pushVertex(verticesF32, index + 30, x1, y0, tx1, ty0, cx1, cy0, r, g, b, a);
  pushVertex(verticesF32, index + 40, x1, y1, tx1, ty1, cx1, cy1, r, g, b, a);
  pushVertex(verticesF32, index + 50, x0, y1, tx0, ty1, cx0, cy1, r, g, b, a);

  batch.count += 2;
  batch.index += 6 * FLOATS_PER_VERTEX;
}

export function pushQuadXXYY3(
  batch: TriangleBatch,
  x0: number, y0: number, x1: number, y1: number,
  tx0: number, ty0: number, tx1: number, ty1: number,
  cx0: number, cy0: number, cx1: number, cy1: number,
  dx0: number, dy0: number, dx1: number, dy1: number,
  b: number, a: number
) {
  if ((batch.count + 2) > batch.capacity) flushBatch(batch);

  const { verticesF32, index } = batch;

  pushVertex(verticesF32, index + 0, x0, y0, tx0, ty0, cx0, cy0, dx0, dy0, b, a);
  pushVertex(verticesF32, index + 10, x1, y0, tx1, ty0, cx1, cy0, dx1, dy0, b, a);
  pushVertex(verticesF32, index + 20, x0, y1, tx0, ty1, cx0, cy1, dx0, dy1, b, a);

  pushVertex(verticesF32, index + 30, x1, y0, tx1, ty0, cx1, cy0, dx1, dy0, b, a);
  pushVertex(verticesF32, index + 40, x1, y1, tx1, ty1, cx1, cy1, dx1, dy1, b, a);
  pushVertex(verticesF32, index + 50, x0, y1, tx0, ty1, cx0, cy1, dx0, dy1, b, a);

  batch.count += 2;
  batch.index += 6 * FLOATS_PER_VERTEX;
}

export function pushQuadXXYY4(
  batch: TriangleBatch,
  x0: number, y0: number, x1: number, y1: number,
  tx0: number, ty0: number, tx1: number, ty1: number,
  cx0: number, cy0: number, cx1: number, cy1: number,
  dx0: number, dy0: number, dx1: number, dy1: number,
  ex0: number, ey0: number, ex1: number, ey1: number,
) {
  if ((batch.count + 2) > batch.capacity) flushBatch(batch);

  const { verticesF32, index } = batch;

  pushVertex(verticesF32, index + 0, x0, y0, tx0, ty0, cx0, cy0, dx0, dy0, ex0, ey0);
  pushVertex(verticesF32, index + 10, x1, y0, tx1, ty0, cx1, cy0, dx1, dy0, ex1, ey0);
  pushVertex(verticesF32, index + 20, x0, y1, tx0, ty1, cx0, cy1, dx0, dy1, ex0, ey1);

  pushVertex(verticesF32, index + 30, x1, y0, tx1, ty0, cx1, cy0, dx1, dy0, ex1, ey0);
  pushVertex(verticesF32, index + 40, x1, y1, tx1, ty1, cx1, cy1, dx1, dy1, ex1, ey1);
  pushVertex(verticesF32, index + 50, x0, y1, tx0, ty1, cx0, cy1, dx0, dy1, ex0, ey1);

  batch.count += 2;
  batch.index += 6 * FLOATS_PER_VERTEX;
}

export function pushQuadTransformed(
  batch: TriangleBatch, transform: Mat2d, x: number, y: number, w: number, h: number,
  tx: number, ty: number, tw: number, th: number, c1: number, c2: number,
  r: number, g: number, b: number, a: number
) {
  pushQuadXXYYTransformed(batch, transform, x, y, x + w, y + h, tx, ty, tx + tw, ty + th, c1, c2, r, g, b, a);
}

export function pushQuadXXYYTransformed(
  batch: TriangleBatch, transform: Mat2d, x0: number, y0: number, x1: number, y1: number,
  tx0: number, ty0: number, tx1: number, ty1: number, c1: number, c2: number,
  r: number, g: number, b: number, a: number
) {
  if ((batch.count + 2) > batch.capacity) flushBatch(batch);

  const { verticesF32, index } = batch;

  pushVertexTransformed(verticesF32, index + 0, transform, x0, y0, tx0, ty0, c1, c2, r, g, b, a);
  pushVertexTransformed(verticesF32, index + 10, transform, x1, y0, tx1, ty0, c1, c2, r, g, b, a);
  pushVertexTransformed(verticesF32, index + 20, transform, x0, y1, tx0, ty1, c1, c2, r, g, b, a);

  pushVertexTransformed(verticesF32, index + 30, transform, x1, y0, tx1, ty0, c1, c2, r, g, b, a);
  pushVertexTransformed(verticesF32, index + 40, transform, x1, y1, tx1, ty1, c1, c2, r, g, b, a);
  pushVertexTransformed(verticesF32, index + 50, transform, x0, y1, tx0, ty1, c1, c2, r, g, b, a);

  batch.count += 2;
  batch.index += 6 * FLOATS_PER_VERTEX;
}

export function pushTransformedQuad(
  batch: TriangleBatch, x: number, y: number, w: number, h: number, tx: number, ty: number, tw: number, th: number,
  r: number, g: number, b: number, a: number, mat: Mat2d
) {
  if (w === 0 || h === 0)
    return;

  // TODO: remove ?
  if ((batch.count + 2) > batch.capacity) {
    flushBatch(batch);
  }

  const { verticesF32, index } = batch;
  let va = createVec2();
  let vb = createVec2();
  let vc = createVec2();
  let vd = createVec2();

  setVec2(va, x, y);
  transformVec2ByMat2d(va, va, mat);
  setVec2(vb, x + w, y);
  transformVec2ByMat2d(vb, vb, mat);
  setVec2(vc, x, y + h);
  transformVec2ByMat2d(vc, vc, mat);
  setVec2(vd, x + w, y + h);
  transformVec2ByMat2d(vd, vd, mat);

  pushVertex(verticesF32, index + 0, va[0], va[1], tx, ty, 0, 0, r, g, b, a);
  pushVertex(verticesF32, index + 10, vb[0], vb[1], tx + tw, ty, 0, 0, r, g, b, a);
  pushVertex(verticesF32, index + 20, vc[0], vc[1], tx, ty + th, 0, 0, r, g, b, a);

  pushVertex(verticesF32, index + 30, vb[0], vb[1], tx + tw, ty, 0, 0, r, g, b, a);
  pushVertex(verticesF32, index + 40, vd[0], vd[1], tx + tw, ty + th, 0, 0, r, g, b, a);
  pushVertex(verticesF32, index + 50, vc[0], vc[1], tx, ty + th, 0, 0, r, g, b, a);

  batch.count += 2;
  batch.index += 6 * FLOATS_PER_VERTEX;
}

export function pushLine(
  batch: TriangleBatch, x1: number, y1: number, x2: number, y2: number, width: number, u0: number, u1: number
) {
  if ((batch.count + 2) > batch.capacity) flushBatch(batch);

  const { verticesF32, index } = batch;
  const half = width / 2;

  let dx = x2 - x1;
  let dy = y2 - y1;
  const length = Math.sqrt(dx * dx + dy * dy);
  dx = (dx / length) * half;
  dy = (dy / length) * half;
  const ox = dy;
  const oy = -dx;

  const ax = x1 + ox; // - dx;
  const ay = y1 + oy; // - dy;
  const bx = x2 + ox; // + dx;
  const by = y2 + oy; // + dy;
  const cx = x1 - ox; // - dx;
  const cy = y1 - oy; // - dy;
  const ex = x2 - ox; // + dx;
  const ey = y2 - oy; // + dy;

  pushVertex(verticesF32, index + 0, ax, ay, u0, 0, 0, 0, 0, 0, 0, 0);
  pushVertex(verticesF32, index + 10, bx, by, u1, 0, 0, 0, 0, 0, 0, 0);
  pushVertex(verticesF32, index + 20, cx, cy, u0, 0, 0, 0, 0, 0, 0, 0);

  pushVertex(verticesF32, index + 30, cx, cy, u0, 0, 0, 0, 0, 0, 0, 0);
  pushVertex(verticesF32, index + 40, bx, by, u1, 0, 0, 0, 0, 0, 0, 0);
  pushVertex(verticesF32, index + 50, ex, ey, u1, 0, 0, 0, 0, 0, 0, 0);

  batch.count += 2;
  batch.index += 6 * FLOATS_PER_VERTEX;
}

export function pushAntialiasedLine(
  batch: TriangleBatch, x1: number, y1: number, x2: number, y2: number, width: number, r: number, g: number, b: number, a: number
) {
  if ((batch.count + 2) > batch.capacity) flushBatch(batch);

  const { verticesF32, index } = batch;
  const half = width / 2 + 1;

  let dx = x2 - x1;
  let dy = y2 - y1;
  const length = Math.sqrt(dx * dx + dy * dy);
  dx = (dx / length) * half;
  dy = (dy / length) * half;
  const ox = dy;
  const oy = -dx;

  const ax = x1 + ox; // - dx;
  const ay = y1 + oy; // - dy;
  const bx = x2 + ox; // + dx;
  const by = y2 + oy; // + dy;
  const cx = x1 - ox; // - dx;
  const cy = y1 - oy; // - dy;
  const ex = x2 - ox; // + dx;
  const ey = y2 - oy; // + dy;

  const ty = width / 2;
  const tx = half;

  pushVertex(verticesF32, index + 0, ax, ay, tx, ty, 0, 0, r, g, b, a);
  pushVertex(verticesF32, index + 10, bx, by, tx, ty, 0, 0, r, g, b, a);
  pushVertex(verticesF32, index + 20, cx, cy, -tx, ty, 0, 0, r, g, b, a);

  pushVertex(verticesF32, index + 30, cx, cy, -tx, ty, 0, 0, r, g, b, a);
  pushVertex(verticesF32, index + 40, bx, by, tx, ty, 0, 0, r, g, b, a);
  pushVertex(verticesF32, index + 50, ex, ey, -tx, ty, 0, 0, r, g, b, a);

  batch.count += 2;
  batch.index += 6 * FLOATS_PER_VERTEX;
}

export function pushAntialiasedQuad(
  batch: TriangleBatch, x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number,
  width: number, r: number, g: number, b: number, a: number
) {
  pushAntialiasedLine(batch, x1, y1, x2, y2, width, r, g, b, a);
  pushAntialiasedLine(batch, x2, y2, x3, y3, width, r, g, b, a);
  pushAntialiasedLine(batch, x3, y3, x4, y4, width, r, g, b, a);
  pushAntialiasedLine(batch, x4, y4, x1, y1, width, r, g, b, a);
}

export function drawBatch(batch: TriangleBatch) {
  if (batch.count === 0) return;

  let buffer: WebGLBuffer;

  if (batch.index <= batch.smallBufferSize) {
    buffer = batch.smallBuffers[batch.activeSmallBuffer];
    batch.activeSmallBuffer = (batch.activeSmallBuffer + 1) % batch.smallBuffers.length;
  } else {
    buffer = batch.largeBuffers[batch.activeLargeBuffer];
    batch.activeLargeBuffer = (batch.activeLargeBuffer + 1) % batch.largeBuffers.length;
  }

  const gl = batch.gl;
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
  gl.bufferSubData(gl.ARRAY_BUFFER, 0, batch.verticesF32.subarray(0, batch.index));
  setupAttribPointers(gl);
  gl.drawArrays(gl.TRIANGLES, 0, batch.count * VERTICES_PER_TRIANGLE);
  gl.bindBuffer(gl.ARRAY_BUFFER, null);
}

export function resetBatch(batch: TriangleBatch) {
  batch.index = 0;
  batch.count = 0;
}

export function flushBatch(batch: TriangleBatch) {
  drawBatch(batch);
  resetBatch(batch);
}
