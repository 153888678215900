export interface RandomState {
  seed: number;
}

export function generateSeed() {
  if (TESTS) return 1234;
  return (Math.random() * Number.MAX_SAFE_INTEGER) | 0;
}

export function randomSeed(state: RandomState, seed: number) {
  if ((state.seed = (seed | 0) % 2147483647) <= 0) {
    state.seed += 2147483646;
  }
}

export function randomInt(state: RandomState) {
  return state.seed = state.seed * 48271 % 2147483647;
}

export function randomFloat(state: RandomState) {
  return (randomInt(state) - 1) / 2147483646;
}
