import { Point } from '../interfaces';
import { TextCharacter } from './text-character';
import { Textarea } from './textarea';
import { assumeParagraphsHaveMeasuredLines } from './paragraph';
import { arrayIndexSafe, clamp } from '../mathUtils';
import { Line, shouldBreakline } from './lines';

export const TEXT_SELECTION_DIRECTION_FORWARD: HTMLTextAreaElement['selectionDirection'] = 'forward';
export const TEXT_SELECTION_DIRECTION_BACKWARD: HTMLTextAreaElement['selectionDirection'] = 'backward';
export const TEXT_SELECTION_DIRECTION_NONE: HTMLTextAreaElement['selectionDirection'] = 'none';

export type SelectionChangeSource = 'mouse' | 'home' | 'end' | 'arrows-h' | 'arrows-v' | 'typing';

export interface TextSelectionDetailed {
  start: number;
  end: number;
  length: number;
  direction: HTMLTextAreaElement['selectionDirection'];
  text: string;
  paragraphIndexes: number[];
}

export interface TextSelectionSegments {
  beforeSelection: string[];
  inSelection: string[];
  afterSelection: string[];
}

export const pointLeftToCharacter = (point: Point, character: TextCharacter | undefined): boolean => {
  return !!(character && character.bbox && point.x <= character.bbox.x);
};

export const pointRightToCharacter = (point: Point, character: TextCharacter | undefined): boolean => {
  return !!(character && character.bbox && point.x >= character.bbox.x + character.bbox.w);
};

const locateExtendedLineWithPoint = (textarea: Textarea, point: Point): Line | undefined => {
  let line = textarea.lines[0];
  for (let i = 0; i <= textarea.lines.length; i++) {
    line = textarea.lines[i];
    if (i === textarea.lines.length || line.bbox.y > point.y) {
      i = arrayIndexSafe(i - 1, textarea.lines);
      line = textarea.lines[i];
      break;
    }
  }
  return line;
};

export const pointToDoubledIndex = (textarea: Textarea, point: Point): number => {
  // doubled indexes for mouse selection in text box represent halves of bounding boxes
  //  character indexes: [ [0], [1], [2], [3], ..., [16], ..., [160], ..., [n] ]
  //  doubled indexes: [ [0,1], [2,3], [4,5], [6,7], ..., [32, 33], ..., [320, 321], ..., [2n, 2n+1] ]
  const line = locateExtendedLineWithPoint(textarea, point);
  if (!line) return 0; // textarea has no characters at all
  const { startAt, breaklineAt, bbox: lineBbox } = line;
  const { x } = point;

  let doubledIndex = 0;

  if (x < lineBbox.x) {
    doubledIndex = 2 * startAt; // left half of first glyph in line
  } else if (x > lineBbox.x + lineBbox.w) {
    // left half of last glyph in line (not right! we don't' want caret/selection-rect in next line)
    // (unless stretched word then - allow for selecting that last glyph fully)
    doubledIndex = 2 * breaklineAt + (textarea.characters[breaklineAt].isWhitespace ? 0 : 1);
  } else {
    const lineCharacters = textarea.characters.slice(startAt, breaklineAt + 1);
    const foundCharacter = lineCharacters.find(c => c.bbox && x > c.bbox.x && x <= c.bbox.x + c.bbox.w);

    if (!foundCharacter || !foundCharacter.bbox) return 0; // textarea has no characters at all
    const { bbox: charBbox } = foundCharacter;

    if (x > charBbox.x + charBbox.w / 2 && x <= charBbox.x + charBbox.w && (!shouldBreakline(foundCharacter, line) || !foundCharacter.isWhitespace)) {
      doubledIndex = 2 * foundCharacter.index + 1; // odd doubled index - right half of glyph, exception for whitespace EOLs (regular characters need to be able to be clickable on both ends)
    } else {
      doubledIndex = 2 * foundCharacter.index; // even doubled index - left half of glyph, or EOL
    }
  }

  return doubledIndex;
};

export const doubleIndexToCharacter = (textarea: Textarea, doubledIndex: number): TextCharacter => {
  const safeDoubleIndex = clamp(doubledIndex, 0, (textarea.characters.length - 1) * 2);
  let character = textarea.characters[(safeDoubleIndex + (safeDoubleIndex % 2)) / 2];
  if (!character) throw new Error(`Something went wrong went converting doubled index (${doubledIndex}) back to character!`);
  return character;
};

export const handleArrowUpVerticalSelection = (e: KeyboardEvent, textarea: Textarea, input: HTMLTextAreaElement) => {
  assumeParagraphsHaveMeasuredLines(textarea.paragraphs);
  if (textarea.text === '') return;

  const { ctrlKey, shiftKey } = e;
  const { selectionStart, selectionEnd, selectionDirection } = input;
  const selectionLength = selectionEnd - selectionStart;
  const repositioningEnd = selectionDirection !== TEXT_SELECTION_DIRECTION_BACKWARD;

  let startChar = textarea.characters[selectionStart];
  let endChar = textarea.characters[selectionEnd];
  let repositionedChar = repositioningEnd ? endChar : startChar;
  let start: number = selectionStart;
  let end: number = selectionEnd;

  let addition;
  let ascendedChar;
  if (ctrlKey) {
    let p = textarea.findParagraphFromCharacter(repositionedChar);
    ascendedChar = p.characters[0];
    if (ascendedChar.index === repositionedChar.index) {
      const i = textarea.findParagraphIndexFromCharacter(ascendedChar) - 1;
      p = textarea.paragraphs[i];
      if (p) ascendedChar = p.characters[0];
    }
  } else {
    addition = ((
      textarea.caretAtEOL
      && !textarea.characterBreaksLine(repositionedChar)
    ) ? 1 : 0);
    if (textarea.verticallyNavigatingDoubleIndex === undefined) {
      textarea.verticallyNavigatingDoubleIndex = 2 * (repositionedChar.index - (addition * 2));
    }
    ascendedChar = textarea.findGlyphAboveDoubleIndex(textarea.verticallyNavigatingDoubleIndex + addition, repositionedChar);
    if (ascendedChar === undefined) {
      ascendedChar = textarea.characters[0];
    }

    if (textarea.caretAtEOL &&
      !ascendedChar.isWhitespace &&
      textarea.lastSelectionChangeSource !== 'arrows-v' &&
      textarea.characterBreaksLine(ascendedChar)) {
      ascendedChar = textarea.characters[ascendedChar.index + 1];
    }
  }

  if (selectionLength === 0) {
    if (shiftKey) {
      [startChar, ascendedChar] = [startChar, ascendedChar].sort((a, b) => a.index - b.index);
      [start, end] = [startChar.index, ascendedChar.index];
    } else {
      [start, end] = [ascendedChar.index, ascendedChar.index];
    }
  } else {
    if (shiftKey) {
      if (repositioningEnd) {
        [startChar, ascendedChar] = [startChar, ascendedChar].sort((a, b) => a.index - b.index);
        [start, end] = [startChar.index, ascendedChar.index];
      } else {
        [ascendedChar, endChar] = [ascendedChar, endChar].sort((a, b) => a.index - b.index);
        [start, end] = [ascendedChar.index, endChar.index];
      }
    } else {
      [start, end] = [ascendedChar.index, ascendedChar.index];
    }
  }

  textarea.lastSelectionChangeSource = 'arrows-v';
  const dirChangeCond = selectionDirection === TEXT_SELECTION_DIRECTION_FORWARD && end === selectionStart;
  input.setSelectionRange(start, end, dirChangeCond ? TEXT_SELECTION_DIRECTION_BACKWARD : selectionDirection);
};

export const handleArrowDownVerticalSelection = (e: KeyboardEvent, textarea: Textarea, input: HTMLTextAreaElement) => {
  assumeParagraphsHaveMeasuredLines(textarea.paragraphs);
  if (textarea.text === '') return;

  const { ctrlKey, shiftKey } = e;
  const { selectionStart, selectionEnd, selectionDirection } = input;
  const selectionLength = selectionEnd - selectionStart;
  const repositioningEnd = selectionDirection !== TEXT_SELECTION_DIRECTION_BACKWARD;

  let startChar = textarea.characters[selectionStart];
  let endChar = textarea.characters[selectionEnd];
  let repositionedChar = repositioningEnd ? endChar : startChar;
  let start: number = selectionStart;
  let end: number = selectionEnd;

  let addition;
  let descendedChar;
  if (ctrlKey) {
    let p = textarea.findParagraphFromCharacter(repositionedChar);
    descendedChar = p.characters[p.characters.length - 1];
    if (descendedChar.index === repositionedChar.index) {
      const i = textarea.findParagraphIndexFromCharacter(descendedChar) + 1;
      p = textarea.paragraphs[i];
      if (p) descendedChar = p.characters[p.characters.length - 1];
    }
  } else {
    addition = ((textarea.caretAtEOL && !textarea.characterBreaksLine(repositionedChar)) ? 1 : 0);
    if (textarea.verticallyNavigatingDoubleIndex === undefined) {
      textarea.verticallyNavigatingDoubleIndex = 2 * (repositionedChar.index - (addition * 2));
    }

    descendedChar = textarea.findGlyphBelowDoubleIndex(textarea.verticallyNavigatingDoubleIndex + addition, repositionedChar);
    if (descendedChar === undefined) {
      start = shiftKey ? selectionStart : input.value.length;
      end = input.value.length;
      textarea.lastSelectionChangeSource = 'arrows-v';
      const dirChangeCond = selectionDirection === TEXT_SELECTION_DIRECTION_BACKWARD && start === selectionEnd;
      input.setSelectionRange(start, end, dirChangeCond ? TEXT_SELECTION_DIRECTION_FORWARD : selectionDirection);
      return;
    }
  }

  if (!descendedChar) return;

  if (selectionLength === 0) {
    if (shiftKey) {
      [startChar, descendedChar] = [startChar, descendedChar].sort((a, b) => a.index - b.index);
      [start, end] = [startChar.index, descendedChar.index];
    } else {
      [start, end] = [descendedChar.index, descendedChar.index];
    }
  } else {
    if (shiftKey) {
      if (repositioningEnd) {
        [startChar, descendedChar] = [startChar, descendedChar].sort((a, b) => a.index - b.index);
        [start, end] = [startChar.index, descendedChar.index];
      } else {
        [descendedChar, endChar] = [descendedChar, endChar].sort((a, b) => a.index - b.index);
        [start, end] = [descendedChar.index, endChar.index];
      }
    } else {
      [start, end] = [descendedChar.index, descendedChar.index];
    }
  }

  textarea.lastSelectionChangeSource = 'arrows-v';
  const dirChangeCond = selectionDirection === TEXT_SELECTION_DIRECTION_BACKWARD && start === selectionEnd;
  input.setSelectionRange(start, end, dirChangeCond ? TEXT_SELECTION_DIRECTION_FORWARD : selectionDirection);
};

export const handleHomeVerticalSelection = (e: KeyboardEvent, textarea: Textarea, input: HTMLTextAreaElement) => {
  assumeParagraphsHaveMeasuredLines(textarea.paragraphs);
  if (textarea.text === '') return;

  const { shiftKey, ctrlKey, metaKey } = e;
  const ctrlOrMeta = ctrlKey || metaKey;
  const { selectionStart, selectionEnd, selectionDirection } = input;

  const charBeforeStart = textarea.characters[selectionStart - 1];
  const charAfterStart = textarea.characters[selectionStart];
  const charBeforeEnd = textarea.characters[selectionEnd - 1];
  const charAfterEnd = textarea.characters[selectionEnd];

  if (selectionDirection !== TEXT_SELECTION_DIRECTION_BACKWARD) {
    let lineIndex = textarea.findLineIndexFromCharacter(charAfterEnd);
    let line = textarea.lines[lineIndex];
    let finalEnd = line.startAt;

    if (twoCaretPositionsViable(textarea, charBeforeEnd, charAfterEnd)) {
      if (lineIndex > 0) lineIndex--;
      line = textarea.lines[lineIndex];
      finalEnd = line.startAt;
    }

    if (ctrlOrMeta) finalEnd = 0;

    if (shiftKey) {
      if (finalEnd < selectionStart) {
        input.setSelectionRange(finalEnd, selectionStart, TEXT_SELECTION_DIRECTION_BACKWARD);
      } else {
        input.setSelectionRange(selectionStart, finalEnd, TEXT_SELECTION_DIRECTION_FORWARD);
      }
    } else {
      input.setSelectionRange(finalEnd, finalEnd, TEXT_SELECTION_DIRECTION_FORWARD);
    }
  } else {
    let lineIndex = textarea.findLineIndexFromCharacter(charAfterStart);
    let line = textarea.lines[lineIndex];
    let finalStart = line.startAt;

    if (twoCaretPositionsViable(textarea, charBeforeStart, charAfterStart)) {
      if (lineIndex > 0) lineIndex--;
      line = textarea.lines[lineIndex];
      finalStart = line.startAt;
    }

    if (ctrlOrMeta) finalStart = 0;

    if (shiftKey) {
      if (finalStart > selectionEnd) {
        input.setSelectionRange(selectionEnd, finalStart, TEXT_SELECTION_DIRECTION_FORWARD);
      } else {
        input.setSelectionRange(finalStart, selectionEnd, TEXT_SELECTION_DIRECTION_BACKWARD);
      }
    } else {
      input.setSelectionRange(finalStart, finalStart, TEXT_SELECTION_DIRECTION_FORWARD);
    }
  }

  textarea.caretAtEOL = false;
  textarea.lastSelectionChangeSource = 'home';
};

export const handleEndVerticalSelection = (e: KeyboardEvent, textarea: Textarea, input: HTMLTextAreaElement) => {
  assumeParagraphsHaveMeasuredLines(textarea.paragraphs);
  if (textarea.text === '') return;

  const { shiftKey, ctrlKey, metaKey } = e;
  const ctrlOrMeta = ctrlKey || metaKey;
  const { selectionStart, selectionEnd, selectionDirection } = input;

  const charBeforeStart = textarea.characters[selectionStart - 1];
  const charAfterStart = textarea.characters[selectionStart];
  const charBeforeEnd = textarea.characters[selectionEnd - 1];
  const charAfterEnd = textarea.characters[selectionEnd];

  if (selectionStart !== selectionEnd) {
    if (selectionDirection !== TEXT_SELECTION_DIRECTION_BACKWARD) {
      let lineIndex = textarea.findLineIndexFromCharacter(charAfterEnd);
      let line = textarea.lines[lineIndex];
      let finalEnd = line.breaklineAt + (textarea.characters[line.breaklineAt].isWhitespace ? 0 : 1);

      if (twoCaretPositionsViable(textarea, charBeforeEnd, charAfterEnd)) {
        if (lineIndex > 0) lineIndex--;
        line = textarea.lines[lineIndex];
        finalEnd = line.breaklineAt + (textarea.characters[line.breaklineAt].isWhitespace ? 0 : 1);
      }

      if (ctrlOrMeta) finalEnd = textarea.characters.length;

      if (shiftKey) {
        const beforeFinalEnd = textarea.characters[arrayIndexSafe(finalEnd - 1, textarea.characters)];
        const atFinalEnd = textarea.characters[arrayIndexSafe(finalEnd, textarea.characters)];
        if (beforeFinalEnd?.isWhitespace && atFinalEnd?.isEOParagraph) finalEnd++;
        if (finalEnd < selectionStart) {
          input.setSelectionRange(finalEnd, selectionStart, TEXT_SELECTION_DIRECTION_BACKWARD);
        } else {
          input.setSelectionRange(selectionStart, finalEnd, TEXT_SELECTION_DIRECTION_FORWARD);
        }
      } else {
        input.setSelectionRange(finalEnd, finalEnd, TEXT_SELECTION_DIRECTION_FORWARD);
      }
    } else {
      let lineIndex = textarea.findLineIndexFromCharacter(charAfterStart);
      let line = textarea.lines[lineIndex];
      let finalStart = line.breaklineAt + (textarea.characters[line.breaklineAt].isWhitespace ? 0 : 1);

      if (twoCaretPositionsViable(textarea, charBeforeStart, charAfterStart)) {
        if (lineIndex > 0) lineIndex--;
        line = textarea.lines[lineIndex];
        finalStart = line.breaklineAt + (textarea.characters[line.breaklineAt].isWhitespace ? 0 : 1);
      }

      if (ctrlOrMeta) finalStart = textarea.characters.length;

      if (shiftKey) {
        const beforeFinalStart = textarea.characters[arrayIndexSafe(finalStart - 1, textarea.characters)];
        const atFinalStart = textarea.characters[arrayIndexSafe(finalStart, textarea.characters)];
        if (beforeFinalStart?.isWhitespace && atFinalStart?.isEOParagraph) finalStart++;
        if (finalStart > selectionEnd) {
          input.setSelectionRange(selectionEnd, finalStart, TEXT_SELECTION_DIRECTION_FORWARD);
        } else {
          input.setSelectionRange(finalStart, selectionEnd, TEXT_SELECTION_DIRECTION_BACKWARD);
        }
      } else {
        input.setSelectionRange(finalStart, finalStart, TEXT_SELECTION_DIRECTION_FORWARD);
      }
    }
  } else {
    let lineIndex = textarea.findLineIndexFromCharacter(charAfterEnd);
    let line = textarea.lines[lineIndex];
    let finalEnd = line.breaklineAt + ((textarea.characters[line.breaklineAt].isWhitespace) ? 0 : 1);

    if (twoCaretPositionsViable(textarea, charBeforeEnd, charAfterEnd)) {
      if (lineIndex > 0) lineIndex--;
      line = textarea.lines[lineIndex];
      finalEnd = line.breaklineAt + ((textarea.characters[line.breaklineAt].isWhitespace) ? 0 : 1);
    }

    if (ctrlOrMeta) finalEnd = textarea.characters.length;

    if (shiftKey) {
      const beforeFinalEnd = textarea.characters[finalEnd - 1];
      const atFinalEnd = textarea.characters[arrayIndexSafe(finalEnd, textarea.characters)];
      if (beforeFinalEnd?.isWhitespace && atFinalEnd?.isEOParagraph) finalEnd++;
      if (finalEnd < selectionStart) {
        input.setSelectionRange(finalEnd, selectionStart, TEXT_SELECTION_DIRECTION_BACKWARD);
      } else {
        input.setSelectionRange(selectionStart, finalEnd, TEXT_SELECTION_DIRECTION_FORWARD);
      }
    } else {
      input.setSelectionRange(finalEnd, finalEnd, TEXT_SELECTION_DIRECTION_FORWARD);
    }
  }

  textarea.caretAtEOL = true;
  textarea.lastSelectionChangeSource = 'end';
};

const twoCaretPositionsViable = (textarea: Textarea, charBefore: TextCharacter | undefined, charAfter: TextCharacter) => {
  return charBefore && textarea.caretAtEOL && !charAfter.isWhitespace && textarea.characterBreaksLine(charBefore) && textarea.characterStartsLine(charAfter);
};
