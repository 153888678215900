import type { Editor } from '../../services/editor';
import { redrawLayer } from '../../services/editorUtils';
import { logAction } from '../actionLog';
import { includes, keys } from '../baseUtils';
import { BLACK, BRUSH_SIZES, TEST_PRESSURE_OPACITY, WHITE } from '../constants';
import { BrushToolSettings, CompositeOp, CursorType, ITabletTool, ITool, IToolEditor, IToolModel, Layer, ToolId } from '../interfaces';
import { isMaskEmpty } from '../mask';
import { PaintBrush } from '../paintBrush';
import { createPoint } from '../point';
import { generateSeed } from '../random';
import { clipRect, cloneRect, copyRect, setRect } from '../rect';
import { brushShapesMap } from '../shapes';
import { createInnerStabilizer } from '../stabilizer';
import { setupSurface } from '../toolSurface';
import { finishTransform } from '../toolUtils';
import { releaseToolRenderingContext } from '../user';
import { copyViewport, createViewport, screenToDocumentXY, viewportsEqual } from '../viewport';
import { BRUSH_FIELDS, compressBrushData, decompressBrushData, IBrushToolData, roundPercent, setupBrush } from './brushUtils';

const temp = createPoint(0, 0);

export abstract class BaseBrushTool implements BrushToolSettings, ITool {
  id = ToolId.None;
  name = '';
  canvasCursor = CursorType.Circle;
  updatesCursor = true;
  altTool = true;
  lineOnShift = true;
  cursor = 'cursor-none';
  fields = keys<BaseBrushTool>(BRUSH_FIELDS);
  // size
  size = 20;
  sizePressure = true;
  sizeJitter = 0;
  sizes = BRUSH_SIZES;
  minSize = 0;
  sizeRatio = 1;
  // other
  flow = 1;
  flowPressure = false;
  opacity = 1;
  opacityPressure: boolean = TEST_PRESSURE_OPACITY; // TODO: add to fields, send
  spacing = 0.2;
  hardness = 1;
  stabilize = 0;
  // spread
  separateSpread = false;
  normalSpread = 0;
  tangentSpread = 0;
  // shape
  shape = '';
  angle = 0;
  angleJitter = 3.14;
  angleToDirection = false;
  // color
  color = BLACK;
  colorHue = 0;
  background = BLACK;
  colorPressure = false;
  foregroundBackgroundJitter = 0;
  hueJitter = 0;
  saturationJitter = 0;
  brightnessJitter = 0;
  view = createViewport();
  private viewCopy = createViewport();
  opacityLocked = false; // indicates whether layer opacity was locked at the start of drawing
  drawingShiftLine = false;
  private brush: PaintBrush;
  private stable: ITabletTool;
  private proxy: ITabletTool;
  private endX = 0;
  private endY = 0;
  private endPressure = 0;
  private prevLayers: any[] = []; // TEMP: testing code
  private minX = 0;
  private maxX = 0;
  private minY = 0;
  private maxY = 0;
  seed = 0;
  protected layer: Layer | undefined = undefined;
  protected compositeOperation = CompositeOp.Draw;
  constructor(public editor: IToolEditor, public model: IToolModel) {
    this.brush = new PaintBrush();
    this.brush.onDirtyRect = rect => {
      redrawLayer(this.editor, this.layer, rect);
    };
    this.stable = this.proxy = {
      id: this.id,
      start: (x: number, y: number, pressure: number) => {
        if (!this.layer) throw new Error('[BaseBrushTool.proxy.start] Missing layer');

        finishTransform(this.editor, this.model.user, 'BaseBrushTool');

        if (isMaskEmpty(this.model.user.selection)) {
          setRect(this.brush.bounds, 0, 0, this.editor.drawing.width, this.editor.drawing.height);
        } else {
          copyRect(this.brush.bounds, this.model.user.selection.bounds);
          clipRect(this.brush.bounds, 0, 0, this.editor.drawing.width, this.editor.drawing.height);
        }

        const hasColorDynamics = this.colorPressure || this.foregroundBackgroundJitter || this.hueJitter || this.saturationJitter || this.brightnessJitter;
        const surface = this.model.user.surface;
        setupSurface(surface, this.id, this.compositeOperation, this.layer);
        surface.opacity = roundPercent(this.opacity);
        surface.color = hasColorDynamics ? WHITE : this.color;

        this.brush.context = this.editor.renderer.getToolRenderingContext(this.model.user);
        this.brush.start(x, y, pressure);
        copyRect(surface.rect, this.brush.dirtyRect);

        // TEMP: testing
        // const r = this.brush.dirtyRect;
        // const l = surface.rect;
        // logAction(`brush.dirty (${r.x}, ${r.y}, ${r.w}, ${r.h}) surface.rect (${l.x}, ${l.y}, ${l.w}, ${l.h}) (start)`);
        // END
      },
      move: (x: number, y: number, pressure: number) => {
        this.brush.move(x, y, pressure);
        copyRect(this.model.user.surface.rect, this.brush.dirtyRect);
      },
      end: (x: number, y: number, pressure: number) => {
        if (!this.layer) throw new Error('[BaseBrushTool.proxy.end] Missing layer');

        const user = this.model.user;
        this.brush.end(x, y, pressure);
        copyRect(user.surface.rect, this.brush.dirtyRect);

        // TEMP: testing code
        if (this.layer !== user.activeLayer) {
          const layerId = this.layer.id;
          const activeId = user.activeLayer?.id ?? -1;
          throw new Error(`[BaseBrushTool.proxy.end] [${this.model.type}] this.layer !== user.activeLayer (${layerId} != ${activeId}) prev: [${this.prevLayers.join(', ')}]`);
        }
        while (this.prevLayers.length > 10) this.prevLayers.shift();
        this.prevLayers.push('end');
        // END

        releaseToolRenderingContext(user);
        const remote = this.model.type === 'remote';
        const beforeRect = cloneRect(this.layer.rect);

        // TEMP: testing
        // const r = this.brush.dirtyRect;
        // const l = user.surface.rect;
        // logAction(`brush.dirty (${r.x}, ${r.y}, ${r.w}, ${r.h}) surface.rect (${l.x}, ${l.y}, ${l.w}, ${l.h})`);
        // END

        if (this.brush.commit(this.name, this.opacityLocked, user, this.editor.drawing, this.editor.renderer, remote)) {
          // TEMP: testing
          if (!remote && !viewportsEqual(this.view, this.viewCopy)) {
            const msg = `view changed from copy ${JSON.stringify(this.viewCopy)} -> ${JSON.stringify(this.view)}`;
            logAction(msg);
            if (editor.type === 'client') (editor as Editor).errorReporter.reportError(msg);
          }
          // END

          // TEMP: testing
          if (!remote && !viewportsEqual(this.view, this.editor.view, true)) {
            let msg = `view changed ${JSON.stringify(this.view)} -> ${JSON.stringify(this.editor.view)}`;
            logAction(msg);
            if (editor.type === 'client') {
              const e = editor as Editor;
              if (e.model.isPresentationMode) msg += ' (presentation)';
              e.errorReporter.reportError(msg);
            }
          }
          // END

          const afterRect = cloneRect(this.layer.rect);
          this.model.endTool(this.id, this.endX, this.endY, this.endPressure, beforeRect, afterRect, this.minX, this.minY, this.maxX, this.maxY);
        } else {
          this.model.cancelTool('empty');
          user.history.unpre();
        }

        this.brush.context = undefined;
        this.layer = undefined;
      }
    };
  }
  protected setupBrush(brush: PaintBrush) {
    if (!SERVER && this.model.type !== 'remote' && !this.model.canUseProBrushes?.()) {
      this.shape = '';
    }

    setupBrush(brush, this, this.color, this.colorHue, this.background, this.seed, this.view);
  }
  protected getData() {
    return compressBrushData(this);
  }
  protected setData(data?: IBrushToolData) {
    if (data) {
      if (data.p) {
        Object.assign(this, decompressBrushData(data.p));
      }
    } else {
      if (!this.layer) throw new Error(`[BaseBrushTool.setData] Missing layer`);
      this.color = this.editor.primaryColor;
      this.colorHue = this.editor.primaryColorHue;
      this.background = this.editor.secondaryColor;
      this.opacityLocked = this.layer.opacityLocked;
      this.seed = generateSeed();
    }
  }
  verify() {
    if (!brushShapesMap.get(this.shape)) {
      this.editor.apply(() => this.shape = '');
    }
  }
  setup(data?: IBrushToolData) {
    this.layer = this.model.user.activeLayer;

    if (!this.layer) throw new Error(`[BaseBrushTool] Missing activeLayer`);
    if (!includes(this.editor.drawing.layers, this.layer)) throw new Error(`[BaseBrushTool] ActiveLayer not in drawing`);

    // TEMP: testing code
    while (this.prevLayers.length > 10) this.prevLayers.shift();
    this.prevLayers.push(this.layer.id);
    // END

    this.setData(data);

    if (this.stabilize === 0) {
      this.stable = this.proxy;
    } else {
      this.stable = createInnerStabilizer(this.id, this.proxy, roundPercent(this.stabilize));
    }

    this.setupBrush(this.brush);
  }
  start(x: number, y: number, pressure: number) {
    if (!this.layer) throw new Error('[BaseBrushTool] Missing layer');

    // TEMP: testing
    this.minX = this.maxX = x;
    this.minY = this.maxY = y;
    // END

    copyViewport(this.viewCopy, this.view);
    screenToDocumentXY(temp, x, y, this.viewCopy);
    this.stable.start!(temp.x, temp.y, pressure);

    const data: IBrushToolData = { id: this.id, p: this.getData() };
    if (this.drawingShiftLine) data.shiftLine = true;
    this.model.startToolView<IBrushToolData>(this.layer.id, this.viewCopy, data, x, y, pressure);
  }
  move(x: number, y: number, pressure: number) {
    // TEMP: testing
    this.minX = Math.min(this.minX, x);
    this.maxX = Math.max(this.maxX, x);
    this.minY = Math.min(this.minY, y);
    this.maxY = Math.max(this.maxY, y);
    // END

    // TEMP: testing (view might be changing during drawing, but then snapping back at the end)
    if (this.model.type !== 'remote' && !viewportsEqual(this.view, this.viewCopy)) {
      const msg = `view changed from copy ${JSON.stringify(this.viewCopy)} -> ${JSON.stringify(this.view)}`;
      logAction(msg);
      if (this.editor.type === 'client') (this.editor as Editor).errorReporter.reportError(msg);
    }
    // END

    screenToDocumentXY(temp, x, y, this.viewCopy);
    this.stable.move!(temp.x, temp.y, pressure);
    this.model.nextTool(x, y, pressure);
  }
  end(x: number, y: number, pressure: number) {
    // TEMP: testing
    this.minX = Math.min(this.minX, x);
    this.maxX = Math.max(this.maxX, x);
    this.minY = Math.min(this.minY, y);
    this.maxY = Math.max(this.maxY, y);
    // END

    this.endX = x;
    this.endY = y;
    this.endPressure = pressure;
    screenToDocumentXY(temp, x, y, this.viewCopy);
    this.stable.end!(temp.x, temp.y, pressure);
  }
}
