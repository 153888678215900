import { Component, ElementRef, Input } from '@angular/core';


/**
 * bind-html-element allows adding any native DOM element to be appended
 * as a child of this component (nativeElement.appendChild)
 * 
 * Upon destruction of bind-html-element, the child element will be hidden
 * and moved to be the child of document.body
 * 
 * This can be used to safely manage natively created elements via Angular
 * but ones that also should not be destrectured.
 * 
 * One of such elements is "video" which is a stateful DOM element.
 */
@Component({
  selector: 'bind-html-element',
  template: '',
  styles: [':host { display: flex; }'],
})
export class BindHtmlElement {
  private _htmlElement!: HTMLElement;

  constructor(private elementRef: ElementRef<HTMLElement>) {
  }

  @Input() set htmlElement(_elem: HTMLElement) {
    if (!this.elementRef.nativeElement) {
      this._htmlElement = _elem;
      return;
    }
    if (_elem === this._htmlElement) {
      return;
    }
    this.unbindElement();
    this._htmlElement = _elem;
    this.bindElement();
    this._htmlElement = _elem;
  }
  get htmlElement() { return this._htmlElement; }

  ngAfterViewInit() {
    this.bindElement();
  }
  private unbindElement() {
    if (!this._htmlElement) {
      return;
    }
    this._htmlElement.hidden = true;
    document.body.appendChild(this._htmlElement);
  }

  private bindElement() {
    if (!this._htmlElement) {
      return;
    }
    this._htmlElement.hidden = false;
    this.elementRef.nativeElement.appendChild(this._htmlElement);
  }

  ngOnDestroy() {
    this.unbindElement();
  }
}
