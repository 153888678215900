import { trigger, transition, query, animateChild } from '@angular/animations';
import { Component } from '@angular/core';
import { ToastService } from 'magma/services/toast.service';

@Component({
  selector: 'toast-container',
  templateUrl: './toast-container.component.pug',
  styleUrls: ['./toast-container.component.scss'],
  animations: [
    trigger('ngIfAnimation', [
      transition(':enter, :leave', [
        query('@*', animateChild()),
      ]),
    ]),
  ],
})
export class ToastContainerComponent {
  toasts$ = this.toastService.getToasts();

  constructor(private toastService: ToastService) {
  }
}
