import { Injectable } from '@angular/core';
import { StoreConfig, Store } from '@datorama/akita';
import { InvitationState } from './invitation.query';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'invitation', resettable: true })
export class InvitationStore extends Store<InvitationState> {
  constructor() {
    super({});
  }
}
