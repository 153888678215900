
import { Injectable } from '@angular/core';
import { PublicPromoCodeInfo } from '../common/interfaces';

@Injectable({ providedIn: 'any' })
export class CouponService {
  async getPromo(): Promise<PublicPromoCodeInfo | undefined> {
    return undefined;
  }
  getCoupon() {
    return '';
  }
}
