import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { AuthService } from 'services/auth.service';
import { FormType } from './login-signup.component';

@UntilDestroy()
@Component({
  selector: 'login-signup-modal',
  templateUrl: './login-signup-modal.component.pug',
  styleUrls: ['./login-signup-modal.component.scss'],
  animations: [],
})
export class LoginSignupModalComponent implements OnDestroy {
  constructor(authService: AuthService) {
    authService.token$
      .pipe(untilDestroyed(this))
      .pipe(filter(() => this.showModal))
      .subscribe(() => {
        this.save.emit();
      });
  }

  formType: FormType = FormType.Login;
  readonly formTypes = FormType;

  @Output() saveAsAnonymous = new EventEmitter();
  @Output() save = new EventEmitter();
  @Input() headerTitle = '';

  showModal = false;

  open() {
    this.formType = FormType.Login;
    this.showModal = true;
  }

  close() {
    this.showModal = false;
  }

  gotoForm(formType: FormType) {
    this.formType = formType;
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
  }
}
