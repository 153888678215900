import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClarityModule } from '@clr/angular';
import { TimeAgoDirective } from 'directives/time-ago.directive';
import { LoginSignupService } from 'magma/services/login-signup.service';
import { ManageService } from 'magma/services/manageService';
import { GravatarModule } from 'ngx-gravatar';
import { PortalLoginSignupService } from 'services/portal-login-signup.service';
import { PortalSharedModule } from 'magma/components/shared/portal-shared.module';
import { FromNow } from 'magma/components/admin/shared/from-now';
import { ToastComponent } from './toasts/toast/toast.component';
import { AppModalComponent } from './app-modal.component';
import { LoginSignupModalComponent } from './login-signup-modal.component';
import { LoginSignupComponent } from './login-signup.component';
import { TopBarComponent } from './top-bar.component';
import { ToastContainerComponent } from './toasts/toast-container/toast-container.component';
import { AvatarComponent } from './avatar.component';
import { DocumentStatusPipe } from 'pipes/document-status.pipe';
import { PortalManageService } from 'services/portal-manage.service';
import { CouponService } from 'magma/services/couponService';
import { PortalCouponService } from 'services/portal-coupon.service';
import { ErrorReporterService } from 'services/error-reporter.service';
import { MarkdownPipe } from 'pipes/markdown.pipe';
import { UNPATCHED_EVENT_DIRECTIVES } from 'directives/unpatched.directive';
import { BytesPipe } from 'pipes/bytes.pipe';

const components = [
  AppModalComponent,
  LoginSignupModalComponent,
  LoginSignupComponent,
  TopBarComponent,
  TimeAgoDirective,
  FromNow,
  ToastContainerComponent,
  ToastComponent,
  AvatarComponent,
  DocumentStatusPipe,
  MarkdownPipe,
  BytesPipe,
  ...UNPATCHED_EVENT_DIRECTIVES,
];

@NgModule({
  declarations: [
    ...components,
  ],
  exports: [
    ...components,
    PortalSharedModule,
  ],
  imports: [
    ClarityModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    GravatarModule,
    PortalSharedModule,
  ],
  providers: [
    { provide: LoginSignupService, useClass: PortalLoginSignupService },
    { provide: ManageService, useClass: PortalManageService },
    { provide: CouponService, useClass: PortalCouponService },
    ErrorReporterService,
  ],
})
export class SharedModule {
}
