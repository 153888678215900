import { StoreConfig, EntityStore } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { TeamsState } from './team.query';
import { TeamData } from 'shared/interfaces';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'teams', resettable: true, idKey: 'slug' })
export class TeamsStore extends EntityStore<TeamsState, TeamData, string> {
  constructor() {
    super([]);
  }
}
