import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  BACKGROUND_COLORS, MAX_ENTITY_NAME_LENGTH, MIN_IMAGE_WIDTH, MIN_IMAGE_HEIGHT, DEFAULT_DPI,
  DEFAULT_BACKGROUND_COLOR, DEFAULT_CANVAS_WIDTH, DEFAULT_CANVAS_HEIGHT,
  isImageSizeValid, getMaxImageWidth, getMaxImageHeight, doesImageSizeRequirePro, invalidImageSizeError,
} from '../../../../common/constants';
import { Model } from '../../../../services/model';
import {
  UserAction, Drawing, User, OtherAction, DrawingPermissions, DRAWING_NOTIFICATION_SETTINGS,
  DEFAULT_NOTIFICATION_SETTING, SelectedFolder,
} from '../../../../common/interfaces';
import { isEmptyDrawing } from '../../../../common/drawingUtils';
import { faFolder, faHashtag, faLock, blazeIcon } from '../../../../common/icons';
import { hasPermission, hasPro, hasDrawingRole } from '../../../../common/userRole';
import { clamp } from '../../../../common/mathUtils';
import { CreateDrawingProps, Modals } from '../../../../services/modals';
import { inchesToLocalUnits } from '../../../../common/settingsUtils';
import { disableMyArtdesk, drawingsPath, DRAWING_PERMISSIONS } from '../../../../common/data';
import { urlToDrawingId, getThumbPath, setEntityPassword, navigateToDrawing } from '../../../../common/clientUtils';
import { IAppNotificationService } from '../../../../services/app-notification.service.interface';
import { ManageService } from '../../../../services/manageService';
import { logAction } from '../../../../common/actionLog';

function canBecomeAdmin(user: User, drawing: Drawing) {
  if (hasDrawingRole(user, 'admin')) return 'is_admin';

  // ref: openedDrawing.ts:canBecomeAdmin
  if (user.anonymous && !IS_PORTAL) return 'anonymous';
  if (user.isSuperAdmin) return 'become_admin';
  if (drawing.hasAdmins) return 'has_admins';
  if (user.isCreator || user.role === 'owner') return 'become_admin';
  if (isEmptyDrawing(drawing)) return 'become_admin';
  return 'cannot_become';
}

@Component({
  selector: 'drawing-settings',
  templateUrl: 'drawing-settings.pug',
  styleUrls: ['drawing-settings.scss'],
})
export class DrawingSettings implements OnInit {
  readonly maxNameLength = MAX_ENTITY_NAME_LENGTH;
  readonly colors = BACKGROUND_COLORS;
  readonly minWidth = MIN_IMAGE_WIDTH;
  readonly minHeight = MIN_IMAGE_HEIGHT;
  readonly permissions = DRAWING_PERMISSIONS;
  readonly initIcon = faLock;
  readonly blazeIcon = blazeIcon;
  projectIcon = faHashtag;
  folderIcon = faFolder;
  @Input() data: CreateDrawingProps = {};
  @Output() close = new EventEmitter<void>();
  background = DEFAULT_BACKGROUND_COLOR;
  dpi = DEFAULT_DPI;
  name = '';
  url = '';
  width = DEFAULT_CANVAS_WIDTH;
  height = DEFAULT_CANVAS_HEIGHT;
  invite = false;
  resizeWarning = false;
  copyPermissions = false;
  respectOfflineOwners = false;
  error: string | undefined = undefined;
  password: string | undefined = undefined;
  addToSequence = false;
  activeTab = 0;
  openInNewWindow = false;
  appNotificationSetting = DEFAULT_NOTIFICATION_SETTING;
  appNotificationSettings = DRAWING_NOTIFICATION_SETTINGS;
  private sequenceIndex = -1;
  selectedFolder: SelectedFolder | undefined;
  selectedFolderName: string | undefined;
  constructor(
    private model: Model,
    private router: Router,
    private sanitizer: DomSanitizer,
    private manage: ManageService,
    private modals: Modals,
    private appNotificationService?: IAppNotificationService,
  ) {
  }
  async ngOnInit() {
    const drawing = this.drawing;

    if (drawing?.id) {
      this.width = drawing.width;
      this.height = drawing.height;
      this.background = (this.colors.find(c => c.value === drawing.background) || BACKGROUND_COLORS[0]).value;
      this.dpi = drawing.dpi;
      this.respectOfflineOwners = drawing.respectOfflineOwners;

      if (!this.create) {
        this.name = drawing.name;
        this.password = drawing.password;
      }
    }

    if (this.create) {
      this.name = this.data.name ?? '';
      this.addToSequence = !!this.data.addToSequence;
      this.sequenceIndex = this.data.sequenceIndex ?? -1;
      this.activeTab = this.data.existing ? 1 : 0;
      this.openInNewWindow = !!this.data.openInNewWindow;
      Object.assign(this.data, this.model.allowedFolderProjectTeam(this.data));
    }

    if (this.appNotificationService && this.drawing) {
      this.appNotificationSetting = await this.appNotificationService.getNotificationSettings(this.drawing.id);
    }
    if (this.folder) {
      const folder = await this.manage.folder(this.folder);
      this.selectedFolderName = folder?.name;
    }
  }
  private get hasPro() {
    const team = this.data.team ? this.manage.team(this.data.team) : undefined;
    return hasPro(this.model.user) || !!team?.pro;
  }
  get hasTrial() {
    return this.manage.hasTrial();
  }
  get drawing() {
    return this.data.drawing;
  }
  get create() {
    return !!this.data.create;
  }
  get isDrawingOwner() {
    return hasDrawingRole(this.model.user, 'owner') || !!this.model.user.isCreator;
  }
  get isDrawingAdminOrOwner() {
    return hasDrawingRole(this.model.user, 'admin');
  }
  get canHaveLargeCanvas() {
    const team = this.data.team ? this.manage.team(this.data.team) : undefined;
    const proUser = this.model.user.pro;
    const proTeam = !!team?.pro;
    return !!(IS_HOSTED || proUser || proTeam);
  }
  get maxWidth() {
    return getMaxImageWidth(this.canHaveLargeCanvas);
  }
  get maxHeight() {
    return getMaxImageHeight(this.canHaveLargeCanvas);
  }
  get valid() {
    return !this.create || (!!this.name && !!this.width && !!this.height && (this.team ? this.project : true));
  }
  get sizeError() {
    return this.create && !isImageSizeValid(this.width, this.height, true);
  }
  get sizeWarning() {
    return this.create && (this.width > 2048 || this.height > 2048);
  }
  get showSizeProPrompt() {
    return !this.canHaveLargeCanvas && doesImageSizeRequirePro(this.width, this.height);
  }
  get canInvite() {
    return this.create && !!this.drawing?.id && this.model.users.length;
  }
  get canCopyPermissions() {
    return this.create && this.canBecomeAdminOfNewDrawing && !!this.drawing?.id;
  }
  get hasAnyPermissions() {
    return this.permissions.some(p => !!this.drawing?.permissions[p.key]);
  }
  get adminAlert() {
    return this.drawing && canBecomeAdmin(this.model.user, this.drawing);
  }
  get canBecomeAdminOfNewDrawing() {
    return IS_PORTAL || !this.model.user.anonymous;
  }
  get units() {
    return this.model.settings.units;
  }
  get showPassword() {
    if (!IS_PORTAL) return false;

    // this is also showing password box for non-pro users, so they can see the upgrade prompt.
    const team = this.create ? this.data.team : this.drawing?.team;
    return !team && (this.create || this.password || this.isDrawingAdminOrOwner);
  }
  get canChangePassword() {
    return this.create || this.isDrawingOwner || this.isDrawingAdminOrOwner;
  }
  get proBlockPasswordChange() {
    return !this.hasPro && !this.model.user.isSuperAdmin;
  }
  get dontRespectOfflineOwners() {
    return !this.drawing?.respectOfflineOwners;
  }
  set dontRespectOfflineOwners(value) {
    this.respectOfflineOwners = !value;
  }
  get canChangeRespectOfflineOwners() {
    return this.create || this.isDrawingAdminOrOwner;
  }
  upgradeToPro() {
    this.manage.upgrade('create-drawing-bigger-canvas');
  }
  toRealSize(value: number) {
    return inchesToLocalUnits(this.model.settings, value / this.dpi);
  }
  sizeClick() {
    if (!this.create) this.resizeWarning = true;
  }
  cancel() {
    this.close.emit();
  }
  async submit() {
    if (!this.valid) return;

    this.error = undefined;

    let wnd: Window | null = null;

    try {
      if (this.create) {
        let id;

        if (this.activeTab === 1) {
          // add existing drawing to sequence
          id = urlToDrawingId(this.url);
          if (!id) throw new Error(`Invalid drawing ID or URL`);

          logAction('[local] Add to sequence');
          await this.model.server.otherAction(OtherAction.AddToSequence, id);
        } else {
          const width = this.width | 0;
          const height = this.height | 0;
          const password = this.proBlockPasswordChange ? undefined : this.password;

          if (this.openInNewWindow) wnd = window.open();

          if (!isImageSizeValid(width, height, this.canHaveLargeCanvas))
            throw new Error(invalidImageSizeError(this.canHaveLargeCanvas));

          id = await this.model.createDrawing({
            name: this.name,
            width,
            height,
            background: this.background === 'none' ? undefined : this.background,
            dpi: clamp(this.dpi || DEFAULT_DPI, 1, 10000),
            password,
            copyPermissions: this.copyPermissions,
            addToSequence: this.addToSequence,
            sequenceIndex: this.sequenceIndex,
            respectOfflineOwners: this.respectOfflineOwners,
            team: this.data.team,
            project: this.data.project,
            folder: this.data.folder,
          });

          await this.appNotificationService?.updateNotificationSettings(id, this.appNotificationSetting);

          setEntityPassword(id, password);

          if (this.invite) this.model.sendMessage(`/invite ${id}${password ? ` ${password}` : ''}`);
        }

        this.close.emit();

        if (wnd) {
          wnd.location.href = `${location.protocol}//${location.host}${drawingsPath}${id}`;
        } else {
          navigateToDrawing(this.router, id, 'create-drawing-modal');
        }
      } else if (this.drawing) {
        if (!hasPermission(this.drawing, this.model.user, 'drawingSettings'))
          throw new Error(`You don't have permission to change drawing settings`);

        await this.model.updateDrawing({
          name: this.name,
          background: this.background === 'none' ? '' : this.background,
          dpi: this.dpi,
          respectOfflineOwners: this.respectOfflineOwners,
        });

        if (this.canChangePassword && this.password !== this.drawing.password) {
          // prevent non-pro from setting password, but allow to clear
          if (!(this.proBlockPasswordChange && this.password)) {
            await this.model.server.updateDrawingPassword(this.model.connId, this.password ?? '');
            setEntityPassword(this.drawing.id, this.password);
          }
        }

        await this.appNotificationService?.updateNotificationSettings(this.drawing.id, this.appNotificationSetting);

        this.close.emit();
      }
    } catch (e) {
      this.error = e.message;
      wnd?.close();
    }
  }
  async becomeAdmin() {
    this.error = undefined;

    try {
      await this.model.tryUserAction(UserAction.BecomeAdmin, 0);
    } catch (e) {
      this.error = e.message;
    }
  }

  // importing / adding existing drawing to sequence
  get thumbSrc() {
    const id = urlToDrawingId(this.url);
    return id && `${location.protocol}//${location.host}${getThumbPath(id)}`;
  }
  fileThumbUrl: string | undefined = undefined;
  fileThumbSrc: SafeUrl | undefined = undefined;
  file: File | undefined = undefined;
  fileSelected(input: any) {
    const file = input.files[0];

    if (this.fileThumbUrl) {
      URL.revokeObjectURL(this.fileThumbUrl);
      this.fileThumbSrc = undefined;
      this.fileThumbUrl = undefined;
    }

    this.file = file;

    if (file) {
      this.fileThumbUrl = URL.createObjectURL(file);
      this.fileThumbSrc = this.sanitizer.bypassSecurityTrustUrl(this.fileThumbUrl);
    }
  }
  getFileName(input: any) {
    return input.files[0]?.name;
  }
  getPermission(key: keyof DrawingPermissions) {
    return this.drawing?.permissions[key];
  }
  get showNotificationSettings() {
    return !!this.appNotificationService;
  }
  get teamAvatar() {
    return this.team ? this.manage.team(this.team)?.avatar : undefined;
  }
  get projectName() {
    return this.project ? this.manage.project(this.project)?.name : undefined;
  }
  async selectTeam() {
    const selectedFolder = await this.modals.selectFolder({
      teamId: undefined,
      showArtdesk: !disableMyArtdesk,
      isCreateDrawingModal: true,
      preselectedFolder: { folderId: this.folder, projectId: this.project, teamId: this.team },
    });

    if (selectedFolder) {
      if (selectedFolder.folderId) {
        const folder = await this.manage.folder(selectedFolder.folderId);
        this.selectedFolderName = folder?.name;
      }
      this.data.folder = selectedFolder.folderId;
      this.data.project = selectedFolder.projectId;
      this.data.team = selectedFolder.teamId;
    }
  }
  get team() {
    return this.data.team;
  }
  get project() {
    return this.data.project;
  }
  get folder() {
    return this.data.folder;
  }
}
