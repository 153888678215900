<div class="top-left">
  <div class="branding" *ngIf="editorHeader" [ngClass]="{ small: editorHeader, 'logged-in': loggedIn$ | async }">
    <div class="nav-link">
      <ng-content select=".top-bar-left"></ng-content>
    </div>
  </div>
  <div class="branding" *ngIf="!editorHeader" [ngClass]="{ small: editorHeader, 'logged-in': loggedIn$ | async }">
    <div class="nav-link">
      <img id="logo-embed" src="/assets/logo-full.a0569acb.svg" />
    </div>
  </div>
  <div class="header-nav">
    <ng-content select=".top-bar-left-header.header-nav"></ng-content>
  </div>

</div>
<div class="top-center">

  <ng-content select=".top-bar-content-item"></ng-content>
</div>
<div class="top-right">
  <ng-content select=".top-bar-right"></ng-content>

  <div class="search-container" *ngIf="!editorHeader && (loggedIn$ | async)">
    <div class="search-flowchart-container" [class.focus]="searchIsFocused">
      <span class="search-icon"><svg-icon [icon]="faSearch"></svg-icon></span>
      <input #searchInput placeholder="Search..." [(ngModel)]="keyToSearch" (focus)="searchIsFocused = true" (blur)="searchIsFocused = false" class="search-flowchart-input" />
      <span class="close-search">
        <svg-icon *ngIf="keyToSearch.length > 0" (click)="clearSearch()" [icon]="farTimes"></svg-icon>
      </span>
    </div>
  </div>

  <div *ngIf="!editorHeader || editorHeader == null" style="display: flex;">
    <div class="user-info" *ngIf="user$ | async as user" [ngClass]="{ 'logged-in': loggedIn$ | async }">
      <clr-dropdown style="width: 100%; display: flex;">
        <button class="nav-item avatar" clrDropdownTrigger>
          <avatar [user]="user" [size]="22"></avatar>
          <span class="email ellipsis">{{ user }}</span>
          <svg-icon [icon]="faChevronDown"></svg-icon>
        
        </button>
        <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
          <a routerLink="/my/account" clrDropdownItem>
            <svg-icon [icon]="faCog"></svg-icon> &nbsp; Account settings
          </a>
          <button class="btn" (click)="logout()" clrDropdownItem="clrDropdownItem">
            Log out
          </button>
          <ng-container *ngIf="isSuperAdmin$ | async">
            <div class="dropdown-divider" role="separator"></div>
            <button (click)="openSuperAdmin()" clrDropdownItem>
              Great powers
            </button>
          </ng-container>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  </div>
  <ng-content select=".share-container"></ng-content>
  <ng-content select=".header-share-button-right"></ng-content>
  <ng-content select=".new-flowchart"></ng-content>
</div>
