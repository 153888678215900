import { Injectable, NgZone } from '@angular/core';
import { Subscription } from 'rxjs';
import { positionElements, PositioningInternalOptions } from './positioning-internal';

export interface PositioningOptions {
  /** An element which will be moved */
  element: HTMLElement;
  /** An element which the `element` will be attached to  */
  target: HTMLElement;
  /**
   * A string of the form 'vert-attachment horiz-attachment' or 'placement'
   * - placement can be "top", "bottom", "left", "right"
   * not yet supported:
   * - vert-attachment can be any of 'top', 'middle', 'bottom'
   * - horiz-attachment can be any of 'left', 'center', 'right'
   */
  attachment: string;
  /** A string similar to `attachment`. The one difference is that, if it's not provided,
   * `targetAttachment` will assume the mirror image of `attachment`.
   */
  targetAttachment?: string;
  /** A string of the form 'vert-offset horiz-offset'
   * - vert-offset and horiz-offset can be of the form "20px" or "55%"
   */
  offset?: string;
  /** A string similar to `offset`, but referring to the offset of the target */
  targetOffset?: string;
  /** If true component will be attached to body */
  appendToBody?: boolean;
  // positioning options
  options?: PositioningInternalOptions;
}

@Injectable({ providedIn: 'any' })
export class PositioningService {
  private positionElements = new Map<HTMLElement, PositioningOptions>();
  private frame = 0;
  private zoneSubscription: Subscription | undefined = undefined;
  constructor(private zone: NgZone) {
    zone.runOutsideAngular(() => {
      window.addEventListener('scroll', this.scheduleUpdate, { capture: true, passive: true });
      window.addEventListener('resize', this.scheduleUpdate, { passive: true });
    });
  }
  addPositionElement(options: PositioningOptions) {
    this.positionElements.set(options.element, options);
    this.zoneSubscription = this.zoneSubscription ?? this.zone.onStable.subscribe(this.calcPosition);
  }
  deletePositionElement(element: HTMLElement) {
    this.positionElements.delete(element);

    if (!this.positionElements.size) {
      this.zoneSubscription?.unsubscribe();
      this.zoneSubscription = undefined;
    }
  }
  calcPosition = () => {
    this.zone.runOutsideAngular(this.scheduleUpdate);
  };
  private scheduleUpdate = () => {
    this.frame = this.frame || requestAnimationFrame(this.update);
  };
  private update = () => {
    this.frame = 0;
    this.positionElements.forEach(({ target, element, attachment, appendToBody, options }) => {
      positionElements(target, element, attachment, appendToBody, options);
    });
  };
}
