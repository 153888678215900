import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'confirm-modal',
  templateUrl: 'confirm-modal.pug',
})
export class ConfirmModal {
  @Output() close = new EventEmitter<boolean>();
  @Input() data = { message: '' };
  ok() {
    this.close.emit(true);
  }
  cancel() {
    this.close.emit(false);
  }
}
