import { Injectable } from '@angular/core';

/* NOTE: this interface is a copy of localforage's interface that's not exported */
@Injectable()
export abstract class LocalForage {
  abstract getItem<T>(key: string, callback?: (err: any, value: T | null) => void): Promise<T | null>;

  abstract setItem<T>(key: string, value: T, callback?: (err: any, value: T) => void): Promise<T>;

  abstract removeItem(key: string, callback?: (err: any) => void): Promise<void>;

  abstract clear(callback?: (err: any) => void): Promise<void>;

  abstract length(callback?: (err: any, numberOfKeys: number) => void): Promise<number>;

  abstract key(keyIndex: number, callback?: (err: any, key: string) => void): Promise<string>;

  abstract keys(callback?: (err: any, keys: string[]) => void): Promise<string[]>;

  abstract iterate<T, U>(
    iteratee: (value: T, key: string, iterationNumber: number) => U,
    callback?: (err: any, result: U) => void,
  ): Promise<U>;
}
