import { compact } from 'lodash';
import { getAvatarPath } from 'magma/common/utils';
import { UserData } from 'shared/interfaces';

const tawkPromise = new Promise(resolve => {
  if (window.Tawk_API) {
    window.Tawk_API.onLoad = resolve as () => void;
  }
});

async function setUserContextForTawk(user?: UserData) {
  if (!window.Tawk_API) {
    return;
  }
  await tawkPromise;
  const element = document.querySelectorAll(`iframe[title='chat widget']`)[1];
  if (element) {
    const style = element.getAttribute('style');
    element.setAttribute('style', `${style}; transform: scale(0.75, 0.75) !important;`);
  }

  if (user) {
    window.Tawk_API.setAttributes({
      email: user.email || '',
      name: user.name,
      id: user._id,
      hash: user.tawkHash,
    });
  } else {
    window.Tawk_API.setAttributes({
      // - tawk doesn't allow unsetting base attributes
      // - trying to unset base attributes would make setAttributes a no-op
      id: null,
    });
  }
}

async function setUserContextForIntercom(user?: UserData) {
  if (!window.Intercom) {
    return;
  }
  const userModel = {
    email: user?.email || user?.unverifiedEmail,
    has_verified_email: !!user?.email,
    name: user?.name,
    user_id: user?._id,
    ...user?.avatar && { avatar: {
      type: 'avatar',
      image_url: `${window.location.origin}${getAvatarPath(user.avatar, 64)}` },
    },
    user_hash: user?.intercomHash,
    signed_up_at: (new Date(user?.createdAt ?? 0).getTime() / 1000) | 0,
    subscription_status: user?.subscriptionStatus?.status,
    subscription_period_ends_at: user?.subscriptionStatus?.currentPeriodEnd ? (user?.subscriptionStatus?.currentPeriodEnd / 1000) | 0 : undefined,
    subscription_canceled: user?.subscriptionStatus?.cancelAtPeriodEnd || false,
    pro: user?.pro,
    anonymous: user?.userType === 'anonymous',
    work_role: user?.workRole,
  };
  if (user?.intercomPayload) {
    // this secret payload visible only by the server
    window.intercomEncryptedPayload = user?.intercomPayload;
  }
  window.Intercom('update', userModel);
}

export async function setUserContextForIntegrations(user?: UserData) {
  void setUserContextForTawk(user);
  void setUserContextForIntercom(user);
}
