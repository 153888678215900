import { Directive, Input, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({ selector: '[radioGroup]' })
export class RadioGroup<T> {
  @Input() disabled = false;
  @Input() radioGroup?: T;
  @Output() radioGroupChange = new EventEmitter<T>();

  // TODO:
  // @HostListener('keydown.ArrowRight', ['$event'])
  // @HostListener('keydown.ArrowDown', ['$event'])
  // selectNext(event: KeyboardEvent) {
  //   this.selectInDirection('next');
  //   event.preventDefault();
  // }

  // @HostListener('keydown.ArrowLeft', ['$event'])
  // @HostListener('keydown.ArrowUp', ['$event'])
  // selectPrevious(event: KeyboardEvent) {
  //   this.selectInDirection('previous');
  //   event.preventDefault();
  // }
}

@Directive({ selector: '[radioValue]' })
export class RadioValue<T> {
  @Input() radioValue?: T;
  @Input() isDisabled = false;
  @HostBinding('class.active')
  get active() {
    return this.group.radioGroup === this.radioValue;
  }
  @HostBinding()
  get disabled() {
    return this.group.disabled || this.isDisabled;
  }
  constructor(private group: RadioGroup<T>) {
  }
  @HostListener('click')
  click() {
    if (!this.group.disabled && !this.isDisabled && this.group.radioGroup !== this.radioValue) {
      this.group.radioGroup = this.radioValue;
      this.group.radioGroupChange.emit(this.radioValue);
    }
  }
}
