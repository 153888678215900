import { Directive, HostBinding, HostListener, EventEmitter, Output } from '@angular/core';

@Directive({ selector: '[dropFile]' })
export class DropFile {
  @Output() dropFile = new EventEmitter<File[]>();
  @Output() dropUrl = new EventEmitter<string>();
  @HostBinding('class.file-drag-over') over = 0;
  @HostListener('drop', ['$event'])
  drop(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();
    this.over = 0;

    if (e.dataTransfer) {
      if (e.dataTransfer.files?.length) {
        this.dropFile.emit(Array.from(e.dataTransfer.files));
      } else {
        const url = e.dataTransfer.getData('text/uri-list') || e.dataTransfer.getData('text/plain');
        if (url) this.dropUrl.emit(url);
      }
    }
  }
  @HostListener('dragover', ['$event'])
  dragover(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();
    // if (e.dataTransfer) e.dataTransfer.dropEffect = 'copy';
  }
  @HostListener('dragenter')
  dragenter() {
    this.over++;
  }
  @HostListener('dragleave')
  dragleave() {
    this.over = Math.max(0, this.over - 1);
  }
}
