import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep, find, findIndex, uniq } from 'lodash';
import { ToastService } from 'magma/services/toast.service';
import { of } from 'rxjs';
import { catchError, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { handleHttpError, toPromise } from 'shared/utils';
import { TeamTreeItem } from './../../../shared/interfaces';
import {
  EntityData, EntityMoveResponseData, CreateProjectData, ProjectData, UpdateProjectData, FolderInfo
} from '../../../shared/interfaces';
import { EntitiesStore } from './entities.store';
import { ProjectQuery } from './projects.query';
import { ProjectStore } from './projects.store';
import { TeamsStore } from './team.store';

export interface MovedFlowchart {
  entities: EntityData[];
  project?: string | null | undefined;
  folder?: string;
}

@Injectable({ providedIn: 'root' })
export class ProjectService {
  projectId$ = this.projectQuery.selectActiveId().pipe(map(x => x ?? undefined), distinctUntilChanged());

  constructor(
    private httpClient: HttpClient,
    private projectStore: ProjectStore,
    private projectQuery: ProjectQuery,
    private flowchartsStore: EntitiesStore,
    private teamStore: TeamsStore,
    private toastService: ToastService,
  ) {
  }

  get activeProjectId() {
    return this.projectQuery.getActiveId();
  }

  get activeProject() {
    return this.projectQuery.getActive();
  }

  setActiveProject(id: string | null | undefined) {
    this.projectStore.setActive(id || null);
  }

  setProjects(projects: ProjectData[]) {
    this.projectStore.set(projects);
  }

  addEntityToProject(entity: EntityData) {
    if (entity.project) {
      const projectId = typeof entity.project === 'string' ? entity.project : entity.project._id;
      this.projectStore.update(projectId, project => ({ ...project, entities: [...project.entities ?? [], entity] }));
    }
  }

  removeEntityFromProject(id: string, projectId: string) {
    this.projectStore.update(projectId, project => ({ ...project, entities: project.entities?.filter(e => e._id !== id) }));
  }

  renameEntityInProject(entity: EntityData, name: string) {
    this.updateEntityInProject({ ...entity, name });
  }

  updateEntityInProject(entity: EntityData) {
    if (!entity.project) return;
    const projectId = typeof entity.project === 'string' ? entity.project : entity.project._id;
    const project = this.getProject(projectId);
    if (!project) return;

    const entities = project.entities?.map(e => e._id === entity._id ? { ...e, ...entity } : e);
    this.projectStore.update(project._id, p => ({ ...p, entities }));
  }

  setLoading(loading: boolean) {
    this.projectStore.setLoading(loading);
  }

  async createNewProject(project: CreateProjectData) {
    const result = await toPromise(this.httpClient.post<ProjectData>(`/api/projects/${project.team}/create-project`, project));
    this.projectStore.add(result);
    return result;
  }

  getProject(id: string | undefined) {
    return id ? this.projectQuery.getEntity(id) : undefined;
  }

  getFolderTree(projectId: string) {
    return this.httpClient.get<FolderInfo[]>(`/api/projects/${projectId}/tree`).pipe(handleHttpError());
  }

  getProjectTree(teamId?: string) {
    return this.httpClient.get<TeamTreeItem[]>(`/api/teams/tree${teamId ? `?teamId=${teamId}` : ''}`).pipe(handleHttpError());
  }

  getProjectWithEntities(projectId: string, folder?: string) {
    return this.httpClient.get<ProjectData>(`/api/projects/${projectId}${folder ? `?folder=${folder}` : ''}`)
      .pipe(
        tap(project => this.projectStore.update(projectId, project)),
        handleHttpError(),
      );
  }

  getAllEntitiesForProject(projectId: string) {
    return this.httpClient.get<ProjectData>(`/api/projects/${projectId}?all=true`)
      .pipe(handleHttpError());
  }

  updateProject(projectId: string, update: UpdateProjectData) {
    return this.httpClient.put(`/api/projects/${projectId}`, update)
      .pipe(
        tap(() => this.projectStore.update(projectId, project => ({ ...project, ...update }))),
        handleHttpError(),
      );
  }

  async deleteProject(projectId: string, teamId: string) {
    await toPromise(this.httpClient.delete<Response>(`/api/projects/${projectId}`));
    this.teamStore.update(teamId, team => {
      const projects = team.projects.filter(project => project._id !== projectId);
      return { ...team, projects };
    });
    this.projectStore.remove(projectId);
  }

  // TODO: why is tnis in project service ???
  moveEntities(data: MovedFlowchart) {
    const { entities, project, folder } = data;

    if (!entities.length) return of(null);

    const uniqueProjects = uniq(entities.map(e => e.project).filter(x => x)) as string[];
    const fromFolder = entities[0].folder; // TODO: this will be wrong when moving from multiple folders

    // TODO: cleanup
    uniqueProjects.map(uniqueProject => {
      if (uniqueProject === undefined) {
        if (folder !== fromFolder || uniqueProject !== project) {
          this.flowchartsStore.update(entities.map(e => e._id), entity => {
            const clonedEntity = cloneDeep(entity) as EntityData;
            clonedEntity.isHidden = true;
            return clonedEntity;
          });

          if (folder) {
            this.flowchartsStore.update(folder, f => {
              const entity = cloneDeep(f) as EntityData;
              entity.children = (entity.children || 0) + entities.length;
              return entity;
            });
          }
        }
      } else {
        this.projectStore.update(uniqueProject === undefined ? null : uniqueProject, p => {
          if (!p.entities) return p;

          const clonedEntitiesFromProject = cloneDeep(p.entities) as EntityData[];
          clonedEntitiesFromProject.map((currentEntity) => {
            if (findIndex(entities, e => e._id === currentEntity._id) > -1) {
              currentEntity.isHidden = true;
            }
          });

          if (folder) {
            const movedToFolder = find(clonedEntitiesFromProject, e => e._id === folder);
            if (movedToFolder) {
              movedToFolder.children = (movedToFolder.children || 0) + entities.length;
            }
          }

          return { ...p, entities: clonedEntitiesFromProject };
        });
      }
    });

    const ids = entities.map(e => e.shortId);

    return this.httpClient.put<EntityMoveResponseData>('/api/entities', { entities: ids, project, folder }).pipe(
      map(moveResponse => {
        if (moveResponse.failure.length > 0) {
          this.toastService.error({ message: 'There was an error moving one or more items' });

          const entitiesFromMoveData = moveResponse.failure
            .map(failedEntity => entities.find(e => e._id === failedEntity.id))
            .filter(x => !!x) as EntityData[];

          this.revertEntityMove(entitiesFromMoveData, data);
        } else {
          this.flowchartsStore.remove(entities.map(e => e._id));
          this.projectStore.update(uniqueProjects, project => {
            const entities = project.entities?.filter((entity) => !entity.isHidden);
            return { ...project, entities };
          });
        }

        return null;
      }),
      handleHttpError(),
      catchError((error) => {
        DEVELOPMENT && console.error('error in moving items', data.entities, error);
        this.toastService.error({ message: error.message });
        this.revertEntityMove(data.entities, data);
        return of(null);
      }),
    );
  }

  private revertEntityMove(failedEntities: EntityData[], entityMoveData: MovedFlowchart) {
    for (const entity of failedEntities) {
      const projectId = entity.project as string || null;

      if (!projectId) {
        this.flowchartsStore.update(entity._id, currentEntity => {
          const clonedCurrentEntity = cloneDeep(currentEntity) as EntityData;
          clonedCurrentEntity.isHidden = false;
          return clonedCurrentEntity;
        });

        if (entityMoveData.folder) {
          this.updateFolderChildrenCount(null, entityMoveData.folder, -1);
        }
      } else {
        this.projectStore.update(projectId, p => {
          const entitiesListFromStore = cloneDeep(p.entities) as EntityData[];

          entitiesListFromStore.map((entityFromStore) => {
            if (findIndex(entitiesListFromStore, (e) => e._id === entityFromStore._id) > -1) {
              entityFromStore.isHidden = false;
            }
          });

          if (entityMoveData.folder) {
            const folder = find(entitiesListFromStore, (entity) => entity._id === entityMoveData.folder);

            if (folder) {
              folder.children = folder.children! - 1;
            }
          }

          return { ...p, entities: entitiesListFromStore };
        });
      }
    }
  }

  updateFolderChildrenCount(projectId: string | null, folderId: string, count: number) {
    if (!projectId) {
      this.flowchartsStore.update(folderId, f => {
        const entity = cloneDeep(f) as EntityData;
        entity.children = (entity.children || 0) + count;
        return entity;
      });
    } else {
      this.projectStore.update(projectId, p => {
        const entities = cloneDeep(p.entities);
        const folder = find(entities, (entity) => entity._id === folderId);

        if (folder) {
          folder.children = (folder.children || 0) + count;
        }

        return p;
      });
    }
  }

  async reorder(teamId: string, projects: string[]) {
    await toPromise(this.httpClient.post<void>(`/api/projects/${teamId}/reorder`, { projects }));
    projects.forEach((id, i) => this.projectStore.update(id, { order: i + 1 }));
  }
}
