import { FontFamilies, FontFamily, FontStyleNames, loadFontFamilies } from './font-family';
import { getWithRetry } from '../shapes';
import { TextLayer } from '../interfaces';
import { getUsedFonts } from './text-utils';
import { Editor, hasLoadedDrawing } from '../../services/editor';
import { hasAllFontsLoaded } from '../drawing';
import { Subject } from 'rxjs';

const LOG = false;
const ARTIFICIAL_DEFERRING = 0;

export const FONTS: FontFamilies = new Map<string, FontFamily>();
export const LOADING_FONTS = new Map<string, Promise<FontFamily>>();
type FontSourcesMap = { [key: string]: Partial<Record<FontStyleNames, string>> };
export const FONTS_SOURCES = {
  Montserrat: {
    [FontStyleNames.Regular]: 'Montserrat-Regular.ttf',
    [FontStyleNames.Italic]: 'Montserrat-Italic.ttf',
    [FontStyleNames.Bold]: 'Montserrat-Bold.ttf',
    [FontStyleNames.BoldItalic]: 'Montserrat-BoldItalic.ttf',
  },
  Bangers: {
    [FontStyleNames.Regular]: 'Bangers-Regular.ttf',
  },
  'Black Ops One': {
    [FontStyleNames.Regular]: 'BlackOpsOne-Regular.ttf',
  },
  Bungee: {
    [FontStyleNames.Regular]: 'Bungee-Regular.ttf',
  },
  Caveat: {
    [FontStyleNames.Regular]: 'Caveat-Regular.ttf',
    [FontStyleNames.Bold]: 'Caveat-Bold.ttf',
  },
  'Courier Prime': {
    [FontStyleNames.Regular]: 'CourierPrime-Regular.ttf',
    [FontStyleNames.Italic]: 'CourierPrime-Italic.ttf',
    [FontStyleNames.Bold]: 'CourierPrime-Bold.ttf',
    [FontStyleNames.BoldItalic]: 'CourierPrime-BoldItalic.ttf',
  },
  Galindo: {
    [FontStyleNames.Regular]: 'Galindo-Regular.ttf',
  },
  'Hachi Maru Pop': {
    [FontStyleNames.Regular]: 'HachiMaruPop-Regular.ttf',
  },
  KaushanScript: {
    [FontStyleNames.Regular]: 'KaushanScript-Regular.ttf',
  },
  Kavivanar: {
    [FontStyleNames.Regular]: 'Kavivanar-Regular.ttf',
  },
  KleeOne: {
    [FontStyleNames.Regular]: 'KleeOne-Regular.ttf',
    [FontStyleNames.Bold]: 'KleeOne-SemiBold.ttf', // warning semi bold used as bold to enable toggling bold
  },
  Lexend: {
    // [FontStyleNames.Light]: 'Lexend-Light.ttf', // disabled because looks like regular, awaiting investigation and fix
    [FontStyleNames.Regular]: 'Lexend-Regular.ttf',
    // [FontStyleNames.SemiBold]: 'Lexend-SemiBold.ttf', // disabled because looks like regular, awaiting investigation and fix
    [FontStyleNames.Bold]: 'Lexend-Bold.ttf',
  },
  Lora: {
    [FontStyleNames.Regular]: 'Lora-Regular.ttf',
    [FontStyleNames.Italic]: 'Lora-Italic.ttf',
    [FontStyleNames.Bold]: 'Lora-Bold.ttf',
    [FontStyleNames.BoldItalic]: 'Lora-BoldItalic.ttf',
  },
  'Mochiy Pop POne': {
    [FontStyleNames.Regular]: 'MochiyPopPOne-Regular.ttf',
  },
  'Open Sans': {
    [FontStyleNames.Regular]: 'OpenSans-Regular.ttf',
    [FontStyleNames.Italic]: 'OpenSans-Italic.ttf',
    [FontStyleNames.Bold]: 'OpenSans-Bold.ttf',
    [FontStyleNames.BoldItalic]: 'OpenSans-BoldItalic.ttf',
  },
  Outfit: {
    [FontStyleNames.Regular]: 'Outfit-Regular.ttf',
    [FontStyleNames.Bold]: 'Outfit-Bold.ttf',
    // [FontStyleNames.Medium]: 'Outfit-Medium.ttf', // disabled because looks like regular, awaiting investigation and fix
    // [FontStyleNames.SemiBold]: 'Outfit-SemiBold.ttf', // disabled because looks like regular, awaiting investigation and fix
  },
  Pacifico: {
    [FontStyleNames.Regular]: 'Pacifico-Regular.ttf',
  },
  PatrickHand: {
    [FontStyleNames.Regular]: 'PatrickHand-Regular.ttf',
  },
  'Roboto Mono': {
    [FontStyleNames.Regular]: 'RobotoMono-Regular.ttf',
    [FontStyleNames.Italic]: 'RobotoMono-Italic.ttf',
    [FontStyleNames.Bold]: 'RobotoMono-Bold.ttf',
    [FontStyleNames.BoldItalic]: 'RobotoMono-BoldItalic.ttf',
  },
  'Rubik Wet Paint': {
    [FontStyleNames.Regular]: 'RubikWetPaint-Regular.ttf',
  },
  Silkscreen: {
    [FontStyleNames.Regular]: 'Silkscreen-Regular.ttf',
    [FontStyleNames.Bold]: 'Silkscreen-Bold.ttf',
  },
  'Special Elite': {
    [FontStyleNames.Regular]: 'SpecialElite-Regular.ttf',
  },
  'Uncial Antiqua': {
    [FontStyleNames.Regular]: 'UncialAntiqua-Regular.ttf',
  },
  'Unifraktur Cook': {
    [FontStyleNames.Regular]: 'UnifrakturCook-Bold.ttf', // warning, bold version used as regular since we have only that
  }
};

export function initFonts (get: (url: string) => Promise<ArrayBuffer>, fontsWhitelist?: string[]) {
  if (fontsWhitelist) {
    const newFontSources: FontSourcesMap = { };
    for (const fontFamilyName of fontsWhitelist) {
      newFontSources[fontFamilyName] = (FONTS_SOURCES as FontSourcesMap)[fontFamilyName];
    }
    return loadFontFamilies(newFontSources, get, FONTS).then((r) => {
      DEVELOPMENT && LOG && console.log(`Loaded (${FONTS.size}) fonts:`, Array.from(FONTS.keys()));
      return r;
    });
  } else {
    return loadFontFamilies(FONTS_SOURCES, get, FONTS).then((r) => {
      DEVELOPMENT && LOG && console.log(`Loaded (${FONTS.size}) fonts:`, Array.from(FONTS.keys()));
      return r;
    });
  }
}

export const clientGetFonts = (url: string) => getWithRetry<ArrayBuffer>(`/assets/fonts/${url}`, 'arraybuffer');
export async function loadAnotherFont(name: string) {
  const font = FONTS.get(name);
  if (font) return font;

  const loading = LOADING_FONTS.get(name);
  if (loading) return loading;

  LOG && DEVELOPMENT && console.log(`Loading font "${name}"`);
  const promise = new Promise((resolve) => setTimeout(() => resolve(true), !DEVELOPMENT ? 0 : ARTIFICIAL_DEFERRING))
    .then(() => loadFontFamilies({ [name]: (FONTS_SOURCES as FontSourcesMap)[name] }, clientGetFonts, FONTS))
    .then(() => {
      LOG && DEVELOPMENT && console.log(`Loaded font "${name}"`);
      requireFontsCheck();
      return FONTS.get(name)!;
    });
  LOADING_FONTS.set(name, promise.finally(() => LOADING_FONTS.delete(name)));
  fontStartedLoading$.next(name);
  return promise;
}

export const someFontsLoading = (): boolean => {
  return LOADING_FONTS.size > 0;
};

let checkLoadingFonts = true;
export const fontStartedLoading$ = new Subject<string>();
export const shouldCheckLoadingFonts = () => checkLoadingFonts;
export const requireFontsCheck = () => {
  if (!checkLoadingFonts) {
    if (DEVELOPMENT && LOG) console.log('Fonts are marked as requiring loading check');
    checkLoadingFonts = true;
  }
};
export const checkedLoadingFonts = () => {
  if (checkLoadingFonts) {
    if (DEVELOPMENT && LOG) console.log('Fonts loading check has been performed');
    checkLoadingFonts = false;
  }
};

export const loadFontsForLayer = (layer: TextLayer): Promise<any> => {
  const requiredFonts = getUsedFonts(layer);
  const requiredFontsLoadings = requiredFonts.map((font) => loadAnotherFont(font));
  return Promise.all(requiredFontsLoadings).then(() => {
    layer.fontsLoaded = true;
  });
};

export const hasFontsLoaded = (layer: TextLayer): boolean => {
  const usedFonts = getUsedFonts(layer);
  return !!usedFonts.length && usedFonts.every(f => FONTS.has(f));
};

export const isFontLoaded = (name: string): boolean => {
  return FONTS.has(name);
};

export const fontsLoadingCommandDisabledTooltip = (editor: Editor) => {
  return () => {
    if (hasLoadedDrawing(editor) && !hasAllFontsLoaded(editor.drawing)) {
      return 'Wait until fonts load';
    } else {
      return '';
    }
  };
};
