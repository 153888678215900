import { addRect, createRect } from './rect';
import { PolySegment, Poly, Mat2d, Rect, Polyf, PolyfSegment } from './interfaces';
import { clamp } from './mathUtils';

export function createPolySegment(initialCapacity = 0): PolySegment {
  return { items: new Int32Array(initialCapacity * 2), size: 0 };
}

export function clonePolySegment({ items, size }: PolySegment): PolySegment {
  return { items: items.slice(0), size };
}

function resizePolySegment(segment: PolySegment) {
  const newItems = new Int32Array(segment.items.length ? segment.items.length * 2 : 32);
  newItems.set(segment.items);
  segment.items = newItems;
}

export function addPolySegmentPoint(segment: PolySegment, x: number, y: number) {
  while ((segment.size << 1) >= segment.items.length) {
    resizePolySegment(segment);
  }

  segment.items[(segment.size << 1) + 0] = x;
  segment.items[(segment.size << 1) + 1] = y;
  segment.size++;
}

export function setPolySegmentPoint(segment: PolySegment, index: number, x: number, y: number) {
  segment.items[(index << 1) + 0] = x;
  segment.items[(index << 1) + 1] = y;
}

export function isPolySegmentEmpty(segment: PolySegment) {
  return segment.size < 3;
}

export function isPolyfSegmentEmpty(segment: PolyfSegment) {
  return segment.length < 3;
}

export function getPolySegmentBounds({ items, size }: PolySegment) {
  if (size === 0) return createRect(0, 0, 0, 0);

  let xmin = items[0];
  let ymin = items[1];
  let xmax = xmin;
  let ymax = ymin;
  const size2 = size << 1;

  for (let i = 2; i < size2; i += 2) {
    const x = items[i];
    const y = items[i + 1];
    if (x < xmin) xmin = x;
    if (x > xmax) xmax = x;
    if (y < ymin) ymin = y;
    if (y > ymax) ymax = y;
  }

  return createRect(xmin, ymin, xmax - xmin, ymax - ymin);
}

export function getPolyfSegmentBounds(items: PolyfSegment) {
  if (items.length === 0) return createRect(0, 0, 0, 0);

  let xmin = items[0];
  let ymin = items[1];
  let xmax = xmin;
  let ymax = ymin;
  const size2 = items.length << 1;

  for (let i = 2; i < size2; i += 2) {
    const x = items[i];
    const y = items[i + 1];
    if (x < xmin) xmin = x;
    if (x > xmax) xmax = x;
    if (y < ymin) ymin = y;
    if (y > ymax) ymax = y;
  }

  return createRect(xmin, ymin, xmax - xmin, ymax - ymin);
}

function isPointInsidePolySegment({ items, size }: PolySegment, x: number, y: number) {
  let oddNodes = false;
  let jx = items[(size - 1) << 1];
  let jy = items[((size - 1) << 1) + 1];
  const size2 = size << 1;

  for (let i = 0; i < size2; i += 2) {
    const ix = items[i];
    const iy = items[i + 1];

    if ((iy < y && jy >= y || jy < y && iy >= y) && (ix + (y - iy) / (jy - iy) * (jx - ix) < x))
      oddNodes = !oddNodes;

    jx = ix;
    jy = iy;
  }

  return oddNodes;
}

function polySegmentToArray({ items, size }: PolySegment) {
  const result: number[] = [];
  const size2 = size << 1;

  for (let i = 0; i < size2; i++) {
    result.push(items[i]);
  }

  return result;
}

function createPolySegmentFromArray(points: number[]) {
  const seg = createPolySegment(points.length / 2);

  for (let i = 0; i < points.length; i++) {
    seg.items[i] = points[i];
  }

  seg.size = points.length / 2;
  return seg;
}

export function createPoly(segments: PolySegment[] = []): Poly {
  return segments;
}

export function clonePoly(poly: Poly): Poly {
  return poly.map(clonePolySegment);
}

export function isPolyEmpty(poly: Poly) {
  return poly.length === 0 || poly.every(isPolySegmentEmpty);
}

export function isPolyfEmpty(poly: Polyf) {
  return poly.length === 0 || poly.every(isPolyfSegmentEmpty);
}

export function totalPolySize(poly: Poly) {
  let size = 0;

  for (const segment of poly) {
    size += segment.size;
  }

  return size;
}

export function clearPoly(poly: Poly) {
  poly.length = 0;
}

export function addPolyPoint(poly: Poly, x: number, y: number) {
  if (poly.length === 0) {
    poly.push(createPolySegment());
  }

  addPolySegmentPoint(poly[0], x, y);
}

export function addPolyfPoint(polyf: Polyf, x: number, y: number) {
  if (polyf.length === 0) {
    polyf.push([]);
  }

  polyf[0].push(x);
  polyf[0].push(y);
}

export function isPointInsidePoly(poly: Poly, x: number, y: number) {
  let intersections = 0;

  for (const s of poly) {
    if (isPointInsidePolySegment(s, x, y)) {
      intersections++;
    }
  }

  return (intersections % 2) === 1;
}

export function getPolyBounds(poly: Poly): Rect {
  const bounds = createRect(0, 0, 0, 0);

  for (const s of poly) {
    addRect(bounds, getPolySegmentBounds(s));
  }

  return bounds;
}

export function getPolyfBounds(polyf: Polyf): Rect {
  const bounds = createRect(0, 0, 0, 0);

  for (const s of polyf) {
    addRect(bounds, getPolyfSegmentBounds(s));
  }

  return bounds;
}

export function movePoly(poly: Poly, dx: number, dy: number) {
  if (dx === 0 && dy === 0) return;

  for (const { items, size } of poly) {
    const size2 = size << 1;

    for (let i = 0; i < size2; i += 2) {
      items[i] = clamp(items[i] + dx, -0x7fff, 0x7fff);
      items[i + 1] = clamp(items[i + 1] + dy, -0x7fff, 0x7fff);
    }
  }
}

export function transformPoly(poly: Poly, transform: Mat2d) {
  for (const { items, size } of poly) {
    const size2 = size << 1;

    for (let i = 0; i < size2; i += 2) {
      const x = items[i];
      const y = items[i + 1];
      items[i] = clamp(Math.round(transform[0] * x + transform[2] * y + transform[4]), -0x7fff, 0x7fff);
      items[i + 1] = clamp(Math.round(transform[1] * x + transform[3] * y + transform[5]), -0x7fff, 0x7fff);
    }
  }
}

export function polyToArray(poly: Poly) {
  return poly.map(polySegmentToArray);
}

export function createPolyFromArray(segments: number[][]) {
  return createPoly(segments.map(createPolySegmentFromArray));
}

export function createPolyRect(x: number, y: number, w: number, h: number) {
  if (w === 0 || h === 0) throw new Error('Cannot create empty rect poly');

  return createPolyFromArray([[
    x, y,
    x + w, y,
    x + w, y + h,
    x, y + h,
  ]]);
}
