import { AVATAR_MAX_FILE_SIZE, MAX_SHARE_SIZE } from '../common/constants';
import { logAction } from '../common/actionLog';
import { canvasToBlob } from '../common/canvasUtils';
import { post } from '../common/xhr';
import { apiPath } from '../common/data';

export async function uploadAvatar(file: File | Blob | undefined): Promise<string> {
  logAction(`uploadAvatar (type: ${file?.type}, size: ${file?.size}b)`);

  if (!file || !/^image\//.test(file.type)) throw new Error('Invalid file');
  if (file.size > AVATAR_MAX_FILE_SIZE) throw new Error(`File is too large (max ${Math.round(AVATAR_MAX_FILE_SIZE / 1024)}kb)`);

  const data = new FormData();
  data.append('file', file, 'name' in file ? file.name : 'blob.png');
  return await post<string>(`${apiPath}upload-avatar`, data);
}

export async function uploadShare(canvas: HTMLCanvasElement, id?: string) {
  const blob = await canvasToBlob(canvas);

  logAction(`uploadImage (canvas: ${canvas.width}x${canvas.height}, size: ${blob?.size}b)`);

  if (!blob) throw new Error('Failed to encode image');
  if (blob.size > MAX_SHARE_SIZE) throw new Error(`File is too large`);

  const data = new FormData();
  data.append('file', blob, 'blob.png');

  if (IS_PORTAL) {
    const response = await post<{ data?: { objectId?: string } }>(`/api/entities/${id}/share-as-image`, data);
    if (!response?.data?.objectId) throw new Error('Failed to share image');
    return `/shared/${response.data.objectId}`;
  } else {
    return post<string>(`${apiPath}upload-share`, data);
  }
}
