import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { signUps } from '../../../../common/data';
import { UpdateAccountData } from '../../../../common/interfaces';
import { Modals } from '../../../../services/modals';
import { Model } from '../../../../services/model';

@Component({
  selector: 'user-settings',
  templateUrl: 'user-settings.pug',
  styleUrls: ['user-settings.scss'],
})
export class UserSettings {
  readonly samlActive = signUps.includes('saml');
  data: UpdateAccountData;
  anonymous = true;
  showDetails = false;
  error: string | undefined = undefined;
  @Output() close = new EventEmitter<void>();
  constructor(private model: Model, private modals: Modals, private router: Router) {
    const user = this.model.user;

    this.anonymous = user.anonymous;
    this.data = {
      email: user.email,
      name: user.name,
      color: user.color,
      avatar: user.avatar,
      password: undefined,
      userJob: user.userJob,
      receiveEmails: user.receiveEmails,
    };
  }
  cancel() {
    this.close.emit();
  }
  submit() {
    this.error = undefined;
    this.model.updateAccount(this.data)
      .then(() => this.close.emit())
      .catch(e => this.error = e.message);
  }
  createAccount() {
    this.close.emit();
    this.modals.createAccount();
  }
  changeAccountDetails() {
    if (IS_PORTAL) {
      this.cancel();
      void this.router.navigate(['/my/account']);
    } else {
      this.showDetails = true;
    }
  }
}
