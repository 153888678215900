import { LayerFlag } from './../interfaces';
import { IToolEditor, IToolModel, IToolData, ITool, ToolId } from '../interfaces';
import { getLayerSafe } from '../drawing';
import { transformAndClipMask } from '../mask';
import { continueTransform, getLayerAfterRect } from '../toolUtils';
import { getLayerSurface, isLayerEmpty } from '../layerUtils';
import { logAction } from '../actionLog';
import { redraw, redrawLayer } from '../../services/editorUtils';
import { cloneRect, rectsIntersection } from '../rect';

export interface IDeleteSelectionToolData extends IToolData {
  layerId: number;
}

export class DeleteSelectionTool implements ITool {
  id = ToolId.DeleteSelection;
  name = '';
  constructor(public editor: IToolEditor, public model: IToolModel) {
  }
  do(data: IDeleteSelectionToolData) {
    this.deleteSelection(data.layerId, true);
  }
  deleteSelection(layerId: number, remote = false) {
    const user = this.model.user;
    logAction(`[${remote ? 'remote' : 'local'}] delete selection (clientId: ${user.localId}, layerId: ${layerId}})`);

    const layer = getLayerSafe(this.editor.drawing, layerId);
    const beforeRect = cloneRect(layer.rect);

    continueTransform(this.editor, this.model.user);

    if (getLayerSurface(layer)) { // delete selection from surface
      user.history.execTransaction(history => {
        history.pushLayerId('delete selection', layerId);
        history.pushTool('delete selection');
        history.pushSelection('delete selection');
        history.pushLayerState(layerId);
      });

      transformAndClipMask(user.selection, user.surface);
      this.editor.renderer.releaseUserCanvas(user);
    } else { // delete selection from layer
      user.history.execTransaction(history => {
        history.pushDirtyRect('delete selection', layerId, rectsIntersection(user.selection.bounds, layer.rect));
        history.pushLayerState(layerId);
      });
      this.editor.renderer.cutLayer(layer, user.selection);
    }

    if (isLayerEmpty(layer)) layer.flags = LayerFlag.None;

    redrawLayer(this.editor, layer); // TODO: use rect of surface + layer ?
    redraw(this.editor); // redraw transform cage
    this.model.doTool<IDeleteSelectionToolData>(layerId, {
      id: this.id, layerId, br: beforeRect, ar: getLayerAfterRect(layer, this.editor.drawing), bs: cloneRect(user.selection.bounds)
    });
  }
}
