// Google Analytics

function isGAInitialized() {
  return typeof (window as any).ga === 'function' && typeof (window as any).ga.getAll === 'function';
}

export function sendGAPageView(url: string) {
  try {
    if (!SERVER && isGAInitialized()) {
      (window as any).ga.getAll().forEach((tracker: any) => {
        tracker.ga('set', 'page', url);
        tracker.ga('send', 'pageview');
      });
    }
  } catch { }
}

export function sendGAEvent(eventCategory: string, eventAction: string, eventLabel: string | null = null) {
  try {
    if (!SERVER && isGAInitialized()) {
      (window as any).ga.getAll().forEach((tracker: any) => {
        tracker.send('event', { hitType: 'event', eventCategory, eventAction, eventLabel });
      });
    }
  } catch { }
}
