import { Directive, OnInit, ElementRef, OnDestroy, Input } from '@angular/core';
import { SequenceDrawing } from '../../../common/interfaces';
import { redrawSequenceThumbCanvas } from '../../../common/update';

@Directive({ selector: '[drawingThumb]' })
export class DrawingThumb implements OnInit, OnDestroy {
  private _drawing: SequenceDrawing | undefined;
  constructor(private canvas: ElementRef<HTMLCanvasElement>) {
  }
  @Input('drawingThumb') set drawing(value: SequenceDrawing | undefined) {
    this.ngOnDestroy();
    this._drawing = value;
    this.ngOnInit();
  }
  ngOnInit() {
    if (this._drawing) {
      this._drawing.thumbCanvas = this.canvas.nativeElement;
      redrawSequenceThumbCanvas(this._drawing);

      // if we don't have any thumb data try to update it
      if (!this._drawing.thumbImage && !this._drawing.thumbData) {
        this._drawing.thumbUpdated = 0;
      }
    }
  }
  ngOnDestroy() {
    if (this._drawing && this._drawing.thumbCanvas === this.canvas.nativeElement) {
      this._drawing.thumbCanvas = undefined;
    }
  }
}
