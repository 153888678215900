import { StoreConfig, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { AppState } from './app.query';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'app', resettable: true })
export class AppStore extends Store<AppState> {
  constructor() {
    super({});
  }
}
