import { IFiltersValues } from './interfaces';

export function applyBrightnessContrast(source: Uint8ClampedArray, destination: Uint8ClampedArray, values: IFiltersValues) {
  const { brightness = 0, contrast = 0 } = values;
  const average = 127.0;
  const percentage = contrast / 100.0;

  for (let index = 0; index < source.length; index += 4) {
    const finalColor = [0, 0, 0];
    if (percentage > 0) {
      finalColor[0] = average + (source[index] - average) * (1.0 / (1.0 - percentage));
      finalColor[1] = average + (source[index + 1] - average) * (1.0 / (1.0 - percentage));
      finalColor[2] = average + (source[index + 2] - average) * (1.0 / (1.0 - percentage));
    } else {
      finalColor[0] = average + (source[index] - average) * (1.0 + percentage);
      finalColor[1] = average + (source[index + 1] - average) * (1.0 + percentage);
      finalColor[2] = average + (source[index + 2] - average) * (1.0 + percentage);
    }

    // applying brightness
    finalColor[0] += brightness;
    finalColor[1] += brightness;
    finalColor[2] += brightness;

    // populating the destination
    destination[index] = finalColor[0];
    destination[index + 1] = finalColor[1];
    destination[index + 2] = finalColor[2];
    destination[index + 3] = source[index + 3];
  }
}
