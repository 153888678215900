import { blobToArrayBuffer } from './canvasUtils';
import { getMaxImageSize, isImageSizeValid, getMaxLayers, getMaxImportSize } from './constants';
import { getImageInfo, ImageType, isImageTypeSupportedForImport } from './imageUtils';
import { Drawing, Layer, LayerData, CommonDrawingData, User } from './interfaces';
import { hasPermission } from './userRole';
import { fullName } from './userUtils';
import { formatBytes } from './utils';

export function isValidDrawingId(id: string): boolean {
  return !!id && typeof id === 'string' && /^[0-9a-zA-Z_-]{1,40}$/.test(id);
}

export function isValidLayerImage(id: string): boolean {
  return !!id && typeof id === 'string' && /^[!@$a-zA-Z0-9_.-]+$/.test(id);
}

export function isEmptyDrawing(drawing: CommonDrawingData | Drawing) {
  return !drawing.layers.some((l: Layer | LayerData) => !!l.image);
}

export function canOwnLayer(drawing: Drawing, user: User, layer: Layer) {
  return !canOwnLayerMessage(drawing, user, layer);
}

const LAYER_ALREADY_OWNED = `This layer already has an owner. Ask the owner to leave it or create new layer.`;

export function canOwnLayerMessage(drawing: Drawing, user: User, layer: Layer): string | undefined {
  if (!hasPermission(drawing, user, 'ownLayer')) {
    return `You don't have permission to own layers. You need permission from an admin first.`;
  }

  const canTakeOver = hasPermission(drawing, user, 'takeOver');

  if (canTakeOver) {
    return undefined;
  } else if (layer.owner) {
    return LAYER_ALREADY_OWNED;
  } else if (drawing.respectOfflineOwners && layer.layerOwner && !layer.layerOwner.left && layer.layerOwner?.name !== fullName(user)) {
    return LAYER_ALREADY_OWNED;
  } else {
    return undefined;
  }
}

export async function verifyFileForImport(file: File | Blob, isPro: boolean, isSuperadmin: boolean) {
  const maxSize = getMaxImportSize(isPro || isSuperadmin);

  if (file.size > maxSize) {
    if (DEVELOPMENT) {
      console.warn(`File is too big (max ${formatBytes(maxSize)})`);
    } else {
      throw new Error(`File is too big (max ${formatBytes(maxSize)})`);
    }
  }

  const data = await blobToArrayBuffer(file);
  if (!data) throw new Error('Invalid data');

  const buffer = new Uint8Array(data);
  const { type, width, height, layers } = getImageInfo(buffer);
  const canHaveLargeCanvas = isPro || isSuperadmin;
  const maxLayers = getMaxLayers(isPro, isSuperadmin);

  if (type === ImageType.Unknown)
    throw new Error('Invalid image file');

  if (!isImageTypeSupportedForImport(type, isSuperadmin))
    throw new Error('Image type is not supported');

  if (!isImageSizeValid(width, height, canHaveLargeCanvas)) {
    throw new Error(`Image size is too large (max ${getMaxImageSize(canHaveLargeCanvas)})`);
  }

  if (layers > maxLayers) {
    throw new Error(`Image has too many layers (max ${maxLayers})`);
  }
  return buffer;
}
