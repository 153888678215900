import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, } from 'rxjs';
import { CreatePostData, Post, UpdatePostData } from '../../../shared/posts';
import { ProjectData } from '../../../shared/interfaces';
import { ToastService } from '../../../../magma/src/ts/services/toast.service';
import { Mandatory } from '../../../../magma/src/ts/common/typescript-utils';
import { blobToBase64, isImageFile } from 'util/util';
import { TeamMembersService } from './team-members.service';
import { UserService } from './user.service';
import { ProjectService } from './projects.service';
import { Permission } from 'magma/common/interfaces';
import { TeamsQuery } from './team.query';
import { TeamsStore } from './team.store';

@Injectable({ providedIn: 'root' })
export class BlogService {
  readonly communityHubProjects: ProjectData[] = [];

  private projectId: string | undefined = undefined; // Rely on project._id instead. This is used only when user loaded into community-hub project but has no list yet, we want to re-setupBlog with it once loaded
  project: ProjectData | undefined = undefined;
  posts: Post[] = [];
  canManage = false;

  loadingPosts = false;
  awaitingPostUpdates = new Set<Post['_id']>([]);
  disableCreating = true;

  setupBlog(projectId: string | null) {
    this.restoreInitialState();
    if (projectId !== null) {
      this.setupProject(projectId);
      if (this.project) {
        this.loadPosts(this.project._id);
      }
    }
  }

  private restoreInitialState() {
    this.loadingPosts = true;
    this.disableCreating = true;
    this.project = undefined;
    this.canManage = false;
    this.posts = [];
    this.awaitingPostUpdates.clear();
  }

  private setupProject(projectId: string) {
    this.projectId = projectId;
    if (this.teamsQuery.getActive()) {
      this.project = this.projectsService.getProject(projectId);
      this.canManage = this.teamMemberService.isPermissionFlagSet([Permission.CanManageBlog]);
    } else {
      this.project = this.isCommunityHubProject(projectId);
      if (this.project) {
        this.teamsStore.setActive(null);
        this.canManage = !!this.userService.user?.isSuperAdmin;
      }
    }
  }

  private loadPosts(projectId: string) {
    this.getPosts(projectId)
      .then((posts) => {
        this.loadingPosts = false;
        this.disableCreating = false;
        this.posts = posts;
      })
      .catch((e) => { this.toastWithErrorMessage(e, 'load', 'posts'); });
  }

  private isCommunityHubProject(id: string) {
    return this.communityHubProjects.find(({ _id }) => _id === id);
  }

  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private projectsService: ProjectService,
    private teamMemberService: TeamMembersService,
    private toastsService: ToastService,
    private teamsQuery: TeamsQuery,
    private teamsStore: TeamsStore,
  ) {
    this.getCommunityHubProjects().catch((e) => { DEVELOPMENT && console.log(e); });
  }

  getPosts(projectId: string) {
    this.loadingPosts = true;
    const url = `api/blog/${projectId}/posts`;
    return lastValueFrom(this.httpClient.get<{ data: Post[] }>(url))
      .then(({ data }) => data);
  }

  getCommunityHubProjects() {
    const url = `api/projects/community-hub`;
    return lastValueFrom(this.httpClient.get<{ data: ProjectData[] }>(url))
      .then(({ data }) => {
        for (const project of data) {
          this.communityHubProjects.push(project);
        }
        if (this.projectId) this.setupBlog(this.projectId);
        this.projectId = undefined;
        return data;
      });
  }

  async createPost(projectId: string, body: Omit<CreatePostData, 'project'>) {
    const url = `api/blog/${projectId}/posts`;
    this.disableCreating = true;
    try {
      const response = await lastValueFrom(this.httpClient.post<{ data: Post }>(url, body));
      this.posts.unshift(response.data);
      this.disableCreating = false;
    } catch (e) {
      this.toastWithErrorMessage(e, 'create', 'post');
    } finally {
      this.disableCreating = false;
    }
  }

  async updatePost(projectId: string, body: Mandatory<UpdatePostData, '_id'>) {
    const url = `api/blog/${projectId}/posts/${body._id}`;
    this.awaitingPostUpdates.add(body._id);
    try {
      const response = await lastValueFrom(this.httpClient.patch<{ data: Post }>(url, body));
      this.awaitingPostUpdates.delete(body._id);
      const postIndex = this.posts.findIndex((p) => p._id === body._id);
      if (postIndex !== -1) this.posts.splice(postIndex, 1, response.data);
    } catch (e) {
      this.toastWithErrorMessage(e, 'edit', 'post');
    } finally {
      this.awaitingPostUpdates.delete(body._id);
    }
  }

  async removePost(projectId: string, postId: string) {
    const url = `api/blog/${projectId}/posts/${postId}`;
    this.awaitingPostUpdates.add(postId);
    try {
      await lastValueFrom(this.httpClient.delete(url, { }));
      this.awaitingPostUpdates.delete(postId);
      const postIndex = this.posts.findIndex((p) => p._id === postId);
      if (postIndex !== -1) this.posts.splice(postIndex, 1);
    } catch (e) {
      this.toastWithErrorMessage(e, 'delete', 'post');
    } finally {
      this.awaitingPostUpdates.delete(postId);
    }
  }

  moveProjectToLeft(projectId: string, post: Post){
    const posts = this.posts;
    const index = posts.indexOf(post);
    if (index !== -1 && index !== 0) {
      this.awaitingPostUpdates.add(posts[index]._id);
      this.awaitingPostUpdates.add(posts[index - 1]._id);
      [posts[index - 1], posts[index]] = [posts[index], posts[index - 1]];
      this.reorderPosts(projectId, posts)
        .catch((e) => {
          [posts[index], posts[index - 1]] = [posts[index - 1], posts[index]];
          this.toastWithErrorMessage(e, 'reorder', 'posts');
        })
        .finally(() => {
          this.awaitingPostUpdates.delete(posts[index]._id);
          this.awaitingPostUpdates.delete(posts[index - 1]._id);
        });
    }
  }

  moveProjectToRight(projectId: string, post: Post){
    const posts = this.posts;
    const index = posts.indexOf(post);
    if (index !== -1 && index !== posts.length - 1) {
      this.awaitingPostUpdates.add(posts[index]._id);
      this.awaitingPostUpdates.add(posts[index + 1]._id);
      [posts[index], posts[index + 1]] = [posts[index + 1], posts[index]];
      this.reorderPosts(projectId, posts)
        .catch((e) => {
          [posts[index], posts[index + 1]] = [posts[index + 1], posts[index]];
          this.toastWithErrorMessage(e, 'reorder', 'posts');
        })
        .finally(() => {
          this.awaitingPostUpdates.delete(posts[index]._id);
          this.awaitingPostUpdates.delete(posts[index + 1]._id);
        });
    }
  }

  async reorderPosts(projectId: string, posts: Post[]){
    const url = `api/blog/${projectId}/reorder-posts`;
    const body = { posts: posts.map((p) => p._id) };
    return lastValueFrom(this.httpClient.post(url, body));
  }

  async validateThumbnail(file: File | undefined) {
    if (!file) return false;

    if (!(file.type?.includes('image/'))) return false;
    if (!(await isImageFile(file))) return false;

    const base64 = await blobToBase64(file);
    if (typeof base64 !== 'string') return false;

    return true;
  }

  async uploadThumbnail(projectId: string, file: File) {
    const url = `/api/blog/${projectId}/thumbnails`;
    const formData = new FormData();
    formData.set('file', file, 'uploaded-blog-thumbnail');
    return lastValueFrom(this.httpClient.post<{ thumbId: string }>(url, formData))
      .then(({ thumbId }) => window.location.origin + `${url}/${thumbId}`)
      .catch((e) => {
        this.toastWithErrorMessage(e, 'upload', 'thumbnail');
        throw e;
      });
  }

  private toastWithErrorMessage(e: any, action: string, entity: 'post' | 'posts' | 'thumbnail') {
    let message = `Failed to ${action} ${entity}`;
    switch (e.status) {
      case 400: message += ' - invalid data.'; break;
      case 403: message += ' - insufficient permissions.'; break;
    }
    DEVELOPMENT && console.error(e);
    this.toastsService.error({ message });
  }
}
