import { Injectable } from '@angular/core';
import { QueryEntity, EntityState, ActiveState } from '@datorama/akita';
import { ProjectStore } from './projects.store';
import { ProjectData } from 'shared/interfaces';

export interface ProjectState extends EntityState<ProjectData>, ActiveState<string> {
}

@Injectable({ providedIn: 'root' })
export class ProjectQuery extends QueryEntity<ProjectState, ProjectData, string> {

  constructor(protected store: ProjectStore) {
    super(store);
  }

}
