import { Injectable } from '@angular/core';
import { ActiveState, EntityState, QueryEntity } from '@datorama/akita';
import { filter, map } from 'rxjs/operators';
import { TeamData } from 'shared/interfaces';
import { TeamsStore } from './team.store';

export interface TeamsState extends EntityState<TeamData, string>, ActiveState<string> { }

@Injectable({ providedIn: 'root' })
export class TeamsQuery extends QueryEntity<TeamsState, TeamData, string> {
  availableProjects$ = this.selectAll().pipe(
    filter(teams => teams && teams.length > 0),
    map(teams => teams.map(({ name, projects }) => ({ name, projects }))));

  constructor(protected store: TeamsStore) {
    super(store);
  }
}
