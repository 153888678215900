import { Directive, ElementRef, Input, NgZone, OnDestroy } from '@angular/core';
// import { formatDistance } from 'date-fns';
import moment from 'moment';

@Directive({ selector: '[timeAgo]' })
export class TimeAgoDirective implements OnDestroy {
  @Input() get timeAgo() {
    return this._timeAgo;
  }
  set timeAgo(value) {
    if (this._timeAgo !== value) {
      this._timeAgo = value;
      this.update();
    }
  }
  private _timeAgo: number | Date | string | undefined = undefined;
  private timeout: any = 0;
  constructor(private element: ElementRef<HTMLElement>, private zone: NgZone) {
  }
  ngOnDestroy() {
    clearTimeout(this.timeout);
  }
  update() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = 0;
    }

    if (this._timeAgo) {
      const date = new Date(this._timeAgo);
      const now = Date.now();
      const untilUpdate = timeUntilUpdate(date.getTime() - now);
      // this.element.nativeElement.textContent = formatDistance(date, now, { addSuffix: true });
      this.element.nativeElement.textContent = moment(date).fromNow();
      this.timeout = this.zone.runOutsideAngular(() => setTimeout(() => this.update(), untilUpdate));
    } else {
      this.element.nativeElement.textContent = '';
    }
  }
}

function timeUntilUpdate(diff: number) {
  diff = Math.abs(diff) / 60000; // convert to minutes

  if (diff < 1) {
    return 1000; // 1s
  } else if (diff < 60) {
    return 30000; // 30s
  } else if (diff < 180) {
    return 300000; // 5min
  } else {
    return 3600000; // 1hour
  }
}
