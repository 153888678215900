import { getContext2d } from '../common/canvasUtils';
import { Drawing, Point, Rect, TextureFormat, TriangleBatch, User, Viewport, WebGLResources } from '../common/interfaces';
import { isLayerVisible } from '../common/layer';
import { createMat4 } from '../common/mat4';
import { createPoint, setPoint } from '../common/point';
import { isRectEmpty } from '../common/rect';
import { getSurfaceBounds, isSurfaceEmpty } from '../common/toolSurface';
import { getPixelRatio } from '../common/utils';
import { clamp } from '../common/mathUtils';
import { invalidEnum } from '../common/baseUtils';
import { createViewportMatrix4, documentToScreenPoint } from '../common/viewport';
import { bindTexture, createEmptyTexture, deleteTexture, unbindTexture } from './webgl';
import { flushBatch, pushAntialiasedQuad, pushQuad } from './webglBatch';
import { copyCanvasToTexture, getTempCanvas, identityViewMatrix } from './webglRenderer';
import { getShader } from './webglRenderingContext';
import { colorToRGBA } from '../common/color';

const tempPt = createPoint(0, 0);
const tempMat4 = createMat4();

export function drawDebugLayerBounds(webgl: WebGLResources, drawing: Drawing, view: Viewport, user: User) {
  const { gl, batch } = webgl;
  const ratio = getPixelRatio();

  const shader = getShader(webgl, 'line');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight));

  for (const layer of drawing.layers) {
    if (!isLayerVisible(layer)) continue;

    if (layer.texture) {
      pushAntialiasedQuadInView(batch, layer.textureX, layer.textureY, layer.texture.width, layer.texture.height, ratio, view, 0, 0, 1);
    }

    if (!isRectEmpty(layer.rect)) {
      pushAntialiasedQuadInViewRect(batch, layer.rect, ratio, view, 1, 0, 0);
    }
  }

  if (!isSurfaceEmpty(user.surface)) {
    pushAntialiasedQuadInViewRect(batch, getSurfaceBounds(user.surface), ratio, view, 0, 1, 0);
  }

  flushBatch(batch);
}

export function formatToString(format: TextureFormat) {
  switch (format) {
    case TextureFormat.RGBA: return 'RGBA';
    case TextureFormat.Alpha: return 'ALPHA';
    default: invalidEnum(format);
  }
}

export function drawDebugAllocatedTextures(webgl: WebGLResources, view: Viewport) {
  const { gl, batch, drawingTexture } = webgl;
  const ratio = getPixelRatio();

  if (view.rotation) return;

  let shader = getShader(webgl, 'basic');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, createViewportMatrix4(tempMat4, view, false));

  const coords: Point[] = [];
  const maxY = drawingTexture.height * 6;
  let maxX = 0;
  let x = drawingTexture.width + 100, y = 0;
  let textY = 0, TH = 100, i = 0;

  for (const texture of webgl.allocatedTextures) {
    if (texture === drawingTexture) continue;
    coords.push({ x, y });
    maxX = Math.max(maxX, x + texture.width);
    y += texture.height + 200;

    if (y > maxY) {
      y = 0;
      x = maxX + 100;
    }
  }

  const tempCanvas = getTempCanvas(webgl, webgl.textureWidth, webgl.textureHeight, false);
  const tempContext = getContext2d(tempCanvas);
  tempContext.clearRect(0, 0, tempCanvas.width, tempCanvas.height);
  tempContext.save();
  tempContext.fillStyle = 'lime';
  tempContext.font = `bold ${clamp(Math.round(webgl.width * 0.05), 20, 80)}px Arial`;
  tempContext.textBaseline = 'top';

  // draw textures
  for (const texture of webgl.allocatedTextures) {
    if (texture === drawingTexture) continue;
    bindTexture(gl, 0, texture);
    pushQuad(batch, coords[i].x, coords[i].y, texture.width, texture.height, 0, 0, 1, 1, 0, 0, 1, 1, 1, 1);
    flushBatch(batch);
    tempContext.fillText(`${texture.id} [${formatToString(texture.format)}] ${texture.width}x${texture.height}`, 0, textY);
    textY += TH;
    i++;
  }

  tempContext.restore();
  const tempTexture = createEmptyTexture(gl, tempCanvas.width, tempCanvas.height);
  copyCanvasToTexture(gl, tempContext, tempTexture, tempCanvas.width, tempCanvas.height);
  tempContext.clearRect(0, 0, tempCanvas.width, tempCanvas.height);
  bindTexture(gl, 0, tempTexture);

  i = 0;
  let ty = 0, th = TH / tempTexture.height;

  // draw labels
  for (const texture of webgl.allocatedTextures) {
    if (texture === drawingTexture || !coords[i]) continue;
    pushQuad(batch, coords[i].x, coords[i].y - TH - 10, tempTexture.width, TH, 0, ty, 1, th, 0, 0, 1, 1, 1, 1);
    ty += th;
    i++;
  }

  flushBatch(batch);
  unbindTexture(gl, 0);
  deleteTexture(gl, tempTexture);

  // draw outlines
  i = 0;
  shader = getShader(webgl, 'line');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight));

  for (const texture of webgl.allocatedTextures) {
    if (texture === drawingTexture) continue;
    pushAntialiasedQuadInView(batch, coords[i].x, coords[i].y, texture.width, texture.height, ratio, view, 0, 0, 1);
    i++;
  }

  // pool
  y = 0;
  for (const texture of webgl.textures) {
    pushAntialiasedQuadInView(batch, -100 - texture.width, y, texture.width, texture.height, ratio, view, 0, 0, 1);
    y += texture.height + 100;
  }

  flushBatch(batch);
}

export function pushAntialiasedQuadInViewRect(
  batch: TriangleBatch, rect: Rect, ratio: number, view: Viewport, r: number, g: number, b: number
) {
  pushAntialiasedQuadInView(batch, rect.x, rect.y, rect.w, rect.h, ratio, view, r, g, b);
}

export function pushAntialiasedQuadInView(
  batch: TriangleBatch, x: number, y: number, w: number, h: number, ratio: number, view: Viewport, r: number, g: number, b: number
) {
  setPoint(tempPt, x, y);
  documentToScreenPoint(tempPt, view);
  const x1 = tempPt.x * ratio;
  const y1 = tempPt.y * ratio;
  setPoint(tempPt, x + w, y);
  documentToScreenPoint(tempPt, view);
  const x2 = tempPt.x * ratio;
  const y2 = tempPt.y * ratio;
  setPoint(tempPt, x + w, y + h);
  documentToScreenPoint(tempPt, view);
  const x3 = tempPt.x * ratio;
  const y3 = tempPt.y * ratio;
  setPoint(tempPt, x, y + h);
  documentToScreenPoint(tempPt, view);
  const x4 = tempPt.x * ratio;
  const y4 = tempPt.y * ratio;
  pushAntialiasedQuad(batch, x1, y1, x2, y2, x3, y3, x4, y4, 1, r, g, b, 1);
}

export function drawDebugMarkers(webgl: WebGLResources, view: Viewport) {
  if (!webgl.markers) return;

  const { gl, batch } = webgl;

  const shader = getShader(webgl, 'vertexColor');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, createViewportMatrix4(tempMat4, view, false));

  const s = 1 / view.scale;

  for (const { x, y, color } of webgl.markers) {
    const c = colorToRGBA(color);
    pushQuad(batch, x - s, y - s, s * 2, s * 2, 0, 0, 0, 0, 0, 0, c.r / 255, c.g / 255, c.b / 255, c.a / 255);
  }

  flushBatch(batch);
}
