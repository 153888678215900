declare const APP_NAME: string; // filled by webpack

const TITLES = [
  { path: '/login', text: `Login` },
  { path: '/create-account', text: `Create account` },
  { path: '/emailConfirmation', text: `Email confirmation` },
  { path: '/search', text: `Search` },
  { path: `/my/${PRODUCT_INFO.home?.route}`, text: `${PRODUCT_INFO.home?.name || ''}` },
  { path: '/my/participated', text: `Participated in` },
  { path: '/my/buddies', text: `Drawing buddies` },
  { path: '/my/live', text: `Live now` },
  { path: '/my/account/profile', text: `Profile` },
  { path: '/my/account/password', text: `Account & Password` },
  { path: '/my/account/billing', text: `Billing` },
  { path: '/my/account/api', text: `API` },
  { path: '/my/account/notifications', text: `Notifications` },
  { path: '/my/account/privacy', text: `Privacy` },
  { path: '/my/account', text: `Account` },
  { path: '/my/feedback', text: `Feedback` },
  { path: '/my/bin', text: `Bin` },
  { path: '/my/whats-new', text: `What's New?` },
  { path: '/apidocs', text: `API documentation` },
  { path: '/my/ai/models', text: `AI Models` },
  { path: '/community-hub', text: `Community hub` },
];

const REGEX_TITLES = [
  { path: /^\/s\/[a-z0-9_-]+\/live/, text: `Live now` },
  { path: /^\/s\/[a-z0-9_-]+\/recent/, text: `Recent` },
];

export function getPageTitle(url: string) {
  for (const title of TITLES) {
    if (url.startsWith(title.path)) {
      return title.text;
    }
  }

  for (const title of REGEX_TITLES) {
    if (title.path.test(url)) {
      return title.text;
    }
  }

  return undefined;
}

export function getTitle(url: string) {
  return pageTitleToFullTitle(getPageTitle(url));
}

export function pageTitleToFullTitle(pageTitle: string | undefined) {
  return pageTitle ? `${pageTitle} | ${APP_NAME}` : APP_NAME;
}
