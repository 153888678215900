import { Directive, ElementRef, NgZone, Input } from '@angular/core';

function stopEvent(e: Event) {
  e.preventDefault();
  e.stopPropagation();
}

@Directive({ selector: '[stopEvents]' })
export class StopEvents {
  private existing: string[] = [];
  constructor(private element: ElementRef, private zone: NgZone) {
  }
  @Input()
  set stopEvents(value: string | undefined) {
    this.zone.runOutsideAngular(() => {
      const events = (value || '').split(/ /g).filter(x => !!x);
      const e = this.element.nativeElement as HTMLElement;
      this.existing.forEach(name => e.removeEventListener(name, stopEvent));
      events.forEach(name => e.addEventListener(name, stopEvent));
      this.existing = events;
    });
  }
}
