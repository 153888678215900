import { calculateAdvancement, charactersToWords, TextCharacter } from './text-character';
import { findSpaceIndexInLine, JustifiedLine, Line, shouldBreakline, shouldStretch } from './lines';
import { DEFAULT_PARAGRAPH_FORMATTING, ParagraphFormatting, TextAlignment } from './formatting';
import { Rect } from '../interfaces';

export type ParagraphWithMeasuredLines = Paragraph & { lines: Line[] };
export function hasLinesMeasured (paragraph: Paragraph): paragraph is ParagraphWithMeasuredLines {
  return paragraph.lines !== undefined;
}
export function assumeParagraphHaveMeasuredLines (paragraph: Paragraph | undefined, customMessage = ''): asserts paragraph is ParagraphWithMeasuredLines {
  if (!paragraph) throw new Error('Expected paragraph but received undefined!');
  if (!hasLinesMeasured(paragraph)) {
    const error = 'Paragraph has not measured lines yet!' + customMessage ? ` ${customMessage}` : '';
    throw new Error(error);
  }
}
export function assumeParagraphsHaveMeasuredLines (paragraphs: Paragraph[]): asserts paragraphs is ParagraphWithMeasuredLines[] {
  if (paragraphs.some(p => !hasLinesMeasured(p))) throw new Error('Paragraphs are assumed to be measured but some of them were not!');
}

export class Paragraph {
  formatting: Required<ParagraphFormatting>;
  characters: TextCharacter[];
  lines: Line[] | undefined = undefined;
  insideVisibleBox = true;
  bbox: Rect | undefined = undefined;  // for empty paragraphs to know where to position cursor

  constructor(characters: TextCharacter[], formatting?: Partial<ParagraphFormatting>) {
    this.characters = characters;
    this.formatting = {
      ...DEFAULT_PARAGRAPH_FORMATTING,
      ...formatting
    };
  }

  loadFormatting(formatting: Partial<ParagraphFormatting>) {
    this.formatting = {
      ...this.formatting,
      ...formatting
    };
  }

  get isJustified(): boolean {
    const a = this.formatting.alignment;
    return a === TextAlignment.LeftJustified || a === TextAlignment.CenterJustified || a === TextAlignment.RightJustified || a === TextAlignment.FullyJustified;
  }

  get lastLine() {
    if (this.lines === undefined) {
      throw new Error('Lines are not measured!');
    } else if (this.lines.length === 0) {
      return undefined;
    } else {
      return this.lines[this.lines.length - 1];
    }
  }

  get lastCharacter() {
    if (this.characters.length === 0) return undefined;
    return this.characters[this.characters.length - 1];
  }

  get words(): TextCharacter[][] {
    return charactersToWords(this.characters);
  }

  get isEmpty(){
    return this.characters.length === 1 && this.characters[0].isEOParagraph;
  }

  get hasVisibleCharacters(){
    return this.characters.some(c => c.partiallyInsideVisibleBox);
  }

  get hasInvisibleCharacters() {
    return this.characters.some(c => !c.partiallyInsideVisibleBox);
  }

  get firstLineHasVisibleCharacters() {
    if (!this.lines || this.lines.length === 0) return false;
    const lastCharacterOfFirstLineIndex = this.lines[0].breaklineAt;
    return this.characters.some(c => c.index <= lastCharacterOfFirstLineIndex && c.renderable);
  }

  getLineIndexFromCharacter(character: TextCharacter) {
    assumeParagraphHaveMeasuredLines(this, `It's impossible to get line with character "${character.glyphKey}".`);
    const lineIndex: number = this.lines.findIndex(l => l.breaklineAt >= character.index);
    if (lineIndex === -1) throw new Error(`This character ${character.indexGlyphKeyString} is not part of this paragraph!`);
    return lineIndex;
  }

  getLineDataFromCharacter(character: TextCharacter) {
    const lineIndex = this.getLineIndexFromCharacter(character);
    return this.getLineDataFromIndex(lineIndex);
  }

  getLineDataFromIndex(index: number) {
    assumeParagraphHaveMeasuredLines(this, `It's impossible to get line with index ${index} data.`);
    if (index < 0) DEVELOPMENT && console.warn(`Trying to find line data with index ${index}`);
    return this.lines[index];
  }

  calculateAdvancement(character: TextCharacter, previousCharacter: TextCharacter | undefined): number {
    if (!this.isJustified || this.isEmpty) {  // aligned or empty paragraphs
      return calculateAdvancement(character, previousCharacter);
    } else {
      const line = this.getLineDataFromCharacter(character) as JustifiedLine;
      if (this.lines && this.formatting.alignment !== TextAlignment.FullyJustified && line === this.lastLine) { // last line in not-fully justified paragraph (works like aligned)
        return calculateAdvancement(character, previousCharacter);
      } else if (!shouldStretch(line)) { // justified paragraphs
        if (!character.isWhitespace) {
          return calculateAdvancement(character, previousCharacter);
        } else {
          if (character.isEOParagraph) {
            return character.glyphWidth;
          } else if (shouldBreakline(character, line)) {
            // last space in justified paragraphs is "outside box", justified space is zeroed there to fit line within boundaries.
            // Bbox however should exceed so we will mark it with regular space width
            return line.regularSpaces[findSpaceIndexInLine(this.characters, line, character)];
          } else {
            return line.justifiedSpaces[findSpaceIndexInLine(this.characters, line, character)];
          }
        }
      } else { // stretched justified lines
        return calculateAdvancement(character, previousCharacter) + (line.justifiedSpaces[character.index - line.startAt] || 0);
      }
    }
  }
}
