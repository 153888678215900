import { Component, Input } from '@angular/core';
import { isMac } from '../../../common/userAgentUtils';
import { Model } from '../../../services/model';
import { getShortcuts } from '../../../common/settingsUtils';

@Component({
  selector: 'kbd-shrtcts',
  templateUrl: 'kbd-shortcuts.pug',
  styleUrls: ['kbd-shortcuts.scss'],
})
export class KbdShortcuts {
  shortcuts: string[] = [];
  constructor(private model: Model) {
  }
  @Input()
  set id(value: string) {
    this.shortcuts = (getShortcuts(this.model, value) || [])
      .filter(x => isMac || x.indexOf('cmd+') === -1);
  }
}
