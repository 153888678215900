import { Injectable, TemplateRef } from '@angular/core';
import { Drawing, SelectedFolder, PresentationModeState, ShareType, TextLayer, User, PresentationActionData } from '../common/interfaces';
import { delay } from '../common/promiseUtils';
import { getValueOfVariable } from '../common/utils';
import { ChoiceModalOption } from '../components/shared/modals/choice-modal/choice-modal';
import { DisabledOnMobileModalData } from '../components/shared/modals/disabled-on-mobile-modal/disabled-on-mobile-modal';
import { Model } from './model';

export interface CreateDrawingProps {
  name?: string;
  addToSequence?: boolean;
  sequenceIndex?: number;
  existing?: boolean;
  openInNewWindow?: boolean;
  drawing?: Drawing;
  create?: boolean;
  team?: string;
  project?: string;
  folder?: string;
}

export interface OpenModalOptions {
  class?: 'modal-sm' | 'modal-lg' | 'modal-xl';
  ignoreKeys?: boolean;
  data?: any;
}

export interface IModalsBox {
  openModal<T = void>(template: TemplateRef<any>, options?: OpenModalOptions): Promise<T | undefined>;
  closeModal(template: TemplateRef<any>): void;
  isAnyModalOpen(): boolean;
  isOpen(template: any): boolean;
  closeAll(): void;
}

export function findTemplate(name: string, templates: { [key: string]: TemplateRef<any>; }[]) {
  for (const obj of templates) {
    if (name in obj && obj[name] && 'elementRef' in obj[name]) {
      return obj[name];
    }
  }

  return undefined;
}

@Injectable({ providedIn: 'any' })
export class Modals {
  static box: IModalsBox | undefined = undefined;
  static templates: { [key: string]: any; }[] = []; // array of objects holding named modal templates

  // openByClass<T>() {

  // }

  openByTemplate<T>(template: TemplateRef<any>, options?: OpenModalOptions) {
    return Modals.box!.openModal<T>(template, options);
  }
  openByName<T>(name: string, options?: OpenModalOptions) {
    const template = findTemplate(name, Modals.templates);
    if (!template) throw new Error(`Modal template not found "${name}"`);
    return this.openByTemplate<T>(template, options);
  }
  isOpen(name: string) {
    if (!Modals.box) return false;
    const template = findTemplate(name, Modals.templates);
    return Modals.box.isOpen(template);
  }
  closeByName(name: string) {
    if (!Modals.box) return;
    const template = findTemplate(name, Modals.templates);
    if (template) Modals.box.closeModal(template);
  }
  closeAll() {
    if (!Modals.box) return;
    Modals.box.closeAll();
  }
  async waitForTemplate(name: string) {
    while (!findTemplate(name, Modals.templates)) {
      await delay(100);
    }
  }
  startPresentation(data: { currentCanvasUsers: User[], isSuperAdmin?: boolean, drawing?: Drawing }) {
    return this.openByName<Pick<PresentationModeState, 'participantsUiHidden'>>('startPresentation', { data });
  }
  presentationActionModal(data: PresentationActionData) {
    return this.openByName<boolean>('presentationActionModal', { data });
  }
  managePresentationViewers(data: { model: Model }) {
    return this.openByName<boolean>('managePresentationViewersModal', { data });
  }
  invokeRasterizeFlow(layer: TextLayer) {
    return this.openByName<boolean>('rasterizeFlow', { data: layer });
  }
  disabledOnMobile(data: DisabledOnMobileModalData) {
    return this.openByName<DisabledOnMobileModalData>('disabledOnMobile', { data });
  }
  createAccount() {
    void this.openByName('createAccount');
  }
  createDrawing(options: CreateDrawingProps = {}) {
    const data: CreateDrawingProps = { ...options, create: true };
    void this.openByName('drawingSettings', { data });
  }
  drawingSettings(drawing: Drawing) {
    const data: CreateDrawingProps = { drawing, team: drawing.team, project: drawing.project, folder: drawing.folder };
    void this.openByName('drawingSettings', { data });
  }
  closeDrawingSettings() {
    this.closeByName('drawingSettings');
  }
  userSettings() {
    void this.openByName('userSettings');
  }
  settings(startTab = 0) {
    void this.openByName('settings', { data: { startTab }, class: 'modal-lg', ignoreKeys: true });
  }
  signIn() {
    void this.openByName('signIn');
  }
  signUp(forced = false) {
    const shareType = getValueOfVariable('shareType');
    void this.openByName('signUp', { class: 'modal-lg', ignoreKeys: true, data: { forced, isPublicDemoDrawing: shareType === ShareType.PUBLIC_DEMO } });
  }
  help() {
    void this.openByName('help', { class: 'modal-lg' });
  }
  changelog() {
    void this.openByName('changelog', { class: 'modal-lg' });
  }
  userLimit(count: number) {
    void this.openByName('userLimit', { ignoreKeys: true, data: count });
  }
  drawingDeleted() {
    void this.openByName('drawingDeleted');
  }
  edition() {
    void this.openByName('edition', { class: 'modal-lg' });
  }
  overloaded() {
    void this.openByName('overloaded', { class: 'modal-lg', ignoreKeys: true });
  }
  acceptAiEula() {
    return this.openByName('acceptAiEula');
  }
  aiQuotaReached() {
    return this.openByName('aiQuotaReached');
  }
  closeOverloaded() {
    this.closeByName('overloaded');
  }
  psdImport() {
    const data: ChoiceModalOption[] = [
      { id: 'image', label: 'Import as image' },
      { id: 'layers', label: 'Import as new layers' },
      { id: 'sync', label: 'Update existing layers' },
    ];
    return this.openByName<'image' | 'layers' | 'sync'>('choice', { class: 'modal-sm', data });
  }

  multiFileImportEditor(hasPdf: boolean, importing: boolean) {
    const data: ChoiceModalOption[] = [
      ...(importing ? [{ id: 'separate', label: 'Import images separately' }] : []),
      { id: 'sequence', label: 'Add images to the sequence' },
      ...(hasPdf ? [] : [{ id: 'layers', label: 'Import as layers in a single drawing' }]),
      { id: 'single', label: importing ? 'Import first image only' : 'Paste first image only' },
    ];

    if (data.length == 1) return data[0].id as 'sequence' | 'layers' | 'single' | 'separate';
    return this.openByName<'sequence' | 'layers' | 'single' | 'separate'>('choice', { class: 'modal-sm', data });
  }

  multiFileImportManage(hasPdf: boolean) {
    const data: ChoiceModalOption[] = [
      { id: 'separate', label: 'Import images separately' },
      ...(hasPdf ? [] : [{ id: 'sequence', label: 'Import to a new sequence' }]),
      ...(hasPdf ? [] : [{ id: 'layers', label: 'Import as layers in a single drawing' }]),
    ];

    if (data.length == 1) return data[0].id as 'separate' | 'sequence' | 'layers';
    return this.openByName<'separate' | 'sequence' | 'layers'>('choice', { class: 'modal-sm', data });
  }

  question(message: string, yes?: string, no?: string) {
    return this.openByName<boolean>('question', { class: 'modal-sm', data: { message, yes, no } });
  }
  input(message: string) {
    return this.openByName<string>('input', { data: { message } });
  }
  isAnyModalOpen() {
    return !!Modals.box?.isAnyModalOpen();
  }
  async shareEntityEditor(_shortId: string, _model: Model, _openedBy: string): Promise<void> {
    DEVELOPMENT && console.error('using incorrect modal service'); // overriden in modal service
  }
  async deleteEntityEditor(_shortId: string, _model: Model, _navigate: boolean, _openedBy: string): Promise<boolean | undefined> {
    DEVELOPMENT && console.error('using incorrect modal service'); // overriden in modal service
    return undefined;
  }
  async duplicateEntityEditor(_shortId: string, _model: Model, _openedBy: string): Promise<string | undefined> {
    DEVELOPMENT && console.error('using incorrect modal service'); // overriden in modal service
    return undefined;
  }
  async selectFolder(_data: any): Promise<SelectedFolder | undefined> {
    DEVELOPMENT && console.error('using incorrect modal service'); // overriden in modal service
    return undefined;
  }
  creator(_data: any, _items?: any[], _model?: Model) {
    DEVELOPMENT && console.error('using incorrect modal service'); // overriden in modal service
  }
  closeAllMarketingModals() {
  }
  closeAllPresentationModeModals() {
  }
  stephenSilverShapesModal() {
  }
  newBrushes() {
  }
}
