import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'question-modal',
  templateUrl: 'question-modal.pug',
})
export class QuestionModal {
  @Input() data: { message: string; yes?: string; no?: string; } = { message: '' };
  @Output() close = new EventEmitter<boolean>();
}
