import {
  Directive, HostListener, Input, Output, EventEmitter, TemplateRef, ViewContainerRef, ContentChild,
  ElementRef, EmbeddedViewRef, NgZone
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Key } from '../../../common/input';
import { toolStarted } from '../../../common/update-state';
import { focusAndSetupUpDownArrows } from './contextMenu';

function shiftToFitOnScreen(element: HTMLElement, oldShift: number) {
  const rect = element.getBoundingClientRect();
  const shift = oldShift + window.innerWidth - rect.right;
  if (shift < 0) element.style.transform = `translateX(${shift}px)`;
  else if (oldShift < 0) element.style.transform = '';
  return shift;
}

// this is used for layer modes to keep the dropdown within window bounds
function updateOverY(element: HTMLElement) {
  const rect = element.getBoundingClientRect();

  if (rect.bottom > window.innerHeight) {
    element.classList.add('dropdown-over-y');
  } else {
    element.classList.remove('dropdown-over-y');
  }
}

@Directive({ selector: '[dropdownMenu]' })
export class DropdownMenu {
  private ref?: EmbeddedViewRef<any>;
  private root: HTMLElement | undefined;
  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private zone: NgZone) {
  }
  open(focus: boolean) {
    if (!this.ref) {
      this.ref = this.viewContainer.createEmbeddedView(this.templateRef);
      this.root = this.ref.rootNodes[0] as HTMLElement;
      this.root.classList.add('show');

      // TODO: replace this with positioning service
      const shift = shiftToFitOnScreen(this.root, 0);
      this.zone.runOutsideAngular(() => requestAnimationFrame(() => {
        if (this.root) {
          shiftToFitOnScreen(this.root, shift);
          updateOverY(this.root);
        }
      }));

      if (focus) {
        focusAndSetupUpDownArrows(this.root);
      }
    }
  }
  close() {
    if (this.ref) {
      this.viewContainer.clear();
      this.ref = undefined;
      this.root = undefined;
    }
  }
  focus() {
    focusAndSetupUpDownArrows(this.root);
  }
  checkTarget(e: Event) {
    return this.ref?.rootNodes[0].contains(e.target);
  }
}

@Directive({
  selector: '[dropdown]',
  exportAs: 'ag-dropdown',
  host: {
    '[class.show]': 'isOpen',
  },
})
export class Dropdown {
  dropdownToggle?: DropdownToggle;
  @ContentChild(DropdownMenu, { static: true }) menu!: DropdownMenu;
  @Input() autoClose: boolean | 'outsideClick' = true;
  @Input() isDisabled = false;
  @Input() directFocus = true;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  private addListenersTimeout: any;
  private preventClose = false;
  private subscription: Subscription | undefined = undefined;
  constructor(private zone: NgZone) {
  }
  open() {
    if (!this.isOpen && !this.isDisabled) {
      this.preventClose = false;
      this.isOpen = true;
      this.isOpenChange.emit(true);
      this.menu.open(this.directFocus);

      this.addListenersTimeout = setTimeout(() => {
        document.addEventListener('click', this.closeHandler);
        document.addEventListener('keydown', this.closeHandler);
        this.subscription = toolStarted.subscribe(this.closeHandler2);
      });
    }
  }
  close() {
    if (this.isOpen) {
      this.preventClose = false;
      this.isOpen = false;
      this.isOpenChange.emit(false);
      this.menu.close();
      clearTimeout(this.addListenersTimeout);
      document.removeEventListener('click', this.closeHandler);
      document.removeEventListener('keydown', this.closeHandler);
      this.subscription?.unsubscribe();
      this.subscription = undefined;
    }
  }
  toggle() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }
  focus() {
    this.menu.focus();
  }
  preventNextAutoClose() { // TODO: remove? it's replaced with dropdown-prevent-autoclose class
    this.preventClose = true;
  }
  private closeHandler: any = (e: KeyboardEvent) => {
    if (e.type === 'click') {
      for (let target = e.target as HTMLElement | null; target; target = target.parentElement) {
        if (target.classList?.contains('dropdown-prevent-autoclose')) {
          return;
        }
      }
    }

    if (this.preventClose) {
      this.preventClose = false;
      if (e.type === 'click') return;
    }

    if (!e.keyCode
      && (this.autoClose || (this.dropdownToggle && this.dropdownToggle.checkTarget(e)))
      && !(this.autoClose === 'outsideClick' && this.menu.checkTarget(e))) {
      this.close();
    } else if (this.autoClose && e.keyCode === Key.Esc) {
      this.close();
      this.dropdownToggle?.element.nativeElement.focus();
    }
  };
  private closeHandler2 = () => {
    this.zone.run(() => this.close());
  };
}

@Directive({
  selector: '[dropdownToggle]',
  host: {
    'aria-haspopup': 'true',
    '[attr.aria-expanded]': 'dropdown.isOpen',
  },
})
export class DropdownToggle {
  constructor(public dropdown: Dropdown, public element: ElementRef<HTMLElement>) {
    dropdown.dropdownToggle = this;
  }
  @HostListener('click')
  click() {
    this.dropdown.toggle();
  }
  checkTarget(e: Event) {
    return this.element.nativeElement.contains(e.target as any);
  }
}
