import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { HelpMessage, IHelpService } from '../common/interfaces';
import { ITrackService } from './track.service.interface';

@Injectable({ providedIn: 'any' })
export class HelpService implements IHelpService {
  messages = new Subject<HelpMessage>();

  constructor(
    private zone: NgZone,
    private trackService: ITrackService
  ) { }

  show(message: HelpMessage) {
    this.trackService.handleHelpMessage(message);
    this.zone.run(() => this.messages.next(message));
  }
}
