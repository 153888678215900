import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'price-tag',
  template: '${{dollars}}<small>.{{cents}}<small>',
  styles: [
    `:host.x { text-decoration: line-through; }`
  ],
  host: {
    '[class.x]': 'slashed'
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceTag {
  dollars = '0';
  cents = '00';
  private _price = 0;
  get price() {
    return this._price;
  }
  @Input() set price(value) {
    if (this._price !== value) {
      this._price = value;
      this.dollars = Math.floor(value / 100).toString();
      this.cents = (value % 100).toString();
      if (this.cents.length === 1) this.cents = '0' + this.cents;
    }
  }
  @Input() slashed = false;
}
