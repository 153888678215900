import { Component, Input, Output, EventEmitter, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { clamp } from '../../../common/mathUtils';
import { faArrowUp } from '../../../generated/fa-icons';
import { AgDragEvent } from '../directives/agDrag';

@Component({
  selector: 'slider-bar',
  templateUrl: 'slider-bar.pug',
  styleUrls: ['slider-bar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'role': 'slider',
    'aria-valuenow': 'value',
    '[tabindex]': 'disabled ? -1 : 0',
  },
})
export class SliderBar {
  @Input() value = 0;
  @Input() min = 0;
  @Input() max = 100;
  @Input() step = 0;
  @Input() largeStep = 10;
  @Input() label = '';
  @Input() desc: any = '';
  @Input() disabled = false;
  @Input() lowBound: number | undefined;
  @Input() arrow: boolean | undefined;
  @Output() valueChange = new EventEmitter<number>();
  @Output() start = new EventEmitter();
  @Output() changed = new EventEmitter<{ oldValue: number; value: number; }>();
  private currentWidth = 0;
  private oldValue = 0;
  private shift = false;
  private startX = 0;
  private startValue = 0;
  private dragging = false;
  readonly upIcon = faArrowUp;
  constructor(private element: ElementRef<HTMLElement>) {
  }
  get width() {
    return clamp(100 * (this.value - this.min) / (this.max - this.min), 0, 100);
  }
  get arrowPosition() {
    return `calc(${this.width}% - 15px)`;
  }
  drag({ x, type, event }: AgDragEvent) {
    if (this.disabled && !this.dragging) return;

    if (type === 'start') {
      const element = this.element.nativeElement;
      this.currentWidth = element.getBoundingClientRect().width;
      this.oldValue = this.value;
      this.dragging = true;
      this.start.emit();
    }

    if (event.shiftKey && !this.shift) {
      this.startX = x;
      this.startValue = this.value;
    }

    this.shift = event.shiftKey;
    const min = +this.min;
    const max = +this.max;
    const step = +this.step;

    let value;

    if (this.shift) {
      value = this.startValue + ((x - this.startX) / (this.currentWidth * 4)) * (max - min);
    } else {
      value = min + clamp(x / this.currentWidth, 0, 1) * (max - min);
    }

    if (step) value = Math.round(value / step) * step;

    value = clamp(value, this.lowBound ?? min, max);

    if (type === 'cancel') {
      value = this.oldValue;
      this.dragging = false;
    }

    if (this.value !== value) {
      this.value = value;
      this.valueChange.emit(value);
    }

    if ((type === 'end' || this.disabled) && this.oldValue !== value) {
      this.dragging = false;
      this.changed.emit({ oldValue: this.oldValue, value });
    }
  }
}
