import { IToolEditor, IToolModel, ToolId, IFiltersValues } from '../interfaces';
import { HUE_FILTER_MAX, HUE_FILTER_MIN, LIGHTNESS_FILTER_MAX, LIGHTNESS_FILTER_MIN, SATURATION_FILTER_MAX, SATURATION_FILTER_MIN } from '../constants';
import { safeFloat } from '../toolUtils';
import { BaseFilterTool } from './baseFilterTool';

export class HueSaturationLightnessTool extends BaseFilterTool {
  id = ToolId.HueSaturationLightness;
  name = 'Hue / Saturation / Lightness';
  constructor(editor: IToolEditor, model: IToolModel) {
    super(editor, model, 'hueSaturationLightness');
  }
  protected applyFilter() {
    if (!this.layer) throw new Error(`[${this.toolName}] Missing layer`);
    this.editor.renderer.applyHueSaturationLightnessFilter(this.srcData, this.model.user.surface, this.values);
  }
  protected parseData(data?: IFiltersValues): IFiltersValues {
    const hue = safeFloat(data?.hue, HUE_FILTER_MIN, HUE_FILTER_MAX);
    const saturation = safeFloat(data?.saturation, SATURATION_FILTER_MIN, SATURATION_FILTER_MAX);
    const lightness = safeFloat(data?.lightness, LIGHTNESS_FILTER_MIN, LIGHTNESS_FILTER_MAX);
    return { hue, saturation, lightness };
  }
}
