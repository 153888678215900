import { Injectable } from '@angular/core';
import { ProjectQuery } from './projects.query';
import { TeamsQuery } from './team.query';
import { EntitiesService } from './entities.service';
import { ManageService, ManageTeamData } from 'magma/services/manageService';
import { ToastService } from 'magma/services/toast.service';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { ModalService } from './modal.service';
import { TeamMembersService } from './team-members.service';
import { BannerInfo, Drawing, Feature, Permission, TagInfo } from 'magma/common/interfaces';
import { toPromise } from 'shared/utils';
import { HttpClient } from '@angular/common/http';
import { IFeatureFlagService } from 'magma/services/feature-flag.service.interface';
import { AppService } from './app.service';
import { TeamService } from './team.service';
import { contactSupportIntercom, fromNow } from 'magma/common/utils';
import { TeamData } from 'shared/interfaces';
import { FREE_USER_STORAGE_LIMIT, PRO_USER_STORAGE_LIMIT } from 'shared/constants';
import { DAY } from 'magma/common/constants';
import { TeamsStore } from './team.store';

@Injectable()
export class PortalManageService extends ManageService {
  constructor(
    private teamsQuery: TeamsQuery,
    private projectQuery: ProjectQuery,
    private entitiesService: EntitiesService,
    private toastService: ToastService,
    private userService: UserService,
    public router: Router,
    private modals: ModalService,
    private teamMemberService: TeamMembersService,
    private httpClient: HttpClient,
    private featureFlagService: IFeatureFlagService,
    private appService: AppService,
    private teamService: TeamService,
    private teamStore: TeamsStore,
  ) {
    super(router);
  }

  team(teamId: string): ManageTeamData | undefined {
    return this.teamsQuery.getAll().find(t => t._id === teamId);
  }

  teams(teamIds: string[]): ManageTeamData[] {
    return this.teamsQuery.getAll().filter(t => teamIds.includes(t._id));
  }

  project(projectId: string) {
    return this.projectQuery.getEntity(projectId);
  }

  async folder(folderId: string) {
    const folder = await this.entitiesService.getById(folderId).toPromise();
    return folder ? { name: folder.name } : undefined;
  }

  activeTeam() {
    return this.teamsQuery.getActive();
  }

  hasTrial() {
    return !this.userService.user?.subscriptionStatus?.status ||
      this.userService.user.subscriptionStatus.status === 'incomplete';
  }

  error(message: string) {
    this.toastService.error({ message });
  }

  upgrade(openedBy: string) {
    // if user has failed charge, redirect them to their billing page
    if (this.userService.user?.subscriptionStatus?.status === 'unpaid') {
      void this.router.navigate(['/my/account/billing']);
    } else {
      this.modals.upgradeModal(openedBy);
    }
  }

  hasPermission(permission: Permission[]) {
    return this.teamMemberService.isPermissionFlagSet(permission);
  }

  hasNewFeature(feature: string) {
    return this.userService.user?.newFeature === feature;
  }

  addTag(tag: string): Promise<TagInfo | void> {
    return toPromise(this.httpClient.post<TagInfo>('/api/profile/tag', { tag }));
  }

  async getFirstInSequence(drawingId: string): Promise<string | undefined> {
    return toPromise(this.httpClient.get<string | undefined>(`/api/entities/first-in-sequence/${drawingId}`));
  }

  getBanner(): BannerInfo | undefined {
    if (this.userService.user?.hideEducationalContent) return undefined;
    return this.userService.user?.banner;
  }

  toggleBannersOff() {
    this.userService.save({ hideEducationalContent: true }).catch(e => DEVELOPMENT && console.error(e));
  }

  async importEntityWithToast(name: string, files: File[], folderId: string | undefined): Promise<string | undefined> {
    const entity = await this.entitiesService.importEntityWithToast(name, files, folderId, this.userService.user!);
    return entity?._id;
  }

  updateDrawing(drawing: Drawing) {
    this.featureFlagService.updateDrawing(drawing);
  }

  async contactUs() {
    await this.appService.onContactSupport();
  }

  async getStorageUsage(teamId?: string, fetch = false) {
    if (fetch) {
      if (!teamId) {
        const usage = await toPromise(this.entitiesService.getUsageData());
        this.userService.updateUser({ storageUsage: usage.used });
        return usage;
      } else {
        const usage = await toPromise(this.teamService.getUsageData(teamId));
        this.teamStore.update(teamId, team => ({ ...team, storageUsage: usage ?? { used: 0, limit: 1 } }));
        return usage;
      }
    } else {
      if (!teamId) {
        return this.userStorageUsage();
      } else {
        return this.team(teamId)?.storageUsage ?? { used: 0, limit: 1 };
      }
    }
  }

  reachedStorageLimit(team?: TeamData, destination = false) {
    if (IS_HOSTED) return;

    const user = this.userService.user;
    if (!user) return;

    const userIsPro = !!user.pro;
    const activeTeam = destination ? team : this.activeTeam();

    this.modals.storageLimitExceeded(user._id, userIsPro, activeTeam).then(response => {
      if (response) {
        if (activeTeam) { // Artspace
          if (activeTeam.pro) contactSupportIntercom('Business inquiries');
          else void this.modals.upgradeTeamSubscriptionModal({
            teamId: activeTeam._id,
            teamSlug: activeTeam.slug,
            alreadyOnProPlan: !!activeTeam.pro
          });
        } else { // Artdesk
          if (userIsPro) contactSupportIntercom('Business inquiries');
          else this.upgrade('storage-limit-exceeded');
        }
      }
    }).catch(e => DEVELOPMENT && console.error(e));
  }

  isStorageLimitActive(team?: ManageTeamData) {
    if (IS_HOSTED) return false;

    if (team) {
      return !team.featureFlags?.includes(Feature.StorageNoUsageLimits);
    } else {
      return !this.userService?.user?.featureFlags?.includes(Feature.StorageNoUsageLimits);
    }
  }

  isStorageLimitExceeded(team?: ManageTeamData) {
    if (IS_HOSTED) return false;
    if (!this.isStorageLimitActive(team)) return false;

    const usage = team ? team.storageUsage : this.userStorageUsage();
    return !!usage && usage.used >= usage.limit;
  }

  handleErrorMessages(message?: string) {
    if (IS_HOSTED) return;
    if (message === 'Your storage limit is exceeded') this.reachedStorageLimit();
  }

  userStorageUsage() {
    const user = this.userService.user;
    return {
      used: user?.storageUsage || 0,
      limit: user?.pro ? PRO_USER_STORAGE_LIMIT : FREE_USER_STORAGE_LIMIT
    };
  }

  hasToolTrial(trial: string) {
    const date = this.userService.user?.trials?.[trial];
    return !!(date && (date > fromNow(-14 * DAY).toISOString()));
  }

  startedToolTrial(trial: string) {
    return !!this.userService.user?.trials?.[trial];
  }

  toolTrialDaysLeft(trial: string) {
    const date = this.userService.user?.trials?.[trial];
    if (!date) return 0;
    return 14 - Math.round((Date.now() - new Date(date).getTime()) / DAY);
  }

  async startToolTrial(trial: string) {
    await this.userService.startTrial(trial);
  }
}
