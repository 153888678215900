import { IHistory, IUndoFunction } from './interfaces';

export class MockHistory implements IHistory {
  stats() { }
  hadDiscardedUndos() { return false; }
  beginTransaction() { }
  endTransaction() { }
  execTransaction(actions: (history: IHistory) => void) {
    actions(this);
  }
  pushUndo() { }
  pushSelection() { }
  pushDirtyRect() { }
  pushAddLayer() { }
  pushRemoveLayer() { }
  pushLayerState() { }
  unpre() { }
  prepushUndo() { }
  prepushSelection() { }
  prepushDirtyRect() { }
  prepushLayerState() { }
  prepushTool() { }
  pushTool() { }
  pushLayerId() { }
  clearLayer() { }
  clearRedos() { }
  clear() { }
  undo() { }
  redo() { }
  canUndo() { return true; }
  canRedo() { return true; }
  attachLastPoint() { }
  isLastEntryMove() { return false; }
  cancelLastUndo() { }
  createSelection(): IUndoFunction { return {} as any; }
  createLayerState(): IUndoFunction { return {} as any; }
}
