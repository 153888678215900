import { Directive, Input, ElementRef } from '@angular/core';

@Directive({ selector: '[svgSrc]' })
export class SvgSrc {
  private _svgSrc: string | undefined = undefined;
  constructor(private element: ElementRef<HTMLImageElement>) {
  }
  @Input() set svgSrc(value: string | undefined) {
    if (this._svgSrc !== value) {
      this._svgSrc = value;
      this.element.nativeElement.src = value ? `data:image/svg+xml;base64,${btoa(value)}` : '';
    }
  }
}
