import { persistState } from '@datorama/akita';
import localforage from 'localforage';
import { debounceTime } from 'rxjs/operators';

localforage.config({
  name: PRODUCT_INFO.productId,
  storeName: 'state',
  driver: [
    localforage.INDEXEDDB,
    localforage.LOCALSTORAGE,
  ],
});

export const persistStorage = persistState({
  include: [
    'teams.active',
  ],
  storage: localforage,
  preStorageUpdateOperator: () => debounceTime(500),
  key: 'app'
});
