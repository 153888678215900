/* eslint-disable no-bitwise */
import { DocumentStatuses } from 'shared/constants';
import type { EntityDocument, IEntity } from 'server/dao/entities';
import type { ID, ProjectDocument, TeamDocument } from 'server/dao/types';
import { CC_PRODUCT_EDITOR, CC_PRODUCT_REVIEWER, CC_PRODUCT_VIEWER } from './billing';
import { ITeamDocument, IUserDocument } from './rpc-interface';
import { Job as BullMqJob } from 'bullmq';
import type { Types } from 'mongoose';
import { ShareType, UserRole, Permission, Feature, SvgIconDefinition, SubscriptionStatus, BannerInfo, ProjectType } from 'magma/common/interfaces';

export enum EntityType {
  Folder = 'Folder',
  Drawing = 'Drawing',
  Flowchart = 'Flowchart',
}

export interface PageSize {
  width: number;
  height: number;
}

// options configurable by the user
export interface DiagramOptions {
  preParsed?: boolean;
  pageSize: PageSize;
  trueLabel: string;
  falseLabel: string;
  selectedTheme: 'plain' | 'clean' | 'colored' | 'cool';
  renderShadows: boolean;
  nodeDistance: number;
  rankDistance: number;
  compact: boolean;
  direction: 'top' | 'bottom' | 'left' | 'right';
  optimizeCommon: boolean;
  decorateEdgeLabels: boolean;
  fontSize: number;
}

// options accepted by the DiagramService
export interface ServiceDiagramOptions extends DiagramOptions {
  clientSideTheme?: boolean;
  withCode?: boolean;
}

// options that the server takes
export interface ServerDiagramOptions extends ServiceDiagramOptions {
  jsonResult?: boolean;
  preParsed?: any;
  svgTextToPath?: boolean;
  codeTheme?: string;
  dpi?: number;
}

export interface RenderLimits {
  maxNodes: number;
}

export interface TokenPosition {
  column: number;
  line: number;
}

export interface SearchRequest {
  q: string;
  queryFor: string;
  page?: number;
  pageSize?: number;
  sortColumn?: string;
  sortOrder?: string;
  place?: string;
  lastActive: number;
  comments: string;
}

export interface TokenRange {
  from: TokenPosition;
  to: TokenPosition;
  type?: string;
  link?: TokenRange;
}

export interface DiagramTokenPositions {
  [name: string]: [TokenRange];
}

export interface CodeMetadata {
  codeWidth: number;
  codeHtml: string;
}

export interface ServerConstantVars {
  isProduction: boolean;
  environment: string;
  publicUrl?: string;
  appOrigin?: string;
  stripePublishableKey?: string;
  privacyPolicyUrl?: string;
  isAiEnabled: boolean;
  // TODO: remove, this should be team/project specific
  allowAnonymousUsers?: boolean;
}

export interface Flow2DotSuccess {
  text: string;
  positions: DiagramTokenPositions;
  nodeCount: number;
  metadata?: CodeMetadata;
}

export interface Flow2DotError {
  error: string;
}

export type Flow2DotResult = Flow2DotSuccess | Flow2DotError;

export interface EntityAuthor {
  _id: string;
  name: string;
  avatar: string;
  isSuperAdmin?: boolean;
  pro?: boolean;
}

export interface ProjectLinkMetadata {
  _id: string;
  name?: string;
  link?: string;
}

export interface EntityData {
  _id: string;
  shortId: string;
  privateId?: string | null | false; // TODO: what is `false` for ?
  type: EntityType;
  name: string;
  folder?: string;
  project?: string | ProjectLinkMetadata; // TODO: remove ProjectLinkMetadata
  team?: string;
  author?: EntityAuthor | string; // TODO: remove `string` or `EntityAuthor` from here
  modifiedAt?: Date | string;
  createdAt?: Date | string;
  updatedAt?: Date | string;
  openedAt?: Date | string;
  status?: EntityStatus;
  folderHierarchy?: EntityData[];
  depth?: number;
  children?: number;
  participants?: Participant[];
  password?: string;
  hasPassword?: boolean;
  isHidden?: boolean; // TODO: what is this ?
  threads?: {
    unread: number;
    unresolved: number;
  };
  cacheId?: string;
  // sharing
  shareType?: ShareType;
  collaborators?: Collaborator[];

  // TODO: combine into one field
  userRole?: UserRole; // role of current user for this drawing

  // move to DrawingData in some way?
  sequence?: string;

  // bin folder
  removedAt?: Date | string;
  removedBy?: EntityAuthor | string;
}

export interface CreateEntityData {
  type: EntityType;
  name: string;
  folder?: string;
  project?: string;
  team?: string;
}

export interface BaseFlowchartData extends CreateEntityData {
  input: string;
  diagramAppearanceOptions: DiagramOptions;
}

export interface FlowchartData extends EntityData {
  positions?: DiagramTokenPositions;
  svg?: string;
  input: string;
  diagramAppearanceOptions: DiagramOptions;
}

export type EntityDataForPublish = Pick<IEntity, 'type' | 'name' | 'author' | 'team' | 'project' | 'folder'>;
export type EntityDataForUpdate = Pick<IEntity, 'name' | 'password'>;
export type EntityDataForClone = Pick<IEntity, 'name' | 'team' | 'project' | 'folder'>;
export type ContextDataForClone<T extends Record<string, any> = Record<string, any>> = EntityDataForClone & {
  dataFromVerify: T;
  cloneComments?: boolean;
  cloneParticipations?: boolean;
  clonePromptHistory?: boolean;
};

export type FlowchartDataForPublish = Pick<FlowchartData, 'name' | 'input' | 'diagramAppearanceOptions' | 'author' | 'project'>;
export type FlowchartDataForUpdate = Pick<FlowchartData, 'name' | 'input'>;
export type FlowchartDataForSave = Pick<FlowchartData, 'name' | 'input' | 'diagramAppearanceOptions' | 'privateId' | 'author'>;
export type FlowchartListData = Omit<FlowchartData, 'input' | 'diagramAppearanceOptions' | 'svg' | 'positions'>;

export interface EntityStatus {
  state: DocumentStatuses;
  notify: UserData[]; //  | Types.ObjectId[] | UserDocument[] | UserData[];
  lastChange: {
    at: Date;
    by: UserData;
  };
}

export type TeamOwnerData = Pick<UserData, '_id' | 'name' | 'email'>;

export interface ProductData {
  productId: string;
  name: string;
  stripePriceId: { [key: string]: string }; // interval: stripePriceId
}

export interface PermissionData {
  group: 'entities' | 'teams' | 'projects';
  permission: Permission;
  name: string;
  description: string;
  requiredProduct?: string[];
}

export const PERMISSION_DATA: PermissionData[] = [
  { group: 'entities', permission: Permission.CanUpdateEntities, name: 'Update entities', description: 'User can change entity name', requiredProduct: [CC_PRODUCT_EDITOR] },
  { group: 'entities', permission: Permission.CanEditEntities, name: 'Change entities', description: 'User can draw', requiredProduct: [CC_PRODUCT_EDITOR] },
  { group: 'entities', permission: Permission.CanViewEntities, name: 'View entities', description: 'User can see drawing', requiredProduct: [CC_PRODUCT_VIEWER] },
  { group: 'entities', permission: Permission.CanDuplicateEntities, name: 'Duplicate entities', description: 'User can duplicate already existing entities', requiredProduct: [CC_PRODUCT_EDITOR] },
  { group: 'entities', permission: Permission.CanDeleteEntities, name: 'Delete entities', description: 'User can delete entities', requiredProduct: [CC_PRODUCT_EDITOR] },
  { group: 'entities', permission: Permission.CanDeleteEntitiesPermanently, name: 'Delete entities permanently', description: 'User can delete entities permanently from bin', requiredProduct: [CC_PRODUCT_EDITOR] },
  { group: 'entities', permission: Permission.CanCreateEntities, name: 'Create folders and entities', description: 'User can create folders and entities', requiredProduct: [CC_PRODUCT_EDITOR] },
  { group: 'entities', permission: Permission.CanExportEntityAsImage, name: 'Export drawings as images', description: 'User can export drawings', requiredProduct: [CC_PRODUCT_VIEWER] },
  { group: 'entities', permission: Permission.CanUpdateEntityPassword, name: 'Change password to entities', description: 'User can change password to drawings', requiredProduct: [CC_PRODUCT_VIEWER] },

  { group: 'entities', permission: Permission.CanReadThreadsAndComments, name: 'Read comments', description: 'User can read all comments added to entities', requiredProduct: [CC_PRODUCT_VIEWER] },
  { group: 'entities', permission: Permission.CanCreateThreadsAndComments, name: 'Create comments', description: 'User can create threads and write comments to already existing threads', requiredProduct: [CC_PRODUCT_REVIEWER] },
  { group: 'entities', permission: Permission.CanDeleteAllThreadsAndComments, name: 'Delete all threads and comments', description: 'User can delete comments and threads created by other users', requiredProduct: [CC_PRODUCT_REVIEWER] },

  { group: 'teams', permission: Permission.CanManageTeam, name: 'Manage artspace', description: 'User will have access to artspace settings' },
  { group: 'teams', permission: Permission.CanMoveEntitiesOutsideTeam, name: 'Move drawings outside team', description: 'User can move drawings to personal artdesk or other teams' },
  { group: 'teams', permission: Permission.CanManageTeamBilling, name: 'Manage billing', description: 'User will have access to artspace billing data and details' },
  { group: 'teams', permission: Permission.CanManageInvites, name: 'Manage invites', description: 'User can enable/disable artspace invites and update invite token' },
  { group: 'teams', permission: Permission.CanViewTeamMembers, name: 'View artspace members', description: 'User can view list of team members' },
  { group: 'teams', permission: Permission.CanManageTeamMembers, name: 'Manage artspace members', description: 'User can add and remove new artspace members' },
  { group: 'teams', permission: Permission.CanManageTeamRoles, name: 'Manage artspace roles', description: 'User can update roles and permissions in roles' },
  // So far disable permissions for contributor roles
  // Permission.CanSeeRestrictedProjectsAndEntities,
  // Permission.CanInviteOthersToEntities,
  // Permission.CanInviteOthersToProjects,

  { group: 'projects', permission: Permission.CanCreateProjects, name: 'Create new projects', description: 'User can create new projects in artspace', requiredProduct: [CC_PRODUCT_EDITOR] },
  { group: 'projects', permission: Permission.CanUpdateProjects, name: 'Update projects', description: 'User can update project settings (including project visibility)', requiredProduct: [CC_PRODUCT_EDITOR] },
  { group: 'projects', permission: Permission.CanDeleteProjects, name: 'Delete projects', description: 'User can delete projects and entities in that project', requiredProduct: [CC_PRODUCT_EDITOR] }, // hmm to remove entites user should also have 'CanDeleteEntities' ?
  // { group: 'projects', permission: Permission.CanManageBlog, name: 'Manage blog', description: 'User can create, edit, remove and pin blog posts within artspaces content pages', requiredProduct: [CC_PRODUCT_EDITOR] },
];

export const PERMISSION_DATA_MAP = new Map(PERMISSION_DATA.map(p => [p.permission, p.requiredProduct]));

export const PERMISSION_DATA_ENTITIES = PERMISSION_DATA.filter(p => p.group === 'entities');
export const PERMISSION_DATA_TEAMS = PERMISSION_DATA.filter(p => p.group === 'teams');
export const PERMISSION_DATA_PROJECTS = PERMISSION_DATA.filter(p => p.group === 'projects');

export enum RoleType {
  Owner = 'owner',
  Default = 'default',
  Everyone = 'everyone',
  Contributor = 'contributor',
  Blocked = 'blocked',
  Custom = 'custom', // all other roles, to avoid setting type as null
}
export const ROLE_TYPES_READ_ONLY = [RoleType.Owner, RoleType.Everyone, RoleType.Blocked];
export const ROLE_TYPES_READ_ONLY_PERMISSIONS = [RoleType.Owner, RoleType.Blocked];
export const DEFAULT_ROLE_TYPES = [RoleType.Default, RoleType.Everyone];
export const ROLE_TYPES = [RoleType.Default, RoleType.Contributor, RoleType.Custom];
export const ALL_ROLE_TYPES = [...ROLE_TYPES, ...ROLE_TYPES_READ_ONLY];

// TODO rename it to Role ?
export interface TeamRole {
  _id: string;
  type: RoleType;
  name: string;

  flags: Permission[];

  user?: {
    _id?: string; // it is used only in admin panel, consider creating separate type for admin
    name: string;
    avatar: string;
  };
  team?: string | null;
  projects?: string[];
  entities?: string[];
}

export type CreateRole = Pick<TeamRole, 'name' | 'flags' | 'projects' | 'entities' | 'type'> & { user?: string; };
export type UpdateRole = Partial<Pick<TeamRole, 'name' | 'flags' | 'projects' | 'entities' | 'type'>>;
export const MAX_ROLE_PERMISSIONS = [0xFFFF_FFFF, 0xFFFF_FFFF];

export const DefaultTeamOwnerRole: CreateRole = {
  name: 'Owner',
  flags: MAX_ROLE_PERMISSIONS,
  type: RoleType.Owner,
};

export const EveryoneTeamMemberRole: CreateRole = {
  name: 'Everyone',
  flags: [(
    (1 << Permission.CanCreateEntities) |
    (1 << Permission.CanUpdateEntities) |
    (1 << Permission.CanDuplicateEntities) |
    (1 << Permission.CanDeleteEntities) |
    (1 << Permission.CanExportEntityAsImage) |
    (1 << Permission.CanUpdateEntityPassword) |
    (1 << Permission.CanEditEntities) |
    (1 << Permission.CanViewEntities) |
    (1 << Permission.CanReadThreadsAndComments) |
    (1 << Permission.CanCreateThreadsAndComments) |
    (1 << Permission.CanViewTeamMembers)
  )],
  type: RoleType.Everyone,
};

export const AdminTeamMemberRole: CreateRole = {
  name: 'Admin',
  flags: [(
    (1 << Permission.CanCreateEntities) |
    (1 << Permission.CanUpdateEntities) |
    (1 << Permission.CanDuplicateEntities) |
    (1 << Permission.CanDeleteEntities) |
    (1 << Permission.CanExportEntityAsImage) |
    (1 << Permission.CanUpdateEntityPassword) |
    (1 << Permission.CanEditEntities) |
    (1 << Permission.CanViewEntities) |
    (1 << Permission.CanReadThreadsAndComments) |
    (1 << Permission.CanCreateThreadsAndComments) |
    (1 << Permission.CanDeleteAllThreadsAndComments) |
    (1 << Permission.CanManageTeam) |
    (1 << Permission.CanManageInvites) |
    (1 << Permission.CanInviteOthersToEntities) |
    (1 << Permission.CanInviteOthersToProjects) |
    (1 << Permission.CanMoveEntitiesOutsideTeam) |
    (1 << Permission.CanManageTeamBilling) |
    (1 << Permission.CanManageTeamMembers) |
    (1 << Permission.CanManageTeamRoles) |
    (1 << Permission.CanSeeRestrictedProjectsAndEntities) |
    (1 << Permission.CanCreateProjects) |
    (1 << Permission.CanUpdateProjects) |
    (1 << Permission.CanDeleteProjects) |
    (1 << Permission.CanDeleteEntitiesPermanently) |
    (1 << Permission.CanViewTeamMembers)
  )],
  type: RoleType.Custom,
};

export const BlockedTeamMemberRole: CreateRole = {
  name: 'Blocked',
  flags: [],
  type: RoleType.Blocked,
};

export const DrawingOwnerRole: TeamRole = {
  _id: 'owner',
  name: 'Drawing Owner',
  type: RoleType.Owner,
  flags: MAX_ROLE_PERMISSIONS,
  team: null,
};

export enum TeamType {
  Public = 'public',
  Private = 'private',
  Professional = 'professional',
  Enterprise = 'enterprise'
}

export interface ScheduledTeamBillingUpdate {
  date: Date;
  interval: BillingInterval;
  items?: UpdateBillingItem[];
}
export const DrawingParticipantRole: TeamRole = {
  _id: 'participant',
  name: 'Drawing participant',
  type: RoleType.Everyone,
  flags: [],
  team: null,
};

export interface OrganizationData {
  name: string;
  provider: string;
  applicationId: string;
  tenantId: string;
}

export interface TeamData {
  _id: string;
  name: string;
  slug: string;
  avatar: string;
  description?: string;
  flags: TeamFlags;
  signupFilter: string[];
  featureFlags?: Feature[];
  pro?: boolean;
  forcePro?: boolean;
  forceProUntil?: Date;
  isPublic: boolean;
  cacheId?: string;
  billing?: BillingData;
  scheduledBillingUpdate: ScheduledTeamBillingUpdate,
  projects: ProjectData[];
  createdAt: string;
  requireSamlAuthentication?: boolean;
  deletionDate?: Date;
  storageLimit?: number;
  storageUsage?: StorageUsageData;
}

export interface UpdateTeam {
  name?: string;
  slug?: string;
  avatar?: string;
  description?: string;
  flags?: TeamFlags;
  signupFilter?: string[];
  isPublic?: boolean;
}

export interface ProjectData {
  _id: string;
  name: string;
  team: string;
  shareType: ProjectShareTypes;
  type: ProjectType;
  entities?: EntityData[];
  description?: string;
  title?: string;
  order?: number;
}

export interface ProjectDataBasic {
  _id: string;
  name: string;
  description?: string;
  shareType: ProjectShareTypes;
}

export interface CreateProjectData {
  name: string;
  team: string;
  type: ProjectType;
  shareType?: ProjectShareTypes;
}

export interface UpdateProjectData {
  name?: string;
  shareType?: ProjectShareTypes;
  description?: string;
  title?: string;
}

export type TeamMemberUserData = Pick<UserData, '_id' | 'name' | 'avatar' | 'lastActive' | 'userType' | 'email'>;

export interface TeamMember {
  _id: string;
  user: Pick<UserData, '_id' | 'name' | 'avatar' | 'lastActive' | 'userType'>;
  status: InvitationActions;
  roles: TeamRole[];
  email: string | undefined;
}
export interface TeamBannedMember {
  user: Pick<UserData, '_id' | 'name' | 'avatar'>;
  team: TeamDocument;
  createdAt: Date;
}

export interface SharedItem {
  thumbnail: { type: 'url'; value: string; } | { type: 'icon'; value: SvgIconDefinition; };
  name: string;
  type: string;
  _id: string;
  folder?: string;
  project?: string;
  team?: string;
}

export interface Invitee {
  email: string;
}

export enum InvitationActions {
  SENT = 'sent',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

// TODO: the fact that all of these fields are optional is very error prone
export interface UserData {
  _id: string;
  userType: 'user' | 'anonymous';
  name: string;
  avatar?: string;
  color?: string;
  workRole?: string;
  workTags?: string[];
  email?: string;
  unverifiedEmail?: string;
  plan?: string;
  newsletter?: boolean;
  newFeature?: string;
  lastTeam?: string;
  lastCommunityHubProject?: string;
  lastNewFeature?: string;
  hideNewFeatureNotifications?: boolean;
  muteAppNotifications?: boolean;
  hideEducationalContent?: boolean;
  isSuperAdmin?: boolean;
  isImpersonating?: boolean;
  pro?: boolean;
  proSources?: string[];
  forcePro?: boolean;
  forceProUntil?: string;
  trials?: { [key: string]: string; };
  /**
   * @internal
   */
  subscriptionStatus?: SubscriptionStatus;
  /**
   * @internal
   */
  featureFlags?: Feature[];
  oauth?: string; // is set to name of oauth provider if user doesn't have email+password set up
  lastActive?: Date;
  tawkHash?: string; // Hash for tawk.to user integration
  intercomHash?: string; // Hash for Intercom user integration
  intercomPayload?: string; // Intercom secret payload
  createdAt?: string;
  settings?: string;
  tags?: string[];
  createdDrawings?: number;
  banner?: BannerInfo;
  npsSurvey?: boolean;

  // storage
  storageUsage?: number;
}

export interface Participant extends Pick<UserData, '_id' | 'name' | 'userType' | 'avatar'> {
  isOnline: boolean;
  role: UserRole | undefined;
}

export type EntityUpdate = Pick<EntityData, 'name' | 'modifiedAt' | 'openedAt' | 'cacheId'>;

export interface RecentEntity {
  _id: string;
  entity: EntityUpdate & Pick<EntityData, '_id' | 'name' | 'modifiedAt' | 'updatedAt' | 'team' | 'author' | 'hasPassword' | 'shortId' | 'type' | 'cacheId'>;
  isLive: boolean;
  participants: Participant[];
  updatedAt: string;
}

export interface UnsubRecentEntity {
  _id: string;
  unsub: true;
}

export interface ApiKeyData {
  _id: string;
  name: string;
  user: string | Types.ObjectId;
  key: string;
  created?: Date;
}

export interface ResetPasswordRequestData {
  email: string;
}

export interface LoginUserData {
  email: string;
  password: string;
  rememberMe: boolean;
}

export type UserTypes = 'anonymous' | 'user';

export interface CreateUserData {
  name?: string; // TODO make non optional
  newsletter?: boolean;
  email: string;
  hash: string;
  userType: UserTypes;
  verified?: boolean;
  verificationToken?: string;
  isSuperAdmin?: boolean;
  avatar?: string;
}

export interface SignupUserData {
  email: string;
  password: string;
  name: string;
  newsletter: boolean;
  token: string;
}

export interface UpdateProfile {
  name?: string;
  workRole?: string;
  newsletter?: boolean;
  lastNewFeature?: string;
  hideNewFeatureNotifications?: boolean;
  muteAppNotifications?: boolean;
  hideEducationalContent?: boolean;
  avatar?: string;
  color?: string;
}

export interface InitEmail {
  email: string;
}

export interface ChangeEmail {
  email: string;
  password: string;
}

export interface ChangePassword {
  password: string;
  newPassword: string;
  repeatNewPassword: string;
}

export enum ProjectShareTypes {
  VISIBLE_TO_MEMBERS = 0,
  RESTRICTED_TO_SPECIFIC_PEOPLE = 1,
}

export type Separator = 'separator';

export interface PermissionMenuItem {
  label: string;
  value: any;
}

export type PermissionMenuOption = Separator | PermissionMenuItem;

export interface ContributorRequest {
  email: string;
  permission: Permissions;
}

export interface Collaborator {
  _id: string;
  name: string;
  verified: boolean;
  role?: UserRole;
}

export interface RevisionListData {
  createdAt: Date;
  author: {
    _id: string;
    name: string;
  };
}

export interface AnonymousUserInfo {
  countryCode?: string;
}

export interface SuccessResponse {
  success: boolean;
}

export interface BaseUserInfo {
  _id: string;
  avatar?: string;
  email: string;
  name: string;
}

export interface CursorPosition {
  row: number;
  column: number;
}

export interface EditorSelection {
  start: CursorPosition;
  end: CursorPosition;
}

export interface EditorCollaboratorState {
  cursorPositions: CursorPosition[];
  selections: EditorSelection[];
}

export type EditorStateMessage = EditorCollaboratorState;

export interface OtherEditorStateMessage extends EditorStateMessage {
  id: string;
}

export type CollaboratorSession = OtherEditorStateMessage & {
  userInfo: BaseUserInfo;
};

export interface CollaboratorsMessage {
  sessions: CollaboratorSession[];
}

export interface UserJoinedMessage {
  id: string;
  userInfo: BaseUserInfo;
}

export interface CustomerBillingInfo {
  name: string; // TODO: why is this non-nullable if we don't get the name from stripe ?
  email?: string;
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
}

export interface SubscriptionInvoiceSimulation {
  customerId?: string;
  subscriptionId?: string;
  items?: UpdateBillingItem[];
  date?: number;
  proration_behavior?: 'always_invoice' | 'create_prorations' | 'none' | undefined;
  subscription_start_date?: number;
}

export interface InvoiceSummary {
  name: string;
  email: string;
  amount_due: number;
  amount_paid: number;
  created: number;
  due_date: number;
}

export interface InvoiceData {
  stripeId: string;
  number: string;
  date: number;
  amount: number;
  currency: 'usd';
  hostedUrl: string;
  pdfUrl: string;
}
export type SubscriptionType = 'individual' | 'team';
export const SUBSCRIPTION_TYPES: SubscriptionType[] = ['individual', 'team'];
export type BillingPlanName = 'team' | 'pro';
export type BillingInterval = 'day' | 'week' | 'month' | 'year';
export const BILLING_PLAN_TYPES: BillingPlanName[] = ['team', 'pro'];
export const BILLING_INTERVALS: BillingInterval[] = ['month', 'year'];

export enum LastPaymentStatuses {
  SUCCEEDED = 'succeeded',
  PENDING = 'pending',
  FAILED = 'failed',
}

// Invoices can have two states - Draft and Open.
// When in Draft, an invoice can transition from Draft -> Deleted.
// When in Open state, an invoice can transition to Paid, Uncollectible or Void.
export enum InvoiceStatuses {
  // Starting status for all invoices; at this point, the invoice can still be edited.
  DRAFT = 'draft',
  // The invoice has been finalized, and is now awaiting payment from the customer. It can no longer be edited.
  OPEN = 'open',
  // Invoice was paid
  PAID = 'paid',
  // It’s unlikely that this invoice will be paid, and it should be treated as bad debt in reports.
  UNCOLLECTIBLE = 'uncollectible',
  // The invoice was a mistake, and should be canceled.
  VOID = 'void',
}

export interface BillingItem {
  id: string;
  price: string;
  product: string;
  interval: string;
  quantity: number;
}

export interface UpdateBillingItem {
  id?: string;
  price: string;
  quantity: number;
}

export interface CreateBillingItem {
  plan: string;
  quantity: number;
}

export interface ProductPrice {
  id: string;
  currency: string;
  amount: number;
  interval?: BillingInterval;
}

export interface CardData {
  brand: string;
  expiryMonth: number;
  expiryYear: number;
  last4: string;
}
// TODO: we're assuming a lot of the fields are non-nullable
//       when in reality a some of them are not initialized in some cases
export interface BillingData {
  plan: BillingPlanName | null;
  interval: BillingInterval;
  validUntil: Date | undefined;
  trialing?: boolean;
  lastChecked: Date;
  latestInvoices?: InvoiceData[];
  lastError?: string;
  billingInfo: CustomerBillingInfo;
  stripe?: {
    customerId: string;
    subscriptionId?: string;
    items: BillingItem[];
    billingAnchor: Date;
  };
  // The payment and invoice statuses are determined by the last invoice of the subscription
  payment: {
    lastPaymentStatus: LastPaymentStatuses | null,
    invoiceStatus: InvoiceStatuses | null,
    invoiceStatusTransitions: {
      // Time when invoice was finalized (Unix Timestamp)
      finalizedAt: number,
      // Time when invoice was marked uncollectible (Unix Timestamp)
      markedUncollectibleAt: number,
      // Time when invoice was paid (Unix Timestamp)
      paidAt: number,
      // Time when invoice was voided (Unix Timestamp)
      voidedAt: number,
    },
  };
  paymentMethod: {
    card?: CardData,
  };
  expiry: {
    cancelAtPeriodEnd: boolean,
    endedAt: number, // TODO: this should be a Date
    isExpired: boolean,
  };
  cancellation?: string[]; // cancellation reasons
  pricePaid: number; // in dollars
  nextCharge?: number; // in cents
  nextChargeDate?: Date;
}

export interface BillingInformation {
  billing: BillingData | undefined;
  billingHistory: BillingData[];
}

export interface SalesData {
  industry: string;
  language: string;
}

export type UserLeftMessage = UserJoinedMessage;

export interface AppErrorMessage {
  code: number;
  message: string;
}

export interface AppErrorMessages {
  [key: string]: AppErrorMessage;
}

export interface TeamCreationDocument {
  name: string;
  invites?: Invitee[];
  projectName?: string;
  flowchartsToMove?: string[];
  slug: string;
}

export enum EntityMoveErrors {
  NOT_OWNER_OR_NO_FULL_ACCESS,
  NO_WRITE_PERMISSION_IN_TEAM,
  NO_WRITE_PERMISSION_IN_DESTINATION_PROJECT,
  INTERNAL_ERROR,
  DESTINATION_DOES_NOT_EXIST,
  CANNOT_MOVE_UNDER_ITSELF,
  DESCENDANT_ACCESS_ERROR,
}

export interface ErrorPayload {
  /** Machine readable code that can be used to identify an error, e.g. "WRONG_PASSWORD" */
  name: string;
  /** HTTP status code associated with this error */
  statusCode: number;
  /** Human readable error message. Object can be checked for this property's existence. */
  message: string;
}

export type EntityMoveErrorsType = keyof typeof EntityMoveErrors;
export interface EntityMoveResponseData {
  failure: { id: string; error: EntityMoveErrorsType; }[];
  success: string[];
}
export interface RenderAngularInput {
  locals?: object;
  content: string;
  url: string;
}

export interface ClientProductInfo {
  name: string;
  defaultServerUrl: string;
  defaultAppRoute: string;
  home: {
    route: string;
    name: string;
  };
  productId: string;
  showOnlyDocuments: boolean;
  entities: {
    [type: string]: {
      defaultName: string;
      path: string;
    };
  };
  discordInviteLinks?: {
    sharingModal?: string,
    drawingBuddiesPage?: string,
  };
  storageReadMoreLink?: string;
  supportEmailAddress: string;
  salesEmailAddress: string;
  contactUsLink: string;
  feedbackLink: string;
  educationLink?: string;
  enterpriseSalesLink?: string;
}

export interface ResourcesLinks {
  url: string;
  title: string;
  description: string;
  published?: Date | string;
  svg?: string;
  new?: boolean;
}

export interface ResourcesLinksResponse {
  total: number;
  data: ResourcesLinks[];
}

export interface ServerProductInfo {
  name: string;
  url: string;
  title: string;
  titleForPrivateTeams: string;
  knownReferralTags?: string[];
  email: {
    productLink: string;
    from: string;
    support: string;
  };
  description: string;
  apiDescription?: string;
  apiSalesLink?: string;
  descriptionForPrivateTeams: string;
  maskIconColor: string;
  themeColor: string;
  modules: string[];
  resourcesLinks: ResourcesLinks[];
  socialAccounts: {
    twitter?: string;
    instagram?: string;
    facebook?: string;
    linkedin?: string;
    youtube?: string;
  };
  recommendedBrowsersLink?: string;
  statusPageUrl: string;
  allowPropsForInitialEntity?: string[];
}

export interface ShareTypeOption {
  label: string;
  value: any;
  icon?: SvgIconDefinition;
}

export interface SharedObjectData {
  entity?: Pick<EntityData, '_id' | 'name' | 'type' | 'folder' | 'project' | 'team' | 'shortId'>;
  project?: Pick<ProjectData, '_id' | 'name'>;
}

export interface Invitable {
  team?: ID;
  project?: ID;
  entity?: ID;
}
export interface InvitableDocuments {
  team?: TeamDocument | null;
  project?: ProjectDocument | null;
  entity?: EntityDocument | null;
}

export type UnsetUserData = { $unset: { [key in keyof Partial<IUserDocument>]: 1; } };
export type UnsetTeamData = { $unset?: { [key in keyof Partial<ITeamDocument>]: 1; } };

export interface CheckoutInfo {
  checkoutId: string;
  checkoutUrl: string | null;
}

export interface SamlData {
  exp: number;
  nbf: number;
  unique_name: string;
  email?: string;
  tid: string;
  appid: string;
}

export interface JWTPayload {
  _id: string;
  name: string;
  userType: 'user' | 'anonymous';
}

export interface JWTDecoded extends JWTPayload {
  exp: number;
}

export interface StorageUsageData {
  used: number;
  limit: number;
}

export interface Job<T extends (...args: any[]) => any = () => void> {
  run(job: BullMqJob<Parameters<T>, Awaited<ReturnType<T>>>): Promise<Awaited<ReturnType<T>>>;
}

export interface SearchResponse {
  records: FlowchartData[] | UserData[] | TeamMember[] | ProjectData[] | EntityData[] | undefined;
  total: number;
  isLoading?: boolean;
}

export interface SearchResponseDocuments {
  records: EntityData[] | undefined;
  total: number | undefined;
  isLoading: boolean;
}

export interface SearchResponseUsers {
  records: UserData[] | undefined;
  total: number | undefined;
  isLoading: boolean;
}

export interface SearchResponseProjects {
  records: ProjectData[] | undefined;
  total: number | undefined;
  isLoading: boolean;
}

export type EmailChangeType = 'initial' | 'change';

export interface CreateTeam {
  name: string;
  slug: string;
  avatar?: string;
  billing?: BillingData;
  isPublic?: boolean;
  tags?: string[];
}

export interface UpdateTeamMember {
  roles: string[];
}

export interface JoinTeam {
  inviteId: string;
}

export interface TeamInvite {
  isActive: boolean;
  isShared: boolean;
  token: string;
  team: {
    _id: string;
    name: string;
    avatar: string;
    description: string;
    slug: string;
    membersCount: number;
    onlineMembersCount: number;
    organizationName?: string;
    requireSamlAuthentication?: boolean;
  };
}

export interface TeamInviteToken {
  token: string;
  isActive: boolean;
  isShared: boolean;
}

export interface UpdateTeamInviteToken {
  isActive?: boolean;
  isShared?: boolean;
}

export interface FolderInfo {
  _id: string;
  parentId: string | null;
  name: string;
  children: FolderInfo[];
}

export interface ProjectTreeItem {
  _id: string;
  name: string;
  folderCount: number;
  order?: number;
}

export interface TeamTreeItem {
  _id: string;
  name: string;
  slug: string;
  avatar: string;
  projects: ProjectTreeItem[];
}

export const enum TeamFlags {
  None = 0,
  SignupFilterEnabled = (1 << 1),
}

export type HiddenProjectsDescriptions = { ids: { [key: string]: boolean; } };
