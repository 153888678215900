import { ITool, IToolEditor, IToolModel, ToolId } from '../interfaces';
import { BaseShapeTool, DrawShape } from './baseShapeTool';
import { faCircle } from '../icons';

export class EllipseTool extends BaseShapeTool implements ITool {
  id = ToolId.Ellipse;
  name = 'Ellipse';
  description = 'Create solid or outlined ellipses';
  learnMore = 'https://help.magma.com/en/articles/6871499-ellipse-shape';
  video = { url: '/assets/videos/ellipse.mp4', width: 374, height: 210 };
  icon = faCircle;
  drawShape = DrawShape.Ellipse;
  constructor(public editor: IToolEditor, public model: IToolModel) {
    super(editor, model);
  }
}
