import { Directive, OnInit, ElementRef } from '@angular/core';
import { replaceKeys } from '../../../common/utils';
import { isMac } from '../../../common/userAgentUtils';

@Directive({ selector: 'kbd.sys' })
export class Kbd implements OnInit {
  constructor(private element: ElementRef<HTMLElement>) {
  }
  ngOnInit() {
    if (isMac) {
      const kbd = this.element.nativeElement;
      kbd.innerHTML = replaceKeys(kbd.innerHTML);
    }
  }
}
