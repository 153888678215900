function boxesForGauss(sigma: number, n: number)  // standard deviation, number of boxes
{
  const wIdeal = Math.sqrt((12 * sigma * sigma / n) + 1);  // Ideal averaging filter width 
  let wl = Math.floor(wIdeal);
  if (wl % 2 == 0) wl--;
  const wu = wl + 2;
  const mIdeal = (12 * sigma * sigma - n * wl * wl - 4 * n * wl - 3 * n) / (-4 * wl - 4);
  const m = Math.round(mIdeal);
  const sizes = [];
  for (let index = 0; index < n; index++) sizes.push(index < m ? wl : wu);
  return sizes;
}
export function applyGaussianBlur(source: Uint8ClampedArray, destination: Uint8ClampedArray, width: number, height: number, radius: number) {
  const redArray = new Uint8ClampedArray(source.length / 4);
  const greenArray = new Uint8ClampedArray(source.length / 4);
  const blueArray = new Uint8ClampedArray(source.length / 4);
  const alphaArray = new Uint8ClampedArray(source.length / 4);
  const dstredArray = new Uint8ClampedArray(source.length / 4);
  const dstgreenArray = new Uint8ClampedArray(source.length / 4);
  const dstblueArray = new Uint8ClampedArray(source.length / 4);
  const dstalphaArray = new Uint8ClampedArray(source.length / 4);

  // separating red, blue, green and alpha to different arrays
  for (let index = 0, count = 0; index < source.length; index += 4, count++) {
    const alpha = source[index + 3];
    redArray[count] = source[index] * alpha / 255;
    greenArray[count] = source[index + 1] * alpha / 255;
    blueArray[count] = source[index + 2] * alpha / 255;
    alphaArray[count] = alpha;
  }

  // applying gauss blur to the diffrent color channels
  gaussBlur(redArray, dstredArray, width, height, radius / 2);
  gaussBlur(greenArray, dstgreenArray, width, height, radius / 2);
  gaussBlur(blueArray, dstblueArray, width, height, radius / 2);
  gaussBlur(alphaArray, dstalphaArray, width, height, radius / 2);

  // re-applying the colors to the destination array
  for (let index = 0, count = 0; index < source.length; index += 4, count++) {
    const alpha = alphaArray[count];
    destination[index] = redArray[count] / alpha * 255;
    destination[index + 1] = greenArray[count] / alpha * 255;
    destination[index + 2] = blueArray[count] / alpha * 255;
    destination[index + 3] = alpha;
  }
}
function gaussBlur(source: Uint8ClampedArray, target: Uint8ClampedArray, width: number, height: number, radius: number) {
  const bxs = boxesForGauss(radius, 3);
  boxBlur_4(source, target, width, height, (bxs[0] - 1) / 2);
  boxBlur_4(target, source, width, height, (bxs[1] - 1) / 2);
  boxBlur_4(source, target, width, height, (bxs[2] - 1) / 2);
}
function boxBlur_4(source: Uint8ClampedArray, target: Uint8ClampedArray, width: number, height: number, radius: number) {
  for (let index = 0; index < source.length; index++)
    target[index] = source[index];
  boxBlurH_4(target, source, width, height, radius);
  boxBlurT_4(source, target, width, height, radius);
}
function boxBlurH_4(source: Uint8ClampedArray, target: Uint8ClampedArray, width: number, height: number, r: number) {
  const iarr = 1 / (r + r + 1);
  for (let index = 0; index < height; index++) {
    let ti = index * width, li = ti, ri = ti + r;
    let fv = source[ti], lv = source[ti + width - 1], val = (r + 1) * fv;
    for (let j = 0; j < r; j++) val += source[ti + j];
    for (let j = 0; j <= r; j++) {
      val += source[ri++] - fv;
      target[ti++] = Math.round(val * iarr);
    }
    for (let j = r + 1; j < width - r; j++) {
      val += source[ri++] - source[li++];
      target[ti++] = Math.round(val * iarr);
    }
    for (let j = width - r; j < width; j++) {
      val += lv - source[li++];
      target[ti++] = Math.round(val * iarr);
    }
  }
}
function boxBlurT_4(source: Uint8ClampedArray, target: Uint8ClampedArray, width: number, height: number, r: number) {
  const iarr = 1 / (r + r + 1);
  for (let index = 0; index < width; index++) {
    let ti = index, li = ti, ri = ti + r * width;
    let fv = source[ti], lv = source[ti + width * (height - 1)], val = (r + 1) * fv;
    for (let j = 0; j < r; j++) val += source[ti + j * width];
    for (let j = 0; j <= r; j++) {
      val += source[ri] - fv;
      target[ti] = Math.round(val * iarr);
      ri += width;
      ti += width;
    }
    for (let j = r + 1; j < height - r; j++) {
      val += source[ri] - source[li];
      target[ti] = Math.round(val * iarr);
      li += width;
      ri += width;
      ti += width;
    }
    for (let j = height - r; j < height; j++) {
      val += lv - source[li];
      target[ti] = Math.round(val * iarr);
      li += width;
      ti += width;
    }
  }
}