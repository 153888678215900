import { Drawing, DrawOptions, Layer, Rect, TextLayer, ToolSurface } from '../interfaces';
import { getLayerName, isTextLayer } from '../layer';
import { createTextarea } from './textarea';
import { FONTS, hasFontsLoaded, requireFontsCheck } from './fonts';
import { Mandatory } from '../typescript-utils';
import { toolIncompatibleWithTextLayers } from '../update';
import { cloneRect } from '../rect';
import { Editor } from '../../services/editor';
import { rasterizeLayer } from '../../services/layerActions';
import { fixedScale, safeFloatAny } from '../toolUtils';
import { createTransform } from '../toolSurface';
import { copyMat2d, createMat2d } from '../mat2d';

type TextLayerWithCachedTextarea = Mandatory<TextLayer, 'textarea'>;

export function shouldCacheTextareaInLayer(layer: Layer): layer is TextLayer {
  return isTextLayer(layer) && !layer.textarea && layer.fontsLoaded && !!getUsedFonts(layer).length;
}

export function cacheTextareaInLayer(layer: TextLayer): asserts layer is TextLayerWithCachedTextarea {
  if (!layer.fontsLoaded) {
    throw new Error('Unable to cache textarea. Fonts are still loading');
  }

  if (layer.textData) {
    const textarea = createTextarea(FONTS, layer.textData);
    if (!textarea) {
      DEVELOPMENT && console.error('Tried to cache textarea but failed', layer);
      reportError(`Tried to cache textarea but failed (${layer.id} / "${getLayerName(layer)}")`);
    }
    layer.textarea = textarea;
  } else {
    layer.textarea = undefined;
  }
}

export function isNonDirtyTextLayer(layer: Layer | undefined) {
  return isTextLayer(layer) && !layer.textData.dirty;
}

export function canDrawTextLayer(layer: TextLayer): layer is TextLayerWithCachedTextarea {
  if (!(layer.visible || layer.visibleLocally)) return false;
  if (!layer.fontsLoaded) {
    requireFontsCheck();
    return false;
  }
  if (layer.textarea) return true;
  if (!hasFontsLoaded(layer)) {
    requireFontsCheck();
    return false;
  }
  cacheTextareaInLayer(layer);
  return !!layer.textarea;
}

export function shouldDrawTextarea(layer: Layer | undefined, options: DrawOptions): layer is TextLayerWithCachedTextarea {
  if (!isTextLayer(layer)) return false;
  if (!options.selectedTool) return false;
  if (toolIncompatibleWithTextLayers(options.selectedTool.id)) return false;
  return canDrawTextLayer(layer);
}

export function textLayerToTextBox(layer: TextLayer, rect: 'rect' | 'textureRect' | 'untransformedTextureRect'): Rect {
  if (!canDrawTextLayer(layer)) throw new Error('Unable to get text box from text layer. Fonts are still loading');
  if (!layer.textarea) cacheTextareaInLayer(layer);
  if (layer.invalidateCanvas) layer.textarea.write(layer.textarea.text);
  switch (rect) {
    case 'rect': return cloneRect(layer.textarea!.rect);
    case 'textureRect': return cloneRect(layer.textarea!.textureRect);
    case 'untransformedTextureRect': return cloneRect(layer.textarea!.getUntransformedTextureRect());
  }
}

let modalUp = false; // this prevents opening multiple modals during move and just allows single modal instance
export async function invokeRasterizeFlow(editor: Editor, layer: TextLayer, rasterizeManually = false) {
  if (modalUp) return false;

  try {
    const untilModalResolved = editor.model.modals.invokeRasterizeFlow(layer);
    modalUp = true;
    editor.apply(() => { });

    const confirmedRasterization = await untilModalResolved;
    modalUp = false;
    if (!rasterizeManually && confirmedRasterization) rasterizeLayer(editor, layer);

    return confirmedRasterization;
  } catch (e) {
    DEVELOPMENT && console.error(e);
    if (editor.model.modals.isOpen('rasterizeFlow')) {
      editor.model.modals.closeByName('rasterizeFlow');
    }
    modalUp = false;
    return false;
  }
}

export const getUsedFonts = ({ textData }: TextLayer, list: string[] = []): string[] => {
  if (textData.defaultFontFamily && !list.includes(textData.defaultFontFamily)) list.push(textData.defaultFontFamily);
  for (const cF of textData.characterFormattings) {
    if (cF.fontFamily && !list.includes(cF.fontFamily)) {
      list.push(cF.fontFamily);
    }
  }
  return list;
};

export const getFontFamilyNamesInDrawing = (drawing: Drawing): string[] => {
  const list: string[] = [];
  for (const layer of drawing.layers) {
    if (isTextLayer(layer)) {
      getUsedFonts(layer, list);
    }
  }
  return list;
};

export function setTextLayerTransform(layer: TextLayer, surface: ToolSurface) {
  const { translateX, translateY, scaleX, scaleY, rotate } = surface;
  const tx = safeFloatAny(translateX);
  const ty = safeFloatAny(translateY);
  const sx = fixedScale(safeFloatAny(scaleX));
  const sy = fixedScale(safeFloatAny(scaleY));
  const r = safeFloatAny(rotate);

  if (layer.textarea) {
    const transform = layer.textarea.transform;
    createTransform(transform, tx, ty, r, sx, sy);
    copyMat2d(layer.textarea.textareaFormatting.transform, transform);
    layer.textarea.bounds = layer.textarea!.getBounds();
  } else {
    const transform = createMat2d();
    createTransform(transform, tx, ty, r, sx, sy);
    layer.textData.textareaFormatting.transform = Array.from(transform);
  }

  layer.invalidateCanvas = true;
}

export function ensureTextLayerDirty(layer: Layer | undefined) {
  if (isTextLayer(layer) && !layer.textData.dirty) {
    if (layer.textarea) layer.textarea.dirty = true;
    layer.textData.dirty = true;
  }
}
