import { ProductData } from './interfaces';

const loadBillingDataFromEnv = SERVER;

export const {
  CC_PRODUCT_MAGMA_PRO,
  CC_PRODUCT_MAGMA_PRO_PRICE_M,
  CC_PRODUCT_MAGMA_PRO_PRICE_Y,

  CC_PRODUCT_EDITOR,
  CC_PRODUCT_EDITOR_PRICE_M,
  CC_PRODUCT_EDITOR_PRICE_Y,

  CC_PRODUCT_REVIEWER,
  CC_PRODUCT_REVIEWER_PRICE_M,
  CC_PRODUCT_REVIEWER_PRICE_Y,

  CC_PRODUCT_VIEWER,
  CC_PRODUCT_VIEWER_PRICE_M,
  CC_PRODUCT_VIEWER_PRICE_Y,
} = loadBillingDataFromEnv ? process.env : require('magma/common/utils').getValueOfVariable('stripeData');


export const MAX_FREE_PRIVATE_ARTSPACE_MEMBERS = 5;

export const TEAM_PRODUCTS = new Map<string, ProductData>([
  [CC_PRODUCT_EDITOR, {
    productId: CC_PRODUCT_EDITOR,
    name: 'Editor',
    stripePriceId: { month: CC_PRODUCT_EDITOR_PRICE_M, year: CC_PRODUCT_EDITOR_PRICE_Y }
  }],
  [CC_PRODUCT_REVIEWER, {
    productId: CC_PRODUCT_REVIEWER,
    name: 'Reviewer',
    stripePriceId: { month: CC_PRODUCT_REVIEWER_PRICE_M, year: CC_PRODUCT_REVIEWER_PRICE_Y }
  }],
  [CC_PRODUCT_VIEWER, {
    productId: CC_PRODUCT_VIEWER,
    name: 'Viewer',
    stripePriceId: { month: CC_PRODUCT_VIEWER_PRICE_M, year: CC_PRODUCT_VIEWER_PRICE_Y }
  }],
]);
