import { IToolEditor, Layer, User } from './interfaces';
import type { Model } from '../services/model';
import type { Editor } from '../services/editor';
import { getLayer, setLayerOwner } from './drawing';
import { clamp } from './mathUtils';
import { removeItem } from './baseUtils';
import { redrawLayerThumb } from './layer';
import { logAction } from './actionLog';
import { userOwnsLayer } from './user';

export function sendLayerOrder(editor: IToolEditor) {
  if (editor.type === 'client') {
    const e = editor as Editor;
    e.model.reorderLayers(e.drawing.layers.map(l => l.id));
  }
}

// NOTE: always use sendLayerOrder() after calling addLayerToDrawing() for [local] calls
export function addLayerToDrawing(editor: IToolEditor, layer: Layer, index: number) {
  layer.loaded = true;
  index = clamp(index, 0, editor.drawing.layers.length);
  editor.drawing.layers.splice(index, 0, layer);

  if (editor.type === 'client') {
    const e = editor as Editor;
    setLayerOwner(layer, getLayerOwner(e.model, layer.id));
    redrawLayerThumb(layer, true);

    if (layer.owner === e.model.user && !e.model.user.activeLayer) {
      e.selectLayer(layer);
    }
  }

  editor.apply(() => { });
}

export function removeLayerFromDrawing(editor: IToolEditor, layerId: number) {
  const layer = getLayer(editor.drawing, layerId);

  if (layer) {
    if (editor.type === 'client') {
      const e = editor as Editor;

      if (layer === e.model.user.activeLayer) {
        e.selectOtherLayer(layerId);
      }
    }

    editor.renderer.releaseLayer(layer);
    removeItem(editor.drawing.layers, layer);
  } else {
    DEVELOPMENT && console.error(`missing layer for removal (layerId: ${layerId})`);
    logAction(`missing layer for removal (layerId: ${layerId})`);
  }

  editor.apply(() => { });
}

function getLayerOwner(model: Model, layerId: number): User | undefined {
  if (userOwnsLayer(model.user, layerId)) {
    return model.user;
  } else {
    return model.users.find(u => userOwnsLayer(u, layerId));
  }
}
