/* tslint:disable */

export const aiInpaintMaskShader = `uniform vec4 color1;
uniform vec4 color2;
uniform sampler2D sampler1;
uniform float time;
uniform float size;
uniform float width;
uniform float height;
varying vec4 textureCoord;
void main() {
  vec4 src = texture2D(sampler1, textureCoord.xy);
  float fmodY = mod(textureCoord.y*height, size*4.0) + time;
  float fmodResult = mod(textureCoord.x*width + fmodY/2.0, size*2.0);
  gl_FragColor = fmodResult < size ? color1 : color2;
  gl_FragColor *= src.a;
}`;

export const aiOutpaintMaskShader = `uniform vec4 color1;
uniform vec4 color2;
uniform sampler2D sampler1;
uniform float time;
uniform float size;
uniform float width;
uniform float height;
varying vec4 textureCoord;
void main() {
  vec4 src = texture2D(sampler1, textureCoord.xy);
  float fmodY = mod(textureCoord.y*height, size*4.0) + time;
  float fmodResult = mod(textureCoord.x*width + fmodY/2.0, size*2.0);
  gl_FragColor = fmodResult < size ? color1 : color2;
  float a = (1.0 - src[3]) * gl_FragColor[3];
  gl_FragColor = vec4(gl_FragColor.rgb * a, a);
}`;

export const antAiShader = `uniform float time;
uniform float size;
uniform vec4 color1;
uniform vec4 color2;
varying vec4 textureCoord;
void main() {
  float fmodResult = mod(textureCoord.x + time, size * 2.0);
  gl_FragColor = fmodResult < size ? color1 : color2;
}`;

export const antsShader = `uniform float time;
uniform float size;
varying vec4 textureCoord;
void main() {
  float fmodResult = mod(textureCoord.x + time, size * 2.0);
  gl_FragColor = fmodResult < size ? vec4(1, 1, 1, 1) : vec4(0, 0, 0, 1);
}`;

export const basicShader = `uniform sampler2D sampler1;
varying vec4 textureCoord;
void main() {
  vec4 color = texture2D(sampler1, textureCoord.xy);
  gl_FragColor = color;
}`;

export const basicColorShader = `uniform sampler2D sampler1;
uniform vec4 color;
varying vec4 textureCoord;
void main() {
  vec4 src = texture2D(sampler1, textureCoord.xy);
  gl_FragColor = src * color;
}`;

export const basicPremultiplyShader = `uniform sampler2D sampler1;
varying vec4 textureCoord;
void main() {
  vec4 color = texture2D(sampler1, textureCoord.xy);
  color.rgb *= color.a;
  gl_FragColor = color;
}`;

export const brightnessContrastShader = `precision highp float;
uniform sampler2D sampler1;
uniform vec2 size;
uniform float brightness;
uniform float contrast;
vec3 ContrastSaturationBrightness(vec3 color, float brt, float sat, float con)
{
  const float AvgLumR = 0.5;
  const float AvgLumG = 0.5;
  const float AvgLumB = 0.5;
  const vec3 LumCoeff = vec3(0.2125, 0.7154, 0.0721);
  vec3 AvgLumin = vec3(AvgLumR, AvgLumG, AvgLumB);
  vec3 brtColor = color * brt;
  vec3 intensity = vec3(dot(brtColor, LumCoeff));
  vec3 satColor = mix(intensity, brtColor, sat);
  vec3 conColor = mix(AvgLumin, satColor, con);
  return conColor;
}
vec3 convertRgbToLinearRgb(vec3 rgb)
{
    return pow(rgb, vec3(2.2));
}
vec3 convertLinearRgbToRgb(vec3 linear_rgb)
{
    return pow(linear_rgb, vec3(1.0 / 2.2));
}
vec3 RGBtoHSP( vec3 rgb) {
  vec3 hsp = vec3(0.0);
  vec3 PerceivedRGB = vec3(.299, .587, 0.114);
  hsp.z = sqrt(rgb.r * rgb.r *PerceivedRGB.r+ rgb.g * rgb.g * PerceivedRGB.g+ rgb.b * rgb.b * PerceivedRGB.b);
  if (rgb.r == rgb.g && rgb.r == rgb.b) { hsp.x = 0.0; hsp.y = 0.0; return hsp; }
  if (rgb.r >= rgb.g && rgb.r >= rgb.b) {   
    if (rgb.b >= rgb.g ) {
      hsp.x = 6.0 / 6.0 - 1.0 / 6.0 * (rgb.b - rgb.g) / (rgb.r - rgb.g); hsp.y = 1.0 - rgb.g / rgb.r;
    }
    else {
      hsp.x = 0.0 / 6.0 + 1.0 / 6.0 * (rgb.g - rgb.b) / (rgb.r - rgb.b); hsp.y =1.0 - rgb.b / rgb.r;
    }
  } else if (rgb.g >= rgb.r && rgb.g >= rgb.b) {   
    if (rgb.r >= rgb.b) {
      hsp.x= 2.0 / 6.0 - 1.0 / 6.0 * (rgb.r- rgb.b) / (rgb.g - rgb.b); hsp.y = 1.0 - rgb.b / rgb.g;
    }
    else {
      hsp.x= 2.0 / 6.0 + 1.0 / 6.0 * (rgb.b - rgb.r) / (rgb.g - rgb.r); hsp.y = 1.0 - rgb.r / rgb.g;
    }
  }
  else {   
    if (rgb.g >= rgb.r) {
      hsp.x= 4.0 / 6.0 - 1.0 / 6.0 * (rgb.g - rgb.r) / (rgb.b - rgb.r); hsp.y = 1.0 - rgb.r / rgb.b; }
    else {
      hsp.x= 4.0 / 6.0 + 1.0 / 6.0 * (rgb.r - rgb.g) / (rgb.b - rgb.g); hsp.y = 1.0 - rgb.g / rgb.b; }
  }
  return hsp;
}
vec3 HSPtoRGB(vec3 hsp ) {
  float  part, minOverMax= 1.0 - hsp.y ;
  vec3 rgb = vec3(0.0);
  vec3 PerceivedRGB = vec3(.299, .587, 0.114);
  if (minOverMax > 0.0 ) {
    if ( hsp.x  < 1.0 / 6.0) {   
      hsp.x = 6.*( hsp.x -0./6.); part=1.+hsp.x *(1./minOverMax-1.);
      rgb.b = hsp.z /sqrt(PerceivedRGB.r / minOverMax / minOverMax + PerceivedRGB.g  * part * part + PerceivedRGB.b );
      rgb.r =(rgb.b )/minOverMax; rgb.g =(rgb.b )+hsp.x *((rgb.r )-(rgb.b ));
    }
    else if ( hsp.x <2./6.) {   
      hsp.x = 6.*(-hsp.x +2./6.); part=1.+hsp.x *(1./minOverMax-1.);
      rgb.b =hsp.z/sqrt(PerceivedRGB.g /minOverMax/minOverMax+PerceivedRGB.r *part*part+PerceivedRGB.b );
      rgb.g =(rgb.b )/minOverMax; rgb.r =(rgb.b )+hsp.x *((rgb.g )-(rgb.b ));
    }
    else if ( hsp.x <3./6.) {   
      hsp.x = 6.*( hsp.x -2./6.); part=1.+hsp.x *(1./minOverMax-1.);
      rgb.r =hsp.z/sqrt(PerceivedRGB.g /minOverMax/minOverMax+PerceivedRGB.b *part*part+PerceivedRGB.r );
      rgb.g =(rgb.r )/minOverMax; rgb.b =(rgb.r )+hsp.x *((rgb.g )-(rgb.r ));
    }
    else if ( hsp.x <4./6.) {   
      hsp.x = 6.*(-hsp.x +4./6.); part=1.+hsp.x *(1./minOverMax-1.);
      rgb.r =hsp.z/sqrt(PerceivedRGB.b /minOverMax/minOverMax+PerceivedRGB.g *part*part+PerceivedRGB.r );
      rgb.b =(rgb.r )/minOverMax; rgb.g =(rgb.r )+hsp.x *((rgb.b )-(rgb.r ));
    }
    else if ( hsp.x <5./6.) {   
      hsp.x = 6.*( hsp.x -4./6.); part=1.+hsp.x *(1./minOverMax-1.);
      rgb.g =hsp.z/sqrt(PerceivedRGB.b /minOverMax/minOverMax+PerceivedRGB.r *part*part+PerceivedRGB.g );
      rgb.b =(rgb.g )/minOverMax; rgb.r =(rgb.g )+hsp.x *((rgb.b )-(rgb.g ));
    }
    else {   
      hsp.x = 6.*(-hsp.x +6./6.); part=1.+hsp.x *(1./minOverMax-1.);
      rgb.g =hsp.z/sqrt(PerceivedRGB.r /minOverMax/minOverMax+PerceivedRGB.b *part*part+PerceivedRGB.g );
      rgb.r =(rgb.g )/minOverMax; rgb.b =(rgb.g )+hsp.x *((rgb.r )-(rgb.g ));
    }
  }
  else {
    if ( hsp.x <1./6.) {   
      hsp.x = 6.*( hsp.x -0./6.); rgb.r =sqrt(hsp.z * hsp.z/(PerceivedRGB.r +PerceivedRGB.g *hsp.x *hsp.x )); rgb.g =(rgb.r )*hsp.x ; rgb.b =0.;
    }
    else if ( hsp.x <2./6.) {   
      hsp.x = 6.*(-hsp.x +2./6.); rgb.g =sqrt(hsp.z * hsp.z/(PerceivedRGB.g +PerceivedRGB.r *hsp.x *hsp.x )); rgb.r =(rgb.g )*hsp.x ; rgb.b =0.;
    }
    else if ( hsp.x <3./6.) {   
      hsp.x = 6.*( hsp.x -2./6.); rgb.g =sqrt(hsp.z * hsp.z/(PerceivedRGB.g +PerceivedRGB.b *hsp.x *hsp.x )); rgb.b =(rgb.g )*hsp.x ; rgb.r =0.;
    }
    else if ( hsp.x <4./6.) {   
      hsp.x = 6.*(-hsp.x +4./6.); rgb.b =sqrt(hsp.z * hsp.z/(PerceivedRGB.b +PerceivedRGB.g *hsp.x *hsp.x )); rgb.g =(rgb.b )*hsp.x ; rgb.r =0.;
    }
    else if ( hsp.x <5./6.) {   
      hsp.x = 6.*( hsp.x -4./6.); rgb.b =sqrt(hsp.z * hsp.z/(PerceivedRGB.b +PerceivedRGB.r *hsp.x *hsp.x )); rgb.r =(rgb.b )*hsp.x ; rgb.g =0.;
    }
    else {   
      hsp.x = 6.*(-hsp.x +6./6.); rgb.r =sqrt(hsp.z * hsp.z/(PerceivedRGB.r +PerceivedRGB.b *hsp.x *hsp.x )); rgb.b =(rgb.r )*hsp.x ; rgb.g =0.;
    }
  }
  return (rgb);
}
mat4 contrastMatrix( float contrast )
{
  float t = ( 1.0 - contrast ) / 2.0;
    return mat4( contrast, 0, 0, 0,
                 0, contrast, 0, 0,
                 0, 0, contrast, 0,
                 t, t, t, 1 );
}
void main() {
  vec4 color = texture2D(sampler1, (gl_FragCoord.xy ) /size.xy);
  if (color.a == 0.0) {
    gl_FragColor = color;
    return;
  }
  vec3 finalColor = color.rgb / color.a;
  float average = 127.0 / 255.0;
  float percentage = contrast / 100.0;
  if (percentage > 0.0) {
    finalColor.r = average + (finalColor.r  - average) * (1.0 / (1.0 - percentage));
    finalColor.g = average + (finalColor.g  - average) * (1.0 / (1.0 - percentage));
    finalColor.b = average + (finalColor.b  - average) * (1.0 / (1.0 - percentage));
  } else {
    finalColor.r = average + (finalColor.r  - average) * (1.0 + percentage);
    finalColor.g = average + (finalColor.g  - average) * (1.0 + percentage);
    finalColor.b = average + (finalColor.b  - average) * (1.0 + percentage);
  }
  finalColor.r += brightness / 255.0;
  finalColor.g += brightness / 255.0;
  finalColor.b += brightness / 255.0;
  finalColor = clamp(finalColor * color.a, 0.0, color.a);
  gl_FragColor = vec4(finalColor.rgb, color.a);
}`;

export const checkerShader = `uniform float checkerSize;
uniform float scale;
uniform vec2 size;
varying vec4 textureCoord;
vec4 checkerAt(vec2 coord) {
  float checkerScale = scale / checkerSize;
  vec2 scaledCoord = coord * size * checkerScale;
  vec4 light = vec4(1, 1, 1, 1);
  vec4 dark = vec4(0.81, 0.81, 0.81, 1);
  vec2 blend = 1.0 - clamp((1.0 - fract(scaledCoord)) * checkerSize, 0.0, 1.0);
  vec4 a;
  vec4 b;
  highp float fmodResult = mod(floor(scaledCoord.x) + floor(scaledCoord.y), 2.0);
  if (fmodResult < 1.0) {
    a = light;
    b = dark;
  } else {
    a = dark;
    b = light;
  }
  vec4 color = mix(mix(a, b, blend.x), mix(b, a, blend.x), blend.y);
  return color;
}
void main() {
  vec2 texCoord = textureCoord.xy;
  vec2 clamped = clamp(texCoord, 0.0, 1.0);
  vec4 color = checkerAt(clamped);
  if (texCoord != clamped) {
    vec2 a = vec2(1.0) - abs(texCoord - clamped) / ((1.0 / scale) / size);
    color.a *= min(a.x, a.y);
  }
  gl_FragColor = color;
}`;

export const circleShader = `varying vec4 vColor;
varying vec4 textureCoord;
void main() {
  float radius = textureCoord.z;
  float dist = length(textureCoord.xy);
  float distFromEdge = dist - radius;
  float alpha = clamp(0.5 - distFromEdge, 0.0, 1.0);
  #ifdef OPACITY
  float result = textureCoord.w * alpha;
  gl_FragColor = vec4(result);
  #else
  vec4 result = vColor * alpha; 
  gl_FragColor = result;
  #endif
}`;

export const circleOutlineShader = `uniform float lineWidth;
varying vec4 vColor;
varying vec4 textureCoord;
void main() {
  float oneByPixelSize = textureCoord.z;
  float texCoordMultiplier = textureCoord.w;
  vec2 t = (textureCoord.xy - 0.5) * 2.0 * texCoordMultiplier;
  float halfWidth = lineWidth * 0.5 + 0.5;
  float distFromCenter = sqrt(dot(t, t));
  float distFromEdge = abs(distFromCenter - 1.0);
  float alpha = clamp(halfWidth - (distFromEdge * oneByPixelSize), 0.0, 1.0);
  gl_FragColor = vColor * alpha; 
}`;

export const clippingShader = `uniform sampler2D sampler1; 
uniform sampler2D sampler2; 
uniform sampler2D sampler3; 
varying vec4 textureCoord;
uniform float maskOpacity;
void main() {
  vec4 src = texture2D(sampler1, textureCoord.xy);
  vec4 dst = texture2D(sampler2, textureCoord.xy);
  vec4 mask = texture2D(sampler3, textureCoord.xy);
  float a = mask.a * maskOpacity;
  if (src.a > 0.0) src.rgb *= a / src.a;
  src.a = a;
  gl_FragColor = src + (1.0 - src.a) * dst;
}`;

export const curvesShader = `precision highp float;
uniform sampler2D sampler1;
uniform sampler2D sampler2;
uniform vec2 size;
uniform float activeChannel;
vec4 unpremultiplyAlpha(vec4 premultColor) {
    if (premultColor.a == 0.0) {
        return vec4(0.0, 0.0, 0.0, 0.0);
    } else {
        return vec4(premultColor.rgb / premultColor.a, premultColor.a);
    }
}
vec4 premultiplyAlpha(vec4 color) {
    return vec4(color.rgb * color.a, color.a);
}
float getMappedValue(float inputValue, float channel) {
    vec4 color = texture2D(sampler2, vec2(inputValue, 0.0));
    float outputValue = color.a;
    if (channel == 1.0) outputValue = color.r;
    else if (channel == 2.0) outputValue = color.g;
    else if (channel == 3.0) outputValue = color.b;
    return outputValue;
}
void main() {
    vec4 color = texture2D(sampler1, (gl_FragCoord.xy ) /size.xy);
    vec4 unpremultiplyColor = unpremultiplyAlpha(color);
    unpremultiplyColor.r = getMappedValue(unpremultiplyColor.r, 0.0);
    unpremultiplyColor.g = getMappedValue(unpremultiplyColor.g, 0.0);
    unpremultiplyColor.b = getMappedValue(unpremultiplyColor.b, 0.0);
    unpremultiplyColor.r = getMappedValue(unpremultiplyColor.r, 1.0);
    unpremultiplyColor.g = getMappedValue(unpremultiplyColor.g, 2.0);
    unpremultiplyColor.b = getMappedValue(unpremultiplyColor.b, 3.0);
    gl_FragColor = premultiplyAlpha(unpremultiplyColor);
}`;

export const drawingShader = `uniform sampler2D sampler1;
uniform vec2 size;
uniform vec2 maxCoord;
uniform float scale;
uniform float pixelated;
varying vec4 textureCoord;
vec4 checkerAt(vec2 coord) {
  float checkerSize = 8.0;
  float checkerScale = scale / checkerSize;
  vec2 scaledCoord = coord * size * checkerScale;
  vec4 light = vec4(1, 1, 1, 1);
  vec4 dark = vec4(0.81, 0.81, 0.81, 1);
  vec2 blend = 1.0 - clamp((1.0 - fract(scaledCoord)) * checkerSize, 0.0, 1.0);
  vec4 a, b;
  highp float fmodResult = mod(floor(scaledCoord.x) + floor(scaledCoord.y), 2.0);
  if (fmodResult < 1.0) {
    a = light;
    b = dark;
  } else {
    a = dark;
    b = light;
  }
  return mix(mix(a, b, blend.x), mix(b, a, blend.x), blend.y);
}
void main() {
  vec2 texCoord = textureCoord.xy;
  vec2 clamped = clamp(texCoord, vec2(0.0), maxCoord);
  vec2 sampleCoords = clamped;
  if (pixelated == 1.0) {
    vec2 pixel = clamped * size;
    vec2 uv = floor(pixel) + 0.5;
    uv += 1.0 - clamp((1.0 - fract(pixel)) * scale, 0.0, 1.0);
    sampleCoords = uv / size;
  }
  vec4 color = texture2D(sampler1, sampleCoords);
  if (color.a < 1.0) {
    vec4 checker = checkerAt(clamped);
    color = vec4(color.rgb + (1.0 - color.a) * checker.rgb, 1.0);
  }
  if (texCoord != clamped) {
    vec2 a = vec2(1.0) - abs(texCoord - clamped) / ((1.0 / scale) / size);
    color *= min(a.x, a.y);
  }
#ifdef GRAYSCALE
  color.rgb = vec3(dot(color.rgb, vec3(0.299, 0.587, 0.114)));
#endif
  gl_FragColor = color;
}`;

export const ellipseShader = `uniform vec2 radius;
uniform vec2 oneByRadiusSq;
varying vec4 vColor;
varying vec4 textureCoord;
#define VAR(INDEX, OFFSET) \
  xs[INDEX] = x + OFFSET;\
  xs[INDEX] = xs[INDEX] * xs[INDEX] * aa;\
  ys[INDEX] = y + OFFSET;\
  ys[INDEX] = ys[INDEX] * ys[INDEX] * bb;
void main() {
  vec2 z = textureCoord.xy;
  float x = z.x;
  float y = z.y;
  float aa = oneByRadiusSq.x;
  float bb = oneByRadiusSq.y;
  float xs[16], ys[16];
  VAR(0, -0.46875);
  VAR(15, 0.46875);
  float p0_0 = xs[0] + ys[0];
  float p0_15 = xs[0] + ys[15];
  float p15_0 = xs[15] + ys[0];
  float p15_15 = xs[15] + ys[15];
  if (p0_0 < 1.0 && p0_15 < 1.0 && p15_0 < 1.0 && p15_15 < 1.0) {
    gl_FragColor = vColor * 1.0;
    return;
  }
  if (radius.x > 10.0 && radius.y > 10.0 && p0_0 > 1.0 && p0_15 > 1.0 && p15_0 > 1.0 && p15_15 > 1.0) {
    gl_FragColor = vec4(0);
    return;
  }
  VAR(1, -0.40625);
  VAR(2, -0.34375);
  VAR(3, -0.28125);
  VAR(4, -0.21875);
  VAR(5, -0.15625);
  VAR(6, -0.09375);
  VAR(7, -0.03125);
  VAR(8, 0.03125);
  VAR(9, 0.09375);
  VAR(10, 0.15625);
  VAR(11, 0.21875);
  VAR(12, 0.28125);
  VAR(13, 0.34375);
  VAR(14, 0.40625);
  float opacity = 0.0;
  for (int iy = 0; iy < 16; iy++) {
    for (int ix = 0; ix < 16; ix++) {
      if ((xs[ix] + ys[iy]) < 1.0) opacity += 0.00390625; 
    }
  }
  gl_FragColor = vColor * opacity;
}`;

export const ellipseOutlineShader = `uniform vec2 radius;
uniform float lineWidth;
varying vec4 vColor;
varying vec4 textureCoord;
#define EPSILON 0.00001
void main() {
  vec2 z = textureCoord.xy;
  float x = z.x;
  float y = z.y;
  float a = radius.x;
  float b = radius.y;
  x = abs(x);
  y = abs(y);
  if (y > x) {
    float t = y;
    y = x;
    x = t;
    a = radius.y;
    b = radius.x;
  }
  if (y < EPSILON) y += EPSILON;
  vec2 f1, f2;
  float aa = a * a;
  float bb = b * b;
  if (a > b) {
    float f = sqrt(aa - bb);
    f1 = vec2(f, 0);
    f2 = vec2(-f, 0);
  } else {
    float f = sqrt(bb - aa);
    f1 = vec2(0, f);
    f2 = vec2(0, -f);
  }
  vec2 pos = vec2(x, y);
  vec2 toF1 = normalize(f1 - pos);
  vec2 toF2 = normalize(f2 - pos);
  vec2 toMid = normalize(toF1 + toF2);
  float m = toMid.y / toMid.x;
  float c = y - m * x;
  float mm = m * m;
  float x1 = (-aa * m * c + a * b * sqrt(aa * mm + bb - c * c)) / (bb + aa * mm);
  float y1 = m * x1 + c;
  vec2 pt = vec2(x1, y1);
  float d = 0.5 - length(pos - pt);
  float alpha = clamp(d + lineWidth * 0.5, 0.0, 1.0);
  gl_FragColor = vColor * alpha;
}`;

export const fastBrushShader = `uniform sampler2D sampler1; 
uniform sampler2D sampler2; 
#ifdef MASK
uniform sampler2D sampler3; 
#endif
uniform float opacity;
uniform vec4 toolColor;
varying vec4 textureCoord;
void main() {
  vec4 src = texture2D(sampler1, textureCoord.zw);
  if (textureCoord.z < 0.0 || textureCoord.z > 1.0 || textureCoord.w < 0.0 || textureCoord.w > 1.0) {
    src = vec4(0, 0, 0, 0);
  }
  vec4 tool = texture2D(sampler2, textureCoord.xy);
  tool *= toolColor;
  #ifdef MASK
  tool *= texture2D(sampler3, textureCoord.xy).a;
  #endif
  vec4 res = tool + (1.0 - tool.a) * src;
  #ifdef OPACITY_LOCKED
  float a = res.a == 0.0 ? 0.0 : (src.a / res.a);
  res.rgb *= a;
  res.a = src.a;
  #endif
  gl_FragColor = res * opacity;
}`;

export const fastEraserShader = `uniform sampler2D sampler1; 
uniform sampler2D sampler2; 
#ifdef MASK
uniform sampler2D sampler3; 
#endif
uniform float opacity;
uniform float toolOpacity;
varying vec4 textureCoord;
void main() {
  vec4 src = texture2D(sampler1, textureCoord.zw);
  if (textureCoord.z < 0.0 || textureCoord.z > 1.0 || textureCoord.w < 0.0 || textureCoord.w > 1.0) {
    src = vec4(0, 0, 0, 0);
  }
  vec4 tool = texture2D(sampler2, textureCoord.xy);
  #ifdef MASK
  tool.a *= texture2D(sampler3, textureCoord.xy).a;
  #endif
  gl_FragColor = src * ((1.0 - tool.a * toolOpacity) * opacity);
}`;

export const fastGaussianBlurShader = `precision highp float;
uniform sampler2D sampler1;
uniform float radius;
uniform vec2 direction;
uniform vec2 offset;
uniform vec2 size;
uniform vec2 borders;
const float rad = 200000.;
const float LOD = 2.;
float gau(float x, float sigma) {
    float m = 0.398942280401 / sigma;
    return m * exp(-x * x * 0.5 / (sigma * sigma));
}
float round(float number) {
    return float(float(int(number)) + 0.5);
}
float gaussian(vec2 i, float sigma) {
    return exp( -.5 * dot(i/=sigma,i) ) / ( 6.28 * sigma*sigma );
}
void main() {
    if (radius == 0.0) {
        vec4 Color = texture2D(sampler1, (gl_FragCoord.xy + offset ) /size.xy);
        gl_FragColor = Color;
        return ;
    };
    float sigma = radius / ( sqrt(2.0 * log(255.0)) - 1.0);
    vec4 sum = vec4(0.);
    float roundedRadius = round(radius);
    float gaussSum = 0.0;
    float gaussValue = 0.0;
    if (roundedRadius < 1.0) roundedRadius = 1.0;
    for(float vIndex = 0.0; vIndex < rad; vIndex++) {
        if (vIndex > 2.0 * roundedRadius) break;
        for(float hIndex = 0.0; hIndex < rad; hIndex++) {
            if (hIndex > 2.0 * roundedRadius) break;
            vec2 currentPixOffset = vec2(vIndex - roundedRadius, hIndex - roundedRadius);
            gaussValue = gaussian(currentPixOffset, sigma);
            vec2 bordersVec = (currentPixOffset + gl_FragCoord.xy) / borders.xy;
            if (bordersVec.x <= 1.0 && bordersVec.y <= 1.0 && bordersVec.x >= 0.0 && bordersVec.y >= 0.0) {
                vec4 currentColor = texture2D(sampler1, (currentPixOffset + gl_FragCoord.xy) / size.xy);
                sum += currentColor * gaussValue;
                gaussSum += gaussValue;
            }
        }
    }
    gl_FragColor = sum / gaussSum;
}`;

export const fastMoveShader = `uniform sampler2D sampler1;
#ifdef MASK
uniform sampler2D sampler2; 
#endif
uniform vec4 color;
uniform mat2 toolTransform;
uniform vec2 toolMove;
uniform vec2 maxWidthHeight;
varying vec4 textureCoord;
void main() {
  vec2 coord = textureCoord.xy * toolTransform + toolMove;
  vec4 src = texture2D(sampler1, coord, -1.0); 
  #ifdef MASK
  src *= texture2D(sampler2, coord).a;
  #endif
  if (coord.x < 0.0 || coord.y < 0.0 || coord.x > maxWidthHeight.x || coord.y > maxWidthHeight.y) {
    src = vec4(0, 0, 0, 0);
  }
  gl_FragColor = src * color;
}`;

export const fastNormalShader = `uniform sampler2D sampler1;
#ifdef MASK
uniform sampler2D sampler2; 
#endif
uniform vec4 color;
varying vec4 textureCoord;
void main() {
  vec4 src = texture2D(sampler1, textureCoord.xy);
  #ifdef MASK
  src *= texture2D(sampler2, textureCoord.xy).a;
  #endif
  gl_FragColor = src * color;
}`;

export const gridShader = `uniform float pixelSize;
uniform vec2 size;
uniform float alpha;
varying vec4 textureCoord;
void main() {
  vec2 coord = textureCoord.xy * size;
  vec2 dist = coord - floor(coord);
  vec2 dd = abs(0.5 - dist);
  float a = 1.0 - ((1.0 - max(dd.x, dd.y) * 2.0) * 0.5 / pixelSize);
  gl_FragColor = vec4(alpha * a);
}`;

export const hslShader = `float getLum(vec3 c) {
  return dot(c, vec3(0.3, 0.59, 0.11));
}
float channelMax(vec3 c) {
  return max(c.r, max(c.g, c.b));
}
float channelMin(vec3 c) {
  return min(c.r, min(c.g, c.b));
}
float getSat(vec3 c) {
  return channelMax(c) - channelMin(c);
}
vec3 clipColor(vec3 color, float a) {
  float l = getLum(color);
  float n = channelMin(color);
  float x = channelMax(color);
  if (n < 0.0) {
    float t = l - n;
    if (t == 0.0) {
      color = vec3(0);
    } else {
      color = vec3(l) + (((color - vec3(l)) * l) / t);
    }
  }
  if (x > a) {
    float t = x - l;
    if (t == 0.0) {
      color = vec3(a);
    } else {
      color = vec3(l) + (((color - vec3(l)) * (a - l) / t));
    }
  }
  return color;
}
vec3 setLum(vec3 color, float sa, float lum) {
  float d = lum - getLum(color);
  color += vec3(d);
  return clipColor(color, sa);
}
vec3 processSat(vec3 res, float sat) {
  float t = res.z - res.x;
  if (t == 0.0) {
    res.y = 0.0;
    res.z = 0.0;
  } else {
    res.y = ((res.y - res.x) * sat) / t;
    res.z = sat;
  }
  res.x = 0.0;
  return res;
}
vec3 setSat(vec3 src, float sat) {
  if (src.r > src.g) {
    if (src.r > src.b) {
      if (src.g > src.b) {
        src.bgr = processSat(src.bgr, sat);
      } else {
        src.gbr = processSat(src.gbr, sat);
      }
    } else {
      src.grb = processSat(src.grb, sat);
    }
  }  else {
    if (src.r > src.b) {
      src.brg = processSat(src.brg, sat);
    } else {
      if (src.g > src.b) {
        src.rbg = processSat(src.rbg, sat);
      } else {
        src.rgb = processSat(src.rgb, sat);
      }
    }
  }
  return src;
}`;

export const hueSaturationLightnessShader = `precision highp float;
uniform sampler2D sampler1;
uniform vec2 size;
uniform float hue;
uniform float saturation;
uniform float lightness;
const float EPSILON = 1e-10;
vec3 HUEtoRGB(float hue) {
  vec3 rgb = abs(hue * 6. - vec3(3, 2, 4)) * vec3(1, -1, -1) + vec3(-1, 2, 2);
  return clamp(rgb, 0., 1.);
}
vec3 RGBtoHCV(vec3 rgb) {
  vec4 p = (rgb.g < rgb.b) ? vec4(rgb.bg, -1., 2. / 3.) : vec4(rgb.gb, 0., -1. / 3.);
  vec4 q = (rgb.r < p.x) ? vec4(p.xyw, rgb.r) : vec4(rgb.r, p.yzx);
  float c = q.x - min(q.w, q.y);
  float h = abs((q.w - q.y) / (6. * c + EPSILON) + q.z);
  return vec3(h, c, q.x);
}
float HueToRGB(float f1, float f2, float hue) {
  if (hue < 0.0)
    hue += 1.0;
  else if (hue > 1.0)
    hue -= 1.0;
  float res;
  if ((6.0 * hue) < 1.0)
    res = f1 + (f2 - f1) * 6.0 * hue;
  else if ((2.0 * hue) < 1.0)
    res = f2;
  else if ((3.0 * hue) < 2.0)
    res = f1 + (f2 - f1) * ((2.0 / 3.0) - hue) * 6.0;
  else
    res = f1;
  return res;
}
vec3 HSLtoRGB(vec3 hsl) {
  vec3 rgb;
  if (hsl.y == 0.0) {
    rgb = vec3(hsl.z); 
  } else {
    float f2;
    if (hsl.z < 0.5)
      f2 = hsl.z * (1.0 + hsl.y);
    else
      f2 = (hsl.z + hsl.y) - (hsl.y * hsl.z);
    float f1 = 2.0 * hsl.z - f2;
    rgb.r = HueToRGB(f1, f2, hsl.x + (1.0/3.0));
    rgb.g = HueToRGB(f1, f2, hsl.x);
    rgb.b= HueToRGB(f1, f2, hsl.x - (1.0/3.0));
  }
  return rgb;
}
vec3 RGBtoHSL(vec3 color) {
  vec3 hsl; 
  float fmin = min(min(color.r, color.g), color.b);    
  float fmax = max(max(color.r, color.g), color.b);    
  float delta = fmax - fmin;             
  hsl.z = (fmax + fmin) / 2.0; 
  if (delta == 0.0) {   
    hsl.x = 0.0;  
    hsl.y = 0.0;  
  } else {                                   
    if (hsl.z < 0.5)
      hsl.y = delta / (fmax + fmin); 
    else
      hsl.y = delta / (2.0 - fmax - fmin); 
    float deltaR = (((fmax - color.r) / 6.0) + (delta / 2.0)) / delta;
    float deltaG = (((fmax - color.g) / 6.0) + (delta / 2.0)) / delta;
    float deltaB = (((fmax - color.b) / 6.0) + (delta / 2.0)) / delta;
    if (color.r == fmax )
      hsl.x = deltaB - deltaG; 
    else if (color.g == fmax)
      hsl.x = (1.0 / 3.0) + deltaR - deltaB; 
    else if (color.b == fmax)
      hsl.x = (2.0 / 3.0) + deltaG - deltaR; 
    if (hsl.x < 0.0)
      hsl.x += 1.0; 
    else if (hsl.x > 1.0)
      hsl.x -= 1.0; 
  }
  return hsl;
}
vec3 BlendLuminosity(vec3 base, vec3 blend) {
  vec3 baseHSL = RGBtoHSL(base);
  return HSLtoRGB(vec3(baseHSL.r, baseHSL.g, RGBtoHSL(blend).b));
}
void main() {
  vec4 Color = texture2D(sampler1, (gl_FragCoord.xy) /size.xy);
  vec3 fragRGB = Color.rgb;
  vec3 fragHSL = RGBtoHSL(fragRGB);
  float h = hue / 360.0;
  float s = saturation / 100.0;
  if (saturation < 0.0) {
    s = fragHSL.y * s;
  } else {
    s = (1.0 - fragHSL.y) * s;
  }
  fragHSL.x += h;
  fragHSL.x = mod(fragHSL.x, 1.0);
  if (fragHSL.y != 0.0) { 
    fragHSL.y += s;
  }
  vec4 finalColor = vec4(HSLtoRGB(fragHSL), Color.a);
  float l = lightness / 100.0;
  vec3 lightnessVec = vec3(0.0);
  if (lightness < 0.0) {
    lightnessVec = finalColor.rgb * l;
  } else {
    lightnessVec = (vec3(Color.a) - finalColor.rgb) * l;
  }
  gl_FragColor = vec4((finalColor.rgb + lightnessVec), finalColor.a);
}`;

export const imageBrushShader = `uniform sampler2D sampler1;
varying vec4 vColor;
varying vec4 textureCoord;
void main() {
  float alpha = texture2D(sampler1, textureCoord.xy, -1.0).a; 
  #ifdef OPACITY
  float result = textureCoord.w * alpha;
  gl_FragColor = vec4(result);
  #else
  vec4 result = vColor * alpha;
  gl_FragColor = result;
  #endif
}`;

export const lineShader = `varying vec4 vColor;
varying vec4 textureCoord;
void main() {
  float alpha = clamp(textureCoord.y + 0.5 - abs(textureCoord.x), 0.0, 1.0);
  gl_FragColor = vColor * alpha;
}`;

export const maskShader = `uniform sampler2D sampler1; 
uniform sampler2D sampler2; 
uniform float reverse;
varying vec4 textureCoord;
void main() {
  vec4 src = texture2D(sampler1, textureCoord.zw);
  if (textureCoord.z < 0.0 || textureCoord.z > 1.0 || textureCoord.w < 0.0 || textureCoord.w > 1.0) {
    src = vec4(0, 0, 0, 0);
  }
  vec4 mask = texture2D(sampler2, textureCoord.xy);
  float maskA = reverse == 0.0 ? mask.a : (1.0 - mask.a);
  float a = min(src.a, maskA);
  if (src.a > 0.0) src.rgb *= a / src.a;
  src.a = a;
  gl_FragColor = src;
}`;

export const maskPremultiplyShader = `uniform sampler2D sampler1; 
uniform sampler2D sampler2; 
uniform float reverse;
varying vec4 textureCoord;
void main() {
  vec4 src = texture2D(sampler1, textureCoord.zw);
  if (textureCoord.z < 0.0 || textureCoord.z > 1.0 || textureCoord.w < 0.0 || textureCoord.w > 1.0) {
    src = vec4(0, 0, 0, 0);
  }
  vec4 mask = texture2D(sampler2, textureCoord.xy);
  float maskA = reverse == 0.0 ? mask.a : (1.0 - mask.a);
  src.a = min(src.a, maskA);
  src.rgb *= src.a;
  gl_FragColor = src;
}`;

export const maskUnpremultiplyShader = `uniform sampler2D sampler1; 
uniform sampler2D sampler2; 
uniform float reverse;
varying vec4 textureCoord;
void main() {
  vec4 src = texture2D(sampler1, textureCoord.zw);
  if (textureCoord.z < 0.0 || textureCoord.z > 1.0 || textureCoord.w < 0.0 || textureCoord.w > 1.0) {
    src = vec4(0, 0, 0, 0);
  }
  vec4 mask = texture2D(sampler2, textureCoord.xy);
  float maskA = reverse == 0.0 ? mask.a : (1.0 - mask.a);
  float a = min(src.a, maskA);
  if (src.a != 0.0) src.rgb /= src.a;
  src.a = a;
  gl_FragColor = src;
}`;

export const meshShader = `uniform sampler2D sampler1;
varying vec2 vTexCoord;
varying vec3 vNormal;
void main() {
  vec4 color = vec4(1, 1, 1, 1);
  vec3 ambient = vec3(0.2, 0.2, 0.2); 
  vec3 diffuse = vec3(1, 1, 1) - ambient; 
  vec3 N = normalize(vNormal);
  vec3 L = normalize(-vec3(-1, -1, 1));
  vec3 light = ambient + diffuse * max(dot(N, L), 0.0);
  color.rgb *= light;
  gl_FragColor = color;
}`;

export const rectOutlineShader = `uniform float pixelSize;
varying vec4 vColor;
varying vec4 textureCoord;
void main() {
  vec2 texCoord = textureCoord.xy;
  vec2 delta = min(abs(texCoord), abs(vec2(1.0) - texCoord));
  float d = min(delta.x, delta.y);
  float a = 1.0 - d / pixelSize;
  gl_FragColor = vColor * a; 
}`;

export const softBrush1Shader = `uniform vec4 color;
varying vec4 textureCoord;
void main() {
  vec2 t = (textureCoord.xy - 0.5) * 2.0;
  float d = sqrt(dot(t, t));
  float a = clamp(1.0 - d, 0.0, 1.0);
  float alpha = pow(a, 3.0);
  gl_FragColor = color * alpha; 
}`;

export const softBrush2Shader = `varying vec4 vColor;
varying vec4 textureCoord;
float ease(float x, float h) {
  if (x > 1.0) return 0.0;
  if (x < h) return 1.0;
  x = ((x - h) / (1.0 - h)) * 2.0;
  return 1.0 - (x < 1.0 ? (0.5 * x * x) : (-0.5 * ((x - 1.0) * (x - 3.0) - 1.0)));
}
void main() {
  float dist = sqrt(dot(textureCoord.xy, textureCoord.xy));
  float hardness = textureCoord.z;
  float alpha = ease(dist, hardness);
  #ifdef OPACITY
  float result = textureCoord.w * alpha;
  gl_FragColor = vec4(result);
  #else
  vec4 result = vColor * alpha; 
  gl_FragColor = result;
  #endif
}`;

export const solidColorShader = `uniform vec4 color;
void main() {
  gl_FragColor = color;
}`;

export const spriteShader = `uniform sampler2D sampler1;
varying vec4 vColor;
varying vec4 textureCoord;
void main() {
  vec4 color = texture2D(sampler1, textureCoord.xy);
  gl_FragColor = color * vColor;
}`;

export const testShader = `varying vec4 textureCoord;
void main() {
  gl_FragColor = vec4(textureCoord.xy, 0.0, 1.0);
}`;

export const toolShader = `#define ZERO vec3(0, 0, 0)
#define ONE vec3(1, 1, 1)
#define HALF vec3(0.5, 0.5, 0.5)
uniform sampler2D sampler1; 
uniform sampler2D sampler2; 
#ifdef TOOL
uniform sampler2D sampler3; 
#ifdef MASKS
uniform sampler2D sampler4; 
#endif
uniform vec4 toolColor;
uniform mat2 toolTransform;
uniform vec2 toolMove;
uniform vec2 maxWidthHeight;
uniform float srcMul;
uniform float toolOpacity;
uniform float lockOpacity;
#endif
uniform float isClipped;
uniform float opacity;
uniform float baseOpacity;
varying vec4 textureCoord;
varying vec4 vColor;
vec4 alphaBlendOperator(vec4 src, vec4 dst, vec3 blnd) {
  vec4 result = vec4(0, 0, 0, src.a + (1.0 - src.a) * dst.a);
  result.rgb = ((1.0 - dst.a) * src.rgb
             + (1.0 - src.a) * dst.rgb
             + src.a * dst.a * blnd);
  return result;
}
vec3 getRGB(vec4 color) {
  float a = color.a == 0.0 ? 0.0 : (1.0 / color.a);
  return color.rgb * a;
}
DECL_HERE
#ifdef TOOL
vec4 blendTool(vec4 src, vec4 dst) {
  return src * srcMul + (1.0 - src.a) * dst;
}
#endif
void main() {
  vec4 tex = texture2D(sampler1, textureCoord.zw);
  if (textureCoord.z < 0.0 || textureCoord.z > 1.0 || textureCoord.w < 0.0 || textureCoord.w > 1.0) {
    tex = vec4(0, 0, 0, 0);
  }
#ifdef TOOL
  vec2 coord = textureCoord.xy * toolTransform + toolMove;
  vec4 tool = texture2D(sampler3, coord, -1.0); 
  float maskAlpha = 1.0;
  #ifdef MASKS
  maskAlpha *= texture2D(sampler4, coord).a;
  #endif
  if (coord.x < 0.0 || coord.y < 0.0 || coord.x > maxWidthHeight.x || coord.y > maxWidthHeight.y) {
    tool = vec4(0, 0, 0, 0);
    maskAlpha = 0.0;
  }
  tool *= maskAlpha * toolOpacity;
  vec4 src = blendTool(tool * toolColor, tex);
  if (lockOpacity == 1.0) {
    float a = src.a == 0.0 ? 0.0 : (tex.a / src.a);
    src.rgb *= a;
    src.a = tex.a;
  }
#else
  vec4 src = tex;
#endif
  vec4 dst = texture2D(sampler2, vColor.xy) * baseOpacity;
  if (vColor.x < 0.0 || vColor.x > 1.0 || vColor.y < 0.0 || vColor.y > 1.0) {
    dst = vec4(0, 0, 0, 0);
  }
  CODE_HERE
  if (isClipped == 1.0) {
    float a = blended.a == 0.0 ? 0.0 : (dst.a / blended.a);
    blended.rgb *= a;
    blended.a = dst.a;
  }
  gl_FragColor = blended;
}`;

export const unpremultiplyShader = `uniform sampler2D sampler1;
varying vec4 textureCoord;
void main() {
  vec4 color = texture2D(sampler1, textureCoord.xy);
  if (color.a != 0.0) {
    color.rgb /= color.a;
  }
  gl_FragColor = color;
}`;

export const vertexShader = `attribute vec2 position;
attribute vec4 texcoords;
attribute vec4 vertexColor;
uniform mat4 transform;
varying vec4 textureCoord;
varying vec4 vColor;
void main() {
  textureCoord = texcoords;
  vColor = vertexColor;
  gl_Position = transform * vec4(position, 0, 1);
}`;

export const vertexColorShader = `varying vec4 vColor;
void main() {
  gl_FragColor = vColor;
}`;

export const vertexMeshShader = `attribute vec3 position;
attribute vec3 vertexNormal;
attribute vec2 texcoord;
uniform mat4 model;
uniform mat4 viewProj;
varying vec2 vTexCoord;
varying vec3 vNormal;
void main() {
  vTexCoord = texcoord;
  vNormal = mat3(model) * vertexNormal;
  gl_Position = viewProj * model * vec4(position, 1);
}`;
