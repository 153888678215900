import { Point, Rect, Viewport } from '../interfaces';
import { cloneRect, createRect, outsetRect } from '../rect';
import { documentToScreenPoint } from '../viewport';
import { Textarea } from './textarea';
import { clonePoint } from '../point';
import { getPixelRatio, isMobile } from '../utils';

export enum TextareaControlPointDirections {
  North = 'north',
  NorthEast = 'north-east',
  East = 'east',
  SouthEast = 'south-east',
  South = 'south',
  SouthWest = 'south-west',
  West = 'west',
  NorthWest = 'north-west',
}

export const TEXTAREA_CONTROL_POINT_HITBOX_PADDING = isMobile ? 48 : 16;
export const TEXTAREA_CONTROL_POINT_MARKER_SIZE = 8;
export const TEXTAREA_CONTROL_POINTS_WITH_CORNER_HITBOXES = [TextareaControlPointDirections.NorthWest, TextareaControlPointDirections.NorthEast, TextareaControlPointDirections.SouthEast, TextareaControlPointDirections.SouthWest];
export const TEXTAREA_CONTROL_POINTS_WITH_SIDE_HITBOXES = [TextareaControlPointDirections.North, TextareaControlPointDirections.East, TextareaControlPointDirections.South, TextareaControlPointDirections.West];

type TextareaControlPointDragHandler = (dx: number, dy: number, textarea: Textarea, originalRect: Rect, thisControlPoint: TextareaControlPoint) => boolean;
interface TextareaControlPointOptions {
  point: Point;
  direction: TextareaControlPointDirections;
  onDrag: TextareaControlPointDragHandler;
}

export class TextareaControlPoint {
  x: number;
  y: number;
  onDrag: TextareaControlPointDragHandler;
  direction: TextareaControlPointDirections;
  lastUsed = 0;
  hovered = false;
  active = false;

  constructor(options: TextareaControlPointOptions) {
    this.x = options.point.x;
    this.y = options.point.y;
    this.onDrag = options.onDrag;
    this.direction = options.direction;
  }

  get isUsingThickBorders(){
    return (this.hovered || this.active);
  }

  get strokeThickness() {
    return this.isUsingThickBorders ? 2 : 1;
  }

  getDrawingInstructions(view: Viewport) {
    const ratio = getPixelRatio();

    const screenPoint = clonePoint(this);
    documentToScreenPoint(screenPoint, view);
    const { x, y } = screenPoint;

    const blueRect = createRect(x - TEXTAREA_CONTROL_POINT_MARKER_SIZE / 2, y - TEXTAREA_CONTROL_POINT_MARKER_SIZE / 2, TEXTAREA_CONTROL_POINT_MARKER_SIZE, TEXTAREA_CONTROL_POINT_MARKER_SIZE);
    const whiteRect = outsetRect(cloneRect(blueRect), 1 / ratio);
    const thickness = this.strokeThickness / ratio;

    return { blueRect, whiteRect, thickness };
  }
}

export function assertTextareaControlPoint(controlPoint: TextareaControlPoint | undefined): asserts controlPoint is TextareaControlPoint {
  if (!controlPoint) throw new Error(`Invalid value`);
}
