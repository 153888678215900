import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { storageGetItem, storageRemoveItem, storageSetString, storageSetItem, storageGetJson } from 'magma/services/storage';
import { ToastService } from 'magma/services/toast.service';
import { combineLatest } from 'rxjs';
import { TeamsQuery } from 'services/team.query';
import { UserService } from 'services/user.service';
import { routeToTeam } from 'shared/utils';
import { FormType, FormTypeChangedData } from './login-signup.component';

export const AFTER_LOGIN_REDIRECT_URL_KEY = 'redirect-after-login-url';
export const AFTER_LOGIN_REDIRECT_QUERY_KEY = 'redirect-after-login-query';

@UntilDestroy()
@Component({
  selector: 'login-signup-page',
  templateUrl: './login-signup-page.component.pug',
  styleUrls: ['./login-signup-page.component.scss'],
})
export class LoginSignupPageComponent implements AfterViewInit {
  currentRoute = FormType.Login;
  token = '';

  private success?: string;
  private error?: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private userService: UserService,
    private teamsQuery: TeamsQuery,
  ) {
    combineLatest([this.route.url, this.route.queryParams])
      .pipe(untilDestroyed(this))
      .subscribe(([route, params]) => {
        const { path } = route[0];
        this.currentRoute = path as FormType;
        this.token = params.token;
        this.success = params.success;
        this.error = params.error;

        const q = { ...params };

        if (params.url) {
          q['fromLogin'] = true;
          storageSetString(AFTER_LOGIN_REDIRECT_URL_KEY, params.url);
        }

        delete q.url; // remove url from query params as it is stored in different key
        if (Object.keys(q).length > 0 && this.currentRoute !== FormType.FinishRegisteration) { // avoid removing stored query on refresh or on random /login visit
          storageSetItem(AFTER_LOGIN_REDIRECT_QUERY_KEY, JSON.stringify(q));
        }
      });
  }

  ngAfterViewInit() {
    if (this.success) this.toastService.success({ message: this.success });
    if (this.error) this.toastService.error({ message: this.error });
  }

  redirect() {
    const lastTeam = this.userService.user?.lastTeam;
    const urlAfterLogin = storageGetItem(AFTER_LOGIN_REDIRECT_URL_KEY);
    const queryParams = storageGetJson<{ [key: string]: any; }>(AFTER_LOGIN_REDIRECT_QUERY_KEY);
    storageRemoveItem(AFTER_LOGIN_REDIRECT_URL_KEY);
    storageRemoveItem(AFTER_LOGIN_REDIRECT_QUERY_KEY);

    if (urlAfterLogin || !lastTeam) {
      void this.router.navigate([urlAfterLogin ?? '/my'], { queryParams });
    } else {
      void this.router.navigate(routeToTeam(lastTeam), { queryParams });
    }
  }

  formTypeChanged({ formType }: FormTypeChangedData) {
    void this.router.navigate([formType as string]);
  }
}
