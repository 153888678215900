import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Analytics } from '../../../../common/interfaces';
import { CHANGELOG } from '../../../../generated/changelog';
import { LoginSignupService } from '../../../../services/login-signup.service';

@Component({
  selector: 'changelog-modal',
  templateUrl: 'changelog-modal.pug',
})
export class ChangelogModal implements OnInit {
  changelog = CHANGELOG;
  @Output() close = new EventEmitter<void>();
  constructor(public loginSignupService: LoginSignupService) {
  }
  ngOnInit() {
    this.loginSignupService.trackEvent(Analytics.OpenChangelogModal);
  }
  closeModal() {
    this.close.emit();
  }
}
