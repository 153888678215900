import { createRect } from './rect';
import { Rect, CancellablePromise, Mat2d, BitmapData, LayerMode, ExtraLoader, Vec2 } from './interfaces';
import { decodePNG } from './png';
import { TEST_PNG_DECODE } from './constants';
import { userAgent } from './userAgentUtils';
import { randomString } from './baseUtils';

type FillStyle = string | CanvasGradient | CanvasPattern;
type Drawable = HTMLImageElement | HTMLCanvasElement | HTMLVideoElement | ImageBitmap;
type Context = CanvasRenderingContext2D;
type Canvas = HTMLCanvasElement;

const useImageBitmap = canUseImageBitmap();

export let createCanvas = (width: number, height: number): Canvas => {
  if (width < 0 || height < 0) throw new Error(`Invalid canvas size ${width}x${height}`);

  if (DEVELOPMENT && (width <= 0 || height <= 0 || (width | 0) !== width || (height | 0) !== height))
    throw new Error(`Invalid canvas size ${width}x${height}`);

  const canvas = document.createElement('canvas');
  canvas.width = width | 0;
  canvas.height = height | 0;
  return canvas;
};

export let createCanvasGL = createCanvas;

export let loadPNG = (src: string) => fetch(src)
  .then(response => response.blob())
  .then(blob => blob.arrayBuffer!())
  .then(buffer => decodePNG(new Uint8Array(buffer)));

export let loadImage = (src: string): Promise<HTMLImageElement> => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.addEventListener('load', () => resolve(img));
    img.addEventListener('error', () => reject(new Error(`Error loading image (${src})`)));
    img.src = src;
  });
};

export let loadImageOrImageDataFromData: (src: Uint8Array) => Promise<HTMLImageElement | ImageBitmap | ImageData> = dataToImage;

export async function dataToImage(data: Uint8Array): Promise<ImageBitmap | HTMLImageElement> {
  return blobToImage(new Blob([data]));
}

export let canvasToUint8Array = async (canvas: HTMLCanvasElement) => {
  const blob = await canvasToBlob(canvas);
  const buffer = await blobToArrayBuffer(blob);
  return buffer ? new Uint8Array(buffer) : undefined;
};

export function canvasToBlob(canvas: HTMLCanvasElement, type?: string, quality?: number) {
  return new Promise<Blob | null>(resolve => canvas.toBlob(resolve, type, quality));
}

export function canvasToBlobSync(canvas: HTMLCanvasElement, type?: string, quality?: number) {
  const dataURI = canvas.toDataURL(type, quality);
  if (!dataURI) throw new Error('Could not convert image to data URL');
  // convert base64 to raw binary data held in a string, doesn't handle URLEncoded DataURIs
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const buffer = new ArrayBuffer(byteString.length);
  const array = new Uint8Array(buffer);

  for (let i = 0; i < byteString.length; i++) {
    array[i] = byteString.charCodeAt(i);
  }

  return new Blob([buffer], { type: mimeString });
}

export async function blobToImage(blob: Blob): Promise<HTMLImageElement | ImageBitmap> {
  // bypass everything for testing
  if (TESTS) return (blob as any).image();

  try {
    if (useImageBitmap) {
      return await createImageBitmap(blob);
    }
  } catch (e) {
    DEVELOPMENT && console.warn('createImageBitmap failed, using fallback', e);
  }

  const url = URL.createObjectURL(blob);
  const image = await loadImage(url);
  URL.revokeObjectURL(url);
  (image as any).close = () => URL.revokeObjectURL(url); // mimic 'close' function from ImageBitmap
  return image;
}

export function blobToArrayBuffer(blob: Blob | null): Promise<ArrayBuffer | null> {
  if (!blob) return Promise.resolve(null);
  if ('arrayBuffer' in blob) return blob.arrayBuffer();
  if (typeof Response !== 'undefined') return new Response(blob).arrayBuffer();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as ArrayBuffer | null);
    reader.onerror = () => reject(reader.error);
    reader.readAsArrayBuffer(blob);
  });
}

export interface LoadOptions {
  onBlock?: (name: string) => void;
}

export function loadImageUsingImageTag(src: string, _options: LoadOptions = {}): CancellablePromise<HTMLImageElement> {
  let cancel!: () => void;

  const promise: any = new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    const onload = () => resolve(img);
    const onerror = () => reject(new Error(`Error loading image (${src})`));
    cancel = () => {
      img.removeEventListener('load', onload);
      img.removeEventListener('error', onerror);
      img.src = '';
      reject(new Error('Cancelled loading image'));
    };
    img.addEventListener('load', onload);
    img.addEventListener('error', onerror);
    img.src = src;
  });

  promise.cancel = cancel;
  return promise;
}

export function loadImageUsingImageBitmap(src: string, options: LoadOptions = {}): CancellablePromise<ImageBitmap> {
  const controller = typeof AbortController === 'function' ? new AbortController() : undefined;
  let cancelled = false;
  let rejectPromise = (_: Error) => { };

  function check(): true {
    if (cancelled) throw new Error('Cancelled loading image');
    return true;
  }

  function onBlock(name: string): true {
    options.onBlock?.(name);
    return true;
  }

  function validResponse(response: Response): Response {
    if (response.status !== 200) throw new Error(`Invalid image reponse ${response.status}`);
    return response;
  }

  const promise: any = new Promise<ImageBitmap>((resolve, reject) => {
    rejectPromise = reject;
    fetch(src, { signal: controller?.signal })
      .then(response => check() && onBlock('blob') && validResponse(response).blob())
      .then(blob => check() && onBlock('createImageBitmap') && createImageBitmap(blob))
      .then(
        imageBitmap => cancelled || resolve(imageBitmap),
        (e: Error | null) => {
          DEVELOPMENT && e && e.message !== 'The user aborted a request.' && console.error(e);
          cancelled || reject(new Error(`Error loading image (${src}) (${e?.message})`));
        });
  });

  promise.cancel = () => {
    cancelled = true;
    controller?.abort();
    rejectPromise(new Error('Cancelled loading image'));
  };

  return promise;
}

export function canUseImageBitmap() {
  const ua = userAgent;

  // Yandex browser reads incorrect image data when using ImageBitmap
  if (/yabrowser/i.test(ua)) return false;

  // ImageBitmap doesn't work in webgl methods in Firefox < 51
  if (/Firefox\/([1-4][0-9]|50)\./i.test(ua)) return false;

  // ImageBitmap doesn't work in webgl methods in Chrome 50
  if (/Chrome\/50\./i.test(ua)) return false;

  // ImageBitmap doesn't work in webgl methods in Safari 12
  if (/Safari\//.test(ua) && /Version\/12\./.test(ua)) return false;

  // It generates empty bitmap
  if (/Safari\//.test(ua) && /Version\/16\./.test(ua)) return false;

  // ImageBitmap not working on iPad, not sure if all versions
  if (/AppleWebKit\//.test(ua) && /iPad/.test(ua)) return false;

  return typeof fetch === 'function' && typeof createImageBitmap === 'function';
}

// TEMP: disabled everywhere except new Chrome/Firefox due to issues on older browser versions
const loadUsingImageBitmap = useImageBitmap &&
  (/Chrome\/(9\d|\d{3,})\./.test(userAgent) || /Firefox\/(9\d|\d{3,})\./.test(userAgent));

export type LoadImageCancellable<T> = (url: string, options?: LoadOptions) => CancellablePromise<T>;

export interface ImageLoader<T> {
  name: string;
  load: LoadImageCancellable<T>;
}

export let loadImageCancellable: LoadImageCancellable<HTMLImageElement | ImageBitmap> =
  loadUsingImageBitmap ? loadImageUsingImageBitmap : loadImageUsingImageTag;

export function loadImageUtingImageTagNoCache(url: string, options?: LoadOptions) {
  const separator = url.includes('?') ? '&' : '?';
  url += `${separator}nocache=${randomString(5)}`;
  return loadImageUsingImageTag(url, options);
}

export function defaultImageLoaders(extraLoader?: ExtraLoader): ImageLoader<HTMLImageElement | ImageBitmap>[] {
  let loaders: ImageLoader<HTMLImageElement | ImageBitmap>[] = [];

  if (loadImageCancellable !== loadImageUsingImageTag) {
    loaders.push({ name: 'bitmap', load: loadImageCancellable });
  }

  loaders.push({ name: 'img', load: loadImageUsingImageTag });
  loaders.push({ name: 'nocache', load: loadImageUtingImageTagNoCache });

  if (extraLoader) {
    loaders.push({ name: 'socket', load: extraLoader.getLayerImage });
  }

  return loaders;
}

export let totalDecodingTime = 0;

let loadPNGSupportedForce = false;

export function loadPNGSupported() {
  return loadPNGSupportedForce || (TEST_PNG_DECODE && typeof fetch === 'function' && typeof Blob.prototype.arrayBuffer === 'function');
}

export let loadPNGCancellable = (src: string, options: LoadOptions = {}): CancellablePromise<BitmapData> => {
  const controller = typeof AbortController === 'function' ? new AbortController() : undefined;
  let cancelled = false;
  let rejectPromise = (_: Error) => { };

  function check(): true {
    if (cancelled) throw new Error('Cancelled loading image');
    return true;
  }

  function onBlock(name: string): true {
    options.onBlock?.(name);
    return true;
  }

  totalDecodingTime = 0;

  const promise: any = new Promise<BitmapData>((resolve, reject) => {
    rejectPromise = reject;
    fetch(src, { signal: controller && controller.signal })
      .then(response => check() && onBlock('blob') && response.blob())
      .then(blob => check() && onBlock('arrayBuffer') && blob.arrayBuffer!())
      .then(buffer => {
        check();
        onBlock('decodePNG');
        const start = performance.now();
        const png = decodePNG(new Uint8Array(buffer));
        const time = performance.now() - start;
        totalDecodingTime += time;
        // DEVELOPMENT && console.log(`decoded PNG ${png.width}x${png.height} (${src}) in ${time.toFixed(2)} ms`);
        return png;
      })
      .then(
        bitmapData => cancelled || resolve(bitmapData),
        error => cancelled || reject(new Error(`Error loading image (${src}) (${error.message})`)));
  });

  promise.cancel = () => {
    cancelled = true;
    controller && controller.abort();
    rejectPromise(new Error('Cancelled loading image'));
  };

  return promise;
};

export function setup(methods: {
  createCanvas(width: number, height: number): Canvas;
  createCanvasGL(width: number, height: number): Canvas;
  loadImage(src: string): Promise<HTMLImageElement>;
  loadImageCancellable(src: string): CancellablePromise<HTMLImageElement>;
  loadImageOrImageDataFromData(src: Uint8Array): Promise<HTMLImageElement | ImageBitmap | ImageData>;
  canvasToUint8Array(canvas: HTMLCanvasElement): Promise<Uint8Array | undefined>;
  loadPNG(src: string): Promise<BitmapData>;
  loadPNGCancellable?(src: string): CancellablePromise<BitmapData>;
}) {
  createCanvas = methods.createCanvas;
  createCanvasGL = methods.createCanvasGL;
  loadImage = methods.loadImage;
  loadImageCancellable = methods.loadImageCancellable;
  loadImageOrImageDataFromData = methods.loadImageOrImageDataFromData;
  canvasToUint8Array = methods.canvasToUint8Array;
  loadPNG = methods.loadPNG;

  if (methods.loadPNGCancellable) {
    loadPNGCancellable = methods.loadPNGCancellable;
    loadPNGSupportedForce = true;
  }
}

export function getContext2d(
  canvas: HTMLCanvasElement,
  options?: { alpha: boolean; desynchronized?: boolean; preserveDrawingBuffer?: boolean; }
): CanvasRenderingContext2D {
  // const context = canvas.getContext('2d', { desynchronized: true, ...options });
  const context = canvas.getContext('2d', options);
  if (!context) throw new Error(`Out of memory (2d context)`);
  return context as CanvasRenderingContext2D;
}

export function textWidth(context: CanvasRenderingContext2D, text: string) {
  return context.measureText(text)?.width ?? 0; // measureText can return undefined on safari somehow
}

let pixelContext: Context | undefined;

export function getPixelContext(): Context {
  return pixelContext || (pixelContext = getContext2d(createCanvas(1, 1)));
}

export function getBlendMode(layerMode: LayerMode): GlobalCompositeOperation {
  switch (layerMode) {
    case 'normal': return 'source-over';
    case 'color dodge': return 'color-dodge';
    case 'color burn': return 'color-burn';
    case 'hard light': return 'hard-light';
    case 'soft light': return 'soft-light';
    default: return layerMode;
  }
}

export function clearRect(context: Context, x: number, y: number, w: number, h: number) {
  if (x < 0 || y < 0 || w < 0 || h < 0 || (x + w) > context.canvas.width || (y + h) > context.canvas.height) {
    throw new Error(`Invalid clearRect (${x} ${y} ${w} ${h}) [${context.canvas.width}x${context.canvas.height}]`);
  }

  context.clearRect(x, y, w, h);
}

export function clearRectRect(context: Context, r: Rect) {
  if (r.w && r.h) {
    if (!context) throw new Error(`Invalid context`); // TEMP: error in rollbar

    const x = Math.max(0, r.x);
    const y = Math.max(0, r.y);
    const w = Math.min(context.canvas.width - x, r.w - (x - r.x));
    const h = Math.min(context.canvas.height - y, r.h - (y - r.y));

    if (w > 0 && h > 0) {
      clearRect(context, x, y, w, h);
    }
  }
}

export function fillRect(context: Context, fillStyle: FillStyle, x: number, y: number, w: number, h: number) {
  if (x < 0 || y < 0 || w < 0 || h < 0 || (x + w) > context.canvas.width || (y + h) > context.canvas.height) {
    throw new Error(`Invalid fillRect (${x} ${y} ${w} ${h}) [${context.canvas.width}x${context.canvas.height}]`);
  }

  context.fillStyle = fillStyle;
  context.fillRect(x, y, w, h);
}

export function fillRectSafe(context: Context, fillStyle: FillStyle, x: number, y: number, w: number, h: number) {
  if (w && h) {
    const tx = Math.max(0, x);
    const ty = Math.max(0, y);
    const tw = Math.min(context.canvas.width - tx, w - (tx - x));
    const th = Math.min(context.canvas.height - ty, h - (ty - y));

    if (tw > 0 && th > 0) {
      fillRect(context, fillStyle, tx, ty, tw, th);
    }
  }
}

export function drawImage(
  context: Context, image: Drawable, sx: number, sy: number, sw: number, sh: number,
  dx: number, dy: number, dw: number, dh: number
) {
  if (sx < 0 || sy < 0 || sw <= 0 || sh <= 0 || dw < 0 || dh < 0 || (sx + sw) > image.width || (sy + sh) > image.height) {
    throw new Error(`Invalid drawImage (${sx} ${sy} ${sw} ${sh}) [${image.width}x${image.height}]`
      + ` => (${dx} ${dy} ${dw} ${dh}) [${context.canvas.width}x${context.canvas.height}]`);
  }

  context.drawImage(image, sx, sy, sw, sh, dx, dy, dw, dh);
}

export function drawImageRect(context: Context, image: Drawable | undefined, r: Rect) {
  if (image && r.w && r.h) {
    const x = Math.max(0, r.x);
    const y = Math.max(0, r.y);
    const w = Math.min(image.width - x, r.w - (x - r.x));
    const h = Math.min(image.height - y, r.h - (y - r.y));

    if (w > 0 && h > 0) {
      drawImage(context, image, x, y, w, h, x, y, w, h);
    }
  }
}

export function drawImageRectShifted(context: Context, image: Drawable | undefined, r: Rect, x: number, y: number) {
  if (image && r.w && r.h) {
    let sx = r.x;
    let sy = r.y;
    let w = r.w;
    let h = r.h;
    let dx = r.x + x;
    let dy = r.y + y;

    const xx = -Math.min(0, sx, dx);
    w -= xx;
    dx += xx;
    sx += xx;

    const yy = -Math.min(0, sy, dy);
    h -= yy;
    dy += yy;
    sy += yy;

    w += Math.min(0, image.width - (sx + w), context.canvas.width - (dx + w));
    h += Math.min(0, image.height - (sy + h), context.canvas.height - (dy + h));

    if (w > 0 && h > 0) {
      drawImage(context, image, sx, sy, w, h, dx, dy, w, h);
    }
  }
}

export function applyTransform(context: Context, m: Mat2d) {
  context.transform(m[0], m[1], m[2], m[3], m[4], m[5]);
}

export function cropCanvas(canvas: HTMLCanvasElement, rect: Rect) {
  const result = createCanvas(rect.w, rect.h);
  getContext2d(result).drawImage(canvas, -rect.x, -rect.y);
  return result;
}

function isRowEmpty({ data, width }: ImageData, y: number, left: number, right: number) {
  const start = ((y * width + left) * 4 + 3) | 0;
  const end = (start + (right - left) * 4) | 0;

  for (let i = start; i < end; i = (i + 4) | 0) {
    if (data[i] !== 0) {
      return false;
    }
  }

  return true;
}

function isColEmpty({ data, width }: ImageData, x: number, top: number, bottom: number) {
  const stride = (width * 4) | 0;
  const start = (top * stride + x * 4 + 3) | 0;

  for (let y = top, i = start; y < bottom; y++, i = (i + stride) | 0) {
    if (data[i] !== 0) {
      return false;
    }
  }

  return true;
}

export function getCanvasBounds(canvas: HTMLCanvasElement, { x, y, w, h }: Rect): Rect {
  let left = 0;
  let right = w;
  let top = 0;
  let bottom = h;

  if (w && h) {
    const context = getContext2d(canvas);
    const data = context.getImageData(x, y, w, h);

    while (top < bottom && isRowEmpty(data, top, left, right))
      top++;
    while (bottom > top && isRowEmpty(data, bottom - 1, left, right))
      bottom--;
    while (left < right && isColEmpty(data, left, top, bottom))
      left++;
    while (right > left && isColEmpty(data, right - 1, top, bottom))
      right--;
  }

  return createRect(left + x, top + y, right - left, bottom - top);
}

export function ellipse(context: CanvasRenderingContext2D, cx: number, cy: number, rx: number, ry: number) {
  context.save();
  context.translate(0, cy);
  context.scale(1, ry / rx);
  context.translate(0, -cy);
  context.arc(cx, cy, rx, 0, Math.PI * 2, false);
  context.restore();
}

export async function openImageInNewTab(canvas: HTMLCanvasElement, title = 'Image') {
  const wnd = window.open();

  if (wnd) {
    wnd.document.title = title;
    wnd.document.body.innerHTML = 'loading...';
    const blob = await canvasToBlob(canvas);
    if (!blob) {
      throw new Error(`Expected Blob, received ${blob}`);
    }
    const url = URL.createObjectURL(blob);
    wnd.location = url as any;
  }
}

export function trimImage(image: BitmapData) {
  let l = 0, t = 0, r = image.width, b = image.height;
  const data = image.data;
  const stride = image.width * 4;

  tloop: for (; t < b; t++) {
    const offset = t * stride;
    for (let i = 3; i < stride; i += 4) {
      if (data[offset + i] !== 0) break tloop;
    }
  }

  bloop: for (; t < b; b--) {
    const offset = (b - 1) * stride;
    for (let i = 3; i < stride; i += 4) {
      if (data[offset + i] !== 0) break bloop;
    }
  }

  lloop: for (; l < r; l++) {
    for (let i = t, o = (t * stride) + (l * 4) + 3; i < b; i++, o += stride) {
      if (data[o] !== 0) break lloop;
    }
  }

  rloop: for (; l < r; r--) {
    for (let i = t, o = (t * stride) + ((r - 1) * 4) + 3; i < b; i++, o += stride) {
      if (data[o] !== 0) break rloop;
    }
  }

  const rect = createRect(l, t, r - l, b - t);

  // TODO: don't trim if it's just 1px ?
  if ((rect.x || rect.y || rect.w !== image.width || rect.h !== image.height) && rect.w && rect.h) {
    // DEVELOPMENT && logger.debug('Trimming image data', rect, image.width, image.height);
    const base = rect.y * stride;
    const newStride = rect.w * 4;

    if (rect.y !== 0 || rect.w !== image.width) {
      for (let y = 0, dst = base, src = base + rect.x * 4; y < rect.h; y++, src += stride, dst += newStride) {
        data.copyWithin(dst, src, src + newStride);
      }
    }

    image.data = data.subarray(base, base + rect.w * rect.h * 4);
    image.width = rect.w;
    image.height = rect.h;
  }

  return rect;
}

export function replaceImageDataRect(dst: ImageData, srcData: Uint8Array | Uint8ClampedArray, rect: Rect) {
  const dstData = dst.data;

  for (let y = 0; y < rect.h; y++) {
    const srcOffsetBase = y * rect.w * 4;
    const dstOffsetBase = (y + rect.y) * dst.width * 4;

    for (let x = 0; x < rect.w; x++) {
      const srcOffset = srcOffsetBase + x * 4;
      const dstOffset = dstOffsetBase + (x + rect.x) * 4;
      dstData[dstOffset + 0] = srcData[srcOffset + 0];
      dstData[dstOffset + 1] = srcData[srcOffset + 1];
      dstData[dstOffset + 2] = srcData[srcOffset + 2];
      dstData[dstOffset + 3] = srcData[srcOffset + 3];
    }
  }
}

// used in tests
export function rotateAndFlipRaw(raw: BitmapData, rot: number, flip: boolean) {
  if (!rot && !flip) return raw;

  let result: BitmapData = { ...raw, data: raw.data.slice() };

  if (flip) {
    for (let y = 0; y < raw.height; y++) {
      for (let x = 0; x < raw.width; x++) {
        const src = (x + y * raw.height) * 4;
        const dst = ((result.width - x) + y * result.height) * 4;
        result.data[dst + 0] = raw.data[src + 0];
        result.data[dst + 1] = raw.data[src + 1];
        result.data[dst + 2] = raw.data[src + 2];
        result.data[dst + 3] = raw.data[src + 3];
      }
    }

    raw = result;
    result = { ...raw, data: raw.data.slice() };
  }

  switch (rot) {
    case 1: // 90 deg CW
      result.width = raw.height;
      result.height = raw.width;
      for (let y = 0; y < raw.height; y++) {
        for (let x = 0; x < raw.width; x++) {
          const src = (x + y * raw.height) * 4;
          const dst = ((result.width - y) + x * result.height) * 4;
          result.data[dst + 0] = raw.data[src + 0];
          result.data[dst + 1] = raw.data[src + 1];
          result.data[dst + 2] = raw.data[src + 2];
          result.data[dst + 3] = raw.data[src + 3];
        }
      }
      break;
    case 2: // 180 deg CW
      for (let y = 0; y < raw.height; y++) {
        for (let x = 0; x < raw.width; x++) {
          const src = (x + y * raw.height) * 4;
          const dst = ((result.width - x) + (result.height - y) * result.height) * 4;
          result.data[dst + 0] = raw.data[src + 0];
          result.data[dst + 1] = raw.data[src + 1];
          result.data[dst + 2] = raw.data[src + 2];
          result.data[dst + 3] = raw.data[src + 3];
        }
      }
      break;
    case 3: // 270 deg CW
      result.width = raw.height;
      result.height = raw.width;
      for (let y = 0; y < raw.height; y++) {
        for (let x = 0; x < raw.width; x++) {
          const src = (x + y * raw.height) * 4;
          const dst = (y + (result.height - x) * result.height) * 4;
          result.data[dst + 0] = raw.data[src + 0];
          result.data[dst + 1] = raw.data[src + 1];
          result.data[dst + 2] = raw.data[src + 2];
          result.data[dst + 3] = raw.data[src + 3];
        }
      }
      break;
  }

  return result;
}

export function imageDataToBitmapData(imageData: ImageData): BitmapData {
  const { width, height, data } = imageData;
  return { width, height, data: new Uint8Array(data.buffer, data.byteOffset, data.byteLength) };
}

export function isImageDataIdentical(a: ImageData | BitmapData, b: ImageData | BitmapData) {
  if (a.width !== b.width || a.height !== b.height) return false;

  const size = a.width * a.height * 4;

  for (let i = 0; i < size; i++) {
    if (a.data[i] !== b.data[i]) {
      return false;
    }
  }

  return true;
}

export function cropImageData<T extends BitmapData | ImageData>(src: T, x: number, y: number, w: number, h: number) {
  if (x === 0 && y === 0 && src.width === w && src.height === h) return src;

  const size = w * h * 4;
  const data = src.data instanceof Uint8Array ? new Uint8Array(size) : new Uint8ClampedArray(size);
  const dst = { width: w, height: h, data } as T;
  const minWidth = Math.min(src.width, dst.width);
  const minHeight = Math.min(src.height, dst.height);

  for (let iy = 0; iy < minHeight; iy++) {
    for (let ix = 0; ix < minWidth; ix++) {
      const si = ((ix + x) + (iy + y) * src.width) * 4;
      const di = (ix + iy * dst.width) * 4;
      dst.data[di + 0] = src.data[si + 0];
      dst.data[di + 1] = src.data[si + 1];
      dst.data[di + 2] = src.data[si + 2];
      dst.data[di + 3] = src.data[si + 3];
    }
  }

  return dst;
}

export const drawBoundsPath = (ctx: CanvasRenderingContext2D, bounds: Vec2[]) => {
  ctx.beginPath();
  ctx.moveTo(bounds[0][0], bounds[0][1]);
  ctx.lineTo(bounds[1][0], bounds[1][1]);
  ctx.lineTo(bounds[2][0], bounds[2][1]);
  ctx.lineTo(bounds[3][0], bounds[3][1]);
  ctx.lineTo(bounds[0][0], bounds[0][1]);
  ctx.closePath();
};
