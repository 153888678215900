import { Component } from '@angular/core';
import { faCheck, faDownload, faExclamationTriangle, faPaintBrush } from '../../../common/icons';
import { getExtensionUrl } from '../../../common/extension';
import { isAndroid, isiPad, isiPhone, userAgent } from '../../../common/userAgentUtils';
import { chrome, edge, firefox, ios, opera, safari } from '../../../generated/resources';

function getBrowserId(ua: string) {
  if (/iPhone|iPad/.test(ua)) return 'ios';
  if (/\bEdge?\//.test(ua)) return 'edge';
  if (/\bOPR\//.test(ua)) return 'opera';
  if (/\bfirefox\//i.test(ua)) return 'firefox';
  if (/\bchrome\//i.test(ua)) return 'chrome';
  if (/\bsafari\//i.test(ua)) return 'safari';
  return 'chrome';
}

@Component({
  selector: 'browser-support',
  templateUrl: 'browser-support.pug',
  styleUrls: ['browser-support.scss'],
})
export class BrowserSupport {
  readonly checkIcon = faCheck;
  readonly downloadIcon = faDownload;
  readonly alertIcon = faExclamationTriangle;
  readonly brushIcon = faPaintBrush;
  readonly extensionLink = getExtensionUrl();
  readonly isMobile = isiPhone || isiPad || isAndroid;
  readonly chromeLogo = chrome;
  readonly firefoxLogo = firefox;
  readonly edgeLogo = edge;
  readonly safariLogo = safari;
  readonly operaLogo = opera;
  readonly iosLogo = ios;
  browser = getBrowserId(userAgent);
}
