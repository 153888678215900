import { Directive, ElementRef, EventEmitter, NgZone, Output } from '@angular/core';

@Directive({ selector: '[unpatchedMouseEnter]' })
export class UnpatchedMouseEnter {
  @Output() unpatchedMouseEnter = new EventEmitter<MouseEvent>();
  constructor(element: ElementRef<HTMLElement>, zone: NgZone) {
    zone.runOutsideAngular(() => element.nativeElement.addEventListener('mouseenter', e => this.unpatchedMouseEnter.emit(e)));
  }
}

@Directive({ selector: '[unpatchedMouseLeave]' })
export class UnpatchedMouseLeave {
  @Output() unpatchedMouseLeave = new EventEmitter<MouseEvent>();
  constructor(element: ElementRef<HTMLElement>, zone: NgZone) {
    zone.runOutsideAngular(() => element.nativeElement.addEventListener('mouseleave', e => this.unpatchedMouseLeave.emit(e)));
  }
}

export const UNPATCHED_EVENT_DIRECTIVES = [UnpatchedMouseEnter, UnpatchedMouseLeave];
