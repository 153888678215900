import { Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { fromEvent, of } from 'rxjs';
import { UserData } from 'shared/interfaces';
import { AuthService } from 'services/auth.service';
import { UserQuery } from 'services/user.query';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, filter, map, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProjectService } from 'services/projects.service';
import { TeamService } from 'services/team.service';
import { faChevronDown, faCog, farTimes, faSearch } from 'magma/common/icons';
import { UserService } from 'services/user.service';

@UntilDestroy()
@Component({
  selector: '[top-bar]',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  faSearch = faSearch;
  farTimes = farTimes;
  faChevronDown = faChevronDown;
  faCog = faCog;

  @ViewChild('searchInput') searchInput!: ElementRef<any>;
  @Input() loginFake?: boolean;
  @Input() editorHeader?: boolean;
  @HostBinding('class.blurred') blurred = false;

  loggedIn$ = this.authService.loggedIn$;
  user$ = this.userService.user$;
  searchIsFocused = false;
  keyToSearch = '';
  isFree$ = this.userQuery.isFreePlan$;
  isSuperAdmin$ = this.userService.user$.pipe(map(user => !!user?.isSuperAdmin));
  superAdminModalOpen = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private userQuery: UserQuery,
    private projectService: ProjectService,
    private teamService: TeamService,
  ) {
    this.initSearchParams();
  }

  ngOnInit() {
    if (this.loginFake) {
      this.blurred = true;
      this.user$ = of({ name: 'You' } as UserData);
      this.loggedIn$ = of(true);
    }
  }

  ngAfterViewInit() {
    this.initInputListener();
  }

  async logout() {
    await this.authService.logout();
  }

  openSuperAdmin() {
    this.superAdminModalOpen = true;
  }

  impersonateUser(user: string) {
    console.log('Impersonate user', user);
  }

  initSearchParams() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params?.key) {
        this.keyToSearch = params.key;
      } else { this.keyToSearch = ''; }
    });
  }

  search() {
    const formattedKey = this.keyToSearch.trim();
    this.searchPage(formattedKey);
  }

  initInputListener() {
    if (!this.searchInput) {
      return;
    }
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      untilDestroyed(this),
      map((event: any) => {
        const keyCode = (event as KeyboardEvent)?.keyCode;
        this.keyToSearch = event.target.value;
        if (keyCode === 13) {
          this.search();
          return false;
        }
        return true;
      }),
      filter(value => !!value),
      debounceTime(700),
      tap((_) => { this.search(); }),
    ).subscribe();
  }

  searchPage(key: string) {
    this.teamService.setActiveTeamSlug(null);
    this.projectService.setActiveProject(null);
    if (key.length === 0) {
      void this.router.navigate(['my']);
    } else {
      void this.router.navigate(['my', 'search'], { queryParams: { key } });
    }
  }

  clearSearch() {
    this.keyToSearch = '';
    this.searchPage('');
  }
}
