export function keys<T>(keysArray: (keyof T)[]): string[] {
  return keysArray as string[];
}

export function hasFlag(value: number | undefined, flag: number): boolean {
  return (value! & flag) === flag;
}

export function setFlag(value: number | undefined, flag: number, on: boolean): number {
  return (value! & ~flag) | (on ? flag : 0);
}

export function findById<U, T extends { id: U; }>(items: T[], id: U): T | undefined {
  for (let i = 0; i < items.length; i++) {
    if (items[i].id === id) return items[i];
  }
  return undefined;
}

export function findByName<U, T extends { name: U; }>(items: T[], name: U): T | undefined {
  for (let i = 0; i < items.length; i++) {
    if (items[i].name === name) return items[i];
  }
  return undefined;
}

export function removeById<U, T extends { id: U; }>(items: T[], id: U): T | undefined {
  for (let i = 0; i < items.length; i++) {
    if (items[i].id === id) {
      return items.splice(i, 1)[0];
    }
  }

  return undefined;
}

// Preserves order of items
export function removeItem<T>(items: T[] | undefined, item: T) {
  if (items) {
    const index = items.indexOf(item);

    if (index !== -1) {
      items.splice(index, 1);
      return true;
    }
  }

  return false;
}

// Does not preserve order of items
export function removeItemFast<T>(items: T[], item: T) {
  const index = items.indexOf(item);

  if (index !== -1) {
    items[index] = items[items.length - 1];
    items.length--;
  }
}

export function includes<T>(array: T[] | undefined, item: T): boolean {
  return array !== undefined && array.indexOf(item) !== -1;
}

export function invalidEnumReturn<T>(value: never, result: T): T {
  if (DEVELOPMENT) throw new Error(`Invalid enum value: ${value}`);
  return result;
}

export function invalidEnum(value: never, message = 'Invalid enum value'): never {
  throw new Error(`${message}: ${value}`);
}

const digits = '0123456789';
const lowercaseCharacters = 'abcdefghijklmnopqrstuvwxyz0123456789_';
const uppercaseCharacters = lowercaseCharacters + 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export function randomString(length: number, useUpperCase = false): string {
  const characters = useUpperCase ? uppercaseCharacters : lowercaseCharacters;
  let result = '';

  for (let i = 0; i < length; i++) {
    result += characters[(Math.random() * characters.length) | 0];
  }

  return result;
}

export function randomDigits(length: number): string {
  let result = '';

  for (let i = 0; i < length; i++) {
    result += digits[(Math.random() * digits.length) | 0];
  }

  return result;
}

export function removeFileExtension(value: string) {
  return value.replace(/\.[a-z0-9]+$/i, '');
}

export function quadraticEasing(start: number, current: number, length: number) {
  return Math.min(1.0, Math.sqrt((Math.max(0, current - length - start)) / length));
}
