import { colorToCSS, colorToFloatArray } from './color';
import type { LayerMode, BrushToolSettings, ClientDrawingData, PresentationModeState } from './interfaces';

// https://colorbrewer2.org/#type=diverging&scheme=RdYlBu&n=11
export const brewerColors = [
  'hsl(3, 69%, 50%)',
  'hsl(8, 63%, 57%)',
  'hsl(14, 89%, 61%)',
  'hsl(18, 84%, 73%)',
  'hsl(28, 94%, 36%)',
  'hsl(30, 98%, 69%)',
  'hsl(32, 84%, 48%)',
  'hsl(33, 87%, 29%)',
  'hsl(35, 62%, 46%)',
  'hsl(42, 60%, 68%)',
  'hsl(44, 98%, 78%)',
  'hsl(60, 100%, 87%)',
  'hsl(87, 60%, 70%)',
  'hsl(90, 49%, 50%)',
  'hsl(97, 63%, 35%)',
  'hsl(108, 49%, 88%)',
  'hsl(114, 45%, 74%)',
  'hsl(125, 34%, 52%)',
  'hsl(138, 63%, 29%)',
  'hsl(171, 44%, 65%)',
  'hsl(171, 45%, 85%)',
  'hsl(175, 48%, 40%)',
  'hsl(175, 98%, 20%)',
  'hsl(195, 58%, 79%)',
  'hsl(200, 54%, 72%)',
  'hsl(201, 51%, 88%)',
  'hsl(203, 52%, 51%)',
  'hsl(203, 50%, 64%)',
  'hsl(210, 68%, 40%)',
  'hsl(237, 51%, 39%)',
  'hsl(251, 30%, 75%)',
  'hsl(254, 26%, 56%)',
  'hsl(268, 55%, 34%)',
  'hsl(281, 30%, 73%)',
  'hsl(282, 26%, 55%)',
  'hsl(291, 51%, 34%)',
  'hsl(297, 30%, 87%)',
  'hsl(323, 68%, 83%)',
  'hsl(325, 76%, 44%)',
  'hsl(326, 99%, 28%)',
  'hsl(328, 61%, 67%)',
  'hsl(346, 100%, 32%)',
];

// color value, text color, color name
const COLORS: [string, string, string][] = [
  ['#ff5bb6', '#ffffff', 'pink'],
  ['#de3bff', '#ffffff', 'magenta'],
  ['#cc78ff', '#ffffff', 'orchid'],
  ['#9879ff', '#ffffff', 'indigo'],
  ['#5c57ec', '#ffffff', 'royal blue'],
  ['#4676ff', '#ffffff', 'blue'],
  ['#46baff', '#ffffff', 'cyan'],
  ['#2cf7d5', '#222222', 'turquoise'],
  ['#40ffb1', '#222222', 'aquamarine'],
  ['#37d973', '#ffffff', 'jade'],
  ['#3db74c', '#ffffff', 'green'],
  ['#97ff40', '#222222', 'lime'],
  ['#f4f41a', '#222222', 'lemon'],
  ['#ffda40', '#222222', 'yellow'],
  ['#ffaf00', '#ffffff', 'tangerine'],
  ['#ff7200', '#ffffff', 'orange'],
  ['#ff4043', '#ffffff', 'scarlet'],
  ['#d71010', '#ffffff', 'red'],
];

export const USER_COLORS = COLORS.map(x => x[0]);
export const USER_COLORS_TEXT: { [key: string]: string | undefined; } = {};
export const USER_COLORS_NAMES: { [key: string]: string | undefined; } = {};

for (const [color, text, name] of COLORS) {
  USER_COLORS_TEXT[color] = text;
  USER_COLORS_NAMES[color] = name;
}

if (DEVELOPMENT) {
  USER_COLORS.forEach(c => !USER_COLORS_TEXT[c] && console.warn('Missing USER_COLORS_TEXT for', c));
}

export const USER_JOBS = [
  'Student',
  'Educator',
  'Freelancer',
  '2D artist / illustrator',
  '3D artist',
  'Art Director / Lead Artist',
  'Director / Manager',
  'C-Level Executive',
  'Other',
];

export const PRESSURE_APIS = {
  ext: 'Chrome extension',
  pen: 'Native support',
  hid: 'WebHID',
  force: 'Touch force',
  none: '',
};

export const RENDERER_APIS = {
  'webgl': 'WebGL',
  'webgl2': 'WebGL 2',
  '2d-off': '2D (off)',
  '2d-fail': '2D (fail)',
  'webgpu': 'WebGPU',
};

export const DEFAULT_USER_COLOR = USER_COLORS[0];

export const BACKGROUND_COLORS: { name: string; value: string; }[] = [
  { name: 'white', value: '#ffffff' },
  { name: 'light gray', value: '#c1c1c1' },
  { name: 'gray', value: '#7f7f7f' },
  { name: 'dark gray', value: '#3e3e3e' },
  { name: 'black', value: '#000000' },
  { name: 'light green', value: '#dae5d6' },
  { name: 'transparent', value: '' },
];

// colors
export const TRANSPARENT = 0x00000000;
export const BLACK = 0x000000ff;
export const WHITE = 0xffffffff;
export const WHITE_FLOAT = colorToFloatArray(WHITE);
export const WHITE_STR = colorToCSS(WHITE);

export const RED = 0xff0000ff;
export const BLUE = 0x0000ffff;
export const ORANGE = 0xffa500ff;

export const DEFAULT_BACKGROUND_COLOR = BACKGROUND_COLORS[0].value;
export const DEFAULT_LAYER_COLOR = '#888';
export const DEFAULT_CANVAS_WIDTH = 1920;
export const DEFAULT_CANVAS_HEIGHT = 1080;
export const DEFAULT_DRAWING_NAME = 'New Drawing';
export const DEFAULT_DPI = 72;

export const DEFAULT_STABILIZE = 0.34;

export const LIGHT_BACKGROUND = '#ccc';
export const DARK_BACKGROUND = '#222';

export const BRUSH_SIZES = [
  0.7, 0.8, 1, 1.5, 2, 2.3, 2.6, 3, 3.5, 4, 5, 6, 7, 8, 9, 10,
  12, 14, 16, 20, 25, 30, 35, 40, 50, 60, 70, 80, 100, 120,
  160, 200, 250, 300, 350, 400, 450, 500, 600, 800, 1000
];

export const PENCIL_SIZES = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  12, 14, 16, 20, 25, 30, 35, 40, 50, 60, 70, 80, 100, 120,
];

export const KB = 1024;
export const MB = 1024 * KB;
export const GB = 1024 * MB;

export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;
export const MONTH = DAY * 30;
export const YEAR = DAY * 365;

export const GENERATED_AVATARS = IS_HOSTED;
export const AVATAR_DEFAULT = GENERATED_AVATARS ? 'none.png' : 'default.png';
export const AVATAR_BACKGROUND = '#454545';
export const AVATAR_MAX_FILE_SIZE = 1 * MB;

export const MASK_COORD_LIMIT = 100000; // -0x080000..0x07ffff is the limit when encoding masks

// cursors
export const USER_CURSOR_RADIUS = 15;
export const USER_NAME_OFFSET = USER_CURSOR_RADIUS - 6;
export const USER_NAME_WIDTH = 128;
export const USER_NAME_HEIGHT = 28;
export const CURSOR_AVATAR_LARGE_HEIGHT = 42;
export const CURSOR_FADE_DISTANCE = 60;
export const CURSOR_FADE_PAD = 60;
export const CURSOR_VIDEO_HEIGHT = 80;

// other limits
export const UNDO_LIMIT = 15;
export const USER_NAME_LENGTH_LIMIT = 32;
export const LAYER_NAME_LENGTH_LIMIT = 32;
export const RECENT_DRAWING_LIMIT = 10;
export const CHAT_MESSAGE_LENGTH_LIMIT = 250;
export const CHAT_MESSAGE_HISTORY_LIMIT = 100;
export const MAX_DRAWING_BACKGROUND_LENGTH = 32;
export const MAX_DRAWING_SAMPLES = 100000;
export const MAX_LASSO_SAMPLES = 5000;
export const MAX_SERVER_SIDE_SAMPLES = 150000;
export const MAX_FREE_SEQUENCE_DRAWINGS = 4;
export const MAX_FREE_VOICE_CALL_USERS = 2;

export const MAX_ENTITY_NAME_LENGTH = 50;
export const MAX_PROJECT_NAME_LENGTH = 50;
export const MAX_ENTITY_PASSWORD_LENGTH = 16;

export const IMPORT_FILE_TYPES = 'image/png,image/jpeg,image/gif,.psd,.pdf';

export const EMAIL_THUMB_RENDER_TYPE = 'thumbnail.email.png';
export const EXPORT_TYPES = [
  'json',
  'zip', 'png', 'jpg', 'psd', 'pdf', 'thumbnail.png', 'thumbnail.200.png', EMAIL_THUMB_RENDER_TYPE, // drawings
  'text', 'svg', 'code.png', 'code.pdf', 'code.svg' // flowcharts
];
export const EXPORT_TYPES_PRO = ['zip'];

export const FLUSH_TIMEOUT = IS_HOSTED ? 10 : 40; // ms, time after which tool moves get send to server as a batch

export const MIN_VIEW_SCALE = 0.01;
export const MAX_VIEW_SCALE = 32.0;
export const DRAG_ZOOM_RATIO = 0.0005;
export const CLICK_ZOOM_RATIO = 0.02;
export const HARD_BRUSH_THRESHOLD = 0.95;

// size limits
export const MIN_IMAGE_WIDTH = 1;
export const MIN_IMAGE_HEIGHT = 1;
export const MAX_IMAGE_WIDTH = 8192; // absolute limits supported by the application (for sanity checks)
export const MAX_IMAGE_HEIGHT = 8192;
export const MAX_IMAGE_WIDTH_PRO = 8192;
export const MAX_IMAGE_HEIGHT_PRO = 8192;
export const MAX_IMAGE_WIDTH_FREE = IS_HOSTED ? MAX_IMAGE_WIDTH_PRO : 4096;
export const MAX_IMAGE_HEIGHT_FREE = IS_HOSTED ? MAX_IMAGE_HEIGHT_PRO : 4096;

export function getMaxImageWidth(pro: boolean) {
  return pro ? MAX_IMAGE_WIDTH_PRO : MAX_IMAGE_WIDTH_FREE;
}

export function getMaxImageHeight(pro: boolean) {
  return pro ? MAX_IMAGE_HEIGHT_PRO : MAX_IMAGE_HEIGHT_FREE;
}

export function getMaxImageSize(canHaveLargeCanvas: boolean) {
  return `${getMaxImageWidth(canHaveLargeCanvas)}x${getMaxImageWidth(canHaveLargeCanvas)}`;
}

export function isImageSizeValid(width: number, height: number, canHaveLargeCanvas: boolean) {
  return width <= getMaxImageWidth(canHaveLargeCanvas) && height <= getMaxImageHeight(canHaveLargeCanvas) &&
    width >= MIN_IMAGE_WIDTH && height >= MIN_IMAGE_HEIGHT;
}

export function doesImageSizeRequirePro(width: number, height: number) {
  return IS_PORTAL && !IS_HOSTED && isImageSizeValid(width, height, true) && !isImageSizeValid(width, height, false);
}

// layer count limits
export const MAX_IMAGE_LAYERS_PRO = IS_HOSTED ? 200 : 200;
export const MAX_IMAGE_LAYERS_FREE = IS_HOSTED ? MAX_IMAGE_LAYERS_PRO : 60;
export const MAX_IMAGE_LAYERS_ADMIN = 1000;

export function getMaxLayers(pro: boolean, superadmin: boolean) {
  if (superadmin) return MAX_IMAGE_LAYERS_ADMIN;
  return pro ? MAX_IMAGE_LAYERS_PRO : MAX_IMAGE_LAYERS_FREE;
}

// allow 5 new layers per minute
export const NEW_LAYERS_LIMIT_TIMEOUT = 1 * MINUTE;
export const NEW_LAYERS_LIMIT_COUNT = 5;

// session limits
export const MAX_SESSIONS_FREE = 2;
export const MAX_SESSIONS_PRO = 5;
export const MAX_USERS_PER_DRAWING_FREE = IS_HOSTED ? 50 : 30; // when changing adjust limits for name plates (max 64 now)
export const MAX_USERS_PER_DRAWING_PRO = IS_HOSTED ? 50 : 50;

export function getMaxUsersPerDrawing(pro: boolean) {
  return pro ? MAX_USERS_PER_DRAWING_PRO : MAX_USERS_PER_DRAWING_FREE;
}

// upload limits
export const MAX_IMPORT_SIZE_PRO = 500 * MB;
export const MAX_IMPORT_SIZE_FREE = IS_HOSTED ? MAX_IMPORT_SIZE_PRO : (25 * MB);
export const MAX_PASTE_SIZE_PRO = 500 * MB;
export const MAX_PASTE_SIZE_FREE = IS_HOSTED ? MAX_PASTE_SIZE_PRO : (25 * MB);
export const MAX_SHARE_SIZE = IS_HOSTED ? (100 * MB) : (25 * MB);
export const MAX_PASTE_IMAGE_SIZE = MAX_IMAGE_WIDTH;
export const MAX_CHUNKED_DATA_SIZE = Math.max(MAX_IMPORT_SIZE_FREE, MAX_IMPORT_SIZE_PRO, MAX_SHARE_SIZE, MAX_PASTE_SIZE_PRO);

export function getMaxImportSize(pro: boolean) {
  return pro ? MAX_IMPORT_SIZE_PRO : MAX_IMPORT_SIZE_FREE;
}

export function isValidImageSizeForPaste(width: number, height: number) {
  return width <= MAX_PASTE_IMAGE_SIZE && height <= MAX_PASTE_IMAGE_SIZE;
}

export const MIN_DPI = 1;
export const MAX_DPI = 10000;

const proCustomShapes = new Set([
  ...[
    'L_Body_003', 'L_Body_004', 'L_Body_005', 'L_Body_006', 'L_Body_008',
    'L_Body_009', 'L_Body_010', 'L_Body_012',
    'L_Heads_004', 'L_Heads_005', 'L_Heads_006', 'L_Heads_008', 'L_Heads_009',
    'L_Legs_002', 'L_Legs_003', 'L_Legs_005', 'L_Legs_007', 'L_Legs_008',
    'L_Legs_009', 'L_Legs_010', 'L_Legs_011', 'L_Legs_012', 'L_Legs_013',
    'L_Legs_015', 'L_Legs_016',
    'M_Body_001', 'M_Body_003', 'M_Body_006', 'M_Body_007',
    'M_Body_009', 'M_Body_010', 'M_Body_011', 'M_Body_012', 'M_Body_013',
    'M_Body_014', 'M_Body_015', 'M_Body_016', 'M_Body_017', 'M_Body_018',
    'M_Heads_002', 'M_Heads_004', 'M_Heads_005', 'M_Heads_006',
    'M_Heads_008', 'M_Heads_009', 'M_Heads_010', 'M_Heads_011',
    'M_Heads_015', 'M_Heads_016', 'M_Heads_017',
    'M_Legs_002', 'M_Legs_003', 'M_Legs_006', 'M_Legs_008', 'M_Legs_009',
    'M_Legs_010', 'M_Legs_011', 'M_Legs_012', 'M_Legs_014', 'M_Legs_015',
    'S_Body_003', 'S_Body_004', 'S_Body_006', 'S_Body_008', 'S_Body_009',
    'S_Body_010', 'S_Body_011', 'S_Body_012',
    'S_Heads_004', 'S_Heads_005', 'S_Heads_006', 'S_Heads_007', 'S_Heads_008',
    'S_Heads_009', 'S_Heads_010', 'S_Heads_011', 'S_Heads_012', 'S_Heads_013',
    'S_Heads_014', 'S_Heads_016',
    'S_Legs_002', 'S_Legs_005', 'S_Legs_007', 'S_Legs_008', 'S_Legs_009',
    'S_Legs_010',
  ].map(x => `silvertoons-${x}`),
]);

export const isProBrushShape = (id: string) => id !== '';
export const isProCustomShape = (id: string) => proCustomShapes.has(id);
export const isProLassoPattern = (_id: string) => false;

export const REVISION_AUTO_INTERVAL = HOUR;

export const ANONYMOUS_USER_EXPIRE_AGE = 120 * DAY;
export const MAX_SESSION_AGE = IS_HOSTED ? (120 * DAY) : (120 * DAY);
export const SECURITY_DELAY = 200; // ms
export const ACTIVE_DRAWING_TIMEOUT = 5 * MINUTE;
export const SAVE_DRAWING_TIMEOUT = DEVELOPMENT ? SECOND : (IS_HOSTED ? 5 * SECOND : 10 * SECOND);
export const ACTIVE_PERIOD = 30 * DAY;
export const MAX_BIN_FOLDER_AGE = 30 * DAY;

export const ROTATION_SNAP = Math.PI / 12;
export const LAYER_THUMB_SIZE = 40;
export const SERVER_THUMB_SIZE = 400;

export const SEQUENCE_THUMB_SIZE = 256;
export const SEQUENCE_THUMB_WIDTH = 200 - 6;
export const SEQUENCE_THUMB_HEIGHT = 137 - 27;

// from _variables.scss
export const EDITOR_TOP_MENU_HEIGHT = 43;
export const EDITOR_LEFT_MENU_WIDTH = 39;
export const EDITOR_COLOR_SELECTOR_HEIGHT = 232;
export const EDITOR_TOOL_OPTIONS_SIMPLE_HEIGHT = 64;
export const EDITOR_TOOL_OPTIONS_FULL_HEIGHT = 195;
export const EDITOR_TOOL_OPTIONS_ADVANCED_HEIGHT = 261 + 22;

export const PDF_MAX_SIZE = 4096;

export const TOOL_SLOTS = 6;

export const LAYER_MODES: (LayerMode | null)[] = [
  'normal',
  null,
  'darken',
  'multiply',
  'color burn',
  null,
  'lighten',
  'screen',
  'color dodge',
  null,
  'overlay',
  'soft light',
  'hard light',
  null,
  'difference',
  'exclusion',
  null,
  'hue',
  'saturation',
  'color',
  'luminosity',
];

export const LAYER_MODES_ALL = LAYER_MODES.filter(x => x) as string[];

export const DEFAULT_DRAWING_DATA: ClientDrawingData = {
  _id: '',
  id: '',
  name: '',
  width: 100,
  height: 100,
  background: '#ffffff',
  dpi: DEFAULT_DPI,
  layers: [],
};

export const SLEEP_TIMEOUT = IS_HOSTED ? 0 : (5 * HOUR);
export const THUMB_CACHE_TIME = 3 * SECOND;
export const USE_SERVER_GL = true;
export const SHOW_CURSORS = IS_PORTAL;
export const SHOW_CURSOR_UNMOVING_TIMEOUT = 30 * SECOND;

export const LOG_FILES = [
  'long-tools.log', 'daily-stats.log', 'monthly-stats.log', 'tablets.log', 'dc.log',
  'dcs.log', 'admin.log', 'disk-io.log', 'apis.log', 'image-loaders.log', 'psds.log',
  'emails.log', 'requested-with.log',
];

export const TASK_CANCELED_ERROR = 'Task was canceled';
export const MAINTENANCE_ERROR = 'Server is undergoing maintenance, please check back later.';
export const PASSWORD_ERROR = 'Drawing is password protected';
export const BLOCKED_ERROR = 'Cannot connect, you are blocked from this drawing';
export const CLIENT_LIMIT_ERROR = 'Cannot connect, too many people are using the application. Try again later.';
export const SESSION_LIMIT_ERROR = 'Cannot connect, you reached limit of active tabs';
export const SIGN_IN_TO_VIEW_ERROR = `Anonymous users can't view documents, please sign in first.`;
export const ALREADY_SUBSCRIBED_ERROR = `Subscription is already active.`;
export const DRAWING_DELETED = 'Drawing is deleted';
export const DRAWING_NOT_FOUND = 'Drawing not found';
export const DRAWING_NO_ACCESS = `You don't have access to view this drawing`;
export const ACCESS_DENIED = `Access denied. You don't have sufficient permissions to perform this action`;
export const SEQUENCE_FRAME_LIMIT = 'Sequences with more than four frames are paid feature';

export const REMOVED_USER_NAME = 'User removed';

export const invalidImageSizeError = (canHaveLargeCanvas: boolean) =>
  `Invalid image size, width and height must be in the range of (${MIN_IMAGE_WIDTH}-${getMaxImageWidth(canHaveLargeCanvas)})`;
export const userLimitError = (count: number) => `User limit reached, maximum of ${count} people can draw on the same canvas.`;
export const isUserLimitError = (error: string) => error.startsWith(`User limit reached, maximum of `);

export const DEFAULT_FONT = `'Helvetica Neue', Helvetica, Arial, sans-serif`;

export const MIN_BRUSH_SIZE = 0.1;
export const MAX_BRUSH_SIZE = 2000;
export const MIN_BRUSH_SPACING = 0.001;
export const MAX_BRUSH_SPACING = 10;
export const MIN_STROKE_WIDTH = 1;
export const MAX_STROKE_WIDTH = 100;
export const MAX_SPREAD = 10;
export const MIN_PATTERN_SCALE = 2;
export const MAX_PATTERN_SCALE = 100;

export const ACCOUNT_EXPORT_COLUMNS = [
  'id', 'name', 'email', 'unverified email', 'current email verified', 'current email', 'created', 'last visit',
  'last active', 'created drawings', 'participated in drawings', 'cancellation reason',
  ...(IS_HOSTED ? [] : ['pro', 'pro until', 'stripe id', 'consent', 'job', 'work tags', 'tags']),
  'features'
];

// TODO: use PRODUCT_INFO instead
export const EDUCATION_LINK = 'https://magma.com/contact/education';
export const NEW_FEATURE_ID = 'custom-avatar';

export const DEFAULT_BRUSH_TOOL_SETTINGS: BrushToolSettings = {
  name: '', sizePressure: true, sizeJitter: 0, minSize: 0, flow: 1, flowPressure: false, opacity: 1,
  opacityPressure: false, spacing: 0.2, hardness: 1, separateSpread: false, normalSpread: 0, tangentSpread: 0,
  shape: '', angle: 0, angleJitter: 3.14, angleToDirection: false,
  colorPressure: false, foregroundBackgroundJitter: 0, hueJitter: 0, saturationJitter: 0, brightnessJitter: 0,
};

const b = DEFAULT_BRUSH_TOOL_SETTINGS;

export const DEFAULT_BRUSHES: BrushToolSettings[] = [
  { ...b, name: 'ink' },
  { ...b, name: 'soft ink', minSize: 0.2, hardness: 0.5 },
  { ...b, name: 'soft', sizePressure: false, flowPressure: true, opacity: 0.5, hardness: 0 },
  { ...b, name: 'softer', sizePressure: false, flow: 0.3, flowPressure: true, opacity: 0.5, hardness: 0 },
  { ...b, name: 'rough', shape: 'brush' },
  { ...b, name: 'rougher', sizeJitter: 0.5, shape: 'brush' },
  { ...b, name: 'thick rough', sizePressure: false, flowPressure: true, shape: 'brush' },
  { ...b, name: 'thin graphite', size: 6, sizeJitter: 0.75, flowPressure: true, spacing: 0.3, shape: 'brush' },
  { ...b, name: 'pencil', size: 10, sizeJitter: 0.5, flowPressure: true, shape: 'pencil' },
  { ...b, name: 'soft pencil', size: 10, sizeJitter: 0.5, minSize: 0.2, flowPressure: true, opacity: 0.5, shape: 'pencil' },
  { ...b, name: 'splotchy', sizePressure: false, sizeJitter: 0.3, flow: 0.5, flowPressure: true, shape: 'paw' },
  { ...b, name: 'splatter', size: 40, sizeJitter: 0.5, normalSpread: 0.5, shape: 'splothes' },
  { ...b, name: 'pawprints', sizePressure: false, sizeJitter: 0.25, spacing: 1.75, separateSpread: true, normalSpread: 3, shape: 'paw', angleJitter: 0, angle: 1.57, angleToDirection: true },
  { ...b, name: 'hearts', sizePressure: false, sizeJitter: 0.3, flowPressure: true, spacing: 0.5, normalSpread: 3.5, shape: 'heart', angleJitter: 0.46 },
];

export const CACHE_ID_LENGTH = 5;

// New Features
export const TEST_NEW_LAYER_RATE_LIMIT = false;

// Development switches
export const TEST_PRESSURE_OPACITY = DEVELOPMENT && false;
export const TEST_PNG_DECODE = DEVELOPMENT && false;
export const TEST_ALPHA_SURFACES = DEVELOPMENT && false; // this won't work because webgl can't render to alpha texture
export const USE_SEPARATE_SOCKETS = DEVELOPMENT && false;
export const PENCIL_LOG = false;

// Test switches
export const USE_GL_IN_TESTS = false;

export const USER_BADGE_REGEX = /\[user\]\(([A-Za-z0-9]{24})\)/g;

export const DEFAULT_PRESENTATION_MODE_STATE: PresentationModeState = {
  host: undefined,
  followingHostLocationEnforced: true,
  followingHostViewportEnforced: true,
  participantsUiHidden: true,
  hostViewFilter: undefined,
  viewersUniqIds: []
};

// Filters constants
export const GAUSSIAN_BLUR_MIN = 0;
export const GAUSSIAN_BLUR_MAX = 100;
export const GAUSSIAN_BLUR_STEP = 0.1;
export const HUE_FILTER_MAX = 180;
export const HUE_FILTER_MIN = -180;
export const SATURATION_FILTER_MAX = 100;
export const SATURATION_FILTER_MIN = -100;
export const LIGHTNESS_FILTER_MAX = 100;
export const LIGHTNESS_FILTER_MIN = -100;
export const BRIGHTNESS_FILTER_MAX = 100;
export const BRIGHTNESS_FILTER_MIN = -100;
export const CONTRAST_FILTER_MAX = 100;
export const CONTRAST_FILTER_MIN = -100;
export const BILLING_REVALIDATE_TIME = 10 * DAY;

// Presentation Mode
export const PRESENTATION_VIEWER_INVITE_TIME = 30 * SECOND;
export const PRESENTATION_VIEWER_FORCED_INVITE_TIME = 20 * SECOND;
export const PRESENTATION_INACTIVE_HOST_TIME = 5 * MINUTE; // host AFK time
export const PRESENTATION_INACTIVE_HOST_MODAL_TIME = 3 * MINUTE;
export const PRESENTATION_JOIN_TIME = 20 * SECOND;
export const PRESENTATION_HOST_DISCONNECTED_TIME = 20 * SECOND;
export const PRESENTATION_TAKE_OVER_TIME = 3 * MINUTE;

