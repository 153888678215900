import { Component, EventEmitter, Output } from '@angular/core';
import { faTwitter, faInstagram, faFacebook, faYoutube, faLinkedin } from '../../../../common/icons';

@Component({
  selector: 'overloaded-modal',
  templateUrl: 'overloaded-modal.pug',
  styleUrls: ['overloaded-modal.scss'],
})
export class OverloadedModal {
  readonly twitterIcon = faTwitter;
  readonly instagramIcon = faInstagram;
  readonly facebookIcon = faFacebook;
  readonly youtubeIcon = faYoutube;
  readonly linkedinIcon = faLinkedin;
  @Output() close = new EventEmitter<void>();
  closeModal() {
    this.close.emit();
  }
}
