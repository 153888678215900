import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Key } from '../../../common/input';

@Component({
  selector: 'editable-text',
  templateUrl: 'editable-text.pug',
  styleUrls: ['editable-text.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableText {
  @Input() value = '';
  @Output() valueChange = new EventEmitter<string>();
  @Input() disabled = false;
  @Input() editing = false;
  @Output() editingChange = new EventEmitter<boolean>();
  @Input() maxlength = 100;
  @Input() minlength = 0;
  private toggleEditing(value: boolean) {
    if (this.editing !== value) {
      this.editing = value;
      this.editingChange.emit(value);
    }
  }
  edit(input: HTMLInputElement) {
    if (!this.disabled) {
      input.value = this.value;
      this.toggleEditing(true);

      setTimeout(() => {
        input.focus();
        input.select();
      }, 100);
    }
  }
  keydown(e: KeyboardEvent, value: string) {
    if (e.keyCode === Key.Enter) {
      this.finish(value);
    } else if (e.keyCode === Key.Esc) {
      this.toggleEditing(false);
    }
  }
  finish(value: string) {
    if (this.editing) {
      this.toggleEditing(false);
      if (value.length < this.minlength) {
        return;
      }

      if (this.value !== value) {
        this.value = value;
        this.valueChange.emit(value);
      }
    }
  }
}
