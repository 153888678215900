import { Settings, ToolId, Units, CursorsMode, FilterSettings } from './interfaces';
import { TOOL_SLOTS, DARK_BACKGROUND } from './constants';
import { arraysEqual, times } from './utils';
import { clamp, rangeMap } from './mathUtils';
import { removeItem, includes } from './baseUtils';
import { toolIdToString } from './toolIdUtils';
import { getDefaultLayout, isQwertz, isAzerty } from './input';
import { voiceChatUrl } from './data';
import { getLocale } from './keyboardUtils';
import { storageGetBoolean, storageGetEnum, storageGetJson, storageGetNumber, storageSetBoolean, storageSetItem, storageSetNumber } from '../services/storage';
import { parseColor } from './color';

const SETTINGS_VERSION = 17;
export const DEFAULT_PRIMARY_COLOR = '#1679ea';
export const DEFAULT_SECONDARY_COLOR = '#eab316';
const DEFAULT_EDITOR_BACKGROUND = DARK_BACKGROUND;
const DEFAULT_CHAT_X = 100; // %
const DEFAULT_CHAT_HEIGHT = 300; // px

export let settingsHideCursor = storageGetBoolean('hide-cursor');
export let settingsSliders = storageGetEnum('settings-sliders', ['advanced', 'simple', 'full']);
export let settingsColumns = storageGetBoolean('settings-columns');
export let settingsHideColumn = storageGetBoolean('settings-hide-column');
export let settingsHideBrushes = storageGetBoolean('settings-hide-brushes');
export let settingsMinimumPressure = storageGetNumber('settings-min-pressure');
export let settingsFilter = storageGetJson<FilterSettings>('settings-filter');

export function setHideCursor(value: boolean) {
  storageSetBoolean('hide-cursor', value);
  settingsHideCursor = value;
}

export function setSettingsSliders(value: string) {
  storageSetItem('settings-sliders', settingsSliders = value);
}

export function setSettingsColumns(value: boolean) {
  storageSetBoolean('settings-columns', settingsColumns = value);
}

export function setSettingsHideColumn(value: boolean) {
  storageSetBoolean('settings-hide-column', settingsHideColumn = value);
}

export function setSettingsHideBrushes(value: boolean) {
  storageSetBoolean('settings-hide-brushes', settingsHideBrushes = value);
}

export function setSettingsMinimumPressure(value: number) {
  storageSetNumber('settings-min-pressure', settingsMinimumPressure = value);
}

function defaultUnits(): Units {
  if (getLocale() === 'en-US') {
    return 'in';
  } else {
    return 'cm';
  }
}

export function defaultSettings(): Settings {
  return {
    version: SETTINGS_VERSION,
    primaryColor: parseColor(DEFAULT_PRIMARY_COLOR),
    secondaryColor: parseColor(DEFAULT_SECONDARY_COLOR),
    saveColorsPerSlot: false,
    slots: times(TOOL_SLOTS, i => i === 0 ? { activeTool: toolIdToString(ToolId.Brush) } : {}),
    activeSlot: 0,
    pixelGrid: true,
    sharpZoom: true,
    background: DEFAULT_EDITOR_BACKGROUND,
    chatX: DEFAULT_CHAT_X,
    chatHeight: DEFAULT_CHAT_HEIGHT,
    hideChatNotifications: false,
    disableTouch: false,
    disableHold: false,
    units: defaultUnits(),
    shortcuts: createDefaultKeyboardShortcuts(),
    keyboardLayout: undefined,
    cursors: CursorsMode.PointerAvatarName,
    fadeCursors: true,
    showCursor: false,
    showSequence: true,
    muteSounds: false,
    hideNewFeatureNotifications: false,
    keepActiveTool: false,
    pasteNewLayer: false,
    flipOnCursor: false,
    snapOnPanZoom: true,
    richTooltips: true,
    touchTap: ['', 'undo', 'redo', '', ''],
    // TODO: add support double tap with 2+ touches
    touchDoubleTap: ['flip-view', '', '', '', ''],
    touchLongPress: ['eyedropper', '', '', '', ''],
    // TODO: add touchDragAfterLongPress ?
    touchDrag: ['normal', 'pan-zoom', 'params', '', ''], // 4 & 5 are used by OS on ipad
    touchDragX: ['', '', 'size', '', ''],
    touchDragY: ['', '', 'opacity', '', ''],
    mouseRight: 'eyedropper',
    mouseMiddle: '',
    mouseButton4: '',
    mouseButton5: '',
    mouseWheel: 'zoom',
    mouseWheelCtrl: 'zoom',
    mouseWheelAlt: 'zoom',
  };
}

export function parseSettings(json: string | undefined | null): Settings {
  const settings = defaultSettings();

  if (!json) return settings;

  const data = JSON.parse(json) as Partial<Settings>;

  if (data.slots) {
    for (let i = 0; i < TOOL_SLOTS; i++) {
      const slot = data.slots[i];

      // remove broken slots
      if (!slot || !slot.tools || !Array.isArray(slot.tools) || !slot.tools.length || slot.tools.some(t => !t || !t.id)) {
        // DEVELOPMENT && console.log('skip slot', i, slot);
        continue;
      }

      // migration for old stabilizer settings v9 -> v10
      if (!data.version || data.version < 10) {
        data.slots[i]?.tools?.forEach((tool: any) => {
          if ('stabilize' in tool && 'stabilizerWeight' in tool) {
            tool.stabilize = tool.stabilize ? stabilizeOld2New(tool.stabilizerWeight) : 0;
            delete tool.stabilizerWeight;
            delete tool.stabilizerLevel;
          }
        });
      }

      // migration for old shape/brush indices (v12)
      const brushShapes = ['', 'brush', 'pencil', 'paw', 'heart', 'rect', 'triangle', 'splothes'];
      const shapeShapes = [
        'faStar', 'faHexagon', 'faOctagon', 'faComment', 'faCommentAlt', 'faHeart', 'shape-1', 'shape-2', 'shape-3',
        'shape-4', 'shape-5', 'shape-6', 'shape-7', 'shape-8', 'shape-9', 'shape-10', 'shape-11', 'shape-12',
        'shape-13', 'shape-14', 'shape-15', 'panels-1', 'panels-2', 'bubble-1', 'bubble-2', 'bubble-3', 'effect-1',
        'cloud-1', 'birds-1', 'birds-2', 'human-1', 'human-2', 'human-3', 'human-4', 'human-5', 'human-6', 'human-7',
        'human-8', 'human-9', 'human-10', 'tree-1', 'tree-2', 'tree-3', 'pebbles', 'field-1', 'field-2', 'path-1',
        'path-2', 'path-3', 'mountains-1', 'mountains-2', 'mountains-3', 'mountains-4', 'mountains-5', 'mountains-6',
        'mountains-7', 'mountains-8', 'mountains-9', 'mountains-10', 'mountains-11', 'mountains-12', 'mountains-13',
        'mountains-14', 'mountains-15', 'mountains-16', 'rocks-1', 'rocks-2', 'rocks-3', 'rocks-4', 'rocks-5',
        'rocks-6', 'dirt-1', 'castle-1', 'castle-2', 'castle-3', 'castle-4', 'buildings-1', 'buildings-2',
        'buildings-3', 'buildings-4', 'vehicle-1', 'vehicle-2', 'vehicle-3', 'buildings-5', 'buildings-6',
        'buildings-7', 'buildings-8', 'buildings-9', 'buildings-10', 'buildings-11', 'buildings-12', 'buildings-13',
        'buildings-14', 'buildings-15', 'buildings-16', 'buildings-17', 'buildings-18', 'buildings-19', 'city-1',
        'city-2', 'city-3', 'city-4', 'city-5', 'tower-1', 'tower-2', 'starships-1', 'starships-2'
      ];
      data.slots[i]?.tools?.forEach((tool: any) => {
        // migrate numbers to strings
        if ('shape' in tool && typeof tool.shape === 'number') {
          if (tool.id === toolIdToString(ToolId.Brush) || tool.id === toolIdToString(ToolId.Eraser)) {
            tool.shape = brushShapes[tool.shape] || brushShapes[0];
          } else if (tool.id === toolIdToString(ToolId.Shape)) {
            tool.shape = shapeShapes[tool.shape] || shapeShapes[0];
          }
        }

        // migrate old PDS IDs
        if ('shape' in tool && tool.id === toolIdToString(ToolId.Shape)) {
          if (shapeShapes.indexOf(tool.shape) > 5) { // is not fontawesome shape
            tool.shape = `pds-${tool.shape}`;
          }
        }
      });

      settings.slots[i] = data.slots[i] || settings.slots[i];
    }
  }

  settings.activeSlot = clamp(data.activeSlot ?? 0, 0, TOOL_SLOTS - 1);
  settings.view = data.view;
  settings.savedView = data.savedView;
  settings.primaryColor = data.primaryColor || settings.primaryColor;
  settings.secondaryColor = data.secondaryColor || settings.secondaryColor;
  settings.pixelGrid = !!(data.pixelGrid ?? settings.pixelGrid);
  settings.sharpZoom = !!(data.sharpZoom ?? settings.sharpZoom);
  settings.background = typeof data.background === 'string' ? data.background : settings.background;
  settings.chatX = data.chatX ?? settings.chatX;
  settings.chatHeight = data.chatHeight ?? settings.chatHeight;
  settings.hideChatNotifications = data.hideChatNotifications ?? settings.hideChatNotifications;
  settings.disableTouch = data.disableTouch ?? settings.disableTouch;
  settings.disableHold = data.disableHold ?? settings.disableHold;
  settings.units = (data.units === 'cm' || data.units === 'in') ? data.units : settings.units;
  settings.shortcuts = data.shortcuts ?? settings.shortcuts;
  settings.keyboardLayout = typeof data.keyboardLayout === 'string' ? data.keyboardLayout : settings.keyboardLayout;
  settings.cursors = data.cursors ?? settings.cursors;
  settings.fadeCursors = data.fadeCursors ?? settings.fadeCursors;
  settings.showCursor = data.showCursor ?? settings.showCursor;
  settings.showSequence = data.showSequence ?? settings.showSequence;
  settings.muteSounds = data.muteSounds ?? settings.muteSounds;
  settings.hideNewFeatureNotifications = data.hideNewFeatureNotifications ?? settings.hideNewFeatureNotifications;
  settings.keepActiveTool = data.keepActiveTool ?? settings.keepActiveTool;
  settings.pasteNewLayer = data.pasteNewLayer ?? settings.pasteNewLayer;
  settings.saveColorsPerSlot = data.saveColorsPerSlot ?? settings.saveColorsPerSlot;
  settings.flipOnCursor = data.flipOnCursor ?? settings.flipOnCursor;
  settings.snapOnPanZoom = data.snapOnPanZoom ?? settings.snapOnPanZoom;
  settings.richTooltips = data.richTooltips ?? settings.richTooltips;
  settings.touchTap = data.touchTap ?? settings.touchTap;
  settings.touchDoubleTap = data.touchDoubleTap ?? settings.touchDoubleTap;
  settings.touchLongPress = data.touchLongPress ?? settings.touchLongPress;
  settings.touchDrag = data.touchDrag ?? settings.touchDrag;
  settings.touchDragX = data.touchDragX ?? settings.touchDragX;
  settings.touchDragY = data.touchDragY ?? settings.touchDragY;
  settings.mouseRight = data.mouseRight ?? settings.mouseRight;
  settings.mouseMiddle = data.mouseMiddle ?? settings.mouseMiddle;
  settings.mouseButton4 = data.mouseButton4 ?? settings.mouseButton4;
  settings.mouseButton5 = data.mouseButton5 ?? settings.mouseButton5;
  settings.mouseWheel = data.mouseWheel ?? settings.mouseWheel;
  settings.mouseWheelCtrl = data.mouseWheelCtrl ?? settings.mouseWheelCtrl;
  settings.mouseWheelAlt = data.mouseWheelAlt ?? settings.mouseWheelAlt;

  // migration v0 -> v1 - fix not reversing keys on french keyboards, remove later
  if (!data.version && data.shortcuts && getDefaultLayout() === 'fr') {
    if (arraysEqual(settings.shortcuts.undo, ['ctrl+z'])) {
      settings.shortcuts.undo = ['ctrl+w'];
    }
    if (arraysEqual(settings.shortcuts.redo, [`ctrl+shift+z`, `ctrl+y`])) {
      settings.shortcuts.redo = [`ctrl+shift+w`, `ctrl+y`];
    }
    settings.version = 1;
  }

  // migration v1,v2,v3 -> v4 - add new shortcuts
  if ((data.version === 1 || data.version === 2 || data.version === 3) && data.shortcuts) {
    addShortcut(settings.shortcuts, 'zoom-in', 'ctrl+=');
    addShortcut(settings.shortcuts, 'zoom-in', 'cmd+=');
    addShortcut(settings.shortcuts, 'zoom-in', 'num+');
    addShortcut(settings.shortcuts, 'zoom-in', 'ctrl+num+');
    addShortcut(settings.shortcuts, 'zoom-in', 'cmd+num+');
    addShortcut(settings.shortcuts, 'zoom-out', 'ctrl+-');
    addShortcut(settings.shortcuts, 'zoom-out', 'cmd+-');
    addShortcut(settings.shortcuts, 'zoom-out', 'num-');
    addShortcut(settings.shortcuts, 'zoom-out', 'ctrl+num-');
    addShortcut(settings.shortcuts, 'zoom-out', 'cmd+num-');
    addShortcut(settings.shortcuts, 'toggle-layer-opacity-lock', '/');
    addShortcut(settings.shortcuts, 'merge-layer', 'ctrl+e');
    addShortcut(settings.shortcuts, 'merge-layer', 'cmd+e');
    addShortcut(settings.shortcuts, 'rotate-right', 'alt+f13');
    addShortcut(settings.shortcuts, 'rotate-left', 'alt+f14');
    addShortcut(settings.shortcuts, 'prev-layer', 'alt+[');
    addShortcut(settings.shortcuts, 'next-layer', 'alt+]');
    data.version = 4;
  }

  // migration v4,v5 -> v6 - add new shortcuts
  if ((data.version === 4 || data.version === 5) && data.shortcuts) {
    addShortcut(settings.shortcuts, 'prev-image', ',');
    addShortcut(settings.shortcuts, 'next-image', '.');
    data.version = 6;
  }

  // migration v6 -> v7 - add/change shortcuts
  if ((data.version === 6) && data.shortcuts) {
    if (includes(settings.shortcuts['duplicate-layer'] || [], 'ctrl+j')) {
      removeItem(settings.shortcuts['duplicate-layer'], 'ctrl+j');
    }
    addShortcut(settings.shortcuts, 'layer-via-copy', 'ctrl+j');
    addShortcut(settings.shortcuts, 'layer-via-cut', 'ctrl+shift+j');
    addShortcut(settings.shortcuts, 'add-layer', 'shift+n');
    data.version = 7;
  }

  // migration v7 -> v8 - remove save/restore-view shortcuts
  if ((data.version === 7) && data.shortcuts) {
    if (includes(settings.shortcuts['save-view'] || [], 'ctrl+`')) {
      removeItem(settings.shortcuts['save-view'], 'ctrl+`');
    }
    if (includes(settings.shortcuts['restore-view'] || [], '`')) {
      removeItem(settings.shortcuts['restore-view'], '`');
    }
    data.version = 8;
  }

  // migration v8 -> v9 - remove save/restore-view shortcuts
  if ((data.version === 8) && data.shortcuts) {
    if (includes(settings.shortcuts['save-view'] || [], 'cmd+`')) {
      removeItem(settings.shortcuts['save-view'], 'cmd+`');
    }
    data.version = 9;
  }

  // migration v9 -> v10
  if (data.version === 9) {
    data.version = 10;
  }

  // migration v10 -> v11
  if (data.version === 10) {
    if (IS_PORTAL && settings.cursors === CursorsMode.PointerName) {
      settings.cursors = CursorsMode.PointerAvatarName;
    }
    data.version = 11;
  }

  // migration v11 -> v12
  if (data.version === 11) {
    if (IS_PORTAL && settings.cursors === CursorsMode.PointerName) {
      settings.cursors = CursorsMode.PointerAvatarName;
    }
    data.version = 12;
  }

  // migration v12 -> v13
  // TODO: update this when releasing comments, remove feature check here
  if (data.version === 12) {
    if (IS_PORTAL) {
      addShortcut(settings.shortcuts, 'comment', 'c');
      addShortcut(settings.shortcuts, 'toggle-threads-visibility', 'shift+c');
    }
    data.version = 13;
  }


  if (data.version === 13) {
    if (typeof settings.primaryColor === 'string') settings.primaryColor = parseColor(settings.primaryColor as any);
    if (typeof settings.secondaryColor === 'string') settings.secondaryColor = parseColor(settings.secondaryColor as any);
    data.version = 14;
  }

  if (data.version === 14) {
    if (IS_PORTAL) {
      addShortcut(settings.shortcuts, 'ai', 'a');
    }
    data.version = 15;
  }

  if (data.version === 15) {
    if (IS_PORTAL) {
      addShortcut(settings.shortcuts, 'repeat-last-filter', 'ctrl+alt+f');
    }
    data.version = 16;
  }

  if (data.version === 16) {
    if (IS_PORTAL) {
      addShortcut(settings.shortcuts, 'text', 't');
    }
    data.version = 17;
  }

  // TODO: maybe remove this
  // add push-to-talk shortcut if it's not added and the key is not in-use
  if (IS_PORTAL && voiceChatUrl && !settings.shortcuts['push-to-talk']?.length) {
    addShortcut(settings.shortcuts, 'push-to-talk', '`');
  }

  // deduplicate shortcuts
  for (const key of Object.keys(settings.shortcuts)) {
    const shortcuts = settings.shortcuts[key];

    if (shortcuts) {
      for (let i = shortcuts.length - 1; i >= 0; i--) {
        if (shortcuts.indexOf(shortcuts[i]) < i) {
          shortcuts.splice(i, 1);
        }
      }
    }
  }

  return settings;
}

function addShortcut(shortcuts: { [key: string]: string[] | undefined; }, key: string, shortcut: string) {
  if (!isShortcutInUse(shortcuts, shortcut)) {
    if (!shortcuts[key]) shortcuts[key] = [];
    shortcuts[key]!.push(shortcut);
  }
}

function isShortcutInUse(shortcuts: { [key: string]: string[] | undefined; }, shortcut: string) {
  return Object.keys(shortcuts).some(key => includes(shortcuts[key], shortcut));
}

export function createDefaultKeyboardShortcuts() {
  const layout = getDefaultLayout();
  const qwertz = isQwertz(layout);
  const azerty = isAzerty(layout);

  // swap these for german & french users, since they come to expect using undo/redo in reverse
  const z = qwertz ? 'y' : (azerty ? 'w' : 'z');
  const y = qwertz ? 'z' : 'y';

  const shortcuts: { [key: string]: string[]; } = {
    // commands
    'save': ['ctrl+s'],
    'save-selection': ['alt+s'],
    'share': ['ctrl+shift+s'],
    'share-selection': ['alt+shift+s'],
    'undo': [`ctrl+${z}`],
    'redo': [`ctrl+shift+${z}`, `ctrl+${y}`],
    'switch-colors': ['x'],
    'chat': ['enter', 'numenter'],
    'prev-size': ['['],
    'next-size': [']'],
    'tool-preset-1': ['1'],
    'tool-preset-2': ['2'],
    'tool-preset-3': ['3'],
    'tool-preset-4': ['4'],
    'tool-preset-5': ['5'],
    'tool-preset-6': ['6'],
    'zoom-in': ['=', 'ctrl+=', 'num+', 'ctrl+num+'],
    'zoom-out': ['-', 'ctrl+-', 'num-', 'ctrl+num-'],
    'flip-horizontally': ['h'],
    'reset-rotation': ['esc'],
    'rotate-right': ['alt+f13'],
    'rotate-left': ['alt+f14'],
    'fit-on-screen': ['home'],
    'actual-pixels': ['end'],
    'full-screen': ['f11'],
    'repeat-last-filter': ['ctrl+alt+f', 'ctrl+cmd+f'],
    // 'save-view': ['ctrl+`'],
    // 'restore-view': ['`'],
    'toggle-ui': ['tab'],
    'select-all': ['ctrl+a'],
    'deselect': ['ctrl+d'],
    'invert-selection': ['ctrl+shift+i'],
    'delete-selection': ['delete'],
    'add-layer': ['shift+n'],
    // 'clear-layer': ['d'],
    'transfer-layer': ['f'],
    'merge-layer': ['ctrl+e'],
    'toggle-layer-clipping-group': ['ctrl+alt+g'],
    'toggle-layer-opacity-lock': ['ctrl+b', '/'],
    'prev-layer': ['alt+['],
    'next-layer': ['alt+]'],
    'layer-via-copy': ['ctrl+j'],
    'layer-via-cut': ['ctrl+shift+j'],
    'prev-image': [','],
    'next-image': ['.'],
    'cut': ['ctrl+x'],
    'copy': ['ctrl+c'],
    'copy-merged': ['ctrl+shift+c'],
    'paste': ['ctrl+v'],
    'paste-in-place': ['ctrl+shift+v'],
    // tools
    'brush': ['b'],
    'eraser': ['e'],
    'eyedropper': ['i'],
    'hand': ['space'],
    'lassoSelection': ['l'],
    'move': ['v'],
    'paintbucket': ['g'],
    'pencil': ['n', 'p'],
    'rect': ['u'],
    'rotateView': ['r'],
    'selection': ['m'],
    'transform': ['shift+t'],
    'zoom': [z, 'ctrl+space'],
  };

  if (IS_PORTAL) {
    // shortcuts['back-to-folder'] = ['alt+b'];
    shortcuts['toggle-threads-visibility'] = ['shift+c'];
    shortcuts['comments'] = ['c'];
    shortcuts['ai'] = ['a'];
    shortcuts['text'] = ['t'];
  }

  for (const key of Object.keys(shortcuts)) {
    const list = shortcuts[key];

    for (let i = list.length - 1; i >= 0; i--) {
      if (list[i].indexOf('ctrl+') !== -1) {
        list.push(list[i].replace('ctrl+', 'cmd+'));
      }
    }
  }

  return shortcuts;
}

function stabilizeOld2New(input: number) {
  const input_start = 20;
  const input_end = 80;
  const output_start = 0.01;
  const output_end = 1;
  return rangeMap(input_start, input_end, output_start, output_end, clamp(input, input_start, input_end));
}
