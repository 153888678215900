import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tag-list-modal',
  templateUrl: 'tag-list-modal.component.pug',
})
export class TagListModal {
  @Output() close = new EventEmitter<string[]>();
  @Input() data!: string[];

  tags = '';

  ngOnInit() {
    this.tags = this.data.join(' ');
  }

  closeModal() {
    this.close.emit();
  }

  saveChanges() {
    this.close.emit(this.tags.split(/\s+/g).map(x => x.trim()).filter(x => x));
  }
}
