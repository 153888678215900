import { charactersToWords, TextCharacter } from './text-character';
import { TextAlignment } from './formatting';
import { Rect } from '../interfaces';
import { Paragraph } from './paragraph';
import { createRect } from '../rect';
import { max, min, sum } from '../mathUtils';
import { Mandatory } from '../typescript-utils';

export function findLineIndexWithCharacter(lines: Line[], character: TextCharacter): number | undefined {
  const index = lines.findIndex(line => line.breaklineAt >= character.index);
  return index !== -1 ? index : undefined;
}

export function findLineDataWithCharacter(lines: Line[], character: TextCharacter): Line | undefined {
  return lines.find(line => line.breaklineAt >= character.index);
}

export enum BreaklineStrategies {
  perLetter = 'perLetter',
  perWord = 'perWord',
}

export interface Line {
  startAt: number; // first TextCharacter absolute index
  breaklineAt: number;  // last TextCharacter absolute index

  baseline: number; // absolute y coordinate in drawing space of baseline of drawing glyphs
  glyphsWidth: number; // sum of glyphs width accounting for base glyph widths, kerning and formattings like bold/scale-x/letter-spacing etc.
  alignment: TextAlignment; // keep in mind lines in justified paragraphs (except for last) are converted to fully-justified
  bbox: Rect; // accounts for highest ascender and lowest descender, completely separate from lineheight, defines characters bboxes

  height: number; // height from previous baseline to this one, not equal to bbox.h
  descender: number; // pixels visible below baseline

  justifiedSpaces?: number[]; // present and used only for justified paragraphs. Stores distance cursor should advance on each space in each line
  regularSpaces?: number[]; // present and used only for justified paragraphs. Stores regular spaces advancement before converting them to justification

  wordsCount: number; // used for defining when to stretch words (if fully justified & single word in line)
}

export type JustifiedLine = Mandatory<Line, 'justifiedSpaces' | 'regularSpaces'>;

const determineLineheight = (line: Line, characters: TextCharacter[]) => {
  const explicitLineheights = characters.filter(c => c.formatting.lineheight !== undefined).map(c => c.formatting.lineheight) as number[];
  const charLineHeights = characters.map(c => c.fontStyle.lineheight(c.size));
  if (explicitLineheights.length > 0) {
    line.height = max(explicitLineheights);
  } else {
    line.height = Math.max(max(charLineHeights), 0);
  }
  line.bbox.y += line.height;
  line.baseline += line.height;
};

const determineLineheightForFirstLine = (line: Line, characters: TextCharacter[]) => {
  const capHeights = characters.map(c => c.fontStyle.capHeight(c.size));
  line.height = Math.max(max(capHeights), 0);

  line.bbox.y += line.height;
  line.baseline += line.height;
};

const determineLineDescender = (line: Line, characters: TextCharacter[]) => {
  const descenders = characters.map(c => c.fontStyle.descender(c.size));
  line.descender = min(descenders);
};

const determineLineBbox = (line: Line, characters: TextCharacter[]) => {
  const { baseline } = line;
  const bbox = createRect(0,0,0, 0);

  const glyphTops = [];
  const glyphBottoms = [];

  for (let c of characters) {
    const scaledAscender = (c.fontStyle.ascender(c.size) * (c.formatting.scaleY || 1));
    const scaledDescender = (c.fontStyle.descender(c.size)) * (c.formatting.scaleY || 1);
    glyphTops.push(Math.min(baseline - scaledAscender, baseline - (c.formatting.baselineShift || 0) - scaledAscender));
    glyphBottoms.push(Math.max(baseline - scaledDescender, baseline - (c.formatting.baselineShift || 0) - scaledDescender));
  }

  const toppestTop = min(glyphTops);
  const bottomestBottom = max(glyphBottoms);

  bbox.y = toppestTop;
  bbox.h = Math.max(bottomestBottom - bbox.y, 0);
  bbox.w = Math.max(line.glyphsWidth, 0);
  bbox.x = 0;

  line.bbox = bbox;
};

const createEmptyLine = (partial: Partial<Line>): Line => {
  return {
    alignment: TextAlignment.LeftAligned,
    startAt: -1,
    baseline: -1,
    breaklineAt: -1,
    glyphsWidth: 0,
    justifiedSpaces: [],
    regularSpaces: [],
    wordsCount: 0,
    height: 0,
    descender: 0,
    bbox: createRect(0,0,0,0),
    ...partial,
  };
};

export const shouldBreakline = (character: TextCharacter, line: Line): boolean => {
  return character.index === line.breaklineAt;
};

export const isFirstCharacter = (character: TextCharacter, line: Line): boolean => {
  return character.index === line.startAt;
};

export const isEmptyLine = (line: Line) => {
  return line.startAt === line.breaklineAt;
};

export const shouldStretch = (line: Line): boolean => {
  return line.wordsCount === 1 && (line.alignment === TextAlignment.FullyJustified || line.alignment === TextAlignment.LeftJustified || line.alignment === TextAlignment.CenterJustified || line.alignment === TextAlignment.RightJustified);
};

export class LineMeasuringService {
  savedLines: Line[] = [];
  currentLine: Line | undefined = undefined;
  private charactersInLine: TextCharacter[] = [];

  lineWasJustBroken = false;

  constructor(private previouslySavedLines: Line[]) { }

  storeCharacter(paragraph: Paragraph, character: TextCharacter, y: number) {
    if (!this.currentLine) this.startNewLine(paragraph, character, y);
    this.charactersInLine.push(character);
  }

  private beforeSubmitLine(line: Line, breaklineAt: number, glyphsWidth: number) {
    line.glyphsWidth = glyphsWidth;
    line.breaklineAt = breaklineAt;

    determineLineBbox(line, this.charactersInLine);
    if (this.previouslySavedLines.length === 0 && this.savedLines.length === 0) {
      determineLineheightForFirstLine(line, this.charactersInLine);
    } else {
      determineLineheight(line, this.charactersInLine);
    }
    determineLineDescender(line, this.charactersInLine);

    line.wordsCount = charactersToWords(this.charactersInLine).length;
  }

  submitLine (breaklineAt: number, glyphsWidth: number) {
    if (this.currentLine) {
      this.beforeSubmitLine(this.currentLine, breaklineAt, glyphsWidth);
      this.savedLines.push(this.currentLine);
      this.lineWasJustBroken = true;
      this.currentLine = undefined;
      this.charactersInLine = [];
    } else {
      DEVELOPMENT && console.warn('Requested line measured submission but currently measured line is undefined.');
    }
  }

  submitLineAtPreviousWhitespace(previousWhitespace: PreviousWhitespaceTracker){
    const { absoluteIndex, lineLength } = previousWhitespace;
    this.charactersInLine = this.charactersInLine.filter((c) => c.index <= previousWhitespace.absoluteIndex);
    this.submitLine(absoluteIndex, lineLength);
  }

  startNewLine(paragraph: Paragraph, character: TextCharacter, y: number) {
    this.currentLine = createEmptyLine({
      alignment: paragraph.formatting.alignment,
      startAt: character.index,
      baseline: y,
    });
  }

  consumeJustificationData(justifiedSpaces: number[][], regularSpaces: number[][], paragraphAlignment: TextAlignment) {
    this.savedLines = this.savedLines.map((line, index) => {
      line.justifiedSpaces = justifiedSpaces[index];
      line.regularSpaces = regularSpaces[index];
      const isLastLine = index === this.savedLines.length - 1;
      if (isLastLine && line.wordsCount > 1 && paragraphAlignment !== TextAlignment.FullyJustified) { // we assume this function will only be called for justified paragraphs, therefore no aligned check
        line.glyphsWidth += sum(line.regularSpaces || []);
      } else {
        line.bbox.w += sum(line.justifiedSpaces || []);
      }
      return line;
    });
  }
}

export class PreviousWhitespaceTracker {
  absoluteIndex = 0;
  relativeIndex = 0;
  lineLength = 0;
  spaceExisting = false;

  reset() {
    this.absoluteIndex = 0;
    this.relativeIndex = 0;
    this.lineLength = 0;
    this.spaceExisting = false;
  }

  set(absoluteIndex: number, relativeIndex: number, lineLength: number) {
    this.absoluteIndex = absoluteIndex;
    this.relativeIndex = relativeIndex;
    this.lineLength = lineLength;
    this.spaceExisting = true;
  }
}

export const findSpaceIndexInLine = (characters: TextCharacter[], line: Line, character: TextCharacter) => {
  const startIndex = characters.findIndex(c => c.index === line.startAt);
  const endIndex = characters.findIndex(c => c.index === line.breaklineAt) + 1;
  const charactersInLine = characters.slice(startIndex, endIndex);
  let i = 0;
  for (const char of charactersInLine) {
    if (char.isWhitespace) {
      if (char.index === character.index) break;
      i++;
    }
  }
  return i;
};

export const justifyOneLine = (spaces: number[], emptySpace: number, ignoreLastValue: boolean) => {
  const spacesInLine = spaces.slice();

  if (ignoreLastValue) {
    spacesInLine[spacesInLine.length - 1] = 0;  // last one is zeroed since it will not be rendered
  }

  const sumOfRegularSpaces = sum(spacesInLine);

  return spacesInLine.map((spaceWidth) => {
    if (sumOfRegularSpaces > 0) {
      return emptySpace * spaceWidth / sumOfRegularSpaces;
    } else {
      return 0;
    }
  });
};

export class JustifiedSpacesTracker {
  regularSpacesWidths: number[] = [];
  readonly measuredLines: number[][] = [];

  addNewSpace(spaceWidth: number) {
    this.regularSpacesWidths.push(spaceWidth);
  }

  submitLine() {
    this.measuredLines.push(this.regularSpacesWidths);
    this.regularSpacesWidths = [];
  }

  getJustifiedSpacesWidths(textareaWidth: number, lineMeasurer: LineMeasuringService, breaklineStrategy: BreaklineStrategies) {
    return this.measuredLines.map((spacesInLine, index, array) => {
      const emptySpace = textareaWidth - lineMeasurer.savedLines[index].glyphsWidth;
      let shouldIgnoreLastValue = true;
      if (breaklineStrategy === BreaklineStrategies.perLetter) shouldIgnoreLastValue = false;
      if (index === array.length - 1) shouldIgnoreLastValue = false;
      return this.computeOneLine(spacesInLine, emptySpace, shouldIgnoreLastValue);
    });
  }

  removeLastSpace() {
    this.regularSpacesWidths.pop();
  }

  computeOneLine(spaces: number[], emptySpace: number, ignoreLastValue: boolean) {
    return justifyOneLine(spaces, emptySpace, ignoreLastValue);
  }

  shouldBreakline(emptySpace: number) {
    return emptySpace < sum(this.regularSpacesWidths);
  }
}

export interface LineStretchingIteration {
  line: Line & { bbox: never };
  lineIndex: number;
  character: TextCharacter;
  previous: TextCharacter | undefined;
  lineAdvancement: number;
  glyphAdvancement: number;
  artificialAdvancementsRegular: number[];
}
