import { UserData, Layer, User, IToolEditor, Drawing, Viewport } from './interfaces';
import { createDecoder, decodePressure } from './compressor';
import { History } from './history';
import { includes } from './baseUtils';
import { createSurface, hasIdentityTransform, hasZeroTransform } from './toolSurface';
import { MockHistory } from './mockHistory';
import type { Editor } from '../services/editor';
import { createMask, clearMask, cloneMask, transformAndClipMask, isMaskEmpty } from './mask';
import { screenToDocumentPoint } from './viewport';
import { createPoint } from './point';
import { colorToFloats, parseColorFast } from './color';
import { DEFAULT_USER_COLOR } from './constants';
import { getAvatarPath } from './utils';
import { loadImage } from './canvasUtils';
import { cloneRect, createRect } from './rect';

export function createUser(uniqId: string, localId: number, name = ''): User {
  return {
    uniqId,
    localId,
    name,
    email: undefined,
    anonymous: true,
    anonymousNumber: 0,
    isSuperAdmin: false,
    color: DEFAULT_USER_COLOR,
    colorFloat: new Float32Array(4),
    avatar: undefined,
    avatarImage: undefined,
    avatarVideo: undefined,
    screenshareVideo: undefined,
    talking: false,
    featureFlags: new Set(),
    role: 'all',
    activeLayer: undefined,
    activeLayerId: 0,
    ownedLayers: [],
    recentDrawings: undefined,
    activeTool: undefined,
    lastTool: undefined,
    lastToolStartData: undefined,
    selection: createMask(),
    lastSelection: createMask(),
    showTransform: undefined,
    decoder: undefined,
    history: new MockHistory(),
    surface: createSurface(),
    isCreator: false,
    cursorX: 1e9,
    cursorY: 1e9,
    cursorAlpha: 1,
    cursorDelay: 0,
    cursorLastUpdate: 0,
    lastX: 1e9,
    lastY: 1e9,
    readOnly: false,
    chunkedData: new Map(),
    userJob: undefined,
    receiveEmails: undefined,
    pro: undefined,
    tags: [],
  };
}

export function createAndInitUser(uniqId: string, localId: number, state?: UserData, editor?: Editor): User {
  const user = createUser(uniqId, localId);
  const voiceChat = editor?.model.voiceChat;
  if (voiceChat) {
    Object.defineProperty(user, 'avatarVideo', {
      get() {
        return voiceChat.videoForUser(user);
      },
    });
  }

  if (state) setUserState(user, state);
  if (editor) user.history = new History(user, editor);
  user.decoder = createDecoder((x, y, p) => {
    user.lastX = x;
    user.lastY = y;
    user.activeTool?.move?.(x, y, decodePressure(p));
  });
  return user;
}

const tempPt = createPoint(0, 0);

export function lastXYToCursorXY(user: User, view: Viewport | undefined) {
  tempPt.x = user.lastX;
  tempPt.y = user.lastY;
  if (view) screenToDocumentPoint(tempPt, view);
  user.cursorX = tempPt.x;
  user.cursorY = tempPt.y;
}

// TODO: remove this so we don't have to add new fields all the time
//       this also will not unset fields
export function setUserState(user: User, state: UserData) {
  user.accountId = state.accountId;
  user.name = state.name ?? '';
  user.email = state.email;
  user.color = state.color ?? '';
  colorToFloats(user.colorFloat, parseColorFast(user.color));
  user.avatar = state.avatar;
  user.role = state.role ?? 'all';
  user.featureFlags = new Set(state.featureFlags ?? []);
  user.anonymous = state.anonymous ?? false;
  user.anonymousNumber = state.anonymousNumber ?? 0;
  user.isSuperAdmin = state.isSuperAdmin ?? false;
  user.ownedLayers = state.ownedLayers ?? [];
  user.readOnly = state.readOnly ?? false;
  user.userJob = state.userJob;
  user.workTags = state.workTags;
  user.receiveEmails = state.receiveEmails;
  user.tags = state.tags;

  // TODO: these fields are optional
  user.recentDrawings = state.recentDrawings ?? user.recentDrawings;
  user.activeLayerId = state.activeLayerId ?? 0;
  user.isCreator = state.isCreator ?? false;
  user.subscriptionStatus = state.subscriptionStatus;
  user.pro = state.pro;
  user.proSources = state.proSources;
  user.adminUrl = state.adminUrl ?? user.adminUrl;
  user.brushSets = state.brushSets;
  user.brushShapeSets = state.brushShapeSets;
  user.shapeSets = state.shapeSets;

  if (state.created) user.created = Date.parse(state.created);
}

export function loadUserAvatarImage(user: User) {
  if (TESTS) return;
  const src = getAvatarPath(user.avatar, 64);

  if (!user.avatarImage || user.avatarImage.src !== src) {
    loadImage(src)
      .then(image => user.avatarImage = image)
      .catch(e => DEVELOPMENT && console.error(e));
  }
}

export function setActiveLayer(user: User, layer: Layer | undefined) {
  user.activeLayer = layer;
  user.activeLayerId = layer ? layer.id : 0;
}

export function userOwnsLayer(user: User, layerId: number) {
  return includes(user.ownedLayers, layerId);
}

export function userOwnsAnyLayers(user: User, drawing: Drawing) {
  return drawing.layers.some(l => l.owner === user);
}

export function releaseUser(user: User, editor: IToolEditor | undefined) {
  user.history.clear();
  editor?.renderer?.releaseUserCanvas(user);
}

export function resetUser(user: User, editor: IToolEditor | undefined) {
  user.activeTool = undefined;
  user.ownedLayers = [];
  user.role = 'all';
  user.isCreator = false;
  user.subscriptionStatus = undefined;
  setActiveLayer(user, undefined);
  clearMask(user.selection);
  releaseUser(user, editor);
}

export function clearUsers(list: User[], editor?: IToolEditor) {
  list.forEach(u => resetUser(u, editor));
  list.length = 0;
}

export function releaseToolRenderingContext(user: User) {
  if (DEVELOPMENT && !user.surface.context) throw new Error('Rendering context already released');

  if (user.surface.context) {
    try {
      user.surface.context.flush();
      user.surface.context.dispose();
    } catch (e) {
      console.error(e);
    }

    user.surface.context = undefined;
  }
}

export function getTransformedSelection(user: User) {
  const selection = cloneMask(user.selection);
  transformAndClipMask(selection, user.surface);
  return selection;
}

export function getTransformedSelectionBounds(user: User) {
  if (isMaskEmpty(user.selection) || hasZeroTransform(user.surface)) {
    return createRect(0, 0, 0, 0);
  } else if (hasIdentityTransform(user.surface)) {
    return cloneRect(user.selection.bounds);
  } else {
    return getTransformedSelection(user).bounds;
  }
}
