import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FeatureBadgePresets } from '../../feature-badge/feature-badge';

export interface DisabledOnMobileModalData {
  disabledThingName: string;
  description: string;
  badge?: FeatureBadgePresets;
  video?: string;
  learnMore?: string;
}

const DEFAULT_DISABLED_ON_MOBILE_MODAL_DATA: DisabledOnMobileModalData = {
  disabledThingName: 'This feature',
  description: 'This functionality is only available on desktop devices. We will notify you as soon as it becomes available on mobile devices.'
};

@Component({
  selector: 'disabled-on-mobile-modal',
  templateUrl: './disabled-on-mobile-modal.pug',
  styleUrls: ['./disabled-on-mobile-modal.scss'],
})
export class DisabledOnMobileModal {
  @Input() data: DisabledOnMobileModalData = DEFAULT_DISABLED_ON_MOBILE_MODAL_DATA;
  @Output() close = new EventEmitter<void>();

  onClose() {
    this.close.emit();
  }
}
