<div class="page-content"><section class="mb-5"><h2>Stylus pressure support</h2><browser-support class="mx-auto my-4"></browser-support></section><section class="mb-5"><h2>Known issues</h2><h4>Graphical glitches or drawing lag</h4><p>You may experience glitches and slow performance on some devices or old graphics card drivers.
Make sure your device and graphics card drivers are up to date.
If updating doesn't help, try switching on <b>Disable graphics acceleration</b> option in <b>Settings</b> (in <b>Edit</b> menu).
</p><h4>Copy and paste not working</h4><p>Copy and paste is only supported in <b>Chrome</b>, <b>Opera</b> and <b>Edge (79+)</b> browsers at the moment,
you can still paste images in other browsers by dragging them from your desktop onto the canvas.
</p><h4>ALT and right mouse button key not working on Opera</h4><p><b>Opera</b> browser does not allow websites to use <kbd class="sys">Alt</kbd> key or right mouse button so all actions using
those buttons will not work correctly on <b>Opera</b> browser.
</p><h4>Short brush strokes or dots connecting with unwanted lines</h4><p>This issues is caused by <b>Tip Double Click Distance</b> pen setting.
Go to your pen settings, find <b>Tip Double Click Distance</b> slider and move it all the way to <b>Off</b>.
</p><h4>Drawing delay on Chrome and Firefox</h4><p>You may experience a delay on <b>Chrome</b> &amp; <b>Firefox</b> browsers on <b>Windows</b> systems when
using a drawing tablet.
To fix the issue you need to install one of the plugins mentioned in the section above or switch to 
<b>Edge</b> or <b>Internet Explorer 11</b> browser.
</p></section><section class="mb-5"><h2>Users, Admins and permission management</h2><p>To enable permission management use <span class="btn btn-sm btn-prime pointer-events-none">Become admin of this drawing</span>
button in <b>Drawing Settings</b>. In order to be able to become an admin you need to satisfy following conditions.</p><ul><li>You need to be signed-in with registered account <em>(anonymous users can't become admins)</em></li><li>You have to be the creator of the drawing or the drawing has to be empty</li><li>There can't be any other admins already set for the drawing</li></ul><p>If you're an admin of the drawing you get following options available to you:</p><ul><li>You can limit permissions for certain actions by using <b>Admin</b> menu.</li><li>You can promote other users to admins and verified users by clicking on their user icon.</li><li>You can kick and block users from drawing by clicking on their user icon.</li><li>You can control ownership of the layers by using right-click menu on the layers.</li><li>You can remove your Admin status by using <b>Admin</b> menu.</li></ul><p>Permission and user management is not required in most cases, and should only be used when necessary.
</p></section><section class="mb-5"><h2>Layer management</h2><h4>Layer ownership states</h4><table class="layer-states"><tbody><tr><td><div class="layer-item-mockup" [agSortable]="mock"><layer-item [layer]="ownActiveLayer" [forceActive]="true"></layer-item></div></td><td><b>Your</b> own <b>active</b> layer that you're currently drawing on.</td></tr><tr><td><div class="layer-item-mockup" [agSortable]="mock"><layer-item [layer]="ownInactiveLayer"></layer-item></div></td><td><b>Your</b> own <b>inactive</b> layer, you can switch to it at any time.</td></tr><tr><td><div class="layer-item-mockup" [agSortable]="mock"><layer-item [layer]="freeLayer"></layer-item></div></td><td><b>Free</b> layer without any owner, you can take it over by double clicking it.
<br>
(drawing permissions or layer limit can prevent you from taking over free layers)</td></tr><tr><td><div class="layer-item-mockup" [agSortable]="mock"><layer-item [layer]="otherLeftLayer"></layer-item></div></td><td><b>Free</b> layer without any owner, you can take it over by double clicking it.
<br>
(crossed out name specifies last owner of the layer)</td></tr><tr><td><div class="layer-item-mockup" [agSortable]="mock"><layer-item [layer]="otherActiveLayer" [forceActive]="true"></layer-item></div></td><td><b>Active</b> layer owned by <b>other user</b>. (you can only take it over if you are given special permissions)</td></tr><tr><td><div class="layer-item-mockup" [agSortable]="mock"><layer-item [layer]="otherInactiveLayer"></layer-item></div></td><td><b>Inactive</b> layer owned by <b>other user</b>. (you can only take it over if you are given special permissions)</td></tr></tbody></table></section><section class="mb-5"><h2>Keyboard shortcuts</h2><p>Shortcuts can be customized in <b>Application settings</b> window. If the shortcuts are shown incorrectly
make sure the keyboard layout is set correctly in <b>Application settings</b> shortcuts section.
</p><h4>General</h4><ul class="spaced"><li><kbd-shrtcts id="prev-size"></kbd-shrtcts> and <kbd-shrtcts id="next-size"></kbd-shrtcts> - change brush size</li><li><kbd-shrtcts id="switch-colors"></kbd-shrtcts> to switch primary and secondary color</li><li><kbd><svg-icon [icon]="leftIcon" [autoWidth]="true"></svg-icon></kbd>
<kbd class="ml-1"><svg-icon [icon]="upIcon" [autoWidth]="true"></svg-icon></kbd>
<kbd class="ml-1"><svg-icon [icon]="rightIcon" [autoWidth]="true"></svg-icon></kbd>
<kbd class="ml-1"><svg-icon [icon]="downIcon" [autoWidth]="true"></svg-icon></kbd>
- move view when using any of the navigation tools, move selected area or entire layer when using move tool,
move selection otherwise (hold <kbd class="sys">Shift</kbd> for larger move)</li><li><kbd class="sys">Ctrl</kbd> + 
<kbd><svg-icon [icon]="leftIcon" [autoWidth]="true"></svg-icon></kbd>
<kbd class="ml-1"><svg-icon [icon]="upIcon" [autoWidth]="true"></svg-icon></kbd>
<kbd class="ml-1"><svg-icon [icon]="rightIcon" [autoWidth]="true"></svg-icon></kbd>
<kbd class="ml-1"><svg-icon [icon]="downIcon" [autoWidth]="true"></svg-icon></kbd>
- move selection when using any of the navigation tools,
move selected area or entire layer otherwise (hold <kbd class="sys">Shift</kbd> for larger move)</li><li><kbd-shrtcts id="chat"></kbd-shrtcts> - open chat box</li><li><kbd class="sys">Esc</kbd> (while in chat) - close chat box</li><li><kbd-shrtcts id="tool-preset-1"></kbd-shrtcts>
<kbd-shrtcts class="ml-1" id="tool-preset-2"></kbd-shrtcts>
<kbd-shrtcts class="ml-1" id="tool-preset-3"></kbd-shrtcts>
<kbd-shrtcts class="ml-1" id="tool-preset-4"></kbd-shrtcts>
<kbd-shrtcts class="ml-1" id="tool-preset-5"></kbd-shrtcts>
<kbd-shrtcts class="ml-1" id="tool-preset-6"></kbd-shrtcts>
- switch tool preset</li><li><kbd-shrtcts id="toggle-ui"></kbd-shrtcts> - hide UI</li><li>Hold <kbd class="sys">Shift</kbd> when adjusting slider values to do fine adjustments</li></ul><h4>Drawing</h4><ul class="spaced"><li><kbd-shrtcts id="save"></kbd-shrtcts> - Save</li><li><kbd-shrtcts id="save-selection"></kbd-shrtcts> - Save selection</li><li><kbd-shrtcts id="share"></kbd-shrtcts> - Share</li><li><kbd-shrtcts id="share-selection"></kbd-shrtcts> - Share selection</li></ul><h4>Edit</h4><ul class="spaced"><li><kbd-shrtcts id="undo"></kbd-shrtcts> - Undo</li><li><kbd-shrtcts id="redo"></kbd-shrtcts> - Redo</li><li><kbd-shrtcts id="cut"></kbd-shrtcts> - Cut</li><li><kbd-shrtcts id="copy"></kbd-shrtcts> - Copy</li><li><kbd-shrtcts id="copy-merged"></kbd-shrtcts> - Compy merged</li><li><kbd-shrtcts id="paste"></kbd-shrtcts> - Paste</li><li><kbd-shrtcts id="paste-in-place"></kbd-shrtcts> - Paste in place</li><li><kbd-shrtcts id="select-all"></kbd-shrtcts> - select all</li><li><kbd-shrtcts id="deselect"></kbd-shrtcts> - deselect</li><li><kbd-shrtcts id="invert-selection"></kbd-shrtcts> - invert selection</li><li><kbd-shrtcts id="delete-selection"></kbd-shrtcts> - delete selected region</li></ul><h4>View</h4><ul class="spaced"><li>hold <kbd-shrtcts id="hand"></kbd-shrtcts> to be able to drag canvas using mouse and zoom using mouse wheel (hold <kbd class="sys">Ctrl</kbd> to zoom in or out,
hold <kbd class="sys">Alt</kbd> to rotate)</li><li><kbd-shrtcts id="zoom-in"></kbd-shrtcts> and <kbd-shrtcts id="zoom-out"></kbd-shrtcts> to zoom in and out</li><li><kbd-shrtcts id="flip-horizontally"></kbd-shrtcts> - flip view horizontally</li><li><kbd-shrtcts id="fit-on-screen"></kbd-shrtcts> - fit entire picture on screen</li><li><kbd-shrtcts id="actual-pixels"></kbd-shrtcts> - show the picture at full resolution</li><li><kbd-shrtcts id="reset-rotation"></kbd-shrtcts> - reset view rotation</li></ul><h4>Tools</h4><ul class="spaced"><li><kbd-shrtcts id="move"></kbd-shrtcts> - <b>move tool</b> (hold <kbd class="sys">Shift</kbd> to lock vertical or horizontal move,
hold <kbd class="sys">Ctrl</kbd> to pick layer to move from cursor position)</li><li><kbd-shrtcts id="selection"></kbd-shrtcts> - <b>rectangle selection</b> (hold <kbd class="sys">Shift</kbd> to add to selection,
hold <kbd class="sys">Alt</kbd> to subtract from selection)</li><li><kbd-shrtcts id="lassoSelection"></kbd-shrtcts> - <b>lasso selection</b> (hold <kbd class="sys">Shift</kbd> to add to selection,
hold <kbd class="sys">Alt</kbd> to subtract from selection)</li><li><kbd-shrtcts id="eyedropper"></kbd-shrtcts> - <b>eyedropper</b> (hold <kbd class="sys">Shift</kbd> to put picked color in secondary color slot,
hold <kbd class="sys">Ctrl</kbd> to switch picking from active layer or whole drawing)</li><li><kbd-shrtcts id="pencil"></kbd-shrtcts> - <b>pencil</b> (hold <kbd class="sys">Shift</kbd> to add draw straight line from last point,
hold <kbd class="sys">Alt</kbd> to switch to eyedropper tool)</li><li><kbd-shrtcts id="brush"></kbd-shrtcts> - <b>brush</b> (hold <kbd class="sys">Shift</kbd> to add draw straight line from last point,
hold <kbd class="sys">Alt</kbd> to switch to eyedropper tool)</li><li><kbd-shrtcts id="eraser"></kbd-shrtcts> - <b>eraser</b> (hold <kbd class="sys">Shift</kbd> to add draw straight line from last point,
hold <kbd class="sys">Alt</kbd> to switch to eyedropper tool)</li><li><kbd-shrtcts id="rect"></kbd-shrtcts> - <b>rectangle tool</b> (hold <kbd class="sys">Shift</kbd> to add draw square,
hold <kbd class="sys">Alt</kbd> to switch start the shape from the center)</li><li><kbd-shrtcts id="rotateView"></kbd-shrtcts> - <b>rotate view tool</b> (hold <kbd class="sys">Shift</kbd> to lock to 15° steps)</li><li><kbd-shrtcts id="zoom"></kbd-shrtcts> - <b>zoom tool</b> (hold <kbd class="sys">Alt</kbd> to zoom-out instead of zooming in when clicking)</li><li>hold any of these buttons to switch to given tool temporarily</li></ul><h4>Layers</h4><ul class="spaced"><li>double click a free layer to take ownership of it</li><li><kbd class="sys">Shift</kbd> + <svg-icon [icon]="eyeIcon"></svg-icon> - toggle any layer visibility just for yourself</li><li><kbd-shrtcts id="toggle-layer-clipping-group"></kbd-shrtcts> - toggle layer clipping group</li><li><kbd-shrtcts id="toggle-layer-opacity-lock"></kbd-shrtcts> - toggle layer opacity lock</li><li><kbd-shrtcts id="clear-layer"></kbd-shrtcts> - clear active layer</li><li><kbd-shrtcts id="transfer-layer"></kbd-shrtcts> - transfer layer down</li><li><kbd-shrtcts id="duplicate-layer"></kbd-shrtcts> - duplicate layer</li></ul></section></div>