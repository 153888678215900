import {
  Layer, Drawing, ClientDrawingData, User, SequenceDrawingData, LayerOwner, SequenceDrawing, WorkerDrawingData, Rect, TextLayer,
} from './interfaces';
import { findById, includes, removeItem } from './baseUtils';
import { toLayerState, layerFromState, isTextLayer } from './layer';
import { clipRect, createRect } from './rect';
import { SEQUENCE_THUMB_HEIGHT, SEQUENCE_THUMB_WIDTH, DEFAULT_DRAWING_DATA } from './constants';
import { fullName } from './userUtils';

export function createDrawing(data: ClientDrawingData): Drawing {
  const {
    _id, id, name, width, height, background, dpi, password, permissions = {}, hasAdmins = false,
    layers = [], pro = false, hideEdition = false, sequence, sequenceMainDrawingId, layerOwners,
    respectOfflineOwners = false, layersPerUser = 0, featureFlags = [], justImported = false,
    team, project, folder,
    permissionFlags, shareType, promptHistory, sequenceId
  } = data;

  const drawing: Drawing = {
    _id, id, name, width, height, background, dpi, password, permissions, hasAdmins, hideEdition,
    respectOfflineOwners, layersPerUser, featureFlags, pro, justImported,
    layers: layers.map(layerFromState),
    rect: createRect(0, 0, width, height),
    sequence: sequence?.map(createSequenceDrawing) ?? [{ _id, id, name, users: [] }],
    sequenceMainDrawingId,
    team, project, folder,
    canvas: undefined,
    thumbUpdate: undefined,
    permissionFlags,
    shareType,
    promptHistory,
    sequenceId
  };

  if (layerOwners) {
    const layerToOwner = new Map<number, LayerOwner>();

    for (const o of layerOwners) {
      for (const layerId of o.left) {
        layerToOwner.set(layerId, { name: o.name, color: o.color, left: true });
      }
    }

    for (const o of layerOwners) {
      for (const layerId of o.layers) {
        layerToOwner.set(layerId, { name: o.name, color: o.color, left: false });
      }
    }

    for (const layer of drawing.layers) {
      layer.layerOwner = layerToOwner.get(layer.id);
    }
  }

  return drawing;
}

export function createSequenceDrawing({ _id, id, name }: SequenceDrawingData): SequenceDrawing {
  return { _id, id, name, users: [] };
}

export function reassignSequenceDrawings(current: Drawing, old: Drawing) {
  if (!current.sequence || !old.sequence) return;

  for (const drawing of current.sequence) {
    const found = findById(old.sequence, drawing.id);
    if (found) Object.assign(drawing, found);
  }
}

export function createTestDrawing(data: Partial<ClientDrawingData>) {
  return createDrawing({ ...DEFAULT_DRAWING_DATA, ...data });
}

// used in worker
export function createWorkerDrawingState(drawing: Drawing): WorkerDrawingData {
  const { _id, id, name, width, height, background, layers, dpi } = drawing;
  return { _id, id, name, width, height, background, dpi, layers: layers.map(toLayerState) };
}

export function hasAllFontsLoaded(drawing: Drawing) {
  return !drawing.layers.some(l => isTextLayer(l) && !l.fontsLoaded);
}

export function forAllTextLayers(drawing: Drawing, fn: (layer: TextLayer) => void) {
  for (const l of drawing.layers) {
    if (isTextLayer(l)) fn(l);
  }
}

export function hasFreeLayers(drawing: Drawing) {
  return drawing.layers.some(l => !l.owner);
}

export function getLayer(drawing: { layers: Layer[] }, id: number): Layer | undefined {
  return findById(drawing.layers, id);
}

export function getAboveLayer(drawing: Drawing, layer: Layer): Layer | undefined {
  const index = drawing.layers.indexOf(layer) - 1;
  return index >= 0 ? drawing.layers[index] : undefined;
}

export function getBelowLayer(drawing: Drawing, layer: Layer): Layer | undefined {
  const index = drawing.layers.indexOf(layer) + 1;
  return index < drawing.layers.length ? drawing.layers[index] : undefined;
}

export function getLayerSafe(drawing: Drawing, id: number) {
  const layer = getLayer(drawing, id);

  if (!layer) throw new Error(`Missing layer (${id})`);

  return layer;
}

export function getNewLayerName(drawing: Drawing | ClientDrawingData) {
  let id = drawing.layers.length;
  let name: string;

  do {
    id++;
    name = `Layer #${id}`;
  } while (drawing.layers.some(l => l.name === name));

  return name;
}

export function hasOwner(layer: Layer) {
  return !!layer.owner || !!(layer.layerOwner && !layer.layerOwner.left);
}

export function setLayerOwner(layer: Layer, user: User | undefined, removeLayerOwner = false) {
  if (layer.owner && layer.owner !== user) {
    removeItem(layer.owner.ownedLayers, layer.id);
  }

  layer.owner = user;

  if (user) {
    layer.layerOwner = { name: fullName(user), color: user.color, left: false };
  } else if (removeLayerOwner && layer.layerOwner) {
    layer.layerOwner = { ...layer.layerOwner, left: true };
  }

  if (user && !includes(user.ownedLayers, layer.id)) {
    user.ownedLayers.push(layer.id);
  }
}

export function setOwnedLayer(drawing: Drawing, layerId: number, user: User | undefined) {
  const layer = getLayer(drawing, layerId);

  if (layer) {
    setLayerOwner(layer, user);
  } else if (user && !includes(user.ownedLayers, layerId)) {
    user.ownedLayers.push(layerId);
  }
}

export function getSequenceThumbSize(drawing: Drawing) {
  const scale = Math.min(SEQUENCE_THUMB_WIDTH / drawing.width, SEQUENCE_THUMB_HEIGHT / drawing.height);
  const width = Math.ceil(drawing.width * scale);
  const height = Math.ceil(drawing.height * scale);
  return { width, height };
}

export function clipToDrawingRect(rect: Rect, drawing: Drawing) {
  clipRect(rect, 0, 0, drawing.width, drawing.height);
}

export function getMainDrawingId(drawing: { id: string; sequenceMainDrawingId?: string; } | undefined) {
  return drawing?.sequenceMainDrawingId ?? drawing?.id;
}
