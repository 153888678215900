import { Drawing, HelpSection, IToolEditor, Layer, UserAction } from '../common/interfaces';
import { setActiveLayer } from '../common/user';
import { hasDrawingRole, hasPermission } from '../common/userRole';
import { getLayer, getNewLayerName, hasOwner, setLayerOwner } from '../common/drawing';
import { isLayerVisible, isTextLayer } from '../common/layer';
import { rectContainsXY } from '../common/rect';
import { getMaxLayers } from '../common/constants';
import { includes } from '../common/baseUtils';
import { getAlpha } from '../common/color';
import { isMaskEmpty } from '../common/mask';
import { logAction } from '../common/actionLog';
import { finishTransform } from '../common/toolUtils';
import { undosLog } from '../common/history';
import { isLayerEmpty } from '../common/layerUtils';
import { canDisownLayer, canDrawOnLayer, canEdit, canEditLayer, Editor, getActiveFilterTool, isFilterActive, isMyLayer } from './editor';
import { redraw } from './editorUtils';
import { helpLockedLayer } from './help';
import { canOwnLayer } from '../common/drawingUtils';
import { canDrawTextLayer, invokeRasterizeFlow, isNonDirtyTextLayer } from '../common/text/text-utils';
import { pointInsidePolygon } from '../common/polyUtils';

export function selectLayer(editor: Editor, layer: Layer | undefined, skipChecks = false) {
  const user = editor.model.user;

  if (!skipChecks && (!canEdit(editor) || (editor.drawingInProgress && user.activeLayer))) return false;
  if (user.activeLayer === layer) return false;
  if (layer && layer.owner !== user) return false;
  if (layer && !includes(editor.drawing.layers, layer)) return false;

  logAction(`[local] selectLayer (layerId: ${layer?.id})`);

  setActiveLayer(user, layer);
  editor.model.selectLayerOnOwn = 0;
  editor.model.selectLayer(user.activeLayerId);

  if (editor.activeTool?.redrawAlways) redraw(editor);

  if (!SERVER) {
    for (const tool of editor.tools) {
      tool.onLayerChange?.(user.activeLayer);
    }

    if (isFilterActive(editor)) {
      logAction(`[local] selectLayer [filter active]`);
      getActiveFilterTool(editor)?.onLayerChange();
      editor.drawingInProgress = false; // in case of reconnecting
    }
  }

  return true;
}

export async function ownLayer(editor: Editor, layer: Layer | undefined, ignoreError = false) {
  try {
    if (canEdit(editor) && !editor.drawingInProgress && layer && canOwnLayer(editor.drawing, editor.model.user, layer)) {
      logAction(`[local] ownLayer (layerId: ${layer.id})`);
      await editor.model.ownLayer(layer.id);
      return true;
    }
  } catch (e) {
    if (!ignoreError) {
      editor.helpService.show({ text: e.message, section: HelpSection.Layer });
    } else if (DEVELOPMENT) {
      console.warn('ignored', e.message);
    }
  }

  return false;
}

export async function disownLayer(editor: Editor, layer: Layer | undefined) {
  if (canDisownLayer(editor) && isMyLayer(editor, layer) && !editor.drawingInProgress) {
    const user = editor.model.user;
    logAction(`[local] disownLayer (layerId: ${layer.id}) ${undosLog(user.history)}`);

    if (DEVELOPMENT && user.activeTool) throw new Error('User has active tool on disownLayer');
    editor.textTool.denyLayerAutoDelete(layer);
    selectOtherLayer(editor, layer.id); // needs to select different layer before finishTool

    if (user.surface.layer === layer) {
      finishTransform(editor, user, 'disownLayer');
    }

    setLayerOwner(layer, undefined); // need to reset owner after finishTool
    user.history.clearLayer(layer.id);
    return await editor.model.disownLayer(layer.id);
  } else {
    return undefined;
  }
}

export async function disownAllLayers(editor: Editor) {
  const user = editor.model.user;
  const ownedLayers = editor.drawing.layers.filter(l => l.owner === user);
  await Promise.all(ownedLayers.map(layer => disownLayer(editor, layer)));
}

export function toggleLayerOpacityLock(editor: Editor, layer: Layer | undefined) {
  if (canEditLayer(editor, layer) && !isFilterActive(editor)) {
    editor.layerUpdateTool.updateLayer({ id: layer.id, opacityLocked: !layer.opacityLocked });
  }
}

export function rasterizeLayer(editor: Editor, layer: Layer | undefined) {
  if (isTextLayer(layer) && canEditLayer(editor, layer)) {
    editor.textTool.rasterizeLocal(layer);
    editor.apply(() => { });
  }
}

export function toggleLayerVisibility(editor: Editor, layer: Layer | undefined) {
  if (canEditLayer(editor, layer)) {
    if (editor.textTool.layer === layer) editor.textTool.focused = !isLayerVisible(layer);
    editor.layerUpdateTool.updateLayer({ id: layer.id, visible: !layer.visible });
  }
}

export function toggleLayerLocked(editor: Editor, layer: Layer | undefined) {
  if (canEditLayer(editor, layer)) {
    editor.layerUpdateTool.updateLayer({ id: layer.id, locked: !layer.locked });
  }
}

export function toggleLayerClippingGroup(editor: Editor, layer: Layer | undefined) {
  if (canEditLayer(editor, layer)) {
    editor.layerUpdateTool.updateLayer({ id: layer.id, clippingGroup: !layer.clippingGroup });
  }
}

export function setLayerMode(editor: Editor, layer: Layer | undefined, mode: string) {
  if (canEditLayer(editor, layer) && layer.mode !== mode) {
    editor.layerUpdateTool.updateLayer({ id: layer.id, mode });
  }
}

export function setLayerName(editor: Editor, layer: Layer | undefined, name: string) {
  if (canEditLayer(editor, layer) && layer.name !== name) {
    editor.layerUpdateTool.updateLayer({ id: layer.id, name });
  }
}

export function changeLayerOpacity(editor: Editor, layer: Layer | undefined, oldValue: number, newValue: number) {
  if (layer) layer.opacity = oldValue;

  if (canEditLayer(editor, layer) && oldValue !== newValue) {
    editor.layerUpdateTool.updateLayer({ id: layer.id, opacity: newValue });
  }
}

export function clearLayer(editor: Editor, layer: Layer | undefined) {
  if (canDrawOnLayer(editor, layer) && !isLayerEmpty(layer)) {
    if (!layer.locked) {
      editor.layerTool.clear(layer.id);
    } else {
      helpLockedLayer(editor.helpService);
    }
  }
}

function layerBelow(drawing: Drawing, layer: Layer | undefined): Layer | undefined {
  return layer ? drawing.layers[drawing.layers.indexOf(layer) + 1] : undefined;
}

export function canTransferLayer(editor: Editor, layer: Layer | undefined) {
  if (canEdit(editor) && layer) {
    const other = layerBelow(editor.drawing, layer);
    return !!(other && other.owner === layer.owner && !isTextLayer(other));
  } else {
    return false;
  }
}

export function transferLayer(editor: Editor, layer: Layer | undefined) {
  const otherLayer = layerBelow(editor.drawing, layer);

  if (
    canEditLayer(editor, layer) && !isLayerEmpty(layer) && isLayerVisible(layer) &&
    isMyLayer(editor, otherLayer) && isLayerVisible(otherLayer) && !isTextLayer(otherLayer)
  ) {
    if (!layer.locked && !otherLayer.locked) {
      if (isTextLayer(layer)) {
        invokeRasterizeFlow(editor, layer)
          .then((rasterized) => { if (rasterized) transferLayer(editor, layer); })
          .catch((e) => { DEVELOPMENT && console.error(e); });
      } else {
        editor.layerTool.transfer(layer.id, otherLayer.id, layer.opacity, layer.clippingGroup && !otherLayer.clippingGroup);
      }
    } else {
      helpLockedLayer(editor.helpService);
    }
  }
}

export const canMergeLayer = canTransferLayer;

export function mergeLayer(editor: Editor, layer: Layer | undefined) {
  const otherLayer = layerBelow(editor.drawing, layer);

  if (
    canEditLayer(editor, layer) && isLayerVisible(layer) &&
    isMyLayer(editor, otherLayer) && isLayerVisible(otherLayer) && !isTextLayer(otherLayer)
  ) {
    if (!layer.locked && !otherLayer.locked) {
      if (isTextLayer(layer)) {
        invokeRasterizeFlow(editor, layer)
          .then((rasterized) => { if (rasterized) mergeLayer(editor, layer); })
          .catch((e) => { DEVELOPMENT && console.error(e); });
      } else {
        editor.layerTool.merge(layer.id, otherLayer.id, layer.opacity, layer.clippingGroup && !otherLayer.clippingGroup);
        selectLayer(editor, otherLayer);
      }
    } else {
      helpLockedLayer(editor.helpService);
    }
  }
}

export function canClearLayer(editor: Editor, layer?: Layer) {
  const l = layer ?? editor.activeLayer;
  const editable = (canEdit(editor) && (l ? l.owner === editor.model.user : !!editor.activeLayer));
  const drawable = editable && !!l && !l.locked;
  return drawable && !isTextLayer(l);
}

export function canCreateNewLayerExceptLimit(editor: Editor) {
  return canEdit(editor) && !editor.drawingInProgress;
}

function checkLayerLimit(editor: Editor) {
  const isPro = !!editor.model.user.pro || editor.drawing.pro;
  const maxLayers = getMaxLayers(isPro, editor.model.user.isSuperAdmin);
  return editor.drawing.layers.length < maxLayers;
}

// this function skips some checks in order to ignore results from canCreateNewLayerExceptLimit
export async function createNewLayersInsecure(editor: Editor, count: number, shouldFinishTransform: boolean) {
  if (!hasPermission(editor.drawing, editor.model.user, 'addRemoveLayer')) {
    throw new Error(`You don't have permission to add new layers`);
  }
  if (!checkLayerLimit(editor)) {
    throw new Error('Layer limit reached');
  }

  try {
    editor.drawingInProgress = true;
    editor.drawingNonCancellable = true;

    const ids = await editor.model.getNewLayerIds(count);
    logAction(`[local] createNewLayers ok (ids: ${ids.join(', ')}, drawingId: ${editor.drawing.id})`);
    if (ids.length !== count) return [];
    if (!editor.drawing.id) return []; // check if drawing is still loaded

    if (shouldFinishTransform) finishTransform(editor, editor.model.user, 'createNewLayersInsecure');
    return ids;
  } finally {
    logAction(`[local] createNewLayers [finally]`);
    editor.drawingInProgress = false;
    editor.drawingNonCancellable = false;
  }
}

export async function createNewLayers(editor: Editor, count: number, can: () => boolean, shouldFinishTransform = true) {
  logAction('[local] createNewLayers');

  try {
    if (canCreateNewLayerExceptLimit(editor) && can()) {
      return await createNewLayersInsecure(editor, count, shouldFinishTransform);
    } else {
      logAction('[local] createNewLayers blocked');
      return [];
    }
  } catch (e) {
    logAction(`[local] createNewLayers error (${e.message}, ${e.stack})`);
    editor.helpService.show({ text: e.message, section: HelpSection.Layer });
    DEVELOPMENT && !TESTS && console.error(e);
  }

  return [];
}

export async function withNewLayers(editor: Editor, count: number, can: () => boolean, action: (newLayerIds: number[]) => void) {
  logAction('[local] withNewLayer');
  const ids = await createNewLayers(editor, count, can);
  if (ids.length > 0) {
    action(ids);
    return ids.map(id => getLayer(editor.drawing, id));
  } else {
    return [];
  }
}

export function addLayer(editor: Editor, autogenerated = false) {
  const layer = editor.activeLayer;
  const index = layer ? editor.drawing.layers.indexOf(layer) : 0;
  const name = getNewLayerName(editor.drawing);
  return withNewLayers(editor, 1, () => true, ([id]) => editor.layerTool.add({ id, name }, index, autogenerated));
}

export async function addAndSelectLayer(editor: Editor, autogenerated = false) {
  const layers = await addLayer(editor, autogenerated);
  if (layers[0]) selectLayer(editor, layers[0]);
}

export function removeLayer(editor: Editor, layer: Layer | undefined) {
  try {
    if (!canEditLayer(editor, layer)) return;

    if (!hasPermission(editor.drawing, editor.model.user, 'addRemoveLayer'))
      throw new Error(`You don't have permission to remove layers`);

    editor.textTool.denyLayerAutoDelete(layer);
    selectOtherLayer(editor, layer.id);
    editor.layerTool.remove(layer.id);
  } catch (e) {
    editor.helpService.show({ text: e.message, section: HelpSection.Layer });
  }
}

export function duplicateLayer(editor: Editor, layer: Layer | undefined) {
  return withNewLayers(editor, 1, () => canEditLayer(editor, layer), ([id]) => editor.layerTool.duplicate(layer!.id, id));
}

export async function duplicateAndSelectLayer(editor: Editor, layer: Layer | undefined) {
  const [newLayer] = await duplicateLayer(editor, layer);
  if (newLayer) selectLayer(editor, newLayer);
}

export function canCopyLayer(editor: Editor, layer: Layer | undefined) {
  return canEditLayer(editor, layer) && !isNonDirtyTextLayer(layer ?? editor.activeLayer); // && !isMaskEmpty(editor.model.user.selection);
}

export function copyLayer(editor: Editor, layer: Layer | undefined) {
  if (isTextLayer(layer)) {
    if (isMaskEmpty(editor.model.user.selection)) {
      return duplicateLayer(editor, layer);
    } else {
      return invokeRasterizeFlow(editor, layer, true)
        .then((confirmed) => {
          if (confirmed && editor.drawing.layers.includes(layer)) {
            return withNewLayers(editor, 1, () => canCopyLayer(editor, layer), ([id]) => editor.layerTool.copy(layer!.id, id));
          } else {
            return Promise.resolve([]);
          }
        });
    }
  }
  return withNewLayers(editor, 1, () => canCopyLayer(editor, layer), ([id]) => editor.layerTool.copy(layer!.id, id));
}

export async function copyAndSelectLayer(editor: Editor, layer: Layer | undefined) {
  const [newLayer] = await copyLayer(editor, layer);
  if (newLayer) selectLayer(editor, newLayer);
}

export function canCutLayer(editor: Editor, layer: Layer | undefined) {
  return canDrawOnLayer(editor, layer) && !isMaskEmpty(editor.model.user.selection) && !isTextLayer(layer);
}

export function cutLayer(editor: Editor, layer: Layer | undefined) {
  return withNewLayers(editor, 1, () => canCutLayer(editor, layer), ([id]) => editor.layerTool.cut(layer!.id, id));
}

export async function cutAndSelectLayer(editor: Editor, layer: Layer | undefined) {
  const [newLayer] = await cutLayer(editor, layer);
  if (newLayer) selectLayer(editor, newLayer);
}

export function selectOtherLayer(editor: Editor, layerId: number) {
  if (!editor.activeLayer || editor.activeLayer.id === layerId) {
    const layers = editor.drawing.layers;
    const index = editor.activeLayer ? layers.indexOf(editor.activeLayer) : 0;

    const trySelect = (i: number) => {
      if (i >= 0 && i < layers.length && layers[i].owner === editor.model.user && layers[i].id !== layerId) {
        selectLayer(editor, layers[i]);
        return true;
      } else {
        return false;
      }
    };

    for (let i = 1; i < layers.length; i++) {
      if (trySelect(index + i) || trySelect(index - i)) {
        return;
      }
    }

    selectLayer(editor, undefined);
  }
}

export function canReorderLayers(editor: Editor): boolean {
  return canEdit(editor) && !editor.drawingInProgress &&
    hasPermission(editor.drawing, editor.model.user, 'reorderLayers');
}

export async function reorderLayers(editor: Editor) {
  if (canReorderLayers(editor)) {
    logAction('[local] reorder layers');
    editor.model.reorderLayers(editor.drawing.layers.map(l => l.id));
  }
}

// export function trimLayer(editor: Editor, layer: Layer) {
//   if (isLayerEmpty(layer)) return;

//   const bounds = getCanvasBounds(layer.canvas!, layer.rect);

//   if (!rectsEqual(bounds, layer.rect)) {
//     layer.rect = bounds;
//     redrawLayerThumb(layer);
//     editor.model.trimLayer(layer);
//   }
// }

export function pickLayerFromEditor(editor: IToolEditor, x: number, y: number, onlyOwned: boolean, onlyText = false) {
  if (editor.type === 'client') {
    return pickLayer(editor as Editor, x, y, onlyOwned, onlyText);
  } else {
    return undefined;
  }
}

// TODO: this doesn't account for clipping groups
export function pickLayer(editor: Editor, x: number, y: number, onlyOwned: boolean, onlyText = false) {
  if (rectContainsXY(editor.drawing.rect, x, y)) {
    for (let i = 0; i < editor.drawing.layers.length; i++) {
      const layer = editor.drawing.layers[i];
      if (onlyText && !isTextLayer(layer)) continue;

      if ((!onlyOwned || layer.owner === editor.model.user) && !isLayerEmpty(layer) && isLayerVisible(layer)) {
        if (isTextLayer(layer) && canDrawTextLayer(layer) && pointInsidePolygon(x, y, layer.textarea.bounds)) return layer;

        // TODO: check layer and surface rect first here
        const color = editor.renderer.pickColor(editor.drawing, layer, x, y, true);

        if (color && getAlpha(color) > 0) {
          return layer;
        }
      }
    }
  }

  return undefined;
}

export function canMoveLayerUp(editor: Editor, layer: Layer | undefined): layer is Layer {
  return canReorderLayers(editor) &&
    layer !== undefined &&
    editor.drawing.layers.indexOf(layer) > 0;
}

export function canMoveLayerDown(editor: Editor, layer: Layer | undefined): layer is Layer {
  return canReorderLayers(editor) &&
    layer !== undefined &&
    editor.drawing.layers.indexOf(layer) < (editor.drawing.layers.length - 1);
}

export function moveLayerUp(editor: Editor, layer: Layer | undefined) {
  if (canMoveLayerUp(editor, layer)) {
    const index = editor.drawing.layers.indexOf(layer);
    editor.drawing.layers.splice(index, 1);
    editor.drawing.layers.splice(index - 1, 0, layer);
    reorderLayers(editor).catch(e => DEVELOPMENT && console.error(e));
  }
}

export function moveLayerDown(editor: Editor, layer: Layer | undefined) {
  if (canMoveLayerDown(editor, layer)) {
    const index = editor.drawing.layers.indexOf(layer);
    editor.drawing.layers.splice(index, 1);
    editor.drawing.layers.splice(index + 1, 0, layer);
    reorderLayers(editor).catch(e => DEVELOPMENT && console.error(e));
  }
}

export function canKickFromLayer(editor: Editor, layer: Layer | undefined): layer is Layer {
  return hasDrawingRole(editor.model.user, 'admin') && !!layer && hasOwner(layer);
}

export function kickFromLayer(editor: Editor, layer: Layer | undefined) {
  if (canKickFromLayer(editor, layer)) {
    editor.model.tryUserAction(UserAction.KickFromLayer, 0, layer.id)
      .catch(e => DEVELOPMENT && console.error(e));
  }
}

export function canRemoveLayerOwner(editor: Editor, layer: Layer | undefined): layer is Layer {
  return hasDrawingRole(editor.model.user, 'admin') && !!layer && !!layer.layerOwner;
}

export function removeLayerOwner(editor: Editor, layer: Layer | undefined) {
  if (canRemoveLayerOwner(editor, layer)) {
    editor.model.tryUserAction(UserAction.RemoveLayerOwner, 0, layer.id)
      .catch(e => DEVELOPMENT && console.error(e));
  }
}

export function prevLayer(editor: Editor) {
  const user = editor.model.user;
  const activeLayer = user.activeLayer;
  if (!activeLayer) return;
  const layers = editor.drawing.layers.filter(l => l.owner === user);
  let newIndex = (layers.indexOf(activeLayer) + 1) % layers.length;
  selectLayer(editor, layers[newIndex]);
}

export function nextLayer(editor: Editor) {
  const user = editor.model.user;
  const activeLayer = user.activeLayer;
  if (!activeLayer) return;
  const layers = editor.drawing.layers.filter(l => l.owner === user);
  let newIndex = layers.indexOf(activeLayer) - 1;
  if (newIndex === -1) newIndex = layers.length - 1;
  selectLayer(editor, layers[newIndex]);
}
