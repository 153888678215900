import { BreaklineStrategies } from './lines';
import { Mandatory } from '../typescript-utils';
import { generateNumbersArray } from '../mathUtils';
import { BLACK, colorToCSS } from '../color';
import { AUTO_SETTING_STRING, AutoOr } from '../utils';
import { Rect } from '../interfaces';
import { TextCharacter } from './text-character';
import { FontFamily, FontStyleNames } from './font-family';
import { FontStyle } from './font-style';
import { invalidEnum } from '../baseUtils';
import { createMat2d } from '../mat2d';

export const MAX_SAFE_BASELINE_SHIFT = 1000;
export const MAX_SAFE_SIZE = 1000;
export const MAX_SAFE_LINEHEIGHT = 1000;
export const MAX_SAFE_SCALE_X = 1000;
export const MAX_SAFE_SCALE_Y = 1000;
export const MAX_SAFE_LETTERSPACING = 10000;

export const MIN_SAFE_BASELINE_SHIFT = -MAX_SAFE_BASELINE_SHIFT;
export const MIN_SAFE_SIZE = 1;
export const MIN_SAFE_LINEHEIGHT = 1;
export const MIN_SAFE_SCALE_X = 0.01;
export const MIN_SAFE_SCALE_Y = 0.01;
export const MIN_SAFE_LETTERSPACING = -MAX_SAFE_LETTERSPACING;

// Returns array with all indexes from starting index to ending.
// Example: getIndexesInFormatting({ start: 2, length: 4 }) // [2, 3, 4, 5]
export const getIndexesInRange = (format: TextRange): number[] => {
  return generateNumbersArray(format.start, format.start + format.length - 1);
};

export const sortFormattingsToEnableRemovingFormattings = (a: CharacterFormattingDescription, b: CharacterFormattingDescription) => {
  // need to sort formattings during merging here because in scenario when we merge we want to remove formatting
  // we want false value last for spread, and that should only be true for boolean-formattings like bold, underline etc.
  for (const key of Object.keys(a).filter((key) => key !== 'start' && key !== 'length')) {
    const property = key as keyof CharacterFormatting;
    if (typeof a === 'boolean' && typeof b === 'boolean' && a[property] !== b[property]) {
      return (+(b as any)[property]) - (+(a as any)[property]);
    } else if (a[property] !== b[property] && (a[property] === AUTO_SETTING_STRING || b[property] === AUTO_SETTING_STRING)) {
      return a[property] === AUTO_SETTING_STRING ? 1 : -1;
    }
  }
  return 0;
};

export interface TextareaFormatting {
  verticalAlignment?: VerticalAlignments;
  displayNonPrintableCharacters?: boolean;
  globalStyles?: CharacterFormatting;
  transform?: number[];
}

export interface CharacterFormatting {
  size?: number;
  fontFamily?: string;

  bold?: boolean;
  italic?: boolean;

  color?: string;
  outline?: LineDescriptor;

  underline?: boolean | LineDescriptor;
  strikethrough?: boolean | LineDescriptor;

  lineheight?: number; // In typography or other solutions sometimes referred to as "leading".
  letterSpacing?: number; // In typography or other solutions sometimes referred to as "tracking"
  baselineShift?: number;

  scaleX?: number;
  scaleY?: number;

  textCase?: TextCases;
}

export type InCharacterFormatting = Mandatory<CharacterFormatting, 'size'>;

export interface ParagraphFormatting {
  alignment?: TextAlignment;
  breaklineStrategy?: BreaklineStrategies;
}

export type AnyLevelTextFormatting = TextareaFormatting | ParagraphFormatting | CharacterFormatting;
export type AnyLevelTextFormattingDescription = TextareaFormatting | ParagraphFormattingDescription | CharacterFormattingDescription;
export type FormattableProperty = (keyof CharacterFormatting | keyof ParagraphFormatting | keyof TextareaFormatting);

export enum TextAlignment {
  LeftAligned = 'aligned-left',
  CenterAligned = 'aligned-center',
  RightAligned = 'aligned-right',
  FullyJustified = 'justified-full',
  LeftJustified = 'justified-left',
  CenterJustified = 'justified-center',
  RightJustified = 'justified-right'
}

export const alignsToLeft = (alignment: TextAlignment): boolean => {
  return alignment === TextAlignment.LeftAligned || alignment === TextAlignment.LeftJustified || alignment === TextAlignment.FullyJustified;
};

export const alignsToCenter = (alignment: TextAlignment): boolean => {
  return alignment === TextAlignment.CenterAligned || alignment === TextAlignment.CenterJustified;
};

export const alignsToRight = (alignment: TextAlignment): boolean => {
  return alignment === TextAlignment.RightAligned || alignment === TextAlignment.RightJustified;
};

export enum TextCases {
  NoCaseModification = 'no-case-modification',
  LowerCase = 'lower-case',
  UpperCase = 'upper-case',
  UpperCasePerWord = 'upper-case-per-word',
  UpperCasePerSentence = 'upper-case-per-sentence',
}

const TEXT_CASES = Object.values(TextCases);

export enum VerticalAlignments {
  Top = 'top',
  Center = 'center',
  Bottom = 'bottom'
}

export interface LineDescriptor {
  color: string;
  width: number;
}

export interface TextDecorationData {
  shiftFromBaseline: number;
  thickness: number; // stroke rect height
  color: string;
}

export type TextRange = { start: number; length: number; };

export type TextLocation = { index: number };

type FormattingPropertiesAcceptingAuto = 'lineheight';

export type CharacterFormattingDescription = (Omit<CharacterFormatting, FormattingPropertiesAcceptingAuto> & { [k in FormattingPropertiesAcceptingAuto]?: AutoOr<CharacterFormatting[k]> }) & TextRange;

export type ParagraphFormattingDescription = ParagraphFormatting & TextLocation;

export const DEFAULT_CHARACTER_FORMATTING: InCharacterFormatting = {
  size: 16,
  color: colorToCSS(BLACK),
  scaleX: 1,
  scaleY: 1,
  letterSpacing: 0,
  baselineShift: 0,
  textCase: TextCases.NoCaseModification,
};

export const DEFAULT_PARAGRAPH_FORMATTING: Required<ParagraphFormatting> = {
  alignment: TextAlignment.LeftAligned,
  breaklineStrategy: BreaklineStrategies.perWord,
};

export const DEFAULT_TEXTAREA_FORMATTING: Required<TextareaFormatting> = {
  verticalAlignment: VerticalAlignments.Top,
  displayNonPrintableCharacters: false,
  transform: Array.from(createMat2d()),
  globalStyles: {
    ...DEFAULT_CHARACTER_FORMATTING
  }
};

export const DEFAULT_OUTLINED_FONT_LINE_WIDTH = 2;
export const DEFAULT_UNDERLINE_WIDTH = 2;
export const DEFAULT_STRIKETHROUGH_WIDTH = 2;

const isValidNumber = (n: number) => Number.isFinite(n);
const isValidString = (str: string) => (typeof str === 'string' && str.length > 0);
const isValidNumberInRangeOrUndefined = (param: any, min: number, max: number): boolean => (param === undefined || (isValidNumber(param) && param <= max && param >= min));

const validateFormattedProperties = <T extends (CharacterFormattingDescription | ParagraphFormattingDescription)>(formatting: T, rangeKeys: (keyof T)[] = []): boolean => {
  const keys = Object.keys(formatting) as (keyof T)[];
  if (keys.length < rangeKeys.length + 1) return false;

  for (const key of keys) {
    if (!rangeKeys.includes(key)) {
      const value = formatting[key];
      if (typeof value === 'string') {
        if (!isValidString(value)) return false;
      } else if (typeof value === 'number') {
        if (!isValidNumber(value)) return false;
      } else if (typeof value === 'boolean') {
        continue; // not invalid, so valid
      } else if (typeof value === 'object') {
        continue; // not invalid, so valid
      } else {
        return false; // unrecognized, invalid
      }
    }
  }
  return true;
};
const validateFormattingRange = <T extends (CharacterFormattingDescription | ParagraphFormattingDescription)>(formatting: T): boolean => {
  const hasStart = 'start' in formatting;
  const hasLength = 'length' in formatting;
  const hasIndex = 'index' in formatting;

  if (!hasIndex && !(hasStart && hasLength)) return false;

  if (hasStart) {
    const { start } = formatting;
    if (start === undefined || !isValidNumber(start) || start < 0) return false;
  }

  if (hasLength) {
    const { length } = formatting;
    if (length === undefined || !isValidNumber(length) || length <= 0) return false;
  }

  if (hasIndex) {
    const { index } = formatting;
    if (index === undefined || !isValidNumber(index) || index < 0) return false;
  }

  return true;
};

export function isValidCharacterFormatting(formatting: CharacterFormattingDescription): boolean {
  if (formatting && validateFormattingRange(formatting) && validateFormattedProperties(formatting, ['start', 'length'])) {
    if (!isValidNumberInRangeOrUndefined(formatting.scaleX, MIN_SAFE_SCALE_X, MAX_SAFE_SCALE_X)) return false;
    if (!isValidNumberInRangeOrUndefined(formatting.scaleY, MIN_SAFE_SCALE_Y, MAX_SAFE_SCALE_Y)) return false;
    if (!isValidNumberInRangeOrUndefined(formatting.baselineShift, MIN_SAFE_BASELINE_SHIFT, MAX_SAFE_BASELINE_SHIFT)) return false;
    if (!isValidNumberInRangeOrUndefined(formatting.letterSpacing, MIN_SAFE_LETTERSPACING, MAX_SAFE_LETTERSPACING)) return false;

    // size and lineheight are being transformed by transform tool. We allow up to infinite values here but they are limited to MAX_SAFE_SIZE/LINEHEIGHT when entering with input
    if (!isValidNumberInRangeOrUndefined(formatting.size, MIN_SAFE_SIZE, Infinity)) return false;
    if (formatting.lineheight !== AUTO_SETTING_STRING && !isValidNumberInRangeOrUndefined(formatting.lineheight, MIN_SAFE_LINEHEIGHT, Infinity)) return false;

    if (formatting.textCase !== undefined && !TEXT_CASES.includes(formatting.textCase)) return false;
    if (formatting.fontFamily !== undefined && (!isValidString(formatting.fontFamily))) {
      // no validation of is font family available because it could possibly not be loaded yet (we initiate empty fonts map and then load afterwards)
      return false;
    }
    if (formatting.color !== undefined && !isValidString(formatting.color)) {
      // TODO: add regexp check for color in different color models
      return false;
    }

    return true;
  }
  return false;
}

export function isValidParagraphFormatting(formatting: ParagraphFormattingDescription): boolean {
  return formatting && validateFormattingRange(formatting) && validateFormattedProperties(formatting, ['index']);
}

const shouldKeepProperty = (formatting: CharacterFormattingDescription, key: keyof CharacterFormatting, propertiesCount: number): boolean => {
  if (formatting[key] === AUTO_SETTING_STRING) return false;
  if (formatting[key] === DEFAULT_CHARACTER_FORMATTING[key] && propertiesCount > 1) return false;
  return true;
};
export const sanitizeFormatting = (formatting: CharacterFormattingDescription) => {
  const sanitizedFormatting: Partial<CharacterFormattingDescription> = { start: formatting.start, length: formatting.length };
  const formattedProperties = (Object.keys(formatting) as (keyof CharacterFormattingDescription)[]).filter(isNonRangeCharacterFormattingKey);
  for (const property of formattedProperties) {
    switch (property) {
      case 'scaleX': if (shouldKeepProperty(formatting, 'scaleX', formattedProperties.length)) sanitizedFormatting.scaleX = formatting.scaleX; break;
      case 'scaleY': if (shouldKeepProperty(formatting, 'scaleY', formattedProperties.length)) sanitizedFormatting.scaleY = formatting.scaleY; break;
      case 'fontFamily': if (formatting.fontFamily) sanitizedFormatting.fontFamily = formatting.fontFamily; break;
      case 'size': if (shouldKeepProperty(formatting, 'size', formattedProperties.length)) sanitizedFormatting.size = formatting.size; break;
      case 'letterSpacing': if (shouldKeepProperty(formatting, 'letterSpacing', formattedProperties.length)) sanitizedFormatting.letterSpacing = formatting.letterSpacing; break;
      case 'baselineShift': if (shouldKeepProperty(formatting, 'baselineShift', formattedProperties.length)) sanitizedFormatting.baselineShift = formatting.baselineShift; break;
      case 'color': if (shouldKeepProperty(formatting, 'color', formattedProperties.length)) sanitizedFormatting.color = formatting.color; break;
      case 'outline': if (formatting.outline) sanitizedFormatting.outline = formatting.outline; break;
      case 'textCase': if (formatting.textCase) sanitizedFormatting.textCase = formatting.textCase; break;
      case 'bold': if (formatting.bold) sanitizedFormatting.bold = formatting.bold; break;
      case 'italic': if (formatting.italic) sanitizedFormatting.italic = formatting.italic; break;
      case 'underline': if (formatting.underline) sanitizedFormatting.underline = formatting.underline; break;
      case 'strikethrough': if (formatting.strikethrough) sanitizedFormatting.strikethrough = formatting.strikethrough; break;
      case 'lineheight': if (shouldKeepProperty(formatting, 'lineheight', formattedProperties.length)) sanitizedFormatting.lineheight = formatting.lineheight; break;
      default: invalidEnum(property, `Unhandled formattable property ${property} in sanitizing function.`);
    }
  }
  return sanitizedFormatting as CharacterFormattingDescription; // still unsafe, to validate if number of keys is ok
};

export interface TextDecorationTracking {
  formatting: CharacterFormattingDescription;
  lineNr: number;
  underlineRect?: Rect;
  strikethroughRect?: Rect;
}

export const isLastCharacterInRange = (range: TextRange, character: TextCharacter) => {
  return range.start + range.length - 1 === character.index;
};

export const textRangesEqual = (a: TextRange, b: TextRange): boolean => {
  return a.start === b.start && a.length === b.length;
};

export const getAppliedFormattingName = (
  formatting: AnyLevelTextFormatting,
  level: 'character' | 'paragraph' | 'textarea' | '' = ''
) => {
  const appliedFormattings = Object.keys(formatting);
  if (appliedFormattings.length === 1) {
    return appliedFormattings[0]!;
  } else if (appliedFormattings.length > 1) {
    DEVELOPMENT && console.warn(`Applied more than one ${level} formatting`, appliedFormattings);
    return appliedFormattings.join('.');
  } else {
    DEVELOPMENT && console.warn(`Applied no ${level} formatting`);
    return `no-${level}-formatting`;
  }
};

export function isNonRangeCharacterFormattingKey (formattingKey: keyof CharacterFormattingDescription): formattingKey is keyof CharacterFormatting {
  return formattingKey !== 'start' && formattingKey !== 'length';
}

export const formattingToFontStyleName = (formatting: CharacterFormatting | CharacterFormattingDescription | undefined): FontStyleNames => {
  if (formatting?.bold && formatting?.italic) {
    return FontStyleNames.BoldItalic;
  } else if (formatting?.bold) {
    return FontStyleNames.Bold;
  } else if (formatting?.italic) {
    return FontStyleNames.Italic;
  } else {
    return FontStyleNames.Regular;
  }
};

export const formattingToFontStyle = (formatting: CharacterFormatting | CharacterFormattingDescription | undefined, fontFamily: FontFamily): FontStyle => {
  const fontStyleName = formattingToFontStyleName(formatting);

  const fontStyle = fontFamily.styles.get(fontStyleName);
  if (!fontStyle) {
    const regularStyle = fontFamily.styles.get(FontStyleNames.Regular);
    if (regularStyle) {
      DEVELOPMENT && fontStyleName !== FontStyleNames.Regular && console.warn(`Font style '${fontStyleName}' not found! Executing fallback to regular.`);
      return regularStyle;
    } else {
      throw new Error(`Font style '${fontStyleName}' not found! Can't return fontStyle!`);
    }
  }

  return fontStyle;
};

export const isEqualAppliedFormatting = (a: CharacterFormattingDescription, b: CharacterFormattingDescription): boolean => {
  if (a.scaleX !== b.scaleX) return false;
  if (a.scaleY !== b.scaleY) return false;
  if (a.fontFamily !== b.fontFamily) return false;
  if (a.size !== b.size) return false;
  if (a.letterSpacing !== b.letterSpacing) return false;
  if (a.baselineShift !== b.baselineShift) return false;
  if (a.color !== b.color) return false;
  if (a.outline !== b.outline) return false;
  if (a.textCase !== b.textCase) return false;
  if (!!a.bold !== !!b.bold) return false;
  if (!!a.italic !== !!b.italic) return false;
  if (!!a.strikethrough !== !!b.strikethrough) return false;
  if (!!a.underline !== !!b.underline) return false;
  if ((a.lineheight ?? AUTO_SETTING_STRING) !== (b.lineheight ?? AUTO_SETTING_STRING)) return false;
  if (Object.keys(a).length !== Object.keys(b).length) return false;
  return true;
};
