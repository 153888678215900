import { Observable } from 'rxjs/internal/Observable';
import { IMsg } from '../common/interfaces';

export abstract class ToastService {
  abstract push(toast: IMsg): IMsg;
  abstract updateToSuccess(toast: IMsg, update: Partial<IMsg>): void;
  abstract updateToError(toast: IMsg, update: Partial<IMsg>): void;
  abstract getToasts(): Observable<IMsg[]>;
  abstract dismiss(toast: IMsg): void;
  abstract loading(toast: Partial<IMsg>): IMsg;
  abstract success(toast: Partial<IMsg>): IMsg;
  abstract error(toast: Partial<IMsg>): IMsg;
  abstract info(toast: Partial<IMsg>): IMsg;
  abstract warning(toast: Partial<IMsg>): IMsg;
  abstract alert(toast: Partial<IMsg>): IMsg;
  abstract notification(toast: Partial<IMsg>): IMsg;
}
