import { Pipe, PipeTransform } from '@angular/core';

const UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

@Pipe({ name: 'bytes'})
export class BytesPipe implements PipeTransform  {

  constructor() {}

  transform(sizeInBytes: number) {
    if (typeof sizeInBytes === 'undefined' || sizeInBytes === null) { return ''; }
    if (!sizeInBytes) { return '0 B'; }

    let power = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
    power = Math.min(power, UNITS.length - 1);

    const divider = Math.pow(1024, power);
    const size = sizeInBytes / divider; // size in new units
    // const precision = Math.pow(10, (power / 2) | 0) | 0;
    let precision: number;
    if (sizeInBytes % divider === 0) {
      precision = 1;
    } else {
      precision = Math.max(Math.pow(10, 2 - (Math.log(size) / Math.log(10)) | 0), 1) | 0;
    }
    const formattedSize = Math.round(size * precision) / precision; // keep up to 2 decimals
    const unit = UNITS[power];

    return `${formattedSize} ${unit}`;
  }
}
