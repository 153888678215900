import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'input-modal',
  templateUrl: 'input-modal.pug',
})
export class InputModal {
  @Input() data: { message: string; } = { message: '' };
  @Output() close = new EventEmitter<string>();
}
