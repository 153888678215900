import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './app-modal.component.pug',
  styleUrls: [ './app-modal.component.scss' ],
  animations: [ ],
})
export class AppModalComponent {

  @Input() isModalOpen = false;
  @Input() modalSize = '';
  @Input() preventClose = false;

  @Output() onModalClose = new EventEmitter<void>();

  constructor() {
  }

  close() {
    this.onModalClose.emit();
  }

  preventScapeEvent(event: Event) {
    this.preventClose ? event.stopImmediatePropagation() : this.close();
  }

}
