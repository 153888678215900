/* tslint:disable:max-line-length */

export { chrome } from './resources/chrome';

export { edge } from './resources/edge';

export { editor_logo } from './resources/editor_logo';

export { editor_title } from './resources/editor_title';

export { failed_generation } from './resources/failed_generation';

export { firefox } from './resources/firefox';

export { ios } from './resources/ios';

export { logo } from './resources/logo';

export { logo_dark } from './resources/logo_dark';

export { logo_extra } from './resources/logo_extra';

export { logo_faded } from './resources/logo_faded';

export { logo_signup } from './resources/logo_signup';

export { magma_logo } from './resources/magma_logo';

export { magma_name } from './resources/magma_name';

export { opera } from './resources/opera';

export { safari } from './resources/safari';

export const avatars = ['1f40a.png', '1f40c.png', '1f417.png', '1f419.png', '1f41f.png', '1f425.png', '1f426.png', '1f427.png', '1f428.png', '1f42a.png', '1f42d.png', '1f42e.png', '1f42f.png', '1f430.png', '1f431.png', '1f434.png', '1f435.png', '1f436.png', '1f437.png', '1f438.png', '1f439.png', '1f43a.png', '1f43b.png', '1f43c.png', '1f981.png', '1f984.png', '1f985.png', '1f988.png', '1f989.png', '1f98a.png', '1f98c.png', '1f98d.png', '1f98f.png', 'default.png'];
