import { redrawDrawing } from '../../services/editorUtils';
import { logAction } from '../actionLog';
import { IToolEditor, IToolModel, Surface, ToolId, Layer, CompositeOp, HistoryBufferEntry, IToolData, IFilterTool, IFiltersValues } from '../interfaces';
import { redrawLayerThumb } from '../layer';
import { isMaskEmpty } from '../mask';
import { clipRect, cloneRect, copyRect, createRect, isRectEmpty, outsetRect } from '../rect';
import { setupSurface } from '../toolSurface';
import { finishTransform } from '../toolUtils';

export interface IFilterData extends IFiltersValues, IToolData {
}

export abstract class BaseFilterTool implements IFilterTool {
  id = ToolId.None;
  name = '';
  nonDrawing = true;
  preview = true;
  protected values: IFiltersValues = {};
  protected layer: Layer | undefined = undefined;
  protected srcData: ImageData | undefined = undefined;
  protected snapshot: Surface | undefined = undefined;
  protected layerRect = createRect(0, 0, 0, 0);
  protected surfaceRect = createRect(0, 0, 0, 0);
  protected layerRectPadding = 0;
  protected alwaysFetchSrcData = false;
  constructor(public editor: IToolEditor, public model: IToolModel, protected toolName: string) {
  }
  move() { }
  end() { }
  start() { }
  onLayerChange() {
    logAction(`[${this.model.type}] layer change ${this.toolName} (${this.preview})`);

    if (this.preview) {
      if (this.layer) this.cancel();
      this.init();
      this.applyInternal();
    }
  }
  do(data?: IFilterData) {
    finishTransform(this.editor, this.model.user, 'BaseFilterTool:do');
    this.updateValues(data);
    this.init();
    this.commit();
  }
  init() {
    const layer = this.model.user.activeLayer;
    if (!layer) throw new Error(`[${this.toolName}] Missing activeLayer`);

    logAction(`[${this.model.type}] init ${this.toolName} (layerId: ${layer.id})`);

    this.layer = layer;

    if (this.alwaysFetchSrcData || (this.editor.renderer.name !== 'webgl' && this.editor.renderer.name !== 'webgl2')) {
      // TODO: we should not use getLayerImageData() here as it unnecessarily padds the layer pixels to image size.
      //       instead just fetch pixels in layer.rect (need to test if everything still works)
      this.srcData = this.editor.renderer.getLayerImageData(layer);
    }

    copyRect(this.layerRect, layer.rect);
    copyRect(this.surfaceRect, layer.rect);

    if (!isRectEmpty(this.surfaceRect)) {
      outsetRect(this.surfaceRect, this.layerRectPadding);
      clipRect(this.surfaceRect, 0, 0, this.editor.drawing.width, this.editor.drawing.height);
    }

    const src = layer.canvas || layer.texture;

    if (src && !isRectEmpty(layer.rect)) {
      this.snapshot = this.editor.renderer.createSurface('filter', layer.rect.w, layer.rect.h);
      this.editor.renderer.copyToSnapshot(src, this.snapshot, layer.rect.x - layer.textureX, layer.rect.y - layer.textureY, layer.rect.w, layer.rect.h, 0, 0);
    } else {
      this.snapshot = undefined;
    }
  }
  apply(values: IFiltersValues) {
    logAction(`[${this.model.type}] apply ${this.toolName}`, true);

    this.updateValues(values);
    this.reset();
    this.applyInternal();
  }
  save(values: IFiltersValues) {
    logAction(`[${this.model.type}] save ${this.toolName}`);

    this.updateValues(values);

    if (!this.preview) this.init();

    if (isRectEmpty(this.layerRect)) {
      this.cancel();
    } else {
      this.reset();
      this.commit();
    }
  }
  cancel() {
    logAction(`[${this.model.type}] cancel ${this.toolName}`);

    if (!this.layer) return;

    this.reset();
    this.model.user.history.unpre();
    this.cleanup();
  }
  private reset() {
    if (!this.layer) throw new Error(`[${this.toolName}] Missing layer`);

    const entry: HistoryBufferEntry | undefined = this.snapshot ? {
      buffer: { sheets: [] }, rect: this.layerRect, x: 0, y: 0,
      sheet: { left: 0, bottom: 0, top: 0, entries: [], surface: this.snapshot }
    } : undefined;

    this.editor.renderer.restoreSnapshotToLayer(entry, this.layer, this.layerRect);
    this.editor.renderer.releaseUserCanvas(this.model.user);

    redrawDrawing(this.editor);
    redrawLayerThumb(this.layer);
  }
  private applyInternal() {
    const user = this.model.user;
    const layer = this.layer;

    if (isRectEmpty(this.layerRect)) return;
    if (!layer) throw new Error(`[${this.toolName}] Missing layer`);

    setupSurface(user.surface, this.id, CompositeOp.Draw, layer);
    copyRect(user.surface.rect, this.surfaceRect);
    this.editor.renderer.copyLayerToSurface(layer, user.surface);

    if (isMaskEmpty(user.selection)) {
      this.editor.renderer.releaseLayer(layer);
    } else {
      this.editor.renderer.cutLayer(layer, user.selection);
    }

    this.applyFilter();
    this.editor.renderer.commitTool(user, false);

    redrawDrawing(this.editor);
    redrawLayerThumb(layer);
  }
  private commit() {
    if (!this.layer) throw new Error(`[${this.toolName}] Missing layer`);
    this.model.user.history.pushDirtyRect(this.toolName, this.layer.id, this.surfaceRect);
    this.applyInternal();
    this.model.doTool<IFilterData>(this.layer.id, { id: this.id, ...this.values, br: cloneRect(this.layerRect), ar: cloneRect(this.layer.rect) });
    this.cleanup();
  }
  private cleanup() {
    this.srcData = undefined;
    this.layer = undefined;
    if (this.snapshot) this.snapshot = this.editor.renderer.releaseSurface(this.snapshot);
  }
  private updateValues(values?: IFiltersValues) {
    Object.assign(this.values, this.parseData(values));
  }
  protected abstract applyFilter(): void;
  protected abstract parseData(data?: IFiltersValues): IFiltersValues;
}
