import { StoreConfig, Store } from '@datorama/akita';
import { UserState } from './user.query';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user', resettable: true })
export class UserStore extends Store<UserState> {
  constructor() {
    super({});
  }
}
