import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Analytics, BannerInfo } from '../../../common/interfaces';
import { faPlay } from '../../../generated/fa-icons';
import { ManageService } from '../../../services/manageService';
import { Model } from '../../../services/model';

@Component({
  selector: 'education-banner',
  templateUrl: 'education-banner.pug',
  styleUrls: ['education-banner.scss'],
})
export class EducationBanner implements OnInit {
  readonly playIcon = faPlay;
  @Input() banner!: BannerInfo;
  @Output() close = new EventEmitter();
  size: 'small' | 'medium' | 'large' = 'large';
  constructor(private element: ElementRef<HTMLElement>, private model: Model, private manage: ManageService) {
  }
  ngOnInit() {
    this.updateSize();
    this.model.trackEvent(Analytics.BannerOpened, { title: this.banner.title });
  }
  closeBanner() {
    this.model.trackEvent(Analytics.BannerClosed, { title: this.banner.title });
    this.manage.toggleBannersOff();
    this.close.emit();
  }
  clicked() {
    this.model.trackEvent(Analytics.BannerButtonClicked, { title: this.banner.title, link: this.banner.link });
    this.close.emit();
  }
  @HostListener('window:resize')
  updateSize() {
    const { width } = this.element.nativeElement.getBoundingClientRect();
    this.size = width < 600 ? 'small' : (width < 900 ? 'medium' : 'large');
  }
}
