import { Drawing } from './interfaces';

const MAX_ITEMS = 300;
const logMessages: string[] = [];
const logTimestamps: number[] = [];
const stateLog: any[][] = [];
let editor: { drawing: Drawing; } | undefined = undefined;

export function getTimestamp(time: number) {
  return (new Date(time)).toUTCString().split(' ')[4];
}

export function logAction(message: string, skipIfTheSame = false) {
  if (!SERVER) {
    if (skipIfTheSame && logMessages[logMessages.length - 1] == message) return;

    while (logMessages.length >= MAX_ITEMS) {
      logMessages.shift();
      logTimestamps.shift();
      stateLog.shift();
    }

    logMessages.push(message);
    logTimestamps.push(Date.now());

    if (DEVELOPMENT) {
      // console.log(message);
      const d = editor?.drawing;
      d && stateLog.push(d.layers.map(({ id, rect }) => ({ id, rect: { ...rect } })));
    }
  }
}

export function logActionInDebug(message: string) {
  logAction(message);
  DEVELOPMENT && !TESTS && console.warn(message);
}

let logEventHandler = (_message: string) => { };

export function logEvent(message: string) {
  if (!SERVER) {
    logAction(message);
    logEventHandler(message);
  }
}

export function setActionLogEventHandler(handler: (message: string) => void) {
  logEventHandler = handler;
}

export function setActionLogEditor(e: { drawing: Drawing; }) {
  editor = e;
}

export function getActionLog() {
  const log: string[] = [];

  for (let i = 0; i < logMessages.length; i++) {
    log.push(`[${getTimestamp(logTimestamps[i])}] ${logMessages[i]} `);
  }

  return log;
}

const layerLogs: string[][] = [];

export function layerLog(layerId: number, message: string) {
  if (DEVELOPMENT && !SERVER && !TESTS) {
    if (!layerLogs[layerId]) layerLogs[layerId] = [];
    layerLogs[layerId].push(message);
  }
}

if (typeof window !== 'undefined') {
  (window as any).actionLog = () => getActionLog().forEach(x => console.log(x));
}

if (DEVELOPMENT && typeof window !== 'undefined') {
  (window as any).actionLogRaw = getActionLog;
  (window as any).stateLogRaw = () => stateLog;
  (window as any).stateLog = () => stateLog.forEach(x => console.log(x));
  (window as any).layerLog = (id: number) => layerLogs[id].forEach(x => console.log(x));
  (window as any).actionsAndStates = () => {
    const log = getActionLog();
    for (let i = 0; i < log.length; i++) {
      console.log(log[i] + '\n  ' + stateLog[i].map(x => `${x.id}:{${x.rect.x},${x.rect.y},${x.rect.w},${x.rect.h}}`).join(', '));
    }
  };
}
