import { Editor, isFilterActive } from './editor';
import {
  zoomViewport, flipViewport, fitViewportOnScreen, fitViewportToActualPixels, moveViewport, setViewportState,
  rotateViewport, rotateViewportBy, zoomViewportAt, moveViewportTo, flipViewportAt,
} from '../common/viewport';
import { ViewportState } from '../common/interfaces';
import { logAction } from '../common/actionLog';
import { pointInRect } from '../common/rect';

export function canChangeView(editor: Editor) {
  const model = editor.model;
  if (editor.drawingInProgress && !isFilterActive(editor)) return false;
  if (model.isPresentationMode && !model.isPresentationHost && model.presentationModeState.followingHostViewportEnforced) return false;
  return true;
}

export function flipView(editor: Editor, shortcut: boolean) {
  if (canChangeView(editor)) {
    logAction('[local] flipView');
    const x = editor.lastCursorX - editor.left;
    const y = editor.lastCursorY - editor.top;

    if (shortcut && editor.model.settings.flipOnCursor && pointInRect(x, y, editor.drawing.rect)) {
      flipViewportAt(editor.view, x, y);
    } else {
      flipViewport(editor.view);
    }
  }
}

export function toggleViewGrayscale(editor: Editor) {
  logAction('[local] toggleViewGrayscale');
  const model = editor.model;
  editor.viewFilter = editor.viewFilter === 'grayscale' ? undefined : 'grayscale';
  if (model.isPresentationMode && model.isPresentationHost) {
    model.updatePresentationMode({ hostViewFilter: editor.viewFilter });
  }
}

export function zoomView(editor: Editor, delta: number) {
  if (canChangeView(editor)) {
    logAction('[local] zoomView');
    zoomViewport(editor.view, delta);
  }
}

export function zoomViewAt(editor: Editor, x: number, y: number, delta: number) {
  if (canChangeView(editor)) {
    // logAction('[local] zoomViewAt'); this is too spammy due to being called using scroll wheel
    zoomViewportAt(editor.view, delta, x, y);
  }
}

export function fitViewOnScreen(editor: Editor) {
  if (canChangeView(editor)) {
    logAction('[local] fitViewOnScreen');
    fitViewportOnScreen(editor.view);
  }
}

export function viewActualPixels(editor: Editor) {
  if (canChangeView(editor)) {
    logAction('[local] viewActualPixels');
    fitViewportToActualPixels(editor.view);
  }
}

export function rotateView(editor: Editor, angle: number) {
  if (canChangeView(editor)) {
    logAction('[local] rotateView');
    rotateViewportBy(editor.view, angle);
  }
}

export function resetViewRotation(editor: Editor) {
  if (canChangeView(editor)) {
    logAction('[local] resetViewRotation');
    rotateViewport(editor.view, 0);
  }
}

export function restoreViewState(editor: Editor, state: ViewportState | undefined) {
  if (canChangeView(editor) && state) {
    logAction('[local] restoreViewState');
    setViewportState(editor.view, state);
  }
}

export function moveView(editor: Editor, dx: number, dy: number) {
  if (canChangeView(editor) && (dx || dy)) {
    logAction(`[local] moveView (${dx}, ${dy})`);
    moveViewport(editor.view, dx, dy);
  }
}

export function centerViewOnPoint(editor: Editor, x: number, y: number) {
  if (canChangeView(editor)) {
    logAction(`[local] centerViewOnPoint (${x}, ${y})`);
    moveViewportTo(editor.view, editor.view.width / 2 - (x * editor.view.scale), editor.view.height / 2 - (y * editor.view.scale));
  }
}

