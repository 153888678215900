import { Rect } from './interfaces';
import { includes } from './baseUtils';

export function object(value: object) {
  if (!value || typeof value !== 'object') throw new Error('Invalid value');
}

export function integerRange(value: number, min: number, max: number) {
  if ((value | 0) !== value || value < min || value > max) throw new Error(`Invalid value`);
}

export function booleanOrUndefined(value: boolean | undefined) {
  if (value !== undefined && value !== true && value !== false) throw new Error(`Invalid value`);
}

export function numberRangeOrUndefined(value: number | undefined, min: number, max: number) {
  if (value !== undefined && (typeof value !== 'number' || value < min || value > max)) throw new Error(`Invalid value`);
}

export function stringOrUndefined(value: string | undefined) {
  if (value !== undefined && typeof value !== 'string') throw new Error(`Invalid value`);
}

export function stringEnumOrUndefined(value: string | undefined, values: string[]) {
  if (value !== undefined && typeof value !== 'string' && !includes(values, value)) throw new Error(`Invalid value`);
}

export function rectOrUndefined(value: Rect | undefined) {
  if (value === undefined) return;
  assertRect(value);
}

export function assertRect(value: Rect | undefined): asserts value is Rect {
  if (typeof value !== 'object' || value == null || typeof value.x !== 'number' || typeof value.y !== 'number' ||
    typeof value.w !== 'number' || typeof value.h !== 'number')
  throw new Error(`Invalid value`);
}
