import { memoize } from 'lodash';
import { DiagramOptions, UserData, EntityData } from 'shared/interfaces';
import { getValueOfVariable } from 'magma/common/utils';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function ServerConstant(variableName?: string) {
  return (target: any, propertyName: string): void => {
    const value = getValueOfVariable(variableName || propertyName);
    Object.defineProperty(target, propertyName, {
      get() {
        return value;
      },
    });
    return target;
  };
}

export function isStylesSetupEqual(initialSetup: DiagramOptions, currentSetup: DiagramOptions): boolean {
  for (const [key, value] of Object.entries(initialSetup)) {
    switch (key) {
      case 'pageSize':
        if (currentSetup.pageSize.width !== initialSetup.pageSize.width
          || currentSetup.pageSize.height !== initialSetup.pageSize.height
        ) {
          return false;
        }
        break;
      default:
        if ((currentSetup as any)[key] !== value) {
          return false;
        }
    }
  }

  return true;
}

export const splitBy = memoize((str: string, splitBy: string, index: number) => {
  return str.split(splitBy)[index];
});

export function validateEmail(email: string) {
  return emailRegex.test(email.toLowerCase());
}

export function isEntityOwner(entity: EntityData, user: UserData) {
  const ownerId = typeof entity.author === 'string' ? entity.author : entity.author?._id;
  return ownerId === user._id;
}

// values from $grid-breakpoints in _variables.scss

export function belowBreakpointXL() {
  return window.innerWidth < 1200;
}

export function belowBreakpointLG() {
  return window.innerWidth < 992;
}

export function belowBreakpointMD() {
  return window.innerWidth < 768;
}

export function belowBreakpointSM() {
  return window.innerWidth < 576;
}
