import { Component, EventEmitter, Output } from '@angular/core';
import { EDUCATION_LINK } from '../../../../common/constants';
import { faAdjust, faCheckCircle, faSchool, faTimesCircle } from '../../../../common/icons';
import { ManageService } from '../../../../services/manageService';

@Component({
  selector: 'edition-modal',
  templateUrl: 'edition-modal.pug',
  styleUrls: ['edition-modal.scss'],
})
export class EditionModal {
  readonly schoolIcon = faSchool;
  readonly noIcon = faTimesCircle;
  readonly yesIcon = faCheckCircle;
  readonly midIcon = faAdjust;
  readonly educationLink = EDUCATION_LINK;
  @Output() close = new EventEmitter<void>();
  tab = 0;
  constructor(private manage: ManageService) {
  }
  closeModal() {
    this.close.emit();
  }
  upgrade() {
    this.close.emit();
    this.manage.upgrade('pro-editions-modal');
  }
}
