import {
  createPolyRect, isPolySegmentEmpty, polyToArray, movePoly, getPolyBounds, isPointInsidePoly, isPolyEmpty, clonePoly, transformPoly, totalPolySize
} from './poly';
import { createEllipseOutline, createPolyOutline, union, difference, intersection, cropPoly } from './polyUtils';
import { deserializePoly, serializePoly } from './maskUtils';
import { copyRect, resetRect, moveRect, createRect, cloneRect, isRectEmpty, integerizeRect } from './rect';
import { Drawing, Rect, Mask, Poly, ToolSurface, Mat2d } from './interfaces';
import { clamp } from './mathUtils';
import { MASK_COORD_LIMIT } from './constants';
import { hasIdentityTransform, hasOnlyTranslation, hasZeroTransform } from './toolSurface';
import { logAction } from './actionLog';

let maskCacheId = 1;

export function createMask(): Mask {
  return {
    cacheId: 0,
    bounds: createRect(0, 0, 0, 0),
    poly: undefined,
  };
}

export function cloneMask(mask: Mask): Mask {
  return {
    cacheId: mask.cacheId,
    bounds: cloneRect(mask.bounds),
    poly: mask.poly && clonePoly(mask.poly),
  };
}

export function isMaskEmpty(mask: Mask) {
  return mask.poly === undefined || isPolyEmpty(mask.poly);
}

export function isMaskingWholeDrawing(mask: Mask, drawing: Drawing) {
  if (!mask.poly || isMaskEmpty(mask)) {
    return false;
  }

  const current = polyToArray(mask.poly);
  const r = drawing.rect;
  const fromDrawing = polyToArray(createPolyRect(r.x, r.y, r.w, r.h));

  if (current.length !== fromDrawing.length) {
    return false;
  }

  if (current[0].length !== fromDrawing[0].length) {
    return false;
  }

  for (let i = 0; i < current[0].length; i++) {
    if (current[0][i] !== fromDrawing[0][i]) {
      return false;
    }
  }

  return true;
}

export function isMaskingWholeRect(rect: Rect, mask: Mask) {
  if (isRectEmpty(rect)) return true;
  if (isMaskEmpty(mask)) return false;
  const rectPoly = createPolyRect(rect.x, rect.y, rect.w, rect.h);
  const diff = difference(rectPoly, mask.poly!);
  return isPolyEmpty(diff);
}

export function cutMaskFromRect(rect: Rect, mask: Mask) {
  if (isRectEmpty(rect)) return;
  if (isMaskEmpty(mask)) return;
  const rectPoly = createPolyRect(rect.x, rect.y, rect.w, rect.h);
  const diff = difference(rectPoly, mask.poly!);
  const bounds = getPolyBounds(diff);
  copyRect(rect, bounds);
}

function rectMaskIntersectionBounds(rect: Rect, mask: Mask) {
  if (isRectEmpty(rect)) return createRect(0, 0, 0, 0);
  if (isMaskEmpty(mask)) return createRect(0, 0, 0, 0);
  const rectPoly = createPolyRect(rect.x, rect.y, rect.w, rect.h);
  const inter = intersection(rectPoly, mask.poly!);
  const bounds = getPolyBounds(inter);
  integerizeRect(bounds);
  return bounds;
}

export function rectMaskIntersectionBoundsInt(rect: Rect, mask: Mask) {
  const bounds = rectMaskIntersectionBounds(rect, mask);
  integerizeRect(bounds);
  return bounds;
}

export function clampXLimits(value: number, bounds: Rect, drawingRect: Rect) {
  const size = drawingRect.w;
  const minX = -(bounds.x + size * 2);
  const maxX = size * 3 - (bounds.x + bounds.w);
  return clamp(value, minX, maxX);
}

export function clampYLimits(value: number, bounds: Rect, drawingRect: Rect) {
  const size = drawingRect.h;
  const minY = -(bounds.y + size * 2);
  const maxY = size * 3 - (bounds.y + bounds.h);
  return clamp(value, minY, maxY);
}

export function moveMask(mask: Mask, x: number, y: number) {
  if (mask.poly && (x || y)) {
    movePoly(mask.poly, x, y);
    moveRect(mask.bounds, x, y);
    mask.cacheId = maskCacheId++;
  }
}

export function transformMask(mask: Mask, transform: Mat2d) {
  if (!mask.poly) return;
  transformPoly(mask.poly, transform);
  mask.poly = createPolyOutline(mask.poly);
  copyRect(mask.bounds, getPolyBounds(mask.poly));
  mask.cacheId = maskCacheId++;
}

export function transformAndClipMask(mask: Mask, surface: ToolSurface) {
  if (!mask.poly || isMaskEmpty(mask) || hasIdentityTransform(surface)) return;

  if (hasZeroTransform(surface)) {
    clearMask(mask);
  } else if (hasOnlyTranslation(surface)) {
    moveMask(mask, surface.translateX, surface.translateY);
  } else {
    transformMask(mask, surface.transform);
  }

  // crop if we exceeded limits
  const b = mask.bounds;
  const limit = MASK_COORD_LIMIT;
  if (b.x < -limit || b.y < -limit || (b.x + b.w) > limit || (b.y + b.h) > limit) {
    logAction('cropped mask');
    cropPoly(mask.poly, -limit, -limit, limit * 2, limit * 2);
    mask.bounds = getPolyBounds(mask.poly);
    mask.cacheId = maskCacheId++;
    if (isRectEmpty(mask.bounds)) clearMask(mask);
  }
}

export function clearMask(mask: Mask) {
  mask.cacheId = 0;
  mask.poly = undefined;
  resetRect(mask.bounds);
}

function add(mask: Mask, poly: Poly) {
  mask.poly = mask.poly ? union(mask.poly, poly) : poly;
  copyRect(mask.bounds, getPolyBounds(mask.poly));
  mask.cacheId = maskCacheId++;
}

function subtract(mask: Mask, poly: Poly) {
  if (mask.poly) {
    mask.poly = difference(mask.poly, poly);
    copyRect(mask.bounds, getPolyBounds(mask.poly));
    mask.cacheId = maskCacheId++;
  }
}

function intersect(mask: Mask, poly: Poly) {
  if (mask.poly) {
    mask.poly = intersection(mask.poly, poly);
    copyRect(mask.bounds, getPolyBounds(mask.poly));
    mask.cacheId = maskCacheId++;
  }
}

export function addRectToMask(mask: Mask, rect: Rect) {
  add(mask, createPolyRect(rect.x, rect.y, rect.w, rect.h));
}

export function subtractRectFromMask(mask: Mask, rect: Rect) {
  subtract(mask, createPolyRect(rect.x, rect.y, rect.w, rect.h));
}

export function intersectRectWithMask(mask: Mask, rect: Rect) {
  intersect(mask, createPolyRect(rect.x, rect.y, rect.w, rect.h));
}

export function addEllipseToMask(mask: Mask, rect: Rect) {
  add(mask, createEllipseOutline(rect));
}

export function subtractEllipseFromMask(mask: Mask, rect: Rect) {
  subtract(mask, createEllipseOutline(rect));
}

export function intersectEllipseWithMask(mask: Mask, rect: Rect) {
  intersect(mask, createEllipseOutline(rect));
}

export const POLY_SIZE_LIMIT = 100;
export const POLY_TOTAL_SIZE_LIMIT = 10000;

export function addPolyToMask(mask: Mask, poly: Poly) {
  if (poly.length < POLY_SIZE_LIMIT && totalPolySize(poly) < POLY_TOTAL_SIZE_LIMIT) {
    add(mask, createPolyOutline(poly));
  } else {
    DEVELOPMENT && console.warn(`Poly too large (segments: ${poly.length}, size: ${totalPolySize(poly)})`);
  }
}

export function subtractPolyFromMask(mask: Mask, poly: Poly) {
  if (poly.length < POLY_SIZE_LIMIT && totalPolySize(poly) < POLY_TOTAL_SIZE_LIMIT) {
    subtract(mask, createPolyOutline(poly));
  } else {
    DEVELOPMENT && console.warn(`Poly too large (segments: ${poly.length}, size: ${totalPolySize(poly)})`);
  }
}

export function intersectPolyWithMask(mask: Mask, poly: Poly) {
  if (poly.length < POLY_SIZE_LIMIT && totalPolySize(poly) < POLY_TOTAL_SIZE_LIMIT) {
    intersect(mask, createPolyOutline(poly));
  } else {
    DEVELOPMENT && console.warn(`Poly too large (segments: ${poly.length}, size: ${totalPolySize(poly)})`);
  }
}

export function invertMask(mask: Mask, drawingRect: Rect) {
  if (mask.poly) {
    const rect = createPolyRect(drawingRect.x, drawingRect.y, drawingRect.w, drawingRect.h);
    mask.poly = difference(rect, mask.poly);
    copyRect(mask.bounds, getPolyBounds(mask.poly));
    mask.cacheId = maskCacheId++;
  }
}

export function maskContainsXY(mask: Mask, x: number, y: number) {
  return mask.poly && isPointInsidePoly(mask.poly, x, y);
}

// TODO: serialize to Uint8Array instead
export function serializeMask(mask: Mask) {
  return serializePoly(mask.poly);
}

export function deserializeMask(mask: Mask, data?: number[]) {
  mask.poly = deserializePoly(data);

  if (mask.poly) {
    copyRect(mask.bounds, getPolyBounds(mask.poly));
    mask.cacheId = maskCacheId++;
  } else {
    resetRect(mask.bounds);
    mask.cacheId = 0;
  }
}

function pathMask(context: CanvasRenderingContext2D, mask: Mask) {
  if (!mask.poly) return false;

  context.beginPath();

  for (const segment of mask.poly) {
    if (!isPolySegmentEmpty(segment)) {
      const { items, size } = segment;
      const size2 = size << 1;

      context.moveTo(items[0], items[1]);

      for (let j = 2; j < size2; j += 2) {
        context.lineTo(items[j], items[j + 1]);
      }

      context.lineTo(items[0], items[1]);
    }
  }

  return true;
}

export function fillMask(context: CanvasRenderingContext2D, mask: Mask) {
  if (pathMask(context, mask)) {
    context.fill();
  }
}

export function clipMask(context: CanvasRenderingContext2D, mask: Mask) {
  if (pathMask(context, mask)) {
    context.clip();
  }
}
