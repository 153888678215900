import { Directive, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { removeElement } from '../../../common/htmlUtils';
import { isiOS } from '../../../common/userAgentUtils';

@Directive({ selector: '[pickFile], [pickFileMultiple]' })
export class PickFile implements OnDestroy {
  private input: HTMLInputElement | undefined = undefined;
  @Output() pickFile = new EventEmitter<File>();
  @Output() pickFileMultiple = new EventEmitter<File[]>();
  @Input() fileTypes = '';

  @HostListener('click')
  click() {
    if (!this.input) {
      this.input = document.createElement('input');
      this.input.type = 'file';
      this.input.style.display = 'none';
      this.input.multiple = this.isMultiple();
      this.input.onchange = () => {
        const files = this.input?.files;
        const file = files?.[0];

        if (this.isMultiple() && files?.length) {
          this.pickFileMultiple.emit(Array.from(files));
        } else if (file) {
          this.pickFile.emit(file);
        }
      };

      document.body.appendChild(this.input);
    }

    if (this.input) this.input.value = '';
    if (!isiOS) this.input.accept = this.fileTypes;
    this.input.click();
  }

  private isMultiple() {
    return this.pickFileMultiple.observers.length > 0;
  }

  ngOnDestroy() {
    if (this.input) {
      removeElement(this.input);
    }
  }
}
