import { getUrl } from './rev';
import { CreateImageData, Rect } from './interfaces';
import { MB, MAX_IMAGE_WIDTH, MAX_IMAGE_HEIGHT } from './constants';
import { createReader, readUint32 } from './binary';
import { compressImageDataRLE, decompressImageDataRLE, compressImageAlphaRLE, decompressImageAlphaRLE } from './rle';
import { get } from './xhr';
import { cloneBuffer } from './utils';
import { delay } from './promiseUtils';
import { userAgent } from './userAgentUtils';

const PAGE_SIZE = 0x10000;
const PAGES_INITIAL = 128; // 256; // 16MB
const PAGES_MAX = 8192; // 512MB
const MEMORY_BASE = (5244592 + 16) | 0; // 5MB, ~80 pages

export let usingWasm = false;
export let wasmFailed: string | undefined = undefined;
export let compressImageDataRLEWasm = compressImageDataRLE;
export let decompressImageDataRLEWasm = decompressImageDataRLE;
export let compressImageAlphaRLEWasm = compressImageAlphaRLE;
export let decompressImageAlphaRLEWasm = decompressImageAlphaRLE;

let memory: WebAssembly.Memory;
let wasm: {
  compressImageDataRLE(inputPtr: number, outputPtr: number, width: number, height: number, skipAlpha: boolean): number;
  decompressImageDataRLE(inputPtr: number, outputPtr: number, outputLength: number, skipAlpha: boolean): number;
  compressImageAlpha(
    inputPtr: number, outputPtr: number, tempPtr: number,
    width: number, height: number, x: number, y: number, w: number, h: number): number;
  decompressImageAlpha(inputPtr: number, outputPtr: number, outputSize: number, color: number): number;
};

function ensureMemorySize(size: number) {
  const requiredSize = MEMORY_BASE + size;
  const missingSize = requiredSize - memory.buffer.byteLength;

  if (missingSize > 0) {
    const pages = Math.ceil(missingSize / PAGE_SIZE);
    memory.grow(pages);
    DEVELOPMENT && console.log(`memory.grow: pages: ${pages}, size: ${(memory.buffer.byteLength / MB).toFixed(1)}MB`);
  }
}

// let memoryPoint = MEMORY_BASE;

// function resetAlloc() {
// }

// function alloc(size: number) {
//   const inputPtr = MEMORY_BASE;
//   const inputLength = data.data.byteLength;
//   ensureMemorySize(inputLength * 2.1);
//   return new Uint8Array(memory.buffer, inputPtr, size);
// }

function _compressImageDataRLEWasm(imageData: ImageData, skipAlpha = false): Uint8Array {
  try {
    const inputLength = imageData.data.byteLength;
    ensureMemorySize(inputLength * 2.1);
    const input = new Uint8Array(memory.buffer, MEMORY_BASE, inputLength);
    input.set(imageData.data);
    const outputPtr = input.byteOffset + input.byteLength;
    const outputLength = wasm.compressImageDataRLE(input.byteOffset, outputPtr, imageData.width, imageData.height, skipAlpha);
    return cloneBuffer(new Uint8Array(memory.buffer, outputPtr, outputLength));
  } catch (e) {
    DEVELOPMENT && console.error(e);
    return compressImageDataRLE(imageData);
  }
}

function _decompressImageDataRLEWasm(buffer: Uint8Array, createImageData: CreateImageData, skipAlpha = false): ImageData {
  try {
    const reader = createReader(buffer);
    const width = readUint32(reader);
    const height = readUint32(reader);

    if (width > MAX_IMAGE_WIDTH || height > MAX_IMAGE_HEIGHT) throw new Error(`Exceeded max size ${width}x${height}`);

    const inputLength = buffer.byteLength;
    const outputLength = width * height * 4;
    ensureMemorySize(inputLength + outputLength);
    const input = new Uint8Array(memory.buffer, MEMORY_BASE, inputLength);
    input.set(buffer);
    const output = new Uint8ClampedArray(memory.buffer, input.byteOffset + input.byteLength, outputLength);
    const result = wasm.decompressImageDataRLE(input.byteOffset + 8, output.byteOffset, outputLength, skipAlpha);
    if (!result) throw new Error(`Invalid RLE data`);

    return createImageData(width, height, output);
  } catch (e) {
    DEVELOPMENT && console.error(e);
    return decompressImageDataRLE(buffer, createImageData);
  }
}

export function _compressImageAlphaRLEWasm(imageData: ImageData, rect: Rect): Uint8Array {
  try {
    if (rect.w <= 0 || rect.h <= 0 || imageData.width <= 0)
      throw new Error(`Invalid data for compression (${rect.w}, ${rect.h}, ${imageData.width})`);

    const inputLength = imageData.data.byteLength;
    const tempLength = rect.w * rect.h;
    ensureMemorySize(inputLength + tempLength * 2.1);
    const input = new Uint8Array(memory.buffer, MEMORY_BASE, inputLength);
    input.set(imageData.data);
    const tempPtr = input.byteOffset + input.byteLength;
    const outputPtr = tempPtr + tempLength;
    const outputLength = wasm.compressImageAlpha(
      input.byteOffset, outputPtr, tempPtr, imageData.width, imageData.height,
      rect.x, rect.y, rect.w, rect.h);
    return cloneBuffer(new Uint8Array(memory.buffer, outputPtr, outputLength));
  } catch (e) {
    DEVELOPMENT && console.error(e);
    return compressImageAlphaRLE(imageData, rect);
  }
}

export function _decompressImageAlphaRLEWasm(
  data: Uint8Array, width: number, height: number, color: number | undefined, createImageData: CreateImageData
) {
  try {
    const c = color === undefined ? 0 : ((color | 0xff) >>> 0);
    const inputLength = data.byteLength;
    const outputLength = 4 * width * height;
    ensureMemorySize(inputLength + outputLength);
    const input = new Uint8Array(memory.buffer, MEMORY_BASE, inputLength);
    input.set(data);
    const output = new Uint8ClampedArray(memory.buffer, input.byteOffset + input.byteLength, outputLength);
    const result = wasm.decompressImageAlpha(input.byteOffset, output.byteOffset, outputLength, c);
    if (!result) throw new Error(`Invalid RLE data`);
    return createImageData(width, height, output);
  } catch (e) {
    DEVELOPMENT && console.error(e);
    return decompressImageAlphaRLE(data, width, height, color, createImageData);
  }
}

const wasmLog: string[] = [];

async function initWasm(url: string, importObject: any, fallbackFetch?: (url: string) => Promise<ArrayBuffer>) {
  wasmLog.length = 0;

  for (let i = 0; i < 5; i++) {
    let useUrl = url;
    if (i) useUrl += `?${Date.now()}`; // cache bust
    wasmLog.push(`attempt ${i}`);
    try {
      if (
        typeof fetch === 'function' &&
        'instantiateStreaming' in WebAssembly &&
        !/ Edge\//.test(userAgent) && // bypass bug on Edge
        !/Chrome\/60\./.test(userAgent) && // bypass bug on Chrome 60
        !i // use old method for re-tries
      ) {
        wasmLog.push('fetch');
        const req = fetch(useUrl);
        wasmLog.push('instantiateStreaming');
        return await WebAssembly.instantiateStreaming(req, importObject);
      } else {
        wasmLog.push('get');
        const buffer = await get<ArrayBuffer>(useUrl, 'arraybuffer');
        wasmLog.push(`instantiate (bytes: ${buffer.byteLength})`);
        return await WebAssembly.instantiate(buffer, importObject);
      }
    } catch (e) {
      wasmLog.push(e.message);
      // testing
      if (/WebAssembly\.instantiate is not a function/.test(e.message)) {
        const keys: string[] = [];
        for (const key in WebAssembly) keys.push(key);
        throw new Error(`${e.message} (type: ${typeof WebAssembly}, keys: [${keys.join(', ')}])`);
      }

      // not sure what `cancelled` error is
      if (
        !/cancelled|Failed to fetch|NetworkError|Could not connect to the server/.test(e.message) &&
        !/status code is not ok|Request failed|Could not download wasm module/.test(e.message) &&
        !/the given value is not a Promise|Load failed/.test(e.message)
      ) {
        throw e;
      }

      await delay(500);
    }
  }

  if (fallbackFetch) {
    wasmLog.push('fallback fetch');
    const buffer = await fallbackFetch(url);
    wasmLog.push(`fallback instantiate (bytes: ${buffer.byteLength})`);
    return await WebAssembly.instantiate(buffer, importObject);
  }

  throw new Error(`Failed to init WASM`);
}

let wasmLoading = false;

export async function loadWasm(fallbackFetch?: (url: string) => Promise<ArrayBuffer>) {
  if (usingWasm || wasmLoading) return true;

  wasmLoading = true;
  const url = getUrl('wasm/main.wasm');

  if (!url) {
    wasmFailed = 'no url';
    return false;
  }

  if (typeof WebAssembly === 'undefined' || /Chrome\/58\./.test(userAgent)) { // chrome 58 uses old wasm version
    wasmFailed = 'no WebAssembly';
    return false;
  }

  try {
    const { instance } = await initWasm(url, {
      env: {
        table: new WebAssembly.Table({ initial: 64, element: 'anyfunc' }),
        tableBase: 0,
        memory: new WebAssembly.Memory({ initial: PAGES_INITIAL, maximum: PAGES_MAX }),
        memoryBase: 0,
        abort() { console.log('[wasm] abort'); },
        console_log(value: any) { console.log('[wasm]', value); },
        _llvm_bswap_i32(x: number) {
          x = x | 0;
          return (((x & 0xff) << 24) | (((x >> 8) & 0xff) << 16) | (((x >> 16) & 0xff) << 8) | (x >>> 24)) | 0;
        },
      },
    }, fallbackFetch);

    usingWasm = true;
    memory = instance.exports.memory as WebAssembly.Memory;
    wasm = instance.exports as any;
    compressImageDataRLEWasm = _compressImageDataRLEWasm;
    decompressImageDataRLEWasm = _decompressImageDataRLEWasm;
    compressImageAlphaRLEWasm = _compressImageAlphaRLEWasm;
    decompressImageAlphaRLEWasm = _decompressImageAlphaRLEWasm;
    wasmFailed = undefined;
    return true;
  } catch (e) {
    wasmFailed = `Error: ${e.message || e}\nLog:\n${wasmLog.map(x => `    ${x}`).join('\n')}\nStack:\n    ${e.stack}`;
    DEVELOPMENT && console.error(e);
    return false;
  }
}
