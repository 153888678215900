import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PersistState, resetStores } from '@datorama/akita';
import { CreateAccountData, CreateOauthAccountData, ResetPasswordData } from 'magma/common/interfaces';
import { fromNow } from 'magma/common/utils';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, NEVER } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { TOKEN_NAME, TOKEN_LIFETIME } from 'shared/constants';
import { LoginUserData, ResetPasswordRequestData, UserData } from 'shared/interfaces';
import { getTokenData, isTokenExpired } from 'shared/jwt';
import { toPromise } from 'shared/utils';
import { UserStore } from './user.store';

@Injectable({ providedIn: 'root' })
export class AuthService {
  token$ = new BehaviorSubject(this.getToken());
  loggedIn$ = this.token$.pipe(map(isTokenLoggedIn));

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    private userStore: UserStore,
    @Inject('persistStorage') private persistStorage: PersistState,
  ) {
    this.refreshToken();
  }

  get loggedIn() {
    return isTokenLoggedIn(this.getToken());
  }

  get hasAuthToken() {
    return !!this.getToken();
  }

  getToken() {
    const token = this.cookieService.get(TOKEN_NAME);
    return isTokenExpired(token) ? undefined : token;
  }

  setToken(token: string) {
    this.cookieService.set(TOKEN_NAME, token, fromNow(TOKEN_LIFETIME), '/');
    this.refreshToken();
  }

  refreshToken() {
    this.token$.next(this.getToken());
  }

  refresh() {
    const params = new HttpParams().set('updateLastVisit', true);
    return this.httpClient.get<UserData>('/api/profile', { params })
      .pipe(
        tap(user => {
          this.userStore.update({ user });
        }),
        catchError(e => {
          if (e.status === 403) {
            return this.logout();
          } else {
            DEVELOPMENT && console.warn(e);
            return NEVER;
          }
        }),
      );
  }

  async login({ email, password, rememberMe }: LoginUserData) {
    await toPromise(this.httpClient.post('/login', { email, password }));
    this.refreshToken();
    await this.refresh().toPromise();
  }

  async signupWithEmail(form: CreateAccountData) {
    const body = await toPromise(this.httpClient.post<{ needsEmailConfirmation: boolean }>('/signup', form));
    this.refreshToken();
    if (!body.needsEmailConfirmation) { // we should have gotten the auth token
      await toPromise(this.refresh());
    }
    return body;
  }

  async signupOauth(form: CreateOauthAccountData) {
    await toPromise(this.httpClient.post('/signup-oauth', form));
    this.refreshToken();
    await toPromise(this.refresh());
  }

  async resetPassword(form: ResetPasswordRequestData) {
    await toPromise(this.httpClient.post('/reset-password', form));
  }

  async changePassword(resetToken: string, data: ResetPasswordData) {
    await toPromise(this.httpClient.post(`/reset-password/${resetToken}`, data));
    this.refreshToken();
    await toPromise(this.refresh());
  }

  async finishInviteSignup(data: CreateAccountData) {
    await toPromise(this.httpClient.post(`/finish-registration`, data));
    this.refreshToken();
    await toPromise(this.refresh());
  }

  async validateInviteToken(token: string, email: string) {
    const resposne = await toPromise(this.httpClient.get(`/validate-token/${token}/${email}`)) as { valid: boolean };
    return resposne?.valid;
  }

  async logout(urlAfterLogout?: string) {
    await toPromise(this.httpClient.get('/logout'));

    resetStores();
    this.persistStorage.clearStore();
    this.cookieService.delete(TOKEN_NAME, '/');
    this.refreshToken();

    const urlTree = this.router.createUrlTree(['/login'], { queryParams: { urlAfterLogout } });
    void this.router.navigateByUrl(urlTree);
  }

  // TODO: this should not be necessary, server should initialize auth token if there isn't one
  async initAuth() {
    return toPromise(this.httpClient.get('/init-auth'));
  }

  async getTeamIdForOrganization(name: string): Promise<string | undefined> {
    const response = await toPromise(this.httpClient.post<{ teamId: string }>('/auth/organization', { name }));
    return response.teamId;
  }
}

function isTokenLoggedIn(token: string | undefined) {
  if (!token) return false;
  const tokenData = getTokenData(token);
  if (!tokenData) return false;
  return tokenData.userType !== 'anonymous';
}
