import { ITool, IToolEditor, IToolModel, ToolId } from '../interfaces';
import { BaseShapeTool, DrawShape } from './baseShapeTool';
import { faSquare } from '../icons';

export class RectangleTool extends BaseShapeTool implements ITool {
  id = ToolId.Rect;
  name = 'Rectangle';
  description = 'Create solid or outlined rectangular shapes';
  learnMore = 'https://help.magma.com/en/articles/6871496-rectangle-shape-tool';
  video = { url: '/assets/videos/rectangle.mp4', width: 374, height: 210 };
  icon = faSquare;
  drawShape = DrawShape.Rectangle;
  constructor(public editor: IToolEditor, public model: IToolModel) {
    super(editor, model);
  }
}
