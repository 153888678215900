import { invalidEnumReturn } from './baseUtils';
import { ToolId } from './interfaces';

export function isNonEditingTool(id: ToolId) {
  return id === ToolId.Selection ||
    id === ToolId.SelectionHelper ||
    id === ToolId.LassoSelection ||
    id === ToolId.CircleSelection ||
    id === ToolId.LayerUpdate;
}

export function isSelectionTool(id: ToolId) {
  return id === ToolId.Selection ||
    id === ToolId.SelectionHelper ||
    id === ToolId.LassoSelection ||
    id === ToolId.CircleSelection;
}

export function toolIdFromString(value: string, ignoreInvalid = false): ToolId {
  switch (value) {
    case 'brush': return ToolId.Brush;
    case 'pencil': return ToolId.Pencil;
    case 'eraser': return ToolId.Eraser;
    case 'move': return ToolId.Move;
    case 'rect': return ToolId.Rect;
    case 'ellipse': return ToolId.Ellipse;
    case 'eyedropper': return ToolId.Eyedropper;
    case 'layer': return ToolId.Layer;
    case 'layerUpdate': return ToolId.LayerUpdate;
    case 'selection': return ToolId.Selection;
    case 'selectionHelper': return ToolId.SelectionHelper;
    case 'circleSelection': return ToolId.CircleSelection;
    case 'lassoSelection': return ToolId.LassoSelection;
    case 'lassoBrush': return ToolId.LassoBrush;
    case 'hand': return ToolId.Hand;
    case 'rotateView': return ToolId.RotateView;
    case 'zoom': return ToolId.Zoom;
    case 'ref': return ToolId.Ref;
    case 'transform': return ToolId.Transform;
    case 'paintbucket': return ToolId.Paintbucket;
    case 'paste': return ToolId.Paste;
    case 'text': return ToolId.Text;
    case 'shape': return ToolId.Shape;
    case 'comment': return ToolId.Comment;
    case 'ai': return ToolId.AI;
    case 'gaussianBlur': return ToolId.GaussianBlur;
    case 'hueSaturationLightness': return ToolId.HueSaturationLightness;
    case 'brightnessContrast': return ToolId.BrightnessContrast;
    case 'curves': return ToolId.Curves;
    default:
      if (!ignoreInvalid && DEVELOPMENT) console.error(new Error(`Invalid tool id: '${value}'`));
      return ToolId.None;
  }
}

export function toolIdToString(id: ToolId): string {
  switch (id) {
    case ToolId.None: return '';
    case ToolId.Brush: return 'brush';
    case ToolId.Pencil: return 'pencil';
    case ToolId.Eraser: return 'eraser';
    case ToolId.Move: return 'move';
    case ToolId.Rect: return 'rect';
    case ToolId.Ellipse: return 'ellipse';
    case ToolId.Eyedropper: return 'eyedropper';
    case ToolId.Layer: return 'layer';
    case ToolId.LayerUpdate: return 'layerUpdate';
    case ToolId.Selection: return 'selection';
    case ToolId.SelectionHelper: return 'selectionHelper';
    case ToolId.CircleSelection: return 'circleSelection';
    case ToolId.LassoSelection: return 'lassoSelection';
    case ToolId.LassoBrush: return 'lassoBrush';
    case ToolId.DeleteSelection: return 'deleteSelection';
    case ToolId.Hand: return 'hand';
    case ToolId.RotateView: return 'rotateView';
    case ToolId.Zoom: return 'zoom';
    case ToolId.Ref: return 'ref';
    case ToolId.Transform: return 'transform';
    case ToolId.Paintbucket: return 'paintbucket';
    case ToolId.Paste: return 'paste';
    case ToolId.Text: return 'text';
    case ToolId.Shape: return 'shape';
    case ToolId.COUNT: return '';
    case ToolId.Comment: return 'comment';
    case ToolId.AI: return 'ai';
    case ToolId.GaussianBlur: return 'gaussianBlur';
    case ToolId.HueSaturationLightness: return 'hueSaturationLightness';
    case ToolId.BrightnessContrast: return 'brightnessContrast';
    case ToolId.Curves: return 'curves';
    default: return invalidEnumReturn(id, '');
  }
}
