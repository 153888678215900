import { Component, EventEmitter, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { faTimes, faExternalLink } from '../../../../generated/fa-icons';

@Component({
  selector: 'accept-ai-eula-modal',
  templateUrl: './accept-ai-eula-modal.pug',
  styleUrls: ['./accept-ai-eula-modal.scss']
})
export class AcceptAiEulaModal {
  readonly closeIcon = faTimes;
  readonly externalLink = faExternalLink;
  @Output() close = new EventEmitter<boolean>();
  stableDiffusionLicense = 'https://huggingface.co/spaces/CompVis/stable-diffusion-license';
  howItWorksVideoEmbed = this.sanitizer.bypassSecurityTrustResourceUrl('https://magm.ai/ai-video-eula');
  ofAge = false;
  fairUse = false;
  usageRestrictions = false;

  constructor(public sanitizer: DomSanitizer) { }

  get hasAllConsents() {
    return this.ofAge && this.fairUse && this.usageRestrictions;
  }

  closeModal(decision: boolean) {
    this.close.emit(decision);
  }
}
