import { Component, Input, ChangeDetectionStrategy, OnInit, OnDestroy, OnChanges, ElementRef } from '@angular/core';
import moment from 'moment';
import { IntervalUpdateService } from '../../../services/intervalUpdateService';

@Component({
  selector: 'from-now',
  template: '<span></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FromNow implements OnInit, OnDestroy, OnChanges {
  @Input() time: any = undefined;
  private moment: moment.Moment | undefined = undefined;
  private text = '';
  private unsubscribe: (() => void) | undefined = undefined;
  constructor(private element: ElementRef<HTMLElement>, private updateService: IntervalUpdateService) {
  }
  ngOnChanges() {
    this.moment = this.time ? moment(this.time) : undefined;
    this.update();
  }
  ngOnInit() {
    this.unsubscribe = this.updateService.subscribe(() => this.update());
    this.update();
  }
  ngOnDestroy() {
    this.unsubscribe?.();
  }
  update() {
    const text = this.moment?.fromNow(true) ?? '';

    if (this.text !== text) {
      this.text = text;
      this.element.nativeElement.firstChild!.textContent = text;
    }
  }
}
