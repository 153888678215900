import { Pipe, PipeTransform } from '@angular/core';
import { DocumentStatuses } from 'shared/constants';

@Pipe({ name: 'documentStatusPipe' })
export class DocumentStatusPipe implements PipeTransform {

  constructor() { }

  transform(value: string) {
    switch (value?.toLocaleLowerCase()) {
      case DocumentStatuses.DRAFT:
        return 'Draft';
      case DocumentStatuses.APPROVE:
        return 'Approved';
      case DocumentStatuses.REJECT:
        return 'Rejected';
      case DocumentStatuses.REVIEW:
        return 'In Review';
      default:
        return undefined;
    }
  }
}
