import type { Model } from '../services/model';

export function getLocale() {
  return SERVER ? 'en-US' : ((navigator.languages ? navigator.languages[0] : navigator.language) || 'en-US');
}

export function reportUnknownKey({ keyCode, key, code, which }: KeyboardEvent, model: Model) {
  if (keyCode === undefined && key === undefined) return;
  if (keyCode === 0) return; // unknown key
  if (keyCode === 255) return; // touchpad toggle

  // ?
  if (keyCode === 12 && key === 'Clear' && !code) return;
  if (keyCode === 24 && key === 'FinalMode' && !code) return;
  if (keyCode === 31 && key === 'ModeChange' && code === 'KeyboardLayoutSelect') return;
  if (keyCode === 41 && key === 'Select' && !code) return;
  if (keyCode === 156 && !code) return;
  if (keyCode === 162 && !code) return;
  // key: ZoomToggle, code: ZoomToggle (should be LaunchApp2)
  if (keyCode >= 195 && keyCode <= 218 && /^Gamepad/.test(key)) return; // Gamepad on old Edge
  if (keyCode === 196 && code === 'Hyper') return;
  if (keyCode === 202 && code === 'Hyper') return;
  if (keyCode === 211 && code === 'Hyper') return;
  if (keyCode === 214 && code === 'Hyper') return;
  if (keyCode === 217 && code === 'Hyper') return;
  if (keyCode === 229) return; // key: Process, code: AltLeft / ShiftLeft / ControlLeft
  if (keyCode === 230) return; // key: Compose, code: ControlLeft
  // Hyper, A, O on ru-Ru, eê in vi-VN, SALVE in pt-PT, ẃ, ≥ on en-US
  if (keyCode === 231) return; // && (code === 'Hyper' || code === 'Unidentified')) return;
  if (keyCode === 246 && key === 'Attn' && !code) return;
  if (keyCode === 247 && key === 'CrSel' && !code) return;
  if (keyCode === 249 && key === 'EraseEof' && !code) return;
  if (keyCode === 254 && key === 'Clear' && !code) return;
  if (keyCode === 416 && !code) return; // Ơ, SMART-TV
  if (keyCode === 1028 && !code) return; // Є, SMART-TV

  if ((!code || code === 'Unidentified') && (!key || key === 'Unidentified')) {
    if (keyCode === 1) return; // ?
    if (keyCode === 2) return; // ?
    if (keyCode === 3) return; // ?
    if (keyCode === 5) return; // ?
    if (keyCode === 7) return; // Hyper
    if (keyCode === 10) return; // ?
    if (keyCode === 11) return; // ?
    if (keyCode === 14) return; // ?
    if (keyCode === 26) return; // ?
    if (keyCode === 42) return; // Print
    if (keyCode === 58) return; // :
    if (keyCode === 146) return; // ?
    if (keyCode === 149) return; // ?
    if (keyCode === 150) return; // ?
    if (keyCode === 151) return; // ?
    if (keyCode === 158) return; // ?
    if (keyCode === 161) return; // ?
    if (keyCode === 162) return; // ?
    if (keyCode === 163) return; // # (firefox, hi-IN)
    if (keyCode === 185) return; // ?
    if (keyCode >= 195 && keyCode <= 209) return; // ?
    if (keyCode >= 210 && keyCode <= 215) return; // ?
    if (keyCode === 217) return; // ?
    if (keyCode === 218) return; // ?
    if (keyCode === 232) return; // ?
    if (keyCode === 233) return; // XF86Forward
    if (keyCode === 234) return; // ?
    if (keyCode === 237) return; // ?
    if (keyCode === 240) return; // jp
    if (keyCode === 241) return; // de
    if (keyCode === 244) return; // kanji (IE, ja-JP)
    if (keyCode === 252) return; // ?
    if (keyCode === 253) return; // ?
    if (keyCode === 461) return; // pt
    if (keyCode === 897) return; // ru (SmartTV NetCast)
    if (keyCode === 10071) return; // SMART-TV Tizen https://github.com/maikudou/SmartTV/blob/master/keycodes.md
    if (keyCode === 10135) return; // SMART-TV Tizen
  }

  const message = `keyCode: ${keyCode}, key: ${key}, code: ${code}, which: ${which} (lang: ${getLocale()})`;
  model.server.debug('key', message);
  DEVELOPMENT && console.log(message);
}
