import { BitmapData } from './interfaces';

interface PNGFrame {
  rect: { x: number; y: number; width: number; height: number; };
  blend: number;
  dispose: number;
  delay: number;
  data: Uint8Array;
}

interface PNGImage {
  width: number;
  height: number;
  tabs: { [key: string]: {}; };
  ctype: number;
  depth: number;
  frames: PNGFrame[];
  data: Uint8Array;
  compress?: any;
  interlace?: any;
  filter?: any;
}

function toRGBA8(out: PNGImage) {
  var w = out.width, h = out.height;

  if (out.tabs.acTL == null) {
    return [decodeImage(out.data, w, h, out).buffer];
  }

  var frms = [];

  if (out.frames[0].data == null) {
    out.frames[0].data = out.data;
  }

  var len = w * h * 4, img = new Uint8Array(len), empty = new Uint8Array(len), prev = new Uint8Array(len);

  for (let i = 0; i < out.frames.length; i++) {
    var frm = out.frames[i];
    var fx = frm.rect.x, fy = frm.rect.y, fw = frm.rect.width, fh = frm.rect.height;
    var fdata = decodeImage(frm.data, fw, fh, out);

    if (i !== 0) {
      for (let j = 0; j < len; j++) {
        prev[j] = img[j];
      }
    }

    if (frm.blend === 0) {
      copyTile(fdata, fw, fh, img, w, h, fx, fy, 0);
    } else if (frm.blend === 1) {
      copyTile(fdata, fw, fh, img, w, h, fx, fy, 1);
    }

    frms.push(img.buffer.slice(0));

    if (frm.dispose === 0) {
      // empty
    } else if (frm.dispose === 1) {
      copyTile(empty, fw, fh, img, w, h, fx, fy, 0);
    } else if (frm.dispose === 2) {
      for (let j = 0; j < len; j++) {
        img[j] = prev[j];
      }
    }
  }
  return frms;
}

function decodeImage(data: Uint8Array, w: number, h: number, out: PNGImage) {
  const area = w * h;
  const bpp = getBPP(out);
  const bpl = Math.ceil(w * bpp / 8);  // bytes per line
  const bf = new Uint8Array(area * 4);
  const bf32 = new Uint32Array(bf.buffer);
  const ctype = out.ctype;
  const depth = out.depth;

  if (ctype === 6) { // RGB + alpha
    const qarea = area << 2;

    if (depth === 8) {
      bf.set(data.subarray(0, qarea));
    } else if (depth === 16) {
      for (let i = 0; i < qarea; i++) {
        bf[i] = data[i << 1];
      }
    }
  } else if (ctype === 2) {  // RGB
    let ts = out.tabs['tRNS'] as null | number[];

    if (ts == null) {
      if (depth === 8) {
        for (let i = 0; i < area; i++) {
          let ti = i * 3;
          bf32[i] = (255 << 24) | (data[ti + 2] << 16) | (data[ti + 1] << 8) | data[ti];
        }
      }
      if (depth === 16) {
        for (let i = 0; i < area; i++) {
          let ti = i * 6;
          bf32[i] = (255 << 24) | (data[ti + 4] << 16) | (data[ti + 2] << 8) | data[ti];
        }
      }
    } else {
      let tr = ts[0], tg = ts[1], tb = ts[2];

      if (depth === 8) {
        for (let i = 0; i < area; i++) {
          let qi = i << 2, ti = i * 3; bf32[i] = (255 << 24) | (data[ti + 2] << 16) | (data[ti + 1] << 8) | data[ti];

          if (data[ti] === tr && data[ti + 1] === tg && data[ti + 2] === tb) {
            bf[qi + 3] = 0;
          }
        }
      } else if (depth === 16) {
        for (let i = 0; i < area; i++) {
          let qi = i << 2, ti = i * 6; bf32[i] = (255 << 24) | (data[ti + 4] << 16) | (data[ti + 2] << 8) | data[ti];

          if (readUshort(data, ti) === tr && readUshort(data, ti + 2) === tg && readUshort(data, ti + 4) === tb) {
            bf[qi + 3] = 0;
          }
        }
      }
    }
  } else if (ctype === 3) {  // palette
    let p = out.tabs['PLTE'] as number[];
    let ap = out.tabs['tRNS'] as null | number[];
    let tl = ap ? ap.length : 0;

    if (depth === 1) {
      for (let y = 0; y < h; y++) {
        let s0 = y * bpl, t0 = y * w;

        for (let i = 0; i < w; i++) {
          let qi = (t0 + i) << 2, j = ((data[s0 + (i >> 3)] >> (7 - ((i & 7) << 0))) & 1), cj = 3 * j;
          bf[qi] = p[cj];
          bf[qi + 1] = p[cj + 1];
          bf[qi + 2] = p[cj + 2];
          bf[qi + 3] = (j < tl) ? ap![j] : 255;
        }
      }
    } else if (depth === 2) {
      for (let y = 0; y < h; y++) {
        let s0 = y * bpl, t0 = y * w;

        for (let i = 0; i < w; i++) {
          let qi = (t0 + i) << 2, j = ((data[s0 + (i >> 2)] >> (6 - ((i & 3) << 1))) & 3), cj = 3 * j;
          bf[qi] = p[cj];
          bf[qi + 1] = p[cj + 1];
          bf[qi + 2] = p[cj + 2];
          bf[qi + 3] = (j < tl) ? ap![j] : 255;
        }
      }
    } else if (depth === 4) {
      for (let y = 0; y < h; y++) {
        let s0 = y * bpl, t0 = y * w;

        for (let i = 0; i < w; i++) {
          let qi = (t0 + i) << 2, j = ((data[s0 + (i >> 1)] >> (4 - ((i & 1) << 2))) & 15), cj = 3 * j;
          bf[qi] = p[cj];
          bf[qi + 1] = p[cj + 1];
          bf[qi + 2] = p[cj + 2];
          bf[qi + 3] = (j < tl) ? ap![j] : 255;
        }
      }
    } else if (depth === 8) {
      for (let i = 0; i < area; i++) {
        let qi = i << 2, j = data[i], cj = 3 * j;
        bf[qi] = p[cj];
        bf[qi + 1] = p[cj + 1];
        bf[qi + 2] = p[cj + 2];
        bf[qi + 3] = (j < tl) ? ap![j] : 255;
      }
    }
  } else if (ctype === 4) {  // gray + alpha
    if (depth === 8) {
      for (let i = 0; i < area; i++) {
        let qi = i << 2, di = i << 1, gr = data[di];
        bf[qi] = gr;
        bf[qi + 1] = gr;
        bf[qi + 2] = gr;
        bf[qi + 3] = data[di + 1];
      }
    } else if (depth === 16) {
      for (let i = 0; i < area; i++) {
        let qi = i << 2, di = i << 2, gr = data[di];
        bf[qi] = gr;
        bf[qi + 1] = gr;
        bf[qi + 2] = gr;
        bf[qi + 3] = data[di + 2];
      }
    }
  } else if (ctype === 0) {  // gray
    let tr = out.tabs['tRNS'] ? out.tabs['tRNS'] as number : -1;

    for (let y = 0; y < h; y++) {
      var off = y * bpl, to = y * w;

      if (depth === 1) {
        for (let x = 0; x < w; x++) {
          var gr = 255 * ((data[off + (x >>> 3)] >>> (7 - ((x & 7)))) & 1), al = (gr === tr * 255) ? 0 : 255;
          bf32[to + x] = (al << 24) | (gr << 16) | (gr << 8) | gr;
        }
      } else if (depth === 2) {
        for (let x = 0; x < w; x++) {
          let gr = 85 * ((data[off + (x >>> 2)] >>> (6 - ((x & 3) << 1))) & 3), al = (gr === tr * 85) ? 0 : 255;
          bf32[to + x] = (al << 24) | (gr << 16) | (gr << 8) | gr;
        }
      } else if (depth === 4) {
        for (let x = 0; x < w; x++) {
          let gr = 17 * ((data[off + (x >>> 1)] >>> (4 - ((x & 1) << 2))) & 15), al = (gr === tr * 17) ? 0 : 255;
          bf32[to + x] = (al << 24) | (gr << 16) | (gr << 8) | gr;
        }
      } else if (depth === 8) {
        for (let x = 0; x < w; x++) {
          let gr = data[off + x], al = (gr === tr) ? 0 : 255;
          bf32[to + x] = (al << 24) | (gr << 16) | (gr << 8) | gr;
        }
      } else if (depth === 16) {
        throw new Error('Not supported');
        // for (let x = 0; x < w; x++) {
        //   let gr = data[off + (x << 1)], al = (rs(data, off + (x << i)) === tr) ? 0 : 255;
        //   bf32[to + x] = (al << 24) | (gr << 16) | (gr << 8) | gr;
        // }
      }
    }
  }

  return bf;
}

function decode(data: Uint8Array) {
  var offset = 8;
  var out: PNGImage = { tabs: {}, frames: [] } as any;
  var dd = new Uint8Array(data.length); // put all IDAT data into it
  var doff = 0;
  var fd: Uint8Array;
  let foff = 0; // frames

  var mgck = [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a];

  for (var i = 0; i < 8; i++) {
    if (data[i] !== mgck[i]) {
      throw new Error('The input is not a PNG file!');
    }
  }

  while (offset < data.length) {
    var len = readUint(data, offset); offset += 4;
    var type = readASCII(data, offset, 4); offset += 4;

    if (type === 'IHDR') {
      IHDR(data, offset, out);
    } else if (type === 'IDAT') {
      dd.set(data.subarray(offset, offset + len), doff);
      doff += len;
    } else if (type === 'acTL') {
      out.tabs[type] = {
        num_frames: readUint(data, offset),
        num_plays: readUint(data, offset + 4)
      };
      fd = new Uint8Array(data.length);
    } else if (type === 'fcTL') {
      if (foff !== 0) {
        var fr = out.frames[out.frames.length - 1];
        fr.data = decompress(out, fd!.slice(0, foff), fr.rect.width, fr.rect.height);
        foff = 0;
      }
      var rct = {
        x: readUint(data, offset + 12),
        y: readUint(data, offset + 16),
        width: readUint(data, offset + 4),
        height: readUint(data, offset + 8)
      };
      var del = readUshort(data, offset + 22);
      del = readUshort(data, offset + 20) / (del === 0 ? 100 : del);
      var frm: PNGFrame = {
        rect: rct,
        delay: Math.round(del * 1000),
        dispose: data[offset + 24],
        blend: data[offset + 25],
      } as any;
      out.frames.push(frm);
    } else if (type === 'fdAT') {
      for (let i = 0; i < len - 4; i++) {
        fd![foff + i] = data[offset + i + 4];
      }
      foff += len - 4;
    } else if (type === 'pHYs') {
      out.tabs[type] = [readUint(data, offset), readUint(data, offset + 4), data[offset + 8]];
    } else if (type === 'cHRM') {
      out.tabs[type] = [];
      for (let i = 0; i < 8; i++) {
        (out.tabs[type] as number[]).push(readUint(data, offset + i * 4));
      }
    } else if (type === 'tEXt') {
      if (out.tabs[type] == null) {
        out.tabs[type] = {};
      }
      let nz = nextZero(data, offset);
      let keyw = readASCII(data, offset, nz - offset);
      let text = readASCII(data, nz + 1, offset + len - nz - 1);
      (out.tabs[type] as any)[keyw] = text;
    } else if (type === 'iTXt') {
      if (out.tabs[type] == null) {
        out.tabs[type] = {};
      }
      let nz = 0, off = offset;
      nz = nextZero(data, off);
      let keyw = readASCII(data, off, nz - off);
      off = nz + 1;
      // let cflag = data[off];
      // let cmeth = data[off + 1];
      off += 2;
      nz = nextZero(data, off);
      readASCII(data, off, nz - off); off = nz + 1; // ltag
      nz = nextZero(data, off);
      readUTF8(data, off, nz - off); off = nz + 1; // tkeyw
      let text = readUTF8(data, off, len - (off - offset));
      (out.tabs[type] as any)[keyw] = text;
    } else if (type === 'PLTE') {
      out.tabs[type] = readBytes(data, offset, len);
    } else if (type === 'hIST') {
      let pl = (out.tabs['PLTE'] as any[]).length / 3;
      out.tabs[type] = [];

      for (let i = 0; i < pl; i++) {
        (out.tabs[type] as number[]).push(readUshort(data, offset + i * 2));
      }
    } else if (type === 'tRNS') {
      if (out.ctype === 3) {
        out.tabs[type] = readBytes(data, offset, len);
      } else if (out.ctype === 0) {
        out.tabs[type] = readUshort(data, offset);
      } else if (out.ctype === 2) {
        out.tabs[type] = [readUshort(data, offset), readUshort(data, offset + 2), readUshort(data, offset + 4)];
      }
      //else console.log("tRNS for unsupported color type",out.ctype, len);
    } else if (type === 'gAMA') {
      out.tabs[type] = readUint(data, offset) / 100000;
    } else if (type === 'sRGB') {
      out.tabs[type] = data[offset];
    } else if (type === 'bKGD') {
      if (out.ctype === 0 || out.ctype === 4) {
        out.tabs[type] = [readUshort(data, offset)];
      } else if (out.ctype === 2 || out.ctype === 6) {
        out.tabs[type] = [readUshort(data, offset), readUshort(data, offset + 2), readUshort(data, offset + 4)];
      } else if (out.ctype === 3) {
        out.tabs[type] = data[offset];
      }
    } else if (type === 'IEND') {
      break;
    }
    // else {
    //   log("unknown chunk type", type, len);
    // }

    offset += len;
    readUint(data, offset); // crc
    offset += 4;
  }

  if (foff !== 0) {
    const fr = out.frames[out.frames.length - 1];
    fr.data = decompress(out, fd!.slice(0, foff), fr.rect.width, fr.rect.height);
    foff = 0;
  }

  out.data = decompress(out, dd, out.width, out.height);

  delete out.compress;
  delete out.interlace;
  delete out.filter;
  return out;
}

function decompress(out: PNGImage, data: Uint8Array, w: number, h: number) {
  const bpp = getBPP(out);
  const bpl = Math.ceil(w * bpp / 8);
  const buff = new Uint8Array((bpl + 1 + out.interlace) * h);
  data = inflate(new Uint8Array(data.buffer, 2, data.length - 6), buff);

  if (out.interlace === 0) {
    data = filterZero(data, out, 0, w, h);
  } else if (out.interlace === 1) {
    data = readInterlace(data, out);
  }

  return data;
}

const HHm_K = new Uint16Array(16);
const HHm_j = new Uint16Array(16);
const HHm_X = [16, 17, 18, 0, 8, 7, 9, 6, 10, 5, 11, 4, 12, 3, 13, 2, 14, 1, 15];
const HHm_S = [3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 15, 17, 19, 23, 27, 31, 35, 43, 51, 59, 67, 83, 99, 115, 131,
  163, 195, 227, 258, 999, 999, 999];
const HHm_T = [0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 0, 0, 0, 0];
const HHm_q = new Uint16Array(32);
const HHm_p = [1, 2, 3, 4, 5, 7, 9, 13, 17, 25, 33, 49, 65, 97, 129, 193, 257, 385, 513, 769, 1025, 1537, 2049,
  3073, 4097, 6145, 8193, 12289, 16385, 24577, 65535, 65535];
const HHm_z = [0, 0, 0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10, 11, 11, 12, 12, 13, 13, 0, 0];
const HHm_c = new Uint32Array(32);
const HHm_J = new Uint16Array(512);
const HHm__: number[] = [];
const HHm_h = new Uint16Array(32);
const HHm_$: number[] = [];
const HHm_w = new Uint16Array(32768);
const HHm_C: number[] = [];
const HHm_v: number[] = [];
const HHm_d = new Uint16Array(32768);
const HHm_D: number[] = [];
const HHm_u = new Uint16Array(512);
const HHm_Q: number[] = [];
const HHm_r = new Uint16Array(1 << 15);

{
  const W = 1 << 15;

  for (let R = 0; R < W; R++) {
    let V = R;
    V = (V & 2863311530) >>> 1 | (V & 1431655765) << 1;
    V = (V & 3435973836) >>> 2 | (V & 858993459) << 2;
    V = (V & 4042322160) >>> 4 | (V & 252645135) << 4;
    V = (V & 4278255360) >>> 8 | (V & 16711935) << 8;
    HHm_r[R] = (V >>> 16 | V << 16) >>> 17;
  }

  for (let R = 0; R < 32; R++) {
    HHm_q[R] = HHm_S[R] << 3 | HHm_T[R];
    HHm_c[R] = HHm_p[R] << 4 | HHm_z[R];
  }

  pushRepeatedZeroAndValue(HHm__, 144, 8);
  pushRepeatedZeroAndValue(HHm__, 255 - 143, 9);
  pushRepeatedZeroAndValue(HHm__, 279 - 255, 7);
  pushRepeatedZeroAndValue(HHm__, 287 - 279, 8);
  HHn(HHm__, 9);
  HHA(HHm__, 9, HHm_J);
  HHl(HHm__, 9);
  pushRepeatedZeroAndValue(HHm_$, 32, 5);
  HHn(HHm_$, 5);
  HHA(HHm_$, 5, HHm_h);
  HHl(HHm_$, 5);
  pushRepeatedZeroAndValue(HHm_Q, 19, 0);
  pushRepeatedZeroAndValue(HHm_C, 286, 0);
  pushRepeatedZeroAndValue(HHm_D, 30, 0);
  pushRepeatedZeroAndValue(HHm_v, 320, 0);
}

function inflate(data: Uint8Array, result: Uint8Array): Uint8Array {
  if (data[0] === 3 && data[1] === 0) {
    return result;
  }

  var J = 0, h = 0, Q = 0, X = 0, u = 0, w = 0, d = 0;
  var v: Uint16Array, C: Uint16Array;

  for (let i = 0; i === 0;) {
    i = HHb(data, d, 1);
    const m = HHb(data, d + 1, 2);
    d += 3;

    if (m === 0) {
      if ((d & 7) !== 0) {
        d += 8 - (d & 7);
      }

      const D = (d >>> 3) + 4;
      const q = data[D - 4] | data[D - 3] << 8;

      result.set(new Uint8Array(data.buffer, data.byteOffset + D, q), w);
      d = D + q << 3;
      w = (w + q) | 0;
      continue;
    } else if (m === 1) {
      v = HHm_J;
      C = HHm_h;
      X = (1 << 9) - 1;
      u = (1 << 5) - 1;
    } else if (m === 2) {
      J = HHe(data, d, 5) + 257;
      h = HHe(data, d + 5, 5) + 1;
      Q = HHe(data, d + 10, 4) + 4;
      d += 14;
      let j = 1;

      for (let c = 0; c < 38; c += 2) {
        HHm_Q[c] = 0;
        HHm_Q[c + 1] = 0;
      }

      for (let c = 0; c < Q; c++) {
        const K = HHe(data, d + c * 3, 3);
        HHm_Q[(HHm_X[c] << 1) + 1] = K;

        if (K > j) {
          j = K;
        }
      }

      d += 3 * Q;
      HHn(HHm_Q, j);
      HHA(HHm_Q, j, HHm_u);
      v = HHm_w;
      C = HHm_d;
      d = HHR(HHm_u, (1 << j) - 1, J + h, data, d, HHm_v);
      const r = HHV(HHm_v, 0, J, HHm_C);
      X = (1 << r) - 1;
      const S = HHV(HHm_v, J, h, HHm_D);
      u = (1 << S) - 1;
      HHn(HHm_C, r);
      HHA(HHm_C, r, v);
      HHn(HHm_D, S);
      HHA(HHm_D, S, C);
    }

    while (true) {
      const T = v![HHZ(data, d) & X];
      d += T & 15;
      const p = T >>> 4;

      if (p >>> 8 === 0) {
        result[w] = p;
        w = (w + 1) | 0;
      } else if (p === 256) {
        break;
      } else {
        let z = (w + p - 254) | 0;

        if (p > 264) {
          const bb = HHm_q[p - 257];
          z = (w + (bb >>> 3) + HHe(data, d, bb & 7)) | 0;
          d += bb & 7;
        }

        const aa = C![HHZ(data, d) & u];
        d += aa & 15;
        const Y = HHm_c[aa >>> 4];
        const a = (Y >>> 4) + HHb(data, d, Y & 15);
        d += Y & 15;

        if ((z - a) < w) {
          result.copyWithin(w, w - a, z - a);
        } else {
          let wa = (w - a) | 0;

          while (w < z) {
            result[(w + 0) | 0] = result[(wa + 0) | 0];
            result[(w + 1) | 0] = result[(wa + 1) | 0];
            result[(w + 2) | 0] = result[(wa + 2) | 0];
            result[(w + 3) | 0] = result[(wa + 3) | 0];
            w = (w + 4) | 0;
            wa = (wa + 4) | 0;
          }
        }

        w = z | 0;
      }
    }
  }

  return result.length === w ? result : result.slice(0, w);
}

function HHR(N: Uint16Array, W: number, R: number, V: Uint8Array, n: number, A: number[]): number {
  var I = 0;

  while (I < R) {
    var e = N[HHZ(V, n) & W];
    n += e & 15;
    var b = e >>> 4;

    if (b <= 15) {
      A[I] = b;
      I++;
    } else {
      var Z = 0, m = 0;

      if (b === 16) {
        m = 3 + HHe(V, n, 2);
        n += 2;
        Z = A[I - 1];
      } else if (b === 17) {
        m = 3 + HHe(V, n, 3);
        n += 3;
      } else if (b === 18) {
        m = 11 + HHe(V, n, 7);
        n += 7;
      }

      var J = I + m;

      while (I < J) {
        A[I] = Z;
        I++;
      }
    }
  }

  return n;
}

function HHV(N: number[], W: number, R: number, V: number[]): number {
  var n = 0, A = 0, l = V.length >>> 1;

  while (A < R) {
    const M = N[A + W];
    V[A << 1] = 0;
    V[(A << 1) + 1] = M;

    if (M > n) {
      n = M;
    }

    A++;
  }

  while (A < l) {
    V[A << 1] = 0;
    V[(A << 1) + 1] = 0;
    A++;
  }

  return n;
}

function HHn(N: number[], W: number) {
  for (let M = 0; M <= W; M++) {
    HHm_j[M] = 0;
  }

  const V = N.length;

  for (let M = 1; M < V; M += 2) {
    HHm_j[N[M]]++;
  }

  let n = 0;
  HHm_j[0] = 0;

  for (let A = 1; A <= W; A++) {
    n = n + HHm_j[A - 1] << 1;
    HHm_K[A] = n;
  }

  for (let l = 0; l < V; l += 2) {
    const I = N[l + 1];

    if (I !== 0) {
      N[l] = HHm_K[I];
      HHm_K[I]++;
    }
  }
}

function HHA(N: number[], W: number, R: Uint16Array) {
  for (let l = 0; l < N.length; l += 2) {
    if (N[l + 1] !== 0) {
      let M = l >> 1;
      let I = N[l + 1];
      let e = M << 4 | I;
      let b = W - I;
      let Z = N[l] << b;
      let m = Z + (1 << b);

      while (Z !== m) {
        const J = HHm_r[Z] >>> 15 - W;
        R[J] = e;
        Z++;
      }
    }
  }
}

function HHl(data: number[], W: number) {
  const V = 15 - W;

  for (let n = 0; n < data.length; n += 2) {
    const A = data[n] << W - data[n + 1];
    data[n] = HHm_r[A] >>> V;
  }
}

function HHe(data: Uint8Array, W: number, R: number) {
  return (data[W >>> 3] | data[(W >>> 3) + 1] << 8) >>> (W & 7) & (1 << R) - 1;
}

function HHb(data: Uint8Array, W: number, R: number) {
  return (data[W >>> 3] | data[(W >>> 3) + 1] << 8 | data[(W >>> 3) + 2] << 16) >>> (W & 7) & (1 << R) - 1;
}

function HHZ(data: Uint8Array, W: number) {
  return (data[W >>> 3] | data[(W >>> 3) + 1] << 8 | data[(W >>> 3) + 2] << 16) >>> (W & 7);
}

function pushRepeatedZeroAndValue(array: number[], count: number, value: number) {
  while (count-- !== 0) {
    array.push(0, value);
  }
}

const starting_row = [0, 0, 4, 0, 2, 0, 1];
const starting_col = [0, 4, 0, 2, 0, 1, 0];
const row_increment = [8, 8, 8, 4, 4, 2, 2];
const col_increment = [8, 8, 4, 4, 2, 2, 1];

function readInterlace(data: Uint8Array, out: PNGImage) {
  const w = out.width;
  const h = out.height;
  const bpp = getBPP(out);
  const cbpp = bpp >> 3;
  const bpl = Math.ceil(w * bpp / 8);
  const img = new Uint8Array(h * bpl);
  var di = 0;
  var pass = 0;

  while (pass < 7) {
    var ri = row_increment[pass];
    var ci = col_increment[pass];
    var sw = 0, sh = 0;
    var cr = starting_row[pass];

    while (cr < h) {
      cr += ri; sh++;
    }

    var cc = starting_col[pass];

    while (cc < w) {
      cc += ci; sw++;
    }

    var bpll = Math.ceil(sw * bpp / 8);
    filterZero(data, out, di, sw, sh);
    var y = 0, row = starting_row[pass];

    while (row < h) {
      var col = starting_col[pass];
      var cdi = (di + y * bpll) << 3;

      while (col < w) {
        if (bpp === 1) {
          let val = data[cdi >> 3];
          val = (val >> (7 - (cdi & 7))) & 1;
          img[row * bpl + (col >> 3)] |= (val << (7 - ((col & 7) << 0)));
        }

        if (bpp === 2) {
          let val = data[cdi >> 3];
          val = (val >> (6 - (cdi & 7))) & 3;
          img[row * bpl + (col >> 2)] |= (val << (6 - ((col & 3) << 1)));
        }

        if (bpp === 4) {
          let val = data[cdi >> 3];
          val = (val >> (4 - (cdi & 7))) & 15;
          img[row * bpl + (col >> 1)] |= (val << (4 - ((col & 1) << 2)));
        }

        if (bpp >= 8) {
          var ii = row * bpl + col * cbpp;

          for (var j = 0; j < cbpp; j++) {
            img[ii + j] = data[(cdi >> 3) + j];
          }
        }

        cdi += bpp; col += ci;
      }

      y++; row += ri;
    }

    if (sw * sh !== 0) {
      di += sh * (1 + bpll);
    }

    pass = pass + 1;
  }
  return img;
}

const getBPP_noc = [1, 0 /*null*/, 3, 1, 2, 0 /*null*/, 4];

function getBPP(out: PNGImage) {
  const noc = getBPP_noc[out.ctype];
  return noc * out.depth;
}

function filterZero(data: Uint8Array, out: PNGImage, off: number, w: number, h: number) {
  const bpp0 = getBPP(out);
  const bpl = Math.ceil(w * bpp0 / 8) | 0;
  const bpp = Math.ceil(bpp0 / 8) | 0;

  for (var y = 0; y < h; y++) {
    let i = (off + y * bpl) | 0;
    let di = (i + y + 1) | 0;
    let type = data[di - 1];
    let x = 0 | 0;

    if (type === 0) {
      data.copyWithin(i, di, di + bpl);
    } else if (type === 1) {
      data.copyWithin(i, di, di + bpp);
      x = bpp;

      for (; x < bpl; x = (x + 1) | 0) {
        const ix = (i + x) | 0;
        data[ix] = (data[di + x] + data[ix - bpp]) & 255;
      }
    } else if (y === 0) {
      data.copyWithin(i, di, di + bpp);
      x = bpp;

      if (type === 2) {
        data.copyWithin(i + x, di + x, di + bpl);
      } else if (type === 3) {
        for (; x < bpl; x = (x + 1) | 0) {
          const ix = (i + x) | 0;
          data[ix] = (data[di + x] + (data[ix - bpp] >>> 1)) & 255;
        }
      } else if (type === 4) {
        for (; x < bpl; x = (x + 1) | 0) {
          const ix = (i + x) | 0;
          data[ix] = (data[di + x] + paeth(data[ix - bpp], 0, 0)) & 255;
        }
      }
    } else {
      if (type === 2) {
        for (; x < bpl; x = (x + 1) | 0) {
          const ix = (i + x) | 0;
          data[ix] = (data[(di + x) | 0] + data[(ix - bpl) | 0]) & 255;
        }
      } else if (type === 3) {
        for (; x < bpp; x = (x + 1) | 0) {
          const ix = (i + x) | 0;
          data[ix] = (data[di + x] + (data[ix - bpl] >>> 1)) & 255;
        }

        for (; x < bpl; x = (x + 1) | 0) {
          const ix = (i + x) | 0;
          data[ix] = (data[di + x] + ((data[ix - bpl] + data[ix - bpp]) >>> 1)) & 255;
        }
      } else if (type === 4) {
        for (; x < bpp; x = (x + 1) | 0) {
          const ix = (i + x) | 0;
          data[ix] = (data[di + x] + paeth(0, data[ix - bpl], 0)) & 255;
        }

        for (; x < bpl; x = (x + 1) | 0) {
          const ix = (i + x) | 0;
          const ixbpp = (ix - bpp) | 0;
          data[ix] = (data[(di + x) | 0] + paeth(data[ixbpp], data[(ix - bpl) | 0], data[(ixbpp - bpl) | 0])) & 255;
        }
      }
    }
  }

  return data;
}

function paeth(a: number, b: number, c: number) {
  const p = (((a + b) | 0) - c) | 0;
  const pa = p > a ? (p - a) | 0 : (a - p) | 0;
  const pb = p > b ? (p - b) | 0 : (b - p) | 0;
  const pc = p > c ? (p - c) | 0 : (c - p) | 0;

  if (pa <= pb && pa <= pc) {
    return a;
  } else if (pb <= pc) {
    return b;
  } else {
    return c;
  }
}

function IHDR(data: Uint8Array, offset: number, out: PNGImage) {
  out.width = readUint(data, offset);
  offset += 4;
  out.height = readUint(data, offset);
  offset += 4;
  out.depth = data[offset];
  offset++;
  out.ctype = data[offset];
  offset++;
  out.compress = data[offset];
  offset++;
  out.filter = data[offset];
  offset++;
  out.interlace = data[offset];
  offset++;
}

function nextZero(data: Uint8Array, p: number) {
  while (data[p] !== 0) p++;
  return p;
}

function readUshort(buff: Uint8Array, p: number) {
  return (buff[p] << 8) | buff[p + 1];
}

function readUint(buff: Uint8Array, p: number) {
  return ((buff[p] << 24) | (buff[p + 1] << 16) | (buff[p + 2] << 8) | buff[p + 3]) >>> 0;
}

function readASCII(buff: Uint8Array, offset: number, length: number) {
  var s = '';

  for (var i = 0; i < length; i++) {
    s += String.fromCharCode(buff[offset + i]);
  }

  return s;
}

function readBytes(buff: Uint8Array, offset: number, length: number) {
  var arr = [];

  for (var i = 0; i < length; i++) {
    arr.push(buff[offset + i]);
  }

  return arr;
}

function pad(n: string) {
  return n.length < 2 ? '0' + n : n;
}

function readUTF8(buff: Uint8Array, offset: number, length: number) {
  var s = '', ns;

  for (var i = 0; i < length; i++) {
    s += '%' + pad(buff[offset + i].toString(16));
  }

  try {
    ns = decodeURIComponent(s);
  } catch (e) {
    return readASCII(buff, offset, length);
  }

  return ns;
}

function copyTile(
  sb: Uint8Array, sw: number, sh: number, tb: Uint8Array, tw: number, th: number, xoff: number, yoff: number,
  mode: number
) {
  let w = Math.min(sw, tw), h = Math.min(sh, th);
  let si = 0, ti = 0;

  for (let y = 0; y < h; y++)
    for (let x = 0; x < w; x++) {
      if (xoff >= 0 && yoff >= 0) {
        si = (y * sw + x) << 2; ti = ((yoff + y) * tw + xoff + x) << 2;
      } else {
        si = ((-yoff + y) * sw - xoff + x) << 2; ti = (y * tw + x) << 2;
      }

      if (mode === 0) {
        tb[ti] = sb[si];
        tb[ti + 1] = sb[si + 1];
        tb[ti + 2] = sb[si + 2];
        tb[ti + 3] = sb[si + 3];
      } else if (mode === 1) {
        let fa = sb[si + 3] * (1 / 255), fr = sb[si] * fa, fg = sb[si + 1] * fa, fb = sb[si + 2] * fa;
        let ba = tb[ti + 3] * (1 / 255), br = tb[ti] * ba, bg = tb[ti + 1] * ba, bb = tb[ti + 2] * ba;

        let ifa = 1 - fa, oa = fa + ba * ifa, ioa = (oa === 0 ? 0 : 1 / oa);
        tb[ti + 3] = 255 * oa;
        tb[ti + 0] = (fr + br * ifa) * ioa;
        tb[ti + 1] = (fg + bg * ifa) * ioa;
        tb[ti + 2] = (fb + bb * ifa) * ioa;
      } else if (mode === 2) { // copy only differences, otherwise zero
        let fa = sb[si + 3], fr = sb[si], fg = sb[si + 1], fb = sb[si + 2];
        let ba = tb[ti + 3], br = tb[ti], bg = tb[ti + 1], bb = tb[ti + 2];

        if (fa === ba && fr === br && fg === bg && fb === bb) {
          tb[ti] = 0; tb[ti + 1] = 0; tb[ti + 2] = 0; tb[ti + 3] = 0;
        } else {
          tb[ti] = fr; tb[ti + 1] = fg; tb[ti + 2] = fb; tb[ti + 3] = fa;
        }
      } else if (mode === 3) { // check if can be blended
        let fa = sb[si + 3], fr = sb[si], fg = sb[si + 1], fb = sb[si + 2];
        let ba = tb[ti + 3], br = tb[ti], bg = tb[ti + 1], bb = tb[ti + 2];

        if (fa === ba && fr === br && fg === bg && fb === bb) {
          continue;
        }

        if (fa < 220 && ba > 20) {
          return false;
        }
      }
    }
  return true;
}

export function decodePNG(data: Uint8Array): BitmapData {
  const image = decode(data);
  const rgba = toRGBA8(image)[0];
  return { width: image.width, height: image.height, data: new Uint8Array(rgba) };
}
