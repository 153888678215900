import {
  BitmapData, CompositeOp, CopyMode, Cursor, CursorsMode, CursorType, defaultDrawingPermissions, Drawing, DrawingDataFlags,
  DrawOptions, ExtraLoader, HistoryBufferEntry, IErrorReporter, IFiltersValues, IRenderer, Layer, Mask, Mat2d, Mat4, Poly, Rect,
  RendererApi, Shader, TextLayer, Texture, TextureFormat, ToolId, ToolSurface, TriangleBatch, User, Vec2, Viewport, WebGLResources
} from '../common/interfaces';
import { applyTransform, createCanvas, defaultImageLoaders, getContext2d, loadPNGCancellable, loadPNGSupported, textWidth } from '../common/canvasUtils';
import { findByLocalId, findIndexById, getPixelRatio, removeAtFast } from '../common/utils';
import { findById, invalidEnum, quadraticEasing, removeItem } from '../common/baseUtils';
import { clamp, distance, round5 } from '../common/mathUtils';
import { fixLayerRect, smoothScalingWebgl, truncateName } from './renderer';
import { isLayerVisible, isTextLayer, layerChanged, loadLayerImages, redrawLayerThumb, shouldRedrawLayerThumb } from '../common/layer';
import {
  allocBuffer, bindAndClearBuffer, bindFrameBufferAndTexture, bindTexture, clearTexture, clearTextureRect, copyTextureRect, createEmptyTexture,
  createWebGLShader, deleteBuffer, deleteTexture, drawMesh, drawQuad, findMultipleOf256, findPowerOf2, getWebGLContext, Mesh, resizeTexture, textureToCanvas,
  unbindFrameBufferAndTexture, unbindTexture
} from './webgl';
import { shaders as shaderSources, vertexShader as vertexSource } from '../common/shaders';
import { colorFromRGBA, colorToFloatArray, colorToFloats, colorToRGBA, parseColor, rgbToGray } from '../common/color';
import { createBatch, createBuffer, flushBatch, pushAntialiasedLine, pushQuad, pushQuad2, pushQuad4, pushQuadTransformed, pushQuadXXYY, pushTransformedQuad, releaseBatch } from './webglBatch';
import { createViewport, createViewportMatrix2d, createViewportMatrix4, documentToScreenPoint, documentToScreenPoints } from '../common/viewport';
import {
  createRect, clipRect, isRectEmpty, rectContainsXY, rectsIntersection, copyRect, setRect, cloneRect, addRect,
  intersectRect, resetRect, haveNonEmptyIntersection, outsetRect, rectToString, rectIncludesRect
} from '../common/rect';
import { resetSurface, getSurfaceBounds, isSurfaceEmpty, getTransformBounds, getTransformOrigin, setupSurface, rectToBounds, transformBounds, hasZeroTransform } from '../common/toolSurface';
import { LassoSelectionTool } from '../common/tools/lassoSelectionTool';
import { CURSOR_AVATAR_LARGE_HEIGHT, CURSOR_VIDEO_HEIGHT, DEFAULT_FONT, LAYER_MODES, LAYER_THUMB_SIZE, MB, SEQUENCE_THUMB_HEIGHT, SEQUENCE_THUMB_SIZE, SEQUENCE_THUMB_WIDTH, SHOW_CURSOR_UNMOVING_TIMEOUT, SHOW_CURSORS, TRANSPARENT, USER_CURSOR_RADIUS, USER_NAME_HEIGHT, USER_NAME_OFFSET, WHITE, WHITE_FLOAT } from '../common/constants';
import { SelectionTool } from '../common/tools/selectionTool';
import { CircleSelectionTool } from '../common/tools/circleSelectionTool';
import { pushLineEllipse, pushLineRect, pushPoly, pushPolygon, pushRectOutline, pushRectOutlineTransformed } from './webglBatchUtils';
import { createWebGLRenderingContext, getShader } from './webglRenderingContext';
import { cloneMask, createMask, cutMaskFromRect, fillMask, isMaskEmpty, isMaskingWholeRect, rectMaskIntersectionBoundsInt, transformAndClipMask, transformMask } from '../common/mask';
import { createMat2d, getMat2dX, getMat2dY, identityMat2d, invertMat2d, isMat2dIdentity, isMat2dIntegerTranslation, multiplyMat2d, rotateMat2d, scaleMat2d, translateMat2d } from '../common/mat2d';
import { createMat4, fromYRotationMat4, identityMat4, lookAtMat4, multiplyMat4, perspectiveMat4, scaleMat4, translateMat4 } from '../common/mat4';
import { logAction } from '../common/actionLog';
import { cloneBounds, createVec2, createVec2FromValues, outsetBounds, transformVec2ByMat2d } from '../common/vec2';
import { getLayerRect, isLayerEmpty, layerHasNonEmptyToolSurface } from '../common/layerUtils';
import { copyPoint, createPoint, setPoint } from '../common/point';
import { drawDebugAllocatedTextures, drawDebugLayerBounds, drawDebugMarkers } from './webglDebug';
import { hasDrawingRole } from '../common/userRole';
import { get } from '../common/xhr';
import { readOBJ } from '../common/obj';
import { MAX_RECT } from './editorUtils';
import { isChromeOS, isiOS, isSafari } from '../common/userAgentUtils';
import { DRAW_TEXTURE_RECT, drawTextareaTextureRect, preprocessTextLayersForDrawing, shouldRenderTextareaBaselineIndicator, shouldRenderTextareaBoundaries, shouldRenderTextareaControlPoints, shouldRenderTextareaCursor, shouldRenderTextareaOverflowIndicator, TextTool, TextToolMode } from '../common/tools/textTool';
import { AutoWidthTextarea, Textarea, TEXTAREA_BOUNDARIES_COLOR, TEXTAREA_HOVERED_BOUNDARIES_WIDTH, TextareaType, TEXTAREA_SELECTION_RECT_COLOR, TEXTAREA_UNHOVERED_BOUNDARIES_WIDTH, getBaselineIndicatorAlignmentSquareSize, TEXTAREA_OVERFLOW_INDICATOR_SQUARE_SIZE, TEXTAREA_OVERFLOW_INDICATOR_PLUS_THICKNESS, TEXTAREA_OVERFLOW_INDICATOR_PLUS_SIZE, TEXTAREA_OVERFLOW_INDICATOR_RED, TEXTAREA_BASELINE_INDICATOR_HOVERED_LINE_THICKNESS, TEXTAREA_BASELINE_INDICATOR_UNHOVERED_LINE_THICKNESS, MIN_TEXTAREA_CURSOR_WIDTH, MAX_TEXTAREA_CURSOR_WIDTH } from '../common/text/textarea';
import { clipToDrawingRect } from '../common/drawing';
import { cacheTextareaInLayer, canDrawTextLayer, shouldCacheTextareaInLayer, shouldDrawTextarea } from '../common/text/text-utils';
import { toolIncompatibleWithTextLayers } from '../common/update';
import { AiTool } from '../common/tools/aiTool';
import { getCurveValues } from '../common/curves';
import { AI_BOUNDING_BOX_COLOR_1_ACTIVE, AI_BOUNDING_BOX_COLOR_2_ACTIVE, AI_BOUNDING_BOX_MASK_ALPHA, AI_SELECTION_COLOR_1_FLOAT, AI_SELECTION_COLOR_2_FLOAT } from '../common/aiInterfaces';
import { applyGaussianBlur } from '../common/gaussianBlur';

// debug switches
const REPORT_SLOW = DEVELOPMENT && false;
const DRAW_LAYER_RECTS = DEVELOPMENT && false;
const DRAW_ALLOCATED_TEXTURES = DEVELOPMENT && false;

// everything breaks if textures are not square on IE11
const IS_IE11 = typeof navigator !== 'undefined' && /Trident\/7/.test(navigator.userAgent);

const MIN_TEXTURE_SIZE = 256;
const USE_FAST_DRAWING = true;
const TEXTURE_POOL_SIZE = SERVER ? 5 : 5;
const cursorColor = colorToFloatArray(0x808080ff);
const transparentColor = colorToFloatArray(TRANSPARENT);
const mat4Identity = createMat4();
const tempMat4 = createMat4();
const tempMat = createMat2d();
const tempMat2 = createMat2d();
const tempVec = createVec2();
const tempRect = createRect(0, 0, 0, 0);
const tempPt = createPoint(0, 0);
const tempViewport = createViewport(0, 0, 1, 0, false);
const emptySelection = createMask();


let drawingBackgroundString: string | undefined = undefined;
let drawingBackground = colorToFloatArray(TRANSPARENT);
let backgroundString: string | undefined = undefined;
let background = colorToFloatArray(TRANSPARENT);
let lastFast = false;
let loseContext: WEBGL_lose_context | null = null;
let meshes: Mesh[] | undefined;
let meshesRotation = 0;

let noSelfVideoTime = 0;

export function identityViewMatrix(width: number, height: number) {
  tempViewport.width = tempViewport.contentWidth = width;
  tempViewport.height = tempViewport.contentHeight = height;
  return createViewportMatrix4(tempMat4, tempViewport);
}

function parseDrawingBackground(color: string | undefined) {
  if (drawingBackgroundString !== color) {
    drawingBackgroundString = color;
    colorToFloats(drawingBackground, parseColor(drawingBackgroundString || ''));
  }
  return drawingBackground;
}

function parseBackground(color: string | undefined) {
  if (backgroundString !== color) {
    backgroundString = color;
    colorToFloats(background, parseColor(backgroundString || ''));
  }
  return background;
}

function getPresentedVideoFrames(videoElement: HTMLVideoElement): number {
  const quality = videoElement.getVideoPlaybackQuality();
  return quality.totalVideoFrames;
}

// when disabled - screen blinking on Chrome OS
// when enabled - performance issues on iOS14 and some other mobile devices
export const preserveDrawingBuffer = isChromeOS;

export class WebGLRenderer implements IRenderer {
  name: RendererApi = 'webgl';
  canvas: HTMLCanvasElement | undefined = undefined;
  private webgl: WebGLResources | undefined = undefined;
  private sharedGL = false;

  addRedrawRect(_user: User, targetDirtyRect: Rect, _options: DrawOptions) {
    if (!preserveDrawingBuffer) {
      addRect(targetDirtyRect, MAX_RECT);
      return true;
    }
    return false;
  }

  constructor(public id: string, private errorReporter: IErrorReporter) {
  }

  private getGL(): WebGLResources {
    if (!this.webgl) throw new Error(`WebGL not initialized (${this.id})`);
    return this.webgl;
  }
  // for debug
  loseContext() {
    if (DEVELOPMENT) {
      if (loseContext) {
        loseContext.restoreContext();
        loseContext = null;
      } else if (this.webgl) {
        loseContext = this.webgl.gl.getExtension('WEBGL_lose_context')!;
        loseContext.loseContext();
      }
    }
  }
  // for debug
  canvases() {
    if (DEVELOPMENT && this.webgl) {
      return [
        ...this.webgl.allocatedTextures,
        ...this.webgl.textures.map((texture, i) => ({ texture, id: `free-${i}` })),
      ];
    } else {
      return [];
    }
  }
  stats() {
    if (this.webgl) {
      const { textures, allocatedTextures } = this.webgl;
      let bytes = 0;
      for (const texture of allocatedTextures) bytes += texture.width * texture.height * 4;
      for (const texture of textures) bytes += texture.width * texture.height * 4;
      return `${Math.floor(bytes / MB)} MB (${allocatedTextures.length}+${textures.length}) [${lastFast ? 'fast' : 'slow'}]`;
    } else {
      return '...';
    }
  }
  isWebgl2() {
    return !!this.webgl?.webgl2;
  }
  init(drawing: Drawing, canvas?: HTMLCanvasElement, gg?: WebGLResources) {
    if (canvas) {
      this.release();
      this.canvas = canvas;
      this.webgl = initializeWebGL(canvas, gg);
      this.name = this.webgl.webgl2 ? 'webgl2' : 'webgl';
      this.sharedGL = !!gg;
    }

    // 3D model test
    if (DEVELOPMENT && typeof window !== 'undefined' && false) {
      void get<string>('/tests/fox.obj', 'text').then(obj => {
        const model = readOBJ(obj);
        const gl = this.webgl!.gl;
        meshes = model.parts.map(part => {
          const vertexBuffer = createBuffer(gl, part.vertices);
          const indexBuffer = createBuffer(gl, part.indices, true);
          return { vertexBuffer, indexBuffer, elements: part.indices.length };
        });
      });
    }

    resizeWebGL(this.webgl!, drawing);
  }
  release() {
    if (this.webgl) {
      releaseWebGL(this.webgl, this.sharedGL);
      this.webgl = undefined;
    }
  }
  releaseTemp() {
  }
  releaseLayer(layer: Layer | undefined) {
    if (layer) releaseLayer(this.getGL(), layer);
  }
  releaseDrawing(drawing: Drawing) {
    for (const layer of drawing.layers) {
      this.releaseLayer(layer);
    }
  }
  // used in paintbucket tool
  getDrawingImageData(drawing: Drawing, flags: DrawingDataFlags) {
    const webgl = this.getGL();
    const temp = getTexture(webgl, webgl.textureWidth, webgl.textureHeight, 'temp-drawing-data');

    try {
      if (flags === DrawingDataFlags.NoBackground) {
        drawing = { ...drawing, background: '' };
      }

      this._drawDrawing(webgl, temp, drawing, drawing.rect);
      return getImageData(webgl, temp, 0, 0, drawing.width, drawing.height);
    } finally {
      releaseTexture(webgl, temp);
    }
  }
  // used in paintbucket tool
  getLayerImageData({ texture, textureX, textureY, rect }: Layer) {
    const webgl = this.getGL();
    const { width, height, textureWidth, textureHeight } = webgl;
    const imageData = this.createImageData(width, height, undefined);

    if (texture) {
      if (textureX || textureY || texture.width !== textureWidth || texture.height !== textureHeight) {
        // TODO: could be faster to pad the data on CPU ?
        const temp = getTexture(webgl, textureWidth, textureHeight, 'temp-layer-data', true);

        try {
          copyTextureRect(webgl, texture, temp, rect.x - textureX, rect.y - textureY, rect.w, rect.h, rect.x, rect.y);
          readPixels(webgl, temp, toUint8(imageData.data), 0, 0, width, height);
        } finally {
          releaseTexture(webgl, temp);
        }
      } else {
        readPixels(webgl, texture, toUint8(imageData.data), 0, 0, width, height);
      }
    }

    return imageData;
  }
  // used for exporting to PSD
  getDrawingThumbnail(drawing: Drawing, maxSize: number): HTMLCanvasElement {
    const webgl = this.getGL();
    const { gl, drawingTexture, batch, drawingTransform } = webgl;
    let width = 0, height = 0;

    if (drawing.width > drawing.height) {
      width = maxSize;
      height = Math.max(Math.floor(drawing.height * (width / drawing.width)), 1);
    } else {
      height = maxSize;
      width = Math.max(Math.floor(drawing.width * (height / drawing.height)), 1);
    }

    const temp = createEmptyTexture(gl, width, height);
    bindAndClearBuffer(webgl, temp, transparentColor);
    bindTexture(gl, 0, drawingTexture);
    const shader = getShader(webgl, 'basic');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, drawingTransform);
    pushQuad(batch, 0, 0, width, height, 0, 0, drawing.width / drawingTexture.width, drawing.height / drawingTexture.height, 0, 0, 1, 1, 1, 1);
    flushBatch(batch);
    unbindTexture(gl, 0);
    unbindFrameBufferAndTexture(webgl);
    const canvas = textureToCanvas(webgl, temp);
    deleteTexture(gl, temp);
    return canvas;
  }
  // used in worker service (testing in dev)
  _getLayerRawDataTest(layer: Layer) {
    if (!layer.texture || isRectEmpty(layer.rect)) throw new Error('Layer is empty');

    const webgl = this.getGL();
    const { gl } = webgl;
    let { x, y, w, h } = layer.rect;

    // TEMP: we had non-integer values in layer.rect
    w = Math.floor(w);
    h = Math.floor(h);

    const data = new Uint8Array(w * h * 4);
    bindFrameBufferAndTexture(webgl, layer.texture);
    gl.readPixels(x - layer.textureX, y - layer.textureY, w, h, gl.RGBA, gl.UNSIGNED_BYTE, data);
    unbindFrameBufferAndTexture(webgl);
    return { width: w, height: h, data, premultiplied: true };
  }
  // used in worker service and psd sync
  getLayerRawData(layer: Layer): BitmapData {
    if (!layer.texture || isRectEmpty(layer.rect)) throw new Error('Layer is empty');

    const webgl = this.getGL();
    const { gl } = webgl;
    let { x, y, w, h } = layer.rect;
    const data = new Uint8Array(w * h * 4);

    x -= layer.textureX;
    y -= layer.textureY;

    if (TESTS) {
      bindFrameBufferAndTexture(webgl, layer.texture);
      gl.readPixels(x, y, w, h, gl.RGBA, gl.UNSIGNED_BYTE, data);
      unbindFrameBufferAndTexture(webgl);
      return { width: w, height: h, data, premultiplied: true };
    } else {
      // bindFrameBufferAndTexture(webgl, layer.texture);
      // gl.readPixels(x, y, w, h, gl.RGBA, gl.UNSIGNED_BYTE, data);
      // unbindFrameBufferAndTexture(webgl);
      // unpremultiply(data);

      readPixels(webgl, layer.texture, data, x, y, w, h);
      return { width: w, height: h, data };
    }
  }
  // used in paste / paintbucket
  createImageData(width: number, height: number, data: Uint8ClampedArray | undefined): ImageData {
    return { width, height, data: data ?? allocUint8ClampedArray(width, height), colorSpace: 'srgb' };
  }
  // used in paste
  putImage(user: User, image: HTMLImageElement | HTMLCanvasElement | ImageBitmap, x = 0, y = 0) {
    const webgl = this.getGL();
    const { gl } = webgl;

    ensureSurfaceWithSize(webgl, user, image.width, image.height);

    bindTexture(gl, 0, user.surface.texture!);
    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, 1);

    if (SERVER) {
      // used in tests
      if (!('__raw' in image)) throw new Error('Not supported on server');
      const raw = (image as any).__raw as BitmapData;
      gl.texSubImage2D(gl.TEXTURE_2D, 0, x, y, raw.width, raw.height, gl.RGBA, gl.UNSIGNED_BYTE, raw.data);
    } else {
      gl.texSubImage2D(gl.TEXTURE_2D, 0, x, y, gl.RGBA, gl.UNSIGNED_BYTE, image);
    }

    unbindTexture(gl, 0);
    user.surface.textureHasMipmaps = false;
    if (user.surface.layer) redrawLayerThumb(user.surface.layer);
  }

  copyLayerToSurface(layer: Layer, surface: ToolSurface) {
    const webgl = this.getGL();
    if (!layer.texture) throw new Error('Layer Missing Texture');
    ensureSurface(webgl, surface, webgl.textureWidth, webgl.textureHeight, 0);
    copyTextureRect(webgl, layer.texture, surface.texture!, 0, 0, layer.texture?.width, layer.texture?.height, layer.textureX, layer.textureY);
  }
  // used in paste / paintbucket
  putImageData(user: User, image: ImageData, x = 0, y = 0) {
    const webgl = this.getGL();
    const { gl } = webgl;

    ensureSurfaceWithSize(webgl, user, image.width, image.height);

    bindTexture(gl, 0, user.surface.texture!);
    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, 1);
    gl.texSubImage2D(gl.TEXTURE_2D, 0, x, y, image.width, image.height, gl.RGBA, gl.UNSIGNED_BYTE, toUint8(image.data));
    unbindTexture(gl, 0);
    user.surface.textureHasMipmaps = false;
    if (user.surface.layer) redrawLayerThumb(user.surface.layer);
  }
  // for debug
  textureToCanvas(texture: Texture) {
    return textureToCanvas(this.getGL(), texture);
  }
  loadLayerImages(drawing: Drawing, extraLoader?: ExtraLoader, onProgress?: (progress: number) => void, ignoreErrors = false) {
    if (loadPNGSupported()) {
      return loadLayerImages(
        drawing, [{ name: 'png', load: loadPNGCancellable }],
        (layer, img) => this.initLayerFromBitmap(layer, img), onProgress, ignoreErrors);
    } else {
      return loadLayerImages(
        drawing, defaultImageLoaders(extraLoader),
        (layer, img) => this.initLayer(layer, img), onProgress, ignoreErrors);
    }
  }
  releaseUserCanvas({ surface }: User) {
    if (surface.layer && !isSurfaceEmpty(surface)) {
      redrawLayerThumb(surface.layer, true);
    }

    releaseSurface(this.webgl, surface);
  }
  initLayer(layer: Layer, image: HTMLImageElement | ImageBitmap) {
    const webgl = this.getGL();
    const { gl } = webgl;

    if (!image) return;

    fixLayerRect(layer, image, this.errorReporter);

    try {
      ensureLayerTexture(webgl, layer, layer.rect);
      gl.bindTexture(gl.TEXTURE_2D, layer.texture!.handle);
      gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, 1);
      gl.texSubImage2D(gl.TEXTURE_2D, 0, layer.rect.x - layer.textureX, layer.rect.y - layer.textureY,
        gl.RGBA, gl.UNSIGNED_BYTE, image);
      gl.bindTexture(gl.TEXTURE_2D, null);
    } catch (e) {
      // TEMP: testing
      logAction(`initLayer failed (error: ${e.message}, ctor: ${image?.constructor?.name}, src: ${(image as any)?.src})`);
      throw e;
    }
  }
  // this method modifies passed `bitmap`
  initLayerFromBitmap(layer: Layer, image: BitmapData) {
    const webgl = this.getGL();
    const { gl } = webgl;

    if (!image) return;

    fixLayerRect(layer, image, this.errorReporter);

    if (!image.premultiplied) {
      premultiply(image.data);
      image.premultiplied = true;
    }

    ensureLayerTexture(webgl, layer, layer.rect);
    gl.bindTexture(gl.TEXTURE_2D, layer.texture!.handle);
    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, 0);
    gl.texSubImage2D(gl.TEXTURE_2D, 0, layer.rect.x - layer.textureX, layer.rect.y - layer.textureY,
      image.width, image.height, gl.RGBA, gl.UNSIGNED_BYTE, image.data);
    gl.bindTexture(gl.TEXTURE_2D, null);
  }
  // used in history
  createSurface(id: string, width: number, height: number) {
    const webgl = this.getGL();

    let w = Math.max(MIN_TEXTURE_SIZE, findPowerOf2(width));
    let h = Math.max(MIN_TEXTURE_SIZE, findPowerOf2(height));
    if (IS_IE11) w = h = Math.max(w, h);

    return getTexture(webgl, w, h, id);
  }
  // used in history
  releaseSurface(texture: Texture | HTMLCanvasElement | undefined): undefined {
    if (texture && 'handle' in texture) {
      return releaseTexture(this.webgl, texture);
    } else {
      DEVELOPMENT && !TESTS && console.warn('releasing non-texture surface');
      return undefined;
    }
  }
  // used in history
  copyToSnapshot(src: Texture, dst: Texture, sx: number, sy: number, w: number, h: number, dx: number, dy: number) {
    const webgl = this.getGL();

    // if we don't have enough texture just clear rect in dst texture and copy existing part of src texture
    if (sx < 0 || sy < 0 || (sx + w) > src.width || (sy + h) > src.height) {
      clearTextureRect(webgl, dst, dx, dy, w, h);

      if (sx < 0) {
        w += sx;
        dx -= sx;
        sx = 0;
      }
      if (sy < 0) {
        h += sy;
        dy -= sy;
        sy = 0;
      }
      w = Math.min(w, src.width - sx);
      h = Math.min(h, src.height - sy);
    }

    if (w > 0 && h > 0) {
      copyTextureRect(webgl, src, dst, sx, sy, w, h, dx, dy);
    }
  }
  // used in history
  restoreSnapshotToLayer(entry: HistoryBufferEntry | undefined, layer: Layer, layerRect: Rect) {
    const webgl = this.getGL();

    if (isRectEmpty(layerRect)) {
      releaseLayer(webgl, layer);
      return;
    }

    ensureLayerTexture(webgl, layer, layerRect);

    if (entry) {
      let { sheet, x, y, rect } = entry;

      // rect can exceed layer.texture bounds
      let dx = rect.x - layer.textureX;
      let dy = rect.y - layer.textureY;
      let { w, h } = rect;
      if (dx < 0) {
        w += dx;
        x -= dx;
        dx = 0;
      }
      if (dy < 0) {
        h += dy;
        y -= dy;
        dy = 0;
      }
      w = Math.min(w, layer.texture!.width - dx);
      h = Math.min(h, layer.texture!.height - dy);

      if (w > 0 && h > 0) {
        copyTextureRect(webgl, sheet.surface as Texture, layer.texture!, x, y, w, h, dx, dy);
      }
    }

    layerChanged(layer);
  }
  // used in history
  restoreSnapshotToTool({ x, y, rect, sheet }: HistoryBufferEntry, user: User) {
    const webgl = this.getGL();
    ensureSurfaceWithSize(webgl, user, rect.x + rect.w, rect.y + rect.h);
    copyTextureRect(webgl, sheet.surface as Texture, user.surface.texture!, x, y, rect.w, rect.h, rect.x, rect.y);
  }
  // used in copy and save, returns data for full size or selection bounds
  getLayerSnapshot(layer: Layer, selection?: Mask, outBounds?: Rect) {
    const webgl = this.getGL();
    return getLayerSnapshot(webgl, layer, selection, outBounds);
  }
  // used in copy, save
  getDrawingSnapshot(drawing: Drawing, selection?: Mask) {
    const webgl = this.getGL();
    const { width, height } = webgl;
    const bounds = selection ? rectsIntersection(selection.bounds, drawing.rect) : createRect(0, 0, width, height);
    clipRect(bounds, 0, 0, width, height);

    if (isRectEmpty(bounds)) return undefined;

    const canvas = createCanvas(bounds.w, bounds.h);
    const context = getContext2d(canvas);

    const smallSelection = bounds.w !== drawing.width || bounds.h !== drawing.height;
    const x = smallSelection ? bounds.x : 0;
    const y = smallSelection ? bounds.y : 0;
    const w = smallSelection ? bounds.w : width;
    const h = smallSelection ? bounds.h : height;

    let textureWidth = smallSelection ? findTextureWidth(webgl, bounds.w) : webgl.textureWidth;
    let textureHeight = smallSelection ? findTextureHeight(webgl, bounds.h) : webgl.textureHeight;
    if (IS_IE11) textureWidth = textureHeight = Math.max(textureWidth, textureHeight);

    const temp = getTexture(webgl, textureWidth, textureHeight, 'temp-drawing-snapshot', false);
    const temp2 = getTexture(webgl, textureWidth, textureHeight, 'temp2-drawing-snapshot', false);

    try {
      this._drawDrawing(webgl, temp2, drawing, bounds, x, y);

      let src: Texture;

      if (selection) {
        const mask = createMaskTexture(webgl, selection, x, y, textureWidth, textureHeight);
        // TODO: in-place mask out using correct blend-mode (need to account for background)
        bindAndClearBuffer(webgl, temp, parseDrawingBackground(drawing.background));
        drawMasked(webgl, temp2, mask, 0, 'mask', 0, 0, w, h, 0, 0, 0, 0);
        unbindFrameBufferAndTexture(webgl);
        src = temp;
      } else {
        src = temp2;
      }

      const data = context.createImageData(canvas.width, canvas.height);
      readPixels(webgl, src, toUint8(data.data), bounds.x - x, bounds.y - y, bounds.w, bounds.h);
      context.putImageData(data, 0, 0);

      if (TESTS) (canvas as any).__raw = { width: canvas.width, height: canvas.height, data: toUint8(data.data) };
    } finally {
      releaseTexture(webgl, temp);
      releaseTexture(webgl, temp2);
    }

    return canvas;
  }
  // used in save (psd)
  getDrawingCanvasForImageData(drawing: Drawing) {
    return this.getDrawingSnapshot(drawing);
  }
  // used in save (psd)
  getLayerCanvasForImageData(drawing: Drawing, layer: Layer) {
    if (isLayerEmpty(layer)) return { rect: createRect(0, 0, 0, 0), canvas: undefined };

    const webgl = this.getGL();
    const rect = cloneRect(getLayerRect(layer));
    clipRect(rect, 0, 0, drawing.width, drawing.height); // this breaks out-of-canvas layer pixels
    return { rect, canvas: createFakeCanvas(webgl, layer, rect) };
  }
  // this will not release surface as regular commitTool
  commitToolOnLayer(user: User, layer: Layer, lockOpacity: boolean) {
    const webgl = this.getGL();
    commitTool(webgl, user, layer, lockOpacity);
  }
  commitTool(user: User, lockOpacity: boolean) {
    if (!user.activeLayer) throw new Error('No active layer');
    if (DEVELOPMENT && user.surface.context) throw new Error('Tool context not released');

    const webgl = this.getGL();
    commitTool(webgl, user, user.activeLayer, lockOpacity);
    releaseSurface(webgl, user.surface);
  }
  commitToolTransform(user: User) {
    const webgl = this.getGL();
    const surface = user.surface;

    if (!surface.layer) throw new Error('Missing surface layer');
    if (DEVELOPMENT && surface.context) throw new Error('Tool context not released');

    if (!isSurfaceEmpty(surface)) {
      commitTool(webgl, user, surface.layer, false);
    }

    transformAndClipMask(user.selection, surface);
    releaseSurface(webgl, surface);
  }
  // Assumes no active tool surfaces
  mergeLayers(src: Layer, dst: Layer, clip: boolean) {
    if (layerHasTool(src) || layerHasTool(dst)) throw new Error('Cannot merge layers with active tool');

    const webgl = this.getGL();

    if (
      src.texture && src.opacity !== 0 && !isRectEmpty(src.rect) &&
      !(clip && isRectEmpty(rectsIntersection(dst.rect, src.rect)))
    ) {
      const rect = cloneRect(dst.rect);
      if (!clip) addRect(rect, src.rect);

      const dstTexture = dst.texture;
      const dstTextureX = dst.textureX;
      const dstTextureY = dst.textureY;
      dst.texture = undefined;

      ensureLayerTexture(webgl, dst, rect);
      bindFrameBufferAndTexture(webgl, dst.texture!);
      drawLayer(webgl, dstTexture || webgl.emptyTexture, src, false, src.opacity, false, clip,
        dst.textureX, dst.textureY, dst.texture!.width, dst.texture!.height,
        dst.textureX - dstTextureX, dst.textureY - dstTextureY, dst.opacity);
      unbindFrameBufferAndTexture(webgl);
      releaseTexture(webgl, dstTexture);
      layerChanged(dst, true);
      dst.opacity = 1;
    }

    releaseLayer(webgl, src);
    layerChanged(src, true);
  }
  // used in move, transform tools
  splitLayer(surface: ToolSurface, layer: Layer, selection: Mask) {
    const webgl = this.getGL();
    ensureSurface(webgl, surface, webgl.textureWidth, webgl.textureHeight, 0);

    const rect = cloneRect(layer.rect);
    // TODO: use selection poly intersection here instead
    if (!isMaskEmpty(selection)) intersectRect(rect, selection.bounds);

    if (!layer.texture || isRectEmpty(rect)) return;

    if (isMaskEmpty(selection) || isMaskingWholeRect(layer.rect, selection)) {
      bindAndClearBuffer(webgl, surface.texture!, transparentColor);
      drawLayerTexture(webgl, layer);
      unbindFrameBufferAndTexture(webgl);
      releaseLayer(webgl, layer);
    } else {
      const mask = createMaskTexture(webgl, selection, layer.textureX, layer.textureY, layer.texture.width, layer.texture.height);
      bindAndClearBuffer(webgl, surface.texture!, transparentColor);
      const { x, y, w, h } = layer.rect;
      const tx = x - layer.textureX;
      const ty = y - layer.textureY;
      // TODO: use selection.bounds * layer.rect here
      drawMasked(webgl, layer.texture, mask, 0, 'mask', x, y, w, h, tx, ty, tx, ty);
      maskOutLayer(webgl, layer, mask, 0, 0);
      cutMaskFromRect(layer.rect, selection);
      layerChanged(layer);
    }

    copyRect(surface.rect, rect);
    surface.textureHasMipmaps = false;
  }
  // Assumes no active tool surface
  cutLayer(src: Layer, selection: Mask) {
    const webgl = this.getGL();

    if (!src.texture || isRectEmpty(src.rect)) return;
    if (isMaskEmpty(selection)) return;

    const mask = createMaskTexture(webgl, selection, src.textureX, src.textureY, src.texture.width, src.texture.height);
    maskOutLayer(webgl, src, mask, 0, 0);
    cutMaskFromRect(src.rect, selection);
    if (isRectEmpty(src.rect)) releaseLayer(webgl, src);
    layerChanged(src);
  }
  // Assumes no active tool surface and empty `dst` layer
  copyLayer(src: Layer, dst: Layer, selection: Mask | undefined, copyMode: CopyMode) {
    const webgl = this.getGL();

    if (layerHasTool(src) || layerHasTool(dst)) throw new Error('Cannot copy layers with active tool');
    if (dst.texture || !isRectEmpty(dst.rect)) throw new Error('Destination layer is not empty');
    if (!src.texture || isRectEmpty(src.rect)) return;

    if (selection) {
      const rect = rectMaskIntersectionBoundsInt(src.rect, selection);

      if (isRectEmpty(rect)) return;

      if (copyMode === CopyMode.Copy || copyMode === CopyMode.Cut) {
        ensureLayerTexture(webgl, dst, rect);
        const mask = createMaskTexture(webgl, selection, dst.textureX, dst.textureY, dst.texture!.width, dst.texture!.height);
        bindAndClearBuffer(webgl, dst.texture!, transparentColor);
        const { x, y, w, h } = rect;
        const dx = x - dst.textureX;
        const dy = y - dst.textureY;
        drawMasked(webgl, src.texture, mask, 0, 'mask', dx, dy, w, h, x - src.textureX, y - src.textureY, dx, dy);
        unbindFrameBufferAndTexture(webgl);

        if (copyMode === CopyMode.Cut) {
          maskOutLayer(webgl, src, mask, dst.textureX - src.textureX, dst.textureY - src.textureY);
          cutMaskFromRect(src.rect, selection);
          if (isRectEmpty(src.rect)) releaseLayer(webgl, src);
          layerChanged(src, true);
        }
      } else {
        invalidEnum(copyMode);
      }

      if (isRectEmpty(dst.rect)) releaseLayer(webgl, dst);
      layerChanged(dst);
    } else {
      if (copyMode === CopyMode.Copy) { // copy entire layer
        ensureLayerTexture(webgl, dst, src.rect);
        copyTextureRect(webgl, src.texture, dst.texture!,
          src.rect.x - src.textureX, src.rect.y - src.textureY, src.rect.w, src.rect.h,
          dst.rect.x - dst.textureX, dst.rect.y - dst.textureY);
        layerChanged(dst);
      } else {
        throw new Error('Invalid copyMode');
      }
    }
  }
  drawDrawing(drawing: Drawing, rect: Rect) {
    if (!this.webgl) return;

    const { gl, drawingTexture, textureWidth, textureHeight } = this.webgl;
    if (DEVELOPMENT && !drawingTexture) throw new Error('Missing drawingTexture');

    resizeTexture(gl, drawingTexture, textureWidth, textureHeight);
    this._drawDrawing(this.webgl, drawingTexture, drawing, rect);

    bindTexture(gl, 0, drawingTexture);
    gl.generateMipmap(gl.TEXTURE_2D);
    unbindTexture(gl, 0);
  }
  draw(drawing: Drawing, user: User, view: Viewport, rect: Rect, options: DrawOptions) {
    if (this.webgl) drawViewport(this.webgl, drawing, user, view, rect, options);
  }
  drawLayerThumbs(layers: Layer[], drawingRect: Rect) {
    if (this.webgl) {
      for (const layer of layers) {
        if (drawLayerThumb(this.webgl, layer, drawingRect)) {
          break;
        }
      }
    }
  }
  drawThumb(drawing: Drawing, rect: Rect) {
    if (this.webgl) drawThumb(this.webgl, drawing, rect);
  }
  pingThumb(drawing: Drawing) {
    if (this.webgl) pingThumb(this.webgl, drawing);
  }
  discardThumb() {
    if (this.webgl) discardThumb(this.webgl);
  }
  scaleImage(image: HTMLImageElement | ImageBitmap | ImageData, scaledWidth: number, scaledHeight: number) {
    const canvas = createCanvas(scaledWidth, scaledHeight);
    const context = getContext2d(canvas);
    const webgl = this.getGL();
    const { gl } = webgl;

    let textureWidth = findTextureWidth(webgl, image.width);
    let textureHeight = findTextureHeight(webgl, image.height);

    const texture = getTexture(webgl, textureWidth, textureHeight, 'scaled', false);

    bindTexture(gl, 0, texture);
    gl.texSubImage2D(gl.TEXTURE_2D, 0, 0, 0, gl.RGBA, gl.UNSIGNED_BYTE, image);
    unbindTexture(gl, 0);

    drawScaled(webgl, texture, scaledWidth, scaledHeight, 0, 0, image.width, image.height, context);

    releaseTexture(webgl, texture);

    return canvas;
  }
  getScaledDrawingSnapshot(drawing: Drawing, scaledWidth: number, scaledHeight: number, selection: Mask | undefined) {
    const canvas = createCanvas(scaledWidth, scaledHeight);
    const context = getContext2d(canvas);
    context.imageSmoothingEnabled = false;
    const webgl = this.getGL();
    const bounds = selection ? rectsIntersection(selection.bounds, drawing.rect) : createRect(0, 0, drawing.width, drawing.height);
    clipRect(bounds, 0, 0, drawing.width, drawing.height);

    const texture = getTexture(webgl, bounds.w, bounds.h, 'scaled', false);
    this._drawDrawing(webgl, texture, drawing, bounds, bounds.x, bounds.y);
    drawScaled(webgl, texture, scaledWidth, scaledHeight, 0, 0, bounds.w, bounds.h, context);

    releaseTexture(webgl, texture);

    return canvas;
  }
  getScaledLayerSnapshot(drawing: Drawing, layer: Layer, scaledWidth: number, scaledHeight: number, selection: Mask | undefined) {
    const canvas = createCanvas(scaledWidth, scaledHeight);
    const context = getContext2d(canvas);
    const webgl = this.getGL();
    const bounds = selection ? selection.bounds : createRect(0, 0, drawing.width, drawing.height);
    clipRect(bounds, 0, 0, drawing.width, drawing.height);

    const texture = getTexture(webgl, bounds.w, bounds.h, 'scaled', false);
    bindAndClearBuffer(webgl, texture, transparentColor);

    drawLayer(webgl, webgl.emptyTexture, layer, layer.opacityLocked, layer.opacity, true, false, bounds.x, bounds.y, bounds.w, bounds.h);
    drawScaled(webgl, texture, scaledWidth, scaledHeight, 0, 0, bounds.w, bounds.h, context);

    releaseTexture(webgl, texture);

    return canvas;
  }

  getScaledLayerMask(drawing: Drawing, layer: Layer, scaledWidth: number, scaledHeight: number, selection: Mask | undefined): HTMLCanvasElement {
    const canvas = createCanvas(scaledWidth, scaledHeight);
    const context = getContext2d(canvas);
    const webgl = this.getGL();
    const bounds = selection ? selection.bounds : createRect(0, 0, drawing.width, drawing.height);
    clipRect(bounds, 0, 0, drawing.width, drawing.height);

    const texture = getTexture(webgl, bounds.w, bounds.h, 'scaled', false);
    bindAndClearBuffer(webgl, texture, colorToFloatArray(WHITE));

    drawLayer(webgl, webgl.whiteTexture, layer, layer.opacityLocked, layer.opacity, true, false, bounds.x, bounds.y, bounds.w, bounds.h, 0, 0, 1, getShader(webgl, 'aiMask'));

    drawScaled(webgl, texture, scaledWidth, scaledHeight, 0, 0, bounds.w, bounds.h, context);

    releaseTexture(webgl, texture);

    return canvas;
  }

  pickColor(drawing: Drawing, layer: Layer | undefined, px: number, py: number, activeLayer: boolean) {
    const webgl = this.getGL();
    const { gl, emptyTexture } = webgl;

    if (!rectContainsXY(drawing.rect, px, py)) return undefined;

    const x = px;
    const y = py;

    let textureWidth = findTextureWidth(webgl, 1);
    let textureHeight = findTextureHeight(webgl, 1);
    if (IS_IE11) textureWidth = textureHeight = Math.max(textureWidth, textureHeight);

    const temp = getTexture(webgl, textureWidth, textureHeight, 'temp-pick-color', false);
    bindFrameBufferAndTexture(webgl, temp);
    gl.enable(gl.SCISSOR_TEST);
    gl.scissor(0, 0, 1, 1);

    if (!activeLayer && drawing.background) {
      const bg = parseDrawingBackground(drawing.background);
      gl.clearColor(bg[0], bg[1], bg[2], bg[3]);
    } else {
      gl.clearColor(0, 0, 0, 0);
    }

    gl.clear(gl.COLOR_BUFFER_BIT);

    if (!activeLayer) {
      this._drawDrawing(webgl, temp, drawing, createRect(px, py, 1, 1), x, y);
    } else if (layer) {
      // TODO: this doesn't account for clipping groups
      drawLayer(webgl, emptyTexture, layer, layer.opacityLocked, layer.opacity, true, false, x, y);
    }

    bindFrameBufferAndTexture(webgl, temp);
    gl.disable(gl.SCISSOR_TEST);

    const data = tempPixelBuffer;
    gl.readPixels(px - x, py - y, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, data);
    unbindFrameBufferAndTexture(webgl);
    releaseTexture(webgl, temp);

    if (data[3] === 0) return undefined;

    const a = 255 / data[3];
    return colorFromRGBA(data[0] * a, data[1] * a, data[2] * a, data[3]);
  }
  getToolRenderingContext(user: User) {
    if (DEVELOPMENT && user.surface.context) throw new Error('Rendering context not released');

    const webgl = this.getGL();

    ensureSurfaceWithSize(webgl, user, webgl.textureWidth, webgl.textureHeight);

    // TODO: perf: re-use ?
    return user.surface.context = createWebGLRenderingContext(webgl, user.surface);
  }
  // used in worker service
  trimLayer(layer: Layer, rect: Rect) {
    if (!rectIncludesRect(layer.rect, rect)) throw new Error(`Trim layer is going to expand layer! (${rectToString(layer.rect)} -> ${rectToString(rect)})`);
    copyRect(layer.rect, rect);
  }
  // for testing
  fillSelection(layer: Layer) {
    const webgl = this.getGL();
    const { gl, width, height, textureWidth, textureHeight, drawingTransform } = webgl;

    if (!layer.owner) throw new Error('Missing layer owner');

    const temp = getTexture(webgl, textureWidth, textureHeight, 'temp-fill-selection');

    if (!hasZeroTransform(layer.owner.surface)) {
      drawUsingCanvas(webgl, temp, 0, 0, textureWidth, textureHeight, 0, (context, x0, y0) => {
        context.save();
        context.fillStyle = 'cornflowerblue';
        context.translate(-x0, -y0);
        applyTransform(context, layer.owner!.surface.transform);
        fillMask(context, layer.owner!.selection);
        context.restore();
      });
    }

    ensureLayerTexture(webgl, layer, createRect(0, 0, width, height)); // just force full size texture

    gl.enable(gl.BLEND);
    gl.blendEquation(gl.FUNC_ADD);
    gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
    const shader = getShader(webgl, 'basic');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, drawingTransform);
    bindFrameBufferAndTexture(webgl, layer.texture!);
    bindTexture(gl, 0, temp);
    drawQuad(webgl);
    unbindTexture(gl, 0);
    gl.disable(gl.BLEND);
    unbindFrameBufferAndTexture(webgl);
    releaseTexture(webgl, temp);

    layerChanged(layer);
  }
  // for gaussian blur filter
  applyGaussianBlurShader(layer: Layer, drawing: Drawing, radius: number, surface: ToolSurface): void {
    const webgl = this.getGL();
    const { gl, batch, drawingTransform } = webgl;
    const { rect } = layer;

    if (!surface.texture) return;

    // single pass
    setupSurface(surface, ToolId.GaussianBlur, CompositeOp.Draw, layer);
    const vTextureTemp = createEmptyTexture(gl, surface.texture.width, surface.texture.height);
    bindAndClearBuffer(webgl, vTextureTemp, transparentColor);
    bindTexture(gl, 0, surface.texture);
    const shader = getShader(webgl, 'gaussianBlur');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, drawingTransform);
    gl.uniform1f(shader.uniforms.radius, radius);
    gl.uniform2f(shader.uniforms.size, surface.texture.width, surface.texture.height);
    gl.uniform2f(shader.uniforms.borders, drawing.width, drawing.height);
    gl.uniform2f(shader.uniforms.offset, 0, 0);
    pushQuad(batch, 0, 0, surface.texture.width, surface.texture.height, 0, 0, drawing.width / rect.w, drawing.height / rect.h, 0, 0, 1, 1, 1, 1);
    flushBatch(batch);
    unbindTexture(gl, 0);
    unbindFrameBufferAndTexture(webgl);
    releaseTexture(webgl, surface.texture);
    surface.texture = vTextureTemp;
  }
  drawTextLayer(layer: TextLayer, drawing: Drawing) {
    if (!canDrawTextLayer(layer)) return;

    const webgl = this.getGL();
    const { width, height } = webgl;

    const textarea = layer.textarea;
    textarea.write(layer.textData.text);

    const rect = cloneRect(textarea.textureRect);
    clipToDrawingRect(rect, drawing);
    clipRect(rect, 0, 0, width, height);
    if (layer.texture) {
      releaseTexture(webgl, layer.texture);
      layer.texture = undefined;
    }
    ensureLayerTexture(webgl, layer, rect);

    drawUsingCanvas(webgl, layer.texture!, layer.rect.x - layer.textureX, layer.rect.y - layer.textureY, layer.rect.w, layer.rect.h, 0, (context, x0, y0) => {
      context.save();
      context.translate(-x0 - layer.textureX, -y0 - layer.textureY);
      textarea.drawOn(context);
      if (DEVELOPMENT && DRAW_TEXTURE_RECT) drawTextareaTextureRect(context, textarea);
      context.restore();
    });

    layer.invalidateCanvas = false;

    redrawLayerThumb(layer);
  }
  private _drawDrawing(webgl: WebGLResources, target: Texture, drawing: Drawing, rect: Rect, x = 0, y = 0) {
    preprocessTextLayersForDrawing(drawing, (layer) => this.drawTextLayer(layer, drawing));
    if (USE_FAST_DRAWING && canDrawDrawingFast(drawing)) {
      drawDrawingFast(webgl, target, drawing, rect, x, y);
      lastFast = true;
    } else {
      drawDrawingSlow(webgl, target, drawing, rect, x, y);
      lastFast = false;
    }
  }

  // filters
  applyHueSaturationLightnessFilter(_srcData: ImageData | undefined, surface: ToolSurface, values: IFiltersValues) {
    const { hue = 0, saturation = 0, lightness = 0 } = values;
    const webgl = this.getGL();
    const { gl, batch, drawingTransform } = webgl;

    if (!surface.texture) return;

    const vTextureTemp = createEmptyTexture(gl, surface.texture.width, surface.texture.height);
    bindAndClearBuffer(webgl, vTextureTemp, transparentColor);
    bindTexture(gl, 0, surface.texture);
    const shader = getShader(webgl, 'hueSaturationLightnessShader');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, drawingTransform);
    gl.uniform1f(shader.uniforms.hue, hue);
    gl.uniform1f(shader.uniforms.saturation, saturation);
    gl.uniform1f(shader.uniforms.lightness, lightness);
    gl.uniform2f(shader.uniforms.size, surface.texture.width, surface.texture.height);
    pushQuad(batch, 0, 0, surface.texture.width, surface.texture.height, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1);
    flushBatch(batch);
    unbindTexture(gl, 0);
    unbindFrameBufferAndTexture(webgl);
    releaseTexture(webgl, surface.texture);
    surface.texture = vTextureTemp;
  }
  applyBrightnessContrastFilter(_srcData: ImageData | undefined, surface: ToolSurface, values: IFiltersValues) {
    const { brightness, contrast } = values;
    const webgl = this.getGL();
    const { gl, batch, drawingTransform } = webgl;

    if (!surface.texture) return;

    const vTextureTemp = createEmptyTexture(gl, surface.texture.width, surface.texture.height);
    bindAndClearBuffer(webgl, vTextureTemp, transparentColor);
    bindTexture(gl, 0, surface.texture);
    const shader = getShader(webgl, 'brightnessContrastShader');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, drawingTransform);
    gl.uniform1f(shader.uniforms.brightness, brightness!);
    gl.uniform1f(shader.uniforms.contrast, contrast!);
    gl.uniform2f(shader.uniforms.size, surface.texture.width, surface.texture.height);
    pushQuad(batch, 0, 0, surface.texture.width, surface.texture.height, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1);
    flushBatch(batch);
    unbindTexture(gl, 0);
    unbindFrameBufferAndTexture(webgl);
    releaseTexture(webgl, surface.texture);
    surface.texture = vTextureTemp;
  }
  applyCurvesFilter(_srcData: ImageData | undefined, surface: ToolSurface, values: IFiltersValues) {
    const { curvePoints = [] } = values;
    const webgl = this.getGL();
    const { gl, batch, drawingTransform } = webgl;
    const curveValues = getCurveValues(curvePoints);

    if (!surface.texture) return;

    const vTextureTemp = createEmptyTexture(gl, surface.texture!.width, surface.texture!.height);
    const outputTexture = createEmptyTexture(gl, 256, 1, new Uint8Array([
      ...Array.from(curveValues[0]).map((_, index) =>
        [
          curveValues[1][index], // red channel
          curveValues[2][index], // green channel
          curveValues[3][index], // blue channel
          curveValues[0][index]  // RGB
        ]).flat()
    ]));
    bindAndClearBuffer(webgl, vTextureTemp, transparentColor);
    bindTexture(gl, 0, surface.texture!);
    bindTexture(gl, 1, outputTexture);
    const shader = getShader(webgl, 'curvesShader');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, drawingTransform);
    gl.uniform2f(shader.uniforms.size, surface.texture!.width, surface.texture!.height);
    pushQuad(batch, 0, 0, surface.texture!.width, surface.texture!.height, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1);
    flushBatch(batch);
    unbindTexture(gl, 0);
    unbindTexture(gl, 1);
    unbindFrameBufferAndTexture(webgl);
    releaseTexture(webgl, surface.texture!);
    releaseTexture(webgl, outputTexture);
    surface.texture = vTextureTemp;
  }
  applyBlurFilter(srcData: ImageData | undefined, surface: ToolSurface, values: IFiltersValues) {
    if (!srcData) throw new Error(`[applyBlurFilter] Missing srcData`);
    const dstData = this.createImageData(srcData.width, srcData.height, undefined);
    applyGaussianBlur(srcData.data, dstData.data, srcData.width, srcData.height, values.radius || 0);
    this.putImageData({ surface } as any, dstData, 0, 0);
  }
}

function canDrawLayerFast(layer: Layer): ToolSurface | true | false {
  if (layer.mode !== 'normal') return false;
  if (!layer.owner) return true;
  const surface = layer.owner.surface;
  if (surface.layer !== layer) return true;
  if (isSurfaceEmpty(surface) || !surface.texture) return true;
  if (surface.textureMask || surface.canvasMask) return false; // TODO: add shader
  return surface;
}

function canDrawDrawingFast({ layers }: Drawing) {
  for (let i = 0; i < layers.length; i++) {
    if (layerVisibleWithTextureOrTool(layers[i])) {
      if (!canDrawLayerFast(layers[i])) return false;
      if (layers[i].clippingGroup) return false;
    }
  }

  return true;
}

let normalShader: Shader | undefined = undefined;

function setNormalShader(webgl: WebGLResources, name: string) {
  const { gl, drawingTransform } = webgl;
  const shader = getShader(webgl, name);

  if (normalShader !== shader) {
    normalShader = shader;
    gl.useProgram(normalShader.program);
    gl.uniformMatrix4fv(normalShader.uniforms.transform, false, drawingTransform);
    gl.enable(gl.BLEND);
  }

  return normalShader;
}

function clearNormal(gl: WebGLRenderingContext, rect?: Rect) {
  if (normalShader) {
    gl.bindTexture(gl.TEXTURE_2D, null);
    gl.disable(gl.BLEND);
    if (rect) gl.scissor(0, 0, rect.w, rect.h);
    normalShader = undefined;
  }
}

const mat2 = new Float32Array([1, 0, 0, 1]);

function setToolTransform({ gl, textureWidth, textureHeight }: WebGLResources, surface: ToolSurface, shader: Shader) {
  const tw = surface.texture?.width ?? textureWidth;
  const th = surface.texture?.height ?? textureHeight;
  const maxX = surface.rect.x + surface.rect.w;
  const maxY = surface.rect.y + surface.rect.h;
  invertMat2d(tempMat2, surface.transform);
  identityMat2d(tempMat);
  scaleMat2d(tempMat, tempMat, 1 / tw, 1 / th);
  multiplyMat2d(tempMat, tempMat, tempMat2);
  scaleMat2d(tempMat, tempMat, tw, th);
  mat2[0] = tempMat[0];
  mat2[1] = tempMat[2];
  mat2[2] = tempMat[1];
  mat2[3] = tempMat[3];
  gl.uniformMatrix2fv(shader.uniforms.toolTransform, false, mat2);
  gl.uniform2f(shader.uniforms.toolMove, getMat2dX(tempMat), getMat2dY(tempMat));
  gl.uniform2f(shader.uniforms.maxWidthHeight, maxX / tw, maxY / th);
}

function drawQuadWithLayer(webgl: WebGLResources, layer: Layer, dx: number, dy: number) {
  const { batch } = webgl;
  const { x, y, w, h } = layer.rect;
  const tw = layer.texture ? layer.texture.width : 1;
  const th = layer.texture ? layer.texture.height : 1;
  pushQuad(batch, x - dx, y - dy, w, h, (x - layer.textureX) / tw, (y - layer.textureY) / th, w / tw, h / th, 0, 0, 1, 1, 1, 1);
  flushBatch(batch);
}

function drawQuad2WithLayer(webgl: WebGLResources, layer: Layer, x: number, y: number) {
  const { batch, width, height, textureWidth, textureHeight } = webgl;
  const tw = layer.texture ? layer.texture.width : 1;
  const th = layer.texture ? layer.texture.height : 1;
  pushQuad2(batch, x, y, width, height,
    0, 0, width / textureWidth, height / textureHeight, // surface, mask
    -layer.textureX / tw, -layer.textureY / th, width / tw, height / th, // layer
    1, 1, 1, 1);
  flushBatch(batch);
}

function drawLayerFast(webgl: WebGLResources, layer: Layer, rect: Rect, x: number, y: number) {
  const { gl, batch, width, height, textureWidth, textureHeight } = webgl;

  const surface = canDrawLayerFast(layer);
  if (!surface) return false;

  let mask: Texture | undefined = undefined;

  if (surface !== true) {
    const selection = layer.owner!.selection;

    if (!isMaskEmpty(selection) && !surface.ignoreSelection) {
      // TODO: use small texture ?
      //       surface.rect * selection.bounds for brush ?
      //       texture.rect * selection.bounds for eraser ?
      //       ... locked opacity ?
      mask = createMaskTexture(webgl, selection, 0, 0, textureWidth, textureHeight);
    }
  }

  if (surface !== true && surface.texture) {
    const isEraser = surface.mode === CompositeOp.Erase;
    const isBrushWithOpacity = surface.mode === CompositeOp.Draw && layer.texture && (layer.opacity !== 1 || layer.opacityLocked);

    if (isEraser || isBrushWithOpacity) {
      copyRect(tempRect, layer.rect);
      // can't use intersection for eraser here because we still need to redraw rest of the layer
      // in case we're redrawing region larger than just surface
      if (!isEraser) addRect(tempRect, surface.rect);
      intersectRect(tempRect, rect);

      if ((layer.texture || isBrushWithOpacity) && !isRectEmpty(tempRect)) {
        let shaderName: string;

        if (isEraser) {
          shaderName = mask ? 'fastEraserWithMask' : 'fastEraser';
        } else {
          shaderName = layer.opacityLocked ?
            (mask ? 'fastBrushOpacityLockedWithMask' : 'fastBrushOpacityLocked') :
            (mask ? 'fastBrushWithMask' : 'fastBrush');
        }

        const shader = setNormalShader(webgl, shaderName);

        // TODO: test this (get snapshot during drawing)
        gl.scissor(tempRect.x - x, tempRect.y - y, tempRect.w, tempRect.h);
        gl.uniform1f(shader.uniforms.opacity, layer.opacity);
        gl.uniform1f(shader.uniforms.baseOpacity, 1);

        if (isEraser) {
          gl.uniform1f(shader.uniforms.toolOpacity, surface.opacity);
        } else {
          gl.uniform4fv(shader.uniforms.toolColor, colorToFloats(surfaceColor, surface.color, surface.opacity));
        }

        bindTexture(gl, 0, layer.texture!);
        bindTexture(gl, 1, surface.texture);
        mask && bindTexture(gl, 2, mask);
        drawQuad2WithLayer(webgl, layer, -x, -y);
        mask && unbindTexture(gl, 2);
        unbindTexture(gl, 1);
      }

      return true;
    }
  }

  const shader = setNormalShader(webgl, 'fastNormal');
  copyRect(tempRect, rect);
  intersectRect(tempRect, layer.rect);

  if (layer.texture && !isRectEmpty(tempRect)) {
    gl.scissor(tempRect.x - x, tempRect.y - y, tempRect.w, tempRect.h);
    gl.uniform4f(shader.uniforms.color, layer.opacity, layer.opacity, layer.opacity, layer.opacity);
    bindTexture(gl, 0, layer.texture);
    drawQuadWithLayer(webgl, layer, x, y);
  }

  // skip for Eraser
  if (surface !== true && surface.texture && (surface.mode === CompositeOp.Draw || surface.mode === CompositeOp.Move)) {
    const isMoveWithTransform = surface.mode === CompositeOp.Move && !isMat2dIdentity(surface.transform);
    const isDrawWithLockedOpacity = surface.mode === CompositeOp.Draw && layer.opacityLocked; // no layer.texture

    if (!isDrawWithLockedOpacity) { // ignore if locked opacity with empty layer
      copyRect(tempRect, rect);
      intersectRect(tempRect, isMoveWithTransform ? getSurfaceBounds(surface) : surface.rect);

      if (!isRectEmpty(tempRect)) {
        const shader2 = isMoveWithTransform ?
          (setNormalShader(webgl, mask ? 'fastMoveWithMask' : 'fastMove')) :
          (mask ? setNormalShader(webgl, 'fastNormalWithMask') : shader);

        gl.scissor(tempRect.x - x, tempRect.y - y, tempRect.w, tempRect.h);
        gl.uniform4fv(shader2.uniforms.color, colorToFloats(surfaceColor, surface.color, surface.opacity * layer.opacity));
        bindTexture(gl, 0, surface.texture);

        if (isMoveWithTransform) {
          setToolTransform(webgl, surface, shader2);
          ensureMipmaps(webgl, surface);
        }

        mask && bindTexture(gl, 1, mask);

        pushQuad(batch, -x, -y, width, height, 0, 0, width / surface.texture.width, height / surface.texture.height, 0, 0, 1, 1, 1, 1);
        flushBatch(batch);

        mask && unbindTexture(gl, 1);
      }
    }
  }

  return true;
}

function drawDrawingFast(webgl: WebGLResources, target: Texture, drawing: Drawing, rect: Rect, x: number, y: number) {
  const { gl } = webgl;
  const bg = parseDrawingBackground(drawing.background);

  try {
    bindFrameBufferAndTexture(webgl, target);
    gl.enable(gl.SCISSOR_TEST);
    gl.scissor(rect.x - x, rect.y - y, rect.w, rect.h);
    gl.clearColor(bg[0], bg[1], bg[2], bg[3]);
    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.blendEquation(gl.FUNC_ADD);
    gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);

    normalShader = undefined;

    for (let i = drawing.layers.length - 1; i >= 0; i--) {
      if (layerVisibleWithTextureOrTool(drawing.layers[i])) {
        drawLayerFast(webgl, drawing.layers[i], rect, x, y);
      }
    }
  } finally {
    gl.disable(gl.SCISSOR_TEST);
    clearNormal(gl);
    unbindFrameBufferAndTexture(webgl);
  }
}

function layerVisibleWithTextureOrTool(layer: Layer) {
  return isLayerVisible(layer) && ((!!layer.texture || layerHasToolTexture(layer)) || (!!isTextLayer(layer) && layer.textData.text !== ''));
}

function isClippingLayerWithVisibleClippedLayers(index: number, { layers }: Drawing) {
  if (layers[index].clippingGroup) return false; // this is not clipping base

  // check if there is at least one visible clipped layer
  while (index > 0 && layers[index - 1].clippingGroup) {
    if (layerVisibleWithTextureOrTool(layers[index - 1])) return true;
    index--;
  }

  return false;
}

function drawDrawingSlow(webgl: WebGLResources, target: Texture, drawing: Drawing, rect: Rect, ox: number, oy: number) {
  const { gl, width, height, emptyTexture, drawingTransform, batch } = webgl;
  REPORT_SLOW && console.warn('slow (drawing)');

  let tempWidth = findTextureWidth(webgl, rect.w);
  let tempHeight = findTextureHeight(webgl, rect.h);
  if (IS_IE11) tempWidth = tempHeight = Math.max(tempWidth, tempHeight);

  const { x, y, w, h } = rect;

  let tex = getTexture(webgl, tempWidth, tempHeight, 'tex', false);
  let texLast = getTexture(webgl, tempWidth, tempHeight, 'tex-last', false);
  let texClip: Texture | undefined = undefined;

  try {
    // TODO: move clear after scissor ?
    bindAndClearBuffer(webgl, texLast, parseDrawingBackground(drawing.background));
    gl.viewport(0, 0, width, height);
    gl.enable(gl.SCISSOR_TEST);
    gl.scissor(rect.x - x, rect.y - y, rect.w, rect.h);
    gl.blendEquation(gl.FUNC_ADD);
    gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);

    normalShader = undefined;

    for (let i = drawing.layers.length - 1; i >= 0; i--) {
      if (!layerVisibleWithTextureOrTool(drawing.layers[i])) {
        // skip layer clipped onto empty or hidden layer
        if (isLayerVisible(drawing.layers[i])) {
          while (i > 0 && drawing.layers[i - 1].clippingGroup) i--;
        }
        continue;
      }

      if (isClippingLayerWithVisibleClippedLayers(i, drawing)) {
        clearNormal(gl, rect);
        texClip = texClip || getTexture(webgl, tempWidth, tempHeight, 'tex-clip', false);
        [texClip, texLast] = [texLast, texClip];

        const clippingLayer = drawing.layers[i];

        // TODO: fast track (always?)
        // cannot scissor clippingLayer since it can clip stuff outside it's rect
        bindAndClearBuffer(webgl, tex, transparentColor);
        drawLayer(webgl, emptyTexture, clippingLayer, clippingLayer.opacityLocked, 1, true, false, x, y, w, h);
        [tex, texLast] = [texLast, tex];

        for (; i > 0 && drawing.layers[i - 1].clippingGroup; i--) {
          if (!layerVisibleWithTextureOrTool(drawing.layers[i - 1])) continue;

          const layer = drawing.layers[i - 1];

          // TODO: need clipped version for fast layer draw
          // if (!USE_FAST_DRAWING || !drawLayerFast(webgl, layer, rect)) { // TODO: use clipped rect
          REPORT_SLOW && console.warn('slow (clipping)');
          // clearNormal(gl, rect); // TODO: use clipped rect
          bindAndClearBuffer(webgl, tex, transparentColor);
          drawLayer(webgl, texLast, layer, layer.opacityLocked, layer.opacity, false, true, x, y, w, h);
          [tex, texLast] = [texLast, tex];
          // }
        }

        // TODO: fast track for normal mode
        REPORT_SLOW && console.warn('slow (clipping merge)');
        bindFrameBufferAndTexture(webgl, tex);
        const shader = getShaderForMode(webgl, clippingLayer.mode, CompositeOp.None, false);
        gl.useProgram(shader.program);
        gl.uniformMatrix4fv(shader.uniforms.transform, false, drawingTransform);
        gl.uniform1f(shader.uniforms.opacity, clippingLayer.opacity);
        gl.uniform1f(shader.uniforms.baseOpacity, 1);
        gl.uniform1f(shader.uniforms.isClipped, 0);
        bindTexture(gl, 0, texLast);
        bindTexture(gl, 1, texClip);

        // need to use 4 texcoords because in small textures mode shaders expect 4 texcoords
        pushQuad4(
          batch, 0, 0, tempWidth, tempHeight,
          0, 0, 1, 1, // ---
          0, 0, 1, 1, // texLast
          0, 0, 1, 1, // texClip
          0, 0, 1, 1 // ---
        );
        flushBatch(batch);

        unbindTexture(gl, 1);
        unbindTexture(gl, 0);

        [texClip, texLast] = [texLast, texClip];
        [tex, texLast] = [texLast, tex];
      } else {
        const layer = drawing.layers[i];

        // TODO: if last layer and can draw fast, draw directly onto output buffer ?
        if (!USE_FAST_DRAWING || !drawLayerFast(webgl, layer, rect, x, y)) {
          REPORT_SLOW && console.warn('slow');
          clearNormal(gl, rect);
          bindAndClearBuffer(webgl, tex, transparentColor);
          drawLayer(webgl, texLast, layer, layer.opacityLocked, layer.opacity, false, false, x, y, w, h);
          [tex, texLast] = [texLast, tex];
        }
      }
    }
  } finally {
    clearNormal(gl);
    gl.disable(gl.SCISSOR_TEST);
    gl.bindTexture(gl.TEXTURE_2D, target.handle);
    gl.copyTexSubImage2D(gl.TEXTURE_2D, 0, rect.x - ox, rect.y - oy, rect.x - x, rect.y - y, rect.w, rect.h);
    gl.bindTexture(gl.TEXTURE_2D, null);

    unbindFrameBufferAndTexture(webgl);

    releaseTexture(webgl, tex);
    releaseTexture(webgl, texLast);
    releaseTexture(webgl, texClip);
  }
}

function drawViewport(webgl: WebGLResources, drawing: Drawing, user: User, view: Viewport, _rect: Rect, options: DrawOptions) {
  const { gl, width, height, textureWidth, textureHeight, drawingTexture, batch } = webgl;
  const { cursor, settings, lastPoint, showShiftLine, users } = options;
  const bg = parseBackground(settings.background);

  if (gl.isContextLost()) {
    DEVELOPMENT && console.warn('Context is lost');
    return;
  }

  gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  gl.bindTexture(gl.TEXTURE_2D, null);
  gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);
  gl.clearColor(bg[0], bg[1], bg[2], 1.0);
  gl.clear(gl.COLOR_BUFFER_BIT);

  if (!drawing.id) return;

  gl.enable(gl.BLEND);
  gl.blendEquation(gl.FUNC_ADD);
  gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);

  const ratio = getPixelRatio();
  const exactView = view.rotation === 0 && view.scale === 1 && ratio === 1;
  const viewMatrix = createViewportMatrix4(tempMat4, view, exactView);
  const pixelSize = 1 / view.scale;
  const tw = width / textureWidth;
  const th = height / textureHeight;

  // draw drawing
  const smooth = smoothScalingWebgl(settings, view);
  const shader = getShader(webgl, options.viewFilter === 'grayscale' ? 'drawingGrayscale' : 'drawing');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
  gl.uniform2f(shader.uniforms.size, textureWidth, textureHeight);
  // gl.uniform2f(shader.uniforms.minCoord, pixelSize / textureWidth, pixelSize / textureHeight);
  gl.uniform2f(shader.uniforms.maxCoord, tw - pixelSize / textureWidth, th - pixelSize / textureHeight);
  gl.uniform1f(shader.uniforms.scale, view.scale);
  gl.uniform1f(shader.uniforms.pixelated, smooth ? 0 : 1);

  bindTexture(gl, 0, drawingTexture);

  if (!smooth || !exactView) {
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
  }

  if (view.rotation) {
    pushQuad(
      batch,
      -pixelSize,
      -pixelSize,
      width + 2 * pixelSize,
      height + 2 * pixelSize,
      -pixelSize / width,
      -pixelSize / height,
      tw + (2 * pixelSize) / width,
      th + (2 * pixelSize) / height,
      0, 0,
      1, 1, 1, 1 // white
    );
  } else {
    pushQuad(batch, 0, 0, drawing.width, drawing.height, 0, 0, tw, th, 0, 0, 1, 1, 1, 1);
  }

  flushBatch(batch);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
  unbindTexture(gl, 0);

  // draw grid
  if (settings.pixelGrid && view.scale > 6) {
    const shader = getShader(webgl, 'grid');
    const alpha = Math.min(0.1 + 0.01 * (view.scale - 6), 0.2);
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
    gl.uniform1f(shader.uniforms.pixelSize, pixelSize);
    gl.uniform2f(shader.uniforms.size, textureWidth, textureHeight);
    gl.uniform1f(shader.uniforms.alpha, alpha);
    drawQuad(webgl);
  }

  drawActiveTool(webgl, user, view);

  if (user?.selection && options.selectedTool?.id !== ToolId.AI) drawSelection(webgl, user, user.selection, view);
  if (user?.showTransform) drawTransform(webgl, user, drawing, view);
  if (user && shouldDrawTextarea(user.activeLayer, options)) drawTextarea(webgl, user.activeLayer.textarea, view, options);
  if (options.selectedTool?.id === ToolId.AI && !!user) {
    const tool = options.selectedTool as AiTool;
    if (tool.showSelection) {
      if (tool.results.size === 0) {
        fillSelection(webgl, tool.getSelection(), view);
      }
    }
    if (tool.pipeline === 'outpaint' && user.activeLayer && tool.results.size === 0) {
      drawAiOutpaintingMask(webgl, tool, user.activeLayer, view);
    }
    drawAiBoundingBox(webgl, view, drawing, options);
  }

  if (showShiftLine) {
    copyPoint(tempPt, lastPoint);
    documentToScreenPoint(tempPt, view);
    const shader = getShader(webgl, 'line');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight));
    const x1 = tempPt.x * ratio;
    const y1 = tempPt.y * ratio;
    const x2 = cursor.x * ratio;
    const y2 = cursor.y * ratio;
    const c = 0.3;
    pushAntialiasedLine(batch, x1, y1, x2, y2, 1.5, c, c, c, c);
    pushAntialiasedLine(batch, x1, y1, x2, y2, 1, 0.5 * c, 0.5 * c, 0.5 * c, c);
    flushBatch(batch);
  }

  if (SHOW_CURSORS && users.length && settings.cursors !== CursorsMode.None) {
    drawCursors(webgl, view, drawing, users, settings.cursors ?? 0, !!options.settings.includeVideo);
  }

  if (!isTextLayer(user?.activeLayer) || (options.selectedTool && !toolIncompatibleWithTextLayers(options.selectedTool.id))) {
    drawCursor(webgl, cursor, view, !!settings.showCursor);
  }

  if (options.settings.includeVideo && options.drawingInProgress !== true) {
    const alpha = quadraticEasing(noSelfVideoTime, performance.now(), 1000);
    drawSelfVideo(webgl, cursor, user, alpha);
  } else {
    noSelfVideoTime = performance.now();
  }

  if (DEVELOPMENT && DRAW_LAYER_RECTS) drawDebugLayerBounds(webgl, drawing, view, user);
  if (DEVELOPMENT && DRAW_ALLOCATED_TEXTURES) drawDebugAllocatedTextures(webgl, view);
  if (DEVELOPMENT) drawDebugMarkers(webgl, view);

  if (DEVELOPMENT && meshes) {
    gl.clear(gl.DEPTH_BUFFER_BIT);
    gl.enable(gl.DEPTH_TEST);
    // TODO: need antialiasing here ? or postprocess ?
    const shader = getShader(webgl, 'mesh');
    gl.useProgram(shader.program);

    const dist = 3;
    const viewMat = lookAtMat4(createMat4(), dist, 2, -dist, 0, 0, 0, 0, 1, 0);
    const projMat = perspectiveMat4(createMat4(), Math.PI * 0.5, view.width / view.height, 0.1, 1000);
    multiplyMat4(viewMat, projMat, viewMat);
    const modelMat = fromYRotationMat4(createMat4(), meshesRotation);

    // TODO: depth testing
    gl.uniformMatrix4fv(shader.uniforms.model, false, modelMat);
    gl.uniformMatrix4fv(shader.uniforms.viewProj, false, viewMat);

    for (const mesh of meshes) {
      drawMesh(gl, mesh);
    }

    meshesRotation += 0.01;
    gl.disable(gl.DEPTH_TEST);
  }

  gl.disable(gl.BLEND);
}

interface TextureCache {
  id: number;
  slot: number;
  w: number;
  h: number;
  tx: number;
  ty: number;
  tw: number;
  th: number;
}

interface NamePlate extends TextureCache {
  name: string;
  color: string;
  avatarImage: HTMLImageElement | undefined;
  avatarVideoDimensions?: {
    width: number;
    height: number;
  }
}

interface VideoPlate extends TextureCache {
  lastDrawnFrame: number;
}

let namePlates: NamePlate[] = [];
let videoPlates: VideoPlate[] = [];
let namePlatesRatio = 1;
let videoPlatesRatio = 1;
let selfVideoRatio = 1;
let namePlatesCanvas: HTMLCanvasElement | undefined = undefined;
let videoPlatesCanvas: HTMLCanvasElement | undefined = undefined;
let selfVideoCanvas: HTMLCanvasElement | undefined = undefined;
let selfVideoPlate: VideoPlate | undefined;

function isSlotTaken(slot: number) {
  for (const n of namePlates) {
    if (n.slot === slot) {
      return true;
    }
  }
  return false;
}

function isVideoSlotTaken(slot: number) {
  for (const n of videoPlates) {
    if (n.slot === slot) {
      return true;
    }
  }
  return false;
}

function createSelfVideoTexture(webgl: WebGLResources, ratio: number, user: User) {
  const { gl } = webgl;
  const textureSize = findPowerOf2(2 * CURSOR_VIDEO_HEIGHT * ratio);
  let updateTexture = false;

  if (selfVideoRatio !== ratio) {
    deleteTexture(gl, webgl.selfVideoTexture);
    webgl.selfVideoTexture = undefined;
    selfVideoRatio = ratio;
  }

  if (!selfVideoCanvas) {
    selfVideoCanvas = createCanvas(textureSize, textureSize);
  }
  if (!webgl.selfVideoTexture) {
    webgl.selfVideoTexture = createEmptyTexture(gl, textureSize, textureSize);
    updateTexture = true;
  }
  if (!user.avatarVideo) {
    return webgl.selfVideoTexture;
  }
  const { avatarVideo } = user;
  const lastDrawnFrame = getPresentedVideoFrames(avatarVideo);
  if (selfVideoPlate?.lastDrawnFrame === lastDrawnFrame) {
    return webgl.selfVideoTexture;
  }
  updateTexture = true;

  const h = CURSOR_VIDEO_HEIGHT * ratio;
  const srcWidth = avatarVideo.videoWidth;
  const srcHeight = avatarVideo.videoHeight;
  let w = Math.floor(h * (srcWidth / srcHeight));

  const context = getContext2d(selfVideoCanvas);

  context.drawImage(avatarVideo, 0, 0, srcWidth, srcHeight, 0, 0, w, h);

  selfVideoPlate = { id: 0, slot: 0, w, h, tx: (1 / textureSize), ty: (1 / textureSize), tw: w / textureSize, th: h / textureSize, lastDrawnFrame };

  if (updateTexture) {
    gl.bindTexture(gl.TEXTURE_2D, webgl.selfVideoTexture.handle);
    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, 1);
    gl.texSubImage2D(gl.TEXTURE_2D, 0, 0, 0, gl.RGBA, gl.UNSIGNED_BYTE, selfVideoCanvas);
    gl.bindTexture(gl.TEXTURE_2D, null);
  }

  return webgl.selfVideoTexture;
}

function createVideoPlatesTexture(webgl: WebGLResources, ratio: number, users: User[]) {
  const { gl } = webgl;
  const textureSize = findPowerOf2(1024 * ratio);
  const rows = 12;
  const columns = 12;
  const colSize = Math.floor(textureSize / columns);
  const rowSize = Math.floor(textureSize / rows);
  let context: CanvasRenderingContext2D | undefined = undefined;
  let slot = 0;
  let updateTexture = false;

  if (videoPlatesRatio !== ratio) {
    deleteTexture(gl, webgl.videoPlatesTexture);
    webgl.videoPlatesTexture = undefined;
    videoPlatesCanvas = undefined;
    videoPlates = [];
    videoPlatesRatio = ratio;
  }

  if (!videoPlatesCanvas) {
    videoPlatesCanvas = createCanvas(textureSize, textureSize);
  }

  if (!webgl.videoPlatesTexture) {
    webgl.videoPlatesTexture = createEmptyTexture(gl, textureSize, textureSize);
    updateTexture = true;
  }

  for (const { avatarVideo, localId } of users) {
    const index = findIndexById(videoPlates, localId);

    if (!avatarVideo) {
      continue;
    }

    const lastDrawnFrame = getPresentedVideoFrames(avatarVideo);
    if (index !== -1 && videoPlates[index].lastDrawnFrame === lastDrawnFrame) {
      continue;
    }
    if (!context) {
      context = getContext2d(videoPlatesCanvas);
      context.font = `bold ${14 * ratio}px ${DEFAULT_FONT}`;
    }

    while (isVideoSlotTaken(slot)) slot++;

    if (slot >= columns * rows) {
      for (let i = videoPlates.length - 1; i >= 0; i--) {
        if (!findByLocalId(users, videoPlates[i].id)) {
          removeAtFast(videoPlates, i);
        }
      }

      slot = 0;
      while (isVideoSlotTaken(slot)) slot++;
    }

    const slotX = Math.floor(slot / rows);
    const slotY = slot % rows;

    const h = CURSOR_VIDEO_HEIGHT * ratio;
    const srcWidth = avatarVideo.videoWidth;
    const srcHeight = avatarVideo.videoHeight;
    let w = Math.floor(h * (srcWidth / srcHeight));

    context.drawImage(avatarVideo, 0, 0, srcWidth, srcHeight, slotX * colSize, slotY * rowSize, w, h);

    const tx = slotX * (1 / columns) + (1 / textureSize);
    const ty = slotY * (1 / rows) + (1 / textureSize);
    const tw = w / textureSize;
    const th = h / textureSize;

    const videoPlate: VideoPlate = { id: localId, slot, w, h, tx, ty, tw, th, lastDrawnFrame };

    if (index === -1) {
      videoPlates.push(videoPlate);
    } else {
      videoPlates[index] = videoPlate;
    }

    updateTexture = true;
  }

  if (updateTexture) {
    gl.bindTexture(gl.TEXTURE_2D, webgl.videoPlatesTexture.handle);
    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, 1);
    gl.texSubImage2D(gl.TEXTURE_2D, 0, 0, 0, gl.RGBA, gl.UNSIGNED_BYTE, videoPlatesCanvas);
    gl.bindTexture(gl.TEXTURE_2D, null);
  }

  return webgl.videoPlatesTexture;
}

function createNamePlatesTexture(webgl: WebGLResources, ratio: number, users: User[], cursors: CursorsMode, includeVideo: boolean) {
  const drawAvatarOption = cursors === CursorsMode.PointerAvatarName || cursors === CursorsMode.PointerAvatar;
  const drawNameOption = cursors === CursorsMode.PointerAvatarName || cursors === CursorsMode.PointerName;
  const { gl } = webgl;
  const textureSize = findPowerOf2(512 * ratio);
  const columns = drawAvatarOption && !drawNameOption ? 12 : 3;
  const rows = drawAvatarOption && !drawNameOption ? 12 : 16;
  const colSize = Math.floor(textureSize / columns);
  const rowSize = Math.floor(textureSize / rows);
  let updateTexture = false;
  let context: CanvasRenderingContext2D | undefined = undefined;
  let slot = 0;

  if (namePlatesRatio !== ratio || webgl.namePlatesMode !== cursors) {
    deleteTexture(gl, webgl.namePlatesTexture);
    webgl.namePlatesTexture = undefined;
    webgl.namePlatesMode = cursors;
    namePlatesCanvas = undefined;
    namePlates = [];
    namePlatesRatio = ratio;
  }

  if (!namePlatesCanvas) {
    namePlatesCanvas = createCanvas(textureSize, textureSize);
  }

  if (!webgl.namePlatesTexture) {
    webgl.namePlatesTexture = createEmptyTexture(gl, textureSize, textureSize);
    updateTexture = true;
  }

  for (const { name, color, colorFloat, localId, avatarImage, avatarVideo } of users) {
    const index = findIndexById(namePlates, localId);
    // already up to date
    if (index !== -1 &&
      namePlates[index].name === name &&
      namePlates[index].color === color &&
      namePlates[index].avatarImage === avatarImage &&
      namePlates[index].avatarVideoDimensions?.width === (includeVideo ? avatarVideo?.videoWidth : undefined) &&
      namePlates[index].avatarVideoDimensions?.height === (includeVideo ? avatarVideo?.videoHeight : undefined)
    ) {
      continue;
    }

    let drawAvatar = drawAvatarOption;
    let drawName = drawNameOption;
    let drawNameplate = true;
    if (includeVideo && avatarVideo) {
      drawAvatar = false;
      drawNameplate = false;
    }
    if (!context) {
      context = getContext2d(namePlatesCanvas);
      context.font = `bold ${14 * ratio}px ${DEFAULT_FONT}`;
    }

    while (isSlotTaken(slot)) slot++;

    if (slot >= columns * rows) {
      for (let i = namePlates.length - 1; i >= 0; i--) {
        if (!findByLocalId(users, namePlates[i].id)) {
          removeAtFast(namePlates, i);
        }
      }

      slot = 0;
      while (isSlotTaken(slot)) slot++;
    }

    const slotX = Math.floor(slot / rows);
    const slotY = slot % rows;
    const padX = 10 * ratio;
    const maxLength = (includeVideo && avatarVideo) ? Math.floor(CURSOR_VIDEO_HEIGHT * ratio * avatarVideo.videoWidth / avatarVideo.videoHeight) - 10 * ratio : 100 * ratio;
    const brightness = drawNameplate ? rgbToGray(colorFloat[0], colorFloat[1], colorFloat[2]) : 0;
    const trimmed = truncateName(context, name, maxLength);
    let w = 0;
    const h = Math.round((drawAvatar && !drawName ? CURSOR_AVATAR_LARGE_HEIGHT : USER_NAME_HEIGHT) * ratio);

    if (drawNameplate) {
      context.fillStyle = color;
      context.fillRect(slotX * colSize, slotY * rowSize, colSize, rowSize);
    } else {
      context.clearRect(slotX * colSize, slotY * rowSize, colSize, rowSize);
    }

    if (drawAvatar && avatarImage) {
      const destImgWidth = h + 1;
      context.drawImage(avatarImage, 0, 0, avatarImage.width, avatarImage.width, slotX * colSize, slotY * rowSize, destImgWidth, destImgWidth);
      w += destImgWidth;
    }

    if (drawName) {
      context.fillStyle = brightness > 0.71 ? '#222' : 'white';
      context.fillText(trimmed, slotX * colSize + padX + w, slotY * rowSize + 20 * ratio);
      w += Math.ceil(textWidth(context, trimmed) + padX * 2);
    }

    w -= 2;

    const tx = slotX * (1 / columns) + (1 / textureSize);
    const ty = slotY * (1 / rows) + (1 / textureSize);
    const tw = w / textureSize;
    const th = h / textureSize;

    const namePlate: NamePlate = {
      id: localId, name, color, avatarImage, slot, w, h, tx, ty, tw, th,
      avatarVideoDimensions: (includeVideo && avatarVideo) ? {
        width: avatarVideo.videoWidth,
        height: avatarVideo.videoHeight,
      } : undefined
    };
    const index2 = findIndexById(namePlates, localId); // `index` might have changed since last check

    if (index2 === -1) {
      namePlates.push(namePlate);
    } else {
      namePlates[index2] = namePlate;
    }

    updateTexture = true;
  }

  if (updateTexture) {
    gl.bindTexture(gl.TEXTURE_2D, webgl.namePlatesTexture.handle);
    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, 1);
    gl.texSubImage2D(gl.TEXTURE_2D, 0, 0, 0, gl.RGBA, gl.UNSIGNED_BYTE, namePlatesCanvas);
    gl.bindTexture(gl.TEXTURE_2D, null);
  }

  return webgl.namePlatesTexture;
}

function drawCursors(webgl: WebGLResources, view: Viewport, drawing: Drawing, users: User[], cursors: CursorsMode, includeVideo: boolean) {
  const { gl, batch } = webgl;
  const role = drawing.permissions.cursors ?? defaultDrawingPermissions.cursors;
  const ratio = getPixelRatio();
  const lastUpdateThreshold = performance.now() - SHOW_CURSOR_UNMOVING_TIMEOUT;

  { // circles
    const radius = USER_CURSOR_RADIUS * ratio;
    const radiusWithBorder = Math.ceil(radius + 2);
    const size = radiusWithBorder * 2;
    const shader = getShader(webgl, 'circleOutline');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight));
    gl.uniform1f(shader.uniforms.lineWidth, 1.5 * ratio);

    for (const u of users) {
      if (!hasDrawingRole(u, role) || u.cursorLastUpdate < lastUpdateThreshold) {
        u.cursorX = 1e9;
        u.cursorY = 1e9;
        continue;
      }

      setPoint(tempPt, u.cursorX, u.cursorY);
      documentToScreenPoint(tempPt, view);
      const cx = tempPt.x * ratio;
      const cy = tempPt.y * ratio;
      const x = Math.round(cx - radiusWithBorder);
      const y = Math.round(cy - radiusWithBorder);
      const c = u.colorFloat;
      const a = Math.max(0.5, u.cursorAlpha);
      pushQuad(batch, x, y, size, size, 0, 0, 1, 1, radiusWithBorder, radiusWithBorder / radius, c[0] * a, c[1] * a, c[2] * a, a);
    }

    flushBatch(batch);
  }

  if (cursors === CursorsMode.PointerName || cursors === CursorsMode.PointerAvatarName || cursors === CursorsMode.PointerAvatar) { // name plates
    const shader = getShader(webgl, 'sprite');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight));

    if (includeVideo) {
      bindTexture(gl, 0, createVideoPlatesTexture(webgl, ratio, users));
      for (const u of users) {
        if (!hasDrawingRole(u, role) || u.cursorLastUpdate < lastUpdateThreshold) continue;
        {
          const c = findById(videoPlates, u.localId);

          if (c) {
            setPoint(tempPt, u.cursorX, u.cursorY);
            documentToScreenPoint(tempPt, view);
            const x = Math.round((tempPt.x + USER_NAME_OFFSET) * ratio);
            const y = Math.round((tempPt.y + USER_NAME_OFFSET) * ratio);
            const a = u.cursorAlpha;
            pushQuad(batch, x, y, c.w, c.h, c.tx, c.ty, c.tw, c.th, 1, 0, a, a, a, a);
          }
        }
      }

      flushBatch(batch);
      unbindTexture(gl, 0);
    }

    bindTexture(gl, 0, createNamePlatesTexture(webgl, ratio, users, cursors, includeVideo));

    for (const u of users) {
      if (!hasDrawingRole(u, role) || u.cursorLastUpdate < lastUpdateThreshold) continue;

      const c = findById(namePlates, u.localId);
      if (c) {
        setPoint(tempPt, u.cursorX, u.cursorY);
        documentToScreenPoint(tempPt, view);
        const x = Math.round((tempPt.x + USER_NAME_OFFSET) * ratio);

        if (includeVideo) {
          const cv = findById(videoPlates, u.localId);
          if (cv) tempPt.y += cv.h - c.h;
        }

        const y = Math.round((tempPt.y + USER_NAME_OFFSET) * ratio);
        const a = u.cursorAlpha;
        pushQuad(batch, x, y, c.w, c.h, c.tx, c.ty, c.tw, c.th, 1, 0, a, a, a, a);
      }
    }

    flushBatch(batch);
    unbindTexture(gl, 0);
  }

  for (const u of users) {
    const color = u.color;
    if (isTextLayer(u.activeLayer) && u.activeLayer.textarea?.isFocused) {
      if (shouldCacheTextareaInLayer(u.activeLayer)) cacheTextareaInLayer(u.activeLayer);
      drawTextareaBoundaries(webgl, view, u.activeLayer.textarea, 2, color);
    }
  }
}

function drawLayerThumb(webgl: WebGLResources, layer: Layer, drawingRect: Rect) {
  const { gl, webgl2, batch, emptyTexture, pendingLayerThumb, thumbnailTexture, thumbnailTransform } = webgl;

  if (pendingLayerThumb && webgl2) {
    const gl2 = gl as WebGL2RenderingContext;
    const status = gl2.getSyncParameter(pendingLayerThumb.sync, gl2.SYNC_STATUS);
    if (status !== gl2.SIGNALED) return true;

    const context = pendingLayerThumb.layer!.thumb?.getContext('2d');

    if (context) {
      const data = getImageDataForThumb(context);
      gl.bindBuffer(gl2.PIXEL_PACK_BUFFER, pendingLayerThumb.buffer);
      gl2.getBufferSubData(gl2.PIXEL_PACK_BUFFER, 0, toUint8(data.data));
      gl.bindBuffer(gl2.PIXEL_PACK_BUFFER, null);
      context.putImageData(data, 0, 0);
    }

    deleteBuffer(gl, pendingLayerThumb.buffer);
    webgl.pendingLayerThumb = undefined;
  }

  if (!layer.thumb || !shouldRedrawLayerThumb(layer)) return false;

  const pixelRatio = getPixelRatio();
  const size = Math.floor(LAYER_THUMB_SIZE * pixelRatio);

  if (layer.thumb.width !== size || layer.thumb.height !== size) {
    layer.thumb.width = size;
    layer.thumb.height = size;
  }

  const layerRect = getLayerRect(layer);
  clipRect(layerRect, 0, 0, drawingRect.w, drawingRect.h);
  const rect = !isRectEmpty(layerRect) ? layerRect : drawingRect;
  const sx = Math.max(0, rect.x);
  const sy = Math.max(0, rect.y);
  const sw = Math.min(drawingRect.w - sx, rect.w - (sx - rect.x));
  const sh = Math.min(drawingRect.h - sy, rect.h - (sy - rect.y));

  const aspectRatio = sw / sh;
  const dw = aspectRatio > 1 ? size : Math.round(size * aspectRatio);
  const dh = aspectRatio > 1 ? Math.round(size / aspectRatio) : size;
  const dx = Math.round((size - dw) / 2);
  const dy = Math.round((size - dh) / 2);

  const x = layerRect.x;
  const y = layerRect.y;

  let textureWidth = 0, textureHeight = 0;
  let temp: Texture | undefined = undefined;

  if (!isRectEmpty(layerRect)) {
    textureWidth = findTextureWidth(webgl, layerRect.w);
    textureHeight = findTextureHeight(webgl, layerRect.h);
    if (IS_IE11) textureWidth = textureHeight = Math.max(textureWidth, textureHeight);

    // TODO: skip completely if layerRect is empty (use empty texture ?)

    temp = getTexture(webgl, textureWidth, textureHeight, 'temp-thumb', false);

    bindAndClearBuffer(webgl, temp, transparentColor);
    drawLayer(webgl, emptyTexture, layer, layer.opacityLocked, layer.opacity, true, false, x, y, layerRect.w, layerRect.h);
    unbindFrameBufferAndTexture(webgl);

    gl.bindTexture(gl.TEXTURE_2D, temp.handle);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
    gl.generateMipmap(gl.TEXTURE_2D);
    gl.bindTexture(gl.TEXTURE_2D, null);

    if (thumbnailTexture.width !== size) {
      const matrix = thumbnailTransform;
      identityMat4(matrix);
      translateMat4(matrix, matrix, -1, -1, 0);
      scaleMat4(matrix, matrix, 2 / size, 2 / size, 1);
      resizeTexture(gl, thumbnailTexture, size, size); // TODO: this should be proper, 2^n texture size
    }
  }

  bindFrameBufferAndTexture(webgl, thumbnailTexture);
  gl.viewport(0, 0, size, size);
  gl.clearColor(0.67, 0.67, 0.67, 1);
  gl.clear(gl.COLOR_BUFFER_BIT);
  gl.enable(gl.BLEND);
  gl.blendEquation(gl.FUNC_ADD);
  gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);

  if (sw > 0 && sh > 0) {
    gl.enable(gl.SCISSOR_TEST);
    gl.scissor(dx, dy, dw, dh);

    {
      const shader = getShader(webgl, 'checker');
      gl.useProgram(shader.program);
      gl.uniformMatrix4fv(shader.uniforms.transform, false, thumbnailTransform);
      gl.uniform2f(shader.uniforms.size, size, size);
      gl.uniform1f(shader.uniforms.scale, 1);
      gl.uniform1f(shader.uniforms.checkerSize, 6 * pixelRatio);
      pushQuad(batch, 0, 0, size, size, 0, 0, 1, 1, 0, 0, 1, 1, 1, 1);
      flushBatch(batch);
    }

    gl.disable(gl.SCISSOR_TEST);

    if (temp) {
      const shader = getShader(webgl, 'basic');
      gl.useProgram(shader.program);
      gl.uniformMatrix4fv(shader.uniforms.transform, false, thumbnailTransform);
      bindTexture(gl, 0, temp);
      const tw = textureWidth;
      const th = textureHeight;
      pushQuad(batch, dx, dy, dw, dh, (sx - x) / tw, (sy - y) / th, sw / tw, sh / th, 0, 0, 1, 1, 1, 1);
      flushBatch(batch);
      unbindTexture(gl, 0);
    }
  }

  gl.disable(gl.BLEND);

  if (temp) {
    gl.bindTexture(gl.TEXTURE_2D, temp.handle);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.bindTexture(gl.TEXTURE_2D, null);
  }

  unbindFrameBufferAndTexture(webgl);
  releaseTexture(webgl, temp);

  const context = layer.thumb.getContext('2d');

  if (context) {
    if (webgl2) {
      const gl2 = gl as WebGL2RenderingContext;
      const buffer = allocBuffer(gl);
      gl2.bindBuffer(gl2.PIXEL_PACK_BUFFER, buffer);
      gl2.bufferData(gl2.PIXEL_PACK_BUFFER, size * size * 4, gl2.STATIC_READ);
      bindFrameBufferAndTexture(webgl, thumbnailTexture);
      gl2.readPixels(0, 0, size, size, gl.RGBA, gl.UNSIGNED_BYTE, 0);
      unbindFrameBufferAndTexture(webgl);
      gl2.bindBuffer(gl2.PIXEL_PACK_BUFFER, null);
      const sync = gl2.fenceSync(gl2.SYNC_GPU_COMMANDS_COMPLETE, 0);
      if (sync) webgl.pendingLayerThumb = { sync, layer, buffer };
    } else {
      const data = getImageDataForThumb(context);
      bindFrameBufferAndTexture(webgl, thumbnailTexture);
      gl.readPixels(0, 0, size, size, gl.RGBA, gl.UNSIGNED_BYTE, toUint8(data.data));
      unbindFrameBufferAndTexture(webgl);
      context.putImageData(data, 0, 0);
    }

    layer.thumbDirty = 0;
    return true;
  } else {
    return false;
  }
}

// needed for IE and Safari
function toUint8(data: Uint8ClampedArray) {
  return new Uint8Array(data.buffer, data.byteOffset, data.byteLength);
}

let imageData: ImageData | undefined = undefined;

function getImageDataForThumb(context: CanvasRenderingContext2D) {
  if (!imageData || imageData.width !== context.canvas.width || imageData.height !== context.canvas.height) {
    imageData = context.createImageData(context.canvas.width, context.canvas.height);
  }

  return imageData;
}

function layerHasTool(layer: Layer) {
  return layer.owner
    && layer.owner.surface.layer === layer
    && !isSurfaceEmpty(layer.owner.surface)
    && !!layer.owner.surface.texture;
}

function ensureMipmaps({ gl }: WebGLResources, surface: ToolSurface) {
  if (!surface.texture) return;

  const linear = !isMat2dIntegerTranslation(surface.transform);

  if (linear !== surface.textureIsLinear) {
    surface.textureIsLinear = linear;
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, linear ? gl.LINEAR : gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, linear ? gl.LINEAR_MIPMAP_LINEAR : gl.NEAREST);

    if (DEVELOPMENT && false) {
      // TODO: move to webgl init
      const ext = gl.getExtension('EXT_texture_filter_anisotropic') ||
        gl.getExtension('MOZ_EXT_texture_filter_anisotropic') ||
        gl.getExtension('WEBKIT_EXT_texture_filter_anisotropic');

      if (ext) {
        const max = gl.getParameter(ext.MAX_TEXTURE_MAX_ANISOTROPY_EXT);
        gl.texParameterf(gl.TEXTURE_2D, ext.TEXTURE_MAX_ANISOTROPY_EXT, max);
      }
    }
  }

  if (linear && !surface.textureHasMipmaps) {
    surface.textureHasMipmaps = true;
    gl.generateMipmap(gl.TEXTURE_2D);
  }
}

const surfaceColor = new Float32Array(4);

function drawLayer(
  webgl: WebGLResources, baseTexture: Texture, layer: Layer, lockOpacity: boolean, layerOpacity: number,
  skipMode = false, clip = false, x = 0, y = 0, w?: number, h?: number, // TODO: make this better ?
  baseX = 0, baseY = 0, baseOpacity = 1, forcedShader?: Shader
) {
  const { gl, drawingTransform, emptyTexture, whiteTexture, batch, width, height, textureWidth, textureHeight } = webgl;
  const surface = layerHasTool(layer) ? layer.owner!.surface : undefined;
  let mask: Texture | undefined = undefined;
  // let mask2: Texture | undefined = undefined;

  if (w === undefined) w = width;
  if (h === undefined) h = height;

  const surfaceW = surface?.texture?.width ?? textureWidth;
  const surfaceH = surface?.texture?.height ?? textureHeight;

  if (surface) {
    const selection = layer.owner!.selection;

    if (!isMaskEmpty(selection) && !surface.ignoreSelection) {
      // TODO: use small texture intersection(surface.rect, selection.bounds) ?
      mask = createMaskTexture(webgl, selection, 0, 0, surfaceW, surfaceH);
    }

    // if (surface.canvasMask) {
    //   mask2 = getTexture(webgl, 'mask2', false);
    //   copyCanvasToTexture(gl, getContext2d(surface.canvasMask), mask2, textureWidth, textureHeight);
    // }
  }

  const op = surface?.mode ?? CompositeOp.None;
  const layerMode = skipMode ? 'normal' : layer.mode;
  const hasMasks = !!(surface && (mask /*|| mask2 || surface.textureMask*/));
  const shader = forcedShader ? forcedShader : getShaderForMode(webgl, layerMode, op, hasMasks);

  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, drawingTransform);
  gl.uniform1f(shader.uniforms.opacity, layerOpacity);
  gl.uniform1f(shader.uniforms.baseOpacity, baseOpacity);
  gl.uniform1f(shader.uniforms.isClipped, clip ? 1 : 0);

  if (surface) {
    if (surface.mode === CompositeOp.Move) lockOpacity = false;

    setToolTransform(webgl, surface, shader);

    gl.uniform4fv(shader.uniforms.toolColor, colorToFloats(surfaceColor, surface.color));
    gl.uniform1f(shader.uniforms.toolOpacity, surface.opacity); // TODO: is this needed ? just multiply the color ?
    gl.uniform1f(shader.uniforms.lockOpacity, (lockOpacity && op !== CompositeOp.Erase) ? 1 : 0);
    gl.uniform1f(shader.uniforms.srcMul, op === CompositeOp.Erase ? 0 : 1);
  }

  const layerTexture = layer.texture || emptyTexture;

  bindTexture(gl, 0, layerTexture);
  bindTexture(gl, 1, baseTexture);

  if (surface) {
    bindTexture(gl, 2, surface.texture || emptyTexture);
    ensureMipmaps(webgl, surface);

    if (hasMasks) {
      bindTexture(gl, 3, mask || whiteTexture);
      // bindTexture(gl, 4, mask2 || surface.textureMask || whiteTexture);
    }
  }

  const tw = layerTexture.width;
  const th = layerTexture.height;
  pushQuad4(
    batch, 0, 0, w, h,
    x / surfaceW, y / surfaceH, w / surfaceW, h / surfaceH, // surface (and mask for now)
    (x - layer.textureX) / tw, (y - layer.textureY) / th, w / tw, h / th, // layer
    baseX / baseTexture.width, baseY / baseTexture.height, w / baseTexture.width, h / baseTexture.height, // base
    // TODO: mask might be transformed differently relative to surface
    //       need to add separate transform for mask
    //       also update tool.glsl
    //       this might not be needed if we always keep mask texture the same size as surface
    x / surfaceW, y / surfaceH, w / surfaceW, h / surfaceH, // TODO: mask
  );

  flushBatch(batch);

  // if (hasMasks) unbindTexture(gl, 4);
  if (hasMasks) unbindTexture(gl, 3);
  if (surface) unbindTexture(gl, 2);
  unbindTexture(gl, 1);
  unbindTexture(gl, 0);

  // releaseTexture(webgl, mask2);
}

function drawCrosshair(webgl: WebGLResources, cx: number, cy: number, viewMatrix: Mat4, pixelRatio: number) {
  const { gl, batch } = webgl;

  const GAP = 5 * pixelRatio;
  const SIZE = 3 * pixelRatio;
  const THICKNESS = 1 * pixelRatio;
  const th = THICKNESS * 0.5;
  const c = cursorColor;
  const r = c[0], g = c[1], b = c[2], a = c[3];
  const x = Math.floor(cx);
  const y = Math.floor(cy);

  const x1 = Math.floor(cx - th);
  const x2 = x1 + 1;
  const ox1 = x2 - (cx - th);
  const ox2 = (cx + th) - x2;

  const y1 = Math.floor(cy - th);
  const y2 = y1 + 1;
  const oy1 = y2 - (cy - th);
  const oy2 = (cy + th) - y2;

  // TODO: use special line shader instead
  const shader = getShader(webgl, 'vertexColor');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
  pushQuad(batch, x1, y - GAP - SIZE, THICKNESS, SIZE, 0, 0, 0, 0, 0, 0, r * ox1, g * ox1, b * ox1, a * ox1); // top (left)
  pushQuad(batch, x2, y - GAP - SIZE, THICKNESS, SIZE, 0, 0, 0, 0, 0, 0, r * ox2, g * ox2, b * ox2, a * ox2); // top (right)
  pushQuad(batch, x1, y + GAP, THICKNESS, SIZE, 0, 0, 0, 0, 0, 0, r * ox1, g * ox1, b * ox1, a * ox1); // bottom (left)
  pushQuad(batch, x2, y + GAP, THICKNESS, SIZE, 0, 0, 0, 0, 0, 0, r * ox2, g * ox2, b * ox2, a * ox2); // bottom (right)
  pushQuad(batch, x - GAP - SIZE, y1, SIZE, THICKNESS, 0, 0, 0, 0, 0, 0, r * oy1, g * oy1, b * oy1, a * oy1); // left (top)
  pushQuad(batch, x - GAP - SIZE, y2, SIZE, THICKNESS, 0, 0, 0, 0, 0, 0, r * oy2, g * oy2, b * oy2, a * oy2); // left (bottom)
  pushQuad(batch, x + GAP, y1, SIZE, THICKNESS, 0, 0, 0, 0, 0, 0, r * oy1, g * oy1, b * oy1, a * oy1); // right (top)
  pushQuad(batch, x + GAP, y2, SIZE, THICKNESS, 0, 0, 0, 0, 0, 0, r * oy2, g * oy2, b * oy2, a * oy2); // right (bottom)
  flushBatch(batch);
}

function drawSelfVideo(webgl: WebGLResources, cursor: Cursor, user: User, opacity: number) {
  const { gl, batch } = webgl;
  const pixelRatio = getPixelRatio();
  const shader = getShader(webgl, 'sprite');
  gl.useProgram(shader.program);
  const viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

  unbindTexture(gl, 0);
  const texture = createSelfVideoTexture(webgl, pixelRatio, user);
  bindTexture(gl, 0, texture);
  {
    const cx = cursor.x * pixelRatio;
    const cy = cursor.y * pixelRatio;

    const a = opacity;
    const c = selfVideoPlate;
    if (c) {
      pushQuad(batch, cx + USER_NAME_OFFSET, cy + USER_NAME_OFFSET, c.w, c.h, c.tx + c.tw, c.ty, -c.tw, c.th, 1, 0, a, a, a, a);
    }
  }

  flushBatch(batch);
  unbindTexture(gl, 0);
}

function drawCursor(webgl: WebGLResources, cursor: Cursor, view: Viewport, skipCrosshair: boolean) {
  const { gl, batch } = webgl;

  if (!cursor.show || cursor.type === CursorType.None) return;

  const pixelRatio = getPixelRatio();
  const c = cursorColor;
  const r = c[0], g = c[1], b = c[2], a = c[3];

  switch (cursor.type) {
    case CursorType.Circle: {
      const radius = Math.max(1, (cursor.size / 2)) * pixelRatio;
      const radiusWithBorder = Math.ceil(radius + 2);
      const cx = cursor.x * pixelRatio;
      const cy = cursor.y * pixelRatio;
      const x = cx - radiusWithBorder;
      const y = cy - radiusWithBorder;
      const size = radiusWithBorder * 2;

      const radius2 = radius + 0.75;
      const radiusWithBorder2 = Math.ceil(radius + 2);
      const x2 = cx - radiusWithBorder2;
      const y2 = cy - radiusWithBorder2;
      const size2 = radiusWithBorder2 * 2;

      const viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);
      const MIN_CIRCLE_SIZE = 2.5;
      const MIN_NORMAL_SIZE = 5.0;

      const shader = getShader(webgl, cursor.size >= MIN_CIRCLE_SIZE ? 'circleOutline' : 'circle');
      gl.useProgram(shader.program);
      gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

      if (cursor.size >= MIN_CIRCLE_SIZE) {
        gl.uniform1f(shader.uniforms.lineWidth, clamp((16 - cursor.size) / 6, 1, 1.75));
        pushQuad(batch, x2, y2, size2, size2, 0, 0, 1, 1, radiusWithBorder2, radiusWithBorder2 / radius2, 0.5, 0.5, 0.5, 0.5);
        pushQuad(batch, x, y, size, size, 0, 0, 1, 1, radiusWithBorder, radiusWithBorder / radius, r, g, b, a);
      } else {
        const x1 = cx + radiusWithBorder, y1 = cy + radiusWithBorder;
        const x1b = cx + radiusWithBorder2, y1b = cy + radiusWithBorder2;
        pushQuadXXYY(batch, x2, y2, x1b, y1b, x2 - cx, y2 - cy, x1b - cx, y1b - cy, radius + 0.5, 1, 1, 1, 1, 0.5);
        pushQuadXXYY(batch, x, y, x1, y1, x - cx, y - cy, x1 - cx, y1 - cy, radius, 1, r, g, b, a);
      }

      flushBatch(batch);

      if (cursor.size < MIN_NORMAL_SIZE && !skipCrosshair) {
        drawCrosshair(webgl, cx, cy, viewMatrix, pixelRatio);
      }

      break;
    }
    case CursorType.Square: {
      const MIN_NORMAL_SIZE = 4.0;
      const size = Math.round(cursor.size) * pixelRatio;
      const half = size / 2;
      const viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);
      const shader = getShader(webgl, 'rectOutline');
      const pix = 1;
      const pix2 = 2;
      const tex = pix / size;
      gl.useProgram(shader.program);
      gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
      gl.uniform1f(shader.uniforms.pixelSize, tex);

      // TODO: handle cursor smaller than outline width
      if (view.rotation === 0) {
        const x = round5(cursor.x * pixelRatio - half);
        const y = round5(cursor.y * pixelRatio - half);
        pushRectOutline(batch, x - 1, y - 1, size + 2, size + 2, 1, 0.25, 0.25, 0.25, 0.25);
        pushRectOutline(batch, x, y, size, size, 1, r, g, b, a);
        flushBatch(batch);
      } else {
        const x = round5(cursor.x * pixelRatio);
        const y = round5(cursor.y * pixelRatio);
        const min = -size / 2 - pix;
        const siz = size + 2 * pix;
        const min2 = -size / 2 - pix2;
        const siz2 = size + 2 * pix2;

        const cursorMatrix = tempMat;
        identityMat2d(cursorMatrix);
        translateMat2d(cursorMatrix, cursorMatrix, x, y);
        rotateMat2d(cursorMatrix, cursorMatrix, -view.rotation);

        pushTransformedQuad(batch, min2, min2, siz2, siz2, -tex, -tex, 1 + 2 * tex, 1 + 2 * tex, 0.25, 0.25, 0.25, 0.25, cursorMatrix);
        pushTransformedQuad(batch, min, min, siz, siz, -tex, -tex, 1 + 2 * tex, 1 + 2 * tex, r, g, b, a, cursorMatrix);
        flushBatch(batch);
      }

      if (cursor.size < MIN_NORMAL_SIZE && !skipCrosshair) {
        const cx = cursor.x * pixelRatio;
        const cy = cursor.y * pixelRatio;
        drawCrosshair(webgl, cx, cy, viewMatrix, pixelRatio);
      }

      break;
    }
    case CursorType.Crosshair: {
      const LENGTH = 11 * pixelRatio; // length of arms
      const WIDTH = 1 * pixelRatio; // half width of each arm
      const cx = cursor.x * pixelRatio;
      const cy = cursor.y * pixelRatio;
      const t0 = cy - WIDTH, t1 = cy - WIDTH - LENGTH;
      const b0 = cy + WIDTH;
      const l0 = cx - WIDTH, l1 = cx - WIDTH - LENGTH;

      const viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);
      const shader = getShader(webgl, 'vertexColor');
      gl.useProgram(shader.program);
      gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
      gl.blendEquation(gl.FUNC_SUBTRACT);
      gl.blendFunc(gl.ONE, gl.ONE);
      pushQuad(batch, l1, t0, (WIDTH + LENGTH) * 2, WIDTH * 2, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1);
      pushQuad(batch, l0, t1, WIDTH * 2, LENGTH, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1);
      pushQuad(batch, l0, b0, WIDTH * 2, LENGTH, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1);
      flushBatch(batch);
      gl.blendEquation(gl.FUNC_ADD);
      gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);

      break;
    }
    default:
      invalidEnum(cursor.type);
  }
}

function drawActiveTool(webgl: WebGLResources, user: User, view: Viewport) {
  const { gl, batch } = webgl;
  const tool = user.activeTool;

  if (!tool) return;

  switch (tool.id) {
    case ToolId.AI: {
      const t = tool as AiTool;
      drawAiSelectionPoly(webgl, t.poly, view, AI_BOUNDING_BOX_COLOR_1_ACTIVE, AI_BOUNDING_BOX_COLOR_2_ACTIVE, 1);
      break;
    }
    case ToolId.Selection:
    case ToolId.CircleSelection:
    case ToolId.LassoSelection: {
      const pixelRatio = getPixelRatio();
      const thickness = 1 * pixelRatio;
      const viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);

      const shader = getShader(webgl, 'ants');
      gl.useProgram(shader.program);
      gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
      gl.uniform1f(shader.uniforms.size, 6 * pixelRatio);
      gl.uniform1f(shader.uniforms.time, Math.floor(performance.now() / 100));

      const mat = createViewportMatrix2d(tempMat, view);
      multiplyMat2d(mat, mat, user.surface.transform);

      switch (tool.id) {
        case ToolId.Selection: {
          const selectionTool = tool as SelectionTool;
          pushLineRect(batch, selectionTool.rect, mat, thickness);
          break;
        }
        case ToolId.CircleSelection: {
          const circleSelectionTool = tool as CircleSelectionTool;
          pushLineEllipse(batch, circleSelectionTool.rect, mat, thickness);
          break;
        }
        case ToolId.LassoSelection: {
          const lassoSelection = tool as LassoSelectionTool;
          pushPoly(batch, lassoSelection.poly, mat, false, thickness);
          break;
        }
        // case ToolId.LassoBrush: {
        //   const lassoBrush = tool as LassoBrushTool;
        //   pushPolyf(batch, lassoBrush.polyf, mat, false, thickness);
        //   break;
        // }
      }

      flushBatch(batch);
      break;
    }
    case ToolId.RotateView: {
      const size = 5;
      const crosshair = size + 3;
      const pixelRatio = getPixelRatio();
      const radius = size * pixelRatio;
      const radiusWithBorder = radius + 2;
      const cx = Math.round(view.width / 2) * pixelRatio;
      const cy = Math.round(view.height / 2) * pixelRatio;
      const x = cx - radiusWithBorder;
      const y = cy - radiusWithBorder;
      const quadSize = radiusWithBorder * 2;
      const viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);
      const c = cursorColor;
      const r = c[0], g = c[1], b = c[2], a = c[3];

      {
        const shader = getShader(webgl, 'circleOutline');
        gl.useProgram(shader.program);
        gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
        gl.uniform1f(shader.uniforms.lineWidth, 1.5);

        pushQuad(batch, x, y + 1, quadSize - 1, quadSize - 1, 0, 0, 1, 1, radiusWithBorder, radiusWithBorder / radius, r, g, b, a);
        flushBatch(batch);
      }

      {
        const shader = getShader(webgl, 'vertexColor');
        gl.useProgram(shader.program);
        gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

        pushQuad(batch, cx - 0.5, cy - crosshair + 1, 1, crosshair * 2 - 1, 0, 0, 0, 0, 0, 0, r, g, b, a);
        pushQuad(batch, cx - crosshair, cy - 0.5, crosshair * 2 - 1, 1, 0, 0, 0, 0, 0, 0, r, g, b, a);
        flushBatch(batch);
      }

      break;
    }
    case ToolId.Text: {
      const textTool = tool as TextTool;
      if (textTool.mode === TextToolMode.Creating) {
        const ratio = getPixelRatio();
        const pixelSize = 1 / view.scale;
        const thickness = TEXTAREA_HOVERED_BOUNDARIES_WIDTH;
        const viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);

        const rect = cloneRect(textTool.rect);
        if (textTool.textarea?.type === TextareaType.AutoWidth) {
          rect.x += (textTool.textarea as AutoWidthTextarea).negativeOffsetForWidth;
        }
        const topLeft = createPoint(rect.x, rect.y);
        const topRight = createPoint(rect.x + rect.w, rect.y);
        const bottomRight = createPoint(rect.x + rect.w, rect.y + rect.h);
        const bottomLeft = createPoint(rect.x, rect.y + rect.h);
        documentToScreenPoints([topLeft, topRight, bottomRight, bottomLeft], view);

        const shader = getShader(webgl, 'line');
        gl.useProgram(shader.program);
        gl.uniform1f(shader.uniforms.pixelSize, pixelSize);
        gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

        const { r, g, b, a } = TEXTAREA_BOUNDARIES_COLOR;
        pushAntialiasedLine(batch, topLeft.x * ratio, topLeft.y * ratio, topRight.x * ratio, topRight.y * ratio, thickness, r, g, b, a);
        pushAntialiasedLine(batch, topRight.x * ratio, topRight.y * ratio, bottomRight.x * ratio, bottomRight.y * ratio, thickness, r, g, b, a);
        pushAntialiasedLine(batch, bottomRight.x * ratio, bottomRight.y * ratio, bottomLeft.x * ratio, bottomLeft.y * ratio, thickness, r, g, b, a);
        pushAntialiasedLine(batch, bottomLeft.x * ratio, bottomLeft.y * ratio, topLeft.x * ratio, topLeft.y * ratio, thickness, r, g, b, a);
        flushBatch(batch);
      }
      break;
    }
  }
}

function fillSelection(webgl: WebGLResources, selection: Mask, view: Viewport) {
  const { gl, batch } = webgl;
  const pixelRatio = getPixelRatio();
  const m = createMat2d();
  const mat = createViewportMatrix2d(m, view);
  const mask = cloneMask(selection);
  transformMask(mask, mat);
  const width = gl.drawingBufferWidth / pixelRatio;
  const height = gl.drawingBufferHeight / pixelRatio;

  const maskTexture = createMaskTexture(webgl, mask, 0, 0, width, height);

  const shader = getShader(webgl, 'aiMaskInpaint');
  const vm = identityViewMatrix(width, height);
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, vm);
  gl.uniform1f(shader.uniforms.size, 4);
  gl.uniform1f(shader.uniforms.width, width);
  gl.uniform1f(shader.uniforms.height, height);
  gl.uniform4fv(shader.uniforms.color1, AI_SELECTION_COLOR_1_FLOAT);
  gl.uniform4fv(shader.uniforms.color2, AI_SELECTION_COLOR_2_FLOAT);
  gl.uniform1f(shader.uniforms.time, performance.now() / (40 * pixelRatio));

  bindTexture(gl, 0, maskTexture);
  pushQuad(batch, 0, 0, width, height, 0, 0, 1, 1, 0, 0, 1, 1, 1, 1);
  flushBatch(batch);

  unbindTexture(gl, 0);
}

function drawAiSelectionPoly(webgl: WebGLResources, poly: Poly, view: Viewport, color1: Float32Array, color2: Float32Array, thickness: number) {
  const { gl, batch } = webgl;

  const pixelRatio = getPixelRatio();
  const viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);

  const shader = getShader(webgl, 'antsAi');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

  gl.uniform1f(shader.uniforms.size, 4 * pixelRatio);
  gl.uniform1f(shader.uniforms.time, Math.floor(performance.now() / 20));
  gl.uniform4fv(shader.uniforms.color1, color1);
  gl.uniform4fv(shader.uniforms.color2, color2);

  const mat = createViewportMatrix2d(tempMat, view);
  pushPoly(batch, poly, mat, true, thickness * pixelRatio);
  flushBatch(batch);
}

function drawAiOutpaintingMask(webgl: WebGLResources, tool: AiTool, layer: Layer, view: Viewport) {
  const gl = webgl.gl;
  const bounds = tool.bounds;
  const texture = getTexture(webgl, bounds.w, bounds.h, 'outpaint', false);
  bindAndClearBuffer(webgl, texture, colorToFloatArray(TRANSPARENT));

  drawLayer(webgl, webgl.emptyTexture, layer, layer.opacityLocked, layer.opacity, true, false, bounds.x, bounds.y, bounds.w, bounds.h, 0, 0, 1);
  unbindTexture(gl, 0);
  unbindFrameBufferAndTexture(webgl);

  const pixelRatio = getPixelRatio();
  const width = texture.width / pixelRatio * view.scale;
  const height = texture.height / pixelRatio * view.scale;
  const vm = identityViewMatrix(gl.drawingBufferWidth / pixelRatio, gl.drawingBufferHeight / pixelRatio);
  const m = createMat2d();
  const mat = createViewportMatrix2d(m, view);

  const shader = getShader(webgl, 'aiMaskOutpaint');
  gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, vm);
  gl.uniform1f(shader.uniforms.size, 4 / pixelRatio);
  gl.uniform4fv(shader.uniforms.color1, AI_SELECTION_COLOR_1_FLOAT);
  gl.uniform4fv(shader.uniforms.color2, AI_SELECTION_COLOR_2_FLOAT);
  gl.uniform1f(shader.uniforms.time, performance.now() / (40 * pixelRatio));
  gl.uniform1f(shader.uniforms.width, width);
  gl.uniform1f(shader.uniforms.height, height);

  bindTexture(gl, 0, texture);
  pushQuadTransformed(webgl.batch, mat, bounds.x, bounds.y, texture.width, texture.height, 0, 0, 1, 1, 0, 0, 1, 0, 0, 0.5);
  flushBatch(webgl.batch);

  unbindTexture(gl, 0);

  releaseTexture(webgl, texture);
}

function drawSelection(webgl: WebGLResources, user: User, selection: Mask, view: Viewport) {
  const { gl, batch } = webgl;

  if (selection?.poly && !isMaskEmpty(selection)) {
    const pixelRatio = getPixelRatio();
    const viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);

    const shader = getShader(webgl, 'ants');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
    gl.uniform1f(shader.uniforms.size, 4 * pixelRatio);
    gl.uniform1f(shader.uniforms.time, Math.floor(performance.now() / 250));

    const mat = createViewportMatrix2d(tempMat, view);
    multiplyMat2d(mat, mat, user.surface.transform);

    // TODO: cache poly batch
    pushPoly(batch, selection.poly, mat, true, 1 * pixelRatio);
    flushBatch(batch);
  }
}

function pushRect(batch: TriangleBatch, x: number, y: number, w: number, h: number, r: number, g: number, b: number, a: number) {
  pushQuad(batch, x, y, w, 1, 0, 0, 0, 0, 0, 0, r, g, b, a); // top
  pushQuad(batch, x, y + h - 1, w, 1, 0, 0, 0, 0, 0, 0, r, g, b, a); // bottom
  pushQuad(batch, x, y + 1, 1, h - 2, 0, 0, 0, 0, 0, 0, r, g, b, a); // left
  pushQuad(batch, x + w - 1, y + 1, 1, h - 2, 0, 0, 0, 0, 0, 0, r, g, b, a); // right
}

function pushSolidTransformedRect(batch: TriangleBatch, mat: Mat2d, x: number, y: number, w: number, h: number, r: number, g: number, b: number, a: number) {
  pushTransformedQuad(batch, x, y, w, h, 0, 0, 0, 0, r, g, b, a, mat);
}

function drawTransformControl(batch: TriangleBatch, cx: number, cy: number) {
  const size = 7;
  const x = round5(cx) - size / 2;
  const y = round5(cy) - size / 2;
  pushRect(batch, x - 1, y - 1, size + 2, size + 2, 1, 1, 1, 1); // white
  pushRect(batch, x, y, size, size, 0, 0, 0, 1); // black
}

function drawAiControl(batch: TriangleBatch, mat: Mat2d, cx: number, cy: number, color: Float32Array, view: Viewport) {
  const size = 8 / view.scale;
  const x = Math.round(cx) - size / 2;
  const y = Math.round(cy) - size / 2;

  pushSolidTransformedRect(batch, mat, x, y, size, size, color[0], color[1], color[2], color[3]);
  pushSolidTransformedRect(batch, mat, x + 1 / view.scale, y + 1 / view.scale, size - 2 / view.scale, size - 2 / view.scale, 1, 1, 1, 1);
}

function drawTransform(webgl: WebGLResources, user: User, drawing: Drawing, view: Viewport) {
  const { gl, batch } = webgl;
  const pixelRatio = getPixelRatio();
  const mat = createViewportMatrix2d(tempMat, view);
  const bounds = getTransformBounds(user, drawing, mat);
  let viewMatrix = identityViewMatrix(gl.drawingBufferWidth / pixelRatio, gl.drawingBufferHeight / pixelRatio);

  { // transform cage
    const shader = getShader(webgl, 'ants');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
    gl.uniform1f(shader.uniforms.size, 3);
    gl.uniform1f(shader.uniforms.time, 0);
    pushPolygon(batch, bounds, true, 1);
    flushBatch(batch);
  }

  { // control points
    const shader = getShader(webgl, 'vertexColor');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

    for (let i = 0; i < 4; i++) {
      const pt = bounds[i];
      drawTransformControl(batch, pt[0], pt[1]);
      const pt2 = bounds[(i + 1) % 4];
      drawTransformControl(batch, (pt[0] + pt2[0]) / 2, (pt[1] + pt2[1]) / 2);
    }
    flushBatch(batch);
  }

  viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);
  getTransformOrigin(tempVec, bounds, user, mat);
  const centerX = round5(tempVec[0] * pixelRatio);
  const centerY = round5(tempVec[1] * pixelRatio);

  { // transform origin circle
    const shader = getShader(webgl, 'circleOutline');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
    gl.uniform1f(shader.uniforms.lineWidth, 1.5 * pixelRatio);

    {
      const circleSize = 8;
      const radius = (circleSize / 2) * pixelRatio;
      const radiusWithBorder = Math.ceil(radius + 2);
      const size = radiusWithBorder * 2;
      const x = centerX - radiusWithBorder;
      const y = centerY - radiusWithBorder;
      pushQuad(batch, x, y, size, size, 0, 0, 1, 1, radiusWithBorder, radiusWithBorder / radius, 1, 1, 1, 1); // white
    }

    {
      const circleSize = 6;
      const radius = (circleSize / 2) * pixelRatio;
      const radiusWithBorder = Math.ceil(radius + 2);
      const size = radiusWithBorder * 2;
      const x = centerX - radiusWithBorder;
      const y = centerY - radiusWithBorder;
      pushQuad(batch, x, y, size, size, 0, 0, 1, 1, radiusWithBorder, radiusWithBorder / radius, 0, 0, 0, 1); // black
    }

    flushBatch(batch);
  }

  { // transform origin lines
    const pr = pixelRatio;
    const shader = getShader(webgl, 'vertexColor');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

    pushQuad(batch, centerX - 0.5 * pr, centerY - 7.5 * pr, 1 * pr, 4 * pr, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1); // top
    pushQuad(batch, centerX - 0.5 * pr, centerY + 3.5 * pr, 1 * pr, 4 * pr, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1); // bottom
    pushQuad(batch, centerX - 7.5 * pr, centerY - 0.5 * pr, 4 * pr, 1 * pr, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1); // left
    pushQuad(batch, centerX + 3.5 * pr, centerY - 0.5 * pr, 4 * pr, 1 * pr, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1); // right

    pushQuad(batch, centerX - 0.5 * pr, centerY - 6.5 * pr, 1 * pr, 3 * pr, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1); // top
    pushQuad(batch, centerX - 0.5 * pr, centerY + 3.5 * pr, 1 * pr, 3 * pr, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1); // bottom
    pushQuad(batch, centerX - 6.5 * pr, centerY - 0.5 * pr, 3 * pr, 1 * pr, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1); // left
    pushQuad(batch, centerX + 3.5 * pr, centerY - 0.5 * pr, 3 * pr, 1 * pr, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1); // right

    flushBatch(batch);
  }
}

function drawAiBoundingBox(webgl: WebGLResources, view: Viewport, drawing: Drawing, options: DrawOptions) {
  const tool = options.selectedTool as AiTool;
  const { gl, batch } = webgl;
  const pixelRatio = getPixelRatio();
  const mat = createViewportMatrix2d(tempMat, view);
  const viewMatrix = identityViewMatrix(gl.drawingBufferWidth / pixelRatio, gl.drawingBufferHeight / pixelRatio);
  const { x, y, w, h } = tool.bounds;

  if (tool.showMask) {
    const shader = getShader(webgl, 'vertexColor');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

    pushQuadTransformed(batch, mat, x, y, w, -y, 0, 0, 1, 1, 0, 0, 0, 0, 0, AI_BOUNDING_BOX_MASK_ALPHA);
    pushQuadTransformed(batch, mat, x, y + h, w, drawing.height - (y + h), 0, 0, 1, 1, 0, 0, 0, 0, 0, AI_BOUNDING_BOX_MASK_ALPHA);
    pushQuadTransformed(batch, mat, 0, 0, x, drawing.height, 0, 0, 1, 1, 0, 0, 0, 0, 0, AI_BOUNDING_BOX_MASK_ALPHA);
    pushQuadTransformed(batch, mat, w + x, 0, drawing.width - w - x, drawing.height, 0, 0, 1, 1, 0, 0, 0, 0, 0, AI_BOUNDING_BOX_MASK_ALPHA);

    flushBatch(batch);
  }

  if (tool.isActive) {
    drawAnimatedFrame(webgl, view, tool.bounds, AI_BOUNDING_BOX_COLOR_1_ACTIVE, AI_BOUNDING_BOX_COLOR_2_ACTIVE, 2);
  } else {
    drawSolidFrame(webgl, view, tool.bounds, AI_BOUNDING_BOX_COLOR_2_ACTIVE, WHITE_FLOAT, 1);
  }

  if (!tool.isActive) { // control points
    const shader = getShader(webgl, 'vertexColor');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

    drawAiControl(batch, mat, x, y, AI_BOUNDING_BOX_COLOR_2_ACTIVE, view);
    drawAiControl(batch, mat, x, y + h, AI_BOUNDING_BOX_COLOR_2_ACTIVE, view);
    drawAiControl(batch, mat, x + w, y, AI_BOUNDING_BOX_COLOR_2_ACTIVE, view);
    drawAiControl(batch, mat, x + w, y + h, AI_BOUNDING_BOX_COLOR_2_ACTIVE, view);

    flushBatch(batch);
  }
}

function drawTextarea(webgl: WebGLResources, textarea: Textarea, view: Viewport, options: DrawOptions) {
  if (options.selectedTool?.id === ToolId.Text) {
    const textTool = (options.selectedTool as TextTool);
    if (shouldRenderTextareaBoundaries(textarea, textTool, options)) drawTextareaBoundaries(webgl, view, textarea);
    if (shouldRenderTextareaCursor(textarea, textTool)) {
      const selection = textTool.textSelection;
      if (selection) {
        const { caretRect, selectionRects } = textarea.getCursorPosition(selection);
        if (caretRect) drawTextareaCaret(webgl, view, caretRect, textarea.transform);
        if (selectionRects.length > 0) drawTextareaSelectionRects(webgl, view, selectionRects, textarea.transform);
      }
    }
    if (shouldRenderTextareaBaselineIndicator(textarea, textTool)) drawTextareaBaselineIndicator(webgl, view, textarea);
    if (shouldRenderTextareaControlPoints(textarea, textTool)) drawTextareaControlPoints(webgl, view, textarea);
    if (shouldRenderTextareaOverflowIndicator(textarea, textTool)) drawTextareaOverflowIndicator(webgl, view, textarea);
  } else {
    if (options.selectedTool?.id !== ToolId.Transform) drawTextareaBoundaries(webgl, view, textarea, TEXTAREA_UNHOVERED_BOUNDARIES_WIDTH);
  }
}

function drawSolidFrame(webgl: WebGLResources, view: Viewport, rect: Rect, color1: Float32Array, color2: Float32Array, thickness: number) {
  const { gl, batch } = webgl;
  const ratio = getPixelRatio();
  const viewMatrix = identityViewMatrix(gl.drawingBufferWidth / ratio, gl.drawingBufferHeight / ratio);
  const mat = createViewportMatrix2d(tempMat, view);

  const shader = getShader(webgl, 'rectOutline');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
  gl.uniform1f(shader.uniforms.pixelSize, 1);

  const r1 = outsetRect(cloneRect(rect), - thickness / view.scale / 2);
  const r2 = outsetRect(cloneRect(r1), thickness / view.scale);

  pushRectOutlineTransformed(batch, mat, r1.x, r1.y, r1.w, r1.h, thickness / view.scale, color1[0], color1[1], color1[2], color1[3]);
  pushRectOutlineTransformed(batch, mat, r2.x, r2.y, r2.w, r2.h, thickness / view.scale, color2[0], color2[1], color2[2], color2[3]);

  flushBatch(batch);
}

function drawAnimatedFrame(webgl: WebGLResources, view: Viewport, rect: Rect, color1: Float32Array, color2: Float32Array, thickness: number) {
  const { gl, batch } = webgl;
  const ratio = getPixelRatio();
  const viewMatrix = identityViewMatrix(gl.drawingBufferWidth / ratio, gl.drawingBufferHeight / ratio);
  const mat = createViewportMatrix2d(tempMat, view);

  const bounds = [createVec2(), createVec2(), createVec2(), createVec2()];
  rectToBounds(bounds, rect);
  if (mat) transformBounds(bounds, mat);

  const shader = getShader(webgl, 'antsAi');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
  gl.uniform1f(shader.uniforms.time, Math.floor(performance.now() / 20));
  gl.uniform1f(shader.uniforms.size, 4);
  gl.uniform4fv(shader.uniforms.color1, color1);
  gl.uniform4fv(shader.uniforms.color2, color2);

  pushPolygon(batch, bounds, true, thickness);
  flushBatch(batch);
}

function drawTextareaBoundaries(webgl: WebGLResources, view: Viewport, textarea: Textarea, forceBoundariesThickness?: number, color?: string) {
  const { gl, batch } = webgl;
  const pixelSize = 1 / view.scale / getPixelRatio();
  const viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);

  const shader = getShader(webgl, 'line');
  gl.useProgram(shader.program);
  gl.uniform1f(shader.uniforms.pixelSize, pixelSize);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

  const thickness = forceBoundariesThickness ?? textarea.boundariesStrokeWidth;
  let r: number, g: number, b: number, a: number;
  if (!color) {
    const textareaColor = textarea.boundariesStrokeColor;
    r = textareaColor.r; g = textareaColor.g;
    b = textareaColor.b; a = textareaColor.a;
  } else {
    const textareaColor = colorToRGBA(parseColor(color));
    r = textareaColor.r / 255; g = textareaColor.g / 255;
    b = textareaColor.b / 255; a = textareaColor.a / 255;
  }

  const blueBounds = cloneBounds(textarea.bounds);
  const whiteBounds = cloneBounds(textarea.bounds);
  outsetBounds(whiteBounds, pixelSize);

  createViewportMatrix2d(tempMat, view);
  pushTextareaBoundry(batch, whiteBounds, tempMat, thickness, 1, 1, 1, a);
  pushTextareaBoundry(batch, blueBounds, tempMat, thickness, r, g, b, a);

  flushBatch(batch);
}

function pushTextareaBoundry(batch: TriangleBatch, bounds: Vec2[], viewMatrix: Mat2d, thickness: number, r: number, g: number, b: number, a: number) {
  const ratio = getPixelRatio();
  for (const point of bounds) { transformVec2ByMat2d(point, point, viewMatrix); }
  pushAntialiasedLine(batch, bounds[0][0] * ratio, bounds[0][1] * ratio, bounds[1][0] * ratio, bounds[1][1] * ratio, thickness, r, g, b, a);
  pushAntialiasedLine(batch, bounds[1][0] * ratio, bounds[1][1] * ratio, bounds[2][0] * ratio, bounds[2][1] * ratio, thickness, r, g, b, a);
  pushAntialiasedLine(batch, bounds[2][0] * ratio, bounds[2][1] * ratio, bounds[3][0] * ratio, bounds[3][1] * ratio, thickness, r, g, b, a);
  pushAntialiasedLine(batch, bounds[3][0] * ratio, bounds[3][1] * ratio, bounds[0][0] * ratio, bounds[0][1] * ratio, thickness, r, g, b, a);
}

function drawTextareaCaret(webgl: WebGLResources, view: Viewport, caret: Rect, transform: Mat2d) {
  const { gl, batch } = webgl;
  const pixelSize = 1 / view.scale;

  const viewMatrix = createViewportMatrix2d(tempMat2, view);
  const mat4 = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);

  const shader = getShader(webgl, 'line');
  gl.useProgram(shader.program);
  gl.uniform1f(shader.uniforms.pixelSize, pixelSize);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, mat4);

  const p1 = createVec2FromValues(caret.x + caret.w / 2, caret.y);
  transformVec2ByMat2d(p1, p1, transform);
  transformVec2ByMat2d(p1, p1, viewMatrix);

  const p2 = createVec2FromValues(caret.x + caret.w / 2, caret.y + caret.h);
  transformVec2ByMat2d(p2, p2, transform);
  transformVec2ByMat2d(p2, p2, viewMatrix);

  const ratio = getPixelRatio();
  const thickness = clamp(distance(p1[0], p1[1], p2[0], p2[1]) * 0.05, MIN_TEXTAREA_CURSOR_WIDTH, MAX_TEXTAREA_CURSOR_WIDTH);

  pushAntialiasedLine(batch, p1[0] * ratio, p1[1] * ratio, p2[0] * ratio, p2[1] * ratio, thickness * ratio, 0, 0, 0, 1);

  flushBatch(batch);
}

function drawTextareaSelectionRects(webgl: WebGLResources, view: Viewport, selectionRects: Rect[], transform: Mat2d) {
  const { gl, batch } = webgl;
  const pixelSize = 1 / view.scale;
  const mat4 = createViewportMatrix4(tempMat4, view);

  const shader = getShader(webgl, 'vertexColor');
  gl.useProgram(shader.program);
  gl.uniform1f(shader.uniforms.pixelSize, pixelSize);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, mat4);

  const { r, g, b, a } = TEXTAREA_SELECTION_RECT_COLOR;
  gl.blendFunc(gl.DST_COLOR, gl.ZERO);
  for (const rect of selectionRects) {
    pushQuadTransformed(batch, transform, rect.x, rect.y, rect.w, rect.h, 0, 0, 1, 1, 0, 0, r * a, g * a, b * a, a);
  }
  flushBatch(batch);
  gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
}

function drawTextareaControlPoints(webgl: WebGLResources, view: Viewport, textarea: Textarea) {
  const { gl, batch } = webgl;
  const pixelSize = 1 / view.scale;
  const ratio = getPixelRatio();
  const viewMatrix = identityViewMatrix(gl.drawingBufferWidth / ratio, gl.drawingBufferHeight / ratio);

  const shader = getShader(webgl, 'vertexColor');
  gl.useProgram(shader.program);
  gl.uniform1f(shader.uniforms.pixelSize, pixelSize);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

  for (const controlPoint of textarea.controlPoints) {
    const { blueRect, whiteRect, thickness } = controlPoint.getDrawingInstructions(view);
    pushQuad(batch, whiteRect.x, whiteRect.y, whiteRect.w, whiteRect.h, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1);
    pushQuad(batch, blueRect.x, blueRect.y, blueRect.w, blueRect.h, 0, 0, 0, 0, 0, 0, 46 / 255, 109 / 255, 1, 1);
    if (!controlPoint.active) pushQuad(batch, blueRect.x + thickness, blueRect.y + thickness, blueRect.w - 2 * thickness, blueRect.h - 2 * thickness, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1);
  }

  flushBatch(batch);
}

function drawTextareaBaselineIndicator(webgl: WebGLResources, view: Viewport, textarea: Textarea) {
  const { gl, batch } = webgl;
  const ratio = getPixelRatio();
  const pixelSize = 1 / view.scale;
  const viewMatrix = identityViewMatrix(gl.drawingBufferWidth / ratio, gl.drawingBufferHeight / ratio);

  const baselineIndicator = textarea.getBaselineIndicator();

  for (const entry of baselineIndicator) {
    {
      const [lineStart, lineEnd] = entry.line;
      // blue line on glyphs baseline
      const shader = getShader(webgl, 'line');
      gl.useProgram(shader.program);
      gl.uniform1f(shader.uniforms.pixelSize, pixelSize);
      gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

      documentToScreenPoints([lineStart, lineEnd], view);
      const thickness = textarea.isHovering ? TEXTAREA_BASELINE_INDICATOR_HOVERED_LINE_THICKNESS : TEXTAREA_BASELINE_INDICATOR_UNHOVERED_LINE_THICKNESS;
      const { r, g, b, a } = textarea.boundariesStrokeColor;

      pushAntialiasedLine(batch, lineStart.x, lineStart.y, lineEnd.x, lineEnd.y, thickness, r, g, b, a);
      flushBatch(batch);
    }

    {
      const { alignmentSquare } = entry;
      if (alignmentSquare) {
        // blue square indicating how paragraph is aligned
        const shader = getShader(webgl, 'vertexColor');
        gl.useProgram(shader.program);
        gl.uniform1f(shader.uniforms.pixelSize, pixelSize);
        gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

        const squareSize = getBaselineIndicatorAlignmentSquareSize(view);

        const mat2 = createViewportMatrix2d(tempMat2, view);
        pushQuadTransformed(batch, mat2, alignmentSquare.x - squareSize / 2, alignmentSquare.y - squareSize / 2, squareSize, squareSize, 0, 0, 0, 0, 0, 0, 46 / 255, 109 / 255, 1, 1);
        flushBatch(batch);
      }
    }
  }
}

function drawTextareaOverflowIndicator(webgl: WebGLResources, view: Viewport, textarea: Textarea) {
  const point = textarea.getOverflowIndicator();
  documentToScreenPoint(point, view);
  const { x, y } = point;

  const ratio = getPixelRatio();
  const size = TEXTAREA_OVERFLOW_INDICATOR_SQUARE_SIZE / ratio;

  const { gl, batch } = webgl;
  const pixelSize = 1 / view.scale;
  const viewMatrix = identityViewMatrix(gl.drawingBufferWidth / ratio, gl.drawingBufferHeight / ratio);

  const shader = getShader(webgl, 'vertexColor');
  gl.useProgram(shader.program);
  gl.uniform1f(shader.uniforms.pixelSize, pixelSize);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

  pushQuad(batch, x - size / 2 - 2, y - size / 2 - 2, size + 4, size + 4, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1);

  const { r, g, b, a } = TEXTAREA_OVERFLOW_INDICATOR_RED;
  pushQuad(batch, x - size / 2, y - size / 2, size, size, 0, 0, 0, 0, 0, 0, r / 255, g / 255, b / 255, a / 255);

  const plusThickness = size * TEXTAREA_OVERFLOW_INDICATOR_PLUS_THICKNESS;
  const plusSize = size * TEXTAREA_OVERFLOW_INDICATOR_PLUS_SIZE;

  pushQuad(batch, x - plusThickness / 2, y - plusSize / 2, plusThickness, plusSize, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1);
  pushQuad(batch, x - plusSize / 2, y - plusThickness / 2, plusSize, plusThickness, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1);

  flushBatch(batch);
}

export function resizeWebGL(webgl: WebGLResources, { width, height }: Drawing) {
  webgl.width = width;
  webgl.height = height;

  webgl.textureWidth = findPowerOf2(width);
  webgl.textureHeight = findPowerOf2(height);
  if (IS_IE11) webgl.textureWidth = webgl.textureHeight = Math.max(webgl.textureWidth, webgl.textureHeight);

  const matrix = webgl.drawingTransform;
  identityMat4(matrix);
  translateMat4(matrix, matrix, -1, -1, 0);
  scaleMat4(matrix, matrix, 2 / width, 2 / height, 1);
}

export function initializeWebGL(canvas: HTMLCanvasElement, gg?: WebGLResources): WebGLResources {
  const { gl, webgl2 } = gg ?? getWebGLContext(canvas);

  const webgl: WebGLResources = {
    name: gg?.name || '',
    gl,
    webgl2,
    width: 0,
    height: 0,
    textureWidth: 0,
    textureHeight: 0,
    shaders: gg?.shaders ?? new Map(),
    emptyTexture: undefined as any,
    whiteTexture: undefined as any,
    drawingTransform: createMat4(),
    frameBuffer: undefined as any,
    batch: undefined as any,
    tempCanvas: undefined as any,
    drawingTexture: undefined as any,
    textures: gg?.textures ?? [],
    allocatedTextures: gg?.allocatedTextures ?? [],
    thumbnailTexture: undefined as any,
    thumbnailTransform: createMat4(),
    namePlatesTexture: undefined,
    videoPlatesTexture: undefined,
    selfVideoTexture: undefined,
    namePlatesMode: CursorsMode.None,
    vertexShader: gg?.vertexShader as any,
    brushCache: gg?.brushCache || [],
    markers: [],
    maskTexture: undefined,
    maskCacheId: -1,
    maskRect: createRect(0, 0, 0, 0),
  };

  try {
    if (!gg) {
      webgl.vertexShader = createWebGLShader(gl, gl.VERTEX_SHADER, vertexSource, true);

      if (DEVELOPMENT && !SERVER) {
        // compile all shaders to check for errors
        for (const key of Object.keys(shaderSources)) {
          getShader(webgl, key);
        }
      }
    }

    webgl.emptyTexture = gg?.emptyTexture ?? createEmptyTexture(gl, 1, 1, new Uint8Array([0, 0, 0, 0]));
    webgl.whiteTexture = gg?.whiteTexture ?? createEmptyTexture(gl, 1, 1, new Uint8Array([255, 255, 255, 255]));

    const frameBuffer = gg?.frameBuffer ?? gl.createFramebuffer();
    if (!frameBuffer) throw new Error('Failed to create frame buffer');
    webgl.frameBuffer = frameBuffer;

    const matrixThumb = webgl.thumbnailTransform;
    translateMat4(matrixThumb, matrixThumb, -1, -1, 0);
    scaleMat4(matrixThumb, matrixThumb, 2, 2, 1);

    // TODO: check if these can be shared
    webgl.batch = /*gg?.batch ??*/ createBatch(gl, 8192, SERVER ? 2 : 8, SERVER ? 16 : 32);
    webgl.tempCanvas = gg?.tempCanvas ?? createCanvas(1, 1);

    if (!gg || TESTS) {
      webgl.drawingTexture = getTexture(webgl, 1, 1, 'drawing'); // this way for tracking
      webgl.thumbnailTexture = createEmptyTexture(gl, 1, 1);
    }
  } catch (e) {
    logAction(`initializeWebGL:error ${e && e.message}`);
    releaseWebGL(webgl);
    throw e;
  }

  // try {
  //   const ext = gl.getExtension('WEBGL_debug_renderer_info');

  //   if (ext) {
  //     webgl.info += `, vendor: ${gl.getParameter(ext.UNMASKED_VENDOR_WEBGL)}, renderer: ${gl.getParameter(ext.UNMASKED_RENDERER_WEBGL)}`;
  //   }
  // } catch { }

  return webgl;
}

export function releaseWebGL(webgl: WebGLResources, shared = false) {
  const {
    gl, shaders, frameBuffer, emptyTexture, whiteTexture, drawingTexture, textures, batch,
    namePlatesTexture, vertexShader, brushCache, thumbnailTexture, maskTexture
  } = webgl;

  if (!shared) {
    for (const key of Array.from(shaders.keys())) gl.deleteProgram(shaders.get(key)!.program);
    for (const texture of textures) deleteTexture(gl, texture);
    for (const brush of brushCache) deleteTexture(gl, brush.texture);

    deleteTexture(gl, emptyTexture);
    deleteTexture(gl, whiteTexture);
    frameBuffer && gl.deleteFramebuffer(frameBuffer);
    vertexShader && gl.deleteShader(vertexShader);
  }

  deleteTexture(gl, namePlatesTexture);
  deleteTexture(gl, drawingTexture);
  deleteTexture(gl, thumbnailTexture);
  deleteTexture(gl, maskTexture);
  batch && releaseBatch(batch);

  webgl.shaders = new Map();
  webgl.textures = [];
  webgl.allocatedTextures = [];
  webgl.brushCache = [];
  webgl.frameBuffer = null as any;
  webgl.emptyTexture = null as any;
  webgl.whiteTexture = null as any;
  webgl.vertexShader = null as any;
  webgl.maskTexture = undefined;

  if (webgl.pendingLayerThumb) {
    deleteBuffer(gl, webgl.pendingLayerThumb.buffer);
    webgl.pendingLayerThumb = undefined;
  }

  if (webgl.pendingDrawingThumb) {
    deleteBuffer(gl, webgl.pendingDrawingThumb.buffer);
    webgl.pendingDrawingThumb = undefined;
  }
}

const shadersDraw = new Map<string, string>();
const shadersDrawAndMask = new Map<string, string>();

for (const mode of LAYER_MODES) {
  if (mode) {
    shadersDraw.set(mode, `${mode}WithDraw`);
    shadersDrawAndMask.set(mode, `${mode}WithDrawAndMask`);
  }
}

function getShaderNameForMode(mode: string, toolMode: CompositeOp, twoMasks: boolean): string {
  switch (toolMode) {
    case CompositeOp.None:
      return mode;
    case CompositeOp.Erase:
    case CompositeOp.Draw:
    case CompositeOp.Move:
      if (twoMasks) {
        return shadersDrawAndMask.get(mode)!;
      } else {
        return shadersDraw.get(mode)!;
      }
    // TODO: add separate shader for CompositeOperation.Move
    default:
      return invalidEnum(toolMode);
  }
}

function getShaderForMode(webgl: WebGLResources, mode: string, toolMode: CompositeOp, hasMasks: boolean) {
  const name = getShaderNameForMode(mode, toolMode, hasMasks);
  return getShader(webgl, name);
}

function layerHasToolTexture(layer: Layer) {
  const owner = layer.owner;

  return owner !== undefined &&
    owner.surface.layer === layer &&
    !isSurfaceEmpty(owner.surface) &&
    !!owner.surface.texture;
}

const tempPixelBuffer = new Uint8Array(4);

export function copyCanvasToTexture(
  gl: WebGLRenderingContext, context: CanvasRenderingContext2D, texture: Texture, width: number, height: number, premultiply = false
) {
  gl.bindTexture(gl.TEXTURE_2D, texture.handle);

  if (SERVER) {
    // server doesn't support interoperation between canvas and webgl
    const data = context.getImageData(0, 0, width, height);
    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, premultiply ? 1 : 0); // ???
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, width, height, 0, gl.RGBA, gl.UNSIGNED_BYTE, toUint8(data.data));
  } else {
    // NOTE: this is not being tested (test manually)
    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, 1);
    gl.texSubImage2D(gl.TEXTURE_2D, 0, 0, 0, gl.RGBA, gl.UNSIGNED_BYTE, context.canvas);
  }

  gl.bindTexture(gl.TEXTURE_2D, null);
}

export function copyCanvasToTextureAt(
  gl: WebGLRenderingContext, context: CanvasRenderingContext2D, texture: Texture, x: number, y: number, premultiply = false
) {
  gl.bindTexture(gl.TEXTURE_2D, texture.handle);

  if (SERVER) {
    // server doesn't support interoperation between canvas and webgl
    const { width, height } = context.canvas;
    const data = context.getImageData(0, 0, width, height);
    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, premultiply ? 1 : 0); // ???
    gl.texSubImage2D(gl.TEXTURE_2D, 0, x, y, width, height, gl.RGBA, gl.UNSIGNED_BYTE, toUint8(data.data));
  } else {
    // NOTE: this is not being tested (test manually)
    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, 1);
    gl.texSubImage2D(gl.TEXTURE_2D, 0, x, y, gl.RGBA, gl.UNSIGNED_BYTE, context.canvas);
  }

  gl.bindTexture(gl.TEXTURE_2D, null);
}

function drawMaskToTexture(webgl: WebGLResources, texture: Texture, selection: Mask, ox: number, oy: number) {
  // We're not using selection bounds here because the texture is assumend not clear.
  // We can't clear the texture because we're often creating masks when something is bounds to render target.
  drawUsingCanvas(webgl, texture, 0, 0, texture.width, texture.height, 0, (context, x0, y0) => {
    context.save();
    context.translate(ox - x0, oy - y0);
    fillMask(context, selection);
    context.restore();
  });
}

function createMaskTexture(webgl: WebGLResources, selection: Mask, x: number, y: number, w: number, h: number) {
  const { maskRect: r, maskTexture, maskCacheId } = webgl;

  if (maskTexture && maskCacheId === selection.cacheId && r.x === x && r.y === y && r.w === w && r.h === h) {
    return maskTexture;
  }

  releaseTexture(webgl, webgl.maskTexture);
  webgl.maskTexture = getTexture(webgl, w, h, 'mask', false);
  drawMaskToTexture(webgl, webgl.maskTexture, selection, -x, -y);
  setRect(webgl.maskRect, x, y, w, h);
  webgl.maskCacheId = selection.cacheId;
  return webgl.maskTexture;
}

function maskOutLayer(webgl: WebGLResources, layer: Layer, mask: Texture, mx: number, my: number) {
  const { gl } = webgl;

  if (!layer.texture) throw new Error('Missing texture');

  bindFrameBufferAndTexture(webgl, layer.texture);
  // TODO: scrissor to layer.rect ? or viewport ?
  gl.enable(gl.BLEND);
  gl.blendEquation(gl.FUNC_ADD);
  gl.blendFunc(gl.ZERO, gl.ONE_MINUS_SRC_ALPHA);
  drawTexture(webgl, mask, mx, my);
  gl.disable(gl.BLEND);
  unbindFrameBufferAndTexture(webgl);
}

function drawTexture(webgl: WebGLResources, texture: Texture, x: number, y: number) {
  const { gl, batch, drawingTransform, width, height } = webgl;
  const shader = getShader(webgl, 'basic');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, drawingTransform);
  bindTexture(gl, 0, texture);
  pushQuad(batch, x, y, width, height, 0, 0, width / texture.width, height / texture.height, 0, 0, 1, 1, 1, 1);
  flushBatch(batch);
  unbindTexture(gl, 0);
}

function drawLayerTexture(webgl: WebGLResources, layer: Layer) {
  const { gl, batch, drawingTransform } = webgl;
  const shader = getShader(webgl, 'basic');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, drawingTransform);
  bindTexture(gl, 0, layer.texture!);

  const { x, y, w, h } = layer.rect;
  const tw = layer.texture!.width;
  const th = layer.texture!.height;
  pushQuad(batch, x, y, w, h, (x - layer.textureX) / tw, (y - layer.textureY) / th, w / tw, h / th, 0, 0, 1, 1, 1, 1);

  flushBatch(batch);
  unbindTexture(gl, 0);
}

// TODO: combine mask* shaders
function drawMasked(
  webgl: WebGLResources, texture: Texture, mask: Texture, reverse: number, shaderName: 'mask' | 'maskUnpremultiply',
  x: number, y: number, w: number, h: number, tx: number, ty: number, mx: number, my: number
) {
  const { gl, batch, drawingTransform } = webgl;
  const shader = getShader(webgl, shaderName);
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, drawingTransform);
  gl.uniform1f(shader.uniforms.reverse, reverse);
  bindTexture(gl, 0, texture);
  bindTexture(gl, 1, mask);

  pushQuad2(batch, x, y, w, h,
    mx / mask.width, my / mask.height, w / mask.width, h / mask.height, // mask
    tx / texture.width, ty / texture.height, w / texture.width, h / texture.height, // texture
    1, 1, 1, 1);

  flushBatch(batch);
  unbindTexture(gl, 1);
  unbindTexture(gl, 0);
}

function commitTool(webgl: WebGLResources, user: User, layer: Layer, lockOpacity: boolean) {
  const { surface } = user;
  const selection = surface.ignoreSelection ? emptySelection : user.selection;

  if (surface.mode === CompositeOp.None) throw new Error('Invalid surface operation');
  if (!surface.texture) throw new Error('Missing surface texture');
  if (DEVELOPMENT && layer.owner !== user) {
    throw new Error(`Commiting tool to layer with different owner (layer: ${layer.name}, user: ${user.name}, owner: ${layer.owner?.name})`);
  }

  const { gl, batch, width, height, emptyTexture, drawingTransform } = webgl;
  const bounds = getSurfaceBounds(surface);
  clipRect(bounds, 0, 0, width, height);

  if (surface.mode !== CompositeOp.Move && !isMaskEmpty(selection)) {
    copyRect(bounds, rectMaskIntersectionBoundsInt(bounds, selection));
  }

  if (surface.mode === CompositeOp.Draw && lockOpacity) {
    intersectRect(bounds, layer.rect);
  }

  const canSkip = isRectEmpty(bounds) ||
    (surface.mode === CompositeOp.Erase && isRectEmpty(layer.rect)) ||
    (surface.mode === CompositeOp.Erase && !haveNonEmptyIntersection(bounds, layer.rect));

  if (!canSkip) {
    const canDrawFast = USE_FAST_DRAWING &&
      !surface.textureMask && !surface.canvasMask && // TODO: shader
      isMaskEmpty(selection); // TODO: shader

    const rect = cloneRect(layer.rect);

    if (surface.mode !== CompositeOp.Erase && !(surface.mode === CompositeOp.Draw && lockOpacity)) {
      addRect(rect, bounds);
    }

    if (canDrawFast) {
      ensureLayerTexture(webgl, layer, rect);
      bindFrameBufferAndTexture(webgl, layer.texture!);
      gl.enable(gl.SCISSOR_TEST);
      gl.scissor(bounds.x - layer.textureX, bounds.y - layer.textureY, bounds.w, bounds.h);
      gl.enable(gl.BLEND);
      gl.blendEquation(gl.FUNC_ADD);

      if (surface.mode === CompositeOp.Draw) {
        if (lockOpacity) {
          gl.blendFuncSeparate(gl.DST_ALPHA, gl.ONE_MINUS_SRC_ALPHA, gl.ZERO, gl.ONE);
        } else {
          gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
        }
      } else if (surface.mode === CompositeOp.Erase) {
        gl.blendFunc(gl.ZERO, gl.ONE_MINUS_SRC_ALPHA);
      } else if (surface.mode === CompositeOp.Move) {
        gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
      } else {
        throw new Error('Not implemented');
      }

      const shader = getShader(webgl, surface.mode === CompositeOp.Move ? 'fastMove' : 'fastNormal');
      gl.useProgram(shader.program);
      gl.uniformMatrix4fv(shader.uniforms.transform, false, drawingTransform);
      gl.uniform4fv(shader.uniforms.color, colorToFloats(surfaceColor, surface.color, surface.opacity));
      bindTexture(gl, 0, surface.texture);

      if (surface.mode === CompositeOp.Move) {
        setToolTransform(webgl, surface, shader);
        ensureMipmaps(webgl, surface);
      }

      const x = -layer.textureX;
      const y = -layer.textureY;
      pushQuad(batch, x, y, width, height, 0, 0, width / surface.texture.width, height / surface.texture.height, 0, 0, 1, 1, 1, 1);
      flushBatch(batch);

      unbindTexture(gl, 0);
      gl.disable(gl.SCISSOR_TEST);
      gl.disable(gl.BLEND);
      unbindFrameBufferAndTexture(webgl);
    } else {
      REPORT_SLOW && console.warn('slow (commit)');

      let textureWidth = findTextureWidth(webgl, bounds.w);
      let textureHeight = findTextureWidth(webgl, bounds.h);
      if (IS_IE11) textureWidth = textureHeight = Math.max(textureWidth, textureHeight);

      const temp = getTexture(webgl, textureWidth, textureHeight, 'temp-commit', false);

      bindAndClearBuffer(webgl, temp, transparentColor);
      gl.enable(gl.SCISSOR_TEST);
      gl.scissor(0, 0, bounds.w, bounds.h);
      drawLayer(webgl, emptyTexture, layer, lockOpacity, 1, true, false, bounds.x, bounds.y);
      gl.disable(gl.SCISSOR_TEST);
      unbindFrameBufferAndTexture(webgl);

      ensureLayerTexture(webgl, layer, rect);
      // TODO: there is a bug here when erasing
      copyTextureRect(webgl, temp, layer.texture!, 0, 0, bounds.w, bounds.h, bounds.x - layer.textureX, bounds.y - layer.textureY);
      releaseTexture(webgl, temp);
    }
  }

  if (isRectEmpty(layer.rect)) releaseLayer(webgl, layer);
  layerChanged(layer);
}

function findTextureSize(size: number, drawingTexureSize: number, gl2: boolean) {
  if (DEVELOPMENT && size <= 0) throw new Error(`Invalid texture size: ${size}`);

  // smaller size for tests, because all test drawings are 200x200
  const minSize = Math.min(MIN_TEXTURE_SIZE, drawingTexureSize);

  if (TESTS) {
    return Math.max(Math.min(minSize, 64), findPowerOf2(size));
  } else if (gl2) {
    return Math.max(256, findMultipleOf256(size));
  } else {
    return Math.max(minSize, findPowerOf2(size));
  }
}

function findTextureWidth(webgl: WebGLResources, width: number) {
  return findTextureSize(width, webgl.textureWidth, webgl.webgl2);
}

function findTextureHeight(webgl: WebGLResources, height: number) {
  return findTextureSize(height, webgl.textureHeight, webgl.webgl2);
}

function ensureLayerTexture(webgl: WebGLResources, layer: Layer, rect: Rect) {
  let textureWidth = findTextureWidth(webgl, rect.w);
  let textureHeight = findTextureHeight(webgl, rect.h);
  if (IS_IE11) textureWidth = textureHeight = Math.max(textureWidth, textureHeight);

  if (
    !layer.texture ||
    layer.texture.width !== textureWidth ||
    layer.texture.height !== textureHeight ||
    rect.x < layer.textureX ||
    rect.y < layer.textureY ||
    (rect.x + rect.w) > (layer.textureX + layer.texture.width) ||
    (rect.y + rect.h) > (layer.textureY + layer.texture.height)
  ) {
    const newTexture = getTexture(webgl, textureWidth, textureHeight, `layer-${layer.id}`, true);
    const newTextureX = Math.min(
      Math.max(0, rect.x - Math.floor((newTexture.width - rect.w) / 2)), Math.max(0, webgl.width - newTexture.width));
    const newTextureY = Math.min(
      Math.max(0, rect.y - Math.floor((newTexture.height - rect.h) / 2)), Math.max(0, webgl.height - newTexture.height));

    if (layer.texture && !isRectEmpty(layer.rect) && !isRectEmpty(rect)) {
      const x = Math.max(rect.x, layer.rect.x);
      const y = Math.max(rect.y, layer.rect.y);
      const w = Math.min(rect.x + rect.w, layer.rect.x + layer.rect.w) - x;
      const h = Math.min(rect.y + rect.h, layer.rect.y + layer.rect.h) - y;

      if (w > 0 && h > 0) {
        copyTextureRect(webgl, layer.texture, newTexture,
          x - layer.textureX, y - layer.textureY, w, h, x - newTextureX, y - newTextureY);
      }
    }

    releaseTexture(webgl, layer.texture);
    layer.texture = newTexture;
    layer.textureX = newTextureX;
    layer.textureY = newTextureY;
  }

  copyRect(layer.rect, rect);
}

function releaseLayer(webgl: WebGLResources, layer: Layer) {
  layer.texture = releaseTexture(webgl, layer.texture);
  layer.textureX = 0;
  layer.textureY = 0;
  resetRect(layer.rect);
  layerChanged(layer);
}

export function getTexture(
  webgl: WebGLResources, width: number, height: number, id: string, clear = true, format = TextureFormat.RGBA
): Texture {
  const { gl, textures } = webgl;
  let texture: Texture | undefined = undefined;

  if (DEVELOPMENT && gl.getParameter(gl.FRAMEBUFFER_BINDING) && clear) {
    throw new Error('Getting texture while frame buffer is bound');
  }

  if (textures.length) {
    // prefer using last one returned to pool, because it's most likely to still be in memory
    for (let i = textures.length - 1; i >= 0; i--) {
      if (textures[i].width === width && textures[i].height === height && textures[i].format === format) {
        texture = textures[i];
        removeAtFast(textures, i);
        break;
      }
    }

    if (!texture && textures.length >= TEXTURE_POOL_SIZE) {
      // TODO: pick closest in size ?
      texture = textures.shift()!; // pick oldest one
      resizeTexture(gl, texture, width, height, format);
    }

    if (texture && clear) clearTexture(webgl, texture);
  }

  if (!texture) {
    texture = createEmptyTexture(gl, width, height, undefined, format);
  }

  texture.id = id;

  if (DEVELOPMENT) webgl.allocatedTextures.push(texture);

  return texture;
}

export function releaseTexture(webgl: WebGLResources | undefined, texture: Texture | undefined): undefined {
  if (webgl && texture) {
    const { gl, textures } = webgl;

    if ((gl as any).webglId !== texture.webglId) {
      if (DEVELOPMENT) throw new Error(`Releasing texture from different context`);
      logAction(`releasing texture from different context`);
      return undefined;
    }

    if (DEVELOPMENT) {
      const oldHandle = gl.getParameter(gl.TEXTURE_BINDING_2D);
      if (oldHandle === texture.handle) throw new Error('Releasing texture that is still bound');
      gl.bindTexture(gl.TEXTURE_2D, texture.handle);
      if (gl.getTexParameter(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER) !== gl.NEAREST) {
        throw new Error('Releasing texture with TEXTURE_MAG_FILTER not reset to NEAREST');
      }
      if (gl.getTexParameter(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER) !== gl.NEAREST) {
        throw new Error('Releasing texture with TEXTURE_MIN_FILTER not reset to NEAREST');
      }
      if (gl.getTexParameter(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S) !== gl.CLAMP_TO_EDGE) {
        throw new Error('Releasing texture with TEXTURE_WRAP_S not reset to CLAMP_TO_EDGE');
      }
      if (gl.getTexParameter(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T) !== gl.CLAMP_TO_EDGE) {
        throw new Error('Releasing texture with TEXTURE_WRAP_T not reset to CLAMP_TO_EDGE');
      }
      gl.bindTexture(gl.TEXTURE_2D, oldHandle);
      removeItem(webgl.allocatedTextures, texture);
    }

    if (textures.length < TEXTURE_POOL_SIZE) {
      textures.push(texture);
    } else {
      deleteTexture(gl, texture);
    }
  }

  return undefined;
}

function releaseSurfaceTexture(webgl: WebGLResources | undefined, surface: ToolSurface) {
  // reset filters in case it was switched to linear for transformed surfaces
  if (webgl && surface.texture && surface.textureIsLinear) {
    const { gl } = webgl;
    gl.bindTexture(gl.TEXTURE_2D, surface.texture.handle);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.bindTexture(gl.TEXTURE_2D, null);
  }

  surface.texture = releaseTexture(webgl, surface.texture);
}

function releaseSurface(webgl: WebGLResources | undefined, surface: ToolSurface) {
  releaseSurfaceTexture(webgl, surface);
  surface.textureMask = releaseTexture(webgl, surface.textureMask);
  surface.textureIsLinear = false;
  surface.textureHasMipmaps = false;
  surface.canvasMask = undefined; // TODO: release
  resetSurface(surface);
}

function getLayerSnapshot(webgl: WebGLResources, layer: Layer, selectionMask?: Mask, outBounds?: Rect) {
  const { gl, width, height, emptyTexture } = webgl;
  const selection = layer.owner?.surface.ignoreSelection ? undefined : selectionMask;

  const layerRect = getLayerRect(layer);
  clipRect(layerRect, 0, 0, width, height);
  const bounds = selection ? rectsIntersection(selection.bounds, layerRect) : createRect(0, 0, width, height);
  clipRect(bounds, 0, 0, width, height);

  if (isRectEmpty(bounds)) return undefined;
  if (!layerHasNonEmptyToolSurface(layer) && !layer.texture) return undefined;

  const canvas = createCanvas(bounds.w, bounds.h);
  const context = getContext2d(canvas);
  const data = context.createImageData(canvas.width, canvas.height);

  const smallSelection = selection;
  const x = smallSelection ? bounds.x : 0;
  const y = smallSelection ? bounds.y : 0;
  const w = smallSelection ? bounds.w : width;
  const h = smallSelection ? bounds.h : height;
  let textureWidth = smallSelection ? findTextureWidth(webgl, bounds.w) : webgl.textureWidth;
  let textureHeight = smallSelection ? findTextureHeight(webgl, bounds.h) : webgl.textureHeight;
  if (IS_IE11) textureWidth = textureHeight = Math.max(textureWidth, textureHeight);

  let temp = getTexture(webgl, textureWidth, textureHeight, 'temp-layer-snapshot', false);
  bindAndClearBuffer(webgl, temp, transparentColor);
  drawLayer(webgl, emptyTexture, layer, layer.opacityLocked, 1, true, false, x, y, textureWidth, textureHeight);

  if (selection) {
    const mask = createMaskTexture(webgl, selection, x, y, textureWidth, textureHeight);
    const temp2 = getTexture(webgl, textureWidth, textureHeight, 'temp2-layer-snapshot', false);
    bindAndClearBuffer(webgl, temp2, transparentColor);
    drawMasked(webgl, temp, mask, 0, 'maskUnpremultiply', 0, 0, w, h, 0, 0, 0, 0);
    releaseTexture(webgl, temp);
    temp = temp2;
    outBounds && copyRect(outBounds, bounds);
    gl.readPixels(bounds.x - x, bounds.y - y, bounds.w, bounds.h, gl.RGBA, gl.UNSIGNED_BYTE, toUint8(data.data));
    unbindFrameBufferAndTexture(webgl);
  } else {
    unbindFrameBufferAndTexture(webgl);
    outBounds && setRect(outBounds, 0, 0, bounds.w, bounds.h);
    readPixels(webgl, temp, toUint8(data.data), 0, 0, bounds.w, bounds.h);
  }

  releaseTexture(webgl, temp);
  context.putImageData(data, 0, 0);

  if (TESTS) (canvas as any).__raw = { width: canvas.width, height: canvas.height, data: toUint8(data.data) };

  return canvas;
}

function createFakeCanvas(webgl: WebGLResources, layer: Layer, rect: Rect): HTMLCanvasElement {
  function getImageDataInner(x: number, y: number, w: number, h: number) {
    let textureWidth = findTextureWidth(webgl, w);
    let textureHeight = findTextureHeight(webgl, h);
    if (IS_IE11) textureWidth = textureHeight = Math.max(textureWidth, textureHeight);

    const temp = getTexture(webgl, textureWidth, textureHeight, 'temp-fake', false);
    bindAndClearBuffer(webgl, temp, transparentColor);
    drawLayer(webgl, webgl.emptyTexture, layer, false, 1, true, false, (x + rect.x), (y + rect.y), textureWidth, textureHeight);
    unbindFrameBufferAndTexture(webgl);
    const result = getImageData(webgl, temp, 0, 0, w, h);
    releaseTexture(webgl, temp);
    return result;
  }

  const canvas: any = { width: rect.w, height: rect.h };
  canvas.getContext = () => ({ getImageData: getImageDataInner, canvas });
  return canvas;
}

function readPixels(webgl: WebGLResources, texture: Texture, data: Uint8Array, x: number, y: number, w: number, h: number) {
  const { gl, batch, drawingTransform } = webgl;

  let textureWidth = findTextureWidth(webgl, w);
  let textureHeight = findTextureHeight(webgl, h);
  if (IS_IE11) textureWidth = textureHeight = Math.max(textureWidth, textureHeight);

  const temp = getTexture(webgl, textureWidth, textureHeight, 'temp-read-pixels');
  bindFrameBufferAndTexture(webgl, temp);

  // TODO: use drawTexture ?
  const shader = getShader(webgl, 'unpremultiply');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, drawingTransform);
  bindTexture(gl, 0, texture);
  pushQuad(batch, 0, 0, w, h, x / texture.width, y / texture.height, w / texture.width, h / texture.height, 0, 0, 1, 1, 1, 1);
  flushBatch(batch);
  unbindTexture(gl, 0);
  gl.readPixels(0, 0, w, h, gl.RGBA, gl.UNSIGNED_BYTE, data);
  unbindFrameBufferAndTexture(webgl);
  releaseTexture(webgl, temp);
}

function getImageData(webgl: WebGLResources, texture: Texture, x: number, y: number, w: number, h: number): ImageData {
  const data = allocUint8ClampedArray(w, h);
  readPixels(webgl, texture, toUint8(data), x, y, w, h);
  return { width: w, height: h, data, colorSpace: 'srgb' };
}

function allocUint8ClampedArray(width: number, height: number) {
  try {
    return new Uint8ClampedArray(width * height * 4);
  } catch (e) {
    throw new Error(`Failed to allocate buffer [${width}x${height}] (${e.stack || e.message || e})`);
  }
}

function ensureSurfaceWithSize(webgl: WebGLResources, user: User, minWidth: number, minHeight: number) {
  const width = Math.max(webgl.textureWidth, findTextureWidth(webgl, minWidth));
  const height = Math.max(webgl.textureHeight, findTextureHeight(webgl, minHeight));
  ensureSurface(webgl, user.surface, width, height, user.localId);
}

function ensureSurface(webgl: WebGLResources, surface: ToolSurface, width: number, height: number, localId: number) {
  if (surface.texture) {
    if (surface.texture.width === width && surface.texture.height === height) {
      clearTexture(webgl, surface.texture);
    } else { // we need different texture size (this can happen when pasting image larger than canvas)
      releaseSurfaceTexture(webgl, surface);
    }
  }

  if (!surface.texture) {
    surface.texture = getTexture(webgl, width, height, `tool-${localId}`);
    surface.textureIsLinear = false;
  }

  surface.textureMask = releaseTexture(webgl, surface.textureMask);
  surface.textureHasMipmaps = false;
  surface.canvasMask = undefined; // TODO: release
}

export function premultiply(data: Uint8Array | Uint8ClampedArray) {
  const t = 1 / 255;

  for (let i = 0; i < data.byteLength; i += 4) {
    const a = data[i + 3] * t;
    data[i + 0] = Math.round(data[i + 0] * a);
    data[i + 1] = Math.round(data[i + 1] * a);
    data[i + 2] = Math.round(data[i + 2] * a);
  }
}

export function premultiplyColor(array: Float32Array) {
  array[0] *= array[3];
  array[1] *= array[3];
  array[2] *= array[3];
  return array;
}

export function unpremultiply(data: Uint8Array | Uint8ClampedArray) {
  for (let i = 0; i < data.byteLength; i += 4) {
    let a = data[i + 3];
    a = a ? (255 / a) : 0;
    data[i + 0] = Math.round(data[i + 0] * a);
    data[i + 1] = Math.round(data[i + 1] * a);
    data[i + 2] = Math.round(data[i + 2] * a);
  }
}

export function getTempCanvas({ tempCanvas }: WebGLResources, width: number, height: number, clear: boolean) {
  if (SERVER) return createCanvas(width, height); // avoid issues with sharing canvas between users

  if (tempCanvas.width !== width || tempCanvas.height !== height || clear) {
    tempCanvas.width = width;
    tempCanvas.height = height;
  }

  return tempCanvas;
}

export function drawUsingCanvas(
  webgl: WebGLResources, target: Texture, x: number, y: number, w: number, h: number, pad: number,
  draw: (context: CanvasRenderingContext2D, x0: number, y0: number) => void
) {
  // limited because of issues on iOS
  const sizeLimit = SERVER ? 8192 : ((isSafari || isiOS) ? 2048 : 4096);

  const x0 = clamp(Math.floor(Math.min(x, x + w) - pad), 0, target.width);
  const y0 = clamp(Math.floor(Math.min(y, y + h) - pad), 0, target.height);
  const x1 = clamp(Math.ceil(Math.max(x, x + w) + pad), 0, target.width);
  const y1 = clamp(Math.ceil(Math.max(y, y + h) + pad), 0, target.height);

  for (let oy = y0; oy < y1; oy += Math.min(y1 - oy, sizeLimit)) {
    for (let ox = x0; ox < x1; ox += Math.min(x1 - ox, sizeLimit)) {
      const canvasWidth = Math.min(x1 - ox, sizeLimit);
      const canvasHeight = Math.min(y1 - oy, sizeLimit);
      const tempCanvas = getTempCanvas(webgl, canvasWidth, canvasHeight, true);
      const context = getContext2d(tempCanvas);
      context.save();
      draw(context, ox, oy);
      context.restore();
      copyCanvasToTextureAt(webgl.gl, context, target, ox, oy, true);
    }
  }
}

// drawing thumb

function pingThumb(webgl: WebGLResources, drawing: Drawing) {
  const { gl, webgl2 } = webgl;
  const gl2 = gl as WebGL2RenderingContext;

  if (webgl.pendingDrawingThumb && webgl2) {
    const { id, sync, rect, buffer } = webgl.pendingDrawingThumb;

    if (drawing.id === id) {
      const status = gl2.getSyncParameter(sync, gl2.SYNC_STATUS);
      if (status !== gl2.SIGNALED) return;

      const scale = Math.min(SEQUENCE_THUMB_WIDTH / drawing.width, SEQUENCE_THUMB_HEIGHT / drawing.height);
      const thumbWidth = Math.ceil(drawing.width * scale);
      const thumbHeight = Math.ceil(drawing.height * scale);
      const thumbRect = rect!;
      const data = new Uint8Array(thumbRect.w * thumbRect.h * 4);
      gl.bindBuffer(gl2.PIXEL_PACK_BUFFER, buffer);
      gl2.getBufferSubData(gl2.PIXEL_PACK_BUFFER, 0, data);
      gl.bindBuffer(gl2.PIXEL_PACK_BUFFER, null);

      // no need to unpremultiply because we always put white background behind thumbnail
      drawing.thumbUpdate = { data, rect: thumbRect, width: thumbWidth, height: thumbHeight };
    }

    deleteBuffer(gl, buffer);
    webgl.pendingDrawingThumb = undefined;
  }
}

function discardThumb(webgl: WebGLResources) {
  const { gl, webgl2 } = webgl;

  if (webgl.pendingDrawingThumb && webgl2) {
    deleteBuffer(gl, webgl.pendingDrawingThumb.buffer);
    webgl.pendingDrawingThumb = undefined;
  }
}

function drawThumb(webgl: WebGLResources, drawing: Drawing, rect: Rect) {
  const { gl, webgl2, drawingTexture, batch } = webgl;
  const gl2 = gl as WebGL2RenderingContext;

  if (webgl.pendingDrawingThumb) {
    deleteBuffer(gl, webgl.pendingDrawingThumb.buffer);
    webgl.pendingDrawingThumb = undefined;
  }

  const scale = Math.min(SEQUENCE_THUMB_WIDTH / drawing.width, SEQUENCE_THUMB_HEIGHT / drawing.height);
  const thumbWidth = Math.ceil(drawing.width * scale);
  const thumbHeight = Math.ceil(drawing.height * scale);

  // outset and add 1px border to avoid edge issues
  const x1 = Math.max(0, Math.floor(rect.x * scale) - 1);
  const x2 = Math.min(thumbWidth, Math.ceil((rect.x + rect.w) * scale) + 1);
  const y1 = Math.max(0, Math.floor(rect.y * scale) - 1);
  const y2 = Math.min(thumbHeight, Math.ceil((rect.y + rect.h) * scale) + 1);
  const thumbRect = createRect(x1, y1, x2 - x1, y2 - y1);

  const texture = getTexture(webgl, SEQUENCE_THUMB_SIZE, SEQUENCE_THUMB_SIZE, 'thumb', false);
  bindAndClearBuffer(webgl, texture, parseDrawingBackground(drawing.background || 'white'));
  gl.viewport(0, 0, texture.width, texture.height);

  gl.enable(gl.BLEND);
  gl.blendEquation(gl.FUNC_ADD);
  gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);

  const shader = getShader(webgl, 'basic');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, mat4Identity);

  bindTexture(gl, 0, drawingTexture);

  if (scale < 1) {
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
  }

  const w = thumbWidth / texture.width;
  const h = thumbHeight / texture.height;
  const tw = drawing.width / drawingTexture.width;
  const th = drawing.height / drawingTexture.height;
  pushQuad(batch, -1, -1, 2 * w, 2 * h, 0, 0, tw, th, 0, 0, 1, 1, 1, 1);
  flushBatch(batch);

  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
  unbindTexture(gl, 0);

  gl.disable(gl.BLEND);

  if (webgl2) {
    const buffer = allocBuffer(gl);
    gl2.bindBuffer(gl2.PIXEL_PACK_BUFFER, buffer);
    gl2.bufferData(gl2.PIXEL_PACK_BUFFER, thumbRect.w * thumbRect.h * 4, gl2.STATIC_READ);
    gl2.readPixels(thumbRect.x, thumbRect.y, thumbRect.w, thumbRect.h, gl.RGBA, gl.UNSIGNED_BYTE, 0);
    gl2.bindBuffer(gl2.PIXEL_PACK_BUFFER, null);
    const sync = gl2.fenceSync(gl2.SYNC_GPU_COMMANDS_COMPLETE, 0);

    if (sync) {
      webgl.pendingDrawingThumb = { id: drawing.id, sync, buffer, rect: thumbRect };
    } else if (DEVELOPMENT) {
      console.error(`Failed to create gpu sync`);
    }
  } else {
    const data = new Uint8Array(thumbRect.w * thumbRect.h * 4);
    gl.readPixels(0, 0, thumbRect.w, thumbRect.h, gl.RGBA, gl.UNSIGNED_BYTE, data);
    unpremultiply(data);
    drawing.thumbUpdate = { data, rect: thumbRect, width: thumbWidth, height: thumbHeight };
  }

  unbindFrameBufferAndTexture(webgl);
  releaseTexture(webgl, texture);
}

function drawScaled(webgl: WebGLResources, drawingTexture: Texture, scaledWidth: number, scaledHeight: number, x: number, y: number, width: number, height: number, output: CanvasRenderingContext2D) {
  const { gl, batch } = webgl;

  const texture = getTexture(webgl, scaledWidth, scaledHeight, 'scaled', false);
  bindAndClearBuffer(webgl, texture, transparentColor);
  gl.viewport(0, 0, texture.width, texture.height);

  const shader = getShader(webgl, 'basic');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, mat4Identity);

  const w = scaledWidth / texture.width;
  const h = scaledHeight / texture.height;
  const tw = width / drawingTexture.width;
  const th = height / drawingTexture.height;
  const tx = -(x / drawingTexture.width);
  const ty = -(y / drawingTexture.height);

  bindTexture(gl, 0, drawingTexture);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
  gl.generateMipmap(gl.TEXTURE_2D);
  pushQuad(batch, -1, -1, 2 * w, 2 * h, tx, ty, tw, th, 0, 0, 1, 1, 1, 1);
  flushBatch(batch);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
  unbindTexture(gl, 0);

  const d = output.createImageData(scaledWidth, scaledHeight);
  gl.readPixels(0, 0, scaledWidth, scaledHeight, gl.RGBA, gl.UNSIGNED_BYTE, d.data);
  output.putImageData(d, 0, 0);

  unbindFrameBufferAndTexture(webgl);
  releaseTexture(webgl, texture);
}
