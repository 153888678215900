import { ToolSurface, User } from './interfaces';
import { maskContainsXY } from './mask';
import { createMat2d, invertMat2d } from './mat2d';
import { hasZeroTransform } from './toolSurface';
import { createVec2FromValues, transformVec2ByMat2d } from './vec2';

export function pointToSurface(surface: ToolSurface, x: number, y: number) {
  if (hasZeroTransform(surface)) {
    return createVec2FromValues(Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY);
  }

  const vec = createVec2FromValues(x, y);
  const mat = createMat2d();
  invertMat2d(mat, surface.transform);
  transformVec2ByMat2d(vec, vec, mat);
  return vec;
}

export function userSelectionContainsPoint(user: User, x: number, y: number) {
  if (hasZeroTransform(user.surface)) return false;
  const vec = pointToSurface(user.surface, x, y);
  return maskContainsXY(user.selection, vec[0], vec[1]);
}
