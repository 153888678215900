import { IToolEditor, IToolModel, ToolId } from '../interfaces';
import { BaseBrushTool } from './baseBrushTool';
import { faPaintBrush } from '../icons';
import { DEFAULT_STABILIZE } from '../constants';

export class BrushTool extends BaseBrushTool {
  id = ToolId.Brush;
  name = 'Brush';
  description = 'Paint using a pre-defined or customised brush';
  learnMore = 'https://help.magma.com/en/articles/6871478-brush';
  video = { url: '/assets/videos/brush.mp4', width: 374, height: 210 };
  icon = faPaintBrush;
  stabilize = DEFAULT_STABILIZE;
  constructor(public editor: IToolEditor, public model: IToolModel) {
    super(editor, model);
  }
}
