import { ICanvasProvider, Drawing, IErrorReporter } from '../common/interfaces';
import { Renderer } from './renderer';
import { WebGLRenderer } from './webglRenderer';
import { logAction } from '../common/actionLog';
import { createCanvas, createCanvasGL } from '../common/canvasUtils';
import { storageGetItem } from './storage';

const IS_IE11 = typeof navigator !== 'undefined' && /Trident\/7/.test(navigator.userAgent);

export function isUsingWebGL() {
  // TODO: force always using webgl on iOS, application will crash on iOS devices otherwise ?
  // disabled for IE11 because it's still broken on webgl and I don't know why
  return !SERVER && storageGetItem('webgl') !== 'no' && !IS_IE11;
}

export let usingWebGL = isUsingWebGL();
export let failedWebGL = false;

export function createRenderer(id: string, canvasProvider: ICanvasProvider, drawing: Drawing, errorReporter: IErrorReporter) {
  let error: string | undefined = undefined;

  try {
    if (usingWebGL) {
      const renderer = new WebGLRenderer(id, errorReporter);
      renderer.init(drawing, createCanvasGL(100, 100));
      return { renderer };
    }
  } catch (e) {
    logAction(`Failed to create WebGLRenderer: ${e.message}`);
    DEVELOPMENT && console.error(e);
    error = e.message;
    usingWebGL = false;
    failedWebGL = true;
  }

  const renderer = new Renderer(id, failedWebGL, canvasProvider, errorReporter);
  renderer.init(drawing, createCanvas(100, 100));
  return { renderer, error };
}
