import { Component, ChangeDetectionStrategy, Output, Input, EventEmitter } from '@angular/core';

let id = 1;

@Component({
  selector: 'custom-checkbox',
  templateUrl: 'custom-checkbox.pug',
  styleUrls: ['custom-checkbox.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCheckbox {
  @Input() disabled = false;
  @Input() help = '';
  @Input() alert = '';
  @Input() checked = false;
  @Output() checkedChange = new EventEmitter<boolean>();
  helpId = `custom-checkbox-help-${++id}`;
}
