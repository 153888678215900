import { Rect, IRenderer, HistoryBuffer, HistoryBufferTarget, HistoryBufferEntry, HistoryBufferSheet, HistoryStats } from './interfaces';
import { removeItem } from './baseUtils';
import { isRectEmpty } from './rect';

export function pushHistoryBufferEntry(
  buffer: HistoryBuffer, renderer: IRenderer, target: HistoryBufferTarget, rect: Rect
): HistoryBufferEntry | undefined {
  const src = target.canvas ?? target.texture;

  if (!src || isRectEmpty(rect)) return undefined;

  const { sheets } = buffer;
  let entry = sheets.length ? addToSheet(buffer, sheets[sheets.length - 1], rect) : undefined;

  if (!entry) {
    const surface = renderer.createSurface('historyBuffer', rect.w, rect.h);
    const sheet: HistoryBufferSheet = { left: 0, bottom: 0, top: 0, entries: [], surface };
    sheets.push(sheet);
    entry = addToSheet(buffer, sheet, rect);
  }

  if (!entry) throw new Error(`Region exceeded sheet size (${rect.x}, ${rect.y}, ${rect.w}, ${rect.h})`);

  renderer.copyToSnapshot(src, entry.sheet.surface,
    rect.x - target.textureX, rect.y - target.textureY, rect.w, rect.h, entry.x, entry.y);

  return entry;
}

export function removeHistoryBufferEntry(entry: HistoryBufferEntry | undefined, renderer: IRenderer) {
  if (entry) {
    const { sheets } = entry.buffer;

    if (removeFromSheet(entry.sheet, entry)) {
      removeItem(sheets, entry.sheet);
      renderer.releaseSurface(entry.sheet.surface);
    }
  }
}

export function historyBufferStats({ sheets }: HistoryBuffer, stats: HistoryStats) {
  stats.canvases += sheets.length;

  for (const s of sheets) {
    stats.total += s.surface.width * s.surface.height;

    for (const e of s.entries) {
      stats.used += e.rect.w * e.rect.h;
    }
  }
}

function addToSheet(buffer: HistoryBuffer, sheet: HistoryBufferSheet, { x, y, w, h }: Rect) {
  let entry: HistoryBufferEntry | undefined;

  if (w <= sheet.surface.width && h <= sheet.surface.height) {
    if ((sheet.surface.width - sheet.left) >= w && (sheet.surface.height - sheet.top) >= h) {
      entry = { buffer, sheet, x: sheet.left, y: sheet.top, rect: { x, y, w, h } };
      sheet.entries.push(entry);
      sheet.left += w;
      sheet.bottom = Math.max(sheet.bottom, sheet.top + h);
    } else if ((sheet.surface.height - sheet.bottom) >= h) {
      entry = { buffer, sheet, x: 0, y: sheet.bottom, rect: { x, y, w, h } };
      sheet.entries.push(entry);
      sheet.top = sheet.bottom;
      sheet.bottom = sheet.top + h;
      sheet.left = w;
    }
  }

  return entry;
}

function removeFromSheet(sheet: HistoryBufferSheet, entry: HistoryBufferEntry) {
  const wasLast = sheet.entries[sheet.entries.length - 1] === entry;
  removeItem(sheet.entries, entry);

  if (wasLast) {
    sheet.left = 0;
    sheet.top = 0;
    sheet.bottom = 0;

    for (const e of sheet.entries) {
      sheet.left = e.x + e.rect.w;
      sheet.top = e.y;
      sheet.bottom = Math.max(sheet.bottom, e.y + e.rect.h);
    }
  }

  return !sheet.entries.length;
}
