import { getSurfaceBounds, isSurfaceEmpty } from './toolSurface';
import { Layer } from './interfaces';
import { layerHasImageData } from './layer';
import { isRectEmpty, addRect } from './rect';

export function isLayerEmpty(layer: Layer) {
  // TEMP
  if (DEVELOPMENT && !isRectEmpty(layer.rect) && !layerHasImageData(layer)) {
    throw new Error('Non-Empty layer missing image data');
  }

  const owner = layer.owner;
  return (!layerHasImageData(layer) || isRectEmpty(layer.rect))
    && (!owner || owner.surface.layer !== layer || isSurfaceEmpty(owner.surface));
}

export function layerHasNonEmptyToolSurface(layer: Layer) {
  const surface = getLayerSurface(layer);
  return surface !== undefined && !isSurfaceEmpty(surface);
}

export function getLayerSurface(layer: Layer) {
  const owner = layer.owner;
  return owner?.surface?.layer === layer ? owner.surface : undefined;
}

export function getLayerRect(layer: Layer) {
  const surface = getLayerSurface(layer);

  if (surface && !isSurfaceEmpty(surface)) {
    return addRect(getSurfaceBounds(surface), layer.rect);
  } else {
    return layer.rect;
  }
}
