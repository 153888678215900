import { addPolySegmentPoint, createPolySegment, createPoly } from './poly';
import { Poly } from './interfaces';

interface PolySerializer {
  data: number[];
  temp: number;
  offset: number;
}

interface PolyDeserializer {
  temp: number;
  bits: number;
  index: number;
  data: number[];
}

// this only serializes pixel aligned polygons, without diagonal lines
// this also can't handle 2 identical points right after each other
export function serializePoly(poly: Poly | undefined): number[] | undefined {
  if (!poly) return undefined;

  // TODO: serialize to Uint8Array
  // TODO: skip invalid points / segments
  // TODO: cleanup stage ? (remove duplicate points, remove sequences with size < 4)

  const serializer = createPolySerializer();

  writeIntVar(serializer, poly.length);

  for (const { items, size } of poly) {
    const size2 = size << 1;
    let lastX = items[0] | 0;
    let lastY = items[1] | 0;
    let lastDX = 0;
    let lastDY = 0;
    let step = 0;

    writeIntVar(serializer, size);

    if (size === 0) continue;

    writeIntVar(serializer, lastX);
    writeIntVar(serializer, lastY);

    for (let j = 2; j < size2; j += 2, step++) {
      const tx = items[j];
      const ty = items[j + 1];

      // TODO: check if point is the same as last one, and ignore ?

      if (j === 2 && ty !== lastY) {
        writeIntVar(serializer, 0);
        step++;
      }

      if (step % 2) {
        const dy = ty - lastY;
        writeIntVar(serializer, dy === lastDY ? 0 : dy);
        lastY = ty;
        lastDY = dy;
      } else {
        const dx = tx - lastX;
        writeIntVar(serializer, dx === lastDX ? 0 : dx);
        lastX = tx;
        lastDX = dx;
      }
    }
  }

  return getData(serializer);
}

function createPolySerializer(): PolySerializer {
  return { data: [], offset: 0, temp: 0 >>> 0 };
}

function flush(serializer: PolySerializer) {
  for (; serializer.offset >= 8; serializer.offset -= 8) {
    serializer.data.push((serializer.temp >>> 24) & 0xff);
    serializer.temp = (serializer.temp & 0x00ffffff) << 8;
  }
}

function push(serializer: PolySerializer, v: number, offset: number) {
  serializer.temp |= v >>> serializer.offset;
  serializer.offset += offset;
}

function writeIntVar(serializer: PolySerializer, value: number) {
  if (value === 0) {
    // write bit
    push(serializer, (value & 1) << (31 - serializer.offset), 1);
  } else if (value <= 0x7 && value > -0x8) {
    // write int 4
    if (value < 0) value += 0x10;
    push(serializer, ((value & 0x0f) | 0x20) << 26, 6);
  } else if (value <= 0x1f && value > -0x20) {
    // write int 6
    if (value < 0) value += 0x40;
    push(serializer, ((value & 0x003f) | 0x0180) << 23, 9);
  } else if (value <= 0x07ff && value > -0x0800) {
    // write int 11
    if (value < 0) value += 0x1000;
    push(serializer, ((value & 0x0fff) | 0xe000) << 16, 16);
  } else if (value <= 0x07ffff && value > -0x080000) {
    // write int 20
    if (value < 0) value += 0x100000;
    push(serializer, ((value & 0x0fffff) | 0xf00000) << 8, 24);
  } else {
    throw new Error(`Value out of range: ${value}`);
  }

  flush(serializer);
}

function getData(serializer: PolySerializer) {
  if (serializer.offset) {
    serializer.offset = 8;
  }

  flush(serializer);
  return serializer.data;
}

export function deserializePoly(data: number[] | undefined): Poly | undefined {
  if (!data) return undefined;

  const deserializer = createPolyDeserializer(data);
  const result = createPoly();
  const length = readIntVar(deserializer);

  for (let i = 0; i < length; i++) {
    let step = 0;
    const size = readIntVar(deserializer);
    const s = createPolySegment(size);
    result.push(s);

    if (size === 0) continue;

    let lastX = readIntVar(deserializer);
    let lastY = readIntVar(deserializer);
    let lastDX = 0;
    let lastDY = 0;

    addPolySegmentPoint(s, lastX, lastY);

    for (let j = 1; j < size; j++, step++) {
      let d = readIntVar(deserializer);

      if (j === 1 && d === 0) {
        d = readIntVar(deserializer);
        step++;
      }

      if (d === 0) {
        d = step % 2 ? lastDY : lastDX;
      }

      addPolySegmentPoint(s, step % 2 ? lastX : (lastX + d), step % 2 ? (lastY + d) : lastY);

      if (step % 2) {
        lastY += d;
        lastDY = d;
      } else {
        lastX += d;
        lastDX = d;
      }
    }
  }

  return result;
}

function createPolyDeserializer(data: number[]): PolyDeserializer {
  return { temp: 0, bits: 0, index: 0, data };
}

function load(deserializer: PolyDeserializer) {
  while (deserializer.bits <= 24 && deserializer.index < deserializer.data.length) {
    deserializer.temp |= deserializer.data[deserializer.index] << (24 - deserializer.bits);
    deserializer.bits += 8;
    deserializer.index++;
  }
}

function shift(deserializer: PolyDeserializer, bits: number) {
  deserializer.temp = deserializer.temp << bits;
  deserializer.bits -= bits;
}

function readIntVar(deserializer: PolyDeserializer): number {
  load(deserializer);

  const bits = deserializer.temp >>> 28;
  let v = 0;

  if ((bits & 0x8) === 0) {
    shift(deserializer, 1);
  } else if ((bits & 0xc) === 0x8) {
    v = (deserializer.temp >>> 26) & 0x0f;
    shift(deserializer, 6);
    v = v > 0x07 ? v - 0x10 : v;
  } else if ((bits & 0xe) === 0xc) {
    v = (deserializer.temp >>> 23) & 0x3f;
    shift(deserializer, 9);
    v = v > 0x1f ? v - 0x40 : v;
  } else if ((bits & 0xf) === 0xe) {
    v = (deserializer.temp >>> 16) & 0x0fff;
    shift(deserializer, 16);
    v = v > 0x07ff ? v - 0x1000 : v;
  } else if (bits === 0xf) {
    v = (deserializer.temp >>> 8) & 0x0fffff;
    shift(deserializer, 24);
    v = v > 0x7ffff ? v - 0x100000 : v;
  } else {
    throw new Error(`Invalid integer: ${bits.toString(2)}`);
  }

  return v;
}
