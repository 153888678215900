import { Rect } from './interfaces';
import { createRect } from './rect';
import { getR, getG, getB, getAlpha } from './color';

const { min, max, abs } = Math;

function resultRect(minX: number, maxX: number, minY: number, maxY: number) {
  if (minX <= maxX && minY <= maxY) {
    return createRect(minX, minY, maxX - minX + 1, maxY - minY + 1);
  } else {
    return createRect(0, 0, 0, 0);
  }
}

export function floodFill(
  src: Uint8ClampedArray, dst: Uint8ClampedArray, width: number, height: number, hitColor: number, color: number,
  tolerance: number
): Rect {
  const tr = getR(color);
  const tg = getG(color);
  const tb = getB(color);
  const ta = 0xff;

  const hitR = getR(hitColor);
  const hitG = getG(hitColor);
  const hitB = getB(hitColor);
  const hitA = getAlpha(hitColor);

  let minX = width - 1;
  let maxX = 0;
  let minY = height - 1;
  let maxY = 0;

  for (let y = 0, i = 0; y < height; y++) {
    for (let x = 0; x < width; x++, i = (i + 4) | 0) {
      const r = src[(i)];
      const g = src[(i + 1) | 0];
      const b = src[(i + 2) | 0];
      const a = src[(i + 3) | 0];
      const delta = (abs((r - hitR) | 0) + abs((g - hitG) | 0) + abs((b - hitB) | 0) + abs((a - hitA) | 0)) | 0;

      if (delta <= tolerance) {
        dst[i] = tr;
        dst[(i + 1) | 0] = tg;
        dst[(i + 2) | 0] = tb;
        dst[(i + 3) | 0] = ta;

        minX = min(x, minX);
        maxX = max(x, maxX);
        minY = min(y, minY);
        maxY = max(y, maxY);
      }
    }
  }

  return resultRect(minX, maxX, minY, maxY);
}

export function floodFillAlpha(
  src: Uint8ClampedArray, dst: Uint8ClampedArray, width: number, height: number, hitAlpha: number, color: number,
  tolerance: number
): Rect {
  const tr = getR(color);
  const tg = getG(color);
  const tb = getB(color);
  const ta = 0xff;

  let minX = width - 1;
  let maxX = 0;
  let minY = height - 1;
  let maxY = 0;

  for (let y = 0, i = 0; y < height; y++) {
    for (let x = 0; x < width; x++, i = (i + 4) | 0) {
      const a = src[(i + 3) | 0];
      const delta = abs((a - hitAlpha) | 0) | 0;

      if (delta <= tolerance) {
        dst[i] = tr;
        dst[(i + 1) | 0] = tg;
        dst[(i + 2) | 0] = tb;
        dst[(i + 3) | 0] = ta;

        minX = min(x, minX);
        maxX = max(x, maxX);
        minY = min(y, minY);
        maxY = max(y, maxY);
      }
    }
  }

  return resultRect(minX, maxX, minY, maxY);
}

export function floodFillContiguous(
  src: Uint8ClampedArray, dst: Uint8ClampedArray, width: number, height: number, x: number, y: number,
  hitColor: number, color: number, tolerance: number
) {
  const dx = [0, -1, +1, 0];
  const dy = [-1, 0, 0, +1];
  const tr = getR(color);
  const tg = getG(color);
  const tb = getB(color);
  const ta = 0xff;

  const hitR = getR(hitColor);
  const hitG = getG(hitColor);
  const hitB = getB(hitColor);
  const hitA = getAlpha(hitColor);

  let stackBuffer = new Uint16Array(1024);
  let stackIndex = 2;
  stackBuffer[0] = x;
  stackBuffer[1] = y;

  const initialOffset = (((y * width) | 0) + x) << 2;
  dst[(initialOffset)] = tr;
  dst[(initialOffset + 1) | 0] = tg;
  dst[(initialOffset + 2) | 0] = tb;
  dst[(initialOffset + 3) | 0] = ta;

  let minX = width - 1;
  let maxX = 0;
  let minY = height - 1;
  let maxY = 0;

  while (stackIndex > 0) {
    const currY = stackBuffer[stackIndex - 1];
    const currX = stackBuffer[stackIndex - 2];
    stackIndex -= 2;

    for (let i = 0; i < 4; i++) {
      const nextX = (currX + dx[i]) | 0;
      const nextY = (currY + dy[i]) | 0;

      if (nextX >= 0 && nextY >= 0 && nextX < width && nextY < height) {
        const nextOffset = (((nextY * width) | 0) + nextX) << 2;

        if (dst[(nextOffset + 3) | 0] === 0) {
          const r = src[(nextOffset)];
          const g = src[(nextOffset + 1) | 0];
          const b = src[(nextOffset + 2) | 0];
          const a = src[(nextOffset + 3) | 0];
          const delta = (abs((r - hitR) | 0) + abs((g - hitG) | 0) + abs((b - hitB) | 0) + abs((a - hitA) | 0)) | 0;

          if (delta <= tolerance) {
            dst[(nextOffset)] = tr;
            dst[(nextOffset + 1) | 0] = tg;
            dst[(nextOffset + 2) | 0] = tb;
            dst[(nextOffset + 3) | 0] = ta;

            if (stackIndex >= stackBuffer.length) {
              try {
                const newStackBuffer = new Uint16Array(stackBuffer.length * 2);
                newStackBuffer.set(stackBuffer);
                stackBuffer = newStackBuffer;
              } catch (e) {
                throw new Error(`Stack buffer allocation failed: ${e.message} (${stackBuffer.length * 2 * 2}b)`);
              }
            }

            stackBuffer[stackIndex] = nextX;
            stackBuffer[stackIndex + 1] = nextY;
            stackIndex += 2;

            minX = min(nextX, minX);
            maxX = max(nextX, maxX);
            minY = min(nextY, minY);
            maxY = max(nextY, maxY);
          }
        }
      }
    }
  }

  const hitIndex = (x + y * width) * 4;
  dst[(hitIndex)] = tr;
  dst[(hitIndex + 1) | 0] = tg;
  dst[(hitIndex + 2) | 0] = tb;
  dst[(hitIndex + 3) | 0] = ta;
  minX = min(x, minX);
  maxX = max(x, maxX);
  minY = min(y, minY);
  maxY = max(y, maxY);

  return resultRect(minX, maxX, minY, maxY);
}

export function floodFillContiguousAlpha(
  src: Uint8ClampedArray, dst: Uint8ClampedArray, width: number, height: number, x: number, y: number,
  hitAlpha: number, color: number, tolerance: number
) {
  const dx = [0, -1, +1, 0];
  const dy = [-1, 0, 0, +1];
  const tr = getR(color);
  const tg = getG(color);
  const tb = getB(color);
  const ta = 0xff;

  let stackBuffer = new Uint16Array(1024);
  let stackIndex = 2;
  stackBuffer[0] = x;
  stackBuffer[1] = y;

  const initialOffset = (((y * width) | 0) + x) << 2;
  dst[(initialOffset)] = tr;
  dst[(initialOffset + 1) | 0] = tg;
  dst[(initialOffset + 2) | 0] = tb;
  dst[(initialOffset + 3) | 0] = ta;

  let minX = width - 1;
  let maxX = 0;
  let minY = height - 1;
  let maxY = 0;

  while (stackIndex > 0) {
    const currY = stackBuffer[stackIndex - 1];
    const currX = stackBuffer[stackIndex - 2];
    stackIndex -= 2;

    for (let i = 0; i < 4; i++) {
      const nextX = (currX + dx[i]) | 0;
      const nextY = (currY + dy[i]) | 0;

      if (nextX >= 0 && nextY >= 0 && nextX < width && nextY < height) {
        const nextOffset = (((nextY * width) | 0) + nextX) << 2;

        if (dst[(nextOffset + 3) | 0] === 0) {
          const a = src[(nextOffset + 3) | 0];
          const delta = abs((a - hitAlpha) | 0) | 0;

          if (delta <= tolerance) {
            dst[(nextOffset)] = tr;
            dst[(nextOffset + 1) | 0] = tg;
            dst[(nextOffset + 2) | 0] = tb;
            dst[(nextOffset + 3) | 0] = ta;

            if (stackIndex >= stackBuffer.length) {
              try {
                const newStackBuffer = new Uint16Array(stackBuffer.length * 2);
                newStackBuffer.set(stackBuffer);
                stackBuffer = newStackBuffer;
              } catch (e) {
                throw new Error(`Allocation failed: (size: ${stackBuffer.length * 2}, error: ${e.message})`);
              }
            }

            stackBuffer[stackIndex] = nextX;
            stackBuffer[stackIndex + 1] = nextY;
            stackIndex += 2;

            minX = min(nextX, minX);
            maxX = max(nextX, maxX);
            minY = min(nextY, minY);
            maxY = max(nextY, maxY);
          }
        }
      }
    }
  }

  const hitIndex = (x + y * width) * 4;
  dst[(hitIndex)] = tr;
  dst[(hitIndex + 1) | 0] = tg;
  dst[(hitIndex + 2) | 0] = tb;
  dst[(hitIndex + 3) | 0] = ta;
  minX = min(x, minX);
  maxX = max(x, maxX);
  minY = min(y, minY);
  maxY = max(y, maxY);

  return resultRect(minX, maxX, minY, maxY);
}

export function floodFillContiguousAlphaSmart(
  src: Uint8ClampedArray, dst: Uint8ClampedArray, width: number, height: number, x: number, y: number,
  hitAlpha: number, color: number
) {
  const dx = [0, -1, +1, 0];
  const dy = [-1, 0, 0, +1];
  const up = hitAlpha < 128;
  const tr = getR(color) | 0;
  const tg = getG(color) | 0;
  const tb = getB(color) | 0;
  const ta = 0xff | 0;

  let stackBuffer = new Uint16Array(3 * 512);
  let stackIndex = 3;
  stackBuffer[0] = hitAlpha;
  stackBuffer[1] = x;
  stackBuffer[2] = y;

  let minX = width - 1;
  let maxX = 0;
  let minY = height - 1;
  let maxY = 0;

  while (stackIndex > 0) {
    const currAlpha = stackBuffer[stackIndex - 3];
    const currX = stackBuffer[stackIndex - 2];
    const currY = stackBuffer[stackIndex - 1];
    stackIndex -= 3;

    for (let i = 0; i < 4; i++) {
      const nextX = (currX + dx[i]) | 0;
      const nextY = (currY + dy[i]) | 0;

      if (nextX >= 0 && nextY >= 0 && nextX < width && nextY < height) {
        const nextOffset = (((nextY * width) | 0) + nextX) << 2;

        if (dst[(nextOffset + 3) | 0] === 0) {
          const a = src[(nextOffset + 3) | 0];
          const go = (a === hitAlpha && a === currAlpha) || (up ? a > currAlpha : a < currAlpha);

          if (go) {
            dst[(nextOffset)] = tr;
            dst[(nextOffset + 1) | 0] = tg;
            dst[(nextOffset + 2) | 0] = tb;
            dst[(nextOffset + 3) | 0] = ta;

            if (stackIndex >= stackBuffer.length) {
              const newStackBuffer = new Uint16Array(stackBuffer.length * 2);
              newStackBuffer.set(stackBuffer);
              stackBuffer = newStackBuffer;
            }

            stackBuffer[stackIndex] = a;
            stackBuffer[stackIndex + 1] = nextX;
            stackBuffer[stackIndex + 2] = nextY;
            stackIndex += 3;

            minX = min(nextX, minX);
            maxX = max(nextX, maxX);
            minY = min(nextY, minY);
            maxY = max(nextY, maxY);
          }
        }
      }
    }
  }

  const hitIndex = (x + y * width) * 4;
  dst[(hitIndex)] = tr;
  dst[(hitIndex + 1) | 0] = tg;
  dst[(hitIndex + 2) | 0] = tb;
  dst[(hitIndex + 3) | 0] = ta;
  minX = min(x, minX);
  maxX = max(x, maxX);
  minY = min(y, minY);
  maxY = max(y, maxY);

  return resultRect(minX, maxX, minY, maxY);
}

export function antialiasFloodFill(data: Uint8ClampedArray, width: number, height: number, color: number, rect: Rect) {
  const r = getR(color) | 0;
  const g = getG(color) | 0;
  const b = getB(color) | 0;
  const a = 0x7f;

  const minX = max(rect.x - 1, 0);
  const maxX = min(rect.x + rect.w + 1, width - 1);
  const minY = max(rect.y - 1, 0);
  const maxY = min(rect.y + rect.h + 1, height - 1);

  for (let y = minY; y <= maxY; y++) {
    for (let x = minX; x <= maxX; x++) {
      const offset = (x + y * width) << 2;

      if (data[(offset + 3) | 0] === 0) {
        let put = false;

        if (x > 0 && data[(offset - 1) | 0] === 0xff) {
          put = true;
          rect.w = max(rect.w, x - rect.x + 1);
        } else if (x < (width - 1) && data[(offset + 7) | 0] === 0xff) {
          put = true;

          if (x < rect.x) {
            rect.w += rect.x - x;
            rect.x = x;
          }
        } else if (y > 0 && data[(offset - (width << 2) + 3) | 0] === 0xff) {
          put = true;
          rect.h = max(rect.h, y - rect.y + 1);
        } else if (y < (height - 1) && data[(offset + (width << 2) + 3) | 0] === 0xff) {
          put = true;

          if (y < rect.y) {
            rect.h += rect.y - y;
            rect.y = y;
          }
        }

        if (put) {
          data[(offset + 0) | 0] = r;
          data[(offset + 1) | 0] = g;
          data[(offset + 2) | 0] = b;
          data[(offset + 3) | 0] = a;
        }
      }
    }
  }
}
