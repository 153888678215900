import { ParsedPath, ShapePath } from './interfaces';

export function cachePath(path: ShapePath) {
  if (typeof Path2D !== 'undefined') {
    path.cachedPath2D = path.cachedPath2D ?? new Path2D(path.path);
  } else {
    path.cachedParsedPath = path.cachedParsedPath ?? parseSvgPath(path.path);
  }
}

export function fillPath(context: CanvasRenderingContext2D, path: ShapePath) {
  cachePath(path);

  if (path.cachedPath2D) {
    context.fill(path.cachedPath2D);
  } else if (path.cachedParsedPath) {
    context.beginPath();
    svgPathParsed(context, path.cachedParsedPath);
    context.fill();
  } else {
    throw new Error('Missing cached path');
  }
}

export function pushPath(context: CanvasRenderingContext2D, path: ShapePath) {
  path.cachedParsedPath = path.cachedParsedPath ?? parseSvgPath(path.path);
  svgPathParsed(context, path.cachedParsedPath);
}

function isWhitespace(code: number) {
  return code === 9 || code === 10 || code === 13 || code === 32 || code === 44; // \t \n \r space ,
}

function isDigit(code: number) {
  return code >= 48 && code <= 57;
}

export function svgPath(context: CanvasRenderingContext2D, path: string) {
  let offset = 0;
  let lastCommand = 0;
  let ix = 0, iy = 0; // initial point
  let x = 0, y = 0; // current point
  let cx = 0, cy = 0;
  let qcx = 0, qcy = 0;

  function char() {
    return path.charCodeAt(offset);
  }

  function flag() {
    while (isWhitespace(char())) offset++;
    const c = char(); offset++;
    return c - 48; // 0
  }

  function num() {
    while (isWhitespace(char())) offset++;
    const start = offset;

    // -?[0-9]*.?[0-9]+(e[-+]?[0-9]+)?
    if (char() === 45) offset++; // -
    while (isDigit(char())) offset++;

    if (char() === 46) { // .
      offset++;
      while (isDigit(char())) offset++;
    }

    if (char() === 101 || char() === 69) { // e E
      offset++;
      if (char() === 43 || char() === 45) offset++; // + -
      while (isDigit(char())) offset++;
    }

    const number = parseFloat(path.substring(start, offset));
    if (Number.isNaN(number)) throw new Error(`Invalid svg path number at: ${start}-${offset} "${path.substring(start, start + 10)}"`);
    return number;
  }

  function setBoth() {
    cx = x; cy = y; qcx = x; qcy = y;
  }

  function setC() {
    cx = x; cy = y;
  }

  function setQC() {
    qcx = x; qcy = y;
  }

  function execCommand(cmd: number) {
    switch (cmd) {
      case 77: // M
        context.moveTo(ix = x = num(), iy = y = num());
        setBoth();
        break;
      case 109: // m
        context.moveTo(ix = x += num(), iy = y += num()); setBoth(); break;
      case 76: // L
        context.lineTo(x = num(), y = num()); setBoth(); break;
      case 108: // l
        context.lineTo(x += num(), y += num()); setBoth(); break;
      case 72: // H
        context.lineTo(x = num(), y); setBoth(); break;
      case 104: // h
        context.lineTo(x += num(), y); setBoth(); break;
      case 86: // V
        context.lineTo(x, y = num()); setBoth(); break;
      case 118: // v
        context.lineTo(x, y += num()); setBoth(); break;
      case 67: // C
        context.bezierCurveTo(num(), num(), cx = num(), cy = num(), x = num(), y = num()); setQC(); break;
      case 99: // c
        context.bezierCurveTo(x + num(), y + num(), cx = x + num(), cy = y + num(), x += num(), y += num()); setQC(); break;
      case 83: // S
        context.bezierCurveTo(x + x - cx, y + y - cy, cx = num(), cy = num(), x = num(), y = num()); setQC(); break;
      case 115: // s
        context.bezierCurveTo(x + x - cx, y + y - cy, cx = x + num(), cy = y + num(), x += num(), y += num()); setQC(); break;
      case 65: // A
      case 97: { // a
        let rx = num();
        let ry = num();
        const angle = (num() * Math.PI) / 180;
        const largeArcFlag = !!flag();
        const sweepFlag = !!flag();
        const currentX = x, currentY = y;

        if (cmd === 97) { // a
          x += num();
          y += num();
        } else {
          x = num();
          y = num();
        }

        // https://www.w3.org/TR/SVG/implnote.html#ArcImplementationNotes

        const midXt = (currentX - x) / 2;
        const midYt = (currentY - y) / 2;
        const midX = midXt * Math.cos(-angle) - midYt * Math.sin(-angle);
        const midY = midYt * Math.cos(-angle) + midXt * Math.sin(-angle);

        // radius correction
        let lambda = (midX * midX) / (rx * rx) + (midY * midY) / (ry * ry);

        if (lambda > 1) {
          lambda = Math.sqrt(lambda);
          rx *= lambda;
          ry *= lambda;
        }

        let centerX = (rx * midY) / ry;
        let centerY = -(ry * midX) / rx;

        const t1 = rx * rx * ry * ry;
        const t2 = rx * rx * midY * midY + ry * ry * midX * midX;
        const s = Math.sqrt((t1 - t2) / t2) || 0;

        if (sweepFlag !== largeArcFlag) {
          centerX *= s;
          centerY *= s;
        } else {
          centerX *= -s;
          centerY *= -s;
        }

        const startAngle = Math.atan2((midY - centerY) / ry, (midX - centerX) / rx);
        const endAngle = Math.atan2(-(midY + centerY) / ry, -(midX + centerX) / rx);
        let tcx = centerX * Math.cos(angle) - centerY * Math.sin(angle);
        let tcy = centerY * Math.cos(angle) + centerX * Math.sin(angle);

        tcx += (x + currentX) / 2;
        tcy += (y + currentY) / 2;

        context.save();
        context.translate(tcx, tcy);
        context.rotate(angle);
        context.scale(rx, ry);
        context.arc(0, 0, 1, startAngle, endAngle, !sweepFlag);
        context.restore();

        setBoth();
        break;
      }
      case 81: // Q
        context.quadraticCurveTo(qcx = num(), qcy = num(), x = num(), y = num()); setC(); break;
      case 113: // q
        context.quadraticCurveTo(qcx = x + num(), qcy = y + num(), x += num(), y += num()); setC(); break;
      case 84: // T
        qcx = 2 * x - qcx; // last control point
        qcy = 2 * y - qcy;
        context.quadraticCurveTo(qcx, qcy, x = num(), y = num());
        setC();
        break;
      case 116: // t
        qcx = 2 * x - qcx; // last control point
        qcy = 2 * y - qcy;
        context.quadraticCurveTo(qcx, qcy, x += num(), y += num());
        setC();
        break;
      case 90: // Z
      case 122: // z
        context.closePath();
        x = ix;
        y = iy;
        setBoth();
        break;
      default:
        return false;
    }

    return true;
  }

  while (offset < path.length) {
    while (isWhitespace(char())) offset++;
    let command = path.charCodeAt(offset++);

    if (execCommand(command)) {
      lastCommand = command;
    } else {
      offset--;
      command = lastCommand;
      if (lastCommand === 77) command = 76; // M -> L
      if (lastCommand === 109) command = 108; // m -> l
      if (!execCommand(command)) {
        throw new Error(`Invalid svg path command at: ${offset} (${command}) "${path.substring(offset, offset + 100)}"`);
      }
    }
  }
}

const enum Cmd { Save, Restore, Translate, Rotate, Scale, Arc, MoveTo, LineTo, BezierCurveTo, QuadraticCurveTo, ClosePath }

export function parseSvgPath(path: string): ParsedPath {
  const numbers: number[] = [];
  const context: Partial<CanvasRenderingContext2D> = {
    save() { numbers.push(Cmd.Save); },
    restore() { numbers.push(Cmd.Restore); },
    translate(tcx, tcy) { numbers.push(Cmd.Translate, tcx, tcy); },
    rotate(angle) { numbers.push(Cmd.Rotate, angle); },
    scale(rx, ry) { numbers.push(Cmd.Scale, rx, ry); },
    moveTo(x, y) { numbers.push(Cmd.MoveTo, x, y); },
    lineTo(x, y) { numbers.push(Cmd.LineTo, x, y); },
    bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x, y) { numbers.push(Cmd.BezierCurveTo, cp1x, cp1y, cp2x, cp2y, x, y); },
    quadraticCurveTo(cpx, cpy, x, y) { numbers.push(Cmd.QuadraticCurveTo, cpx, cpy, x, y); },
    closePath() { numbers.push(Cmd.ClosePath); },
    arc(x, y, radius, startAngle, endAngle, sweepFlag) { numbers.push(Cmd.Arc, x, y, radius, startAngle, endAngle, sweepFlag ? 1 : 0); },
  };
  svgPath(context as CanvasRenderingContext2D, path);
  return { commands: new Float32Array(numbers) };
}

export function svgPathParsed(context: CanvasRenderingContext2D, path: ParsedPath) {
  const c = path.commands;

  for (let p = 0; p < c.length;) {
    switch (c[p++]) {
      case Cmd.Save: context.save(); break;
      case Cmd.Restore: context.restore(); break;
      case Cmd.Translate: context.translate(c[p++], c[p++]); break;
      case Cmd.Rotate: context.rotate(c[p++]); break;
      case Cmd.Scale: context.scale(c[p++], c[p++]); break;
      case Cmd.Arc: context.arc(c[p++], c[p++], c[p++], c[p++], c[p++], !!c[p++]); break;
      case Cmd.MoveTo: context.moveTo(c[p++], c[p++]); break;
      case Cmd.LineTo: context.lineTo(c[p++], c[p++]); break;
      case Cmd.BezierCurveTo: context.bezierCurveTo(c[p++], c[p++], c[p++], c[p++], c[p++], c[p++]); break;
      case Cmd.QuadraticCurveTo: context.quadraticCurveTo(c[p++], c[p++], c[p++], c[p++]); break;
      case Cmd.ClosePath: context.closePath(); break;
      default: throw new Error(`Invalid command ${c[p - 1]} at ${p - 1}`);
    }
  }
}
