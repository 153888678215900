import { decorate } from 'core-decorators';
import { storageGetItem, storageSetItem } from 'magma/services/storage';
import memoizee from 'memoizee';
import { Observable, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, finalize, multicast, refCount } from 'rxjs/operators';
import { refCountDelay } from './rxjs';

type ObservableFunction<T> = (...args: any[]) => Observable<T>;
type MemoizedObservableFunction<T> = ObservableFunction<T> & memoizee.Memoized<ObservableFunction<T>>;

// TODO: use memoizee.Options instead, for some reason it's not compiling on CI
type MemoizeeOptions = {
  length?: number | false;
  maxAge?: number;
  max?: number;
  preFetch?: number | true;
  promise?: boolean;
  dispose?(value: any): void;
  async?: boolean;
  primitive?: boolean;
  normalizer?(...args: any[]): any;
  resolvers?: Array<(arg: any) => any>;
}

type MemoizeeRxOptions = MemoizeeOptions & {
  deleteOnFinalize?: boolean | number;
  replayLast?: boolean,
  serializeArgs?: boolean,
  delayUnsubscribe?: number;
};

export function memoizeeRx<T>(toWrap: ObservableFunction<T>, options: MemoizeeRxOptions = {}): MemoizedObservableFunction<T> {
  const subjectConstructor = options.replayLast ? <T>() => new ReplaySubject<T>(1) : <T>() => new Subject<T>();
  const { delayUnsubscribe } = options;

  const memoized = memoizee(function (this: any, ...args: any[]) {
    return toWrap.apply(this, args).pipe(
      finalize(() => {
        if (options.deleteOnFinalize) {
          memoized.delete(...args);
        }
      }),
      catchError((err) => {
        memoized.delete(...args);
        return throwError(err);
      }),
      multicast(subjectConstructor),
      delayUnsubscribe ? refCountDelay(delayUnsubscribe) : refCount()
    );
  }, {
    ...options,
    ...options.serializeArgs && { normalizer: (args: any[]) => JSON.stringify(args) }
  });

  return memoized as any;
}

export function memoize$(this: any, options: MemoizeeRxOptions) {
  return decorate.call(this, memoizeeRx, { ...options });
}

export function memoize(this: any, options: MemoizeeOptions) {
  return decorate.call(this, memoizee, options);
}

// tslint:disable-next-line:ban-types
export function benchmark(this: Function, name: string) {
  // tslint:disable-next-line:ban-types
  return decorate.call(this, (toWrap: Function) => {
    // tslint:disable-next-line:ban-types
    return function (this: any) {
      const t1 = new Date().getTime();
      const res = toWrap.apply(this, arguments);
      const printRes = () => console.log(`Benchmark ${name} took ${new Date().getTime() - t1}ms`);
      if (res && res.then) {
        res.finally(printRes);
      } else {
        printRes();
      }
      return res;
    };
  });
}

// tslint:disable-next-line:ban-types
export function cacheOnce(this: any, cacheFunc: Function) {
  // tslint:disable-next-line:ban-types
  return decorate.call(this, (toWrap: Function) => {
    return function (this: any) {
      const cacheEntity = cacheFunc.call(this, this);
      if (cacheEntity === this.__cacheEntity) {
        return this.__cacheResult;
      }
      this.__cacheEntity = cacheEntity;
      const result = toWrap.apply(this, arguments);
      this.__cacheResult = result;
    };
  });
}

export function saveInLocalStorage(defaultValue: any, serialize = JSON.stringify, deserialize = JSON.parse) {
  return (target: {} | any, name: PropertyKey): any => {
    let localValue: any;
    const descriptor = {
      get(this: any) {
        const out = (() => {
          if (typeof localValue !== 'undefined') { return localValue; }
          const val = storageGetItem(name as string);
          return (typeof val === 'string') ? deserialize(val) : defaultValue;
        })();
        return out;
      },
      set(value: any) {
        storageSetItem(name.toString(), serialize((localValue = value)));
      },
      enumerable: true,
      configurable: true,
    };

    Object.defineProperty(target, name, descriptor);
  };
}
