import { DrawingOwnerRole, EntityData, ProjectData, TeamBannedMember } from './../../../shared/interfaces';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest, Subject, BehaviorSubject, of, Observable } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { TeamMember, TeamRole } from 'shared/interfaces';
import { hasPermissionFlag, hasPermissionFlags, toPromise } from 'shared/utils';
import { TeamMembersQuery } from './team-members.query';
import { TeamMembersStore } from './team-members.store';
import { TeamsQuery } from './team.query';
import { UserService } from './user.service';
import { Permission } from 'magma/common/interfaces';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class TeamMembersService {
  private reload$ = new Subject<void>();
  roles$ = new BehaviorSubject<TeamRole[]>([]);
  private memoizedPermissions = new Map<string, boolean>();
  teamMembersReady$ = new BehaviorSubject<boolean>(false);

  constructor(
    private httpClient: HttpClient,
    private teamMembersStore: TeamMembersStore,
    private teamsQuery: TeamsQuery,
    private teamMemberQuery: TeamMembersQuery,
    private userService: UserService,
  ) {
    combineLatest([
      this.teamsQuery.selectActive().pipe(map(team => team ? team._id : null), distinctUntilChanged()),
      this.reload$,
    ]).pipe(
      switchMap(([teamId, _]) => teamId ? this.getTeamMembers(teamId) : of(null)),
      map(members => members ? members.map(member => ({ ...member, _id: member.user._id })) : null),
      untilDestroyed(this),
    ).subscribe((members) => {
      this.memoizedPermissions.clear();
      if (members !== null) {
        this.teamMembersStore.set(members);
        if (this.userService.user) {
          const member = this.teamMemberQuery.getEntity(this.userService.user._id);
          if (member) {
            this.roles$.next(member.roles);
          } else {
            this.roles$.next([]);
          }
        }
        this.teamMembersReady$.next(true);
      } else {
        // artdesk
        this.roles$.next([DrawingOwnerRole]);
      }
    });
    this.reload$.next();
  }

  reset() {
    this.teamMembersReady$.next(false);
    this.roles$.next([]);
    this.memoizedPermissions.clear();
    this.reload$.next();
  }

  reloadMembers() {
    this.reload$.next();
  }

  isTeamOwner(userId: string): boolean {
    const member = this.teamMemberQuery.getEntity(userId);
    return !!member?.roles.find(r => r.type === 'owner');
  }

  isPermissionFlagSet(flags: Permission[] | Permission, project?: ProjectData, entity?: EntityData): boolean {
    const key = `${flags}-${project?._id}-${entity?._id}`;
    let permission = this.memoizedPermissions.get(key);
    if (permission === undefined) {
      if (Array.isArray(flags)) {
        permission = hasPermissionFlags(flags, this.roles$.getValue(), entity, project);
      } else {
        permission = hasPermissionFlag(flags, this.roles$.getValue(), entity, project);
      }
      this.memoizedPermissions.set(key, permission);
    }
    return permission;
  }

  countTeamMembersWithOwnerRole() {
    return this.teamMemberQuery.getAll().reduce((sum, member) => sum + (member.roles.find(r => r.type === 'owner') ? 1 : 0), 0);
  }

  private getTeamMembers(teamId: string): Observable<TeamMember[]> {
    return this.httpClient.get<TeamMember[]>(`/api/teams/${teamId}/members`)
      .pipe(catchError(() => of([])));
  }

  async getTeamBannedMembers(teamId: string) {
    return await toPromise(this.httpClient.get<TeamBannedMember[]>(`/api/teams/${teamId}/bans`));
  }

  async updateTeamMember(teamId: string, memberId: string, roles: string[]) {
    await toPromise(this.httpClient.put(`/api/teams/${teamId}/members/${memberId}`, { roles }));
    this.reload$.next();
  }

  async transferTeamMemberRoles(teamId: string, sourceMemberId: string, targetMemberId: string) {
    await toPromise(this.httpClient.post(`/api/teams/${teamId}/transfer-roles`, { sourceMemberId, targetMemberId }));
    this.reload$.next();
  }

  async removeMemberTeam(teamId: string, memberId: string, banMember = false) {
    await toPromise(this.httpClient.delete(`/api/teams/${teamId}/members/${memberId}`, { body: { banMember } }));
    this.teamMembersStore.remove(memberId);
  }

  async unbanUserTeam(teamId: string, memberId: string) {
    await toPromise(this.httpClient.delete(`/api/teams/${teamId}/bans/${memberId}`));
  }
}
