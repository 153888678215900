import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { escape } from 'lodash';
import { colorToCSS } from '../../common/color';
import { getUrl } from '../../common/rev';
import { getAvatarPath, relativeTime, replaceKeys, toDegrees, toPercentage } from '../../common/utils';
import { Model } from '../../services/model';
import { IThreadService } from '../../services/thread.service.interface';
import { convertMarkdownToComment } from './directives/mention.directive';

@Pipe({ name: 'deg' })
export class Deg implements PipeTransform {
  transform(value: any, decimals = 0, suffix = '°') {
    return isNaN(+value) ? '' : toDegrees(value, decimals, suffix);
  }
}

@Pipe({ name: 'num' })
export class Num implements PipeTransform {
  transform(value: any) {
    return isNaN(+value) ? value : value.toFixed(Math.abs(+value) < 10 ? 1 : 0);
  }
}

@Pipe({ name: 'filter', pure: false })
export class Filter implements PipeTransform {
  transform(items: any, search?: (value: any, index: number, array: any[]) => boolean) {
    return (items && items.filter && search) ? items.filter(search) : items;
  }
}

@Pipe({ name: 'kbd' })
export class Kbd implements PipeTransform {
  transform(value: any) {
    return value && replaceKeys(value);
  }
}

@Pipe({ name: 'rev' })
export class Rev implements PipeTransform {
  transform(value: any) {
    return value && getUrl(value);
  }
}

const hasEmojiRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;

async function transformChat(value: string) {
  value = escape(value);
  value = value.replace(
    // added `;` to catch `&` converted to `&amp;`
    /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}(\.[a-z]{2,6}|:[0-9]+)\b([-a-zA-Z0-9@:%_\+.~#?&;//=]*))/g,
    '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>');
  if (hasEmojiRegex.test(value)) {
    const { default: emojione } = await import('emojione_minimal' /* webpackChunkName: "emoji" */);
    (emojione as any).imagePathPNG = '/assets/emoji/';
    value = emojione.toImage(value);
  }
  return value;
}

@Pipe({ name: 'chat' })
export class Chat implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }
  async transform(value: any) {
    value = await transformChat(value);
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@Pipe({ name: 'comment' })
export class CommentPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer, private model: Model, private threadService: IThreadService) { }
  async transform(value: string) {
    const suggestions = await this.threadService.getMentionSuggestions();
    value = await transformChat(value);
    value = convertMarkdownToComment(value, this.model.user?.accountId, suggestions);
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

const onlyEmojis = /^(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]| )+$/;

export function isEmojiOnly(value: string) {
  return !!value && onlyEmojis.test(value); // emojione.unifyUnicode(value));
}

@Pipe({ name: 'isEmojiOnly' })
export class IsEmojiOnly implements PipeTransform {
  transform(value: any) {
    return isEmojiOnly(value);
  }
}

@Pipe({ name: 'colorToCss' })
export class ColorToCss implements PipeTransform {
  transform(value: any) {
    return colorToCSS(value | 0);
  }
}

@Pipe({ name: 'percentage' })
export class Percentage implements PipeTransform {
  transform(value: any) {
    return toPercentage(value);
  }
}

@Pipe({ name: 'percentageOrOff' })
export class PercentageOrOff implements PipeTransform {
  transform(value: any) {
    return value === 0 ? 'off' : toPercentage(value);
  }
}

@Pipe({ name: 'linkEmails' })
export class LinkEmails implements PipeTransform {
  transform(value: string) {
    return escape(value).replace(/([a-z0-9._-]+@[a-z0-9._-]+)/g, '<a href="mailto:$1">$1</a>');
  }
}

@Pipe({ name: 'avatarPath' })
export class AvatarPath implements PipeTransform {
  transform(value: string | undefined, size?: string | number) {
    return getAvatarPath(value, size ? (+size | 0) : 32);
  }
}

@Pipe({ name: 'wrapUrl' })
export class WrapUrl implements PipeTransform {
  transform(value: string) {
    return `url(${value})`;
  }
}

@Pipe({ name: 'largeNumber' })
export class LargeNumber implements PipeTransform {
  transform(value: number) {
    if (value < 1000) {
      return `${value}`;
    } else if (value < 10_000) {
      return `${(value / 1000).toFixed(1)}k`;
    } else if (value < 1_000_000) {
      return `${(value / 1000).toFixed(0)}k`;
    } else if (value < 10_000_000) {
      return `${(value / 1_000_000).toFixed(1)}M`;
    } else if (value < 1_000_000_000) {
      return `${(value / 1_000_000).toFixed(0)}M`;
    } else {
      return `${(value / 1_000_000_000).toFixed(1)}G`;
    }
  }
}

@Pipe({ name: 'relativeTime' })
export class RelativeTime implements PipeTransform {
  transform(value: string | Date | undefined) {
    return relativeTime(value);
  }
}

export const PIPES = [
  Deg, Num, Filter, Kbd, Rev, Chat, IsEmojiOnly, ColorToCss, Percentage, PercentageOrOff, LinkEmails, LargeNumber,
  RelativeTime, AvatarPath, WrapUrl
];
