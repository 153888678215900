import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { TeamMember } from 'shared/interfaces';
import { map } from 'rxjs/operators';
import { TeamMembersState, TeamMembersStore } from './team-members.store';

@Injectable({ providedIn: 'root' })
export class TeamMembersQuery extends QueryEntity<TeamMembersState, TeamMember> {
  teamMembers$ = this.selectAll();

  constructor(protected store: TeamMembersStore) {
    super(store);
  }
}
