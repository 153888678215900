import { StoreConfig, EntityState, EntityStore } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { FlowchartListData } from 'shared/interfaces';

export interface EntitiesState extends EntityState<FlowchartListData, string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'entities', resettable: true, idKey: '_id' })
export class EntitiesStore extends EntityStore<EntitiesState, FlowchartListData> {
  constructor() {
    super([]);
  }
}

// tslint:disable-next-line:max-classes-per-file
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'sharedEntities', resettable: true, idKey: '_id' })
export class SharedEntitiesStore extends EntityStore<EntitiesState, FlowchartListData> {
  constructor() {
    super([]);
  }
}
