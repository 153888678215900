import memoizee from 'memoizee';
import { fromByteArray, toByteArray } from 'base64-js';
import { AVATAR_DEFAULT, DAY, GB, GENERATED_AVATARS, HOUR, KB, MB, MINUTE, MONTH, SECOND, USER_COLORS, YEAR } from './constants';
import { avatarPathTemplate } from './data';
import { avatars } from '../generated/resources';
import { isAndroid, isChromeOS, isFirefox, isiOS, isMac, isWindows } from './userAgentUtils';
import { rad2deg } from './mathUtils';
import { randomString } from './baseUtils';
import { OauthProfile, Point, Viewport, Permission } from './interfaces';
import { perceivedBrightness, randomBrewerHexColor } from './color';
import { MagmaKeyboardEvent } from './input';

export function getKeysOf<T>(keysMap: { [P in keyof T]-?: 1 }): (keyof T)[] {
  return Object.keys(keysMap) as any;
}

export function times<T>(count: number, action: (index: number) => T) {
  const result: T[] = [];

  for (let i = 0; i < count; i++) {
    result.push(action(i));
  }

  return result;
}

export function cloneBuffer(data: Uint8Array) {
  const result = new Uint8Array(data.byteLength);
  result.set(data);
  return result;
}

export function cloneDeep<T>(value: T): T {
  if (Array.isArray(value)) {
    return value.map(cloneDeep) as any;
  } else if (value instanceof Date) {
    return new Date(value) as any;
  } else if (value && typeof value === 'object') {
    const obj: any = {};
    for (const key of Object.keys(value)) {
      obj[key] = cloneDeep((value as any)[key]);
    }
    return obj;
  } else {
    return value;
  }
}

export function fromNow(duration: number): Date {
  const date = new Date();
  date.setTime(date.getTime() + duration);
  return date;
}

export function formatDuration(duration: number) {
  const s = Math.floor(duration / SECOND) % 60;
  const m = Math.floor(duration / MINUTE) % 60;
  const h = Math.floor(duration / HOUR) % 24;
  const d = Math.floor(duration / DAY);

  if (d > 0) {
    return h ? `${d}d ${h}h` : `${d}d`;
  } else if (h > 0) {
    return m ? `${h}h ${m}m` : `${h}h`;
  } else if (m > 0) {
    return s ? `${m}m ${s}s` : `${m}m`;
  } else {
    return `${s}s`;
  }
}

export function formatBytes(value: number) {
  if (value < 1024) {
    return `${value.toFixed()} bytes`;
  } else if (value < (1024 * 1024)) {
    return `${(value / 1024).toFixed()} kB`;
  } else {
    return `${(value / (1024 * 1024)).toFixed()} MB`;
  }
}

export function toPercentage(value: number) {
  value *= 100;
  let digits = 0;
  if (value && value < 1) digits = 1;
  if (value && value < 0.1) digits = 2;
  return `${value.toFixed(digits)}%`;
}

export function toDegrees(value: number, decimals = 0, suffix = '°') {
  return `${rad2deg(+value).toFixed(decimals)}${suffix}`;
}

export const CMD_KEY = '⌘';
export const SHIFT_KEY = '⇧';
export const ALT_KEY = '⌥';

export function replaceKeys(text: string) {
  return !isMac ? text : text
    .replace(/ctrl/ig, CMD_KEY)
    .replace(/shift/ig, SHIFT_KEY)
    .replace(/alt/ig, ALT_KEY);
}

// collections

export function deepEqual(a: any, b: any): boolean {
  if (a === b) return true;
  if (typeof a !== typeof b) return false;

  if (Array.isArray(a)) {
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; i++) {
      if (!deepEqual(a[i], b[i])) {
        return false;
      }
    }

    return true;
  } else if (typeof a === 'object') {
    if (a === null || b === null) return false;

    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);

    if (aKeys.length !== bKeys.length) return false;

    for (const key of aKeys) {
      if (!deepEqual(a[key], b[key])) {
        return false;
      }
    }

    return true;
  }

  return false;
}

export function arraysEqual<T>(a: T[] | undefined, b: T[] | undefined): boolean {
  if (!a || !b) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }

  return true;
}

export function findIndexById<U, T extends { id: U; }>(items: T[], id: U): number {
  for (let i = 0; i < items.length; i++) {
    if (items[i].id === id) return i;
  }
  return -1;
}

export function findByLocalId<T extends { localId: number; }>(items: T[], localId: number): T | undefined {
  for (let i = 0; i < items.length; i++) {
    if (items[i].localId === localId) return items[i];
  }
  return undefined;
}

export function findLastIndex<T>(items: T[], predicate: (item: T) => boolean): number {
  for (let i = items.length - 1; i >= 0; i--) {
    if (predicate(items[i])) return i;
  }
  return -1;
}

export function removeFast<T>(items: T[], test: (item: T) => boolean) {
  for (let i = items.length - 1; i >= 0; i--) {
    if (test(items[i])) {
      items[i] = items[items.length - 1];
      items.length--;
    }
  }
}

export function removeByLocalId<T extends { localId: number; }>(items: T[], id: number): T | undefined {
  for (let i = 0; i < items.length; i++) {
    if (items[i].localId === id) {
      return items.splice(i, 1)[0];
    }
  }

  return undefined;
}

// Does not preserve order of items
export function removeAtFast<T>(items: T[], index: number) {
  items[index] = items[items.length - 1];
  items.length--;
}

export function reverse<T>(items: T[]): T[] {
  const result: T[] = [];

  for (let i = items.length - 1; i >= 0; i--) {
    result.push(items[i]);
  }

  return result;
}

export function createMap<T, TKey, TValue = T>(items: T[], toKey: (item: T) => TKey, toValue: (item: T) => TValue = x => (x as any)) {
  const map = new Map<TKey, TValue>();
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    map.set(toKey(item), toValue(item));
  }
  return map;
}

export function removeFromMap<TKey, TValue>(map: Map<TKey, TValue>, test: (value: TValue, key: TKey) => boolean) {
  if (!map.size) return;

  const removeKeys: TKey[] = [];

  map.forEach((value, key) => {
    if (test(value, key)) removeKeys.push(key);
  });

  for (const key of removeKeys) map.delete(key);
}

export function sample<T>(items: T[]): T {
  return items[Math.floor(Math.random() * items.length)];
}

export function compact<T>(items: (T | null | undefined | false | '' | 0)[] | null | undefined): T[] {
  const result: T[] = [];

  if (items) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item) result.push(item);
    }
  }

  return result;
}

export function sum(items: number[]): number {
  let sum = 0;

  for (let i = 0; i < items.length; i++) {
    sum += items[i];
  }

  return sum;
}

function compareById<U, T extends { id: U }>(a: T, b: T, order: U[]): number {
  const ao = order.indexOf(a.id);
  const bo = order.indexOf(b.id);
  return (ao === -1 ? order.length : ao) - (bo === -1 ? order.length : bo);
}

function shouldReorder<U, T extends { id: U }>(items: T[], order: U[]): boolean {
  for (let i = 1; i < items.length; i++) {
    if (compareById(items[i - 1], items[i], order) > 0) {
      return true;
    }
  }

  return false;
}

export function reorder<U, T extends { id: U }>(items: T[] | undefined, order: U[] | undefined): boolean {
  if (items && order && shouldReorder(items, order)) {
    items.sort((a, b) => compareById(a, b, order));
    return true;
  }

  return false;
}

// other

export function pickValidSize(size: number, validSizes: number[]) {
  let index = validSizes.length - 1;
  while (index > 0 && validSizes[index - 1] >= size) index--;
  return validSizes[index];
}

export const avatarSizes = [32, 64, 128, 256];

export function getAvatarPath(image: string | undefined, size: number) {
  const actualSize = Math.round(size * getPixelRatio());
  const validSize = pickValidSize(actualSize, avatarSizes);
  return avatarPathTemplate
    .replace('%SIZE%', validSize.toString())
    .replace('%NAME%', image || AVATAR_DEFAULT);
}

export function randomAvatar() {
  if (GENERATED_AVATARS) {
    return `${randomString(5)}.gen.png`;
  } else {
    return sample(avatars);
  }
}

export function randomTeamAvatar(name: string | undefined) {
  return `${randomBrewerHexColor().toUpperCase()}${(name || 'A').toUpperCase().codePointAt(0)?.toString(16)}.chr.png`;
}

export function isRandomTeamAvatar(avatar: string | undefined) {
  return avatar && avatar.endsWith('.chr.png');
}

export function getPixelRatio() {
  return SERVER ? 1 : window.devicePixelRatio;
}

function swallowRejection(promise: any) {
  if (promise && 'then' in promise) promise.then(null, (e: any) => DEVELOPMENT && console.error(e));
}

export function toggleFullScreen() {
  const d: any = document;

  if (!d.fullscreenElement && // alternative standard method
    !d.mozFullScreenElement && !d.webkitFullscreenElement && !d.msFullscreenElement) { // current working methods

    if ('requestFullscreen' in d.documentElement) {
      swallowRejection(d.documentElement.requestFullscreen());
    } else if ('msRequestFullscreen' in d.documentElement) {
      swallowRejection(d.documentElement.msRequestFullscreen());
    } else if ('mozRequestFullScreen' in d.documentElement) {
      swallowRejection(d.documentElement.mozRequestFullScreen());
    } else if ('webkitRequestFullscreen' in d.documentElement) {
      swallowRejection(d.documentElement.webkitRequestFullscreen((<any>Element).ALLOW_KEYBOARD_INPUT));
    }
  } else {
    if ('exitFullscreen' in d) {
      swallowRejection(d.exitFullscreen());
    } else if ('msExitFullscreen' in d) {
      swallowRejection(d.msExitFullscreen());
    } else if ('mozCancelFullScreen' in d) {
      swallowRejection(d.mozCancelFullScreen());
    } else if ('webkitExitFullscreen' in d) {
      swallowRejection(d.webkitExitFullscreen());
    }
  }
}

export function canShareUrl() {
  return (isiOS || isAndroid || isChromeOS) && 'share' in navigator && !!navigator.share;
}

export async function shareUrl(password?: string | null) {
  const url = location.href + (password ? `?pass=${password}` : '');

  if (typeof navigator !== 'undefined') {
    if (canShareUrl()) {
      await navigator.share({ title: document.title, url });
    } else if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(url);
      return true;
    }
  }

  return false;
}

// Events

export type AnyEvent = MouseEvent | PointerEvent | TouchEvent;

export const buttonToFlag = [1, 4, 2, 8, 16, 32, 64, 128];

export function isPointer(e: AnyEvent): e is PointerEvent {
  return 'pointerType' in e;
}

export function isTouch(e: AnyEvent): e is TouchEvent {
  return /^touch/i.test(e.type);
}

export function getButton(e: AnyEvent) {
  return ('button' in e) ? (e.button || 0) : 0;
}

export function getPointerId(e: AnyEvent) {
  // Firefox returns wrong numbers when dom.w3c_pointer_events.dispatch_by_pointer_messages is not enabled
  if (isFirefox && (e as PointerEvent).pointerType === 'mouse') return 0;
  return (e as any).pointerId || 0;
}

export function getX(e: AnyEvent) {
  const pageX = ('touches' in e && e.touches.length > 0) ? e.touches[0].pageX : (e as any).pageX;
  if (DEVELOPMENT && !Number.isFinite(pageX)) console.error('invalid X in', e);
  return pageX - (window.scrollX ?? window.pageXOffset);
}

export function getY(e: AnyEvent) {
  const pageY = ('touches' in e && e.touches.length > 0) ? e.touches[0].pageY : (e as any).pageY;
  if (DEVELOPMENT && !Number.isFinite(pageY)) console.error('invalid Y in', e);
  return pageY - (window.scrollY ?? window.pageYOffset);
}

export function attachDebugMethod(name: string, method: any) {
  if (!SERVER) {
    (window as any)[name] = method;
  }
}

// browser check

export function isBrowserOutdated(ua: string) {
  // jsdom
  if (/ jsdom\//.test(ua)) return true;

  // Jetty/9.4.34.v20201102
  if (/Jetty\//.test(ua)) return true;

  // Safari <= 8
  // Mozilla/5.0 (Macintosh; Intel Mac OS X 10_10_1)
  //   AppleWebKit/600.1.25 (KHTML, like Gecko) Version/8.0 Safari/600.1.25
  //   AppleWebKit/600.1.4 (KHTML, like Gecko) Version/8.0 Mobile/12B410 Safari/600.1.4
  const safari = /Version\/(\d+)\.[0-9.]+(?: Mobile\/[A-Z0-9]+)? Safari/.exec(ua);

  if (safari && parseInt(safari[1], 10) <= 8) {
    return true;
  }

  // Android browser
  // Mozilla/5.0 (Linux; U; Android 4.4.2; es-ar; LG-D375AR Build/KOT49I)
  // Mozilla/5.0 (Linux; Android 4.2.2; ZTE V829 Build/JDQ39) AppleWebKit/537.36 (KHTML, like Gecko)
  //   Chrome/31.0.1650.59 Mobile Safari/537.36
  //   AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/30.0.1599.103 Mobile Safari/537.36
  if (/Android /.test(ua) && /AppleWebKit/.test(ua) && (!/chrome/i.test(ua) || /Chrome\/[23][0-9]\./.test(ua))) {
    return true;
  }

  // Old Chrome 20-39
  if (/Chrome\/[23][0-9]\./.test(ua)) {
    return true;
  }

  // Puffin browser
  // Mozilla/5.0 (X11; U; Linux x86_64; en-US) AppleWebKit/537.36 (KHTML, like Gecko)
  //   Chrome/30.0.1599.114 Safari/537.36 Puffin/5.2.0IT
  // if (/ Puffin\//.test(ua)) {
  //   return true;
  // }

  return false;
}

export function dontReportErrors(ua: string) {
  if (isBrowserOutdated(ua)) return true;
  if (/BingPreview/.test(ua)) return true;

  return false;
}

export const baseTitle = typeof document !== 'undefined' ? document.title.substr(document.title.lastIndexOf('|') + 1).trim() : '';

export function setDocumentTitle(value: string) {
  if (typeof document !== 'undefined') {
    const title = value ? `${value} | ${baseTitle}` : baseTitle;
    (window as any)._documentTitle = title; // HACK: value to restore if tawk resets our title
    document.title = title;
  }
}

// mobile check

export let isMobile = isiOS || isAndroid;
export let noKeyboard = false;
export let hasKeyboard = false;

function setToMobile() {
  isMobile = true;

  if (!hasKeyboard) {
    document.body.classList.add('no-keyboard');
    noKeyboard = true;
  }
}

function setMobile() {
  window.removeEventListener('touchstart', setMobile);
  setToMobile();
}

function setMobilePointer(e: PointerEvent) {
  if (e.pointerType !== 'touch') return;
  window.removeEventListener('pointerdown', setMobilePointer);
  setToMobile();
}

function onKeyDown(e: KeyboardEvent) {
  if (isKeyboardEventValid(e)) {
    setHasKeyboard();
  }
}

function setHasKeyboard() {
  hasKeyboard = true;
  window.removeEventListener('keydown', onKeyDown);
  document.body?.classList.remove('no-keyboard');
  noKeyboard = false;
}

if (typeof window !== 'undefined') {
  if (isMobile) {
    setToMobile();
  } else {
    if (typeof PointerEvent !== 'undefined') {
      window.addEventListener('pointerdown', setMobilePointer);
    } else {
      window.addEventListener('touchstart', setMobile);
    }
  }

  window.addEventListener('keydown', onKeyDown);
}

if (isWindows) setHasKeyboard();

export function isControl(e: Element | undefined) {
  return e && /^(input|textarea|select)$/i.test(e.tagName);
}

export function isEventFromControl(e: Event) {
  return !!e && !!e.target && isControl(e.target as HTMLElement);
}

export function isKeyboardEventValid(e: KeyboardEvent) {
  return !isEventFromControl(e) || (e.target as HTMLElement).classList.contains('ks-allow') || (e as MagmaKeyboardEvent).allowKeyboardService;
}

// other

export function createCache<Key, Result>(resolve: (key: Key) => Result, timeoutLength: number) {
  let timeout: any;
  let cache = new Map<Key, { result: Result; last: number; }>();

  let cleanup = () => {
    const removeKeys: Key[] = [];
    const limit = Date.now() - timeoutLength;

    cache.forEach((entry, key) => {
      if (entry.last < limit) removeKeys.push(key);
    });

    removeKeys.forEach(key => cache.delete(key));

    if (cache.size) timeout = setTimeout(cleanup, timeoutLength);
  };

  return (key: Key) => {
    if (!timeout) timeout = setTimeout(cleanup, timeoutLength);

    const cached = cache.get(key);
    const last = Date.now();

    if (cached) {
      cached.last = Date.now();
      return cached.result;
    } else {
      const result = resolve(key);
      cache.set(key, { last, result });
      return result;
    }
  };
}

export function parseCSV(csv: string) {
  return csv.trim().split(/\r?\n/g).map(row => row.split(/,/g));
}

export function serializeCSV(data: any[][], separator = ',') {
  return data.map(row => row.map(item =>
    `${item ?? ''}`.replace(/,/g, '.').replace(/;/g, ':').replace(/"/g, `'`).replace(/\r?\n/g, ' ')).join(separator)).join('\n');
}

// tawk

export function canOpenTawk() {
  try {
    return typeof Tawk_API !== 'undefined' && typeof Tawk_API.showWidget === 'function';
  } catch {
    return false;
  }
}

export function openTawk() {
  try {
    Tawk_API?.showWidget?.();
    setTimeout(() => {
      try {
        Tawk_API?.maximize?.();
      } catch { }
    });
  } catch { }
}

// other

// tslint:disable:max-line-length
export const OBJECT_ID_REGEX = /^[a-fA-F\d]{24}$/;
export const EMAIL_REGEX = /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
export const HTTP_URL_REGEX = /^https?:\/\/(.*)$/;

export function isValidEmail(email: string) {
  return EMAIL_REGEX.test(email);
}

export const AVATAR_REGEX = /^([$a-zA-Z0-9_-][$a-zA-Z0-9_.-]{1,100}|)$/;
export function isValidAvatar(avatar: string) {
  return AVATAR_REGEX.test(avatar);
}

export function isValidUserColor(color: string) {
  return USER_COLORS.indexOf(color) !== -1;
}

export function assertEmptyObject(value: {}) { // TODO: somehow error at compile time ?
  if (DEVELOPMENT && Object.keys(value).length !== 0) {
    throw new Error(`Object should be empty, keys: ${Object.keys(value)}`);
  }
}

export function callNowOrAfterSwitchedToTab(action: () => void) {
  const handler = () => {
    if (!document.hidden) {
      document.removeEventListener('visibilitychange', handler);
      action();
    }
  };
  document.addEventListener('visibilitychange', handler); // delay showing message until tab is active
  handler();
}

export function accountHasPro(account: { pro?: boolean }) {
  if (IS_HOSTED) return true;
  return !!account.pro;
}

// TEMP
export function isBlackFridayLive() {
  return false;
  // const from = new Date('Nov 22 2021 00:00:00 UTC').getTime();
  // const to = new Date('Nov 30 2021 11:59:59 UTC').getTime();
  // const now = new Date().getTime();
  // return now >= from && now <= to;
}

export function quickChecksum(value: string) {
  let sum = 0x12345678;

  for (let i = 0; i < value.length; i++) {
    sum += value.charCodeAt(i) * (i + 1);
  }

  return (sum & 0xffffffff).toString(16);
}

export function urlForOauth(profile: OauthProfile) {
  switch (profile.provider) {
    case 'twitter': return `https://twitter.com/${profile.identifier}`;
    case 'github': return `https://github.com/${profile.identifier}`;
    default: return undefined;
  }
}

export function maskPassword(password: string) {
  let result = '';
  for (let i = 0; i < password.length; i++) result += '*';
  return result;
}

export async function copy(target: HTMLInputElement) {
  try {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(target.value);
    }
  } catch (e) {
    DEVELOPMENT && console.warn(e);
  }

  target.focus();
  target.select();
  document.execCommand('copy');
}

function fallbackCopyText(text: string) {
  const element = document.createElement('input');
  element.value = text;

  // make it focusable and not visible
  element.style.width = '2em';
  element.style.height = '2em';
  element.style.top = '0';
  element.style.left = '0';
  element.style.position = 'fixed';
  element.style.border = 'none';
  element.style.outline = 'none';
  element.style.boxShadow = 'none';
  element.style.background = 'transparent';

  document.body.appendChild(element);
  element.focus();
  element.select();
  document.execCommand('copy');
  document.body.removeChild(element);
}

export async function copyText(text: string) {
  try {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    }
  } catch (e) {
    DEVELOPMENT && console.warn(e);
  }

  fallbackCopyText(text);
}

export function adjustThreadLocationToFitInViewport(tw: number, th: number, margin: number, point: Point, viewport: Viewport) {
  const cw = viewport.width;
  const ch = viewport.height;

  if (cw && (point.x + tw + margin * 2) < cw) {
    // done by css
  } else if (cw && (point.x - tw - margin * 2) > 0) {
    point.x = point.x - tw - margin * 2;
  } else {
    point.x = cw / 2 - tw / 2;
  }

  if (ch && (th + point.y + margin * 2) < ch) {
    // done by css
  } else if (ch && (point.y - th - margin * 2) > 0) {
    point.y = point.y - th - margin * 2;
  } else {
    point.y = ch / 2 - th / 2;
  }
}

export function toBase64Url(data: Uint8Array) {
  return fromByteArray(data).replace(/\+/g, '-').replace(/\//g, '_');
}

export function fromBase64Url(encoded: string) {
  return toByteArray(encoded.replace(/-/g, '+').replace(/_/g, '/'));
}

export function byteSize(size: number) {
  if (size < KB) {
    return `${size}B`;
  } else if (size < MB) {
    return `${(size / KB).toFixed(1)}KB`;
  } else if (size < GB) {
    return `${(size / MB).toFixed(1)}MB`;
  } else {
    return `${(size / GB).toFixed(1)}GB`;
  }
}

export function textFieldToArray(text: string) {
  return text.split(/\r?\n/g).map(x => x.trim()).filter(x => x);
}

export function arrayToTextField(text?: string[]) {
  return (text || []).join('\r\n');
}

export function drawAvatarOnCanvas(canvas: HTMLCanvasElement, color: number[], fontSize: number, fontFamily: string, text: string) {
  const ctx = canvas.getContext('2d');
  if (!ctx) return;

  const foregroundcolor = perceivedBrightness(color) > 130 ? 'black' : 'white';
  const font = `${fontSize}px ${fontFamily}`;

  ctx.fillStyle = `rgb(${color.join(',')})`;
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  ctx.fillStyle = foregroundcolor;

  ctx.font = font;
  ctx.textBaseline = 'middle';
  ctx.textAlign = 'center';

  let { actualBoundingBoxAscent, actualBoundingBoxDescent } = ctx.measureText(text);
  ctx.fillText(text, 256 / 2, 256 / 2 + (actualBoundingBoxAscent - actualBoundingBoxDescent) / 2);
}


export function relativeTime(value: string | Date | undefined) {
  if (!value) return '';

  const elapsed = Date.now() - new Date(value).getTime();

  if (elapsed < MINUTE) {
    return `${Math.round(elapsed / SECOND)}s`;
  } else if (elapsed < HOUR) {
    return `${Math.round(elapsed / MINUTE)}m`;
  } else if (elapsed < DAY) {
    return `${Math.round(elapsed / HOUR)}h`;
  } else if (elapsed < MONTH) {
    return `${Math.round(elapsed / DAY)}d`;
  } else if (elapsed < YEAR) {
    return `${Math.round(elapsed / MONTH)}mo`;
  } else {
    return `${Math.round(elapsed / YEAR)}yr`;
  }
}

export function hasFlags(flagBitNumbers: Permission[], sumOfRequiredPermissionFlags: number[]) {
  for (let i = 0; i < flagBitNumbers.length; i++) {
    const bit = flagBitNumbers[i] % 32;
    const byte = (flagBitNumbers[i] / 32) | 0;
    if (!(sumOfRequiredPermissionFlags[byte] & (1 << bit))) return false;
  }
  return true;
}

export function hasFlag(flagBitNumber: Permission, sumOfRequiredPermissionFlags: number[]) {
  const bit = flagBitNumber % 32;
  const byte = (flagBitNumber / 32) | 0;
  return !!(sumOfRequiredPermissionFlags[byte] & (1 << bit));
}

export function setFlags(flagBitNumbers: Permission, sumOfRequiredPermissionFlags: number[]) {
  const bit = flagBitNumbers % 32;
  const byte = (flagBitNumbers / 32) | 0;

  if (sumOfRequiredPermissionFlags.length > byte) {
    sumOfRequiredPermissionFlags[byte] = sumOfRequiredPermissionFlags[byte] | (1 << bit);
  } else {
    const b = (1 << bit);
    while (sumOfRequiredPermissionFlags.length < byte) sumOfRequiredPermissionFlags.push(0);
    sumOfRequiredPermissionFlags.push(b);
  }
}

export const getValueOfVariable = memoizee((variableName: string) => {
  if (typeof document === 'undefined') return undefined;
  const element = document.getElementById(variableName);
  if (!element) {
    DEVELOPMENT && console.error(`Cannot find element with id ${variableName}`);
    return undefined;
  }
  const content = element.textContent?.trim();
  if (content) {
    try {
      return JSON.parse(content);
    } catch (e) {
      DEVELOPMENT && console.error(e);
    }
  }
  return undefined;
});

export function isObjectId(value: string) {
  return value.length === 24 && /^[0-9a-f]+$/.test(value);
}

export function createCopyName(originalName: string, existingNames: string[]) {
  let name = '';
  let n = 1;

  do {
    name = originalName.replace(/Copy( \d+)?$/, '').trim() + (n === 1 ? ' Copy' : ` Copy ${n}`);
    n++;
  } while (existingNames.includes(name));

  return name;
}

export type AutoOr<T> = typeof AUTO_SETTING_STRING | T;
export const AUTO_SETTING_STRING = 'auto';


export function contactSupportIntercom(reason: string, failCallback?: () => void) {
  if (window.Intercom) {
    // opening intercom
    window.Intercom('showNewMessage', reason);

    setTimeout(() => {
      let messageSent = false;
      const elements = document.getElementsByName('intercom-messenger-frame');
      if (elements.length) {
        const intercomFrame = elements[0] as HTMLIFrameElement;
        if (intercomFrame) {
          const intercomDocument = intercomFrame.contentWindow?.document;
          const availableButtons = intercomDocument?.getElementsByTagName('button');
          const reportingABugButton = Array.from(availableButtons || []).filter(e => e.innerHTML === reason);
          if (reportingABugButton.length) {
            // clicking the report a bug button
            reportingABugButton[0].click();
            messageSent = true;
          } else {
            // sending reason message
            const sendButton = intercomDocument?.querySelector('.intercom-composer-send-button') as HTMLElement;
            if (sendButton) {
              sendButton.click();
              messageSent = true;
            }
          }
        }
      }
      // redirecting in case no both cases fails
      if (!messageSent && failCallback) failCallback();
    }, 2000);
  } else {
    DEVELOPMENT && console.error('Missing Intercom');
    if (failCallback) failCallback();
  }
}
