import { ITool, QuickAction, Settings, SettingsUpdate, ToolId, ToolSlot, ToolSlotTool, ToolSource } from '../common/interfaces';
import { defaultSettings, parseSettings } from '../common/settings';
import { deepEqual, cloneDeep } from '../common/utils';
import { findById } from '../common/baseUtils';
import { getViewportState, setViewportState } from '../common/viewport';
import { createTool } from '../common/tools';
import { toolIdToString, toolIdFromString } from '../common/toolIdUtils';
import { setKeyboardLayout } from '../common/input';
import { MINUTE, TOOL_SLOTS } from '../common/constants';
import { logAction } from '../common/actionLog';
import { selectTool } from './otherActions';
import { storageGetItem, storageRemoveItem, storageSetItem } from './storage';
import { redraw, setEditorColor } from './editorUtils';
import type { Model } from './model';
import { getShortcutsAsString } from '../common/settingsUtils';
import { asOpaque } from '../common/color';
import { getLastToolSource } from '../common/toolUtils';
import { take } from 'rxjs';

const SETTINGS_SAVE_TIMEOUT = 1 * MINUTE;
const TOOLSLOT_KEYS: (keyof ToolSlot)[] = ['activeTool', 'primaryColor', 'secondaryColor'];

export function resetRemoteSettings(model: Model) {
  model.lastSettings = defaultSettings();
}

export function scheduleSaveSettings(model: Model) {
  if (TESTS) return;

  model.scheduledSettingsSave = model.scheduledSettingsSave || setTimeout(() => saveSettings(model), SETTINGS_SAVE_TIMEOUT);
}

export function saveSettings(model: Model) {
  saveSlot(model, model.activeSlot);
  model.settings.activeSlot = Math.max(0, model.settings.slots.indexOf(model.activeSlot!));

  if (model.editor) {
    model.settings.view = getViewportState(model.editor.view);
    model.settings.primaryColor = model.editor.primaryColor;
    model.settings.secondaryColor = model.editor.secondaryColor;
  } else {
    DEVELOPMENT && console.warn('No editor when saving settings');
  }

  saveSettingsInternal(model);
  clearTimeout(model.scheduledSettingsSave);
  model.scheduledSettingsSave = 0;
}

export function resetSettings(model: Model) {
  storageRemoveItem('settings');
  loadSettings(model);
  scheduleSaveSettings(model);
}

export function setActiveSlot(model: Model, slot: ToolSlot | undefined) {

  if (model.activeSlot !== slot && !model.editor?.drawingInProgress) {
    saveSlot(model, model.activeSlot);

    if (model.settings.keepActiveTool && slot && model.activeSlot) {
      slot.activeTool = model.activeSlot.activeTool;
    }

    if (model.editor && slot?.activeTool) {
      const tool = findById(model.editor.tools, toolIdFromString(slot.activeTool));
      if (tool?.feature && model.editor.featureFlags && !model.editor.featureFlags.isFeatureSupported(tool.feature)) {
        slot.activeTool = toolIdToString(ToolId.Brush);
      }
    }

    model.activeSlot = slot;
    loadSlot(model, model.activeSlot);
    scheduleSaveSettings(model);
    model.tryQuickAction(QuickAction.ReportToolStat, `slot ${getLastToolSource()}`);
  }
}

export function invalidateSettingsCache(model: Model) {
  model.toolTitles.clear();
  model.commandTitles.clear();
  model.settingsChanged.next();
}

export function getToolTitle(model: Model, { id, name }: ITool) {
  let title = model.toolTitles.get(id);

  if (title === undefined) {
    const shortcuts = getShortcutsAsString(model, toolIdToString(id));
    title = `${name}${shortcuts ? ` [${shortcuts}]` : ''}`;
    model.toolTitles.set(id, title);
  }

  return title;
}

export function loadSettings(model: Model, settingsJson?: string) {
  if (model.editor?.drawingInProgress) return;

  logAction('[local] loadSettings');

  try {
    model.settings = parseSettings(settingsJson ?? storageGetItem('settings'));
    model.lastSettings = cloneDeep(model.settings);
  } catch (e) {
    DEVELOPMENT && console.error(e);
  }

  initSettingsOnEditor(model);

  invalidateSettingsCache(model);
  setKeyboardLayout(model.settings.keyboardLayout);
}

export function initSettingsOnEditor(model: Model) {
  if (model.editor) {
    setEditorColor(model.editor, model.settings.primaryColor, true);
    setEditorColor(model.editor, model.settings.secondaryColor, false);

    // reset all tools to default settings
    for (const tool of model.editor.tools) {
      if (tool.fields) {
        const def = createTool(tool.id, model.editor, model);

        for (const field of tool.fields) {
          (tool as any)[field] = (def as any)[field];
        }
      }
    }

    if (model.settings.view) {
      setViewportState(model.editor.view, model.settings.view);
      redraw(model.editor);
    }
  }

  model.activeSlot = model.settings.slots[model.settings.activeSlot];
  loadSlot(model, model.activeSlot); // load tools settings
  if (model.editor) model.editor.toolSource = ToolSource.None; // reset tool source, which was set by loadSlot
}

function saveSettingsInternal(model: Model) {
  // const diff = createDiff(model.lastSettings, model.settings);

  // if (diff) {
  //  if (model.model.isConnected) model.model.server.updateSettings(diff);
  //   const json = JSON.stringify(model.settings);
  //   storageSetItem('settings', json);
  //   model.lastSettings = JSON.parse(json);
  // }

  // cleanup empty shortcuts entries
  for (const key of Object.keys(model.settings.shortcuts)) {
    if (!model.settings.shortcuts[key]?.length) {
      delete model.settings.shortcuts[key];
    }
  }

  const json = JSON.stringify(model.settings);

  if (model.isConnected) {
    const updates: SettingsUpdate[] = [];

    for (const key of (Object.keys(model.settings) as (keyof Settings)[])) {
      if (key === 'slots') {
        const slots = model.settings.slots;
        const lastSlots = model.lastSettings.slots;

        for (let i = 0; i < TOOL_SLOTS; i++) {
          const slot = slots[i];
          const lastSlot = lastSlots[i];

          if (!deepEqual(slot, lastSlot)) {
            for (const slotKey of TOOLSLOT_KEYS) {
              if (slot[slotKey] !== lastSlot[slotKey]) {
                updates.push(['slots', i, slotKey, (lastSlot as any)[slotKey] = slot[slotKey] as any]);
              }
            }

            if (slot.tools) {
              for (let j = 0; j < slot.tools.length; j++) {
                const tool = slot.tools[j];
                const lastTool = lastSlot.tools?.[j];

                if (!deepEqual(tool, lastTool)) {
                  if (!lastSlot.tools) lastSlot.tools = [];
                  updates.push(['slots', i, j, lastSlot.tools[j] = cloneDeep(tool)]);
                }
              }
            } else {
              updates.push(['slots', i, 'tools', lastSlot.tools = cloneDeep(slot.tools)]);
            }
          }
        }
      } else {
        if (!deepEqual(model.settings[key], model.lastSettings[key])) {
          updates.push([key, (model.lastSettings as any)[key] = cloneDeep(model.settings[key])]);
        }
      }
    }

    if (updates.length) {
      if (updates.length > 10) {
        model.server.saveSettings(json);
      } else {
        model.server.saveSettingsPartial(updates);
      }
    }
  }

  storageSetItem('settings', JSON.stringify(model.settings));
}

function saveSlot(model: Model, slot: ToolSlot | undefined) {
  if (slot && model.editor?.selectedTool) {
    slot.tools = getToolsSettings(model);
    slot.activeTool = toolIdToString(model.editor.selectedTool.id);

    if (model.settings.saveColorsPerSlot) {
      slot.primaryColor = model.editor.primaryColor;
      slot.secondaryColor = model.editor.secondaryColor;
    }
  }
}

function resetToolSettings(model: Model) {
  if (!model.editor) return;

  // check if drawing is loaded
  if (model.editor.drawing.id !== '') {
    model.editor.selectedTool?.resetSettings?.();
  } else {
    model.finishedLoadingDrawing.pipe(take(1)).subscribe(() => {
      model.editor?.selectedTool?.resetSettings?.();
    });
  }
}

function loadSlot(model: Model, slot: ToolSlot | undefined) {
  if (slot && slot.tools && slot.activeTool !== undefined && model.editor) {
    setToolsSettings(model, slot.tools);
    selectTool(model.editor, findById(model.editor.tools, toolIdFromString(slot.activeTool)), ToolSource.SlotSwitch);

    resetToolSettings(model);

    if (model.settings.saveColorsPerSlot) {
      if (slot.primaryColor !== undefined) setEditorColor(model.editor, asOpaque(slot.primaryColor), true);
      if (slot.secondaryColor !== undefined) setEditorColor(model.editor, asOpaque(slot.secondaryColor), false);
    }
  } else {
    saveSlot(model, slot);
  }
}

function getToolsSettings(model: Model) {
  return model.editor!.tools
    .filter(tool => !!tool.fields)
    .map(tool => {
      const toolSettings: ToolSlotTool = { id: toolIdToString(tool.id) };
      tool.fields!.forEach(field => (toolSettings as any)[field] = cloneDeep((tool as any)[field]));
      return toolSettings;
    });
}

function setToolsSettings(model: Model, settings: ToolSlotTool[]) {
  for (const toolSettings of settings) {
    const tool = findById(model.editor!.tools, toolIdFromString(toolSettings.id));
    if (tool) setToolSettings(tool, toolSettings);
  }
}

function setToolSettings(tool: ITool, toolSettings: ToolSlotTool) {
  for (const key of Object.keys(toolSettings)) {
    if ((tool as any)[key] !== undefined && key !== 'id') {
      (tool as any)[key] = cloneDeep((toolSettings as any)[key]);
    }
  }
}
