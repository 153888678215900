import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { HelpMessage, HelpSeverity, MsgType, IMsg, Analytics } from 'magma/common/interfaces';
import { ITrackService } from 'magma/services/track.service.interface';
import { getViewPageEvent, ViewPageEvent } from 'shared/analytics';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class TrackService implements ITrackService {
  constructor(
    private httpClient: HttpClient,
    private injector: Injector
  ) { }

  event(eventName: string, props: any = {}) {
    window.Intercom?.('trackEvent', eventName, props);

    props.$current_url = location.href;
    props.$pathname = location.pathname + location.search;

    if (document.referrer) {
      try {
        const ref = new URL(document.referrer);
        if (ref.host !== location.host) {
          props.$referrer = ref.href;
          props.$referring_domain = ref.host;
        }
      } catch (e) {
        DEVELOPMENT && console.warn(e);
      }
    }

    this.httpClient.post('/api/track/event', { eventName, props })
      .toPromise()
      .catch(e => DEVELOPMENT && console.error(e));
  }

  handleHelpMessage(message: HelpMessage) {
    if (shouldReportHelpMessageAsMistake(message)) {
      this.getUserData().then((user) => {
        const mistake = helpMessageToMistake(message, getViewPageEvent(user));
        this.event(Analytics.UserMistake, mistake);
      }).catch(e => { DEVELOPMENT && console.log(e); });
    }
  }

  handleToast(toast: IMsg) {
    if (shouldReportToastAsMistake(toast)) {
      this.getUserData().then((user) => {
        const mistake = toastToMistake(toast, getViewPageEvent(user));
        this.event(Analytics.UserMistake, mistake);
      }).catch(e => { DEVELOPMENT && console.log(e); });
    }
  }

  private getUserData() {
    return firstValueFrom((this.injector.get(UserService) as UserService).user$);
  }
}

enum MistakeFeedbackChannel {
  Tooltip = 'tooltip', // helpService tooltips extending over layer list
  Toast = 'toast', // floating toasts in bottom left corner
}

interface Mistake extends ViewPageEvent {
  title: string
  message: string;
  channel: MistakeFeedbackChannel;
  color: string;
  highlights: string;
}

const shouldReportHelpMessageAsMistake = (message: HelpMessage): boolean => {
  message;
  return true;
};

const helpMessageToMistake = (message: HelpMessage, viewpageEvent: ViewPageEvent): Mistake => {
  return {
    ...viewpageEvent,
    title: message.text,
    message: '',
    channel: MistakeFeedbackChannel.Tooltip,
    color: message.severity === HelpSeverity.Warning ? 'rgb(210,73,6)' : 'rgb(0,188,96)',
    highlights: (message.highlights || []).join(','),
  };
};

const shouldReportToastAsMistake = (toast: IMsg): boolean => {
  const { type } = toast;
  return type === MsgType.Error || type === MsgType.Warning || type === MsgType.Alert;
};

const toastToMistake = (toast: IMsg, viewpageEvent: ViewPageEvent): Mistake => {
  const toastElement = findToastElement(toast);
  return {
    ...viewpageEvent,
    title: toast.title ? toast.title : toast.message,
    channel: MistakeFeedbackChannel.Toast,
    color: toastElement ? getComputedStyle(toastElement).backgroundColor : '',
    message: [
      toast.title ? toast.message : undefined, toast.subtitle
    ].filter(txt => txt !== undefined).join(','),
    highlights: '',
  };
};

type ToastElement = HTMLElement & { toastReportSubmitted: boolean };
const findToastElement = (toast: IMsg): ToastElement | undefined => {
  // due to complexity of toast combinations (boxed/embedded/toasts) and (dark/light theme) and (alert, error, warning...)
  // it's tough to have it working here and to not have consts referring to magma stylesheets that need to be updated and synced at all times
  // we will perform one DOM search for element to report real outputed background color, no puppies will be harmed
  // although that created other problem - handling toast-mistake report is now reliant on UI layer,
  // and it's being executed in component instead of service (to have element present to check it) - but I guess that's lesser evil
  const matchingToasts = Array.from(document.querySelectorAll(`.msg.msg-toast.${toast.type}`)) as ToastElement[];
  for (const matchingToast of matchingToasts) {
    if (!matchingToast.toastReportSubmitted) {
      matchingToast.toastReportSubmitted = true;
      return matchingToast;
    }
  }
  return undefined;
};
