import { Directive, OnInit, OnDestroy, ElementRef, NgZone } from '@angular/core';
import { Model } from '../../../services/model';

const options = { passive: false, capture: true };

@Directive({ selector: '[preventTouch]' })
export class PreventTouch implements OnInit, OnDestroy {
  private allowNextTouch = false;
  constructor(private element: ElementRef<HTMLElement>, private model: Model, private zone: NgZone) {
  }
  private preventTouch = (e: PointerEvent | TouchEvent) => {
    if (this.model.settings.disableTouch) {
      const pe = e as PointerEvent;

      if (pe.pointerType && pe.pointerType !== 'touch') {
        this.allowNextTouch = true;
      } else if (e.type === 'touchstart' || e.type === 'touchend' || pe.pointerType === 'touch') {
        if (this.allowNextTouch && (e.type === 'touchstart' || e.type === 'touchend')) {
          if (e.type === 'touchend') this.allowNextTouch = false;
        } else {
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
        }
      }
    }
  };
  ngOnInit() {
    this.zone.runOutsideAngular(() => {
      const element = this.element.nativeElement;
      element.addEventListener('touchstart', this.preventTouch, options);
      element.addEventListener('touchend', this.preventTouch, options);
      element.addEventListener('pointerdown', this.preventTouch, options);
    });
  }
  ngOnDestroy() {
    const element = this.element.nativeElement;
    element.removeEventListener('touchstart', this.preventTouch, options);
    element.removeEventListener('touchend', this.preventTouch, options);
    element.removeEventListener('pointerdown', this.preventTouch, options);
  }
}
