import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProCreatorInfo } from 'magma/common/interfaces';
import { toPromise } from 'shared/utils';

@Injectable({ providedIn: 'root' })
export class CreatorInfoService {
  constructor(private http: HttpClient) {
  }

  getCreatorInfo(id: string) {
    return toPromise(this.http.get<ProCreatorInfo>(`/api/profile/creator-info/${id}`));
  }
}
