import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OptionsConfirmationModalInput } from '../../../../services/admin-modals.service';

@Component({
  selector: 'options-confirmation-modal',
  templateUrl: 'options-confirmation-modal.component.pug',
})
export class OptionsConfirmationModal {
  @Output() close = new EventEmitter<number | undefined>();
  @Input() data!: OptionsConfirmationModalInput;

  closeModal() {
    this.close.emit();
  }

  selectOption(value: number) {
    this.close.emit(value);
  }
}
