import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Analytics } from '../../../../common/interfaces';
import { sendGAEvent } from '../../../../common/utilsAnalytics';
import { LoginSignupService } from '../../../../services/login-signup.service';

@Component({
  selector: 'sign-in',
  templateUrl: 'sign-in.pug',
  styleUrls: ['sign-in.scss'],
})
export class SignIn {
  @Input() inModal = true;
  @Output() close = new EventEmitter<void>();
  @Output() modeChange = new EventEmitter<string>();
  constructor(public loginSignupService: LoginSignupService) {
  }
  ngOnInit() {
    sendGAEvent('Sign in', 'Viewed signin form', null);
    this.loginSignupService.trackEvent(Analytics.ViewSigninFormScreen, { formOrigin: this.inModal ? 'user-menu' : 'login-page' });
  }
  cancel() {
    this.close.emit();
  }
}
