import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface ChoiceModalOption {
  id: string;
  label: string;
}

@Component({
  selector: 'choice-modal',
  templateUrl: 'choice-modal.pug',
})
export class ChoiceModal {
  @Input() data: ChoiceModalOption[] = [];
  @Output() close = new EventEmitter<string | undefined>();
}
