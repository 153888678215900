export const userAgent = SERVER ? 'server' : navigator.userAgent;
export const isIE = /Trident/.test(userAgent);
export const isMac = /Macintosh/.test(userAgent);
export const isWindows = /Windows/.test(userAgent);
export const isiPad = SERVER ? false : (/iPad/.test(userAgent) || !!(isMac && navigator.maxTouchPoints && navigator.maxTouchPoints > 2));
export const isiPhone = /iPhone/.test(userAgent);
export const isiOS = isiPad || isiPhone;
export const isChromeOS = /\bCrOS\b/.test(userAgent);
export const isAndroid = /Android/.test(userAgent);
export const isFirefox = /firefox/i.test(userAgent);
export const isSafari = /safari/i.test(userAgent) && !/chrome/i.test(userAgent);

if (isFirefox && typeof document !== 'undefined') document.body.classList.add('is-firefox');

export const isWebpSupported = !isSafari;
