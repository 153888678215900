import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DAY, SECOND, USER_COLORS } from '../../../common/constants';
import { hasVoiceChat, Model } from '../../../services/model';
import {
  faSignOut, faComment, faGlobe, faSpinner, faSignIn, faUser, adminIcon, faPlug, faPhone, faMicrophoneSlash,
  endCallIcon, faCheck, faBan, faCog, faUserCircle, commentStarIcon, blazeIcon, blazeBoxIcon
} from '../../../common/icons';
import { getRoleIcon, getRoleTitle } from '../../../common/userRole';
import { ErrorReporter } from '../../../services/errorReporter';
import { delay } from '../../../common/promiseUtils';
import { colorYIQ } from '../../../common/color';
import { SubscriptionService } from '../../../services/subscription';
import { VoiceChatService } from '../../../services/voiceChatService';
import { Modals } from '../../../services/modals';
import { signUps } from '../../../common/data';
import { PublicPromoCodeInfo } from '../../../common/interfaces';
import { LoginSignupService } from '../../../services/login-signup.service';
import { CouponService } from '../../../services/couponService';
import { storageGetItem, storageSetItem } from '../../../services/storage';
import { ManageService } from '../../../services/manageService';

@Component({
  selector: 'user-button',
  templateUrl: 'user-button.pug',
  styleUrls: [
    'user-button-shared.scss',
    'user-button.scss',
  ],
})
export class UserButton implements OnInit, OnDestroy {
  readonly blazeBoxIcon = blazeBoxIcon;
  readonly websiteIcon = faGlobe;
  readonly signOutIcon = faSignOut;
  readonly signInIcon = faSignIn;
  readonly userSettingsIcon = faUserCircle;
  readonly chatIcon = faComment;
  readonly userIcon = faUser;
  readonly speakingIcon = faPhone;
  readonly mutedIcon = faMicrophoneSlash;
  readonly ownerIcon = adminIcon;
  readonly checkIcon = faCheck;
  readonly banIcon = faBan;
  readonly feedbackIcon = commentStarIcon;
  readonly accountIcon = faCog;
  readonly colors = USER_COLORS;
  readonly samlActive = signUps.includes('saml');
  readonly blazeIcon = blazeIcon;
  @Input() showChat = true;
  @Input() showVoiceCall = true;
  @Input() inPortal = false;
  readonly colorYIQ = colorYIQ;
  error: string | undefined = undefined;
  errorClass = 'danger-tooltip';
  private subscription: Subscription | undefined = undefined;
  private coupon: PublicPromoCodeInfo | undefined;
  constructor(
    public element: ElementRef<HTMLElement>,
    private model: Model,
    private modals: Modals,
    private errorReporter: ErrorReporter,
    private subscriptionService: SubscriptionService,
    private voiceChat: VoiceChatService,
    private router: Router,
    public loginSignupService: LoginSignupService,
    private couponService: CouponService,
    private manage: ManageService,
  ) {
  }
  async ngOnInit() {
    this.subscription = this.model.onUserError.subscribe(({ message, type }) => this.showError(message, type));
    this.voiceChat.userElement = this.element.nativeElement;
    this.coupon = await this.couponService.getPromo();

    if (this.coupon && storageGetItem('coupon-added') !== this.coupon.name) {
      storageSetItem('coupon-added', this.coupon.name);
      this.model.onUserError.next({ type: 'success', message: this.getPromoMessage(this.coupon) });
    }
  }
  ngOnDestroy() {
    this.subscription?.unsubscribe();

    if (this.voiceChat.userElement === this.element.nativeElement) {
      this.voiceChat.userElement = undefined;
    }
  }
  get user() {
    return this.model.user;
  }
  get isConnected() {
    return this.model.isConnected;
  }
  get isSleeping() {
    return this.model.isSleeping;
  }
  get roleIcon() {
    return getRoleIcon(this.user);
  }
  get roleTitle() {
    return getRoleTitle(this.user);
  }
  get spinnerIcon() {
    return this.isSleeping ? faPlug : faSpinner;
  }
  get spinnerTitle() {
    return this.isSleeping ? 'Disconnected due to inactivity' : 'Connecting...';
  }
  get canCreateAccount() {
    return this.user.anonymous && !!signUps.length;
  }
  signIn() {
    this.modals.signIn();
  }
  signOut() {
    this.loginSignupService.signOut().catch(e => DEVELOPMENT && console.error(e));
  }
  createAccount() {
    this.modals.createAccount();
  }
  accountSettings() {
    void this.router.navigate(['/my/account']);
  }
  settings() {
    this.modals.userSettings();
  }
  get hasPro() {
    return this.user.pro;
  }
  async changeColor(color: string) {
    try {
      if (!this.colorTaken(color)) {
        await this.model.changeColor(color);
      }
    } catch (e) {
      this.showError(e.message);
    }
  }
  colorTaken(color: string): boolean {
    return this.model.colorTaken(color);
  }
  async selectAvatar(file: File) {
    try {
      await this.model.changeAvatar(file);
    } catch (e) {
      this.showError(e.message);
    }
  }
  private errorTimeout: any;
  private showError(message: string, type = 'danger') {
    void this.showErrorInner(message, type);
  }
  private async showErrorInner(message: string, type: string) {
    if (this.error) {
      this.error = undefined;
      clearTimeout(this.errorTimeout);
      await delay(1);
    }

    this.error = message;
    this.errorClass = `${type}-tooltip`;
    this.errorTimeout = setTimeout(() => this.error = undefined, 10 * SECOND);
  }
  hideError() {
    this.error = undefined;
    clearTimeout(this.errorTimeout);
  }
  // voice chat
  get hasVoiceChat() {
    return hasVoiceChat(this.model);
  }
  get inVoiceCall() {
    return this.voiceChat.isConnected;
  }
  get mutedText() {
    if (this.voiceChat.isMuted()) return 'muted';
    if (this.voiceChat.mutedByAdmin.has(this.user.uniqId)) return 'muted by admin';
    return undefined;
  }
  get isConnectedOrConnectingToVoiceChat() {
    return this.voiceChat.isJoining || this.voiceChat.isConnected || this.voiceChat.isConnecting;
  }
  get voiceChatIcon() {
    return this.isConnectedOrConnectingToVoiceChat ? endCallIcon : faPhone;
  }
  get voiceChatText() {
    if (this.isConnectedOrConnectingToVoiceChat) {
      return 'Leave voice call';
    } else if (this.voiceChat.session) {
      return 'Join voice call';
    } else {
      return 'Start voice call';
    }
  }
  async voiceChatAction() {
    try {
      this.hideError();

      if (this.isConnectedOrConnectingToVoiceChat) {
        this.voiceChat.stop();
      } else {
        await this.voiceChat.start();
      }
    } catch (e) {
      this.showError(e.message);
    }
  }
  // subscription
  async manageSubscription() {
    try {
      await this.subscriptionService.manageSubscription();
    } catch (e) {
      if (e.message === 'Cancelled') return;
      this.errorReporter.reportError('Manage subscription error', e);
      this.showError(e.message);
    }
  }
  upgradeToPro() {
    this.manage.upgrade('user-button');
  }
  getPromoMessage(info: PublicPromoCodeInfo) {
    const promoName = (info.percentOff === 100 && info.durationInMonths) ?
      `${info.durationInMonths} months free` : `${info.percentOff}% OFF`;
    return `Your ${promoName} ${info.promoCode} coupon has been applied. Click here to redeem it.`;
  }
  get discount() {
    return this.coupon?.percentOff;
  }
  get discountMonths() {
    return this.coupon?.durationInMonths;
  }
  get initialFreeMonths() {
    return (this.coupon && this.coupon.percentOff === 100) ? this.coupon.durationInMonths : 0;
  }
  get discountLabel() {
    if (!this.coupon || !this.coupon.percentOff) {
      return undefined;
    } else if (this.initialFreeMonths) {
      return `-${this.coupon.durationInMonths}mo`;
    } else {
      return `-${this.coupon.percentOff}%`;
    }
  }
  get discountLabelLong() {
    if (this.initialFreeMonths) {
      return `First ${this.coupon?.durationInMonths} months FREE`;
    } else {
      return `${this.coupon?.percentOff}% OFF`;
    }
  }
  get hasTrial() {
    return !!this.coupon?.percentOff && this.coupon.percentOff < 100;
  }
  get hasTrial2() {
    return this.manage.hasTrial();
  }
  get showUpgrade() {
    return !(this.hasPro || this.paymentFailed);
  }
  get subscriptionLabel() {
    if (this.paymentFailed) {
      return 'Payment failed';
    } else if (this.user.subscriptionStatus?.status === 'trialing') {
      const days = Math.ceil((this.currentPeriodEnd - Date.now()) / DAY);
      return `${days} ${days === 1 ? 'day' : 'days'} of trial left`;
    } else {
      return this.user.subscriptionStatus?.cancelAtPeriodEnd && !this.user.pro ? 'Re-activate subscription' : 'Manage subscription';
    }
  }
  get paymentFailed() {
    return this.user.subscriptionStatus?.status === 'unpaid';
  }
  get showPeriodEnd() {
    return !this.paymentFailed && !!this.user.subscriptionStatus?.currentPeriodEnd;
  }
  get currentPeriodEnd() {
    let end = this.user.subscriptionStatus?.currentPeriodEnd ?? 0;
    if (!IS_PORTAL) end *= 1000;
    return end;
  }
  get isProForced() {
    return !this.user.proSources?.includes('paid');
  }
  get subscriptionFootnote() {
    if (this.paymentFailed) {
      return 'Update payment method';
    } else if (this.user.subscriptionStatus?.currentPeriodEnd) {
      return this.user.subscriptionStatus?.cancelAtPeriodEnd ? `Canceling at` : `Next charge`;
    } else {
      return 'Free trial';
    }
  }
  // superadmin
  endImpersonation() {
    return this.loginSignupService.endImpersonation();
  }
  get fromSuperAdmin() {
    return this.loginSignupService.isImpersonating();
  }

  isPaidOnlyByUser() {
    const teamIds = this.user.proSources ?? [];
    return teamIds.length === 1 && teamIds[0] === 'paid';
  }

  proSources(): string[] {
    const teamIds = this.user.proSources ?? [];
    const sources: string[] = [];
    if (teamIds.includes('paid')) {
      sources.push(this.user.avatar ?? '');
    }
    if (teamIds.includes('magma')) {
      sources.push('magma'); // it will be replaced in template
    }
    sources.push(...this.manage.teams(teamIds).map(t => t.avatar));
    return sources;
  }
}
