import { Directive, OnInit, ElementRef } from '@angular/core';

@Directive({ selector: '[autoFocus]' })
export class AutoFocus implements OnInit {
  constructor(private element: ElementRef<HTMLElement>) {
  }
  ngOnInit() {
    setTimeout(() => this.element.nativeElement.focus(), 100);
  }
}
