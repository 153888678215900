import { cloneDeep, truncate } from 'lodash';
import { ILayerTool, IToolEditor, IToolModel, LayerData, IToolData as ToolData, Layer, ToolId, CopyMode, Rect, LayerFlag } from '../interfaces';
import { getLayerSafe } from '../drawing';
import { LAYER_NAME_LENGTH_LIMIT } from '../constants';
import { toLayerState, layerFromState, layerChanged, redrawLayerThumb, validateLayerData, isTextLayer, getLayerName } from '../layer';
import { cloneRect, addRect, createRect } from '../rect';
import { logAction } from '../actionLog';
import { invalidEnum } from '../baseUtils';
import { redrawDrawing } from '../../services/editorUtils';
import { getLayerRect, isLayerEmpty } from '../layerUtils';
import { isMaskEmpty } from '../mask';
import { addLayerToDrawing, removeLayerFromDrawing, sendLayerOrder } from '../layerToolHelpers';
import { finishTransform, getLayerAfterRect, safeOpacity } from '../toolUtils';

export enum Action {
  Clear = 0,
  Transfer = 1,
  Add = 2,
  Remove = 3,
  // Update = 4,
  Duplicate = 5,
  Copy = 6,
  Cut = 7,
  Merge = 8,
}

export const ActionNames = ['clear', 'transfer', 'add', 'remove', 'update', 'duplicate', 'copy', 'cut', 'merge'];

export interface LayerToolData extends ToolData {
  action: Action;
  index?: number;
  layer?: LayerData;
  rect?: Rect;
  clip?: boolean;
  opacity?: number;
  mode?: string;
  auto?: boolean;
}

export class LayerTool implements ILayerTool {
  id = ToolId.Layer;
  name = '';
  constructor(public editor: IToolEditor, public model: IToolModel) {
  }
  do(data: LayerToolData, _binaryData?: Uint8Array, debugInfo?: string) {
    const action = data.action;
    const layerId = () => {
      if (!this.model.user.activeLayer)
        throw new Error(`[LayerTool] Missing activeLayer (action: ${Action[action]}, ${debugInfo})`);

      return this.model.user.activeLayer.id;
    };

    const otherLayerId = () => {
      if (!data.otherLayerIds?.[0])
        throw new Error(`[LayerTool] Missing otherLayerId (${debugInfo})`);

      return data.otherLayerIds[0];
    };

    switch (action) {
      case Action.Clear:
        return this.clear(layerId(), true);
      case Action.Transfer:
        return this.transfer(layerId(), otherLayerId(), safeOpacity(data.opacity ?? 1), !!data.clip, true);
      case Action.Add:
        validateLayerData(data.layer!);
        return this.add(data.layer!, data.index! | 0, !!data.auto, true);
      case Action.Remove:
        return this.remove(layerId(), true);
      // case Action.Update:
      //   return this.update(data.layer!);
      case Action.Duplicate:
        return this.duplicate(layerId(), otherLayerId(), true);
      case Action.Copy:
        return this.copy(layerId(), otherLayerId(), true);
      case Action.Cut:
        return this.cut(layerId(), otherLayerId(), true);
      case Action.Merge:
        return this.merge(layerId(), otherLayerId(), safeOpacity(data.opacity ?? 1), !!data.clip, true);
      default:
        invalidEnum(action, `[LayerTool] Invalid layer action`);
    }
  }
  clear(layerId: number, remote = false) {
    logAction(`[${remote ? 'remote' : 'local'}] clear layer (${this.info()}, layerId: ${layerId})`);

    finishTransform(this.editor, this.model.user, 'LayerTool:clear');
    const layer = getLayerSafe(this.editor.drawing, layerId);
    redrawDrawing(this.editor, layer.rect); // before we reset layer.rect

    this.model.user.history.pushDirtyRect('clear layer', layerId, layer.rect);
    layer.flags = LayerFlag.None;
    this.editor.renderer.releaseLayer(layer);
    redrawLayerThumb(layer, true);
    this.model.doTool<LayerToolData>(layerId, { id: this.id, action: Action.Clear, ar: cloneRect(layer.rect) });
  }
  // assumes `otherLayer` is directly below `layer`
  transfer(layerId: number, otherLayerId: number, opacity: number, clip: boolean, remote = false) {
    logAction(`[${remote ? 'remote' : 'local'}] transfer layer (${this.info()}, layerId: ${layerId}, otherLayerId: ${otherLayerId})`);

    finishTransform(this.editor, this.model.user, 'LayerTool:transfer');
    const layer = getLayerSafe(this.editor.drawing, layerId);
    const otherLayer = getLayerSafe(this.editor.drawing, otherLayerId);
    const rect = addRect(cloneRect(layer.rect), otherLayer.rect);
    const br = cloneRect(layer.rect);
    const br2 = cloneRect(otherLayer.rect);

    this.model.user.history.execTransaction(history => {
      history.pushDirtyRect('transfer layer', layerId, rect);
      history.pushDirtyRect('transfer layer', otherLayerId, rect);
      history.pushLayerState(layerId);
      history.pushLayerState(otherLayerId);
    });

    try {
      layer.opacity = opacity;
      this.editor.renderer.mergeLayers(layer, otherLayer, clip);
      otherLayer.flags = otherLayer.flags | layer.flags;
      layer.flags = LayerFlag.None;
    } catch (e) {
      this.model.user.history.cancelLastUndo();
      throw e;
    }

    redrawDrawing(this.editor);

    this.model.doTool<LayerToolData>(layerId, {
      id: this.id, action: Action.Transfer, otherLayerIds: [otherLayerId], opacity, clip, mode: layer.mode,
      inf: `layer: ${layerInfo(layer)}, otherLayer: ${layerInfo(otherLayer)}`,
      br, ar: getLayerAfterRect(layer, this.editor.drawing), br2, ar2: cloneRect(otherLayer.rect),
    });
  }
  // assumes `otherLayer` is directly below `layer`
  merge(layerId: number, otherLayerId: number, opacity: number, clip: boolean, remote = false) {
    logAction(`[${remote ? 'remote' : 'local'}] merge layer (${this.info()}, layerId: ${layerId}, otherLayerId: ${otherLayerId})`);

    finishTransform(this.editor, this.model.user, 'LayerTool:merge');
    const layer = getLayerSafe(this.editor.drawing, layerId);
    const otherLayer = getLayerSafe(this.editor.drawing, otherLayerId);
    const rect = addRect(cloneRect(layer.rect), otherLayer.rect);
    const br = cloneRect(layer.rect);
    const br2 = cloneRect(otherLayer.rect);

    if (this.model.user.surface.layer === layer) {
      if (DEVELOPMENT) throw new Error('User surface is attached to removed layer (merge)');
      logAction('warning: User surface is attached to removed layer (merge)');
    }

    this.model.user.history.execTransaction(history => {
      history.pushDirtyRect('merge layer', layerId, rect);
      history.pushDirtyRect('merge layer', otherLayerId, rect);
      history.pushLayerState(otherLayerId);
      history.pushRemoveLayer(toLayerState(layer), this.editor.drawing.layers.indexOf(layer));
    });

    try {
      layer.opacity = opacity;
      this.editor.renderer.mergeLayers(layer, otherLayer, clip);
      otherLayer.flags = otherLayer.flags | layer.flags;
    } catch (e) {
      this.model.user.history.cancelLastUndo();
      throw e;
    }

    removeLayerFromDrawing(this.editor, layer.id);
    redrawDrawing(this.editor);
    this.model.doTool<LayerToolData>(layerId, {
      id: this.id, action: Action.Merge, otherLayerIds: [otherLayerId], opacity, clip, mode: layer.mode,
      inf: `layer: ${layerInfo(layer)}, otherLayer: ${layerInfo(otherLayer)}`,
      br, ar: getLayerAfterRect(layer, this.editor.drawing), br2, ar2: cloneRect(otherLayer.rect)
    });
  }
  remove(layerId: number, remote = false) {
    logAction(`[${remote ? 'remote' : 'local'}] remove layer (${this.info()}, layerId: ${layerId}) [${this.editor.drawing.layers.map(l => l.id).join(', ')}]`);

    finishTransform(this.editor, this.model.user, 'LayerTool:remove');
    const layer = getLayerSafe(this.editor.drawing, layerId);

    if (this.model.user.surface.layer === layer) {
      if (DEVELOPMENT) throw new Error('User surface is attached to removed layer');
      logAction('warning: User surface is attached to removed layer');
    }

    this.model.user.history.execTransaction(history => {
      history.pushDirtyRect('removeLayer', layer.id, layer.rect);
      history.pushRemoveLayer(toLayerState(layer), this.editor.drawing.layers.indexOf(layer));
    });

    redrawDrawing(this.editor); // redraw whole drawing in case the empty layer had clipped layer on top of it
    removeLayerFromDrawing(this.editor, layer.id);

    this.model.doTool<LayerToolData>(layerId, { id: this.id, action: Action.Remove, ar: getLayerAfterRect(layer, this.editor.drawing) });
  }
  add(layerData: LayerData, index: number, auto: boolean, remote = false) {
    logAction(`[${remote ? 'remote' : 'local'}] add layer (${this.info()}, layerId: ${layerData.id}, name: ${layerData.name})`);

    finishTransform(this.editor, this.model.user, 'LayerTool:add');
    this.model.user.history.pushAddLayer(layerData, index);

    const layer = layerFromState(layerData);
    addLayerToDrawing(this.editor, layer, index);
    layer.owner = this.model.user;

    const tool: LayerToolData = { id: this.id, action: Action.Add, layer: layerData, index, ar: createRect(0, 0, 0, 0) };
    if (auto) tool.auto = true;
    this.model.doTool(layerData.id, tool);

    if (!remote) sendLayerOrder(this.editor);
  }
  // update(data: ILayerData) {
  //   const layer = getLayerSafe(this.editor.drawing, data.id);

  //   this.model.user.history.pushLayerState(data.id);
  //   layer.setState(data);

  //   if (data.opacity != null) {
  //     layer.redrawThumb();
  //   }

  //   this.editor.apply(() => { }); // applies layer state changes
  //   this.editor.redrawDrawing(layer.getRect());

  //   this.model.doTool<ILayerToolData>(data.id, { id: this.id, action: Action.Update, layer: data });
  // }
  duplicate(layerId: number, newLayerId: number, remote = false) {
    logAction(`[${remote ? 'remote' : 'local'}] duplicate layer (${this.info()}, layerId: ${layerId}, newId: ${newLayerId})`);

    finishTransform(this.editor, this.model.user, 'LayerTool:duplicate');
    const layer = getLayerSafe(this.editor.drawing, layerId);
    const index = this.editor.drawing.layers.indexOf(layer);
    const layerName = getNewCopyName(layer, this.editor.drawing.layers);
    const newLayerData = cloneLayerState(layer, newLayerId, layerName);
    const newLayer = layerFromState(newLayerData);
    let pushedHistory = false;

    addLayerToDrawing(this.editor, newLayer, index);

    try {
      this.model.user.history.execTransaction(history => {
        history.pushLayerId('duplicate layer', layerId);
        history.pushAddLayer(newLayerData, index);
        history.pushDirtyRect('duplicate layer', newLayerId, layer.rect);
      });
      pushedHistory = true;

      this.editor.renderer.copyLayer(layer, newLayer, undefined, CopyMode.Copy);
    } catch (e) {
      // remove layer in case of failure (out of memory)
      if (pushedHistory) this.model.user.history.cancelLastUndo();
      removeLayerFromDrawing(this.editor, newLayerId);
      throw e;
    }

    layerChanged(newLayer);

    redrawDrawing(this.editor, getLayerRect(newLayer));
    this.model.doTool<LayerToolData>(layerId, {
      id: this.id, action: Action.Duplicate, otherLayerIds: [newLayerId], ar: getLayerAfterRect(layer, this.editor.drawing), ar2: getLayerAfterRect(newLayer, this.editor.drawing)
    });
    if (!remote) sendLayerOrder(this.editor);
  }
  copy(layerId: number, newLayerId: number, remote = false) {
    logAction(`[${remote ? 'remote' : 'local'}] layer:copy (${this.info()}, layerId: ${layerId}, newId: ${newLayerId})`);

    finishTransform(this.editor, this.model.user, 'LayerTool:copy');
    const layer = getLayerSafe(this.editor.drawing, layerId);
    const selection = this.model.user.selection;
    const index = this.editor.drawing.layers.indexOf(layer);
    const layerName = getNewCopyName(layer, this.editor.drawing.layers);
    const newLayerData = cloneLayerState(layer, newLayerId, layerName);
    const newLayer = layerFromState(newLayerData);
    let pushedHistory = false;

    if (isTextLayer(layer) && isTextLayer(newLayer)) {
      (newLayer as Layer).textData = undefined;
      newLayer.textarea = undefined;
    }

    addLayerToDrawing(this.editor, newLayer, index);

    try {
      this.model.user.history.execTransaction(history => {
        history.pushLayerId('copy layer', layerId);
        history.pushAddLayer(newLayerData, index);
        history.pushDirtyRect('copy layer', newLayerId, layer.rect);
      });
      pushedHistory = true;

      this.editor.renderer.copyLayer(layer, newLayer, isMaskEmpty(selection) ? undefined : selection, CopyMode.Copy);
    } catch (e) {
      // remove layer in case of failure (out of memory)
      if (pushedHistory) this.model.user.history.cancelLastUndo();
      removeLayerFromDrawing(this.editor, newLayerId);
      throw e;
    }

    layerChanged(newLayer);

    if (isLayerEmpty(newLayer)) newLayer.flags = LayerFlag.None;

    redrawDrawing(this.editor, getLayerRect(newLayer));
    this.model.doTool<LayerToolData>(layerId, {
      id: this.id,
      action: Action.Copy,
      otherLayerIds: [newLayerId],
      ar: getLayerAfterRect(layer, this.editor.drawing),
      ar2: getLayerAfterRect(newLayer, this.editor.drawing)
    });
    if (!remote) sendLayerOrder(this.editor);
  }
  cut(layerId: number, newLayerId: number, remote = false) {
    logAction(`[${remote ? 'remote' : 'local'}] layer:cut (${this.info()}, layerId: ${layerId}, newId: ${newLayerId})`);

    finishTransform(this.editor, this.model.user, 'LayerTool:cut');
    const layer = getLayerSafe(this.editor.drawing, layerId);
    const selection = this.model.user.selection;
    const index = this.editor.drawing.layers.indexOf(layer);
    const layerName = getNewCopyName(layer, this.editor.drawing.layers);
    const newLayerData = cloneLayerState(layer, newLayerId, layerName);
    const newLayer = layerFromState(newLayerData);
    let pushedHistory = false;

    addLayerToDrawing(this.editor, newLayer, index);

    try {
      this.model.user.history.execTransaction(history => {
        history.pushAddLayer(newLayerData, index);
        history.pushDirtyRect('cut layer', newLayerId, selection.bounds);
        history.pushDirtyRect('cut layer', layerId, layer.rect);
        history.pushLayerState(layerId); // TODO do it only when current layer will be cut to new layer
      });
      pushedHistory = true;

      this.editor.renderer.copyLayer(layer, newLayer, selection, CopyMode.Cut);
    } catch (e) {
      // remove layer in case of failure (out of memory)
      if (pushedHistory) this.model.user.history.cancelLastUndo();
      removeLayerFromDrawing(this.editor, newLayerId);
      throw e;
    }

    redrawDrawing(this.editor, getLayerRect(newLayer));

    if (isLayerEmpty(layer)) layer.flags = LayerFlag.None;
    if (isLayerEmpty(newLayer)) newLayer.flags = LayerFlag.None;

    this.model.doTool<LayerToolData>(layerId, {
      id: this.id, action: Action.Cut, otherLayerIds: [newLayerId], ar: getLayerAfterRect(layer, this.editor.drawing), ar2: getLayerAfterRect(newLayer, this.editor.drawing)
    });
    if (!remote) sendLayerOrder(this.editor);
  }
  private info() {
    return `clientId: ${this.model.user.localId}`;
  }
}

function cloneLayerState(layer: Layer, newId: number, newLayerName?: string) {
  const state = toLayerState(layer);
  state.id = newId;
  if (newLayerName) {
    state.name = truncate(newLayerName, { length: LAYER_NAME_LENGTH_LIMIT });
  } else {
    state.name = truncate(`${state.name} Copy`, { length: LAYER_NAME_LENGTH_LIMIT });
  }
  if (layer.textData) state.textData = cloneDeep(layer.textData);
  delete state.rect;
  delete state.image;
  return state;
}

function layerInfo({ id, opacity, opacityLocked, clippingGroup, visible, locked }: Layer) {
  return JSON.stringify({ id, opacity, opacityLocked, clippingGroup, visible, locked });
}

function getNewCopyName(layer: Layer, allLayers: Layer[]) {
  const thisLayerName = getLayerName(layer);
  const layerNames = allLayers.map(getLayerName);
  let copyNum = 1;
  let newName = thisLayerName;
  while (layerNames.includes(newName)) {
    const copySuffix = ` Copy${copyNum === 1 ? '' : ' ' + copyNum}`;
    const match = newName.match(/^(.*) Copy(?: \d+)?$/i);
    if (match) {
      newName = truncate(match[1], { length: LAYER_NAME_LENGTH_LIMIT - copySuffix.length }) + copySuffix;
    } else {
      newName = truncate(newName, { length: LAYER_NAME_LENGTH_LIMIT - copySuffix.length }) + copySuffix;
    }
    copyNum++;
  }
  return newName;
}
