import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Feature, BannerInfo, Permission, TagInfo, Drawing } from '../common/interfaces';

export interface ManageTeamData {
  _id: string;
  avatar: string;
  name: string;
  slug: string;
  pro?: boolean;
  featureFlags?: Feature[];
  storageUsage?: { limit: number, used: number };
}

@Injectable({ providedIn: 'any' })
export class ManageService {
  constructor(public router: Router) {
  }
  team(_teamId: string): ManageTeamData | undefined {
    return undefined;
  }
  teams(_teamId: string[]): ManageTeamData[] {
    return [];
  }
  project(_projectId: string): { name: string; } | undefined {
    return undefined;
  }
  async folder(_folderId: string): Promise<{ name: string; } | undefined> {
    return undefined;
  }
  activeTeam(): { avatar: string; } | undefined {
    return undefined;
  }
  hasTrial() {
    return false;
  }
  error(message: string) {
    DEVELOPMENT && console.error(message);
  }
  upgrade(_openedBy: string) {
  }
  reachedStorageLimit(_team?: ManageTeamData, _destinantion = false) {
  }
  isStorageLimitActive(_team?: ManageTeamData): boolean {
    if (IS_HOSTED) return false;
    return _team ? !_team.featureFlags?.includes(Feature.StorageNoUsageLimits) : true;
  }
  isStorageLimitExceeded(_team?: ManageTeamData): boolean {
    return false;
  }
  userStorageUsage() {
    return { used: 0, limit: 1 };
  }
  handleErrorMessages(_message?: string) {
  }
  async getStorageUsage(_teamId?: string, _fetch?: boolean) {
    return { used: 0, limit: 1 };
  }
  hasPermission(_permission: Permission[]) {
    return false;
  }
  hasNewFeature(_feature: string) {
    return false;
  }
  async addTag(_name: string): Promise<TagInfo | void> {
    return undefined;
  }
  async getFirstInSequence(_drawingId: string): Promise<string | undefined> {
    return undefined;
  }
  getBanner(): BannerInfo | undefined {
    return undefined;
  }
  toggleBannersOff() {
  }
  async importEntityWithToast(_name: string, _files: File[], _folderId: string | undefined): Promise<string | undefined> {
    return undefined;
  }
  updateDrawing(_drawing: Drawing) {
  }
  async contactUs() {
  }
  hasToolTrial(_trial: string) {
    return false;
  }
  startedToolTrial(_trial: string) {
    return false;
  }
  toolTrialDaysLeft(_trial: string) {
    return 0;
  }
  async startToolTrial(_trial: string) {
  }
}
