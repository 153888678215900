import { REV } from '../generated/rev';

const BASE = '/assets/';

export function getUrl(name: string): string {
  if (DEVELOPMENT) {
    return `${BASE}${name}`;
  }

  if (!REV[name]) {
    throw new Error(`Cannot find file url for: ${name}`);
  }

  return `${BASE}${REV[name]}`;
}
