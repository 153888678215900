import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { getAvatarPath } from 'magma/common/utils';
import { getAnonymousNumber } from 'shared/utils';
import { UserData } from 'shared/interfaces';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  private _size = 32;
  @Input() get size() {
    return this._size;
  }
  set size(value: number) {
    this._size = value;
    this.avatarSrc = this.getAvatarSource();
  }

  private _user: Pick<UserData, '_id' | 'name' | 'avatar' | 'userType'> | undefined = undefined;
  @Input() get user() {
    return this._user;
  }
  set user(value) {
    this._user = value;
    this.avatarSrc = this.getAvatarSource();
  }

  @Input() round = false;
  @Input() cornerRadius = 0;
  @Input() borderWidth = 0;
  @Input() borderColor = 'transparent';

  avatarSrc = this.getAvatarSource();

  private getAvatarSource() {
    return getAvatarPath(this.user?.avatar, this.size);
  }

  get anonymousNumber() {
    return this.user ? getAnonymousNumber(this.user._id) : 0;
  }

  get isAnonymous() {
    return this.user?.userType === 'anonymous';
  }
}
