import { Directive, HostBinding, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { colorToHexRGB, parseColorWithAlpha } from '../../../common/color';
import { BLACK } from '../../../common/constants';

@Directive({ selector: '[colorInput]' })
export class ColorInput {
  @HostBinding() value = '000000';
  @Output() colorInputChange = new EventEmitter<number>(false);
  private _colorInput = BLACK;
  @Input() get colorInput() {
    return this._colorInput;
  }
  set colorInput(value: number) {
    if (this._colorInput !== value) {
      this._colorInput = value;
      this.value = colorToHexRGB(value);
    }
  }
  @HostListener('input', ['$event.target.value'])
  setValue(value: string) {
    const color = parseColorWithAlpha(value, 1);
    this._colorInput = color;
    this.colorInputChange.emit(color);
  }
}
