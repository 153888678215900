import { GAUSSIAN_BLUR_MAX, GAUSSIAN_BLUR_MIN } from '../constants';
import { IFiltersValues, IToolEditor, IToolModel, ToolId } from '../interfaces';
import { safeFloat } from '../toolUtils';
import { BaseFilterTool, IFilterData } from './baseFilterTool';

export class GaussianBlurTool extends BaseFilterTool {
  id = ToolId.GaussianBlur;
  name = 'Gaussian Blur';
  constructor(public editor: IToolEditor, public model: IToolModel) {
    super(editor, model, 'gaussianBlur');
    this.layerRectPadding = GAUSSIAN_BLUR_MAX;
    this.alwaysFetchSrcData = true;
  }
  protected applyFilter() {
    if (!this.layer) throw new Error(`[${this.toolName}] Missing layer`);
    this.editor.renderer.applyBlurFilter(this.srcData, this.model.user.surface, this.values);
  }
  protected parseData(data?: IFilterData): IFiltersValues {
    const radius = safeFloat(data?.radius, GAUSSIAN_BLUR_MIN, GAUSSIAN_BLUR_MAX);
    return { radius };
  }
}
