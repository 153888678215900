import { Component, EventEmitter, Input, Output } from '@angular/core';
import { options } from '../../../common/data';
import { FEATURE_LIST } from '../../../common/feature-list';
import { Feature, getAvailableFeatureFlags, getAvailableFeatureFlagsForTeam } from '../../../common/interfaces';
import { FeatureListModalInput } from '../../../services/admin-modals.service';

const AVAILABLE_FEATURE_FLAGS = getAvailableFeatureFlags(options.featureLevel);
const AVAILABLE_FEATURE_FLAGS_FOR_TEAMS = getAvailableFeatureFlagsForTeam(options.featureLevel);

@Component({
  selector: 'features-list-modal',
  templateUrl: 'features-list-modal.component.pug',
})
export class FeaturesListModal {
  @Output() close = new EventEmitter<Feature[]>();
  @Input() data!: FeatureListModalInput;

  features: { feature: Feature, description: string; active: boolean; }[] = [];

  ngOnInit() {
    const list = this.data.team ? AVAILABLE_FEATURE_FLAGS_FOR_TEAMS : AVAILABLE_FEATURE_FLAGS;
    this.features = FEATURE_LIST
      .filter(f => list.includes(f.feature))
      .map(f => ({ ...f, active: this.data.features.includes(f.feature) }));
  }

  updateFeature(feature: Feature, value: boolean) {
    const item = this.features.find(f => f.feature == feature);
    if (item) item.active = value;
  }

  closeModal() {
    this.close.emit();
  }

  saveChanges() {
    this.close.emit(this.features.filter(f => f.active).map(f => f.feature));
  }
}
