import jwtDecode from 'jwt-decode';
import { JWTDecoded } from './interfaces';

export function getTokenData(token: string) {
  try {
    const decoded = jwtDecode<JWTDecoded>(token);

    if (decoded.exp === undefined) { return null; }

    const expiryDate = new Date(0);
    const { exp, userType } = decoded;
    expiryDate.setUTCSeconds(exp);
    return { expiryDate, userType };
  } catch (e) {
    { return null; }
  }
}
export function isTokenExpired(token: string): boolean {
  if (!token) { return true; }

  const data = getTokenData(token);
  if (!data) {
    return false;
  }
  const { expiryDate } = data;
  return !(expiryDate.valueOf() > new Date().valueOf());
}
