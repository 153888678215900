import { AfterViewInit, Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { Model } from '../../../services/model';
import type { Editor } from '../../../services/editor';
import { redrawDrawing } from '../../../services/editorUtils';
import { DEFAULT_LAYER_COLOR, LAYER_NAME_LENGTH_LIMIT } from '../../../common/constants';
import { aiAssisted, aiGenerated, emptyIcon, faEye, faEyeSlash, faFileImport, faLock, faPencil, faSpinner, faText, levelDownLeftIcon } from '../../../common/icons';
import { getLayerDebugInfo, getLayerName, isLayerActive, isLayerVisible, isTextLayer } from '../../../common/layer';
import { HelpSection, Layer, LayerFlag, ToolSource, User, UserAction } from '../../../common/interfaces';
import { ownLayer, removeLayer, selectLayer, setLayerName, toggleLayerVisibility } from '../../../services/layerActions';
import { fullName } from '../../../common/userUtils';
import { hasDrawingRole, hasPermission } from '../../../common/userRole';
import { canOwnLayer, canOwnLayerMessage } from '../../../common/drawingUtils';
import { colorToCSS, parseColorWithAlpha } from '../../../common/color';
import { HelpService } from '../../../services/helpService';
import { hasFlag } from '../../../common/baseUtils';
import { setLastToolSource } from '../../../common/toolUtils';

@Component({
  selector: 'layer-item',
  templateUrl: 'layer-item.pug',
  styleUrls: ['layer-item.scss'],
  host: {
    '[class.clip]': 'layer.clippingGroup', // for clipping group indentation
  },
})
export class LayerItem implements AfterViewInit {
  readonly clipIcon = levelDownLeftIcon;
  readonly lockIcon = faLock;
  readonly visibleIcon = faEye;
  readonly hiddenIcon = faEyeSlash;
  readonly emptyIcon = emptyIcon;
  readonly textIcon = faText;
  readonly maxLayerNameLength = LAYER_NAME_LENGTH_LIMIT;

  @Input() forceActive = false; // for help page
  @Input() layer!: Layer;
  @ViewChild('thumb', { static: true }) thumb!: ElementRef;
  constructor(@Inject('Editor') private editor: Editor, private model: Model, private helpService: HelpService) {
  }
  get user() {
    return this.model.user;
  }
  get users() {
    return this.model.users;
  }
  get layerColor() {
    const layer = this.layer;

    if (layer.owner) {
      if (this.layerActive) {
        return layer.owner.color;
      } else {
        return colorToInactiveCSS(layer.owner.color);
      }
    } else {
      if (layer.layerOwner && !layer.layerOwner.left && this.editor.drawing.respectOfflineOwners) {
        return colorToInactiveCSS(layer.layerOwner.color);
      } else {
        return DEFAULT_LAYER_COLOR;
      }
    }
  }
  get layerHasOwner() {
    const { owner, layerOwner } = this.layer;
    return owner || (layerOwner && !layerOwner.left && this.editor.drawing.respectOfflineOwners);
  }
  get layerHadOwner() {
    const { layerOwner } = this.layer;
    return layerOwner && layerOwner.left;
  }
  get layerActive() {
    return isLayerActive(this.layer) || this.forceActive;
  }
  get layerVisible() {
    return isLayerVisible(this.layer);
  }
  get layerOwned() {
    return this.layer.owner === this.model.user;
  }
  get layerPending() {
    return this.model.pendingLayerOwns.has(this.layer.id);
  }
  get layerIcon() {
    if (this.layerPending) return faSpinner;
    else if (this.layer.locked) return faLock;
    else if (this.layerActive) return faPencil;
    else return emptyIcon;
  }
  get layerIconTitle() {
    if (this.layerPending) return 'Waiting...';
    else if (this.layer.locked) return 'This layer is locked';
    else if (this.layerActive) return 'You are drawing on this layer';
    else return '';
  }
  get isClipBase() {
    const layers = this.editor.drawing.layers;

    for (let i = 0, clip = false; i < layers.length; i++) {
      if (layers[i] === this.layer) return clip && !this.layer.clippingGroup;
      clip = layers[i].clippingGroup;
    }

    return false;
  }
  get ownerText() {
    const { owner, layerOwner } = this.layer;

    if (owner === this.model.user) {
      return 'Your layer';
    } else if (owner) {
      return `Layer belongs to: ${fullName(owner)}`;
    } else if (layerOwner) {
      if (layerOwner.left) {
        return `Layer belonged to: ${layerOwner.name}`;
      } else {
        return `Layer belongs to: ${layerOwner.name}`;
      }
    } else {
      return 'Free layer';
    }
  }
  get canOwn() {
    const user = this.model.user;
    const drawing = this.editor.drawing;
    const { owner, layerOwner } = this.layer;

    if (owner) {
      return user !== owner && hasPermission(drawing, user, 'takeOver');
    } else if (layerOwner) {
      return layerOwner.left || !drawing.respectOfflineOwners || hasPermission(drawing, user, 'takeOver');
    } else {
      return true;
    }
  }
  get debug() {
    return (DEVELOPMENT && !this.model.hideDebug) ? getLayerDebugInfo(this.layer) : '';
  }
  get isAdmin() {
    return hasDrawingRole(this.model.user, 'admin') || this.isSuperAdmin;
  }
  get isSuperAdmin() {
    return this.model.user.isSuperAdmin;
  }
  get hasContextMenu() {
    return true;
  }
  get ownerName() {
    if (this.layer.owner) return fullName(this.layer.owner);
    if (this.layer.layerOwner) return this.layer.layerOwner.name; // TODO: indicate anonymous
    return undefined;
  }
  get ownerLeft() {
    return !this.layer.owner && this.layer.layerOwner?.left;
  }
  layerOwnerTitle() {
    return this.ownerLeft ? `Last owner: ${this.ownerName}` : `Layer owner: ${this.ownerName}`;
  }
  ngAfterViewInit() {
    this.layer.thumb = this.thumb.nativeElement;
  }
  toggleVisibility(e: MouseEvent) {
    e.stopPropagation();

    if (e.shiftKey) {
      this.layer.visibleLocally = this.layer.visibleLocally == null ? !this.layer.visible : !this.layer.visibleLocally;
    } else if (this.layer.visibleLocally != null) {
      this.layer.visibleLocally = undefined;
    } else {
      toggleLayerVisibility(this.editor, this.layer);
    }

    redrawDrawing(this.editor);
  }
  selectLayer() {
    selectLayer(this.editor, this.layer);
  }
  async own(all?: boolean) {
    const { drawing } = this.editor;
    const { user } = this.model;

    if (all) {
      const layers = drawing.layers.filter(layer => layer.owner !== user && canOwnLayer(drawing, user, layer));

      for (const layer of layers) {
        setLastToolSource(ToolSource.ButtonPress);
        ownLayer(this.editor, layer).catch(e => DEVELOPMENT && console.error(e));
      }
    } else {
      const layer = this.layer;

      if (layer.owner === user) return;

      if (!canOwnLayer(drawing, user, layer)) {
        const text = canOwnLayerMessage(drawing, user, layer);
        if (text) this.helpService.show({ text, section: HelpSection.Layer });
        return;
      }

      if (layer.owner) this.model.pendingLayerOwns.add(layer.id);

      try {
        setLastToolSource(ToolSource.ButtonPress);
        const owned = await ownLayer(this.editor, layer);

        if (owned) {
          if (layer.owner === user) {
            selectLayer(this.editor, layer);
          } else {
            this.model.selectLayerOnOwn = layer.id;
          }
        }
      } catch (e) {
        DEVELOPMENT && console.error(e);
      } finally {
        this.model.pendingLayerOwns.delete(layer.id);
      }
    }
  }
  setLayerName(name: string) {
    setLayerName(this.editor, this.layer, name);
  }
  assignLayerTo(user: User) {
    const layerId = this.layer.id;
    this.model.pendingLayerOwns.add(layerId);
    this.model.tryUserAction(UserAction.AssignLayerTo, user.localId, layerId)
      .catch(e => {
        this.editor.helpService.show({ section: HelpSection.Layer, text: e.message });
        this.model.pendingLayerOwns.delete(layerId);
      });
  }
  async removeAllLayersForUser({ layerOwner }: Layer) {
    if (!layerOwner || !layerOwner.name) return;

    for (const layer of this.editor.drawing.layers.filter(layer => layer.layerOwner?.name === layerOwner.name)) {
      try {
        await ownLayer(this.editor, layer);
        removeLayer(this.editor, layer);
      } catch (e) {
        DEVELOPMENT && console.error(e);
      }
    }
  }

  get isTextLayer() {
    return isTextLayer(this.layer);
  }

  get layerName() {
    return getLayerName(this.layer);
  }

  get layerFlagIcon() {
    if (hasFlag(this.layer.flags, LayerFlag.AiGenerated)) {
      return aiGenerated;
    } else if (hasFlag(this.layer.flags, LayerFlag.AiAssisted)) {
      return aiAssisted;
    } else if (hasFlag(this.layer.flags, LayerFlag.External)) {
      return faFileImport;
    }
    return null;
  }

  get layerFlagsTooltipText() {
    let tooltip = [];
    if (hasFlag(this.layer.flags, LayerFlag.AiGenerated)) tooltip.push('AI-generated');
    if (hasFlag(this.layer.flags, LayerFlag.AiAssisted)) tooltip.push('AI-assisted');
    if (hasFlag(this.layer.flags, LayerFlag.External)) tooltip.push('Imported');
    return tooltip.join(', ');
  }
}

function colorToInactiveCSS(value: string) {
  const color = parseColorWithAlpha(value, 0.5);
  return colorToCSS(color);
}
