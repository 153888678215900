import { CompositeOp, hasCtrlOrMetaKey, hasShiftKey, ITool, IToolData, IToolEditor, IToolModel, Layer, SelectionMode, TabletEvent, ToolId, User } from '../interfaces';
import { moveIcon } from '../icons';
import { isTextLayer, redrawLayerThumb } from '../layer';
import { cloneRect, copyRect, intersectRect } from '../rect';
import { createPoint } from '../point';
import { createViewport, screenToDocumentXY } from '../viewport';
import { commitedLayerRect, copySurfaceTransform, createSurface, isSurfaceEmpty, setTransform, setupSurface, updateTransform } from '../toolSurface';
import { isMaskEmpty } from '../mask';
import { continueTransform, fixedScale, safeFloatAny } from '../toolUtils';
import { logAction } from '../actionLog';
import { redraw, redrawLayer } from '../../services/editorUtils';
import { toolIdToString } from '../toolIdUtils';
import { canDrawTextLayer, setTextLayerTransform, textLayerToTextBox } from '../text/text-utils';
import { copyMat2d, createMat2d, decomposeMat2d } from '../mat2d';

export interface IMoveToolData extends IToolData {
  tx: number;
  ty: number;
  sx: number;
  sy: number;
  r: number;
  op?: string; // for stats
  ratio?: string; // for stats
}

const enum Lock {
  None,
  Horizontal,
  Vertical,
}

const temp = createPoint(0, 0);

export class MoveTool implements ITool {
  id = ToolId.Move;
  name = 'Move';
  description = 'Move your layer or a selected part of it';
  learnMore = 'https://help.magma.com/en/articles/6790067-move-tool';
  video = { url: '/assets/videos/move.mp4', width: 374, height: 210 };
  icon = moveIcon;
  skipMoves = true;
  cursor = 'cursor-move';
  usesModifiers = true;
  view = createViewport();
  cancellableLocally = true;
  cancellingKeepsSurface = true;
  private startX = 0;
  private startY = 0;
  private currentX = 0;
  private currentY = 0;
  layer: Layer | undefined = undefined;
  private moved = false;
  replace = false;
  private pushedMove = false;
  private locked = Lock.None;
  private lastTranslateX = 0;
  private lastTranslateY = 0;
  private surfaceBefore = createSurface();
  constructor(public editor: IToolEditor, public model: IToolModel) {
  }
  do({ tx, ty, sx, sy, r }: IMoveToolData, _data: Uint8Array, debugInfo?: string) {
    const pushed = setupCanvasesAndLayerForMoveOrTransform(this, this.editor, this.model.user, debugInfo);

    if (!this.layer) throw new Error('[MoveTool.do] Missing layer');

    const surface = this.model.user.surface;
    surface.translateX = safeFloatAny(tx);
    surface.translateY = safeFloatAny(ty);
    surface.scaleX = fixedScale(safeFloatAny(sx));
    surface.scaleY = fixedScale(safeFloatAny(sy));
    surface.rotate = safeFloatAny(r);

    if (pushed) {
      this.model.user.history.pushLayerId('move', this.layer.id);
    } else {
      this.model.user.history.clearRedos();
    }

    updateTransform(surface);
    if (isTextLayer(this.layer)) setTextLayerTransform(this.layer, surface);

    redrawLayerThumb(this.layer);
    redrawLayer(this.editor, this.layer);
  }

  hover(_x: number, _y: number, e: TabletEvent) {
    this.cursor = hasCtrlOrMetaKey(e) ? 'cursor-default' : 'cursor-move';
  }

  start(x: number, y: number, _pressure: number) {
    screenToDocumentXY(temp, x, y, this.view);

    copySurfaceTransform(this.surfaceBefore, this.model.user.surface);

    this.moved = false;
    this.locked = Lock.None;

    this.currentX = temp.x | 0;
    this.currentY = temp.y | 0;
    this.startX = this.currentX;
    this.startY = this.currentY;
  }

  move(x: number, y: number, _pressure: number, e?: TabletEvent) {
    screenToDocumentXY(temp, x, y, this.view);

    if (e && hasShiftKey(e)) {
      if (this.locked === Lock.None) {
        const dx = Math.abs(this.startX - this.currentX);
        const dy = Math.abs(this.startY - this.currentY);

        if (dx > dy) {
          this.locked = Lock.Horizontal;
        } else if (dy > dx) {
          this.locked = Lock.Vertical;
        }
      }
    } else {
      this.locked = Lock.None;
    }

    if (this.locked === Lock.Horizontal) {
      this.currentX = temp.x | 0;
      this.currentY = this.startY;
    } else if (this.locked === Lock.Vertical) {
      this.currentX = this.startX;
      this.currentY = temp.y | 0;
    } else {
      this.currentX = temp.x | 0;
      this.currentY = temp.y | 0;
    }

    const surface = this.model.user.surface;

    if (!this.moved) {
      if (this.currentX !== this.startX || this.currentY !== this.startY) {
        this.moved = true;
        this.pushedMove = setupCanvasesAndLayerForMoveOrTransform(this, this.editor, this.model.user);
        this.lastTranslateX = surface.translateX;
        this.lastTranslateY = surface.translateY;
        if (!this.layer) throw new Error('[MoveTool.move] Missing layer');
      } else {
        return;
      }
    }

    const dx = Math.round(this.currentX - this.startX) | 0;
    const dy = Math.round(this.currentY - this.startY) | 0;
    surface.translateX = this.lastTranslateX + dx;
    surface.translateY = this.lastTranslateY + dy;
    // removed clamping due to issues when pasting images larger than canvas
    // const bounds = isMaskEmpty(this.model.user.selection) ? surface.rect : this.model.user.selection.bounds;
    // surface.translateX = clampXLimits(this.lastTranslateX + dx, bounds, this.editor.drawing.rect);
    // surface.translateY = clampYLimits(this.lastTranslateY + dy, bounds, this.editor.drawing.rect);
    updateTransform(surface);
    if (isTextLayer(this.layer)) setTextLayerTransform(this.layer, surface);

    if (!isMaskEmpty(this.model.user.selection)) { // tool bounds instead ?
      redraw(this.editor); // TODO: use tool bounds ?
    }

    redrawLayer(this.editor, this.layer); // TODO: use dirty rect
  }

  end(x: number, y: number, pressure: number, e?: TabletEvent) {
    this.move(x, y, pressure, e);

    if (this.moved) {
      if (!this.layer) throw new Error('[MoveTool.end] Missing layer');

      if (this.pushedMove) {
        this.model.user.history.pushLayerId('move', this.layer.id);
      } else {
        this.model.user.history.clearRedos();
      }

      const surface = this.model.user.surface;
      const data: IMoveToolData = {
        id: this.id,
        tx: surface.translateX,
        ty: surface.translateY,
        sx: surface.scaleX,
        sy: surface.scaleY,
        r: surface.rotate,
        replace: this.replace,
        ar: commitedLayerRect(surface, this.layer, this.editor.drawing),
        selection: isMaskEmpty(this.model.user.selection) ? SelectionMode.Empty : SelectionMode.Update,
      };

      this.model.doTool<IMoveToolData>(this.layer.id, data);

      redrawLayerThumb(this.layer);
    } else {
      logAction('[local] move omitted');
      this.model.user.history.unpre();
    }
  }

  cancel() {
    if (this.moved) {
      this.moved = false;
      copySurfaceTransform(this.model.user.surface, this.surfaceBefore);
    }
  }
}

interface IMoveTool extends ITool {
  layer: Layer | undefined;
  replace: boolean;
}

export function setupCanvasesAndLayerForMoveOrTransform(tool: IMoveTool, editor: IToolEditor, user: User, debugInfo?: string) {
  const toolId = toolIdToString(tool.id);
  const surface = user.surface;
  const layer = user.activeLayer;

  if (!layer) throw new Error(`[${tool.name}Tool] Missing layer (${debugInfo})`);

  continueTransform(editor, user);

  tool.layer = layer;

  if (!isSurfaceEmpty(surface) || surface.transforming) {
    // continue existing move
    tool.replace = true;

    if (!user.history.isLastEntryMove(layer.id)) {
      if (isTextLayer(layer)) {
        layer.invalidateCanvas = true;
        user.history.prepushLayerState(layer.id);
      }
      user.history.prepushTool(`${toolId}L`, true);
      return true;
    } else {
      return false;
    }
  } else {
    // start new move
    tool.replace = false;

    if (DEVELOPMENT && (surface.translateX || surface.translateY)) console.warn('Non 0 translation');

    // TODO: cancel move when selection or layer is empty ? (only local)
    const rect = cloneRect(layer.rect);
    if (!isMaskEmpty(user.selection)) intersectRect(rect, user.selection.bounds);

    if (isTextLayer(layer)) {
      user.history.prepushLayerState(layer.id);
    }
    user.history.prepushDirtyRect(`${toolId}DR`, layer.id, rect, true);
    user.history.prepushTool(toolId);

    setupSurface(surface, tool.id, CompositeOp.Move, layer);

    if (isTextLayer(layer)) {
      if (canDrawTextLayer(layer)) {
        const { translateX, translateY, rotate, scaleX, scaleY } = decomposeMat2d(layer.textarea.transform);
        setTransform(surface, [translateX, translateY, scaleX, scaleY, rotate]);
        copyRect(surface.rect, textLayerToTextBox(layer, 'untransformedTextureRect'));
      } else {
        const mat = createMat2d();
        if (layer.textData.textareaFormatting.transform) {
          copyMat2d(mat, layer.textData.textareaFormatting.transform);
        }
        const { translateX, translateY, rotate, scaleX, scaleY } = decomposeMat2d(mat);
        setTransform(surface, [translateX, translateY, scaleX, scaleY, rotate]);
        copyRect(surface.rect, layer.rect);
      }
    } else {
      editor.renderer.splitLayer(surface, layer, user.selection);
    }

    surface.transforming = true;
    return true;
  }
}
