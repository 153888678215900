import { Font, Glyph, Path } from 'opentype.js';

export const NOT_DEF_CHAR = '𑑛';
const MEASURED_CHAR = 'H';
const FONT_TABLES_FALLBACK_VALUES = {
  os2: {
    yStrikeoutPosition: 200,
    yStrikeoutSize: 50,
  },
  post: {
    underlinePosition: 0,
    underlineThickness: 50
  }
} as any;

export class FontStyle {
  readonly font: Font;
  private readonly glyphs: GlyphCache;

  private readonly unavailableGlyph: Glyph;
  private readonly identifyAs: string; // `${fontFamily.name} - ${fontStyle.name}`, used for logging
  private readonly measuredCharHeight: number; // in font units

  constructor(font: Font, identifyAs: string) {
    this.identifyAs = identifyAs;
    this.font = font;
    this.glyphs = new Map<string, Glyph>();
    this.unavailableGlyph = this.loadUnavailableGlyph();
    this.measuredCharHeight = this.font.charToGlyph(MEASURED_CHAR).getMetrics().yMax;
  }

  getGlyph(character: string): Glyph {
    if (this.glyphs.get(character) === undefined) {
      this.loadGlyph(character);
    }
    return this.glyphs.get(character)!;
  }

  toPixels(n: number, size: number) {
    return n * size / this.font.unitsPerEm;
  }

  fromPixels(px: number, size: number): number {
    return px * this.font.unitsPerEm / size;
  }

  ascender(size: number): number {
    return this.toPixels(this.font.ascender, size);
  }

  descender(size: number): number {
    // returns negative number
    return this.toPixels(this.font.descender, size);
  }

  capHeight(size: number): number {
    return this.toPixels(this.measuredCharHeight, size);
  }

  lineheight(size: number) {
    return this.toPixels(this.font.ascender - this.font.descender, size);
  }

  getFontTable(key: string) {
    const fontTable = this.font.tables[key];
    if (DEVELOPMENT && !fontTable) console.warn(`Table "${key}" is missing!`);
    return fontTable;
  }

  getFontTableProperty(tableKey: string, property: string) {
    const fontTable = this.getFontTable(tableKey);
    let value = fontTable[property];
    if (DEVELOPMENT && !value) {
      console.warn(`Property ${property} is missing in font table "${tableKey}" (font: ${this.identifyAs})`, { tables: this.font.tables });
      value = FONT_TABLES_FALLBACK_VALUES[tableKey][property];
    }
    return value;
  }

  getStrikethroughPosition(size: number) {
    const strikethroughPositionInFontUnits = this.getFontTableProperty('os2', 'yStrikeoutPosition');
    return this.toPixels(strikethroughPositionInFontUnits, size);
  }

  getStrikethroughThickness(size: number) {
    const strikethroughThicknessInFontUnits = this.getFontTableProperty('os2', 'yStrikeoutSize');
    return this.toPixels(strikethroughThicknessInFontUnits, size);
  }

  getUnderlinePosition(size: number) {
    const underlinePositionInFontUnits = this.getFontTableProperty('post', 'underlinePosition');
    return this.toPixels(underlinePositionInFontUnits, size);
  }

  getUnderlineThickness(size: number) {
    const underlineThicknessInFontUnits = this.getFontTableProperty('post', 'underlineThickness');
    return this.toPixels(underlineThicknessInFontUnits, size);
  }

  private loadGlyph(character: string) {
    const glyph = this.font.charToGlyph(character);
    if (glyph && glyph.name !== '.notdef') {
      this.glyphs.set(character, glyph);
    } else {
      this.glyphs.set(character, this.unavailableGlyph);
    }
  }

  private loadUnavailableGlyph() {
    const unavailableGlyph = this.font.charToGlyph(NOT_DEF_CHAR);
    if (unavailableGlyph && (unavailableGlyph.path as Path).commands.length > 0) {
      return unavailableGlyph;
    } else {
      const questionMarkGlyph = this.font.charToGlyph('?');
      if (questionMarkGlyph) {
        return questionMarkGlyph;
      } else {
        throw new Error('This font style does not support fallback glyphs (unavailable glyph or question mark)!');
      }
    }
  }
}

// Defines ways of importing file with font style
export type FontFileInput = string;

// Defines key-value pairs of character (glyphKey) and it's glyph
export type GlyphCache = Map<string, Glyph>;
