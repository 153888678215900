import { Component, Input } from '@angular/core';
import { BrushToolSettings } from '../../../common/interfaces';
import { getBrushPreviewPath } from '../../../common/shapes';
import { blazeBoxIcon } from '../../../common/icons';

const CANVAS_WIDTH = 114;
const PLACEHOLDER_IMAGE = '/assets/pixel.png';

@Component({
  selector: 'brush-preview-img',
  templateUrl: 'brush-preview-img.pug',
  styleUrls: ['brush-preview-img.scss'],
  host: {
    '[class.show-pro]': 'showPro',
    '[class.skip-badge]': 'skipBadge',
  }
})
export class BrushPreviewImg {
  src = PLACEHOLDER_IMAGE;
  readonly blazeBoxIcon = blazeBoxIcon;
  private _tool: BrushToolSettings | undefined = undefined;
  @Input() showPro = false;
  @Input() skipBadge = false;
  @Input() set tool(value) {
    if (this._tool !== value) {
      this._tool = value;
      this.src = value ? getBrushPreviewPath(value, CANVAS_WIDTH) : PLACEHOLDER_IMAGE;
    }
  }
  get tool() {
    return this._tool;
  }
}
