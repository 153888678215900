import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CouponService } from 'magma/services/couponService';
import { PublicPromoCodeInfo } from 'magma/common/interfaces';
import { UserService } from './user.service';
import { getCookie } from 'magma/common/cookies';
import { toPromise } from 'shared/utils';

@Injectable({ providedIn: 'any' })
export class PortalCouponService extends CouponService {
  private cache = new Map<string, Promise<PublicPromoCodeInfo | undefined>>();
  constructor(private httpClient: HttpClient, private userService: UserService) {
    super();
  }
  async getPromo() {
    if (this.userService.user?.pro) return undefined;

    const code = this.getCoupon();
    if (!code) return undefined;

    if (!this.cache.has(code)) {
      this.cache.set(code, toPromise(this.httpClient.get<PublicPromoCodeInfo>(`/api/billing/coupon/${code}`)).catch(e => {
        DEVELOPMENT && console.error(e);
        return undefined;
      }));
    }

    return await this.cache.get(code);
  }
  getCoupon() {
    return getCookie('magmacoupon');
  }
}
