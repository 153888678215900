import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'alert-modal',
  templateUrl: 'alert-modal.pug',
})
export class AlertModal {
  @Output() close = new EventEmitter<void>();
  @Input() data = { message: '' };
  ok() {
    this.close.emit();
  }
}
