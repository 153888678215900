import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, defer, merge, NEVER, of, Subject, timer } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, mapTo, publishReplay, skip, switchMap, take, tap } from 'rxjs/operators';
import { RecentEntity } from 'shared/interfaces';
import { refCountDelay, repeatLastValueWhen } from 'shared/rxjs';
import { handleHttpError, toPromise } from 'shared/utils';
import { PresenceService } from './presence.service';
import { RecentEntitiesQuery } from './recent-entities/recent-entities.query';
import { RecentEntitiesStore } from './recent-entities/recent-entities.store';
import { TeamsQuery } from './team.query';
import { UserService } from './user.service';

const INITIAL_REQUEST_SIZE = 15;

@Injectable({ providedIn: 'root' })
@UntilDestroy()
export class RecentParticipationService {
  constructor(
    private httpClient: HttpClient,
    private recentEntitiesQuery: RecentEntitiesQuery,
    private recentEntitiesStore: RecentEntitiesStore,
    private presenceService: PresenceService,
    private userService: UserService,
    private teamsQuery: TeamsQuery,
  ) { }

  readonly initialEntityIds = this.presenceService.lastPublishedPresence ? [this.presenceService.lastPublishedPresence] : [];
  private requestSize$ = new BehaviorSubject<number>(INITIAL_REQUEST_SIZE);
  private onlyLive$ = new BehaviorSubject(false);
  private leftId$ = new Subject<string>();

  private presence$ = combineLatest([
    this.teamsQuery.selectActive().pipe(map(team => team?._id), distinctUntilChanged()),
    this.userService.user$.pipe(map(user => user?._id), distinctUntilChanged()),
  ]).pipe(
    switchMap(([teamId]) => combineLatest([
      this.onlyLive$.pipe(distinctUntilChanged()),
      this.presenceService.monitorRecentEntities(this.initialEntityIds, teamId),
    ])),
    tap(([onlyLive, data]) => {
      let shortId: string | undefined = undefined;

      if ('unsub' in data) {
        shortId = this.recentEntitiesQuery.getEntity(data._id)?.entity?.shortId;
      } else {
        shortId = data.entity?.shortId;
      }

      if (shortId === this.presenceService.lastPublishedPresence) {
        this.presenceService.clearLastPublishedPresence();
      }

      if ('unsub' in data || (onlyLive && !data.isLive) || !data.entity) {
        this.recentEntitiesStore.remove(data._id);
      } else {
        this.recentEntitiesStore.upsert(data._id, data);
      }
    }),
    untilDestroyed(this),
  );

  /**
   * This is exposing the entityId which just got closed from the editor in the same browser tab.
   * We use it to show a loading indicator for the duration when the server is saving the drawing
   * until a live update comes with a new thumbnail.
   */
  loadingIndicatorForEntity$ = defer(() => {
    if (!this.presenceService.lastPublishedPresence) {
      return of(undefined);
    }
    return merge(
      this.presenceService.lastPublishedPresence$.pipe(take(1)),
      this.presenceService.lastPublishedPresence$.pipe(skip(1), delay(200)),
      timer(10000).pipe(mapTo(undefined), tap(() => this.presenceService.clearLastPublishedPresence())),
    );
  }).pipe(take(2));

  getRequestSize() {
    return this.requestSize$.value;
  }

  requestSize(size: number) {
    this.requestSize$.next(size);
  }

  refreshEntities(size: number) {
    this.recentEntitiesStore.reset();
    this.requestSize(size);
  }

  private requested = false;

  recentEntities(requestSize = INITIAL_REQUEST_SIZE, onlyLive = false) {
    this.requestSize(requestSize);
    this.onlyLive$.next(onlyLive);

    // TODO: no idea why we don't keep reference to these and unsubscribe, no idea how to fix it
    if (!this.requested) {
      this.requested = true;
      this.presence$.subscribe(() => { }, e => DEVELOPMENT && console.error(e));
      this.recentEntities$.subscribe(() => { }, e => DEVELOPMENT && console.error(e));
    }

    return this.recentEntitiesQuery.selectLoading().pipe(
      filter(isLoading => !isLoading),
      switchMap(() => this.recentEntitiesQuery.selectAll()),
      publishReplay(1),
      refCountDelay(5000),
    );
  }

  async leave(id: string) {
    this.recentEntitiesStore.remove(id);
    this.leftId$.next(id);
    await toPromise(this.httpClient.delete(`/api/participation/byId/${id}`));
  }

  private prevTeamId: string | undefined = undefined;
  private prevLive = false;

  private recentEntities$ = combineLatest([
    this.userService.user$.pipe(map(u => u?._id), distinctUntilChanged()),
    this.teamsQuery.selectActive().pipe(map(t => t?._id), distinctUntilChanged()),
    this.requestSize$.pipe(distinctUntilChanged(), repeatLastValueWhen(this.leftId$)),
    this.onlyLive$.pipe(distinctUntilChanged()),
  ]).pipe(
    untilDestroyed(this),
    switchMap(([userId, teamId, requestSize, onlyLive]) => {
      if (teamId !== this.prevTeamId || onlyLive !== this.prevLive) {
        this.prevTeamId = teamId;
        this.prevLive = onlyLive;
        this.recentEntitiesStore.reset();
      }

      const entities = this.recentEntitiesQuery.getAll();
      const requestCount: number = requestSize - entities.length;

      if (!userId || requestCount < 1) { // required amount of data already in store
        return NEVER;
      } else { // fetch additional data from server
        const afterId = entities[entities.length - 1]?._id;
        return this.getRecentEntities(afterId, requestCount, false, teamId, onlyLive);
      }
    }),
  );

  getRecentEntities(afterId: string | undefined, requestCount: number, sortByLastUpdated: boolean, teamId: string | null | undefined, onlyLive: boolean) {
    let params = new HttpParams();
    params = params.set('pageSize', requestCount);
    params = params.set('sortByLastUpdated', sortByLastUpdated);
    if (afterId) params = params.set('afterId', afterId);
    if (teamId) params = params.set('team', teamId);
    if (onlyLive) params = params.set('onlyLive', true);

    return this.httpClient.get<RecentEntity[]>('/api/participation', { params }).pipe(
      take(1),
      tap(entities => this.recentEntitiesStore.upsertMany(entities)),
      handleHttpError(),
    );
  }
}
