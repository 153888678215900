import {
  IToolEditor, IToolModel, TabletEvent, ToolId, SelectionMode, hasShiftKey, hasAltKey, IUndoFunction,
  Mask, SelectionToolMode
} from '../interfaces';
import { createPolyRect, clearPoly, addPolyPoint, isPolyEmpty, createPoly, getPolyBounds } from '../poly';
import { intersection } from '../polyUtils';
import {
  subtractPolyFromMask, addPolyToMask, clearMask, moveMask, isMaskEmpty, maskContainsXY, clampXLimits,
  clampYLimits, intersectPolyWithMask
} from '../mask';
import { clamp, distance } from '../mathUtils';
import { getPixelRatio } from '../utils';
import { invalidEnum } from '../baseUtils';
import { SelectionToolAction } from './selectionTool';
import { applyViewportTransform, screenToDocumentXY, createViewport } from '../viewport';
import { createPoint } from '../point';
import { createRect, resetRect, isRectEmpty, copyRect } from '../rect';
import { logAction } from '../actionLog';
import { Editor } from '../../services/editor';
import { redraw } from '../../services/editorUtils';
import { LassoSelectionData } from './lassoSelectionTool';
import { finishTransform } from '../toolUtils';

const emptyRect = createRect(0, 0, 0, 0);

export abstract class LassoSelectionBaseTool {
  id = ToolId.None;
  poly = createPoly();
  action = SelectionToolAction.Select;
  rect = createRect(0, 0, 0, 0);
  view = createViewport();
  mode: SelectionToolMode = 'select';
  protected shift = false;
  protected alt = false;
  protected startX = 0;
  protected startY = 0;
  protected endX = 0;
  protected endY = 0;
  protected originalStartX = 0;
  protected originalStartY = 0;
  protected moveX = 0;
  protected moveY = 0;
  protected selectionBounds = createRect(0, 0, 0, 0);
  protected startedInside = false;
  protected startedEmpty = false;
  protected moved = false;
  protected selectionStarted = false;
  protected lastX = -1e5;
  protected lastY = -1e5;
  protected started = false;
  protected undo: IUndoFunction | undefined = undefined;
  protected temp = createPoint(0, 0);
  constructor(public editor: IToolEditor, public model: IToolModel) {
  }
  abstract getSelection(): Mask;
  isPathEmpty() {
    return isPolyEmpty(this.poly);
  }
  drawPath(context: CanvasRenderingContext2D, close = false) {
    context.save();

    applyViewportTransform(context, this.editor.view, getPixelRatio());

    context.beginPath();

    // TODO: extract this into helper
    for (const { items, size } of this.poly) {
      const size2 = size << 1;

      if (size > 0) {
        context.moveTo(items[0], items[1]);

        for (let j = 2; j < size2; j += 2) {
          context.lineTo(items[j], items[j + 1]);
        }
      }
    }

    if (close && this.poly.length > 0 && this.poly[0].items.length > 2) {
      context.lineTo(this.poly[0].items[0], this.poly[0].items[1]);
    }

    context.restore();
  }
  protected doMove(x: number, y: number, force = false) {
    this.endX = x | 0;
    this.endY = y | 0;

    this.moved = this.moved || (distance(x, y, this.originalStartX, this.originalStartY) > 0);

    if (this.moved) this.selectionStarted = true;

    if (this.moved && this.action === SelectionToolAction.Clear) {
      this.action = this.startedInside ? SelectionToolAction.Move : SelectionToolAction.Select;
    }

    if (this.action === SelectionToolAction.Move) {
      const dx = this.endX - this.startX;
      const dy = this.endY - this.startY;
      const moveX = clampXLimits(dx, this.selectionBounds, this.editor.drawing.rect);
      const moveY = clampYLimits(dy, this.selectionBounds, this.editor.drawing.rect);
      moveMask(this.getSelection(), moveX - this.moveX, moveY - this.moveY);
      this.moveX = moveX;
      this.moveY = moveY;
    }

    // add point
    x = Math.round(clamp(x, 0, this.editor.drawing.width));
    y = Math.round(clamp(y, 0, this.editor.drawing.height));

    const select = force ||
      this.action === SelectionToolAction.Select || this.action === SelectionToolAction.Add ||
      this.action === SelectionToolAction.Subtract || this.action === SelectionToolAction.Intersect;

    if (select && (Math.abs(x - this.lastX) > 1 || Math.abs(y - this.lastY) > 1)) {
      // DEVELOPMENT && !TESTS && (this.editor.renderer as any).webgl.markers.push({ x, y });
      addPolyPoint(this.poly, x, y);
      this.lastX = x;
      this.lastY = y;
    }

    redraw(this.editor);
  }
  protected startSelection(x: number, y: number, pressure: number, isLassoTool: boolean, e?: TabletEvent) {
    this.started = true;

    if (isLassoTool) {
      finishTransform(this.editor, this.model.user, 'LassoSelectionBaseTool');
    }

    if (e) {
      this.shift = hasShiftKey(e);
      this.alt = hasAltKey(e);
    }

    const selection = (this.shift || this.alt) ? SelectionMode.Update : SelectionMode.Break;
    const user = this.model.user;

    screenToDocumentXY(this.temp, x, y, this.view);

    this.undo = user.history.createSelection('lasso-selection'); // TODO check it
    this.lastX = -1e5;
    this.lastY = -1e5;
    this.originalStartX = this.temp.x;
    this.originalStartY = this.temp.y;
    this.moveX = 0;
    this.moveY = 0;
    this.startX = this.temp.x | 0;
    this.startY = this.temp.y | 0;
    this.moved = false;
    this.action = SelectionToolAction.Clear;
    this.startedInside = false;
    this.selectionStarted = false;
    copyRect(this.selectionBounds, this.getSelection().bounds);
    resetRect(this.rect);
    clearPoly(this.poly);

    if (isLassoTool) {
      const { id, shift, alt, mode } = this;
      this.model.startToolView<LassoSelectionData>(0, this.view, { id, shift, alt, mode, selection }, x, y, pressure);
    }

    this.startedEmpty = isMaskEmpty(this.getSelection());

    if (this.mode === 'add') {
      this.action = SelectionToolAction.Add;
    } else if (this.mode === 'subtract' && !this.startedEmpty) {
      this.action = SelectionToolAction.Subtract;
    } else if (this.mode === 'intersect' && !this.startedEmpty) {
      this.action = SelectionToolAction.Intersect;
    } else if (this.startedEmpty) {
      this.action = SelectionToolAction.Select;
    } else if (this.alt) {
      this.action = SelectionToolAction.Subtract;
    } else if (this.shift) {
      this.action = SelectionToolAction.Add;
    } else if (maskContainsXY(this.getSelection(), this.temp.x, this.temp.y)) {
      this.startedInside = true;
    } else {
      this.selectionStarted = true;
      clearMask(this.getSelection());
    }

    this.doMove(this.temp.x, this.temp.y, true);
  }

  protected endSelection(x: number, y: number, pressure: number, isLassoTool: boolean) {
    // TEMP: remove after fix
    if (!this.started) {
      const error = new Error('ending not started lasso');
      (this.editor as Editor).errorReporter?.reportError(error.message, error);
    }

    this.started = false;
    screenToDocumentXY(this.temp, x, y, this.view);
    this.doMove(this.temp.x, this.temp.y);

    let omitted = false;
    const action = this.action;

    switch (action) {
      case SelectionToolAction.Clear: { // guaranteed not empty selection
        this.selectionStarted = true;
        clearMask(this.getSelection());
        break;
      }
      case SelectionToolAction.Move: { // guaranteed that selection was moved
        break;
      }
      case SelectionToolAction.Select:
      case SelectionToolAction.Intersect:
      case SelectionToolAction.Add:
      case SelectionToolAction.Subtract: {
        if (this.selectionStarted) {
          const { w, h } = this.editor.drawing.rect;
          this.poly = intersection(this.poly, createPolyRect(0, 0, w, h), true);

          if (isRectEmpty(getPolyBounds(this.poly))) {
            // TODO: started empty -> empty should be omitted
            // TODO: this can be selection outside canvas
            /* if (this.startedEmpty) {
              logAction(`[${this.model.type}] empty lasso omitted`);
              // this.model.user.history.unpre(); // how to do this now ???
              this.model.user.history.cancelLastUndo(); // omitted, but still clears redos
              cancelled = true;
            } else { */
            // clear selection if it wasn't empty before
            if (action === SelectionToolAction.Select) clearMask(this.getSelection());
            // }
          } else if (action === SelectionToolAction.Subtract) {
            subtractPolyFromMask(this.getSelection(), this.poly);
          } else if (action === SelectionToolAction.Intersect) {
            intersectPolyWithMask(this.getSelection(), this.poly);
          } else {
            addPolyToMask(this.getSelection(), this.poly);
          }
        } else {
          logAction(`[${this.model.type}] lasso omitted`);
          omitted = true;
        }
        break;
      }
      default: invalidEnum(action);
    }

    if (isLassoTool) {
      if (omitted) {
        this.model.user.history.unpre();
        // TEMP: remove message string later
        this.model.cancelTool(`cancelled (${this.originalStartX}, ${this.originalStartY}) -> (${this.temp.x}, ${this.temp.y}), ` +
          `action: ${this.action}, dist: ${distance(this.temp.x, this.temp.y, this.originalStartX, this.originalStartY)}`);
      } else {
        if (!this.undo) throw new Error('Missing undo');
        this.model.user.history.pushUndo(this.undo);
        const bounds = this.model.user.selection.bounds;
        this.model.endTool(this.id, x, y, pressure, emptyRect, emptyRect, bounds.x, bounds.y, bounds.w, bounds.h);
      }
    }

    this.undo = undefined;
    resetRect(this.rect);
    clearPoly(this.poly);
    redraw(this.editor);
  }
}
