import { defer, merge, NEVER } from 'rxjs';
import { logAction } from '../common/actionLog';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import type { ClientErrorHandler, ClientOptions } from 'ag-sockets';
import { removeItem } from '../common/baseUtils';
import { IErrorReporter } from '../common/interfaces';

export interface ReporterInfo {
  renderer: string;
  tablet?: string;
  languages: string;
  drawingId?: string;
}

export interface ErrorReport {
  message: string;
  error?: any;
  data?: any;
}

let disabled = false;

const errorHandlers: ErrorCallback[] = [];

export function nextError({ message, error, data }: ErrorReport) {
  if (!disabled && errorHandlers.length) {
    errorHandlers[errorHandlers.length - 1](message, error, data);
  }
}

export type ErrorCallback = (errorMessage: string, error: any, data: any) => void;

@Injectable({ providedIn: 'root' })
@UntilDestroy()
export class ErrorReporter implements IErrorReporter {
  constructor() {
    // don't report errors on android browser
    if (typeof window !== 'undefined' && !/Chrome\/28\./.test(navigator.userAgent)) {
      window.onerror = (event, _source, _lineno, _colno, error) => {
        DEVELOPMENT && console.error(error);
        if (event || error) this.reportError(`onerror (${event})`, error);
      };
      window.onunhandledrejection = (event: PromiseRejectionEvent) => {
        DEVELOPMENT && console.error(event.reason);
        if (event?.reason) this.reportError('onunhandledrejection', event.reason, event.reason);
      };
    }
  }
  /**
   * Only most recent subscriber will receive errors, until it unsubscribes,
   * then the previous most recent subscriber will receive the errors
   */
  handleErrors(handler: ErrorCallback) {
    return merge(defer(async () => {
      errorHandlers.push(handler);
    }), NEVER).pipe(finalize(() => {
      removeItem(errorHandlers, handler);
    }), untilDestroyed(this));
  }
  reportError(message: string, error?: any | undefined, data?: any) {
    DEVELOPMENT && console.error('reportError:', message, error, error?.stack ?? '', data);
    logAction(`reportError ('${message}', '${error?.message}', ${JSON.stringify(data)})`);
    nextError({ message, error, data });
  }
  createClientErrorHandler(socketOptions: ClientOptions): ClientErrorHandler {
    const handleRecvError = (error: Error, data: string | Uint8Array) => {
      let method: string | undefined;

      if (data instanceof Uint8Array) {
        const bytes: number[] = [];
        const length = Math.min(data.length, 512);

        for (let i = 0; i < length; i++) bytes.push(data[i]);

        if (data.length > 0) {
          const item = socketOptions.client[data[0]] as string | [string, any];
          method = typeof item === 'string' ? item : item[0];
        }

        const trail = length < data.length ? '...' : '';
        data = `<${bytes.toString()}${trail}>`;
      }

      this.reportError('handleRecvError', error, { data, method });
    };

    return { handleRecvError };
  }
  disable() {
    disabled = true;
  }
}
