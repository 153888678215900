import { Directive, Input, TemplateRef, Component, ViewContainerRef, Injectable, HostListener, EmbeddedViewRef, ElementRef, ChangeDetectionStrategy, OnDestroy, Output, EventEmitter } from '@angular/core';
import { findFocusableElements, focusFirstElement, isDescendantOf } from '../../../common/htmlUtils';
import { Key } from '../../../common/input';
import { clamp } from '../../../common/mathUtils';
import { getX, getY } from '../../../common/utils';
import { PositioningService } from '../../../services/positioning';

@Injectable({ providedIn: 'any' })
export class ContextMenuOutletService {
  viewContainer?: ViewContainerRef;
}

@Directive({
  selector: '[contextMenu]',
  exportAs: 'context-menu'
})
export class ContextMenu implements OnDestroy {
  @Input() contextMenu: TemplateRef<any> | undefined = undefined;
  @Input('contextMenuEvent') event = 'contextmenu';
  @Input('contextMenuLocation') location: string | undefined = undefined;
  @Input() allowInsideClick = false;
  private ref: EmbeddedViewRef<any> | undefined = undefined;
  private positioningElement: HTMLElement | undefined = undefined;
  constructor(private element: ElementRef, private service: ContextMenuOutletService, private positioning: PositioningService) {
  }
  ngOnDestroy() {
    this.close();
  }
  @Input() get isOpen() {
    return !!this.ref;
  }
  @Output() isOpenChange = new EventEmitter<boolean>();
  @HostListener('click', ['$event'])
  @HostListener('contextmenu', ['$event'])
  open(e: MouseEvent) {
    if (e.type !== this.event) return;

    e.preventDefault();

    const wasOpen = this.isOpen;

    this.close();

    // toggle menu open/close instead of opening it again after each click
    if (wasOpen && this.event === 'click') return;

    if (!this.contextMenu) return;
    if ((e.buttons & (~2>>>0)) !== 0) return;

    this.ref = this.service.viewContainer!.createEmbeddedView(this.contextMenu);
    this.ref.detectChanges();

    if (this.location) {
      this.positioning.addPositionElement({
        element: this.positioningElement = this.ref.rootNodes[0],
        target: this.element.nativeElement,
        attachment: this.location,
        appendToBody: true,
        options: {
          flip: {
            enabled: true
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport',
          },
        },
      });

      if (this.positioningElement) {
        this.positioningElement.style.margin = '5px';
      }
      if (!this.allowInsideClick) {
        this.ref.rootNodes[0].addEventListener('click', () => this.close());
      }
    } else {
      for (const node of this.ref.rootNodes as HTMLElement[]) {
        const { width, height } = node.getBoundingClientRect();
        const x = getX(e);
        const y = getY(e);
        node.style.left = `${Math.min(x, window.innerWidth - width - 5)}px`;
        node.style.top = `${(y + height) >= window.innerHeight ? y - height : y}px`;
        node.addEventListener('contextmenu', e => e.preventDefault());

        if (!this.allowInsideClick) {
          node.addEventListener('click', () => this.close());
        }
      }
    }

    focusAndSetupUpDownArrows(this.ref.rootNodes[0]);

    document.addEventListener('pointerdown', this.close, true);
    document.addEventListener('mousedown', this.close, true);
    document.addEventListener('touchstart', this.close, true);
    window.addEventListener('keydown', this.keydown);

    this.isOpenChange.emit(true);
  }
  close = (e?: Event) => {
    if (e?.target) {
      // ignore events inside the menu
      if (this.ref && isDescendantOf(e.target as any, this.ref.rootNodes)) return;

      // ignore clicking on the same button to close the menu
      if (this.event === 'click' && isDescendantOf(e.target as any, [this.element.nativeElement])) return;
    }

    this.ref?.destroy();
    this.ref = undefined;

    if (this.positioningElement) {
      this.positioning.deletePositionElement(this.positioningElement);
      this.positioningElement = undefined;
    }

    document.removeEventListener('pointerdown', this.close, true);
    document.removeEventListener('mousedown', this.close, true);
    document.removeEventListener('touchstart', this.close, true);
    window.removeEventListener('keydown', this.keydown);

    this.isOpenChange.emit(false);
  };
  private keydown = (e: KeyboardEvent) => {
    if (e.keyCode === Key.Esc) {
      this.close();
      this.element.nativeElement.focus();
    }
  };
}

const focusSetUpMap = new WeakSet<HTMLElement>();

export function focusAndSetupUpDownArrows(element: HTMLElement | undefined) {
  if (!element) return;

  if (!focusSetUpMap.has(element)) {
    focusSetUpMap.add(element);
    element.addEventListener('keydown', e => {
      let move = 0;

      if (e.keyCode === Key.Up) {
        e.preventDefault();
        move = -1;
      } else if (e.keyCode === Key.Down) {
        e.preventDefault();
        move = 1;
      }

      if (move) {
        const elements = findFocusableElements(element);
        const index = elements.indexOf(document.activeElement as any);

        if (index !== -1) {
          const newIndex = clamp(index + move, 0, elements.length - 1);
          elements[newIndex].focus();
        }
      }
    });
  }

  setTimeout(() => focusFirstElement(element));
}

@Component({
  selector: 'context-menu-outlet',
  template: `<ng-template></ng-template>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenuOutlet {
  constructor(service: ContextMenuOutletService, viewContainer: ViewContainerRef) {
    service.viewContainer = viewContainer;
  }
}
