export function getExtensionUrl() {
  return document.querySelector('link[rel="chrome-webstore-item"]')?.getAttribute('href');
}

export function canInstallExtension() {
  return (window.chrome?.app && !window.chrome.app.isInstalled && !(window as any).Tablet);
  //|| (window.opr?.addons && window.opr.addons.installExtension && !window.Tablet);
}

export function installExtension(_afterInstall?: () => void) {
  if (window.chrome) {
    const url = getExtensionUrl();

    if (url) {
      // if (canInstallExtension() && window.chrome.webstore) {
      //   window.chrome.webstore.install(url, afterInstall, e => {
      //     window.open(url);
      //     console.error(e);
      //   });
      // } else {
      window.open(url);
      // }
    }
  } /*else if (window.opr) {
    const id = document.querySelector('meta[name="opera-extension-id"]')?.getAttribute('content');
    window.opr.addons.installExtension(id, afterInstall, e => console.error(e));
  }*/
}
