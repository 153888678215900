import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Analytics } from '../../../../common/interfaces';
import { sendGAEvent } from '../../../../common/utilsAnalytics';
import { LoginSignupService } from '../../../../services/login-signup.service';

@Component({
  selector: 'create-account',
  templateUrl: 'create-account.pug',
  styleUrls: ['create-account.scss'],
})
export class CreateAccount implements OnInit {
  readonly featurePro = IS_PORTAL;
  @Output() close = new EventEmitter<void>();
  constructor(public loginSignupService: LoginSignupService) {
  }
  ngOnInit() {
    if (!IS_PORTAL) {
      sendGAEvent('Sign up', 'View', 'user-menu');
      this.loginSignupService.trackEvent(Analytics.ViewSignupFormScreen, {
        formOrigin: 'user-menu',
        method: 'email',
        FieldDisplayed: false,
        userJobFieldDisplayed: false,
      });
    }
  }
  closeModal() {
    this.close.emit();
  }
  cancel() {
    this.close.emit();
    this.loginSignupService.trackEvent(Analytics.CancelSignup, { method: 'email' });
  }
}
