/* tslint:disable */

export const CHANGELOG: { version: string; changes: string[]; date?: string; }[] = [
  {
    "version": "1.5.14",
    "date": "(August 2022)",
    "changes": [
      "added \"flip view at cursor\" option to application settings",
      "fixed issues with layer panel resizing on iPad when opening keyboard"
    ]
  },
  {
    "version": "1.5.12",
    "date": "(July 2022)",
    "changes": [
      "added \"Paste on new layer\" and \"Paste on active layer\" commands",
      "added \"Use separate colors for each tool presets slot\" option to application settings",
      "fixed issue with drawing with touch on iOS devices"
    ]
  },
  {
    "version": "1.5.11",
    "date": "(July 2022)",
    "changes": [
      "added touch pressure on Android devices",
      "fixed some UI settings saving per account instead of per device"
    ]
  },
  {
    "version": "1.5.10",
    "date": "(July 2022)",
    "changes": [
      "fixed some admin actions missing corresponding notification in chat"
    ]
  },
  {
    "version": "1.5.9",
    "date": "(July 2022)",
    "changes": [
      "fixed issues in some cases after pasting"
    ]
  },
  {
    "version": "1.5.7",
    "date": "(June 2022)",
    "changes": [
      "fixed issues with importing GIF files in some cases",
      "fixed chat messages being cut-off on small screens"
    ]
  },
  {
    "version": "1.5.6",
    "date": "(June 2022)",
    "changes": [
      "changed \"Copy permissions from current drawing\" option to also copy block list from current drawing",
      "added button next to \"Unblock all blocked users\" option that allows to copy block list from another drawing"
    ]
  },
  {
    "version": "1.5.5",
    "date": "(June 2022)",
    "changes": [
      "added touch option to application settings to pan view using single finger gesture",
      "fixed issues with not being able to change some settings"
    ]
  },
  {
    "version": "1.5.4",
    "date": "(June 2022)",
    "changes": [
      "changed arrow keys to move viewport when not selection is active (similar to how it worked before)",
      "changed speed at which arrow keys viewport to align with old behavior",
      "fixed issues with drawing shapes on large canvas on iOS devices"
    ]
  },
  {
    "version": "1.5.3",
    "date": "(June 2022)",
    "changes": [
      "improved peformance of drawing with selection",
      "changed moving view and selection with arrow keys more closely follow shortcuts of other art programs",
      "added support for pasting webp and avif files"
    ]
  },
  {
    "version": "1.5.2",
    "date": "(June 2022)",
    "changes": [
      "improved \"Kick and Block\" admin option",
      "improved prioritizing joining users when drawing is full",
      "fixed stutter when starting moving selection on large canvas"
    ]
  },
  {
    "version": "1.5.1",
    "date": "(May 2022)",
    "changes": [
      "added \"my\" search term in layer search for quickly showing own layers"
    ]
  },
  {
    "version": "1.5.0",
    "date": "(May 2022)",
    "changes": [
      "added \"Keep current tool when switching tool presets\" option to application settings"
    ]
  },
  {
    "version": "1.4.0",
    "date": "(May 2022)",
    "changes": [
      "added importing PSD files"
    ]
  },
  {
    "version": "1.3.2",
    "date": "(October 2021)",
    "changes": [
      "fixed not redrawing clipped layer when deleting layer below it",
      "fixed incorrect DPI when importing images with invalid DPI setting",
      "fixed selecting incorrect layers after removing layer or undoing layer remove",
      "fixed some UI issues",
      "fixed stylus eraser not working on Cintiq tablets"
    ]
  },
  {
    "version": "1.3.1",
    "date": "(September 2021)",
    "changes": [
      "added <b>Fit to screen</b> button in place of <b>Reset rotation</b> when canvas is not rotated",
      "fixed issue with iOS 15 loupe showing next to the cursor when drawing"
    ]
  },
  {
    "version": "1.3.0",
    "date": "(September 2021)",
    "changes": [
      " added custom shape tool",
      " added collection of custom shapes by <b>Pitch Dev Studios</b>",
      "fixed issues with caching"
    ]
  },
  {
    "version": "1.2.4",
    "date": "(September 2021)",
    "changes": [
      "fixed issues with drawing using stylus on Firefox",
      " fixed issues with selecting brush presets on mobile devices using stylus"
    ]
  },
  {
    "version": "1.2.3",
    "date": "(August 2021)",
    "changes": [
      "fixed double tap to own layer on touch devices"
    ]
  },
  {
    "version": "1.2.2",
    "date": "(August 2021)",
    "changes": [
      "added \"Touch\" section to application settings",
      "added undo touch gesture (tapping with two fingers)",
      "added redo touch gesture (tapping with three fingers)",
      "added \"Disable touch gestures\" option to application settings",
      "added \"Drawing with touch\" option to application settings (normal - default behavior, eraser - switches to eraser when using touch, ignore touch - ignores touch for drawing)",
      "added \"Enable touch eraser\" option to application settings, that switches to eraser tool when using touch instead of stylus on mobile devices",
      "added support for palm rejection on mobile devices",
      "fixed issue with brush stroke being cancelled when touching with second finger",
      "fixed various touch input related issues",
      "fixed eraser shape setting not saving properly",
      "fixed performance issues when using circle tool",
      "fixed clicking on eye icon incorrectly triggering layer ownership"
    ]
  },
  {
    "version": "1.2.1",
    "date": "(August 2021)",
    "changes": [
      "fixed some keyboard shortcuts not working correctly with new Huion tablet drivers"
    ]
  },
  {
    "version": "1.2.0",
    "date": "(July 2021)",
    "changes": [
      " added voice chat",
      "fixed some user interface issues"
    ]
  },
  {
    "version": "1.1.9",
    "date": "(July 2021)",
    "changes": [
      "added \"Hide brush cursor\" option to applicaton settings",
      "fixed error when drawing on layer with locked opacity and active selection",
      "fixed issue with color picker being half pixel off",
      "fixed issue with menus not closing when starting drawing on canvas",
      "fixed issue with menus closing when they shouldn't on mobile devices",
      "fixed some issues with user interface on Internet Explorer 11",
      "fixed issue with selected layer not being restored after disconnect",
      "fixed issue with personal layer visibility (clicking on toggle layer visibility button while holding shift key) setting not being restored after disconnect",
      "fixed issue with incorrect pressure at the beginning of lines when using apple pencil on iPad",
      "fixed image rendering issues on iPad",
      "fixed issues with selection tools on iPad",
      "fixed some user interface elements blurry on iPad"
    ]
  },
  {
    "version": "1.1.8",
    "date": "(July 2021)",
    "changes": [
      "fixed issues with incorrect cursor position on iPhone in some cases",
      "fixed issue with keyboard shortcuts showing on mobile devices"
    ]
  },
  {
    "version": "1.1.7",
    "date": "(July 2021)",
    "changes": [
      "fixed some user interface issues"
    ]
  },
  {
    "version": "1.1.6",
    "date": "(June 2021)",
    "changes": [
      "fixed issues with canvas drawing incorrectly in some cases"
    ]
  },
  {
    "version": "1.1.5",
    "date": "(June 2021)",
    "changes": [
      "fixed synchronization issue in some cases when user leaves canvas while it's still being loaded"
    ]
  },
  {
    "version": "1.1.4",
    "date": "(June 2021)",
    "changes": [
      " added fading for cursors of other people when they get close to your cursor",
      " added option to disable cursor fading",
      "fixed issues with chat scrolling"
    ]
  },
  {
    "version": "1.1.3",
    "date": "(June 2021)",
    "changes": [
      "fixed issues with importing immages",
      "fixed issues with creating accounts",
      "fixed issues with loading drawing"
    ]
  },
  {
    "version": "1.1.2",
    "date": "(June 2021)",
    "changes": [
      "fixed arrow cursor visible when using eraser tool"
    ]
  },
  {
    "version": "1.1.1",
    "date": "(June 2021)",
    "changes": [
      "fixed issues with loading canvas"
    ]
  },
  {
    "version": "1.1.0",
    "date": "(May 2021)",
    "changes": [
      "added <b>Anyone can take over offline users layers</b> option",
      "added <b>Take over layers</b> permission option to admin menu",
      "added max layers per user option to admin menu",
      "added permanent layer ownership and listing layer owner on layer list",
      "added automatic layer creation for new users",
      "added help section describing different layer ownership states",
      "changed automatic layer assignment for new users to only pick layers without any previous owners",
      "changed new drawings to automatically start with 0 layers"
    ]
  },
  {
    "version": "1.0.21",
    "date": "(May 2021)",
    "changes": [
      "added <b>fit</b>, <b>cover</b> and <b>reset</b> buttons for transform tool for quick transform scale options",
      "fixed pasting resulting in cropped images when pasting images larger than canvas",
      "fixed inviting collaborators on iPad",
      "fixed cursors and selection bounds not drawn correctly on high pixel density displays",
      "fixed <b>cut</b> not deleting selection on browsers that don't support copy and paste"
    ]
  },
  {
    "version": "1.0.20",
    "date": "(May 2021)",
    "changes": [
      "fixed not being able to set custom keyboard shortcuts on safari"
    ]
  },
  {
    "version": "1.0.19",
    "date": "(April 2021)",
    "changes": [
      "added layer search (available from magnifying glass icon above layer list)",
      "changed anonymous user identification code from `[123]` format to `#123` format, more commonny used in other applications",
      "fixed showing \"anonymous user\" text for registered users in a tooltip message",
      "fixed \"assign layer to\" option not listing your own avatar",
      "fixed import failing for partially broken PNG files",
      " fixed drawing thumbnails being accessible for password protected drawings"
    ]
  },
  {
    "version": "1.0.18",
    "date": "(March 2021)",
    "changes": [
      "fixed hide UI option not preserving drawing position on screen when switching",
      "fixed issues with incorrect viewport in some rare cases",
      "fixed clear layer button becoming disabled during drawing",
      "fixed shift-line tool resulting in errors when usend with very large zoom levels"
    ]
  },
  {
    "version": "1.0.17",
    "date": "(March 2021)",
    "changes": [
      "fixed synchronization issue in some cases when undoing layer removal or merge",
      "fixed synchronization issue in some cases when deleting selection",
      "fixed issue with incorrect cursor position in some rare cases on certain systems",
      "fixed layer opacity getting stuck on wrong value in some rare cases",
      "fixed brush showing up incorrectly when drawing on empty layer with locked opacity",
      "reduced graphics memory usage"
    ]
  },
  {
    "version": "1.0.16",
    "date": "(February 2021)",
    "changes": [
      "fixed synchronization issue in some rare cases when moving selection",
      "fixed multiple issues with transform tool"
    ]
  },
  {
    "version": "1.0.15",
    "date": "(February 2021)",
    "changes": [
      "fixed synchronization issue in some rare cases when using delete selection"
    ]
  },
  {
    "version": "1.0.14",
    "date": "(February 2021)",
    "changes": [
      "fixed rendering issue when using eraser in some cases"
    ]
  },
  {
    "version": "1.0.13",
    "date": "(February 2021)",
    "changes": [
      "fixed issues with pasting files on Safari browsers",
      "fixed synchronization issue in some rare cases when using lasso selection"
    ]
  },
  {
    "version": "1.0.12",
    "date": "(February 2021)",
    "changes": [
      " added <b>Show cursors</b> permission option for restricting showing cursors to only approved or admin users",
      "added <b>Select all</b>, <b>Deselect</b> and <b>Invert selection</b> buttons to tool panel when any of the selection tools are active"
    ]
  },
  {
    "version": "1.0.11",
    "date": "(February 2021)",
    "changes": [
      "added default shortcut for <b>add layer</b> - <kbd>Shift</kbd> + <kbd>N</kbd>",
      "added default shortcut for <b>layer via copy</b> - <kbd>Shift</kbd> + <kbd>J</kbd>",
      "added default shortcut for <b>layer via cut</b> - <kbd>Ctrl</kbd> + <kbd>Shift</kbd> + <kbd>J</kbd>",
      "removed default shortcut for <b>duplicate layer</b>",
      "removed default shortcut for <b>clear layer</b>",
      "changed <b>layer via copy</b> to copy entire layer when executing it without any selection",
      "changed hand tool cursor",
      "fixed issues in some cases when using move or transform tool",
      "fixed issues with reconnecting"
    ]
  },
  {
    "version": "1.0.10",
    "date": "(January 2021)",
    "changes": [
      "improved image scaling quality with disabled graphics acceleration"
    ]
  },
  {
    "version": "1.0.9",
    "date": "(January 2021)",
    "changes": [
      "fixed desynchronization issue in some rare cases after disowning a layer"
    ]
  },
  {
    "version": "1.0.8",
    "date": "(January 2021)",
    "changes": [
      "fixed multiple server side issues",
      "improved some UI interactions"
    ]
  },
  {
    "version": "1.0.7",
    "date": "(January 2021)",
    "changes": [
      " added preview images when sharing drawing URL",
      "fixed multiple server side issues",
      "fixed scroll wheel zoom not blocked when drawing"
    ]
  },
  {
    "version": "1.0.6",
    "date": "(November 2020)",
    "changes": [
      "fixed incorrectly showing invalid size error"
    ]
  },
  {
    "version": "1.0.5",
    "date": "(November 2020)",
    "changes": [
      "improved graphics memory usage"
    ]
  },
  {
    "version": "1.0.4",
    "date": "(November 2020)",
    "changes": [
      "fixed not being able to draw for up to few seconds after pasting large image"
    ]
  },
  {
    "version": "1.0.3",
    "date": "(November 2020)",
    "changes": [
      "added progress indicators when copy or paste takes long time to complete",
      "added option to copy permissions when creating a new drawing",
      "fixed issues with disconnects when pasting large images",
      "fixed issues with disconnects when importing large files",
      "improved rendering performance in some cases"
    ]
  },
  {
    "version": "1.0.2",
    "date": "(November 2020)",
    "changes": [
      "fixed issues with redo after leaving layers in some cases",
      "fixed issue with memory management"
    ]
  },
  {
    "version": "1.0.1",
    "date": "(November 2020)",
    "changes": [
      "fixed errors after leaving layers in some rare cases"
    ]
  },
  {
    "version": "1.0.0",
    "date": "(November 2020)",
    "changes": [
      " added pro plan",
      " added textured brushes",
      " added new brush settings (<b>Full mode</b> option from dropdown on bottom right corner of color wheel)",
      " added predefined brush list",
      "fixed selecting incorrect layer after merging layers",
      "fixed brush size ratio not saved for each tool separately",
      "fixed redo not working correctly in some cases"
    ]
  }
];
