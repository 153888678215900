import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoginSignupService } from 'magma/services/login-signup.service';
import { storageGetItem } from 'magma/services/storage';
import { ToastService } from 'magma/services/toast.service';
import { CookieService } from 'ngx-cookie-service';
import { combineLatest } from 'rxjs';
import { AuthService } from 'services/auth.service';
import { InvitationQuery } from 'services/invitation.query';
import { InvitationService } from 'services/invitation.service';
import { ServerConstantVars, SignupUserData } from '../../../shared/interfaces';
import { AFTER_LOGIN_REDIRECT_URL_KEY } from './login-signup-page.component';
import { ServerConstant } from './utils';

export enum FormType {
  Login = 'login',
  SignUp = 'create-account',
  ResetPassword = 'resetPassword',
  EmailConfirmation = 'emailConfirmation',
  FinishRegisteration = 'finish-registration'
}

export interface FormTypeChangedData {
  formType: FormType;
}

// TODO: cleanup this component, a lot of unused properties, functions and styles
@UntilDestroy()
@Component({
  selector: 'login-signup',
  templateUrl: './login-signup.component.pug',
  styleUrls: ['./login-signup.component.scss'],
})
export class LoginSignupComponent implements OnDestroy {
  @ServerConstant('vars') vars!: ServerConstantVars;
  readonly productName = PRODUCT_INFO.name;

  @Input() formType: FormType = FormType.Login;
  @Input() token?: string;
  @Output() formTypeChanged = new EventEmitter<FormTypeChangedData>();
  @Output() redirect = new EventEmitter();

  formTypes = FormType;
  signup: SignupUserData = {
    email: '',
    name: '',
    password: '',
    newsletter: false,
    token: '',
  };

  extras = {};

  gotoForm(formType: FormType) {
    this.formType = formType;
    this.formTypeChanged.emit({ formType });
  }

  handleModeChange(mode: string) {
    if (mode === 'sign-in' || mode === 'login') this.gotoForm(FormType.Login);
    // TODO: use 'create-account' instead of '' in sign-up-box.pug
    else if (mode === 'create-account' || mode === '') this.gotoForm(FormType.SignUp);
    else if (mode === 'emailConfirmation') this.gotoForm(FormType.EmailConfirmation);
  }

  get mode() {
    return this.formType === FormType.SignUp ? '' : this.formType;
  }

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private invitationQuery: InvitationQuery,
    public loginSignupService: LoginSignupService,
    private invitationService: InvitationService,
    private cookieService: CookieService,
    private toastService: ToastService,
  ) {
    window.Intercom?.('update', {
      hide_default_launcher: false,
    });
    combineLatest([this.route.url, this.route.queryParams])
    .pipe(untilDestroyed(this))
    .subscribe(([route, params]) => {
      const { path } = route[0];
      if (path === FormType.FinishRegisteration || path === FormType.ResetPassword) {
        const { token } = this.route.snapshot.params;
        const { email, name } = params;
        this.extras = { email, name, token };
        if (path === FormType.FinishRegisteration) {
          this.authService.validateInviteToken(token, email).then(isValid => {
            if (!isValid) window.location.href = '/';
          }).catch((e) => {
            DEVELOPMENT && console.error(e);
            this.toastService.error({ message: 'Failed to verify invite, try again later or get a new invite link'});
          });
        }
      }
    });
  }

  ngOnDestroy() { }

  async ngOnInit() {
    try {
      const redirectUrl = storageGetItem(AFTER_LOGIN_REDIRECT_URL_KEY);
      if (redirectUrl?.startsWith('/invite/')) {
        const token = redirectUrl.replace('/invite/', '');
        const invite = await this.invitationService.getInviteTokenById(token); // TODO: catch
        if (IS_HOSTED) {
          // needed by hosted panels to override global sign up filter with a manual invite
          this.cookieService.set('invite-token', token, 1, '/');
        }
        this.extras = { ...this.extras, invite };
      }
    } catch (e) {
      DEVELOPMENT && console.error(e);
      this.toastService.error({ message: 'Failed to verify invite, try again later or get a new invite link', subtitle: e.message });
    }

    if (this.router.url === '/signupByInvitation') {
      this.invitationState$().subscribe(state => {
        if (state.data?.email) {
          this.signup.email = state.data.email;
        }
      });
    } else {
      this.authRoute();
    }
  }

  invitationState$() {
    return this.invitationQuery.invitationData$;
  }

  authRoute() {
    combineLatest([this.route.url, this.route.params, this.route.queryParams])
      .pipe(untilDestroyed(this))
      .subscribe(async ([url, params, queryParams]) => {
        const segment = url[0];

        if (
          this.authService.loggedIn && segment !== undefined &&
          (segment.path === FormType.Login || segment.path === FormType.SignUp)
        ) {
          this.redirect.emit();
        } else if (this.formType === FormType.SignUp) {
          const { email = '', token = '' } = queryParams;
          this.signup.email = email;
          this.signup.token = token;
        }
      });
  }

  cancel() {
    this.redirect.emit();
  }
}
