import { Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, Params } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getPageTitle, pageTitleToFullTitle } from 'shared/title-generator';
import { ActivatedRouteSnapshot, Data } from '@angular/router';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import { Analytics } from 'magma/common/interfaces';
import { setOgEntityName } from 'util/util';
import { logAction } from 'magma/common/actionLog';
import { ITrackService } from 'magma/services/track.service.interface';
import { TeamsQuery } from './team.query';
import { ProjectQuery } from './projects.query';
import { sendGAPageView } from 'magma/common/utilsAnalytics';
import { EntityData, EntityType } from 'shared/interfaces';
import { getEntityUrl } from 'shared/product-info';
import { routeToProject } from 'shared/utils';
import { navigateToDrawing, pushOpenedBy } from 'magma/common/clientUtils';
import { TeamService } from './team.service';
import { UserService } from './user.service';
import { getViewPageEvent } from 'shared/analytics';

export interface RouteData {
  page?: string; // indicates special pages
  pageTitle?: string; // for showing in navigation-bar
  noReload?: true;
  team?: true; // indicates that page is in team
  teamSelector?: string;
  title?: string; // for recent-entities-page
  onlyLive?: boolean; // for recent-entities-page
  path?: string; // for recent-entities-page
}

function getDeepestData({ data, firstChild }: ActivatedRouteSnapshot): Data {
  return firstChild ? { ...data, ...getDeepestData(firstChild) } : data;
}

function getDeepestParams({ params, firstChild }: ActivatedRouteSnapshot): Params {
  return firstChild ? { ...params, ...getDeepestParams(firstChild) } : params;
}

@Injectable({ providedIn: 'root' })
@UntilDestroy()
export class RouterService {
  data$ = new BehaviorSubject<RouteData>({});
  url$ = new BehaviorSubject<string>('');
  private lastPage = '';
  constructor(
    private router: Router,
    private teamService: TeamService,
    userService: UserService,
    track: ITrackService,
    teamsQuery: TeamsQuery,
    projectQuery: ProjectQuery,
  ) {
    router.events
      .pipe(untilDestroyed(this))
      .subscribe(event => {
        if (event instanceof NavigationStart) {
          const entityName = router.getCurrentNavigation()?.extras?.state?.entityName;
          if (entityName) {
            setOgEntityName(entityName);
          }
        }

        if (event instanceof NavigationEnd) {
          const url = event.urlAfterRedirects;

          logAction(`navigate ${url}`);

          const data: RouteData = getDeepestData(router.routerState.snapshot.root);
          this.data$.next(data);
          this.url$.next(url);

          let page = getPageTitle(url);
          if (!page && url.startsWith('/d/')) page = 'Drawing';
          if (page && page !== this.lastPage) {
            firstValueFrom(userService.user$).then((user) => {
              const viewPageEvent = getViewPageEvent(user, url);
              track.event(Analytics.ViewPage, viewPageEvent);
            }).catch((e) => { DEVELOPMENT && console.log(e); });

            sendGAPageView(url.replace(/\/d\/.+/, '/d/DRAWING'));
            this.lastPage = page;
          }
        }
      });

    // document title
    combineLatest([this.url$, this.data$, teamsQuery.selectActive(), projectQuery.selectActive()])
      .pipe(untilDestroyed(this))
      .subscribe(([url, data, team, project]) => {
        let page: string | undefined = undefined;

        if (data.team && team) {
          page = ((data.title || project) ? `${data.title ?? project!.name} | ${team.name}` : team.name);
        } else {
          page = getPageTitle(url);
        }

        window.document.title = pageTitleToFullTitle(page);

        if (DEVELOPMENT && url && !page && !data.team && !url.startsWith('/d/')) {
          console.warn('Missing page title for:', url);
        }
      });
  }

  navigateToEntity(entity: EntityData, openedBy: string) {
    if (entity.type === EntityType.Folder) {
      const teamSlug = entity.team ? this.teamService.getTeamSlug(entity.team) : undefined;
      const projectId = typeof entity.project === 'string' ? entity.project : entity.project?._id;
      this.navigateToFolder(teamSlug, projectId, entity._id);
    } else {
      pushOpenedBy(entity.shortId, openedBy);
      void this.router.navigateByUrl(getEntityUrl(entity));
    }
  }

  // TODO: remove ?
  navigateToFolder(teamSlug: string | undefined, projectId: string | undefined, folder: string | undefined) {
    const params = folder ? { queryParams: { folder } } : {};

    if (teamSlug && projectId) {
      void this.router.navigate(routeToProject(teamSlug, projectId), params);
    } else {
      void this.router.navigate([`/my/${PRODUCT_INFO.home?.route}`], params);
    }
  }

  navigateToDrawing(id: string, openedBy: string) {
    return navigateToDrawing(this.router, id, openedBy);
  }
}
