import { logAction } from './actionLog';
import { isIE } from './userAgentUtils';

let requestNumber = 1;

export function request<T>(
  url: string, method: 'GET' | 'POST', responseType: XMLHttpRequestResponseType = 'json', data?: FormData | any
) {
  const message = `${method} ${url} #${requestNumber++} (xhr)`;
  logAction(message);

  return new Promise<T>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onloadstart = () => xhr.responseType = responseType;
    xhr.onload = () => {
      logAction(`${message} -> ${xhr.status}`);

      if (xhr.status === 200) {
        let response = xhr.response;

        // HACK: IE11 doesn't parse json for some reason
        if (isIE && responseType === 'json' && typeof response === 'string') {
          try {
            response = JSON.parse(response);
          } catch { }
        }

        resolve(response);
      } else {
        reject(new Error(`Invalid response: ${xhr.status} ${xhr.statusText}`));
      }
    };
    xhr.onerror = () => {
      logAction(`${message} -> failed`);
      reject(new Error(`Request failed`));
    };
    xhr.open(method, url, true);

    if (data && !(data instanceof FormData)) {
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify(data));
    } else {
      xhr.send(data);
    }
  });
}

export function post<T>(url: string, data: FormData | any, responseType: XMLHttpRequestResponseType = 'json') {
  return request<T>(url, 'POST', responseType, data);
}

export function get<T>(url: string, responseType: XMLHttpRequestResponseType = 'json') {
  return request<T>(url, 'GET', responseType);
}
