import { readPsd } from 'ag-psd';
import { canDrawOnActiveLayer, canEdit, Editor, isFilterActive, lockEditor, unlockEditor } from './editor';
import { screenToDocumentPoint } from '../common/viewport';
import { HelpSection, Layer, PasteLayerData, Rect, ToolSource, Viewport } from '../common/interfaces';
import { isMaskEmpty } from '../common/mask';
import { blobToArrayBuffer, blobToImage, canvasToBlob, canvasToUint8Array, createCanvas, getContext2d, isImageDataIdentical, rotateAndFlipRaw } from '../common/canvasUtils';
import { cloneRect, createRect, rectsEqual, rectsIntersection } from '../common/rect';
import { setLastToolSource } from '../common/toolUtils';
import { createPoint } from '../common/point';
import { getTransformedSelection } from '../common/user';
import { hasPermission } from '../common/userRole';
import { MAX_PASTE_IMAGE_SIZE, MAX_PASTE_SIZE_FREE, MAX_PASTE_SIZE_PRO } from '../common/constants';
import { logAction } from '../common/actionLog';
import { clamp } from '../common/mathUtils';
import { isiOS } from '../common/userAgentUtils';
import { showHelpAlert } from './help';
import { createMat2d } from '../common/mat2d';
import { createTransform, getTransformedRectBounds } from '../common/toolSurface';
import { getImageInfo, ImageInfo, ImageType, isValidImageTypeForPaste } from '../common/imageUtils';
import { psdLayerData } from '../server/importPsd';
import { ownLayer, selectLayer, withNewLayers } from './layerActions';
import { getLayer, getLayerSafe, getNewLayerName } from '../common/drawing';
import { isLayerEmpty } from '../common/layerUtils';
import { cropPsdLayerImageData, flattenPsdLayers } from '../common/psdHelpers';
import { byteSize } from '../common/utils';
import { invalidEnum, removeFileExtension } from '../common/baseUtils';
import { importFile } from './importUtils';
import { invokeRasterizeFlow } from '../common/text/text-utils';
import { isTextLayer } from '../common/layer';

let lastPaste = 0;
let inPlaceCopy: Rect | undefined = undefined;
let copyPasteInProgress = false;

export function clipboardSupported() {
  return navigator.clipboard !== undefined &&
    navigator.clipboard.write !== undefined &&
    navigator.clipboard.read !== undefined &&
    typeof ClipboardItem !== 'undefined';
}

export function canCopyToClipboard(editor: Editor) {
  return clipboardSupported() && !isMaskEmpty(editor.model.user.selection);
}

export function rotateCanvasToView(canvas: HTMLCanvasElement, view: Viewport) {
  let rot = Math.round(-view.rotation / (Math.PI / 2));
  while (rot < 0) rot += 4;
  rot = rot % 4;

  if (view.flipped || rot) {
    let { width, height } = canvas;
    if (rot === 1 || rot === 3) [width, height] = [height, width];
    const flipped = createCanvas(width, height);
    const context = getContext2d(flipped);
    context.translate(0.5 * width, 0.5 * height);
    context.rotate(rot * Math.PI * 0.5);
    if (view.flipped) context.scale(-1, 1);
    context.translate(-0.5 * canvas.width, -0.5 * canvas.height);
    context.drawImage(canvas, 0, 0);

    if (TESTS && '__raw' in canvas) {
      (flipped as any).__raw = rotateAndFlipRaw((canvas as any).__raw, rot, view.flipped);
    }

    canvas = flipped;
  }

  return canvas;
}

export async function copyToClipboard(editor: Editor, merged: boolean, background: boolean) {
  if (copyPasteInProgress) return false;
  copyPasteInProgress = true;

  const done = editor.model.startTask('Copying...');

  try {
    if (!clipboardSupported()) return false;

    const start = performance.now();
    const selection = getTransformedSelection(editor.model.user);
    let bounds = createRect(0, 0, 0, 0);
    let canvas: HTMLCanvasElement | undefined;

    if (merged) {
      const bg = editor.drawing.background;
      if (!background) editor.drawing.background = undefined;
      canvas = editor.renderer.getDrawingSnapshot(editor.drawing, selection);
      editor.drawing.background = bg;
      bounds = isMaskEmpty(selection) ? cloneRect(editor.drawing.rect) : rectsIntersection(selection.bounds, editor.drawing.rect);
    } else {
      if (!editor.activeLayer) return false;
      canvas = editor.renderer.getLayerSnapshot(editor.activeLayer, selection, bounds);
    }

    if (!canvas) return false;

    canvas = rotateCanvasToView(canvas, editor.view);

    let blob: Blob | Promise<Blob> | null = null;
    const startBlob = performance.now();

    if (TESTS) {
      blob = { type: 'canvas', canvas } as any;
    } else if (isiOS) {
      // on iOS clipboard.write has to be called in event handler, but the value can be a promise
      blob = canvasToBlob(canvas) as Promise<Blob>;
    } else {
      blob = await canvasToBlob(canvas);
    }

    if (!blob) return false;

    const startWrite = performance.now();
    await navigator.clipboard.write!([new ClipboardItem({ 'image/png': blob })]);

    inPlaceCopy = bounds;
    DEVELOPMENT && !TESTS && console.log(`copy: size: ${byteSize('size' in blob ? blob.size : 0)}, time: (` +
      `snapshot: ${(startBlob - start).toFixed(2)}ms, ` +
      `blob: ${(startWrite - startBlob).toFixed(2)}ms, ` +
      `write: ${(performance.now() - startWrite).toFixed(2)}ms)`);
    return true;
  } catch (e) {
    if (e.message === 'Document is not focused.') return false;

    if (/permission/i.test(e.message)) {
      editor.model.showError('Clipboard permission was denied, you need to allow access to clipboard using clipboad icon in the address bar', false);
      return false;
    } else {
      throw e;
    }
  } finally {
    done();
    copyPasteInProgress = false;
  }
}

export function canPasteFromClipboard(editor: Editor) {
  if (!clipboardSupported() || isFilterActive(editor)) return false;

  // don't check if layer is editable here, so we can show error later
  return canEdit(editor) && !editor.drawingInProgress;
}

async function readImageFromClipboard() {
  for (const item of await navigator.clipboard.read!()) {
    try {
      for (const type of item.types) {
        if (type === 'image/png') {
          return await item.getType(type);
        }
      }
    } catch (e) {
      DEVELOPMENT && console.error(e);
    }
  }

  return undefined;
}

function checkPermissionForPaste(editor: Editor) {
  if (!hasPermission(editor.drawing, editor.model.user, 'paste')) {
    editor.helpService.show({
      text: `You don't have permission to paste`,
      section: HelpSection.Layer,
    });
    return false;
  }
  return true;
}

let startRead = 0, endRead = 0;

export interface PasteOptions {
  inPlace?: boolean;
  canPastePsd?: boolean;
  onNewLayer?: boolean;
  skipMultiPasteProtection?: boolean;
}

export async function pasteFromClipboard(editor: Editor, options: PasteOptions) {
  if (copyPasteInProgress) return;
  copyPasteInProgress = true;

  const done = editor.model.startTask('Pasting...');

  try {
    if (!clipboardSupported()) return;

    startRead = performance.now();
    const blob = await readImageFromClipboard();
    endRead = performance.now();

    if (!blob) return;
    if (!checkPermissionForPaste(editor)) return;

    await pasteImage(editor, blob, options);
  } catch (e) {
    if (e.message === 'No valid data on clipboard.' || e.message === 'Document is not focused.') return;

    if (/permission|to use custom clipboard|The user dismissed the/i.test(e.message)) {
      editor.model.showError('Clipboard permission was denied, you need to allow access to clipboard using clipboad icon in the address bar', false);
    } else {
      DEVELOPMENT && console.error(e);
      editor.errorReporter.reportError(e.message, e);
      editor.model.showError(e.message, false);
    }
  } finally {
    done();
    copyPasteInProgress = false;
  }
}

export async function dropFiles(editor: Editor, files: (File | Blob)[], _x: number, _y: number, options: PasteOptions) {
  if (copyPasteInProgress) return;
  copyPasteInProgress = true;

  const done = editor.model.startTask('Pasting...');
  const hasPdf = files.some(file => file.type === 'application/pdf');

  try {
    if (!checkPermissionForPaste(editor)) return;

    startRead = endRead = 0;

    setLastToolSource(ToolSource.FileDrop);
    const importMode = (files.length === 1 || !IS_PORTAL) ? 'single' : await editor.model.modals.multiFileImportEditor(hasPdf, false);

    switch (importMode) {
      case 'single':
        await pasteImage(editor, files[0], options);
        break;
      case 'layers':
        for (const file of files) {
          await pasteImage(editor, file, { ...options, onNewLayer: true, skipMultiPasteProtection: true });
        }
        break;
      case 'sequence': {
        for (const file of files) {
          await importFile(editor.model, file, true);
        }
        break;
      }
      case 'separate': break;
      case undefined: break;
      default: invalidEnum(importMode);
    }
  } catch (e) {
    DEVELOPMENT && console.error(e);
    editor.errorReporter.reportError(e.message, e);
    editor.model.showError(e.message, false);
  } finally {
    done();
    copyPasteInProgress = false;
  }
}

function checkPasteSize(editor: Editor, size: number) {
  const { pro, isSuperAdmin } = editor.model.user;

  if (!isSuperAdmin && size > MAX_PASTE_SIZE_PRO) {
    editor.helpService.show({ text: `Data size is too large for pasting`, section: HelpSection.Layer });
    return false;
  }

  if (!pro && !isSuperAdmin && size > MAX_PASTE_SIZE_FREE) {
    editor.helpService.show({ text: `Data size is too large for pasting, you can extend the size limit with Blaze`, section: HelpSection.Layer });
    return false;
  }

  return true;
}

async function pasteImage(editor: Editor, blob: Blob | File, { inPlace, onNewLayer, canPastePsd, skipMultiPasteProtection }: PasteOptions) {
  let image: HTMLCanvasElement | HTMLImageElement | ImageBitmap | undefined = undefined;

  try {
    if (!checkPasteSize(editor, blob.size)) return;

    const buffer = await blobToArrayBuffer(blob);
    if (!buffer) return;

    let data: Uint8Array | undefined = new Uint8Array(buffer);
    let info: ImageInfo;

    try {
      info = getImageInfo(data);
      if (!isValidImageTypeForPaste(info.type)) {
        editor.helpService.show({ text: `This image type is not supported`, section: HelpSection.Layer });
        return;
      }
    } catch (e) {
      if (e.message.startsWith('Failed to parse PSD file')) {
        editor.model.errorWithData(e.message, '', data);
      }
      throw e;
    }

    const startBlob = performance.now();
    if (info.type === ImageType.PSD) {
      if (info.layers && canPastePsd && (IS_PORTAL || TESTS || DEVELOPMENT)) {
        const importAs = await editor.model.modals.psdImport();

        if (!importAs) return;

        if (importAs === 'layers' || importAs === 'sync') {
          return await importPsdLayers(editor, data, importAs === 'sync');
        }
      }

      image = readPsd(data, { skipLayerImageData: true, skipLinkedFilesData: true, skipThumbnail: true }).canvas;
    } else {
      image = await blobToImage(blob);
    }
    const endBlob = performance.now();

    if (!image) return;

    if (!TESTS && !skipMultiPasteProtection && (performance.now() - lastPaste) < 1000) return; // prevent double pasting

    logAction(`[local] pasteImage (name: ${(blob as File).name})`); // just to check file name/ext if paste fails

    if (Math.max(image.width, image.height) > MAX_PASTE_IMAGE_SIZE) {
      // scale down picture if it's too large
      while (Math.max(image.width, image.height) > MAX_PASTE_IMAGE_SIZE) {
        const canvas = createCanvas(image.width / 2, image.height / 2);
        const context = getContext2d(canvas);
        context.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);
        if ('close' in image) image.close();
        image = canvas;
      }

      data = await canvasToUint8Array(image as HTMLCanvasElement);
    } else if (info.type !== ImageType.PNG && info.type !== ImageType.JPG) {
      // convert image to PNG if it's incorrect format
      DEVELOPMENT && console.log('converting to PNG');
      const canvas = createCanvas(image.width, image.height);
      const context = getContext2d(canvas);
      context.drawImage(image, 0, 0);
      data = await canvasToUint8Array(canvas);
    }

    if (!data || editor.drawingInProgress) return;

    if (!canEdit(editor)) return;

    const isNewLayer = onNewLayer;
    const theImage = image;
    const theData = data;

    if (!isNewLayer && !canDrawOnActiveLayer(editor)) {
      showHelpAlert(editor);
      return;
    }

    if (!onNewLayer && isTextLayer(editor.activeLayer)) {
      logAction(`[local] pasteImage (rasterize layer)`);
      const rasterized = await invokeRasterizeFlow(editor, editor.activeLayer);
      if (!rasterized) return;
    }

    if (!checkPasteSize(editor, data.byteLength)) return;

    const finalize = async (layerId: number) => {
      let rot = Math.round(editor.view.rotation / (Math.PI / 2));
      while (rot < 0) rot += 4;
      rot = rot % 4;

      let { width, height } = theImage;
      if (rot === 1 || rot === 3) [width, height] = [height, width];

      const scale = Math.min(1, editor.drawing.width / width, editor.drawing.height / height);
      const point = createPoint(editor.view.width / 2, editor.view.height / 2);
      screenToDocumentPoint(point, editor.view);
      let x = Math.floor(point.x - width * scale / 2);
      let y = Math.floor(point.y - height * scale / 2);
      let pastedInPlace = false;

      if (inPlace && inPlaceCopy && inPlaceCopy.w === width && inPlaceCopy.h === height) {
        x = inPlaceCopy.x;
        y = inPlaceCopy.y;
        pastedInPlace = true;
      }

      x = clamp(x, 0, editor.drawing.width - width * scale);
      y = clamp(y, 0, editor.drawing.height - height * scale);

      let sx = scale;
      let sy = scale;
      let r = rot * Math.PI * 0.5;

      if (editor.view.flipped) {
        if (rot === 0 || rot === 2) {
          sx = -sx;
        } else {
          sy = -sy;
        }
      }

      // compensate from rotation and scale
      const rect = createRect(0, 0, theImage.width, theImage.height);
      const mat = createMat2d();
      createTransform(mat, 0, 0, r, sx, sy);
      const bounds = getTransformedRectBounds(rect, mat);
      x = Math.round(x - bounds.x);
      y = Math.round(y - bounds.y);

      logAction(`[local] pasteImage (image: ${theImage.width}x${theImage.height}, ` +
        `rect: (${rect.x}, ${rect.y}, ${rect.w}, ${rect.h}), transform: [${x}, ${y}, ${sx}, ${sy}, ${r}], ` +
        `blob: ${byteSize(blob.size)}, data: ${byteSize(theData.byteLength)}, ` +
        `in-place: ${pastedInPlace})`);

      let promise: Promise<void>;

      const transform = [x, y, sx, sy, r];

      if (isNewLayer) {
        const layer = editor.activeLayer;
        const index = layer ? editor.drawing.layers.indexOf(layer) : 0;
        const name = removeFileExtension('name' in blob ? blob.name : '') || getNewLayerName(editor.drawing);
        promise = editor.pasteTool.pasteOnNewLayer({ id: layerId, name }, index, rect, transform, theData, theImage);
        const newLayer = getLayer(editor.drawing, layerId);
        if (newLayer) selectLayer(editor, newLayer, true);
      } else {
        promise = editor.pasteTool.paste(layerId, rect, transform, theData, theImage, true);
      }

      if (scale < 1) {
        editor.activeTool = editor.transformTool;
      }

      DEVELOPMENT && !TESTS && console.log(
        `paste: size: ${byteSize(blob.size)}, ` +
        `raw: ${byteSize(width * height * 4)}, time: (` +
        `read: ${(endRead - startRead).toFixed(2)}ms, ` +
        `blob: ${(endBlob - startBlob).toFixed(2)}ms)`);

      lastPaste = performance.now();
      lockEditor(editor, 'pasting');
      await promise;
    };

    if (isNewLayer) {
      let promise = Promise.resolve();
      await withNewLayers(editor, 1, () => true, ([id]) => promise = finalize(id));
      await promise;
    } else {
      await finalize(editor.activeLayer!.id);
    }
  } finally {
    if (image && 'close' in image) image.close();
    unlockEditor(editor);
  }
}

async function importPsdLayers(editor: Editor, data: Uint8Array, sync: boolean) {
  const psd = readPsd(data, { skipLinkedFilesData: true, skipThumbnail: true, useImageData: true });
  const psdLayers = flattenPsdLayers(psd).layers;
  const { drawing, renderer } = editor;
  const layerOrder: (PasteLayerData | Layer)[] = [];
  const layers: PasteLayerData[] = [];
  const matchedLayers: (Layer | undefined)[] = psdLayers.map(() => undefined);

  // match layers for sync
  if (sync) {
    const usedLayers = new Set<Layer>();

    // match layers by ID
    if (psd.width === drawing.width && psd.height === drawing.height && psdLayers.every(l => l.id !== undefined)) {
      for (let i = 0; i < psdLayers.length; i++) {
        const layer = getLayer(drawing, psdLayers[i].id!);
        if (layer) usedLayers.add(matchedLayers[i] = layer);
      }
    }

    // match layers by name
    for (let i = 0; i < psdLayers.length; i++) {
      if (!matchedLayers[i]) {
        matchedLayers[i] = drawing.layers.find(l => !usedLayers.has(l) && l.name === psdLayers[i].name);
      }
    }
  }

  for (let i = 0; i < psdLayers.length; i++) {
    const psdLayer = psdLayers[i];
    const layer = matchedLayers[i];
    const pasteLayer: PasteLayerData = {
      ...psdLayerData(psdLayer),
      id: layer?.id ?? 0,
      srcLayerIndex: i,
    };

    const cropped = cropPsdLayerImageData(psdLayer, drawing);

    if (cropped) {
      // check if we need to update image data
      if (!layer || !rectsEqual(layer.rect, cropped.rect) || !isImageDataIdentical(renderer.getLayerRawData(layer), cropped.imageData)) {
        pasteLayer.rect = cropped.rect;
        pasteLayer.imageData = cropped.imageData;
      }
    } else if (layer && !isLayerEmpty(layer)) {
      pasteLayer.rect = createRect(0, 0, 0, 0); // empty rect indicates clearing of layer data
    }

    // TODO: skip layer completely if all params are the same

    layers.push(pasteLayer);
    layerOrder.push(layer ? layer : pasteLayer);
  }

  for (let i = 0; i < drawing.layers.length; i++) {
    const layer = drawing.layers[i];

    if (sync) {
      if (!layerOrder.includes(layer)) {
        if (i === 0) {
          layerOrder.unshift(layer);
        } else {
          const prevLayer = drawing.layers[i - 1];
          const indexOfPrevLayer = layerOrder.indexOf(prevLayer);
          layerOrder.splice(indexOfPrevLayer + 1, 0, layer);
        }
      }
    } else {
      layerOrder.push(layer);
    }
  }

  const addCount = layers.filter(l => !l.id).length;

  // own all layers that need to be updated
  for (const { id } of layers) {
    if (!id) continue;

    const layer = getLayerSafe(drawing, id);
    if (layer.owner !== editor.model.user) {
      const owned = await ownLayer(editor, layer);
      if (!owned) throw new Error(`Cannot take over layer "${layer.name}"`);
    }
  }

  if (addCount) {
    // add missing layers
    let promise = Promise.resolve();
    await withNewLayers(editor, addCount, () => true, ids => promise = finalize(ids));
    await promise;
  } else {
    await finalize([]);
  }

  async function finalize(ids: number[]) {
    // set IDs and do final verification
    for (const layer of layers) {
      if (layer.id) {
        if (getLayer(drawing, layer.id)?.owner !== editor.model.user) {
          throw new Error(`Failed to update drawing due to changes during update`);
        }
      } else if (ids.length) {
        layer.id = ids.pop()!;
      } else {
        throw new Error(`Failed to update drawing due to changes during update`);
      }
    }

    await editor.pasteTool.pasteLayers(sync, layers, layerOrder.map(l => l.id), data);
  }
}
