import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { AppNotification, AppNotificationSettingsOption, AppNotificationStatus } from '../common/interfaces';

export abstract class IAppNotificationService {
  notifications$ = new BehaviorSubject<AppNotification[]>([]);
  notificationCounters$ = new BehaviorSubject<Map<string, number>>(new Map());
  abstract loadMore(skip: number, teamId: string | null): Promise<number>;
  abstract observeNotifications(): Promise<Observable<AppNotification>>;
  abstract observeTeamNotifications(teamId: string): Observable<AppNotification[]>;

  abstract getNotifications(teamId: string | null, limit: number, skip: number): Promise<AppNotification[]>;
  abstract updateNotificationStatus(shortId: string, status: AppNotificationStatus): Promise<void>;
  abstract markAllAsRead(teamId: string | null): Promise<void>;
  abstract hasUnread(teamId: string | null): Promise<boolean>;
  abstract markUnreadAsViewed(teamId: string | null, notificationIds: string[]): Promise<void>;

  abstract updateNotificationSettings(entityId: string, setting: AppNotificationSettingsOption): Promise<void>;
  abstract getNotificationSettings(entityId: string): Promise<AppNotificationSettingsOption>;
}
