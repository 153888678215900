import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { InvitationStore } from './invitation.store';
import { InvitationDataToken } from './invitation.service';

export interface InvitationState {
  message: string;
  error: boolean;
  showTwoTeamsModal: boolean;
  id: string;
  data: InvitationDataToken;
}

@Injectable({ providedIn: 'root' })
export class InvitationQuery extends Query<InvitationState> {

  invitationData$ = this.select(state => state);

  constructor(protected store: InvitationStore) {
    super(store);
  }
}
