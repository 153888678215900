import { Drawing, DrawOptions, Feature, hasCtrlOrMetaKey, ITool, IToolData, IToolEditor, IToolModel, IUndoFunction, Layer, LayerData, Mutable, Point, TabletEvent, TabletEventFlags, TextLayer, TextSelection, ToolId, Viewport } from '../interfaces';
import { faText } from '../icons';
import { FontFamily, FontStyleNames, fontStyleNameToWeight, isBold, isItalic } from '../text/font-family';
import { assertTextarea, createTextarea, FixedWidthTextarea, insertCharactersIntoTextarea, isBoxTextareaType, removeCharactersFromTextarea, sanitizeEmojis, Textarea, TEXTAREA_BOUNDARIES_COLOR, TEXTAREA_UNHOVERED_BOUNDARIES_WIDTH, TextareaOptions, TextareaType } from '../text/textarea';
import { uniq } from 'lodash';
import { AnyLevelTextFormattingDescription, CharacterFormatting, CharacterFormattingDescription, DEFAULT_CHARACTER_FORMATTING, DEFAULT_PARAGRAPH_FORMATTING, DEFAULT_TEXTAREA_FORMATTING, getAppliedFormattingName, isValidCharacterFormatting, isValidParagraphFormatting, MAX_SAFE_BASELINE_SHIFT, MAX_SAFE_LETTERSPACING, MAX_SAFE_LINEHEIGHT, MAX_SAFE_SCALE_X, MAX_SAFE_SCALE_Y, MAX_SAFE_SIZE, MIN_SAFE_BASELINE_SHIFT, MIN_SAFE_LETTERSPACING, MIN_SAFE_LINEHEIGHT, MIN_SAFE_SCALE_X, MIN_SAFE_SCALE_Y, MIN_SAFE_SIZE, ParagraphFormatting, ParagraphFormattingDescription, TextAlignment, TextareaFormatting, TextCases, TextRange, VerticalAlignments } from '../text/formatting';
import { colorToCSS, getAlpha, parseColor, withAlpha } from '../color';
import { cloneRect, copyRect, createRect, integerizeRect, normalizeRect, resetRect, setRect } from '../rect';
import { clonePoint, copyPoint, createPoint, setPoint } from '../point';
import { pickLayerFromEditor, removeLayer, selectLayer, withNewLayers } from '../../services/layerActions';
import { Editor, isLoadedConnectedAndNotLocked } from '../../services/editor';
import { getLayerName, isTextLayer, layerFromState, setLayerState, toLayerState } from '../layer';
import { addLayerToDrawing, sendLayerOrder } from '../layerToolHelpers';
import { redraw, redrawDrawing, redrawLayer } from '../../services/editorUtils';
import { FONTS, FONTS_SOURCES, hasFontsLoaded, isFontLoaded, loadAnotherFont, loadFontsForLayer } from '../text/fonts';
import { assertTextareaControlPoint, TextareaControlPoint, TextareaControlPointDirections } from '../text/control-points';
import { clamp, distance, generateNumbersArray, isOdd } from '../mathUtils';
import { clipToDrawingRect, forAllTextLayers, getLayer } from '../drawing';
import { doubleIndexToCharacter, handleArrowDownVerticalSelection, handleArrowUpVerticalSelection, handleEndVerticalSelection, handleHomeVerticalSelection, pointToDoubledIndex, TEXT_SELECTION_DIRECTION_BACKWARD, TEXT_SELECTION_DIRECTION_FORWARD, TEXT_SELECTION_DIRECTION_NONE, TextSelectionDetailed } from '../text/navigation';
import { Key, MagmaKeyboardEvent } from '../input';
import { AUTO_SETTING_STRING, AutoOr, getPixelRatio, isControl } from '../utils';
import { charactersToWordsWithWhitespaces } from '../text/text-character';
import { canDrawTextLayer, getUsedFonts, textLayerToTextBox } from '../text/text-utils';
import { copyTextFromTextarea, copyTextFromTextareaViaClipboardEvent, cutTextFromTextareaViaClipboardEvent, displayToastAboutNotSupportedClipboard, pasteIntoTextarea, pasteIntoTextareaViaClipboardEvent } from '../text/clipboard';
import { History as GlobalHistory } from '../history';
import { clipboardSupported } from '../../services/copyPasteActions';
import { createVec2, setVec2, transformVec2ByMat2d } from '../vec2';
import { createMat2d, decomposeMat2d, invertMat2d } from '../mat2d';
import { documentToScreenPoint, documentToScreenXY } from '../viewport';
import { finishTransform } from '../toolUtils';
import { Mandatory } from '../typescript-utils';
import { keys } from '../baseUtils';
import { imageDataToBitmapData, trimImage } from '../canvasUtils';
import { logAction } from '../actionLog';

const CLICK_OR_DRAG_THRESHOLD = 10;
const TYPING_IDLE_DEBOUNCE = 800;
const SHORT_TEXTAREA_PLACEHOLDER = 'Lorem ipsum';
export const LONG_TEXTAREA_PLACEHOLDER = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.';
export const TEXT_TOOL_ARTIFICIAL_TEXTAREA_CLASS = 'text-tool-input';
const ARTIFICIAL_TEXTAREA_SIZE = 1;

// debug switches:
export const DRAW_TEXTURE_RECT = false;
const SHOW_ARTIFICIAL_TEXTAREA = false;

export interface TextToolData extends IToolData {
  layerIndex: number;
  layerData: LayerData;
  rasterizedFrom?: LayerData;
  mode: TextToolMode;
  history: boolean;
}

export enum TextToolMode {
  Creating = 'creating', // only invoked with end()
  Typing = 'typing', // every action changing textarea value so not only input event but also pasting, cutting etc.
  Moving = 'moving',
  Resizing = 'resizing',
  Formatting = 'formatting',
  Rasterizing = 'rasterizing',
  Selecting = 'selecting', // doesn't synchronize anything to remote, shouldn't ever call doTool with it
  Focusing = 'focusing', // does synchronize state at remote but doesn't account as an action (does not modify drawing, doesnt add undos, doesn't clear redos)
}

const tempVec2 = createVec2();
const tempMat2d = createMat2d();

export class TextTool implements ITool {
  id = ToolId.Text;
  name = 'Text tool';
  icon = faText;
  feature = Feature.Text;
  onlyPortal = true;
  skipMoves = true;
  contextMenu = true;
  continuousRedraw = true;
  description = 'Enter text from keyboard to your artworks';
  video = { url: 'assets/videos/text.mp4', width: 374, height: 210 };
  editor: IToolEditor;
  layer: TextLayer | undefined = undefined;
  cursor = 'cursor-default';
  cancellableLocally = true;
  nonDrawing = true;
  mode = TextToolMode.Typing;
  fields = keys<TextTool>(['fontFamily', 'fontStyle', 'fontSize', 'lineheight', 'letterSpacing', 'baselineShift', 'bold', 'italic', 'underline', 'strikethrough', 'alignment', 'textareaType', 'verticalAlignment', 'scaleX', 'scaleY', 'textCase']);
  private _focused = false;
  get focused() {
    return !!this.input && this._focused;
  }
  set focused(value: boolean) {
    if (SERVER) {
      if (DEVELOPMENT) throw new Error(`TextTool.focused was set on server. That should not be needed.`);
      else return;
    }

    const wasDifferent = value !== this._focused;

    redraw(this.editor);
    this._focused = value;
    if (this._focused) {
      this.input?.focus();
      this.input?.classList.remove('ks-allow');
      if (this.textarea) this.textarea.blinkOffset = performance.now();
    } else {
      this.input?.blur();
      this.input?.classList.add('ks-allow');
      if (this.textarea && this.textarea.textareaFormatting.displayNonPrintableCharacters) this.applyTextareaFormatting({ displayNonPrintableCharacters: false });
      setTimeout(() => this.hover((this.editor as Editor).lastMoveX, (this.editor as Editor).lastMoveY, undefined));
    }

    const doToolOptions = this.recomputeTextLocally(TextToolMode.Focusing);
    if (isLayerOk(this.layer, this.editor.drawing) && wasDifferent && doToolOptions && isLoadedConnectedAndNotLocked(this.editor as Editor)) {
      doToolOptions.history = false;
      doToolOptions.preventHistory = true;
      this.runDoTool(this.layer, doToolOptions);
    }

    if (this.textarea) this.textarea.isFocused = this.focused;
  }

  input: HTMLTextAreaElement | undefined = undefined;
  initialised = false;

  constructor(editor: IToolEditor, public model: IToolModel) {
    this.editor = editor;
  }

  get textarea() {
    return this.layer?.textarea;
  }

  set textarea(value: Textarea | undefined) {
    if (this.layer) this.layer.textarea = value;
  }

  initTool() {
    this.onLayerChange(this.model.user.activeLayer);
    this.fontFamily = this.selectableFontFamilies[0];
    this.editor.apply(() => { });
    if (!SERVER) this.setupBodyListeners();
    this.initialised = true;
  }

  setupBodyListeners() {
    document.body.addEventListener('keydown', (e) => {
      const target = e.target as HTMLElement;
      if ((this.editor as Editor).selectedTool?.id === this.id && this.focused && !isControl(target)) {
        this.focused = true;
        this.keydownHandler(e);
      }
    });

    document.body.addEventListener('focusout', (e) => {
      if (this.isUsingTextTool && !e.relatedTarget && isTextLayer(this.layer)) {
        // it's important to check if this.layer is not undefined here because when restoring initial state
        // we first set layer to undefined just for this so we don't refocus input about to be deleted
        // without this check keyboard service can break in few scenarios (for example removing layer with undo)
        this.input?.focus();
      }
    });
  }

  cancel() {
    if (isLayerOk(this.model.user.activeLayer, this.editor.drawing) && this.mode === TextToolMode.Creating) {
      this.onLayerChange(this.model.user.activeLayer);
    }
  }

  onSelect() {
    const editor = this.editor as Editor;
    if (isLoadedConnectedAndNotLocked(editor)) {
      const layer = this.model.user.activeLayer;
      this.onLayerEnter(layer);
    }
  }

  getSelection(): TextSelectionDetailed | undefined {
    if (this.input && isTextareaOk(this.textarea)) {
      const textarea = this.textarea;

      const { selectionStart, selectionEnd } = this.input;

      const startCharacter = textarea.characters[selectionStart];
      let endCharacter = textarea.characters[selectionEnd];

      const startParagraphIndex = startCharacter ? textarea.findParagraphIndexFromCharacter(startCharacter) : 0;
      const endParagraphIndex = endCharacter ? textarea.findParagraphIndexFromCharacter(endCharacter) : 0;

      return {
        start: selectionStart,
        end: selectionEnd,
        length: Math.max(selectionEnd - selectionStart, 0),
        direction: this.input.selectionDirection,
        text: textarea.text.substring(selectionEnd, selectionStart),
        paragraphIndexes: generateNumbersArray(startParagraphIndex, endParagraphIndex),
      };
    } else {
      return undefined;
    }
  }

  async doAsync(data: TextToolData) {
    switch (data.mode) {
      case TextToolMode.Creating: {
        const user = this.model.user;
        const newLayer = layerFromState(data.layerData);

        user.history.pushAddLayer(data.layerData, data.layerIndex);

        addLayerToDrawing(this.editor, newLayer, data.layerIndex);
        newLayer.owner = user;
        break;
      }
      case TextToolMode.Rasterizing: {
        const layer = getLayer(this.editor.drawing, data.layerData.id) as (TextLayer | undefined);
        if (!layer) throw new Error('Tried rasterizing not existing layer.');
        layer.textData.dirty = true;
        finishTransform(this.editor, this.model.user, 'TextTool:doAsync:Rasterizing');
        this.globalHistory.pushLayerState(layer.id);

        if (!data.rasterizedFrom) throw new Error('Missing state from before rasterization.');
        setLayerState(layer, data.rasterizedFrom);

        if (!canDrawTextLayer(layer)) {
          await this.dispatchAsyncRasterization(layer);
        } else {
          this.rasterizeRemote(layer);
        }
        break;
      }
      default: {
        const layer = getLayer(this.editor.drawing, data.layerData.id);
        if (!isLayerOk(layer, this.editor.drawing)) {
          if (data.mode === TextToolMode.Focusing) {
            return; // not an action, can be sent when exiting layer that gets removed
          } else {
            throw new Error('[text-tool] Attempting to update text layer data but the layer is not existent!');
          }
        }
        if (data.history) {
          finishTransform(this.editor, this.model.user, 'TextTool:doAsync:default');
          this.globalHistory.pushLayerState(layer.id);
        }
        setLayerState(layer, data.layerData);
        redrawLayer(this.editor, layer);
        break;
      }
    }
  }

  rect = createRect(0, 0, 0, 0);

  originalRect = createRect(0, 0, 0, 0); // saved on start used for resizing/moving
  originalTextureRect = createRect(0, 0, 0, 0);
  resizingControlPoint: TextareaControlPoint | undefined = undefined;

  dragged = false;

  private selectDoubleIndexStart: number | undefined = undefined;
  private selectDoubleIndexEnd: number | undefined = undefined;

  private get readyForActions() {
    return isLayerOk(this.layer, this.editor.drawing) && isTextareaOk(this.textarea);
  }

  private get isCreating() {
    return !this.readyForActions || this.mode === TextToolMode.Creating;
  }

  protected checkIfInitialised() {
    if (!this.initialised) {
      throw new Error('Text tool was not initialised yet or something went wrong.');
    }
  }

  private startPoint = createPoint(0, 0);
  private startPointTransformed = createPoint(0, 0);
  private endPoint = createPoint(0, 0);
  private endPointTransformed = createPoint(0, 0);

  private getTransformedPoint(point: Point) {
    setVec2(tempVec2, point.x, point.y);
    if (this.textarea) {
      invertMat2d(tempMat2d, this.textarea.transform);
      transformVec2ByMat2d(tempVec2, tempVec2, tempMat2d);
    }
    return createPoint(tempVec2[0], tempVec2[1]);
  }

  private copyTextareaRect(textarea: Textarea) {
    this.rect.x = textarea.x;
    this.rect.y = textarea.y;
    this.rect.w = textarea.width;
    this.rect.h = textarea.height;
  }
  get isTextLayerLocked() {
    const realModel = (this.editor as Editor).model;
    return (isLayerOk(this.layer, this.editor.drawing) && this.layer.locked)
      || (realModel && realModel.isPresentationMode && !realModel.isPresentationHost && realModel.presentationModeState.participantsUiHidden);
  }
  start(x: number, y: number, _: number, e?: TabletEvent) {
    if (this.toolCreationLock) return;
    setPoint(this.startPoint, x, y);
    copyPoint(this.startPointTransformed, this.getTransformedPoint(this.startPoint));
    this.dragged = false;
    const shiftPressed = !!(e && (e.flags & TabletEventFlags.ShiftKey));

    if (isLayerOk(this.layer, this.editor.drawing) && isTextareaOk(this.textarea) && !this.isTextLayerLocked) {
      copyRect(this.originalRect, this.textarea.rect);
      const insideTextarea = this.textarea.pointInFrame(this.startPoint);
      if (this.focused) {
        this.resizingControlPoint = this.textarea.pointInControlPoint(this.startPoint, this.editor.view);
        if (this.resizingControlPoint) {
          this.startResizing(this.layer as Mandatory<TextLayer, 'textarea'>);
        } else if (insideTextarea) {
          this.startSelecting(this.startPoint, this.startPointTransformed, shiftPressed);
        } else if (!insideTextarea) {
          this.startMoving(this.layer as Mandatory<TextLayer, 'textarea'>);
        }
      } else {
        this.resizingControlPoint = this.textarea.pointInControlPoint(this.startPoint, this.editor.view);
        if (this.resizingControlPoint) {
          this.startResizing(this.layer as Mandatory<TextLayer, 'textarea'>);
        } else if (insideTextarea) {
          this.focused = true;
          this.startSelecting(this.startPoint, this.startPointTransformed, shiftPressed);
        } else {
          this.startCreating();
        }
      }
    } else {
      // not a text layer (or for some weird reason textarea not existing)
      this.startCreating();
    }

    this.move(x, y, 0, e);
  }

  private startCreating() {
    if (isLayerOk(this.layer, this.editor.drawing) && this.input && !this.panelState) {
      this.updatePanelStateFromSelection(this.textSelection);
    }
    finishTransform(this.editor as Editor, this.model.user, 'TextTool:startCreating');
    this.restoreInitialState();
    this.mode = TextToolMode.Creating;
    resetRect(this.rect);
  }

  private startResizing(layer: Mandatory<TextLayer, 'textarea'>) {
    if (layer.textarea.type === TextareaType.AutoWidth) {
      // _width property holds user-defined width, the one user for setting during resizing
      // since auto-width uses not-user-defined (automatic) at almost all times,
      // we want to restore that value to auto-computed before beginning so there is no weird snap from previous state
      layer.textarea['_width'] = layer.textarea.width;
    }
    finishTransform(this.editor as Editor, this.model.user, 'TextTool:startResizing');
    this.mode = TextToolMode.Resizing;
    this.pushNewChangeToHistory(layer, TextToolMode.Resizing);
    layer.textarea.isResizing = true;
    layer.textarea.activeControlPoint = this.resizingControlPoint!;
    layer.textarea.activeControlPoint.lastUsed = Date.now();
    layer.textarea.resizedNegativelyX = false;
    layer.textarea.resizedNegativelyY = false;
  }

  private startMoving(layer: Mandatory<TextLayer, 'textarea'>) {
    this.mode = TextToolMode.Moving;
    this.dragged = false;
    layer.textarea.isMoving = true;
  }

  private startSelecting(clicked: Point, clickedTransformed: Point, shiftPressed: boolean) {
    if (!isTextareaOk(this.textarea) || !this.input) return;
    this.inputPositionAdjust?.();
    this.input.focus();
    this.mode = TextToolMode.Selecting;
    this.selectDoubleIndexStart = pointToDoubledIndex(this.textarea, clickedTransformed);
    this.selectDoubleIndexEnd = this.selectDoubleIndexStart;

    if (!this.multiClickPosition || distance(this.multiClickPosition.x, this.multiClickPosition.y, clicked.x, clicked.y) < CLICK_OR_DRAG_THRESHOLD) {
      if (!this.registeringMultiClick) this.multiClicks = 0;
      this.registeringMultiClick = true;
      this.multiClickPosition = clonePoint(clicked);

      if (this.registeringMultiClickTimeout) clearTimeout(this.registeringMultiClickTimeout);
      this.registeringMultiClickTimeout = setTimeout(() => {
        this.registeringMultiClick = false;
        this.multiClickPosition = undefined;
      }, 500);

      this.multiClicks++;
      this.updateSelecting(shiftPressed);
    } else if (this.multiClickPosition && distance(this.multiClickPosition.x, this.multiClickPosition.y, clicked.x, clicked.y) >= Math.max(CLICK_OR_DRAG_THRESHOLD / 2, 1)) {
      this.multiClicks = 0;
      this.registeringMultiClick = true;
      this.multiClickPosition = clonePoint(clicked);

      if (this.registeringMultiClickTimeout) clearTimeout(this.registeringMultiClickTimeout);
      this.registeringMultiClickTimeout = setTimeout(() => {
        this.registeringMultiClick = false;
        this.multiClickPosition = undefined;
      }, 500);

      this.multiClicks++;
      this.updateSelecting(shiftPressed);
    }
  }

  private multiClicks = 0;
  private multiClickPosition: Point | undefined = undefined;
  private registeringMultiClick = false;
  registeringMultiClickTimeout: NodeJS.Timeout | undefined;

  end(x: number, y: number, _: number, e?: TabletEvent) {
    if (this.toolCreationLock) return;
    this.doMove(x, y, e);

    if (this.isCreating) {
      this.endCreating();
    } else if (isLayerOk(this.layer, this.editor.drawing) && isTextareaOk(this.textarea)) {
      if (!this.textarea.dirty) this.textarea.dirty = true;
      switch (this.mode) {
        case TextToolMode.Resizing:
          this.endResizing(this.layer as Mandatory<TextLayer, 'textarea'>);
          break;
        case TextToolMode.Moving:
          this.endMoving(this.layer as Mandatory<TextLayer, 'textarea'>);
          break;
        case TextToolMode.Selecting:
          this.endSelecting();
          break;
      }
    }
    this.mode = TextToolMode.Typing;
    this.cursor = 'cursor-default';
    this.dragged = false;
    setPoint(this.startPoint, 0, 0);
    setPoint(this.endPoint, 0, 0);
    setPoint(this.startPointTransformed, 0, 0);
    setPoint(this.endPointTransformed, 0, 0);
    setRect(this.originalRect, 0, 0, 0, 0);
  }

  private endCreating() {
    if (this.rect.w > CLICK_OR_DRAG_THRESHOLD) {
      this.textareaType = TextareaType.FixedDimensions;
    } else {
      this.textareaType = TextareaType.AutoWidth;
    }

    const user = this.model.user;
    const editor = this.editor as Editor;

    logAction('[local] text.endCreating');
    editor.drawingInProgress = false;
    let newLayerId = 0;
    this.toolCreationLock = true;
    withNewLayers((this.editor as Editor), 1, () => true, ([id]) => newLayerId = id)
      .then(() => {
        const activeLayer = this.model.user.activeLayer;
        const layerIndex = activeLayer === undefined ? this.editor.drawing.layers.length - 1 : this.editor.drawing.layers.indexOf(activeLayer);
        const placeholder = this.textareaType === TextareaType.AutoWidth ? SHORT_TEXTAREA_PLACEHOLDER : LONG_TEXTAREA_PLACEHOLDER;
        const layerData = this.constructTextLayerData(newLayerId, placeholder);

        const fullTextCharacterFormatting = this.getCharacterFormattingFromPanel();
        if (fullTextCharacterFormatting && !fullTextCharacterFormatting.fontFamily) {
          fullTextCharacterFormatting.fontFamily = this.selectableFontFamilies[0];
        }
        if (fullTextCharacterFormatting) layerData.textData?.characterFormattings.push(fullTextCharacterFormatting);

        const fullTextParagraphFormatting = this.getParagraphFormattingFromPanel();
        if (fullTextParagraphFormatting) layerData.textData?.paragraphFormattings.push(fullTextParagraphFormatting);

        const textareaFormatting = this.getTextareaFormattingFromPanel();
        if (textareaFormatting && layerData.textData) layerData.textData.textareaFormatting = textareaFormatting;

        if (layerData.textData) {
          layerData.textData.textareaFormatting.transform = [1, 0, 0, 1, 0, 0];
          layerData.textData.dirty = false;
        }

        let numberOfTextLayers = 0;
        for (let i = 0; i < this.editor.drawing.layers.length; i++) {
          if (isTextLayer(this.editor.drawing.layers[i])) numberOfTextLayers++;
        }
        layerData.name = `Text layer #${numberOfTextLayers + 1}`;

        const newLayer = layerFromState(layerData) as TextLayer;

        const fonts = getUsedFonts(newLayer);
        if (!fonts.length || !fonts.every(isFontLoaded)) {
          newLayer.fontsLoaded = false;
          loadFontsForLayer(newLayer).finally(() => {
            // TODO: perhaps we want to reset that lock onLayerExit? for now this allows
            //  only queueing one text layer creation at the time
            this.toolCreationLock = false;
          });
        } else {
          newLayer.fontsLoaded = true;
          this.toolCreationLock = false;
        }

        this.topActionHistoryString = '';
        user.history.pushAddLayer(layerData, layerIndex);

        newLayer.owner = user;
        addLayerToDrawing(this.editor, newLayer, layerIndex);
        sendLayerOrder(this.editor);

        const doToolOptions: TextToolData = {
          id: ToolId.Text,
          mode: TextToolMode.Creating,
          layerIndex, layerData,
          history: false,
        };

        if (newLayer.fontsLoaded) {
          doToolOptions.br = createRect(0, 0, 0, 0);
          doToolOptions.ar = textLayerToTextBox(newLayer, 'textureRect');
        }

        this.runDoTool(newLayer, doToolOptions);

        selectLayer(editor, newLayer);

        this.rememberPanelState();
        this.textarea?.syncControlPoints();
        this.input?.select();
        this.forceCommitOnInputEvent = false;
      })
      .catch((e) => {
        // TODO: display help to user because possibly this will fail (layer limits, etc.)
        DEVELOPMENT && console.error(e);
      })
      .finally(() => {
        logAction('[local] text.endCreating (finally)');
        editor.drawingInProgress = false;
      });

    editor.drawingInProgress = true;
  }

  private endResizing(layer: Mandatory<TextLayer, 'textarea'>) {
    if (!this.resizingControlPoint) throw new Error('TextTool resizing prerequisites not present.');
    layer.textarea.activeControlPoint!.active = false;
    layer.textarea.activeControlPoint = undefined;
    layer.textarea.resizedNegativelyX = false;
    layer.textarea.resizedNegativelyY = false;
    switch (layer.textarea.type) {
      case TextareaType.AutoWidth: {
        layer.textarea.x = layer.textarea.leftBorder;
        this.copyTextareaRect(layer.textarea);
        layer.textarea.isResizing = false;
        this.changeTextareaType(TextareaType.FixedWidth, false);
        break;
      }
      case TextareaType.FixedDimensions: this.changeTextareaType(TextareaType.FixedDimensions, false); break;
      case TextareaType.FixedWidth: {
        if (FixedWidthTextarea.controlPointChangesType(this.resizingControlPoint!)) {
          this.changeTextareaType(TextareaType.FixedDimensions, false);
        }
        break;
      }
    }
    layer.textarea.isResizing = false;
    this.resizingControlPoint = undefined;
    this.commitChangesOnRemote(layer, TextToolMode.Resizing);
  }

  endMoving(layer: Mandatory<TextLayer, 'textarea'>) {
    const inside = layer.textarea.pointInFrame(this.startPoint!);
    const blurring = !this.dragged && !inside;
    if (blurring) this.focused = false;
    if (this.input && inside) {
      this.input.setSelectionRange(this.input.selectionStart, this.input.selectionStart);
      this.dragged = false;
    }
    layer.textarea.isMoving = false;
    if (!blurring) {
      this.commitChangesOnRemote(layer, TextToolMode.Moving);
    }
  }

  private endSelecting() {
    const selection = this.textSelection;
    if (selection && this.input) {
      const { start, end, direction } = selection;
      this.input.setSelectionRange(start, end, direction);
    }
    this.textSelection = this.getSelection();
    this.selectDoubleIndexStart = undefined;
    this.selectDoubleIndexEnd = undefined;
    this.trackSelectionChange();
    this.rememberPanelState();
  }

  move(x: number, y: number, _: number, e?: TabletEvent) {
    if (this.toolCreationLock) return;
    this.doMove(x, y, e);
  }
  private doMove(x: number, y: number, e?: TabletEvent) {
    setPoint(this.endPoint, x, y);
    copyPoint(this.endPointTransformed, this.getTransformedPoint(this.endPoint));

    const shiftPressed = !!(e && (e.flags & TabletEventFlags.ShiftKey));
    const startPoint = this.startPoint;

    if (!this.dragged) {
      const startInScreen = clonePoint(startPoint);
      documentToScreenPoint(startInScreen, this.editor.view);

      const endInScreen = clonePoint(this.endPoint);
      documentToScreenPoint(endInScreen, this.editor.view);

      this.dragged = distance(startInScreen.x, startInScreen.y, endInScreen.x, endInScreen.y) > CLICK_OR_DRAG_THRESHOLD;

      if (this.dragged) {
        this.registeringMultiClick = false;
        if (this.mode === TextToolMode.Moving && isLayerOk(this.layer, this.editor.drawing)) {
          // we want to commit to history at this point and not at start because focusing and moving
          // uses the same technique and it is known which one is executed at this point, not in start
          // and committing to history on one client and not doing that on other can cause desyncs,
          // state is not modified yet
          finishTransform(this.editor as Editor, this.model.user, 'TextTool:doMove');
          this.pushNewChangeToHistory(this.layer, TextToolMode.Moving);
        }
      }
    }

    switch (this.mode) {
      case TextToolMode.Creating: {
        this.rect.x = startPoint.x;
        this.rect.y = startPoint.y;
        this.rect.w = x - startPoint.x;
        this.rect.h = y - startPoint.y;
        normalizeRect(this.rect);
        break;
      }
      case TextToolMode.Moving: {
        if (this.dragged) {
          assertTextarea(this.textarea);
          const dx = this.startPointTransformed!.x - this.endPointTransformed!.x;
          const dy = this.startPointTransformed!.y - this.endPointTransformed!.y;
          this.textarea.x = this.originalRect.x - dx - this.textarea.negativeOffset;
          this.textarea.y = this.originalRect.y - dy;
          this.copyTextareaRect(this.textarea);
        }
        break;
      }
      case TextToolMode.Resizing: {
        assertTextareaControlPoint(this.resizingControlPoint);
        assertTextarea(this.textarea);

        this.resizingControlPoint.active = true;

        if (this.dragged) {
          let dx = this.endPointTransformed.x - this.startPointTransformed!.x;
          let dy = this.endPointTransformed.y - this.startPointTransformed!.y;

          if (this.textarea.resizedNegativelyX) dx = this.startPointTransformed!.x - this.endPointTransformed.x;
          if (this.textarea.resizedNegativelyY) dy = this.startPointTransformed!.y - this.endPointTransformed.y;

          const flipped = this.resizingControlPoint.onDrag?.(dx, dy, this.textarea, this.originalRect, this.resizingControlPoint);
          if (flipped) {
            // transformation matrix is changed during flip therefore we need to
            // transform starting point again for dx/dy to be accurate
            this.startPointTransformed = this.getTransformedPoint(this.startPoint!);
          }
          this.copyTextareaRect(this.textarea);
        }
        break;
      }
      case TextToolMode.Selecting: {
        if (this.textarea) {
          const assumedNewEndDoubleIndex = pointToDoubledIndex(this.textarea, this.endPointTransformed);
          if (this.selectDoubleIndexEnd !== assumedNewEndDoubleIndex) {
            this.selectDoubleIndexEnd = assumedNewEndDoubleIndex;
            this.updateSelecting(shiftPressed);
          }
        }
        break;
      }
    }

    this.cursor = this.getCursor(this.mode, this.resizingControlPoint);
    if (this.mode !== TextToolMode.Selecting) this.recomputeTextLocally();
  }

  private updateSelecting(shiftPressed: boolean) {
    if (!(this.textarea && this.input && this.selectDoubleIndexStart !== undefined && this.selectDoubleIndexEnd !== undefined && !!this.textarea.characters.length)) return;

    let start = undefined;
    let end = undefined;

    const doubleIndexDiff = this.selectDoubleIndexEnd - this.selectDoubleIndexStart;
    let direction = TEXT_SELECTION_DIRECTION_NONE;
    if (doubleIndexDiff > 0) direction = TEXT_SELECTION_DIRECTION_FORWARD;
    if (doubleIndexDiff < 0) direction = TEXT_SELECTION_DIRECTION_BACKWARD;

    start = doubleIndexToCharacter(this.textarea, this.selectDoubleIndexStart).index;
    end = doubleIndexToCharacter(this.textarea, this.selectDoubleIndexEnd).index;

    if (start !== undefined && end !== undefined) {
      if (this.textSelection && shiftPressed) {
        direction = this.textSelection.direction;
        if (direction !== TEXT_SELECTION_DIRECTION_BACKWARD) {
          start = this.textSelection.start;
          if (end < start) {
            [start, end] = [end, start];
            direction = TEXT_SELECTION_DIRECTION_BACKWARD;
          }
        } else {
          start = end;
          end = this.textSelection.end;
          if (end < start) {
            [start, end] = [end, start];
            direction = TEXT_SELECTION_DIRECTION_FORWARD;
          }
        }
      } else {
        [start, end] = this.sanitizeMouseSelectionRange(start, end);
        [start, end] = this.extendRangeByMulticlickLevel(start, end);
      }

      this.textarea.lastSelectionChangeSource = 'mouse';
      this.textarea.determineIfCaretAtEOL(direction !== TEXT_SELECTION_DIRECTION_BACKWARD ? end : start, this.selectDoubleIndexEnd);
      if (this.textSelection) {
        this.textSelection.start = start;
        this.textSelection.end = end;
        this.textSelection.length = end - start;
        this.textSelection.direction = direction;
        this.textarea.previousSelection = undefined;
      }
    }
  }
  private sanitizeMouseSelectionRange(start: number, end: number): [number, number] {
    if (this.textarea) {
      if (start > end) [start, end] = [end, start];
      if (
        !this.textarea.viableForSelectionVisually(this.textarea.characters[start]) ||
        !this.textarea.viableForSelectionVisually(this.textarea.characters[end])
      ) {
        let newStart = undefined;
        let newEnd = undefined;
        for (let i = 0; i < Math.ceil((end - start) / 2); i++) {
          const fromStart = this.textarea.characters[start + i];
          const fromEnd = this.textarea.characters[end - i];

          if (newStart === undefined && this.textarea.viableForSelectionVisually(fromStart)) {
            newStart = fromStart.index;
          }

          if (newEnd === undefined && this.textarea.viableForSelectionVisually(fromEnd)) {
            newEnd = fromEnd.index;
          }

          if (newStart !== undefined && newEnd !== undefined) break;
        }

        return [(newStart ?? start), (newEnd ?? end) + 1];
      }
    }
    return [start, end];
  }
  private extendRangeByMulticlickLevel(start: number, end: number): [number, number] {
    if (!this.textarea) return [start, end];

    try {
      if (this.multiClicks === 2) {
        return this.extendRangeByWords(this.textarea, start, end);
      } else if (this.multiClicks === 3) {
        return this.extendRangeByLines(this.textarea, start, end);
      } else if (this.multiClicks === 4) {
        return this.extendRangeByParagraphs(this.textarea, start, end);
      } else if (this.multiClicks > 4) {
        return [0, this.textarea.characters.length - 1]; // select enitre text
      } else {
        return [start, end]; // selection by letters, dont't do anything
      }
    } catch (e) {
      DEVELOPMENT && console.log(e);
      return [start, end]; // fallback to selection by letters
    }
  }
  private extendRangeByWords(textarea: Textarea, start: number, end: number): [number, number] {
    const words = charactersToWordsWithWhitespaces(textarea.characters);
    let newStart = start;
    let newEnd = end;
    let foundStart = false;
    let foundEnd = false;

    wordSearch: for (let i = 0; i < words.length; i++) {
      const word = words[i];
      for (const character of word) {
        if (character.index === start) {
          newStart = word[0].index;
          foundStart = true;
        }
        if (end >= word[0].index && end <= word[word.length - 1].index) {
          newEnd = word[word.length - 1].index + 1;
          foundEnd = true;
        }
        if (foundStart && foundEnd) break wordSearch;
      }
    }

    return [newStart, newEnd];
  }
  private extendRangeByLines(textarea: Textarea, start: number, end: number): [number, number] {
    const newStart = textarea.findLineDataFromCharacter(textarea.characters[start]).startAt;
    const newEnd = textarea.findLineDataFromCharacter(textarea.characters[end]).breaklineAt + 1;
    return [newStart, newEnd];
  }
  private extendRangeByParagraphs(textarea: Textarea, start: number, end: number): [number, number] {
    const newStart = textarea.findParagraphFromCharacter(textarea.characters[start]).characters[0].index;
    const endParagraph = textarea.findParagraphFromCharacter(textarea.characters[end]);
    const newEnd = endParagraph.characters[endParagraph.characters.length - 1].index + 1;
    return [newStart, newEnd];
  }

  private applyGlobalCharacterFormatting(formatting: CharacterFormatting, focusInput = true, preventRemoteSync = false) {
    if (this.textarea) {
      this.applyCharacterFormatting(formatting, { start: 0, length: this.textarea.characters.length }, focusInput, preventRemoteSync);
    }
  }
  private applyCharacterFormattingToSelection(formatting: CharacterFormatting, focusInput = true, preventRemoteSync = false) {
    if (this.textSelection) {
      this.applyCharacterFormatting(formatting, this.textSelection, focusInput, preventRemoteSync);
    }
  }
  selectionToString() {
    const selection = this.textSelection;
    if (selection) {
      const { start, end, direction } = selection;
      return `(${start},${end},${direction})`;
    } else {
      return '';
    }
  }
  private syncingRemoteLock = false;
  private pushingHistoryLock = false;
  lockSlidingFormatting(formattedPropertyName: string) {
    if (isLayerOk(this.layer, this.editor.drawing)) {
      this.syncingRemoteLock = true;
      this.pushingHistoryLock = true;
      if (!this.focused || this.hasGlyphsInSelection) {
        // else it would just remember state to apply on next character which is not what we want to add as history entry until they are added
        this.pushNewChangeToHistory(this.layer, `${TextToolMode.Formatting}-c-${formattedPropertyName}${this.selectionToString()}`);
      }
    }
  }
  releaseSlidingFormatting(andDoWhat: () => void) {
    this.syncingRemoteLock = false;
    andDoWhat();
    this.pushingHistoryLock = false;
  }
  private applyCharacterFormatting(formatting: CharacterFormatting, range: TextRange, focusInput = true, preventRemoteSync = false) {
    preventRemoteSync;
    if (isLayerOk(this.layer, this.editor.drawing) && isTextareaOk(this.textarea) && range) {
      const { start, length } = range;

      if (!this.pushingHistoryLock) {
        this.pushNewChangeToHistory(this.layer, `${TextToolMode.Formatting}-c-${getAppliedFormattingName(formatting)}${this.selectionToString()}`);
      }

      this.textarea.formatCharacters({ ...formatting, start, length }, false);
      this.recomputeTextLocally();

      if (!this.syncingRemoteLock) {
        this.commitChangesOnRemote(this.layer, TextToolMode.Formatting);
      }
    }
    if (focusInput) this.input?.focus();
  }
  private applyParagraphFormatting(formatting: ParagraphFormatting) {
    if (isLayerOk(this.layer, this.editor.drawing) && isTextareaOk(this.textarea) && this.textSelection) {
      this.pushNewChangeToHistory(this.layer, `${TextToolMode.Formatting}-p-${getAppliedFormattingName(formatting)}${this.selectionToString()}`);
      for (let paragraphIndex of this.textSelection.paragraphIndexes) {
        this.textarea.formatParagraph({ ...formatting, index: paragraphIndex }, false);
      }
      this.commitChangesOnRemote(this.layer, TextToolMode.Formatting);
      this.focused = this.focused;
    }
  }
  private applyTextareaFormatting(formatting: TextareaFormatting) {
    if (isLayerOk(this.layer, this.editor.drawing) && isTextareaOk(this.textarea)) {
      this.pushNewChangeToHistory(this.layer, `${TextToolMode.Formatting}-t-${getAppliedFormattingName(formatting)}`);
      this.textarea.formatTextarea(formatting, false);
      this.commitChangesOnRemote(this.layer, TextToolMode.Formatting);
      this.focused = this.focused;
    }
  }

  // this function gets current state of textarea and confirms it in serializable data in layer (layer.textData)
  // recomputes all necesary recomputements and returns doTool options which
  // can be used to request synchronization at remote clients
  recomputeTextLocally(mode: TextToolMode = this.mode) {
    if (!isLayerOk(this.layer, this.editor.drawing) || !canDrawTextLayer(this.layer)) return undefined;
    const layer = this.layer;
    const textarea = layer.textarea;

    const br = cloneRect(this.originalTextureRect);
    clipToDrawingRect(br, this.editor.drawing);
    normalizeRect(this.rect);

    textarea.previousSelection = undefined;
    textarea.write(textarea.text);
    textarea.syncControlPoints();

    const layerIndex = this.editor.drawing.layers.indexOf(layer);
    const layerData = this.constructTextLayerData(layer.id);

    if (this.layer && !this.layer.textData.dirty && (layerData.textData && layerData.textData.dirty)) this.layer.name = '';
    (layer as Layer).textData = layerData.textData; // cast is needed because possibly we want to convert this back to raster layer
    layer.invalidateCanvas = true;

    redrawLayer(this.editor, layer);

    const ar = textLayerToTextBox(layer, 'textureRect');
    clipToDrawingRect(ar, this.editor.drawing);

    const doToolOptions: TextToolData = {
      id: ToolId.Text, mode,
      layerIndex, layerData,
      br: mode !== TextToolMode.Focusing ? br : undefined,
      ar: mode !== TextToolMode.Focusing ? ar : undefined,
      history: true,
      replace: mode === TextToolMode.Focusing,
      preventClearingUndos: mode === TextToolMode.Focusing,
      preventHistory: mode === TextToolMode.Focusing,
    };

    return doToolOptions;
  }

  hover(x: number, y: number, e?: TabletEvent) {
    if (e && hasCtrlOrMetaKey(e)) {
      const layer = pickLayerFromEditor(this.editor, x, y, true, true);
      if (layer && layer !== this.model.user.activeLayer) {
        this.cursor = 'cursor-pointer';
      } else {
        this.cursor = 'cursor-default';
      }
      return;
    }

    if (this.toolCreationLock) {
      this.cursor = 'cursor-loading';
      return;
    }
    const hovered = createPoint(x, y);

    const textarea = this.textarea;
    if (isTextareaOk(textarea) && !this.isTextLayerLocked) {

      const insideTextarea = textarea.pointInFrame(hovered);
      const hoveredControlPoint = textarea.pointInControlPoint(hovered, this.editor.view);

      textarea.syncControlPoints();
      if (!textarea.activeControlPoint) {
        textarea.controlPoints.sort((a, b) => {
          const aDistance = distance(x, y, a.x, a.y);
          const bDistance = distance(x, y, b.x, b.y);
          if (bDistance - aDistance !== 0) {
            return bDistance - aDistance;
          } else {
            return b.lastUsed - a.lastUsed;
          }
        });
      }
      textarea.isHovering = !!hoveredControlPoint || textarea.pointInFrame(hovered);

      if (this.focused) {
        if (hoveredControlPoint) {
          this.cursor = this.getCursor(TextToolMode.Resizing, hoveredControlPoint);
        } else if (insideTextarea) {
          this.cursor = 'cursor-text';
        } else {
          this.cursor = 'cursor-move';
        }
      } else {
        if (hoveredControlPoint) {
          this.cursor = this.getCursor(TextToolMode.Resizing, hoveredControlPoint);
        } else if (insideTextarea) {
          this.cursor = 'cursor-text';
        } else {
          this.cursor = 'cursor-selection-add';
        }
      }
    } else {
      const realModel = (this.editor as Editor).model;
      if (realModel.isPresentationMode && !realModel.isPresentationHost && realModel.presentationModeState.participantsUiHidden) {
        this.cursor = 'cursor-default';
      } else {
        this.cursor = 'cursor-selection-add';
      }
    }

    redrawLayer(this.editor, this.layer);
  }

  private getCursor(action: TextToolMode, extra?: any) {
    if (action === TextToolMode.Selecting) {
      return 'cursor-text';
    } else if (action === TextToolMode.Resizing) {
      return this.getResizeCursor(extra);
    } else if (action === TextToolMode.Moving) {
      return 'cursor-move';
    } else {
      return 'cursor-default';
    }
  }

  private getResizeCursor(controlPoint: TextareaControlPoint) {
    const textarea = this.textarea;

    let flips = 0;
    if (textarea && textarea.transform[0] < 0) flips++;
    if (textarea && textarea.transform[3] < 0) flips++;
    if (this.editor.view.flipped) flips++;

    switch (controlPoint.direction) {
      case TextareaControlPointDirections.North:
      case TextareaControlPointDirections.South:
        return 'cursor-resize-v';
      case TextareaControlPointDirections.East:
      case TextareaControlPointDirections.West:
        return 'cursor-resize-h';
      case TextareaControlPointDirections.NorthWest:
      case TextareaControlPointDirections.SouthEast:
        return isOdd(flips) ? 'cursor-resize-tr' : 'cursor-resize-tl';
      case TextareaControlPointDirections.NorthEast:
      case TextareaControlPointDirections.SouthWest:
        return isOdd(flips) ? 'cursor-resize-tl' : 'cursor-resize-tr';
      default:
        return 'cursor-move';
    }
  }

  private constructTextLayerData(layerId: number, text?: string): LayerData {
    integerizeRect(this.rect);
    const options: TextareaOptions = {
      type: this.textareaType,
      x: this.rect.x,
      y: this.rect.y,
      text: text ?? this.textarea?.text ?? '',
      textareaFormatting: {
        ...DEFAULT_TEXTAREA_FORMATTING,
        ...this.textarea?.textareaFormatting,
      },
      paragraphFormattings: this.textarea?.paragraphFormattings || [],
      characterFormattings: this.textarea?.characterFormattings || [],
      defaultFontFamily: this.textarea?.defaultFontFamily || this.fontFamily || (this.availableFontFamilies.length ? this.availableFontFamilies[0].name : this.selectableFontFamilies[0]),
      dirty: this.textarea?.dirty || false,
      isFocused: this.focused,
    };

    if (isBoxTextareaType(this.textareaType)) {
      options.w = this.rect.w;
      options.h = this.rect.h;
    }

    let name = '';
    if (options.dirty) {
      name = this.layer?.name || text || '';
    }

    return {
      id: layerId,
      name,
      textData: options
    };
  }

  private getFormattingPropertyFromPanel(property: (keyof TextTool), defaultsObject: any) {
    const value = this[property];
    if (value === undefined) return null; // mixed
    if (defaultsObject[property] !== undefined && value === defaultsObject[property]) return undefined;
    if (value === AUTO_SETTING_STRING) return undefined;
    return value;
  }
  private addPropertyFromPanelToFormatting<T extends AnyLevelTextFormattingDescription>(formatting: T, property: (keyof T & keyof TextTool), defaultsObject: any) {
    const value = this.getFormattingPropertyFromPanel(property, defaultsObject);
    if (value) formatting[property] = value;
  }
  private getCharacterFormattingFromPanel() {
    const entireTextFormatting: CharacterFormattingDescription = { start: 0, length: LONG_TEXTAREA_PLACEHOLDER.length };

    const activeColor = (this.editor as Editor).activeColor;
    if (activeColor) entireTextFormatting.color = colorToCSS(activeColor);

    this.addPropertyFromPanelToFormatting(entireTextFormatting, 'baselineShift', DEFAULT_CHARACTER_FORMATTING);
    this.addPropertyFromPanelToFormatting(entireTextFormatting, 'lineheight', DEFAULT_CHARACTER_FORMATTING);
    this.addPropertyFromPanelToFormatting(entireTextFormatting, 'letterSpacing', DEFAULT_CHARACTER_FORMATTING);
    this.addPropertyFromPanelToFormatting(entireTextFormatting, 'bold', DEFAULT_CHARACTER_FORMATTING);
    this.addPropertyFromPanelToFormatting(entireTextFormatting, 'italic', DEFAULT_CHARACTER_FORMATTING);
    this.addPropertyFromPanelToFormatting(entireTextFormatting, 'underline', DEFAULT_CHARACTER_FORMATTING);
    this.addPropertyFromPanelToFormatting(entireTextFormatting, 'strikethrough', DEFAULT_CHARACTER_FORMATTING);
    this.addPropertyFromPanelToFormatting(entireTextFormatting, 'scaleX', DEFAULT_CHARACTER_FORMATTING);
    this.addPropertyFromPanelToFormatting(entireTextFormatting, 'scaleY', DEFAULT_CHARACTER_FORMATTING);
    this.addPropertyFromPanelToFormatting(entireTextFormatting, 'textCase', DEFAULT_CHARACTER_FORMATTING);

    if (this.fontFamily) entireTextFormatting.fontFamily = this.fontFamily;

    if (this.fontSize !== undefined && this.fontSize !== AUTO_SETTING_STRING && this.fontSize !== DEFAULT_CHARACTER_FORMATTING.size) {
      // font size is handled manually because it has different keys / types (ITool.size) in TextTool and CharacterFormatting
      entireTextFormatting.size = this.fontSize;
    }

    return isValidCharacterFormatting(entireTextFormatting) ? entireTextFormatting : undefined;
  }
  private getParagraphFormattingFromPanel() {
    const entireTextFormatting: ParagraphFormattingDescription = { index: 0 };
    this.addPropertyFromPanelToFormatting(entireTextFormatting, 'alignment', DEFAULT_PARAGRAPH_FORMATTING);
    return isValidParagraphFormatting(entireTextFormatting) ? entireTextFormatting : undefined;
  }
  private getTextareaFormattingFromPanel() {
    const textareaFormatting: TextareaFormatting = {};
    this.addPropertyFromPanelToFormatting(textareaFormatting, 'verticalAlignment', DEFAULT_TEXTAREA_FORMATTING);
    return Object.keys(textareaFormatting).length > 0 ? textareaFormatting : undefined;
  }
  private toolCreationLock = false;

  private dispatchAsyncRasterization(layer: TextLayer) {
    return loadFontsForLayer(layer).then(() => {
      layer.fontsLoaded = hasFontsLoaded(layer);
      if (canDrawTextLayer(layer)) {
        this.rasterizeRemote(layer);
      } else {
        throw new Error('Failed on (async) rasterizing remote text layer.');
      }
    });
  }
  rasterizeRemote(layer: TextLayer) {
    if (!canDrawTextLayer(layer)) throw new Error('Failed on (sync) rasterizing remote text layer.');
    layer.textData.dirty = true;
    this.rasterizeInternal(layer);
  }
  rasterizeLocal(layer: TextLayer) {
    if (!canDrawTextLayer(layer)) return;
    layer.textData.dirty = true;

    finishTransform(this.editor, this.model.user, 'rasterizeLocal');
    this.globalHistory.pushLayerState(layer.id);

    const rasterizedFrom = toLayerState(layer);
    const ar = this.rasterizeInternal(layer);

    const doToolOptions: TextToolData = {
      id: ToolId.Text,
      mode: TextToolMode.Rasterizing,
      layerIndex: 0,
      layerData: { id: layer.id, },
      history: false,
      br: undefined, // we don't want to compare before rects because worker may skip few states
      ar, rasterizedFrom,
    };

    this.onLayerChange(layer);
    this.runDoTool(layer, doToolOptions);
  }

  rasterizeInternal(layer: TextLayer) {
    layer.invalidateCanvas = true;
    this.editor.renderer.drawTextLayer(layer, this.editor.drawing);
    layer.name = getLayerName(layer);
    (layer as Layer).textData = undefined;
    (layer as TextLayer).textarea = undefined;

    const image = this.editor.renderer.getLayerImageData(layer);
    const bitmap = imageDataToBitmapData(image);
    const croppedRect = trimImage(bitmap);
    this.editor.renderer.trimLayer(layer, croppedRect);

    normalizeRect(this.rect);
    redrawDrawing(this.editor);

    return cloneRect(croppedRect);
  }

  restoreInitialState() {
    this.layer = undefined;
    if (this.input) this.input.remove();
    this.input = undefined;
    this.topActionHistoryString = '';
    this.lastInputEvent = 0;
    this.forceCommitOnInputEvent = true;
    this.panelState = undefined;
    this.mode = TextToolMode.Typing;
    this.activeColorEmpty = false;
    if (!SERVER) this.focused = false;
  }

  get selectedEntireTextOrCaretAtStart() {
    if (!this.textSelection || !this.textarea) return false;
    const { start, end } = this.textSelection;
    const characters = this.textarea.characters;
    return (start <= 0 && end >= characters.length - 1) || start === 0;
  }

  lastInputEvent = 0;
  forceCommitOnInputEvent = false;
  deferredChangeTimeout: NodeJS.Timeout | undefined = undefined;
  deferredChangeFn: Function | undefined = undefined;
  private scheduleDeferredChange(fn: Function) {
    if (this.deferredChangeTimeout) this.clearDeferredChange();
    this.deferredChangeFn = fn;
    this.deferredChangeTimeout = setTimeout(() => {
      this.confirmDeferredChange();
    }, TYPING_IDLE_DEBOUNCE);
  }
  private clearDeferredChange() {
    if (this.deferredChangeTimeout) {
      clearTimeout(this.deferredChangeTimeout);
      this.deferredChangeTimeout = undefined;
      this.lastInputEvent = 0;
      this.deferredChangeFn = undefined;
    }
  }
  confirmDeferredChange() {
    this.deferredChangeFn?.();
    this.clearDeferredChange();
  }
  private recreateInput() {
    if (!this.layer) throw new Error('[text-tool] No active layer!');
    this.input = document.createElement('textarea');
    this.input.className = TEXT_TOOL_ARTIFICIAL_TEXTAREA_CLASS;
    this.input.style.position = 'absolute';
    this.input.style.width = `${ARTIFICIAL_TEXTAREA_SIZE}px`;
    this.input.style.height = `${ARTIFICIAL_TEXTAREA_SIZE}px`;
    this.input.style.whiteSpace = 'pre'; // this is very important, it causes whitespaces to be preserved in original form when ingesting into textarea object instead of collapsing them together and forming "\n" characters
    setInputPositionOnPage(this.input, this.editor.view, this.layer.textData.x, this.layer.textData.y);
    this.input.style.opacity = '0';
    this.input.style.overflow = 'hidden';
    this.input.style.fontSize = '1px';
    this.input.value = this.layer.textData.text;
    if (DEVELOPMENT && SHOW_ARTIFICIAL_TEXTAREA) {
      this.input.style.zIndex = '9999';
      this.input.style.opacity = '100%';
    }
    document.body.appendChild(this.input);

    this.input.addEventListener('compositionstart', (e) => {
      const input = (e.target as HTMLTextAreaElement);
      this.beforeCompositionSelection[0] = input.selectionStart;
      this.beforeCompositionSelection[1] = input.selectionEnd;
      this.beforeCompositionSelection[2] = input.selectionDirection;
      this.composeInputEvents = true;
    });

    this.input.addEventListener('compositionend', (e) => {
      const input = (e.target as HTMLTextAreaElement);
      input.value = sanitizeEmojis(input.value);
      this.composeInputEvents = false;
      const insertedCharacters = sanitizeEmojis(e.data);
      let [start, end, direction] = this.beforeCompositionSelection;
      start += insertedCharacters.length;
      end = start;
      input.setSelectionRange(start, end, direction);
      this.inputHandler({ preventDefault() { }, target: this.input } as any);
    });

    this.input.addEventListener('input', (e) => {
      if (!this.composeInputEvents) {
        this.inputHandler(e);
      }
    });

    if (!clipboardSupported()) {
      this.input.addEventListener('copy', (e) => {
        if (!this.textarea || !this.textSelection) throw new Error('Can\'t copy text from textarea. No textarea or selection.');
        e.preventDefault();
        const successfullyCopied = copyTextFromTextareaViaClipboardEvent(e, this.textarea, this.textSelection);
        if (!successfullyCopied) {
          displayToastAboutNotSupportedClipboard(this.editor, 'Copying');
        }
      });
    }

    if (!clipboardSupported()) {
      this.input.addEventListener('cut', (e) => {
        if (!isLayerOk(this.layer, this.editor.drawing) || !this.textarea || !this.textSelection || !this.input) throw new Error('Can\'t cut text from textarea.');
        e.preventDefault();
        const successfullyCut = cutTextFromTextareaViaClipboardEvent(e, this.textarea, this.textSelection);
        if (successfullyCut) {
          this.pushNewChangeToHistory(this.layer, TextToolMode.Typing);
          this.commitChangesOnRemote(this.layer, TextToolMode.Typing);
        } else {
          displayToastAboutNotSupportedClipboard(this.editor, 'Cutting');
        }
      });
    }

    if (!clipboardSupported()) {
      this.input.addEventListener('paste', (e) => {
        if (!isLayerOk(this.layer, this.editor.drawing) || !this.textarea || !this.textSelection || !this.input) throw new Error('Can\'t paste text into textarea.');
        e.preventDefault();
        if (this.selectedEntireTextOrCaretAtStart) this.rememberPanelState();
        const successfullyPasted = pasteIntoTextareaViaClipboardEvent(e, this.textarea, this.textSelection, this.pastingWithoutStylesOnFirefox, this.panelState);
        if (successfullyPasted) {
          this.pushNewChangeToHistory(this.layer, TextToolMode.Typing);
          this.commitChangesOnRemote(this.layer, TextToolMode.Typing);
        } else {
          displayToastAboutNotSupportedClipboard(this.editor, 'Pasting');
        }
        this.pastingWithoutStylesOnFirefox = false;
      });
    }

    this.input.addEventListener('keydown', this.keydownHandler);

    this.input.focus();
  }
  pastingWithoutStylesOnFirefox = false;
  keydownHandler = (e: KeyboardEvent) => {
    if (!this.input || !this.textarea || !isLayerOk(this.layer, this.editor.drawing)) return;

    const { keyCode, ctrlKey, metaKey, shiftKey } = e;
    const ctrlOrMeta = ctrlKey || metaKey;

    if (e.target !== this.input) {
      // we were text layer but on different tool, captured event propagated to body
      // and now replaying to invoke default behavior on proper target (textarea input)
      const duplicatedEvent = new (e.constructor as { new(...args: any[]): Event })(e.type, e);
      Object.defineProperty(duplicatedEvent, 'target', { writable: true });
      (duplicatedEvent as Mutable<Event>).target = this.input;
      e.stopPropagation();
      this.input.dispatchEvent(duplicatedEvent);
    } else if (!this.isUsingTextTool) {
      // we're on text layer but using some different tools, let's not capture events then and
      // forward them to keyboard service under all conditions
      (e as MagmaKeyboardEvent).allowKeyboardService = true;
    } else if (this.isTextLayerLocked) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      switch (keyCode) {
        case Key.Backspace:
        case Key.Delete:
        case Key.A: {
          if (this.focused) {
            e.stopPropagation();
          }
          break;
        }
        case Key.Tab: {
          if (this.focused) {
            e.stopPropagation();
            this.handleTabKey(e, this.textarea);
          }
          break;
        }
        case Key.Z:
        case Key.Y: {
          if (ctrlOrMeta) {
            (e as MagmaKeyboardEvent).allowKeyboardService = true;
          }
          break;
        }
        case Key.Up: {
          if (this.focused) {
            e.stopPropagation();
            e.preventDefault();
            this.textarea.blinkOffset = performance.now();
            handleArrowUpVerticalSelection(e, this.textarea, this.input);
          }
          break;
        }
        case Key.Down: {
          if (this.focused) {
            e.stopPropagation();
            e.preventDefault();
            this.textarea.blinkOffset = performance.now();
            handleArrowDownVerticalSelection(e, this.textarea, this.input);
          }
          break;
        }
        case Key.Home: {
          if (this.focused) {
            e.stopPropagation();
            e.preventDefault();
            this.textarea.blinkOffset = performance.now();
            this.textarea.verticallyNavigatingDoubleIndex = undefined;
            handleHomeVerticalSelection(e, this.textarea, this.input);
          }
          break;
        }
        case Key.End: {
          if (this.focused) {
            e.stopPropagation();
            e.preventDefault();
            this.textarea.blinkOffset = performance.now();
            this.textarea.verticallyNavigatingDoubleIndex = undefined;
            handleEndVerticalSelection(e, this.textarea, this.input);
          }
          break;
        }
        case Key.Esc: {
          if (this.focused) {
            e.stopPropagation();
            e.preventDefault();
            this.focused = false;
          }
          break;
        }
        case Key.Right:
        case Key.Left: {
          if (this.focused) {
            e.stopPropagation();
            this.handleHorizontalArrows(this.textarea);
          }
          break;
        }
        case Key.PageUp:
        case Key.PageDown: {
          if (this.focused) {
            e.stopPropagation();
            e.preventDefault();
          }
          break;
        }
        case Key.Space:
        case Key.Enter: {
          if (this.focused) {
            e.stopPropagation();
            this.forceCommitOnInputEvent = true;
          }
          break;
        }
        case Key.B: {
          if (ctrlOrMeta) {
            e.stopPropagation();
            e.preventDefault();
            this.toggleBold();
            this.editor.apply(() => { });
          }
          break;
        }
        case Key.I: {
          if (ctrlOrMeta) {
            e.stopPropagation();
            e.preventDefault();
            this.toggleItalic();
            this.editor.apply(() => { });
          }
          break;
        }
        case Key.U: {
          if (ctrlOrMeta) {
            e.stopPropagation();
            e.preventDefault();
            this.toggleUnderline();
            this.editor.apply(() => { });
          }
          break;
        }
        case Key.C: {
          if (clipboardSupported() && ctrlOrMeta && this.focused) {
            e.stopPropagation();
            e.preventDefault();
            this.copy().catch((e) => {
              DEVELOPMENT && console.warn('Couldn\'t copy textarea text.', e);
            });
          }
          break;
        }
        case Key.X: {
          if (clipboardSupported() && ctrlOrMeta && this.focused && isLayerOk(this.layer, this.editor.drawing)) {
            e.stopPropagation();
            e.preventDefault();
            this.cut()?.catch((e) => {
              DEVELOPMENT && console.warn('Couldn\'t cut textarea text!.', e);
            });
          }
          break;
        }
        case Key.V: {
          if (!clipboardSupported() && ctrlOrMeta && this.focused && shiftKey) {
            this.pastingWithoutStylesOnFirefox = true;
          }
          if (clipboardSupported() && ctrlOrMeta && this.focused) {
            e.stopPropagation();
            e.preventDefault();
            this.paste(shiftKey).catch((e) => {
              DEVELOPMENT && console.warn('Couldn\'t paste text in textarea.', e);
            });
          }
          break;
        }
        default: {
          if (this.focused && ctrlOrMeta) {
            // if we're unfocused every event is picked up because of input having 'ks-allow' class
            // if focused we want to make sure we don't handle key in explicit way in one of cases above,
            // if none and ctrl is pressed we want to allow propagation so keyboard service picks it up
            // and attach this custom property to make it pass validation (so it doesn't get ignored because of being event from input)
            (e as MagmaKeyboardEvent).allowKeyboardService = true;
          }
          break;
        }
      }
    }
  };
  beforeCompositionSelection: TextSelection = [0, 0, TEXT_SELECTION_DIRECTION_NONE];
  composeInputEvents = false;
  typingPreundo: IUndoFunction | undefined = undefined;
  inputHandler = (e: Event) => {
    e.preventDefault();
    const input = (e!.target as HTMLTextAreaElement);
    const layer = this.layer;
    if (isLayerOk(layer, this.editor.drawing) && this.focused && this.textarea && this.textSelection && !this.isTextLayerLocked) {
      finishTransform(this.editor as Editor, this.model.user, 'TextTool:inputHandler');

      const debounce = (performance.now() > (this.lastInputEvent + TYPING_IDLE_DEBOUNCE)) && this.lastInputEvent !== 0;
      const synchronizeNow = (debounce || this.forceCommitOnInputEvent);

      if (!this.typingPreundo) {
        copyRect(this.originalTextureRect, this.textarea.textureRect);
        this.typingPreundo = this.globalHistory.createLayerState(layer.id);
      }

      if (synchronizeNow && this.typingPreundo) {
        this.globalHistory.pushUndo(this.typingPreundo);
        this.topActionHistoryString = TextToolMode.Typing;
        this.typingPreundo = undefined;
      }

      if (this.selectedEntireTextOrCaretAtStart) this.rememberPanelState();
      this.textarea.onInput(input.value, input, this.textSelection, this.panelState);
      if (this.textarea.formattingToApplyOnNextInput) this.updatePanelState([this.textarea.formattingToApplyOnNextInput]);
      if (this.panelState) this.panelState = undefined;

      const opts = this.recomputeTextLocally(TextToolMode.Typing);
      if (opts && synchronizeNow) {
        this.runDoTool(layer, opts);
      } else if (opts) {
        this.scheduleDeferredChange(() => {
          logAction('[local] text tool deferred change');
          if (this.typingPreundo) this.globalHistory.pushUndo(this.typingPreundo);
          this.topActionHistoryString = TextToolMode.Typing;
          this.model.doTool(layer.id, opts);
          this.lastInputEvent = 0;
          this.typingPreundo = undefined;
        });
      }

      this.lastInputEvent = performance.now();
      this.forceCommitOnInputEvent = false;
      this.textSelection = this.getSelection();
    } else if (this.textarea) {
      const { selectionStart, selectionEnd } = input;
      input.value = this.textarea.text;
      input.setSelectionRange(selectionStart, selectionEnd);
    }
  };
  private handleTabKey(e: KeyboardEvent, textarea: Textarea) {
    if (e.shiftKey) {
      e.preventDefault();
      this.applyTextareaFormatting({ displayNonPrintableCharacters: !textarea.textareaFormatting.displayNonPrintableCharacters });
      DEVELOPMENT && console.log('TOGGLED VISIBILITY OF NON-PRINTABLE-CHARACTERS now is:', textarea.textareaFormatting.displayNonPrintableCharacters);
    } else {
      const selection = this.textSelection;
      if (selection && isLayerOk(this.layer, this.editor.drawing)) {
        e.preventDefault();
        this.pushNewChangeToHistory(this.layer, TextToolMode.Typing);
        insertCharactersIntoTextarea(textarea, selection, e.target as HTMLTextAreaElement, '\t', this.panelState);
        this.commitChangesOnRemote(this.layer, TextToolMode.Typing);
      }
    }
  }
  private handleHorizontalArrows(textarea: Textarea) {
    textarea.lastSelectionChangeSource = 'arrows-h';
    textarea.blinkOffset = performance.now();
    textarea.caretAtEOL = false;
  }

  // changing active layer:
  shouldAutoDelete(layer: Layer | undefined) {
    return layer && isLayerOk(layer, this.editor.drawing) && (layer.textData.text === '' || (layer.textarea && !layer.textarea.dirty));
  }
  denyLayerAutoDelete(layer: Layer | undefined) {
    if (this.layer === layer && this.shouldAutoDelete(layer)) {
      this.layer = undefined;
    }
  }
  onLayerExit(editor: Editor) {
    this.scheduledLayer = undefined;
    this.confirmDeferredChange();
    if (!SERVER) this.focused = false;
    if (this.shouldAutoDelete(this.layer)) {
      DEVELOPMENT && console.log('TextTool removed non-dirty text layer', toLayerState(this.layer!));
      removeLayer(editor, this.layer);
    }
    this.restoreInitialState();
  }
  onLayerEnter(layer: Layer | undefined) {
    this.restoreInitialState();
    if (isLayerOk(layer, this.editor.drawing) && layer.fontsLoaded) {
      this.layer = layer;
      this.loadTextLayerProperties(layer.textData);
      this.focused = true;
      this.recomputeTextLocally();
      if (!layer.textData.dirty) this.selectEntireText();
      this.forceCommitOnInputEvent = true;
      this.trackSelectionChange();
      this.rememberPanelState();
    } else if (isLayerOk(layer, this.editor.drawing)) {
      this.scheduleOnLayerChange(layer);
    } else {
      this.mode = TextToolMode.Creating;
    }
  }
  onLayerChange(layer: Layer | undefined) {
    this.onLayerExit(this.editor as Editor);
    this.onLayerEnter(layer);
  }
  scheduledLayer: TextLayer | undefined = undefined;
  scheduleOnLayerChange(layer: TextLayer) {
    this.scheduledLayer = layer;
    loadFontsForLayer(layer).then(() => {
      if (isLayerOk(this.scheduledLayer, this.editor.drawing) && this.model.user.activeLayer === this.scheduledLayer) {
        this.onLayerChange(this.scheduledLayer);
      }
    }).catch(e => DEVELOPMENT && console.log(e));
  }

  // editor-sliders component bindable-data:
  fontFamily = '';
  fontStyle: FontStyleNames | '' = '';
  fontSize: AutoOr<number> | undefined = DEFAULT_CHARACTER_FORMATTING.size;
  lineheight: AutoOr<number> | undefined = AUTO_SETTING_STRING;
  letterSpacing: number | undefined = DEFAULT_CHARACTER_FORMATTING.letterSpacing;
  baselineShift: number | undefined = DEFAULT_CHARACTER_FORMATTING.baselineShift;
  bold = false;
  italic = false;
  underline = false;
  strikethrough = false;
  strokeWidth: number | undefined = 0;
  fillOpacity = 255;

  get fillOpacityInPercents() {
    return Math.floor(this.fillOpacity / 255 * 100);
  }

  alignment: TextAlignment | undefined = DEFAULT_PARAGRAPH_FORMATTING.alignment;
  textareaType = TextareaType.AutoWidth;
  verticalAlignment = VerticalAlignments.Top;
  scaleX: number | undefined = 1;
  scaleY: number | undefined = 1;
  textCase: TextCases | undefined = TextCases.NoCaseModification;

  get disablePanel() {
    const editorLocked = (this.editor as Editor).locked;
    const layerScheduledToEnter = isLayerOk(this.scheduledLayer, this.editor.drawing);
    const noTextarea = (isLayerOk(this.layer, this.editor.drawing) && !this.textarea);
    return editorLocked || layerScheduledToEnter || noTextarea;
  }
  get disableBoxTextareaControls() {
    return this.textareaType === TextareaType.AutoWidth || this.textarea?.type === TextareaType.AutoWidth;
  }

  panelState: CharacterFormatting | undefined = undefined; // textarea formatting does not need to be kept and restored, nor paragraph ones
  get selectedCharacters() {
    if (this.textarea && this.textSelection?.length) {
      return this.textarea.characters.slice(this.textSelection.start, this.textSelection.end);
    }
    return [];
  }
  private assemblePanelProperty(panelState: CharacterFormatting, property: keyof TextTool & keyof CharacterFormatting) {
    const firstSelectedCharacterFormatting = this.selectedCharacters.length ? this.selectedCharacters[0].formatting : undefined;
    let value = this.getFormattingPropertyFromPanel(property, DEFAULT_CHARACTER_FORMATTING);
    if (value === null) value = firstSelectedCharacterFormatting ? firstSelectedCharacterFormatting[property] : DEFAULT_CHARACTER_FORMATTING[property];
    if (value !== null && value !== undefined) (panelState as any)[property] = value;
  }
  getPanelState() {
    const panelState: CharacterFormatting = {};
    const firstSelectedCharacterFormatting = this.selectedCharacters.length ? this.selectedCharacters[0].formatting : undefined;

    let size = this.getFormattingPropertyFromPanel('fontSize', DEFAULT_CHARACTER_FORMATTING);
    if (size === null) size = firstSelectedCharacterFormatting?.size ?? DEFAULT_CHARACTER_FORMATTING.size;
    if (size !== null && size !== undefined) panelState.size = size;

    this.assemblePanelProperty(panelState, 'baselineShift');
    this.assemblePanelProperty(panelState, 'lineheight');
    this.assemblePanelProperty(panelState, 'letterSpacing');

    let fontFamily = this.getFormattingPropertyFromPanel('fontFamily', DEFAULT_CHARACTER_FORMATTING);
    if (fontFamily === null) fontFamily = firstSelectedCharacterFormatting?.fontFamily ?? this.availableFontFamilies[0].name;
    if (fontFamily !== null && fontFamily !== undefined) panelState.fontFamily = fontFamily;

    if (this.bold || firstSelectedCharacterFormatting?.bold) panelState.bold = true;
    if (this.italic || firstSelectedCharacterFormatting?.italic) panelState.italic = true;
    if (this.underline || firstSelectedCharacterFormatting?.underline) panelState.underline = true;
    if (this.strikethrough || firstSelectedCharacterFormatting?.strikethrough) panelState.strikethrough = true;

    let scaleX = this.getFormattingPropertyFromPanel('scaleX', DEFAULT_CHARACTER_FORMATTING);
    if (scaleX === null) scaleX = firstSelectedCharacterFormatting ? firstSelectedCharacterFormatting.scaleX : DEFAULT_CHARACTER_FORMATTING.scaleX;
    if (scaleX !== null && scaleX !== undefined && !!scaleX) panelState.scaleX = scaleX;

    let scaleY = this.getFormattingPropertyFromPanel('scaleY', DEFAULT_CHARACTER_FORMATTING);
    if (scaleY === null) scaleY = firstSelectedCharacterFormatting ? firstSelectedCharacterFormatting.scaleY : DEFAULT_CHARACTER_FORMATTING.scaleY;
    if (scaleY !== null && scaleY !== undefined && !!scaleY) panelState.scaleY = scaleY;

    if (this.activeColorEmpty) { // or rather mixed values in selection
      panelState.color = firstSelectedCharacterFormatting?.color ?? DEFAULT_CHARACTER_FORMATTING.color;
    } else {
      panelState.color = colorToCSS(this.editor.primaryColor);
    }

    this.assemblePanelProperty(panelState, 'textCase');

    return panelState;
  }
  rememberPanelState(focusInput = true) {
    this.panelState = this.getPanelState();
    if (focusInput && this.focused) {
      this.input?.focus();
    }
    this.presettingFont = false;
  }

  get selectableFontFamilies(): string[] {
    return Object.keys(FONTS_SOURCES).sort() || [];
  }
  get availableFontFamilies(): FontFamily[] {
    return Array.from(FONTS.values() || []);
  }
  get availableFontStyles(): FontStyleNames[] {
    if (FONTS) {
      if (this.fontFamily) {
        const family = FONTS.get(this.fontFamily);
        return Array.from(family?.styles.keys() || []).sort((a, b) => {
          return fontStyleNameToWeight(a) - fontStyleNameToWeight(b);
        });
      } else {
        return [FontStyleNames.Regular];
      }
    } else {
      return [];
    }
  }
  readonly selectableFontSizes: AutoOr<number>[] = [
    // AUTO_SETTING_STRING,
    8, 9, 10, 11, 12, 13, 14, 15, 16,
    20, 24, 28, 32, 36, 40, 44, 48,
    60, 72
  ];
  readonly selectableLineheights: AutoOr<number>[] = [AUTO_SETTING_STRING, ...this.selectableFontSizes];
  readonly selectableLetterspacings: number[] = [-100, -75, -50, -25, -10, -5, 0, 5, 10, 25, 50, 75, 100, 200];
  readonly selectableBaselineShifts: number[] = [-12, -6, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 9, 12];
  readonly selectableStrokeWidths: number[] = [
    0, 0.25, 0.5, 0.75,
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
    12, 14, 16, 18,
    20, 40, 60, 80,
  ];
  setCharacterFormatting(property: (keyof TextTool & keyof CharacterFormatting), value?: any, focusInput = true, preventRemoteSync = false) {
    (this as any)[property] = value;
    const formatting = { [property]: this[property] };
    this.onCharacterFormatting(formatting, focusInput, preventRemoteSync);
  }
  setParagraphFormatting(property: (keyof TextTool & keyof ParagraphFormatting), value?: any) {
    finishTransform(this.editor as Editor, this.model.user, 'setParagraphFormatting');
    (this as any)[property] = value;
    const formatting = { [property]: this[property] };
    this.applyParagraphFormatting(formatting);
  }
  setTextareaFormatting(property: (keyof TextTool & keyof TextareaFormatting), value?: any) {
    finishTransform(this.editor as Editor, this.model.user, 'setTextareaFormatting');
    (this as any)[property] = value;
    const formatting = { [property]: this[property] };
    this.applyTextareaFormatting(formatting);
  }
  private onCharacterFormatting(formatting: CharacterFormatting, focusInput = true, preventRemoteSync = false) {
    if (!this.isUsingTextTool) return;
    if (formatting.color) this.activeColorEmpty = false;
    if (this.focused && this.hasGlyphsInSelection) {
      this.panelState = undefined;
      finishTransform(this.editor as Editor, this.model.user, 'onCharacterFormatting-1');
      this.applyCharacterFormattingToSelection(formatting, focusInput, preventRemoteSync);
    } else if (this.focused) {
      this.rememberPanelState(focusInput);
    } else {
      finishTransform(this.editor as Editor, this.model.user, 'onCharacterFormatting-2');
      this.applyGlobalCharacterFormatting(formatting, focusInput, preventRemoteSync);
    }
  }
  private queuedLoadingFontName = '';
  private presettingFont = true;
  presetFont(fontFamilyName: string) {
    if (this.presettingFont) {
      this.setFontFamily(fontFamilyName);
      this.presettingFont = false;
    }
  }
  setFontFamily(fontFamilyName: string) {
    this.queuedLoadingFontName = fontFamilyName;
    this.fontFamily = fontFamilyName;
    if (!FONTS.has(fontFamilyName)) {
      loadAnotherFont(fontFamilyName).then((fontFamily) => {
        if (this.queuedLoadingFontName === fontFamilyName) {
          this._setFontFamily(fontFamily);
          this.recomputeTextLocally(); // this is needed additionally because we want to do this manually instead of focus text tool input again and close dropdown (when wheeling or using arrow-keys)
        }
      }).catch((e) => DEVELOPMENT && console.error(e));
    } else {
      this._setFontFamily(FONTS.get(fontFamilyName)!);
      this.recomputeTextLocally();// this is needed additionally because we want to do this manually instead of focus text tool input again and close dropdown (when wheeling or using arrow-keys)
    }
  }
  private _setFontFamily(fontFamily: FontFamily) {
    this.fontFamily = fontFamily.name;
    const formatting: CharacterFormatting = { fontFamily: fontFamily.name };

    if (this.fontStyle === '' || !fontFamily.styles.get(this.fontStyle)) {
      this.fontStyle = fontFamily.styles.get(FontStyleNames.Regular) ? FontStyleNames.Regular : '';
      this.bold = isBold(this.fontStyle);
      this.italic = isItalic(this.fontStyle);
    }

    formatting.bold = this.bold;
    formatting.italic = this.italic;

    if (!this.presettingFont) {
      this.onCharacterFormatting(formatting, false);
    } else {
      this.rememberPanelState();
    }
  }
  setFontStyle(fontStyleName: FontStyleNames) {
    this.fontStyle = fontStyleName;
    this.bold = isBold(fontStyleName);
    this.italic = isItalic(fontStyleName);
    this.onCharacterFormatting({
      bold: this.bold,
      italic: this.italic
    }, this.focused);
  }
  toggleBold() {
    if (this.availableFontStyles.includes(FontStyleNames.Bold)) {
      const isBoldNow = isBold(this.fontStyle);
      const isItalicNow = isItalic(this.fontStyle);

      if (!isItalicNow && !isBoldNow) this.fontStyle = FontStyleNames.Bold;
      else if (isItalicNow && !isBoldNow) this.fontStyle = FontStyleNames.BoldItalic;
      else if (!isItalicNow && isBoldNow) this.fontStyle = FontStyleNames.Regular;
      else if (isItalicNow && isBoldNow) this.fontStyle = FontStyleNames.Italic;

      this.bold = isBold(this.fontStyle);
      this.onCharacterFormatting({ bold: this.bold }, this.focused);
    }
  }
  toggleItalic() {
    if (this.availableFontStyles.includes(FontStyleNames.Italic)) {
      const isBoldNow = isBold(this.fontStyle);
      const isItalicNow = isItalic(this.fontStyle);

      if (!isItalicNow && !isBoldNow) this.fontStyle = FontStyleNames.Italic;
      else if (isItalicNow && !isBoldNow) this.fontStyle = FontStyleNames.Regular;
      else if (!isItalicNow && isBoldNow) this.fontStyle = FontStyleNames.BoldItalic;
      else if (isItalicNow && isBoldNow) this.fontStyle = FontStyleNames.Bold;

      this.italic = isItalic(this.fontStyle);
      this.onCharacterFormatting({ italic: this.italic }, this.focused);
    }
  }
  toggleUnderline() {
    this.underline = !this.underline;
    this.onCharacterFormatting({ underline: this.underline }, this.focused);
  }
  toggleStrikethrough() {
    this.strikethrough = !this.strikethrough;
    this.onCharacterFormatting({ strikethrough: this.strikethrough }, this.focused);
  }
  setFontSize(size: AutoOr<number>, focusInput = true) {
    this.fontSize = size;
    const formatting = { size: this.fontSize };
    // cast is needed because CharacterFormatting expects size: number and in tool fontSize is of type AutoOr<number>
    // but during applying (Textarea.formatCharacters()) all possible autos are stripped
    this.onCharacterFormatting(formatting as CharacterFormatting, focusInput);
    if (!focusInput) this.recomputeTextLocally();
  }
  setFillOpacity(newOpacity: number) {
    newOpacity;
    // this.fillOpacity = newOpacity;
    // const color = colorToCSS(withAlpha(this.fillColor || 0, this.fillOpacity));
    // this.onCharacterFormatting({ color });
  }
  setColor(color: number, focusInput = this.focused) {
    if (!this.isTextLayerLocked) {
      const formatting = { color: colorToCSS(withAlpha(color || 0, this.fillOpacity)) };
      this.onCharacterFormatting(formatting, focusInput);
    }
  }

  loadTextLayerProperties(options: TextareaOptions) {
    const textarea = createTextarea(FONTS, options);
    if (textarea) {
      this.textarea = textarea;
      const { type, textareaFormatting } = options;
      this.textareaType = type;
      this.verticalAlignment = textareaFormatting.verticalAlignment || VerticalAlignments.Top;
      this.mode = TextToolMode.Typing;
      copyRect(this.rect, textarea.rect);
      if (!SERVER && (this.editor as Editor).selectedTool?.id === this.id) this.recreateInput();
    }
  }
  changeTextareaType(type: TextareaType, sendToRemote = true) {
    if (isLayerOk(this.layer, this.editor.drawing) && isTextareaOk(this.textarea)) {
      if (sendToRemote) {
        this.pushNewChangeToHistory(this.layer, `${TextToolMode.Formatting}-textareaType`);
      }

      normalizeRect(this.rect);
      const options: TextareaOptions = {
        ...this.rect,
        textareaFormatting: this.textarea.textareaFormatting,
        paragraphFormattings: this.textarea.paragraphFormattings,
        characterFormattings: this.textarea.characterFormattings,
        defaultFontFamily: this.textarea?.defaultFontFamily || this.fontFamily || (this.availableFontFamilies.length ? this.availableFontFamilies[0].name : this.selectableFontFamilies[0]),
        text: this.textarea.text,
        dirty: this.textarea.dirty,
        type,
      };
      this.textarea = createTextarea(this.textarea.fontFamilies, options);

      normalizeRect(this.rect);
      integerizeRect(this.rect);

      this.textarea?.syncControlPoints();
      this.textareaType = type;
      redraw(this.editor);
      redrawLayer(this.editor, this.layer);

      if (sendToRemote) {
        this.commitChangesOnRemote(this.layer, TextToolMode.Formatting);
        this.focused = this.focused;
      }
    } else {
      throw new Error('Unable to change textarea type. For some reason fonts are not present.');
    }
  }

  inputPositionAdjust: Function | undefined = undefined;
  private _textSelection: TextSelectionDetailed | undefined = undefined;
  get textSelection(): TextSelectionDetailed | undefined {
    return this._textSelection;
  }
  set textSelection(value: TextSelectionDetailed | undefined) {
    this._textSelection = value;
    if (!this.textarea?.formattingToApplyOnNextInput) {
      if (value && this.textarea?.text !== '') {
        this.updatePanelStateFromSelection(value);
      } else {
        this.updatePanelStateFromSelection();
      }
    }
    this.scheduleAdjustingInputPosition(value);
  }

  scheduleAdjustingInputPosition(selection: TextSelectionDetailed | undefined){
    this.inputPositionAdjust = () => {
      // repainting artificial textarea input takes a lot of resources, making this asynchronous helps with performance,
      // ux can be only affected with IME or emoji popups popping in wrong place but window is so short it shouldn't happen
      if (isLayerOk(this.layer, this.editor.drawing) && this.input && this.textarea) {
        const cursor = this.textarea.getCursorPosition(selection);
        let x = this.layer.textData.x;
        let y = this.layer.textData.y;
        if (cursor.caretRect) {
          x = cursor.caretRect.x;
          y = cursor.caretRect.y;
        }
        if (cursor.selectionRects.length) {
          if (selection?.direction !== TEXT_SELECTION_DIRECTION_BACKWARD) {
            const lastSelectionRect = cursor.selectionRects[cursor.selectionRects.length - 1];
            x = lastSelectionRect.x + lastSelectionRect.w;
            y = lastSelectionRect.y;
          } else {
            const firstSelectionRect = cursor.selectionRects[0];
            x = firstSelectionRect.x; y = firstSelectionRect.y;
          }
        }
        setInputPositionOnPage(this.input, this.editor.view, x, y);
      }
      this.inputPositionAdjust = undefined;
    };
    setTimeout(() => { this.inputPositionAdjust?.(); }, 10);
  }
  frame() {
    if (this.shouldFixMissingTextarea(this.layer)) this.fixMissingTextarea(this.layer);
    if (this.mode !== TextToolMode.Selecting) this.trackSelectionChange();
  }
  private shouldFixMissingTextarea(layer: Layer | undefined): layer is TextLayer & { textarea: undefined } {
    return (isLayerOk(layer, this.editor.drawing) && !layer.textarea && layer.fontsLoaded);
  }
  private fixMissingTextarea(layer: TextLayer & { textarea: undefined }) {
    if (!layer.textData.defaultFontFamily) {
      layer.textData.defaultFontFamily = this.fontFamily || (this.availableFontFamilies.length ? this.availableFontFamilies[0].name : this.selectableFontFamilies[0]);
    }
    layer.fontsLoaded = hasFontsLoaded(layer);
  }
  get isUsingTextTool() {
    return (this.editor as Editor).selectedTool?.id === this.id;
  }
  get shouldRefocus() {
    if (SERVER) return false;
    if (!this.input) return false;

    if (!this.isUsingTextTool) return false;

    const activeElement = document.activeElement;
    const consideredFocusedButIsNot = this.focused && this.input !== activeElement;
    const focusSomewhereElseValid = !activeElement || (!isControl(activeElement) && !activeElement.classList.contains('font-select-skip-blur'));
    return consideredFocusedButIsNot && focusSomewhereElseValid;
  }
  private trackSelectionChange() {
    if (this.input) {
      const selection = this.getSelection();
      const existing = this.textSelection;
      const differentStart = selection?.start !== existing?.start;
      const differentEnd = selection?.end !== existing?.end;
      if (differentStart || differentEnd) {
        this.textSelection = selection;
      }
    }
  }

  // updating panel according to selection:
  private updatePanelState(formattings: (CharacterFormattingDescription | CharacterFormatting)[]) {
    this.letterSpacing = this.getUniqueValuesFromFormatting(formattings, 'letterSpacing');
    this.baselineShift = this.getUniqueValuesFromFormatting(formattings, 'baselineShift');

    // TODO: for now we mark indeterminate state (mixed formattings) as 0 on scale sliders
    //  (which is unsettable manually because we clamp at 1%), might want to change sometime
    this.scaleX = this.getUniqueValuesFromFormatting(formattings, 'scaleX') || 0;
    this.scaleY = this.getUniqueValuesFromFormatting(formattings, 'scaleY') || 0;

    this.setPanelStateForFontSize(formattings);
    this.setPanelStateForLineheight(formattings);
    this.setPanelStateForFontStyle(formattings);
    this.setPanelStateForFontFamily(formattings);
    this.setPanelStateForDecorations(formattings);
    this.setPanelStateForColor(formattings);
  }
  updatePanelStateFromSelection(selection?: TextSelectionDetailed): void {
    const sel = this.ensureSelectionHasAtLeastOneCharacter(selection);
    const characters = sel ? (this.textarea?.getCharactersFromTextSelection(sel) || []) : [];
    const formattings = characters.map(c => c.formatting);
    this.updatePanelState(formattings);
    this.setPanelStateForAlignment(selection);
  }
  private ensureSelectionHasAtLeastOneCharacter(selection: TextSelectionDetailed | undefined) {
    // this mutation enables setting new panel state according to letter
    // before cursor when it's in not selection mode (between letters)
    if (selection && this.textarea) {
      const newSelection = { ...selection };
      if (newSelection.length === 0) {
        if (newSelection.start === 0) {
          newSelection.end++;
        } else {
          newSelection.start--;
        }
        newSelection.length = 1;
        return newSelection;
      }
      return selection;
    }
    return undefined;
  }
  private setPanelStateForFontStyle(formattings: (CharacterFormattingDescription | CharacterFormatting)[]): void {
    let differentValues = false;

    const uniqueBolds = uniq(formattings.map(f => f.bold));
    const uniqueItalics = uniq(formattings.map(f => f.italic));

    // TODO: is there indeterminate state for bold/italic togglers?
    //  For now assuming it's the same as false and handling it in elses
    //  unless global textarea formatting says otherwise

    if (uniqueBolds.filter(v => v !== undefined).length === 1) {
      if (uniqueBolds.length !== 1) differentValues = true;
      this.bold = uniqueBolds.filter(v => v !== undefined)[0]!;
    } else {
      if (!(uniqueBolds.length === 1 && uniqueBolds[0] === undefined)) differentValues = true;
      this.bold = this.textarea?.textareaFormatting.globalStyles?.bold ?? false;
    }

    if (uniqueItalics.filter(v => v !== undefined).length === 1) {
      if (uniqueItalics.length !== 1) differentValues = true;
      this.italic = uniqueItalics.filter(v => v !== undefined)[0]!;
    } else {
      if (!(uniqueItalics.length === 1 && uniqueItalics[0] === undefined)) differentValues = true;
      this.italic = this.textarea?.textareaFormatting.globalStyles?.italic ?? false;
    }

    if (this.textarea?.text === '' && !this.textarea.formattingToApplyOnNextInput) {
      this.fontStyle = FontStyleNames.Regular;
    } else if (differentValues) {
      this.fontStyle = '';
    } else {
      if (!this.italic && !this.bold) this.fontStyle = FontStyleNames.Regular;
      else if (this.italic && !this.bold) this.fontStyle = FontStyleNames.Italic;
      else if (!this.italic && this.bold) this.fontStyle = FontStyleNames.Bold;
      else if (this.italic && this.bold) this.fontStyle = FontStyleNames.BoldItalic;
    }
  }
  private setPanelStateForFontFamily(formattings: (CharacterFormattingDescription | CharacterFormatting)[]): void {
    const uniqueValues = uniq(formattings.map(f => f.fontFamily));
    if (uniqueValues.filter(v => v !== undefined).length === 0) {
      this.fontFamily = this.textarea?.defaultFontFamily || '';
    } else if (uniqueValues.length > 1) {
      this.fontFamily = '';
    } else {
      this.fontFamily = uniqueValues[0] ?? this.textarea?.defaultFontFamily ?? '';
    }
  }
  private setPanelStateForFontSize(formattings: (CharacterFormattingDescription | CharacterFormatting)[]): void {
    if (formattings.length === 0) {
      this.fontSize = DEFAULT_CHARACTER_FORMATTING.size;
    } else {
      const uniqueValues = uniq(formattings.map(f => f.size));
      if (uniqueValues.filter(v => v !== undefined).length === 0) {
        // characters in range are not formatted that way - fallback to globals (if not present to defaults)
        if (uniqueValues.length === 0) this.fontSize = undefined;
        else this.fontSize = this.textarea?.textareaFormatting?.globalStyles?.size ?? DEFAULT_TEXTAREA_FORMATTING.globalStyles.size ?? AUTO_SETTING_STRING;
      } else if (uniqueValues.length > 1) {
        // characters in range have multiple different values - set values in dropdowns to empty strings
        this.fontSize = undefined;
      } else {
        // one common formatting between all characters in range - set to this one
        this.fontSize = uniqueValues[0]!;
      }
    }
  }
  private setPanelStateForAlignment(selection: TextSelectionDetailed | undefined): void {
    if (!selection || selection.paragraphIndexes.length !== 1) this.alignment = undefined;
    else this.alignment = this.textarea?.paragraphs[selection.paragraphIndexes[0]!]!.formatting.alignment;
  }
  private setPanelStateForLineheight(formattings: (CharacterFormattingDescription | CharacterFormatting)[]): void {
    if (formattings.length === 0) {
      this.lineheight = AUTO_SETTING_STRING;
    } else {
      const uniqueValues = uniq(formattings.map(f => f.lineheight));
      if (uniqueValues.filter(v => v !== undefined).length === 0) {
        // characters in range are not formatted that way - fallback to globals (if not present to defaults)
        if (uniqueValues.length === 0) this.lineheight = undefined;
        else this.lineheight = this.textarea?.textareaFormatting?.globalStyles?.lineheight ?? DEFAULT_TEXTAREA_FORMATTING.globalStyles.lineheight ?? AUTO_SETTING_STRING;
      } else if (uniqueValues.length > 1) {
        // characters in range have multiple different values - set values in dropdowns to empty strings
        this.lineheight = undefined;
      } else {
        // one common formatting between all characters in range - set to this one
        this.lineheight = uniqueValues[0]!;
      }
    }
  }
  private setPanelStateForDecorations(formattings: (CharacterFormattingDescription | CharacterFormatting)[]): void {
    const uniqueUnderlines = uniq(formattings.map(f => f.underline));
    const uniqueStrikethroughs = uniq(formattings.map(f => f.strikethrough));

    // TODO: is there indeterminate state for underline/strikethgrough togglers?
    //  For now assuming it's the same as false and handling it in elses
    //  unless global textarea formatting says otherwise

    this.underline = uniqueUnderlines.some(v => !!v);
    this.strikethrough = uniqueStrikethroughs.some(v => !!v);
  }
  private setPanelStateForColor(formattings: (CharacterFormattingDescription | CharacterFormatting)[]): void {
    const uniqueColors = uniq(formattings.map(f => f.color));
    if (uniqueColors.filter(c => c !== undefined).length === 1) {
      const color = parseColor(uniqueColors[0]!);
      const alpha = getAlpha(color);
      (this.editor as Editor).activeColor = color;
      this.fillOpacity = alpha;
      this.activeColorEmpty = false;
    } else if (uniqueColors.length !== 1) {
      this.activeColorEmpty = true;
    }
  }
  private getUniqueValuesFromFormatting<T extends keyof CharacterFormatting>(formattings: (CharacterFormattingDescription | CharacterFormatting)[], key: T): (CharacterFormattingDescription | CharacterFormatting)[T] | undefined {
    const uniqueValues = uniq(formattings.map(f => f[key]));
    if (uniqueValues.length === 0) {
      // characters in range are not formatted that way - fallback to globals (if not present to defaults)
      return this.textarea?.textareaFormatting?.globalStyles?.[key] ?? DEFAULT_TEXTAREA_FORMATTING.globalStyles[key];
    } else if (uniqueValues.length > 1) {
      // characters in range have multiple different values - set values in dropdowns to empty strings
      return undefined;
    } else {
      // one common formatting between all characters in range - set to this one
      return uniqueValues[0];
    }
  }

  primaryColorEmpty = false;
  secondaryColorEmpty = false;
  get activeColorEmpty() {
    const editor = this.editor as Editor;
    return editor && editor.activeColorField && editor.activeColorField === 'primary' ? this.primaryColorEmpty : this.secondaryColorEmpty;
  }
  set activeColorEmpty(value: boolean) {
    const editor = this.editor as Editor;
    if (editor && editor.activeColorField) {
      if (editor.activeColorField === 'primary') {
        this.primaryColorEmpty = value;
      } else {
        this.secondaryColorEmpty = value;
      }
    }
  }

  // tooltips in panel:
  get fontFamilyTooltip() {
    return this.fontFamily === undefined ? 'mixed' : undefined;
  }
  get baselineShiftTooltip() {
    return this.baselineShift !== undefined ? 'Baseline Shift' : 'mixed';
  }
  get fontSizeTooltip() {
    return this.fontSize !== undefined ? 'Font Size' : 'mixed';
  }
  get lineheightTooltip() {
    return this.lineheight !== undefined ? 'Line Height' : 'mixed';
  }
  get letterSpacingTooltip() {
    return this.letterSpacing !== undefined ? 'Letter Spacing' : 'mixed';
  }

  // local history:
  private runDoTool(layer: Layer, data: TextToolData) {
    if (this.layer?.id === data.layerData.id) this.clearDeferredChange();
    else this.confirmDeferredChange();
    this.model.doTool(layer.id, data);
  }
  commitChangesOnRemote(layer: TextLayer, mode: TextToolMode) {
    const opts = this.recomputeTextLocally(mode);
    if (opts) {
      if (this.skipHistory) {
        opts.history = false;
        opts.preventHistory = true;
        this.skipHistory = false;
      }
      this.runDoTool(layer, opts);
    }
  }

  // context menu:
  selectEntireText() {
    if (this.input) {
      if (!this.focused) {
        this.focused = true;
      } else {
        this.input?.focus();
        this.input?.classList.remove('ks-allow');
      }
      this.dragged = false;
      this.mode = TextToolMode.Selecting;
      this.input.setSelectionRange(0, this.input.value.length, TEXT_SELECTION_DIRECTION_FORWARD);
    }
  }
  get hasGlyphsInSelection() {
    return this.input && this.textSelection && this.textSelection.length > 0;
  }
  copy() {
    const selection = this.textSelection;
    const textarea = this.textarea;
    if (!selection || !textarea) throw new Error('Couldn\'t copy textarea text.');
    return copyTextFromTextarea(textarea, selection).then((successfullyCopied) => {
      if (!successfullyCopied) displayToastAboutNotSupportedClipboard(this.editor, 'Copying');
    }).catch((e) => {
      displayToastAboutNotSupportedClipboard(this.editor, 'Copying');
      DEVELOPMENT && console.warn('Couldn\'t copy textarea text.', e);
      return false;
    });
  }
  cut() {
    if (!this.hasGlyphsInSelection) return undefined;
    const selection = this.textSelection;
    const textarea = this.textarea;
    if (!selection || !textarea || !isLayerOk(this.layer, this.editor.drawing)) throw new Error('Couldn\'t copy textarea text.');

    this.confirmDeferredChange();
    const goToStateBefore = this.globalHistory.createLayerState(this.layer.id);
    copyRect(this.originalTextureRect, textarea.textureRect);

    return copyTextFromTextarea(textarea, selection)
      .then((successfullyCopied) => {
        if (!successfullyCopied) displayToastAboutNotSupportedClipboard(this.editor, 'Cutting');
        const selection = this.textSelection;
        const textarea = this.textarea;
        const input = this.input;
        if (!successfullyCopied || !selection || !textarea || !input || !isLayerOk(this.layer, this.editor.drawing)) {
          DEVELOPMENT && console.warn('Failed to copy text from selection, aborting cut.');
          return false;
        }

        removeCharactersFromTextarea(textarea, selection, input);
        this.globalHistory.pushUndo(goToStateBefore);
        this.topActionHistoryString = TextToolMode.Typing;

        this.commitChangesOnRemote(this.layer, TextToolMode.Typing);
        this.textSelection = this.getSelection();
        return true;
      })
      .catch((e) => {
        displayToastAboutNotSupportedClipboard(this.editor, 'Cutting');
        DEVELOPMENT && console.warn('Couldn\'t cut textarea text.', e);
        return false;
      });
  }
  paste(forcePlainText = false) {
    const selection = this.textSelection;
    const textarea = this.textarea;
    const input = this.input;
    if (!selection || !textarea || !input || !isLayerOk(this.layer, this.editor.drawing)) throw new Error('Couldn\'t paste text in textarea.');

    this.confirmDeferredChange();
    const goToStateBefore = this.globalHistory.createLayerState(this.layer.id);
    copyRect(this.originalTextureRect, textarea.textureRect);

    if (this.selectedEntireTextOrCaretAtStart) this.rememberPanelState();
    return pasteIntoTextarea(textarea, selection, input, forcePlainText, this.panelState)
      .then((successfullyPasted) => {
        if (isLayerOk(this.layer, this.editor.drawing) && successfullyPasted) {
          this.globalHistory.pushUndo(goToStateBefore);
          this.topActionHistoryString = TextToolMode.Typing;
          this.commitChangesOnRemote(this.layer, TextToolMode.Typing);
        }
        this.textSelection = this.getSelection();
      })
      .catch((e) => {
        displayToastAboutNotSupportedClipboard(this.editor, 'Pasting');
        DEVELOPMENT && console.warn('Couldn\'t paste textarea text!', e);
      });
  }
  clear() {
    if (!this.hasGlyphsInSelection) return;
    const selection = this.textSelection;
    const textarea = this.textarea;
    const input = this.input;
    if (!selection || !textarea || !input || !isLayerOk(this.layer, this.editor.drawing)) throw new Error('Couldn\'t clear text in textarea.');
    this.pushNewChangeToHistory(this.layer, TextToolMode.Typing);
    removeCharactersFromTextarea(textarea, selection, input);
    this.commitChangesOnRemote(this.layer, TextToolMode.Typing);
    this.textSelection = this.getSelection();
  }

  readonly safeLimits = {
    scaleX: { min: MIN_SAFE_SCALE_X, max: MAX_SAFE_SCALE_X },
    scaleY: { min: MIN_SAFE_SCALE_Y, max: MAX_SAFE_SCALE_Y },
    size: { min: MIN_SAFE_SIZE, max: MAX_SAFE_SIZE },
    letterSpacing: { min: MIN_SAFE_LETTERSPACING, max: MAX_SAFE_LETTERSPACING },
    lineheight: { min: MIN_SAFE_LINEHEIGHT, max: MAX_SAFE_LINEHEIGHT },
    baselineShift: { min: MIN_SAFE_BASELINE_SHIFT, max: MAX_SAFE_BASELINE_SHIFT },
  };

  get transformationScaleY() {
    if (!this.textarea) return 1;
    return decomposeMat2d(this.textarea.transform).scaleY;
  }

  get globalHistory() {
    return this.model.user.history as GlobalHistory;
  }

  private topActionHistoryString = '';
  skipHistory = false;
  pushNewChangeToHistory(layer: TextLayer, historyString: string) {
    this.confirmDeferredChange();

    if (layer.textarea) copyRect(this.originalTextureRect, layer.textarea.textureRect);
    else setRect(this.originalTextureRect, 0, 0, 0, 0);

    if (this.topActionHistoryStringStacks) {
      if (this.topActionHistoryString === historyString) {
        this.skipHistory = true;
      } else {
        this.globalHistory.pushLayerState(layer.id);
      }
    } else {
      this.globalHistory.pushLayerState(layer.id);
    }

    this.topActionHistoryString = historyString;
  }
  private get topActionHistoryStringStacks() {
    if (this.topActionHistoryString === TextToolMode.Moving || this.topActionHistoryString === TextToolMode.Resizing) {
      return true;
    } else {
      return this.topActionHistoryString.split('-')[0] === TextToolMode.Formatting;
    }
  }
  getSelectionForUndo(layer: Layer): TextSelection | undefined {
    const selection = this.textSelection;
    if (this.layer === layer && this.focused && selection) {
      const { start, end, direction } = selection;
      return [start, end, direction];
    } else {
      return undefined;
    }
  }
  retrieveSelectionFromUndo(textSelectionSavedInUndo: TextSelection | undefined) {
    if (!textSelectionSavedInUndo || !this.input) return;
    const [start, end, direction] = textSelectionSavedInUndo;
    this.input.setSelectionRange(start, end, direction);
  }
}

const isLayerOk = (layer: Layer | undefined, drawing: Drawing): layer is TextLayer => !!layer && isTextLayer(layer) && drawing.layers.includes(layer);
const isTextareaOk = (textarea: Textarea | undefined): textarea is Textarea => !!textarea && !!textarea.type;

export function preprocessTextLayersForDrawing(drawing: Drawing, drawingFn: (layer: TextLayer, drawing: Drawing) => void, force = false) {
  forAllTextLayers(drawing, (layer) => {
    if (canDrawTextLayer(layer) && (layer.invalidateCanvas || force)) {
      drawingFn(layer, drawing);
    }
  });
}

export function shouldRenderTextareaBoundaries(textarea: Textarea, tool: TextTool, options: DrawOptions): boolean {
  return (tool.focused || textarea.type !== TextareaType.AutoWidth) && options.selectedTool?.id !== ToolId.Transform;
}

export function shouldRenderTextareaCursor(textarea: Textarea, tool: TextTool): boolean {
  textarea;
  return tool.focused && !tool.isTextLayerLocked;
}

export function shouldRenderTextareaBaselineIndicator(textarea: Textarea, tool: TextTool) {
  tool;
  return textarea.type !== TextareaType.AutoWidth || !!textarea.text.length;
}

export function shouldRenderTextareaControlPoints(textarea: Textarea, tool: TextTool): boolean {
  return !tool.isTextLayerLocked && (tool.focused || textarea.type !== TextareaType.AutoWidth);
}

export function shouldRenderTextareaOverflowIndicator(textarea: Textarea, tool: TextTool): boolean {
  tool;
  return textarea.hasOverflowingCharacters;
}

const TEXTURE_RECT_STROKE_COLOR = `rgba(${TEXTAREA_BOUNDARIES_COLOR.r * 255}, ${TEXTAREA_BOUNDARIES_COLOR.b * 255}, ${TEXTAREA_BOUNDARIES_COLOR.g * 255}, ${TEXTAREA_BOUNDARIES_COLOR.a * 255})`;
export function drawTextareaTextureRect(ctx: CanvasRenderingContext2D, textarea: Textarea) {
  if (DEVELOPMENT && DRAW_TEXTURE_RECT) {
    const ratio = getPixelRatio();
    const greenRect = textarea.textureRect;
    ctx.lineWidth = 2 * TEXTAREA_UNHOVERED_BOUNDARIES_WIDTH / ratio;
    ctx.strokeStyle = TEXTURE_RECT_STROKE_COLOR;
    ctx.strokeRect(greenRect.x, greenRect.y, greenRect.w, greenRect.h);
  }
}

function setInputPositionOnPage(input: HTMLTextAreaElement, view: Viewport, docX: number, docY: number) {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const topLeft = createPoint(docX, docY);
  documentToScreenXY(topLeft, topLeft.x, topLeft.y, view);
  const left = clamp(topLeft.x, 0, windowWidth - ARTIFICIAL_TEXTAREA_SIZE);
  const top = clamp(topLeft.y, 0, windowHeight - ARTIFICIAL_TEXTAREA_SIZE);
  input.style.transform = `translate3d(${left}px, ${top}px, 0)`;
  input.style.overflow = 'hidden';
}
