import { EventEmitter, Output, Directive, ElementRef, Input, SimpleChanges } from '@angular/core';
import Tribute, { TributeItem } from 'tributejs';
import { Suggestion } from '../../../common/interfaces';
import { getAvatarPath } from '../../../common/utils';
import { escape } from 'lodash';
import { IThreadService } from '../../../services/thread.service.interface';
import { AVATAR_DEFAULT, REMOVED_USER_NAME, USER_BADGE_REGEX } from '../../../common/constants';
import { Model } from '../../../services/model';

export type SuggestionCallback = (result: Array<Suggestion>) => void;
export interface CommentData {
  content: string;
  mentions: string[]
}

function userBadgeTemplate(id: string, name: string, isMe: boolean) {
  const userName = escape(name);
  return `<span class="mention-badge ${isMe ? 'mention-badge-me' : ''}" contenteditable="false" data-id="${id}">@${userName}</span>`;
}

function convertCommentToMarkdown(element: HTMLElement) {
  let content = '';
  const mentions = new Set<string>();
  for (let n = element.firstChild; n; n = n.nextSibling) {
    if (n.nodeType === Node.TEXT_NODE) {
      content += n.textContent;
    } else if (n.nodeType === Node.ELEMENT_NODE) {
      if (n.nodeName === 'SPAN') {
        const userId = (n as Element).getAttribute('data-id');
        if (userId) {
          content += `[user](${userId})`;
          mentions.add(userId);
        }
      } else if (n.nodeName === 'BR') {
        content += `\n`;
      }
    }
  }
  return { content, mentions: Array.from(mentions) };
}

export function convertMarkdownToComment(data: string, myAccountId: string | undefined, mentions: Suggestion[]) {
  return data.replace(USER_BADGE_REGEX, (_: string, id: string) => {
    const user = mentions?.find(u => u._id === id); // use map
    return userBadgeTemplate(id, user?.name ?? REMOVED_USER_NAME, myAccountId === id);
  });
}


@Directive({ selector: '[mention]' })
export class MentionDirective {
  @Output() queryChanged = new EventEmitter<{ query: string, callback: SuggestionCallback }>();
  @Output() send = new EventEmitter<CommentData>();
  @Input() value?: CommentData;
  @Output() valueChange = new EventEmitter<CommentData>();
  @Input() disabled = false;

  tribute?: Tribute<Suggestion>;

  constructor(private element: ElementRef<HTMLElement>, private model: Model, private threadService: IThreadService) { }

  async ngOnInit() {
    const menuContainer = document.getElementById('mention-dropdown-container');
    if (!menuContainer) {
      DEVELOPMENT && console.error('Unable to find element with id mention-dropdown-container');
      return;
    }

    this.tribute = new Tribute({
      values: (query: string, callback: SuggestionCallback) => {
        this.queryChanged.emit({ query, callback });
      },
      lookup: (item: Suggestion) =>{
        return item.name + item.email;
      },
      containerClass: 'use-magma-styles mention-container',
      menuContainer,
      noMatchTemplate: () => '',
      selectTemplate: (item: TributeItem<Suggestion> | undefined) => {
        if (!item) return '';
        return userBadgeTemplate(item.original._id, item.original.name, this.model.user.accountId === item.original._id);
      },
      menuItemTemplate: (item) => {
        return `
          ${item.original.avatar ? `<img src="${getAvatarPath(item.original.avatar, 32)}"/>` : `<img src="${getAvatarPath(AVATAR_DEFAULT, 32)}"/>`}
          <span>${escape(item.original.name)}</span>
        `;
      },
      requireLeadingSpace: false,
      replaceTextSuffix: ' ',
    });

    this.tribute.attach(this.element.nativeElement);

    this.element.nativeElement.addEventListener('keydown', (event: KeyboardEvent) => {
      if (this.disabled) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }
      const commentData = convertCommentToMarkdown(this.element.nativeElement);
      this.valueChange.emit(commentData);

      if (event.key === 'Enter' && !event.shiftKey && !this.tribute?.isActive) {
        if (commentData.content.length > 0) {
          this.send.emit(commentData);
        }
        event.preventDefault(); // remove if multiline
      }
      event.stopPropagation(); // do not pass events to editor when in comments component
    });

    this.element.nativeElement.addEventListener('paste', async (event: ClipboardEvent) => {
      event.stopPropagation();
      event.preventDefault();

      const clipboardData = event.clipboardData || (window as any).clipboardData;
      const text = clipboardData.getData('Text');

      const selection = window.getSelection();
      if (selection) {
        if (!selection.rangeCount) return;
        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(text));
      }
    });

    if (this.value) {
      const suggestions = await this.threadService.getMentionSuggestions();
      this.element.nativeElement.innerHTML = convertMarkdownToComment(escape(this.value.content), this.model.user.accountId, suggestions);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // so far react only for clear value
    if (changes.value?.currentValue === undefined) {
      this.element.nativeElement.innerHTML = '';
    }

    if (changes.disabled?.currentValue !== changes.disabled?.previousValue) {
      if (changes.disabled.currentValue) {
        this.element.nativeElement.blur();
      } else {
        this.element.nativeElement.focus();
      }
    }
  }

  ngOnDestroy() {
    this.tribute?.detach(this.element.nativeElement);
  }
}
