import { Feature } from './interfaces';

// keep it here because we don't want to share flag descriptions to all users
export const FEATURE_LIST: { feature: Feature, description: string; }[] = [
  { feature: Feature.Hid, description: 'enable WebHID' },
  { feature: Feature.NoUserLimit, description: 'disable user limit for artspace' },
  { feature: Feature.Video, description: 'enable the video button' },
  { feature: Feature.Presentation, description: 'allow starting presentations' },

  { feature: Feature.BrushesGrut, description: '' },
  { feature: Feature.BrushesIntq20, description: '' },
  { feature: Feature.BrushesIntq49, description: '' },
  { feature: Feature.BrushesOmri, description: '' },
  { feature: Feature.BrushesRoss, description: '' },
  { feature: Feature.BrushesFantasio, description: '' },
  { feature: Feature.BrushesBradley, description: '' },

  { feature: Feature.ShapesGrids, description: '' },

  { feature: Feature.Ai, description: 'enable AI' },
  { feature: Feature.AiNsfw, description: 'disable NSFW filter for AI' },
  { feature: Feature.AiEula, description: 'agreed to AI EULA' },
  { feature: Feature.AiBeta, description: 'enable access to beta ai options' },
  { feature: Feature.AiNoPromptFilter, description: 'disable filtering restricted keywords in prompt' },
  { feature: Feature.AiNoUsageLimits, description: 'disable limiting ai generation time' },
  { feature: Feature.AiDreambooth, description: 'enable access to dreambooth (training custom AI models)' },
  { feature: Feature.AiControlNet, description: 'enable access to control nets' },

  { feature: Feature.Text, description: 'enable text tool (beta feature)' },

  { feature: Feature.StorageNoUsageLimits, description: 'enable no storage usage limit' },
];
