import type { Router } from '@angular/router';
import { storageGetItem, storageSetItem, storageSetString } from '../services/storage';
import { MINUTE } from './constants';
import { apiPath, drawingsPath } from './data';
import { isValidDrawingId } from './drawingUtils';

export function pushOpenedBy(shortId: string | undefined, openedBy: string) {
  if (shortId) {
    storageSetItem('opened-by', JSON.stringify({ id: shortId, openedBy, time: Date.now() + (5 * MINUTE) }));
  }
}

export function popOpenedBy(shortId: string | undefined) {
  try {
    if (shortId) {
      const item = JSON.parse(storageGetItem('opened-by') ?? '{}');

      if (item && item.openedBy && item.id === shortId && Date.now() < item.time) {
        return item.openedBy;
      }
    }
  } catch { }

  return undefined;
}

export function navigateToDrawing(router: Router, drawingId: string, openedBy: string) {
  pushOpenedBy(drawingId, openedBy);
  void router.navigate([drawingsPath, drawingId]);
}

const thumbPathCache = new Map<string, { timestampOrCacheId: string | number | undefined; url: string; }>();

export function getEntityPassword(drawingId: string) {
  if (SERVER) return undefined;
  return storageGetItem(`pass:${drawingId}`) ?? undefined;
}

export function setEntityPassword(drawingId: string, password: string | undefined) {
  storageSetString(`pass:${drawingId}`, password);
  thumbPathCache.delete(drawingId);
}

export function getThumbPath(
  drawingId: string,
  cacheId: string | number | undefined = 0,
  forceNoError = false,
  password?: string | undefined | null,
  isAdmin?: boolean,
  resolution?: number,
) {
  if (IS_PORTAL) {
    const useCache = !isAdmin && !password && !resolution;

    if (useCache) {
      const cache = thumbPathCache.get(drawingId);
      if (cache?.timestampOrCacheId === cacheId) return cache.url;
    }

    let url = `/${drawingId}.thumbnail.${resolution ? `${resolution}.` : ''}png`;
    let query: string[] = [];
    if (cacheId) query.push(`cacheId=${cacheId}`);
    if (!password) password = getEntityPassword(drawingId);
    if (password) query.push(`password=${password}`);
    if (query) url += `?${query.join('&')}`;

    if (useCache) {
      if (thumbPathCache.size > 1000) thumbPathCache.clear(); // prevent memory leak
      thumbPathCache.set(drawingId, { timestampOrCacheId: cacheId, url });
    }

    return url;
  } else {
    let params: string[] = [];
    password = password ?? getEntityPassword(drawingId);
    if (password) params.push(`pass=${password}`);
    if (forceNoError) params.push('f=1');
    params.push(`t=${cacheId}`);
    return `${apiPath}thumb/${drawingId}.png${(params.length ? `?${params.join('&')}` : '')}`;
  }
}

export function urlToDrawingId(url: string) {
  if (!url) return undefined;
  const root = `${location.protocol}//${location.host}/`;
  if (url.startsWith(root)) url = url.substring(root.length).replace(/^.+\//i, '').replace(/\.[a-z0-9_]+$/i, '');
  if (isValidDrawingId(url)) return url;
  return undefined;
}

let zone: any = undefined;

export function initAngularZone(newZone: any) {
  zone = newZone;
}

export function isInAngularZone() {
  return !!zone?.isInAngularZone();
}
