import { Directive, ElementRef, Input, NgZone } from '@angular/core';

@Directive({ selector: '[syncScroll]' })
export class SyncScroll {
  @Input() topScroll?: HTMLElement;
  @Input() leftScroll?: HTMLElement;
  constructor(element: ElementRef<HTMLElement>, zone: NgZone) {
    zone.runOutsideAngular(() => {
      const e = element.nativeElement;
      e.addEventListener('scroll', () => {
        if (this.topScroll) this.topScroll.scrollTop = e.scrollTop;
        if (this.leftScroll) this.leftScroll.scrollLeft = e.scrollLeft;
      });
    });
  }
}
