import { writeObject, createBinaryWriter, getWriterBuffer as agGetWriterBuffer } from 'ag-sockets/dist/browser';
import { toByteArray } from 'base64-js';
import type { Editor } from '../services/editor';
import { Model } from '../services/model';
import { createRenderingContext, createSoftBrush } from '../services/renderingContext';
import { logAction } from './actionLog';
import { createReader, createWriter, getWriterBuffer, readString, readUint32, writeString, writeUint32 } from './binary';
import { brushBrush, pencilBrush, splotchesBrush } from './brushes';
import { createCanvas, ellipse, getContext2d } from './canvasUtils';
import { BLACK, HARD_BRUSH_THRESHOLD, WHITE } from './constants';
import { apiPath } from './data';
import { faArrowRight, faComment, faCommentAlt, faHeart, faHexagon, faOctagon, faPaw, faStar } from './icons';
import { BrushShape, QuickAction, ShapePath, CustomShape, SvgIconDefinition, BrushToolSettings, CompressedImageData, BitmapData } from './interfaces';
import { PaintBrush } from './paintBrush';
import { fillPath } from './path';
import { delay } from './promiseUtils';
import { setRect } from './rect';
import { getUrl } from './rev';
import { compressRLE, decompressImageAlphaRLE } from './rle';
import { compressBrushData, createBrushShapeImageFromPath, setupBrush } from './tools/brushUtils';
import { isSafari } from './userAgentUtils';
import { decodeString } from './utf8';
import { getPixelRatio, pickValidSize, toBase64Url } from './utils';
import { createViewport } from './viewport';
import { get } from './xhr';

interface Redrawable {
  redraw(): void;
}

const redrawList = new Set<Redrawable>();

function shapesLoaded() {
  if (redrawList.size) {
    const list = Array.from(redrawList);
    redrawList.clear();
    list.forEach(i => i.redraw());
  }
}

export function redrawOnShapeLoaded(obj: Redrawable) {
  redrawList.add(obj);
}

export function removeFromOnShapeLoaded(obj: Redrawable) {
  redrawList.delete(obj);
}

// brush shapes

export const brushShapes: BrushShape[] = [];
export const brushShapesMap = new Map<string, BrushShape>();
export const brushShapesSetsLoaded = new Map<string, BrushShape[]>();

export function addBrushShape(shape: BrushShape) {
  if (!brushShapesMap.has(shape.id)) {
    brushShapes.push(shape);
    brushShapesMap.set(shape.id, shape);
  }
}

addBrushShape({ id: '', name: 'circle' });
addBrushShape(shapeFromBase64('brush', 'brush', brushBrush));
addBrushShape(shapeFromBase64('pencil', 'pencil', pencilBrush));
addBrushShape(shapeFromIcon('paw', 'paw', faPaw));
addBrushShape(shapeFromIcon('heart', 'heart', faHeart));
addBrushShape(shapeFromPath('rect', 'rect', createShapePath(512, 512, 'M 52 52 H 460 V 460 H 52 Z')));
addBrushShape(shapeFromPath('triangle', 'triangle', createShapePath(512, 512, createTrianglePath())));
addBrushShape(shapeFromBase64('splothes', 'splotches', splotchesBrush));

export const DEFAULT_BRUSH_SHAPES = brushShapes.map(b => b.id);

export function writeBrushShapes(shapes: { id: string; imageData: BitmapData; }[]) {
  const writer = createWriter();

  for (const shape of shapes) {
    writeString(writer, shape.id);
    writeUint32(writer, shape.imageData!.width);
    writeUint32(writer, shape.imageData!.height);
    const sizeOffset = writer.offset;
    writeUint32(writer, 0);
    const beforeDataOffset = writer.offset;
    compressRLE(writer, shape.imageData!.data, 3, 4);
    const afterDataOffset = writer.offset;
    writer.offset = sizeOffset;
    writeUint32(writer, afterDataOffset - beforeDataOffset);
    writer.offset = afterDataOffset;
  }

  return getWriterBuffer(writer);
}

export function readBrushShapes(buffer: Uint8Array) {
  const reader = createReader(buffer);
  const shapes: BrushShape[] = [];

  while (reader.offset < reader.buffer.byteLength) {
    const id = readString(reader);
    const width = readUint32(reader);
    const height = readUint32(reader);
    const size = readUint32(reader);
    const compressed = reader.buffer.subarray(reader.offset, reader.offset + size);
    reader.offset += size;
    shapes.push({ id, name: id, imageData: { width, height, compressed } });
  }

  return shapes;
}

export async function loadBrushShapesSet({ model, brushShapes }: Editor, setId: string, onError?: (error: Error) => void) {
  if (TESTS) return;
  if (brushShapesSetsLoaded.has(setId)) return;

  brushShapesSetsLoaded.set(setId, []);

  const buffer = await getWithRetryOrFromSocket<ArrayBuffer>(model, getUrl(`shapes/${setId}.bin`), 'arraybuffer', onError);
  const shapes = readBrushShapes(new Uint8Array(buffer));

  for (const shape of shapes) {
    if (!brushShapesMap.has(shape.id)) {
      // shape.image = createBrushShape(shape.imageData!);
      // shape.imageData = undefined;
      addBrushShape(shape);
    }
  }

  brushShapesSetsLoaded.set(setId, shapes);

  model.tryQuickAction(QuickAction.BrushShapesLoaded, shapes.map(s => s.id));

  const group = brushShapes.find(g => g.id === setId);
  if (group) group.items = shapes;

  shapesLoaded();
  logAction(`loaded brush shapes (${setId})`);
}

export async function initBrushShapes(_onError?: (error: Error) => void) {
  // for (const shape of brushShapes) {
  //   if (!shape.image && shape.imageData) {
  //     shape.image = createBrushShape(shape.imageData); // TODO: do this on-demand instead ? (especially on server)
  //     shape.imageData = undefined;
  //   }
  // }
}

// lasso brush patterns

export const patternShapes: BrushShape[] = [];
export const patternShapesMap = new Map<string, BrushShape>();

function addPattern(pattern: BrushShape) {
  if (!patternShapesMap.has(pattern.id)) {
    patternShapes.push(pattern);
    patternShapesMap.set(pattern.id, pattern);
  }
}

addPattern(shapeFromPath('', 'Solid fill', createShapePath(1, 1, 'M-1 -1h3v3h-3z')));
addPattern(shapeFromPath('checker', 'Checker', createShapePath(2, 2, 'M0 0h1v1h-1zM1 1h1v1h-1z')));
addPattern(shapeFromPath('circle-25', 'Circle 25', circlePattern(25)));
addPattern(shapeFromPath('circle-20', 'Circle 20', circlePattern(20)));
addPattern(shapeFromPath('circle-12', 'Circle 12', circlePattern(12)));
addPattern(shapeFromPath('circle-6', 'Circle 6', circlePattern(6)));
addPattern(shapeFromIcon('faStar', 'Star', faStar));
addPattern(shapeFromIcon('faOctagon', 'Octagon', faOctagon));

// custom shapes

export const shapeShapes: BrushShape[] = [];
export const shapeShapesMap = new Map<string, BrushShape>();
export const shapeSetsLoaded = new Map<string, BrushShape[]>();

export function addShape(shape: BrushShape) {
  if (!shapeShapesMap.has(shape.id)) {
    shapeShapes.push(shape);
    shapeShapesMap.set(shape.id, shape);
  }
}

const defaultShapes = [
  shapeFromIcon('faStar', 'Star', faStar),
  shapeFromIcon('faHexagon', 'Hexagon', faHexagon),
  shapeFromIcon('faOctagon', 'Octagon', faOctagon),
  shapeFromIcon('faComment', 'Dialogue bubble 1', faComment),
  shapeFromIcon('faCommentAlt', 'Dialogue bubble 2', faCommentAlt),
  shapeFromIcon('faHeart', 'Heart', faHeart),
  shapeFromIcon('faArrowRight', 'Arrow right', faArrowRight),
];

defaultShapes.forEach(addShape);

export const DEFAULT_SHAPE_ID = defaultShapes[0].id;
export const DEFAULT_SHAPE_SHAPES = defaultShapes.map(b => b.id);

export async function loadShapesSet(editor: Editor, setId: string, onError?: (error: Error) => void) {
  if (TESTS) return;
  if (shapeSetsLoaded.has(setId)) return;

  const shapes: BrushShape[] = [];
  shapeSetsLoaded.set(setId, shapes);

  const customShapes = await getWithRetryOrFromSocket<CustomShape[]>(editor.model, getUrl(`shapes/${setId}.json`), 'json', onError);

  for (const { id, name, width, height, path, icon } of customShapes) {
    let shape = shapeShapesMap.get(id);

    if (!shape) {
      shape = {
        id,
        name,
        path: createShapePath(width, height, path),
        icon: icon ? createShapePath(icon.width, icon.height, icon.path) : undefined,
      };
      addShape(shape);
    }

    shapes.push(shape);
  }

  editor.model.tryQuickAction(QuickAction.ShapeShapesLoaded, shapes.map(s => s.id));

  const group = editor.shapeShapes.find(g => g.id === setId);
  if (group) group.items = shapes;

  const group2 = editor.shapes.find(g => g.id === setId);
  if (group2) group2.items = shapes.map(({ id, name }) => ({ name, shape: id }));

  shapesLoaded();
  logAction(`loaded shapes (${setId})`);
}

// helpers

export async function initBrushesAndShapes(onError?: (error: Error) => void) {
  await initBrushShapes(onError);
}

export function createShapePath(width: number, height: number, path: string): ShapePath {
  return { width, height, path, cachedPath2D: undefined, cachedParsedPath: undefined };
}

function shapeFromIcon(id: string, name: string, { icon }: SvgIconDefinition): BrushShape {
  if (Array.isArray(icon[4])) throw new Error('Multiple paths not supported');
  return shapeFromPath(id, name, createShapePath(icon[0], icon[1], icon[4]));
}

function shapeFromPath(id: string, name: string, path: ShapePath): BrushShape {
  return { id, name, path };
}

function shapeFromBase64(id: string, name: string, { width, height, data }: { width: number; height: number; data: string; }): BrushShape {
  return { id, name, imageData: { width, height, compressed: toByteArray(data) } };
}

export function createBrushShape({ width, height, compressed }: CompressedImageData) {
  if (width !== height) throw new Error(`Non rectangular brush image size`);

  const canvas = createCanvas(width, height);
  const context = getContext2d(canvas);
  const imageData = decompressImageAlphaRLE(compressed, width, height, WHITE, (w, h) => context.createImageData(w, h));
  context.putImageData(imageData, 0, 0);

  if (width !== 170) {
    const resized = createCanvas(170, 170);
    getContext2d(resized).drawImage(canvas, 0, 0, width, height, 0, 0, resized.width, resized.height);
    return resized;
  }

  return canvas;
}

function createTrianglePath() {
  const cx = 256, cy = 256, arm = 230;
  const dx = arm * Math.cos(Math.PI / 6);
  const dy = arm * Math.sin(Math.PI / 6);
  return `M ${cx} ${cy - arm} L ${cx + dx} ${cy + dy} L ${cx - dx} ${cy + dy} Z`;
}

function circlePattern(r: number): ShapePath {
  const path =
    `M ${25 - r} 25 a ${r} ${r} 0 1 0 ${r * 2} 0 a ${r} ${r} 0 1 0 ${-r * 2} 0z ` +
    `M ${75 - r} 75 a ${r} ${r} 0 1 0 ${r * 2} 0 a ${r} ${r} 0 1 0 ${-r * 2} 0z`;
  return createShapePath(100, 100, path);
}

export async function getWithRetryOrFromSocket<T>(model: Model, url: string, type: XMLHttpRequestResponseType = 'json', _onError?: (error: Error) => void) {
  while (true) {
    try {
      return await getWithRetry<T>(url, type, () => { }, 3);
    } catch (e) {
      if (e.message === 'Reached limit' || e.message?.startsWith('Failed to get file')) {
        try {
          const buffer = await model.getFile(url);
          if (type === 'json') {
            const bytes = new Uint8Array(buffer);
            const text = decodeString(bytes);
            return JSON.parse(text) as T;
          } else if (type === 'arraybuffer') {
            return buffer as any as T;
          } else {
            throw new Error('Invalid response type');
          }
        } catch (e) {
          DEVELOPMENT && console.error(e);
        }
      }
    }
  }
}

export async function getWithRetry<T>(url: string, type: XMLHttpRequestResponseType = 'json', onError?: (error: Error) => void, limit = 1e9) {
  let cacheBust = false;
  let timeout: any = 0;

  while (limit-- > 0) {
    try {
      const result = await get<T>(`${url}${cacheBust ? `?${Date.now()}` : ``}`, type);
      if (!result) throw new Error(`Failed to get file: ${url}`);
      if (result instanceof ArrayBuffer && !result.byteLength) throw new Error(`Failed to get file: ${url}`);
      clearTimeout(timeout);
      return result;
    } catch (e) {
      cacheBust = true;
      DEVELOPMENT && console.error(e);
      if (!timeout && onError) {
        timeout = setTimeout(() => onError(e), 10000);
      }
      await delay(1000);
    }
  }

  clearTimeout(timeout);
  throw new Error('Reached limit');
}

function createCachedGenerator<TKey, TValue>(capacity: number, generate: (key: TKey) => TValue | undefined, getKey: ((key: TKey) => any) = (x => x)) {
  const cacheMap = new Map<any, { value: TValue; last: number; }>();

  return (key: TKey): TValue | undefined => {
    let cacheKey = getKey(key);
    let cache = cacheMap.get(cacheKey);

    if (!cache) {
      const value = generate(key);

      if (value) {
        while (cacheMap.size >= capacity) {
          let minLast = Date.now();
          let minKey: any = undefined;
          cacheMap.forEach((value, key) => {
            if (value.last < minLast) {
              minLast = value.last;
              minKey = key;
            }
          });
          cacheMap.delete(minKey);
        }
        cacheMap.set(cacheKey, cache = { value, last: 0 });
      }
    }

    if (cache) cache.last = Date.now();

    return cache?.value;
  };
}

const BRUSH_IMAGE_CACHE_LIMIT = SERVER ? 10000 : (isSafari ? 5 : 10);
export const getBrushShapeImage = createCachedGenerator<BrushShape, HTMLCanvasElement>(BRUSH_IMAGE_CACHE_LIMIT, shape => {
  if (shape.imageData) {
    return createBrushShape(shape.imageData);
  } else if (shape.path) {
    return createBrushShapeImageFromPath(shape.path);
  } else {
    return undefined;
  }
}, shape => shape.id);

const BRUSH_MIPMAPS_CACHE_LIMIT = SERVER ? 10000 : (isSafari ? 5 : 10);
export const getBrushShapeMipmaps = createCachedGenerator<BrushShape, HTMLCanvasElement[]>(BRUSH_MIPMAPS_CACHE_LIMIT, shape => {
  const image = getBrushShapeImage(shape);
  if (!image) throw new Error('Cannot get brush shape image');

  if (DEVELOPMENT && !SERVER && !TESTS) {
    console.log('Creating mipmaps for', shape.id);
  }

  // TODO: maybe avoid creating mipmaps just for previews
  let last = createCanvas(512, 512);
  getContext2d(last).drawImage(image, 171, 171);

  const mipmaps = [last]; // TODO: we should free these after reaching some limit

  for (let size = last.width; size > 1; size /= 2) {
    const mip = createCanvas(size / 2, size / 2);
    const context = getContext2d(mip);
    context.drawImage(last, 0, 0, last.width, last.height, 0, 0, mip.width, mip.height);
    mipmaps.unshift(mip);
    last = mip;
  }

  return mipmaps;
}, shape => shape.id);

export interface DrawShapeOptions {
  fillStyle?: string | CanvasGradient | CanvasPattern;
}

const DEFAULT_DRAW_SHAPE_OPTIONS: DrawShapeOptions = {
  fillStyle: '#222',
};

export function drawShape(
  canvas: HTMLCanvasElement, shape: BrushShape | undefined, hardness: number, angle: number, isPattern: boolean,
  options: DrawShapeOptions = DEFAULT_DRAW_SHAPE_OPTIONS,
) {
  if (!shape) return false;
  if (!shape.path && !shape.imageData && shape.id) return false; // not loaded yet

  const { width, height } = canvas;
  const context = getContext2d(canvas);
  context.save();
  context.fillStyle = options.fillStyle || DEFAULT_DRAW_SHAPE_OPTIONS.fillStyle!;

  const path = shape.icon ?? shape.path;

  if (path) {
    if (isPattern) {
      const pad = Math.round(width * 0.1);
      context.beginPath();
      context.rect(pad, pad, width - 2 * pad, height - 2 * pad);
      context.clip();
    }

    context.translate(width / 2, height / 2);
    context.rotate(angle);
    context.translate(-width / 2, -height / 2);
    context.translate(0.1 * width, 0.1 * height);
    const aspect = path.width / path.height;

    if (aspect > 1) {
      context.translate(0, 0.4 * height * (1 - path.height / path.width));
      context.scale(width / path.width, width / path.width);
    } else {
      context.translate(0.4 * width * (1 - path.width / path.height), 0);
      context.scale(height / path.height, height / path.height);
    }

    context.scale(0.8, 0.8);

    if (isPattern) {
      context.scale(1 / 3, 1 / 3);
      for (let y = -1; y < 4; y++) {
        for (let x = -1; x < 4; x++) {
          context.save();
          context.translate(x * path.width, y * path.height);
          fillPath(context, path);
          context.restore();
        }
      }
    } else {
      fillPath(context, path);
    }
  } else if (shape.imageData) {
    const pad = 2;
    const image = getBrushShapeImage(shape)!;
    context.translate(width / 2, height / 2);
    context.rotate(angle);
    context.translate(-width / 2, -height / 2);
    context.drawImage(image, 0, 0, image.width, image.height, pad, pad, width - pad * 2, height - pad * 2);
    context.globalCompositeOperation = 'source-atop';
    context.fillRect(0, 0, width, height);
  } else if (hardness > HARD_BRUSH_THRESHOLD) {
    const r = width * 0.4;
    context.beginPath();
    ellipse(context, width / 2, height / 2, r, r);
    context.fill();
  } else {
    const size = Math.round(width * 0.8);
    const canvas = createCanvas(size, size);
    createSoftBrush(canvas, size, hardness, 0x222222ff);
    context.drawImage(canvas, (width - size) / 2, (height - size) / 2);
  }

  context.restore();
  return true;
}

export function drawBrushPreview(canvas: HTMLCanvasElement, tool: BrushToolSettings) {
  const points = [
    20.00, 40.00, 24.09, 36.22, 28.36, 32.89, 32.80, 30.00, 37.42, 27.56, 42.22, 25.56, 47.20, 24.00,
    52.36, 22.89, 57.69, 22.22, 63.20, 22.00, 68.89, 22.22, 74.76, 22.89, 80.80, 24.00, 87.02, 25.56,
    93.42, 27.56, 100.00, 30.00];

  const { width, height } = canvas;
  const scaleX = Math.max(100, width) / 200;
  const scaleY = height / 60;
  const scale = (scaleX + scaleY) * 0.5;
  const maxSize = (scaleX + scaleY) * 20;

  const midX = points[points.length - 2];
  const midY = points[points.length - 1];

  const context = getContext2d(canvas);
  context.clearRect(0, 0, width, height);
  context.save();

  if (false) {
    const ctrl = canvas.width === 200 ?
      [20, 40, 50, 10, 100, 30] :
      [10, 30, 20, 5, 40, 20];
    ctrl.push(ctrl[4] + (ctrl[4] - ctrl[2]), ctrl[5] + (ctrl[5] - ctrl[3]));
    ctrl.push(ctrl[4] + (ctrl[4] - ctrl[0]), ctrl[5] + (ctrl[5] - ctrl[1]));

    for (let i = 0; i < points.length; i += 2) {
      context.fillStyle = 'orange';
      const s = Math.min(1, i / 20) * 3;
      context.beginPath();
      ellipse(context, points[i], points[i + 1], s, s);
      context.fill();
    }

    for (let i = 0; i < points.length; i += 2) {
      const s = Math.min(1, i / 20) * 3;
      context.fillStyle = 'orange';
      context.beginPath();
      ellipse(context, (midX + (midX - points[i])), (midY + (midY - points[i + 1])), s, s);
      context.fill();
    }

    context.strokeStyle = 'red';
    context.moveTo(ctrl[0], ctrl[1]);
    context.quadraticCurveTo(ctrl[2], ctrl[3], ctrl[4], ctrl[5]);
    context.quadraticCurveTo(ctrl[6], ctrl[7], ctrl[8], ctrl[9]);
    context.stroke();

    for (let i = 0; i < ctrl.length; i += 2) {
      context.fillStyle = 'black';
      context.beginPath();
      ellipse(context, ctrl[i], ctrl[i + 1], 1, 1);
      context.fill();
    }
  } else {
    const brush = new PaintBrush(); // TODO: re-use one instance
    const view = createViewport();
    setupBrush(brush, tool, 0x222222ff, 0, BLACK, 0, view);
    setRect(brush.bounds, 0, 0, canvas.width, canvas.height);
    brush.size = Math.min(tool.size || (30 * scale), maxSize);
    brush.context = createRenderingContext(context); // TODO: re-use also
    brush.start(points[0] * scaleX, points[1] * scaleY, 0);

    for (let i = 2; i < points.length; i += 2) {
      const p = Math.min(1, i / 20);
      brush.move(points[i] * scaleX, points[i + 1] * scaleY, p);
    }

    for (let i = points.length - 2; i >= 0; i -= 2) {
      const p = Math.min(1, i / 20);
      const x = midX + (midX - points[i]);
      const y = midY + (midY - points[i + 1]);

      if (i === 0) {
        brush.end(x * scaleX, y * scaleY, p);
      } else {
        brush.move(x * scaleX, y * scaleY, p);
      }
    }

    context.globalCompositeOperation = 'source-in';
    context.fillStyle = '#222222';
    context.fillRect(0, 0, width, height);
    context.globalAlpha = tool.opacity;
    context.globalCompositeOperation = 'destination-in';
    context.fillRect(0, 0, width, height);
  }

  context.restore();
}

export const shapePreviewSizes = [32, 48, 64, 72, 128];
if (DEVELOPMENT) shapePreviewSizes.push(512);

export function getShapePreviewPath(shapeId: string, size: number, pattern: boolean) {
  const actualSize = Math.floor(size * getPixelRatio());
  const validSize = pickValidSize(actualSize, shapePreviewSizes);
  return `${apiPath}${pattern ? 'pattern' : 'shape'}/${validSize}/${shapeId || '-'}.png`;
}

const brushPreviewSizes = [114, 171, 228];
const writer = createBinaryWriter(1024);

export function getBrushPreviewPath(brush: BrushToolSettings, size: number) {
  const data = compressBrushData(brush);
  writer.offset = 0;
  writeObject(writer, data);
  const buffer = agGetWriterBuffer(writer);
  const base64 = toBase64Url(buffer);

  const actualSize = Math.floor(size * getPixelRatio());
  const validSize = pickValidSize(actualSize, brushPreviewSizes);
  return `${apiPath}brush/${validSize}/${base64}.png`;
}
