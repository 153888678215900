import { IFiltersValues } from './interfaces';

function rgbToHsl(r: number, g: number, b: number) {
  r /= 255, g /= 255, b /= 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0, s = 0, l = (max + min) / 2;

  if (max == min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }
    h /= 6;
  }

  return { h, s, l };
}

function hue2rgb(p: number, q: number, t: number) {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
}

function hslToRgb(h: number, s: number, l: number) {
  let r = l, g = l, b = l;

  if (s != 0) {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return { r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255) };
}

export function applyHueSaturationLightness(source: Uint8ClampedArray, destination: Uint8ClampedArray, values: IFiltersValues) {
  let { hue = 0, saturation = 0, lightness = 0 } = values;

  // normalizing the params
  hue /= 360;
  saturation /= 100;
  lightness /= 100;

  for (let index = 0; index < source.length; index += 4) {
    let { h, s, l } = rgbToHsl(source[index], source[index + 1], source[index + 2]);

    // hue
    h += hue;

    // saturation
    s += saturation < 0 ? (s * saturation) : ((1 - s) * saturation);

    let { r, g, b } = hslToRgb(h, s, l);

    // lightness
    if (lightness < 0) {
      r += r * lightness;
      g += g * lightness;
      b += b * lightness;
    } else {
      r += (255 - r) * lightness;
      g += (255 - g) * lightness;
      b += (255 - b) * lightness;
    }

    // populating the destination
    destination[index] = r;
    destination[index + 1] = g;
    destination[index + 2] = b;
    destination[index + 3] = source[index + 3];
  }
}
