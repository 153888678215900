import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { getShapePreviewPath } from '../../../common/shapes';
import { blazeIcon } from '../../../common/icons';

const PLACEHOLDER_IMAGE = '/assets/pixel.png';

@Component({
  selector: 'shape-preview-img',
  template: `<img [src]="src" loading="lazy" [style.width.px]="size" [style.height.px]="size" draggable="false"  class="cursor-pointer">` +
    `<span class="badge badge-pro badge-pro-tool" *ngIf="showPro && !skipBadge">
      <svg-icon [icon]="blazeIcon"></svg-icon>
    </span>`,
  styleUrls: ['shape-preview-img.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.show-pro]': 'showPro',
    '[class.skip-badge]': 'skipBadge',
    '[style.width.px]': 'size',
    '[style.height.px]': 'size',
  }
})
export class ShapePreviewImg implements OnChanges {
  src = PLACEHOLDER_IMAGE;
  readonly blazeIcon = blazeIcon;
  @Input() showPro = false;
  @Input() skipBadge = false;
  @Input() shapeId = '';
  @Input() isPattern = false;
  @Input() size = 43;
  ngOnChanges() {
    this.src = (this.shapeId || this.isPattern) ? getShapePreviewPath(this.shapeId, this.size, this.isPattern) : PLACEHOLDER_IMAGE;
  }
}
