import { Observable } from 'rxjs/internal/Observable';
import { NewThread, Point, Thread, UpdateComment, UpdateThread, Comment, Suggestion } from '../common/interfaces';

export enum ThreadsFilter {
  ALL = 'all',
  UNRESOLVED = 'unresolved'
}
export abstract class IThreadService {
  abstract observeThreads(entityShortId: string): Observable<Thread[]>;
  abstract observeComments(threadShortId: string): Promise<Observable<Comment[]>>;

  abstract getThreads(entityShortId: string): Promise<Thread[]>;

  abstract createThread(newThread: NewThread): Promise<string>;
  abstract updateThread(threadShortId: string, updatedThread: UpdateThread): Promise<void>;
  abstract deleteThread(threadShortId: string): Promise<void>;

  abstract markThreadAsRead(entityShortId: string, threadShortId: string): Promise<void>;
  abstract markAllThreadsAsRead(entityShortId: string): Promise<void>;

  abstract createComment(threadShortId: string, newComment: UpdateComment): Promise<void>;
  abstract updateComment(threadShortId: string, commentShortId: string, updatedComment: UpdateComment): Promise<void>;
  abstract deleteComment(threadShortId: string, commentShortId: string): Promise<void>;

  abstract toggleThreadsVisibility(): void;
  abstract setThreadVisibility(visible: boolean): void;
  abstract getThreadVisibility(): boolean;
  abstract observeThreadsVisibility(): Observable<boolean>;

  abstract setThreadsFilter(filter: ThreadsFilter): void;
  abstract observeThreadsFilter(): Observable<ThreadsFilter>;

  abstract newThread(point: Point | undefined): void;
  abstract onNewThread(): Observable<Point | undefined>;

  abstract getMentionSuggestions(): Promise<Suggestion[]>;
}
