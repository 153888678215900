import { ITool, IToolEditor, IToolModel, TabletEvent, ToolId, hasAltKey, getTabletEventButton, Viewport } from '../interfaces';
import { faSearch } from '../icons';
import { zoomViewportAt } from '../viewport';
import { DRAG_ZOOM_RATIO, CLICK_ZOOM_RATIO } from '../constants';
import { MouseButton } from '../input';
import { logAction } from '../actionLog';

export class ZoomTool implements ITool {
  id = ToolId.Zoom;
  name = 'Zoom';
  description = 'View finer details or get a distanced view of your canvas';
  learnMore = 'https://help.magma.com/en/articles/6871512-zoom';
  video = { url: '/assets/videos/zoom.mp4', width: 374, height: 210 };
  icon = faSearch;
  cursor = 'cursor-zoom-in';
  navigation = true;
  //usesModifiers = true;
  private startX = 0;
  private startY = 0;
  private button = 0;
  private moved = false;
  private viewX = 0;
  private viewY = 0;
  private viewScale = 0;
  constructor(public editor: IToolEditor, public model: IToolModel) {
  }
  hover(_x: number, _y: number, e: TabletEvent) {
    this.cursor = hasAltKey(e) ? 'cursor-zoom-out' : 'cursor-zoom-in';
  }
  start(_x: number, _y: number, _pressure: number, e: TabletEvent) {
    logAction('[local] start: zoom');

    this.startX = e.x;
    this.startY = e.y;
    this.button = getTabletEventButton(e);
    this.moved = false;
    this.viewX = this.editor.view.x;
    this.viewY = this.editor.view.y;
    this.viewScale = this.editor.view.scale;
  }
  move(_x: number, _y: number, _pressure: number, e: TabletEvent) {
    this.innerMove(e);
  }
  end(_x: number, _y: number, _pressure: number, e: TabletEvent) {
    this.innerMove(e);

    if (!this.moved) clickZoom(this.editor.view, this.startX, this.startY, this.button, hasAltKey(e));
  }
  private innerMove(e: TabletEvent) {
    // TODO: snapping to 10% with shift key
    this.moved = this.moved || e.x !== this.startX;
    this.editor.view.x = this.viewX;
    this.editor.view.y = this.viewY;
    this.editor.view.scale = this.viewScale;
    zoomViewportAt(this.editor.view, (e.x - this.startX) * DRAG_ZOOM_RATIO, this.startX, this.startY);
  }
}

export function clickZoom(view: Viewport, startX: number, startY: number, button: number, zoomingOut: boolean) {
  let zoom = CLICK_ZOOM_RATIO *
    (button === MouseButton.Right ? -1 : (button === MouseButton.Left ? 1 : 0)) *
    (zoomingOut ? -1 : 1);

  if (zoom) {
    // TODO: snapping to whole ratios (when pressing shift)
    zoomViewportAt(view, zoom, startX, startY);
  }
}
