import { Directive, ElementRef, Input } from '@angular/core';

// @Directive({
//   selector: '[cssVariable]',
// })
// export class CssVariable {
//   private _value: string | undefined = undefined;
//   @Input() get cssVariable() {
//     return this._value;
//   }
//   set cssVariable(value) {
//     if (this._value !== value) {
//       this.element.nativeElement.style.setProperty();
//     }
//   }
//   constructor(private element: ElementRef<HTMLElement>) {
//   }
// }

@Directive({
  selector: '[userColor]',
})
export class UserColor {
  private _value: string | undefined = undefined;
  @Input() get userColor() {
    return this._value;
  }
  set userColor(value) {
    if (this._value !== value) {
      this.element.nativeElement.style.setProperty('--user-color', value ?? null);
    }
  }
  constructor(private element: ElementRef<HTMLElement>) {
  }
}
