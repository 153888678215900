import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import {
  ITool, IRenderer, ISelectionHelperTool, ILayerTool, RendererSettings, ClientDrawingData, ITabletTool, ApplyFunc,
  Logger, Layer, IToolEditor, ToolId, Viewport, IRendererFactory, IPasteTool, DrawOptions,
  ICanvasProvider, PickColor, QuickAction, IHelpService, User, BrushToolSettings, ToolResourceGroup, BrushShape, IEditorFilter, ViewFilter, ToolSource, FilterNames, LastFilter,
} from '../common/interfaces';
import { findById, includes, invalidEnumReturn } from '../common/baseUtils';
import { LayerTool } from '../common/tools/layerTool';
import { LayerUpdateTool } from '../common/tools/layerUpdateTool';
import { SelectionHelperTool } from '../common/tools/selectionHelperTool';
import { createDrawing, reassignSequenceDrawings } from '../common/drawing';
import { DeleteSelectionTool } from '../common/tools/deleteSelectionTool';
import type { EyedropperTool } from '../common/tools/eyedropperTool';
import { isLayerVisible, layerHasImageData } from '../common/layer';
import { createPoint } from '../common/point';
import { createRect } from '../common/rect';
import { setViewportSize, setViewportContentSize, createViewport } from '../common/viewport';
import { DEFAULT_DRAWING_DATA, GAUSSIAN_BLUR_MAX, HUE_FILTER_MAX, HUE_FILTER_MIN, LIGHTNESS_FILTER_MAX, LIGHTNESS_FILTER_MIN, MB, SATURATION_FILTER_MAX, SATURATION_FILTER_MIN } from '../common/constants';
import { removeAllNodes, removeElement } from '../common/htmlUtils';
import { releaseToolRenderingContext } from '../common/user';
import { logAction, setActionLogEditor, logEvent } from '../common/actionLog';
import { createAllTools } from '../common/tools';
import { toolIdToString } from '../common/toolIdUtils';
import { PasteTool } from '../common/tools/pasteTool';
import { undosLog } from '../common/history';
import { TransformTool } from '../common/tools/transformTool';
import { deserializeMask } from '../common/mask';
import { colorToHSVA, parseColor } from '../common/color';
import { updateTransform } from '../common/toolSurface';
import { DEFAULT_PRIMARY_COLOR, DEFAULT_SECONDARY_COLOR, settingsFilter, settingsMinimumPressure } from '../common/settings';
import { initSettingsOnEditor, loadSettings, scheduleSaveSettings } from './settingsService';
import { resetCursor, moveCursor, createCursor } from './cursor';
import { createTabletEvent, TabletConfig } from './tablet';
import { selectLayer, selectOtherLayer } from './layerActions';
import { ErrorReporter } from './errorReporter';
import { Model } from './model';
import { redraw, redrawDrawing } from './editorUtils';
import { loadWasm } from '../common/wasmUtils';
import { cachePath } from '../common/path';
import {
  brushShapesMap, brushShapesSetsLoaded, DEFAULT_BRUSH_SHAPES, DEFAULT_SHAPE_SHAPES, initBrushesAndShapes,
  loadBrushShapesSet, loadShapesSet, patternShapes, shapeSetsLoaded, shapeShapes, shapeShapesMap
} from '../common/shapes';
import { decompressBrushes } from '../common/tools/brushUtils';
import { ShapeToolSettings } from '../common/tools/baseShapeTool';
import { AiTool } from '../common/tools/aiTool';
import { safeFloat } from '../common/toolUtils';
import { TextTool } from '../common/tools/textTool';
import { GaussianBlurTool } from '../common/tools/gaussianBlurTool';
import { HueSaturationLightnessTool } from '../common/tools/hueSaturationLightnessTool';
import { BrightnessContrastTool } from '../common/tools/brightnessContrastTool';
import { storageGetItem, storageGetJson } from './storage';
import { ToastService } from './toast.service';
import { CurvesTool } from '../common/tools/curvesTool';
import { ITrackService } from './track.service.interface';
import { IFeatureFlagService } from './feature-flag.service.interface';

let reportedWebglError = false;

export class Editor implements IToolEditor, DrawOptions {
  name = 'client';
  type = 'client';
  // colors
  primaryColor = parseColor(DEFAULT_PRIMARY_COLOR);
  primaryColorHue = colorToHSVA(this.primaryColor).h;
  secondaryColor = parseColor(DEFAULT_SECONDARY_COLOR);
  secondaryColorHue = colorToHSVA(this.secondaryColor).h;
  activeColorField: 'primary' | 'secondary' = 'primary';
  // view
  view = createViewport();
  viewFilter: ViewFilter = undefined;
  viewUpdated = new BehaviorSubject<Viewport>(this.view);
  viewLast = createViewport();
  settings: RendererSettings = { // TODO: remove ?
    pixelGrid: true,
    sharpZoom: true,
    background: '#222',
    showCursor: false,
    fadeCursors: true
  };
  renderer: IRenderer = undefined as any;
  element: HTMLElement | undefined = undefined;
  canvas: HTMLCanvasElement | undefined = undefined;
  canvasContainer: HTMLElement | undefined = undefined;
  drawing = createDrawing(DEFAULT_DRAWING_DATA);
  activeFrame = 0;
  events = 0;
  stylus = 'none';
  tabletConfig: TabletConfig = {
    api: 'none',
    isTouchDisabled: () => false,
    minimumPressure: () => 0,
    tabletName: tablet => {
      this.model.server.quickAction(QuickAction.TabletName, tablet);
    },
  };
  lastPresentationViewToMatch: Viewport | undefined = undefined;
  selectionUpdated = new BehaviorSubject<boolean>(false);
  selectionLast = false;
  // bounds
  width = 300;
  height = 200;
  left = 0;
  top = 0;
  boundsChanged = true;
  includeVideos = false;
  onBoundsChanged = new ReplaySubject<{ width: number, height: number, left: number, top: number }>(1);
  dirty = createRect(0, 0, 1e5, 1e5);
  drawingDirty = createRect(0, 0, 1e5, 1e5);
  thumbDirty = createRect(0, 0, 1e5, 1e5);
  cursor = createCursor(); // screen space cursor
  cursorClass = '';
  // last
  lastPoint = createPoint(0, 0);
  lastCursorX = 0;
  lastCursorY = 0;
  lastRedraw = 0;
  showShiftLine = false;
  // tools
  toolSource = ToolSource.None;
  isAltPressed = false;
  tools: ITool[];
  moveTool: ITool;
  layerTool: ILayerTool;
  pasteTool: IPasteTool;
  aiTool: AiTool;
  layerUpdateTool: LayerUpdateTool;
  deleteSelectionTool: DeleteSelectionTool;
  selectionHelperTool: ISelectionHelperTool;
  textTool: TextTool;
  eyedropperTool: EyedropperTool;
  eraserTool: ITool;
  handTool: ITool;
  transformTool: TransformTool;
  gaussianBlurTool: GaussianBlurTool;
  hueSaturationLightnessTool: HueSaturationLightnessTool;
  brightnessContrastTool: BrightnessContrastTool;
  curvesTool: CurvesTool;
  filterCancelled = new Subject<void>();
  // presets
  brushes: ToolResourceGroup<BrushToolSettings>[] = [
  ];
  shapes: ToolResourceGroup<ShapeToolSettings>[] = [
    { id: '', name: 'Basic', items: [...DEFAULT_SHAPE_SHAPES.map(id => shapeShapesMap.get(id)!).map(s => ({ name: s.name, shape: s.id }))] },
  ];
  patterns: ToolResourceGroup<ShapeToolSettings>[] = [
    { id: '', name: '', items: [...patternShapes.map(s => ({ name: s.name, shape: s.id }))] }, // TODO: change to DEFAULT_PATTERN_SHAPES
  ];
  brushShapes: ToolResourceGroup<BrushShape>[] = [
    { id: '', name: '', items: [...DEFAULT_BRUSH_SHAPES.map(id => brushShapesMap.get(id)!)] },
  ];
  shapeShapes: ToolResourceGroup<BrushShape>[] = [
    { id: '', name: '', items: [...shapeShapes] }, // TODO: change to DEFAULT_SHAPE_SHAPES
  ];
  patternShapes: ToolResourceGroup<BrushShape>[] = [
    { id: '', name: '', items: [...patternShapes] }, // TODO: change to DEFAULT_PATTERN_SHAPES
  ];
  // state
  locked = false;
  lockedReason: string | undefined = undefined;
  movingView = false;
  drawingInProgress = false;
  drawingNonCancellable = false;
  drawingEnded = true;
  private _selectedTool: ITool | undefined = undefined;
  private _holdingTool: ITool | undefined = undefined;
  afterFinish: (() => void)[] = [];
  // tool handling
  x = 0; // TODO: remove
  y = 0; // TODO: remove
  p = createPoint(0, 0); // TODO: remove
  ev = createTabletEvent(); // TODO: remove
  tabletTool: ITabletTool | undefined = undefined; // tool or stabilizer used for drawing
  attachLastPt = createPoint(0, 0);
  attachLastSave = false;
  startX = 0;
  startY = 0;
  drawTooFar = false; // indicates if drawing went on long enough to not consider cancelling it for view move by touch
  // TODO: clean all of this mess here
  // last event
  lastMoveFlag = false;
  lastMove = createPoint(0, 0); // TODO: why this one exists ?
  lastMoveX = 0;
  lastMoveY = 0;
  lastMoveXView = 0;
  lastMoveYView = 0;
  lastEventX = 0;
  lastEventY = 0;
  lastPressure = 1;
  // pick color
  pickColor: PickColor = { do: false, x: 0, y: 0, activeLayer: false, secondary: false, alphaTo: '' };
  selectedTool$ = new BehaviorSubject<ITool | undefined>(undefined);
  // filter
  filter: IEditorFilter = {
    activeFilter: '',
    settings: { preview: true, radius: 10 },
  };
  // only used for testing
  throwAfterStart = false;
  constructor(
    public model: Model,
    public canvasProvider: ICanvasProvider,
    public rendererFactory: IRendererFactory,
    public errorReporter: ErrorReporter,
    public helpService: IHelpService,
    public apply: ApplyFunc,
    private toastService: ToastService,
    public track?: ITrackService,
    public logger?: Logger,
    public featureFlags?: IFeatureFlagService
  ) {
    if (DEVELOPMENT && !TESTS) {
      setActionLogEditor(this);
      (window as any).editor = this;
      (window as any).undos = () => undosLog(this.model.user.history);
      (window as any).setView = (view: any) => this.apply(() => Object.assign(this.view, view));
      (window as any).setSelection = (data: any) => deserializeMask(this.model.user.selection, data);
      (window as any).setTransform = (data: any) => {
        const surface = this.model.user.surface;
        surface.translateX = data[0];
        surface.translateY = data[1];
        surface.scaleX = data[2];
        surface.scaleY = data[3];
        surface.rotate = data[4];
        updateTransform(surface);
      };
      (window as any).report = () => {
        const layers = this.drawing.layers;
        const lines: string[] = [];
        let sum = 0, ideal = 0;

        for (const layer of layers) {
          if (!layerHasImageData(layer)) continue;

          const texture = layer.texture as any;
          const sizeInMB = (texture.width * texture.height * 4) / MB;
          const rectInMB = (layer.rect.w * layer.rect.h * 4) / MB;
          sum += sizeInMB;
          ideal += rectInMB;

          lines.push(`#${layer.id.toString().padStart(2, '0')} | ${sizeInMB.toFixed(0).padStart(5)}MB ` +
            `${(100 * rectInMB / sizeInMB).toFixed(0).padStart(5)}%  ${texture.width} x ${texture.height}`);
        }

        lines.push('');
        lines.push(`total: ${sum.toFixed(0)}MB  (ideal: ${ideal.toFixed(0)}MB, ${(100 * ideal / sum).toFixed()}%)`);
        console.log(lines.join('\n'));
      };
    }
    this.model.editor = this;
    this.settings = this.model.settings;
    this.tools = createAllTools(this, model);
    this.textTool = findById(this.tools, ToolId.Text) as TextTool;
    this.textTool.initTool();
    this.selectedTool = findById(this.tools, ToolId.Brush);
    this.eyedropperTool = findById(this.tools, ToolId.Eyedropper) as EyedropperTool;
    this.transformTool = findById(this.tools, ToolId.Transform) as TransformTool;
    this.eraserTool = findById(this.tools, ToolId.Eraser)!;
    this.handTool = findById(this.tools, ToolId.Hand)!;
    this.moveTool = findById(this.tools, ToolId.Move)!;
    if (IS_PORTAL) {
      this.aiTool = findById(this.tools, ToolId.AI) as AiTool;
    } else {
      this.aiTool = new AiTool(this, model); // Mock it so everything doesn't break
    }
    this.layerTool = new LayerTool(this, model);
    this.pasteTool = new PasteTool(this, model);
    this.layerUpdateTool = new LayerUpdateTool(this, model);
    this.deleteSelectionTool = new DeleteSelectionTool(this, model);
    this.selectionHelperTool = new SelectionHelperTool(this, model);
    this.gaussianBlurTool = new GaussianBlurTool(this, model);
    this.hueSaturationLightnessTool = new HueSaturationLightnessTool(this, model);
    this.brightnessContrastTool = new BrightnessContrastTool(this, model);
    this.curvesTool = new CurvesTool(this, model);
    this.tabletConfig.isTouchDisabled = () => {
      const settings = this.model.settings;

      if (settings.disableTouch) return false;

      const touchDrag = settings.touchDrag[0];
      if (touchDrag === 'normal' || touchDrag === 'pan' || touchDrag === 'eraser') return false;

      return true;
    };

    this.tabletConfig.minimumPressure = () => settingsMinimumPressure;

    if (settingsFilter) {
      this.filter = {
        ...this.filter,
        settings: {
          radius: safeFloat(settingsFilter.radius, 0, GAUSSIAN_BLUR_MAX),
          hue: safeFloat(settingsFilter.hue, HUE_FILTER_MIN, HUE_FILTER_MAX),
          saturation: safeFloat(settingsFilter.saturation, SATURATION_FILTER_MIN, SATURATION_FILTER_MAX),
          lightness: safeFloat(settingsFilter.lightness, LIGHTNESS_FILTER_MIN, LIGHTNESS_FILTER_MAX),
          preview: settingsFilter.preview
        },
      };
    }

    if (this.model.settingsInitialized) {
      initSettingsOnEditor(model);
    } else {
      loadSettings(model);
    }

    initEditorBrushesAndShapes(this, model.user);
  }
  hasActiveTool(): boolean {
    return this.aiTool.isActive;
  }
  activeToolLayerIds(): number[] {
    return [this.aiTool.resultLayerId, ...this.aiTool.addedLayerIds];
  }
  toast(callback: (toastService: ToastService) => void) {
    if (this.toastService) {
      this.apply(() => callback(this.toastService));
    }
  }
  // properties
  get users() {
    return this.model.users;
  }
  get activeTool(): ITool | undefined {
    const tool = this.holdingTool ?? this.selectedTool;
    return this.model.user.activeTool ?? ((tool?.altTool && this.isAltPressed) ? this.eyedropperTool : tool);
  }
  set activeTool(value: ITool | undefined) {
    this.selectedTool = value;
  }
  get selectedTool() {
    return this._selectedTool;
  }
  set selectedTool(value) {
    if (this._selectedTool !== value) {
      this._selectedTool?.cancelBeganTool?.();
      this.selectedTool$.next(value);
      this._selectedTool = value;
      redraw(this);
      scheduleSaveSettings(this.model);
    }
  }
  get holdingTool() {
    return this._holdingTool;
  }
  set holdingTool(value) {
    if (this._holdingTool !== value) {
      this._holdingTool = value;
      redraw(this);
    }
  }
  get activeColor() {
    return this.activeColorField === 'primary' ? this.primaryColor : this.secondaryColor;
  }
  set activeColor(value) {
    if (this.activeColorField === 'primary') {
      this.primaryColor = value;
    } else {
      this.secondaryColor = value;
    }

    scheduleSaveSettings(this.model);
  }
  get activeColorHue() {
    return this.activeColorField === 'primary' ? this.primaryColorHue : this.secondaryColorHue;
  }
  set activeColorHue(value) {
    if (this.activeColorField === 'primary') {
      this.primaryColorHue = value;
    } else {
      this.secondaryColorHue = value;
    }
  }
  get activeLayer() {
    return this.model.user.activeLayer;
  }
  selectLayer(layer: Layer | undefined) {
    return selectLayer(this, layer);
  }
  selectOtherLayer(layerId: number) {
    return selectOtherLayer(this, layerId);
  }
  private requestedShapes = new Set<string>();
  requestShapePath(shapeId: string) {
    if (!this.requestedShapes.has(shapeId)) {
      this.requestedShapes.add(shapeId);
      this.model.server.quickAction(QuickAction.RequestShape, shapeId);
    }
  }
  applyNextFrame() {
    if (!SERVER) requestAnimationFrame(this.applyNoop);
  }
  private applyNoop = () => this.apply(noop);
}

function noop() { }

// checks

export function hasLoadedDrawing(editor: Editor): boolean {
  return !!editor.drawing.id && editor.model.loaded === 1 && !editor.model.failed && !!editor.renderer;
}

export function isLoadedConnectedAndNotLocked(editor: Editor) {
  return hasLoadedDrawing(editor) && !editor.locked && editor.model.isConnected && !editor.model.fatalError;
}

export function canEdit(editor: Editor): boolean {
  return isLoadedConnectedAndNotLocked(editor) && !editor.hasActiveTool() && !isFilterActive(editor);
}

export function canDisownLayer(editor: Editor): boolean {
  return isLoadedConnectedAndNotLocked(editor) && !isFilterActive(editor);
}

export function canEditLayer(editor: Editor, layer: Layer | undefined): layer is Layer {
  return canEdit(editor) && !editor.drawingInProgress &&
    isMyLayer(editor, layer) && includes(editor.drawing.layers, layer);
}

export function canEditLayerForFilterSave(editor: Editor, layer: Layer | undefined): layer is Layer {
  return isLoadedConnectedAndNotLocked(editor) && !editor.drawingInProgress &&
    isMyLayer(editor, layer) && includes(editor.drawing.layers, layer);
}

export function canDrawOnLayer(editor: Editor, layer: Layer | undefined): layer is Layer {
  return canEditLayer(editor, layer) && !layer.locked;
}

// TODO: merge with canEditLayer and canDrawOnLayer ???
export function canDrawOnActiveLayer(editor: Editor): boolean {
  const layer = editor.activeLayer;

  return canEdit(editor) && !!layer && !layer.locked && isLayerVisible(layer) && layer.opacity !== 0 &&
    includes(editor.drawing.layers, layer);
}

export function isMyLayer(editor: Editor, layer: Layer | undefined): layer is Layer {
  return layer?.owner === editor.model.user;
}

export function isFilterActive(editor: Editor) {
  return !!editor.filter?.activeFilter;
}

export function isLastFilterExist() {
  return !!storageGetItem('settings-last-filter');
}

export function getLastFilterSettings() {
  return storageGetJson<LastFilter>('settings-last-filter');
}

// other

export function init(editor: Editor, element: HTMLElement | undefined, canvasContainer: HTMLElement | undefined) {
  logEvent('init(editor)');
  editor.cursorClass = '';
  editor.element = element;
  editor.canvasContainer = canvasContainer;
  updateBounds(editor);
  setupEditorCanvas(editor);
}

export function release(editor: Editor) {
  logEvent('release(editor)');
  editor.element = undefined;
  editor.canvasContainer = undefined;
  if (editor.canvas) removeElement(editor.canvas);
  editor.canvas = undefined;
}

export function reset(editor: Editor) {
  logEvent('reset(editor)');
  editor.afterFinish.length = 0;
  setDrawing(editor, DEFAULT_DRAWING_DATA);
}

export function setDrawing(editor: Editor, data: ClientDrawingData) {
  try {
    logEvent(`setDrawing (id: ${data.id}, width: ${data.width}, height: ${data.height})`);

    editor.renderer?.releaseDrawing(editor.drawing);

    if (editor.model.contextLost || !editor.canvas) {
      editor.renderer?.release();
      editor.renderer = undefined as any;
    }

    // defensive clears
    editor.tabletTool?.cancel?.();
    editor.activeTool?.cancel?.();
    editor.textTool?.restoreInitialState();

    // TODO: make filter propertly re-apply on reconnect
    editor.filter.activeFilter = '';

    if (!TESTS && (editor.drawing.width !== data.width || editor.drawing.height !== data.height)) {
      editor.canvasProvider.clear();
    }

    const drawing = createDrawing(data);
    reassignSequenceDrawings(drawing, editor.drawing);
    editor.drawing = drawing;

    if (!editor.renderer) {
      logEvent('create renderer');
      const { renderer, error } = editor.rendererFactory.createRenderer('Editor', editor.canvasProvider, editor.drawing, editor.errorReporter, editor.logger);
      logEvent(`created renderer (renderer: ${renderer?.name}, error: ${error})`);

      editor.model.contextLost = false;
      editor.renderer = renderer;
      editor.canvas = renderer.canvas;

      editor.canvas?.addEventListener?.('webglcontextlost', _e => {
        // e.preventDefault();
        DEVELOPMENT && console.warn('Context lost');
        logEvent('context lost');
        editor.errorReporter.disable();
        editor.model.contextLost = true;
        lockEditor(editor, 'context lost');
      });

      editor.canvas?.addEventListener?.('webglcontextrestored', () => {
        DEVELOPMENT && console.warn('Context restored');
        logEvent('context restored');
        // can't restore webgl context because there's no way to restore the layer data
        // if (editor.webgl) editor.webgl = initWebGLResources(editor.webgl.gl, editor.paletteManager, editor.camera);
      });

      setupEditorCanvas(editor);

      if (error && !reportedWebglError) {
        reportedWebglError = true;
        editor.model.server.quickAction(QuickAction.WebGLFailed, error);
      }
    } else {
      logEvent(`init renderer`);
      editor.renderer.init(editor.drawing);
    }

    editor.aiTool?.resetSettings?.();

    setViewportContentSize(editor.view, editor.drawing.width, editor.drawing.height);
    redrawDrawing(editor);
    redraw(editor);
    resetCursor(editor.cursor);
    logEvent('setDrawing done');
  } catch (e) {
    logAction(`setDrawing failed (error: ${e?.message}, stack: ${e?.stack})`);
    throw e;
  }
}

function setupEditorCanvas(editor: Editor) {
  if (editor.canvasContainer) {
    removeAllNodes(editor.canvasContainer);
    if (editor.canvas) editor.canvasContainer.appendChild(editor.canvas);
  }
}

export function updateBounds(editor: Editor) {
  if (!editor.element) return;

  const { left, top, width, height } = editor.element.getBoundingClientRect();

  editor.left = left;
  editor.top = top;

  if (editor.width !== width || editor.height !== height) {
    editor.width = width;
    editor.height = height;

    logAction(`[local] update bounds (${left}, ${top}, ${width}, ${height}, ${editor.drawingInProgress})`);

    // TODO: remove ? we're doing it in update anyway
    if (!editor.drawingInProgress) {
      setViewportSize(editor.view, editor.width, editor.height);
      moveCursor(editor.cursor, editor.lastCursorX - left, editor.lastCursorY - top);
      redraw(editor);
    }
  }

  editor.boundsChanged = false;
  editor.onBoundsChanged.next({ width, height, left, top });
}

export function cancelToolInternal(editor: Editor, tool: ITool, user: User, useTabletTool: boolean) {
  if (useTabletTool && editor.tabletTool) {
    editor.tabletTool.cancel?.();
  } else {
    tool.cancel?.();
  }

  if (user.surface.context) releaseToolRenderingContext(user);

  // move and transform tools shouldn't release user canvas
  if (!tool.cancellingKeepsSurface) editor.renderer?.releaseUserCanvas(user);

  user.history.unpre();
}

export function cancelTool(editor: Editor, message: string) {
  if (DEVELOPMENT && editor.drawingNonCancellable) throw new Error('Cancelling non-cancellable drawing');
  if (editor.drawingNonCancellable) return;

  const user = editor.model.user;
  const tool = user.activeTool;

  logAction(`[local] cancel tool (${tool ? toolIdToString(tool.id) : 'none'}, by ${message})`);

  if (tool && !tool.nonDrawing && !tool.navigation) {
    cancelToolInternal(editor, tool, user, true);
    if (!tool.cancellableLocally) editor.model.cancelTool(message);
    redrawDrawing(editor);
  }

  user.activeTool = undefined;

  editor.drawingEnded = true;
  editor.drawingInProgress = false;
  editor.tabletTool = undefined;
}

export function lockEditor(editor: Editor, reason: string) {
  editor.locked = true;
  editor.lockedReason = reason;
}

export function unlockEditor(editor: Editor) {
  if (editor.locked) {
    editor.locked = false;
    editor.lockedReason = undefined;
    redrawDrawing(editor);
    redraw(editor);
    // TODO: end/cancel active tool ?
  }
}

export function switchColors(editor: Editor) {
  [editor.primaryColor, editor.secondaryColor] = [editor.secondaryColor, editor.primaryColor];
  [editor.primaryColorHue, editor.secondaryColorHue] = [editor.secondaryColorHue, editor.primaryColorHue];
  if (editor.textTool.isUsingTextTool) editor.textTool.setColor(editor.activeColor);
  scheduleSaveSettings(editor.model);
}

export function initTools(errorReporter?: ErrorReporter, fallbackFetch?: (url: string) => Promise<ArrayBuffer>) {
  void loadWasm(fallbackFetch);
  initBrushesAndShapes(e => errorReporter?.reportError('initShapes failed', e))
    .then(() => {
      for (const { path } of shapeShapes) {
        if (path) {
          setTimeout(() => cachePath(path), 1000);
        }
      }
    })
    .catch(e => DEVELOPMENT && console.error(e));
}

export function initEditorBrushesAndShapes(editor: Editor, user: User, onError?: (error: Error) => void) {
  const { brushSets = [], brushShapeSets = [], shapeSets = [] } = user;

  editor.brushes = [
    ...brushSets.map(set => findById(editor.brushes, set.id) ?? {
      id: set.id,
      name: set.name,
      info: set.info,
      items: decompressBrushes(set.brushes),
    }),
  ];

  editor.brushShapes = [
    editor.brushShapes[0], // default brush shapes
    ...brushShapeSets.map(set => findById(editor.brushShapes, set.id) ?? {
      id: set.id,
      name: set.name,
      info: set.info,
      items: brushShapesSetsLoaded.get(set.id) ?? [],
    }),
  ];

  for (const set of brushShapeSets) {
    if (set.id) void loadBrushShapesSet(editor, set.id, onError);
  }

  editor.shapeShapes = [
    editor.shapeShapes[0], // default shapes
    ...shapeSets.map(set => findById(editor.shapeShapes, set.id) ?? {
      id: set.id,
      name: set.name,
      info: set.info,
      items: shapeSetsLoaded.get(set.id) ?? [],
    }),
  ];

  editor.shapes = [
    editor.shapes[0], // default shapes
    ...shapeSets.map(set => findById(editor.shapes, set.id) ?? {
      id: set.id,
      name: set.name,
      info: set.info,
      items: (shapeSetsLoaded.get(set.id) ?? []).map(({ id, name }) => ({ name, shape: id })),
    }),
  ];

  for (const set of shapeSets) {
    if (set.id) void loadShapesSet(editor, set.id, onError);
  }

  // TODO: patterns
}

export function getActiveFilterTool(editor: Editor) {
  return getFilterByName(editor, editor.filter.activeFilter);
}

export function getFilterByName(editor: Editor, name: FilterNames) {
  switch (name) {
    case 'gaussianBlur': return editor.gaussianBlurTool;
    case 'hueSaturationLightness': return editor.hueSaturationLightnessTool;
    case 'brightnessContrast': return editor.brightnessContrastTool;
    case 'curves': return editor.curvesTool;
    case '': return undefined;
    default: return invalidEnumReturn(name, undefined);
  }
}
