import { readPsd, Layer as PsdLayer, Psd } from 'ag-psd';
import { DEFAULT_DPI, LAYER_MODES } from '../common/constants';
import { ClientDrawingData, LayerData, LayerFlag, LayerMode } from '../common/interfaces';
import { createLayer } from '../common/layer';
import { copyRect } from '../common/rect';
import { includes } from '../common/baseUtils';
import { cropPsdLayerImageData, flattenPsdLayers, getSingleColor } from '../common/psdHelpers';

export interface ImportPsdResult {
  drawing: ClientDrawingData;
  missingFeatures: string[];
}

export function importPsd(buffer: Uint8Array, saveImage: (data: ImageData) => string): ImportPsdResult {
  const psd = readPsd(buffer, { skipThumbnail: true, useImageData: true, skipLinkedFilesData: true });
  const features = new Set<string>();
  const drawing: ClientDrawingData = {
    _id: '',
    id: '',
    name: '',
    width: psd.width,
    height: psd.height,
    layers: [],
    background: undefined,
    dpi: DEFAULT_DPI,
  };

  const res = psd.imageResources;

  if (res?.gridAndGuidesInformation) features.add('guides');

  if (res?.resolutionInfo) {
    drawing.dpi = res?.resolutionInfo.horizontalResolution;

    if (res?.resolutionInfo.horizontalResolutionUnit === 'PPCM') {
      drawing.dpi = Math.round((drawing.dpi * 2.54) * 100) / 100;
    }
  }

  const { layers, background } = flattenPsdLayers(psd);

  if (background) drawing.background = background;
  if (psd.children?.some(child => child.children?.length)) features.add('layerGroup');

  for (const psdLayer of layers) {
    let newLayerId = psdLayer.id || 1;
    while (drawing.layers.some(l => l.id === newLayerId)) newLayerId++;
    const layer = createLayerFromPsdLayer(newLayerId, psdLayer);

    const cropped = cropPsdLayerImageData(psdLayer, drawing);
    if (cropped && cropped.rect.w && cropped.rect.h) {
      copyRect(layer.rect, cropped.rect);
      layer.image = saveImage(cropped.imageData);
    }

    if (psdLayer.adjustment) features.add(`adjustment:${psdLayer.adjustment.type}`);
    if (psdLayer.artboard) features.add('artboard');
    if (psdLayer.effects && !psdLayer.effects.disabled) {
      if (psdLayer.effects.dropShadow?.some(e => e.enabled)) features.add('effects:dropShadow');
      if (psdLayer.effects.innerShadow?.some(e => e.enabled)) features.add('effects:innerShadow');
      if (psdLayer.effects.outerGlow?.enabled) features.add('effects:outerGlow');
      if (psdLayer.effects.innerGlow?.enabled) features.add('effects:innerGlow');
      if (psdLayer.effects.bevel?.enabled) features.add('effects:bevel');
      if (psdLayer.effects.solidFill?.some(e => e.enabled)) features.add('effects:solidFill');
      if (psdLayer.effects.satin?.enabled) features.add('effects:satin');
      if (psdLayer.effects.stroke?.some(e => e.enabled)) features.add('effects:stroke');
      if (psdLayer.effects.gradientOverlay?.some(e => e.enabled)) features.add('effects:gradientOverlay');
      if (psdLayer.effects.patternOverlay?.enabled) features.add('effects:patternOverlay');
    }
    if (psdLayer.filterMask) features.add('filterMask');
    if (psdLayer.layerColor && psdLayer.layerColor !== 'none') features.add('layerColor');
    if (psdLayer.mask) features.add('mask');
    if (psdLayer.patterns) features.add('patterns');
    if (psdLayer.placedLayer) features.add('placedLayer');
    if (psdLayer.text) features.add('text');
    if (psdLayer.vectorMask) features.add('vectorMask');
    if (psdLayer.vectorStroke || psdLayer.vectorFill) features.add('vector');

    if (psdLayer.adjustment) continue; // skip adjustment layers

    layer.flags = LayerFlag.External;

    drawing.layers.push(layer);
  }

  return { drawing, missingFeatures: Array.from(features) };
}

export function createLayerFromPsdLayer(layerId: number, psdLayer: PsdLayer) {
  const layer = createLayer(layerId);
  Object.assign(layer, psdLayerData(psdLayer));
  return layer;
}

export function psdLayerData(psdLayer: PsdLayer): Omit<LayerData, 'id'> {
  return {
    name: psdLayer.name,
    mode: (psdLayer.blendMode && includes(LAYER_MODES, psdLayer.blendMode as any) ? psdLayer.blendMode as LayerMode : undefined) ?? 'normal',
    visible: !psdLayer.hidden,
    opacity: psdLayer.opacity ?? 1,
    opacityLocked: !!(psdLayer.transparencyProtected && psdLayer.protected?.transparency),
    clippingGroup: !!psdLayer.clipping,
    locked: !!(psdLayer.transparencyProtected && !psdLayer.protected?.transparency),
  };
}

export function readAndFlattenPsdLayers(data: Uint8Array): PsdLayer[] {
  const psd = readPsd(data, { skipCompositeImageData: true, skipLinkedFilesData: true, skipThumbnail: true, useImageData: true });
  const psdLayers: PsdLayer[] = [];

  function processLayer(psdLayer: Psd | PsdLayer) {
    if (psdLayer.children) {
      psdLayer.children.forEach(processLayer);
    } else {
      psdLayers.push(psdLayer);
    }
  }

  processLayer(psd);

  const bgLayer = psdLayers[0];
  let bgColor: number | undefined = undefined;

  if (
    bgLayer && bgLayer.imageData && bgLayer.transparencyProtected &&
    bgLayer.imageData.width === psd.width && bgLayer.imageData.height === psd.height
  ) {
    bgColor = getSingleColor(bgLayer.imageData);
    if (bgColor !== undefined) psdLayers.shift();
  }

  // if (syncExistingLayers && bgColor !== undefined) {
  // TODO: check for permission to update drawing data
  // drawing.background = `#${colorToHexRGB(bgColor)}`;
  // editor.model.updateDrawing({ background: drawing.background });
  // }

  return psdLayers;
}
