import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { faAngleDown } from '../../../generated/fa-icons';

export interface SettingsSelectGroup {
  name: string;
  items: SettingsSelectItem[];
}

export interface SettingsSelectItem {
  value: any;
  label: string;
  description?: string;
}

@Component({
  selector: 'settings-select',
  templateUrl: 'settings-select.pug',
  styleUrls: ['settings-select.scss'],
})
export class SettingsSelect {
  readonly caretIcon = faAngleDown;
  @Input() value: any | undefined = undefined;
  @Output() valueChange = new EventEmitter<any | undefined>();
  @Input() items: SettingsSelectGroup[] = [];
  @Input() disabled = false;
  @Input() small = false;
  constructor(private element: ElementRef<HTMLElement>) {
  }
  get label() {
    for (const group of this.items) {
      for (const item of group.items) {
        if (item.value === this.value) {
          return item.label;
        }
      }
    }
    return undefined;
  }
  get dropup() {
    const rect = this.element.nativeElement.getBoundingClientRect();
    return rect.top > (window.innerHeight - rect.bottom);
  }
  select(value: any | undefined) {
    this.value = value;
    this.valueChange.emit(value);
  }
}
