import { Directive, Input, ElementRef } from '@angular/core';
import { getUrl } from '../../../common/rev';

@Directive({ selector: '[revSrc]' })
export class RevSrc {
  constructor(private element: ElementRef<HTMLImageElement>) {
  }
  @Input() set revSrc(value: string | undefined) {
    this.element.nativeElement.src = value ? getUrl(value) : '';
  }
}
