import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppNotification, AppNotificationAction, AppNotificationSettingsOption, AppNotificationStatus, AppNotificationTeamCounter, NOTIFICATION_ARTDESK_ID } from 'magma/common/interfaces';
import { IAppNotificationService } from 'magma/services/app-notification.service.interface';
import { BehaviorSubject, defer, NEVER } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { TeamsQuery } from './team.query';
import { RpcService } from './rpc.service';
import { UserService } from './user.service';
import { REMOVED_USER_NAME } from 'magma/common/constants';
import { REMOVED_ENTITY_NAME } from 'shared/utils';
import { escape } from 'lodash';

const NOTIFICATION_PAGE_SIZE = 20;

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AppNotificationService implements IAppNotificationService {
  notifications$ = new BehaviorSubject<AppNotification[]>([]);
  private teamNotifications = new Map<string, BehaviorSubject<AppNotification[]>>(new Map());
  notificationCounters$ = new BehaviorSubject<Map<string, number>>(new Map());

  newNotifications$ = this.rpc.isConnected$.pipe(switchMap(async (status) => {
    if (status) {
      return defer(async () => this.observeNotifications()).pipe(switchMap(o => o));
    } else {
      return NEVER;
    }
  }));

  constructor(private rpc: RpcService, private teamQuery: TeamsQuery, private userService: UserService) {
    this.userService.user$.pipe(map(u => u?._id), distinctUntilChanged(), switchMap(user => {
      if (user) {
        this.updateNotificationCounters().catch(e => DEVELOPMENT && console.error(e));
        return this.newNotifications$.pipe(switchMap(o => o));
      } else {
        this.notificationCounters$.next(new Map());
        this.notifications$.next([]);
        this.teamNotifications.clear();
        return NEVER;
      }
    }), untilDestroyed(this)).subscribe(notification => {
      this.handleNewNotification(notification);
    });

    this.teamQuery.selectAll().pipe(map(teams => teams.length), distinctUntilChanged()).subscribe(async (len) => {
      if (len > 0) {
        await this.updateNotificationCounters();
      }
    });
  }

  private handleNewNotification(notification: AppNotification) {
    const list = this.notifications$.getValue();

    const index = list.findIndex(n => n.shortId === notification.shortId);
    if (index > -1) {
      list[index] = this.mapNotification(notification);
      this.notifications$.next(list);
    } else {
      list.push(this.mapNotification(notification));
      this.notifications$.next(list);
    }

    const subject = this.teamNotifications.get(notification.team?._id ?? NOTIFICATION_ARTDESK_ID)!;
    if (subject) {
      const teamList = subject.getValue();

      const teamIndex = teamList.findIndex(n => n.shortId === notification.shortId);
      if (teamIndex > -1) {
        teamList[teamIndex] = this.mapNotification(notification);
        subject.next(teamList);
      } else {
        teamList.push(this.mapNotification(notification));
        subject.next(teamList);
      }
    }

    this.updateNotificationCounters().catch(e => DEVELOPMENT && console.error(e));
  }

  observeTeamNotifications(teamId: string) {
    let subject = this.teamNotifications.get(teamId);
    if (!subject) {
      subject = new BehaviorSubject<AppNotification[]>([]);
      this.teamNotifications.set(teamId, subject);

      this.getNotifications(teamId, 0, NOTIFICATION_PAGE_SIZE).then(notifications => {
        subject!.next(notifications);
      }).catch(e => DEVELOPMENT && console.error(e));
    }

    return subject.asObservable();
  }

  async updateNotificationCounters() {
    const res = await this.getNotificationTeamCounters();
    this.notificationCounters$.next(new Map(res.map(counter => [counter.teamId, counter.unread])));
  }

  async loadMore(skip: number, teamId: string | null): Promise<number> {
    const notifications = await this.getNotifications(teamId, skip);

    if (teamId && this.teamNotifications.has(teamId)) {
      const list = this.teamNotifications.get(teamId)!.getValue();
      list.push(...notifications.map(n => this.mapNotification(n)));
      this.teamNotifications.get(teamId)!.next(list);
    } else {
      const list = this.notifications$.getValue();
      list.push(...notifications.map(n => this.mapNotification(n)));
      this.notifications$.next(list);
    }

    return notifications.length;
  }

  hasUnread(teamId: string | null): Promise<boolean> {
    return this.rpc.notifications.hasUnread(teamId);
  }

  private generateNotificationContent(notification: AppNotification, forEmail: boolean): { title: string, bodyHTML: string } {
    const { user } = notification.trigger;
    const teamName = notification.team?.name;
    const userName = user ? user.name : REMOVED_USER_NAME;
    const action = notification.trigger.action;
    switch (action) {
      case AppNotificationAction.CommentAdded: {
        const comment = notification.trigger.data.comment;
        const entity = notification.trigger.entity;
        const entityName = entity?.name ?? REMOVED_ENTITY_NAME;
        return {
          title: `${userName} left a comment on ${entityName}`,
          bodyHTML: `<strong>${escape(userName)}</strong> left a comment on <strong>${escape(entityName)}</strong>`
            + `:${forEmail ? '<br><br>' : ' '}${escape(comment)}`,
        };
      }
      case AppNotificationAction.CommentMentioned: {
        const comment = notification.trigger.data.comment;
        const entity = notification.trigger.entity;
        const entityName = entity?.name ?? REMOVED_ENTITY_NAME;
        return {
          title: `${userName} mentioned you in a comment on ${entityName}`,
          bodyHTML: `<strong>${escape(userName)}</strong> mentioned you in a comment on `
            + `<strong>${escape(entityName)}</strong>: ${forEmail ? '<br><br>' : ' '}${escape(comment)}`,
        };
      }
      case AppNotificationAction.CanvasChanged: {
        const entity = notification.trigger.entity;
        const entityName = entity?.name ?? REMOVED_ENTITY_NAME;
        return {
          title: `${userName} made changes to ${entityName}`,
          bodyHTML: `<strong>${escape(userName)}</strong> made changes to <strong>${escape(entityName)}</strong>`,
        };
      }
      case AppNotificationAction.UserJoinedTeam:
        return {
          title: `${userName} has just joined the artspace!`,
          bodyHTML: `<strong>${escape(userName)}</strong> has just joined the artspace!`,
        };
      case AppNotificationAction.UserJoinedTeamLimitReached:
        return {
          title: `${userName} has just joined the artspace!`,
          bodyHTML: `<strong>${escape(userName)}</strong> has just joined the artspace!<br>` +
            `<strong>You've reached limit of users on your current plan.</strong><br>` +
            `If you invite any more users, they won't be able to access any projects or drawings until you upgrade or switch your artspace to the public mode.`,
        };
      case AppNotificationAction.UserJoinedTeamLimitExceeded:
        return {
          title: `${userName} has just joined the artspace!`,
          bodyHTML: `<strong>${escape(userName)}</strong> has just joined the artspace!<br>` +
            `<strong>You've exceeded limit of users on your current plan.</strong><br>` +
            `${escape(userName)} won't be able to access any projects or drawings until you upgrade plan or switch your artspace to the public mode.`,
        };
      case AppNotificationAction.TeamMarkedForDeletion:
        return {
          title: `${userName} marked ${teamName} for deletion`,
          bodyHTML: `<strong>${escape(userName)}</strong> marked <strong>${escape(teamName)}</strong> for deletion`,
        };
      case AppNotificationAction.TeamUnmarkedForDeletion:
        return {
          title: `${userName} cancelled ${teamName} deletion`,
          bodyHTML: `<strong>${escape(userName)}</strong> cancelled <strong>${escape(teamName)}</strong> deletion`,
        };
      default:
        throw new Error(`Missing content for action: ${action}`);
    }
  }

  private mapNotification(n: AppNotification) {
    return { ...n, generatedContent: this.generateNotificationContent(n, false).bodyHTML };
  }

  async markAllAsRead(teamId: string | null): Promise<void> {
    await this.rpc.notifications.markAllAsRead(teamId);

    const notifications = await this.getNotifications(null);
    this.notifications$.next(notifications);

    if (teamId) {
      const teamNotifications = await this.getNotifications(teamId);
      this.teamNotifications.get(teamId)?.next(teamNotifications);
    }
    this.updateNotificationCounters().catch(e => DEVELOPMENT && console.error(e));
  }

  async markUnreadAsViewed(teamId: string, notificationIds: string[]): Promise<void> {
    await this.rpc.notifications.markUnreadAsViewed(notificationIds);
    const notifications = await this.getNotifications(null);
    this.notifications$.next(notifications);

    if (teamId) {
      const teamNotifications = await this.getNotifications(teamId);
      this.teamNotifications.get(teamId)?.next(teamNotifications);
    }
    this.updateNotificationCounters().catch(e => DEVELOPMENT && console.error(e));
  }

  observeNotifications(): Promise<Observable<AppNotification>> {
    return this.rpc.notifications.observeNotifications();
  }

  async getNotifications(teamId: string | null, skip = 0, limit = NOTIFICATION_PAGE_SIZE): Promise<AppNotification[]> {
    const notifications = await this.rpc.notifications.getNotifications(teamId ?? '', skip, limit);
    return notifications.map(n => this.mapNotification(n));
  }

  updateNotificationStatus(shortId: string, status: AppNotificationStatus): Promise<void> {
    return this.rpc.notifications.updateNotificationStatus(shortId, status);
  }

  updateNotificationSettings(entityId: string, setting: AppNotificationSettingsOption): Promise<void> {
    return this.rpc.notifications.updateNotificationSettings(entityId, setting);
  }

  getNotificationSettings(entityId: string): Promise<AppNotificationSettingsOption> {
    return this.rpc.notifications.getNotificationSettings(entityId);
  }

  getNotificationTeamCounters(): Promise<AppNotificationTeamCounter[]> {
    return this.rpc.notifications.getNotificationTeamCounters();
  }
}
