export class YoutubeApi {
  /**
   * @param ytUrl - url in format: https://www.youtube.com/watch?v=dQw4w9WgXcQ
   */
  static getVideoId(ytUrl: string) {
    const url = new URL(ytUrl);
    return new URLSearchParams(url.search).get('v');
  }

  /**
   * @param videoId - video id (obtained from v query parameter when grabbing link)
   * @param thumbNr - each video usually has up to 4 thumbnails
   */
  static getThumbnailUrl(videoId: string, thumbNr = 0) {
    return `https://img.youtube.com/vi/${videoId}/${thumbNr}.jpg`;
  }

  static getEmbeddedVideoUrl(videoId: string, autoplay = false) {
    const url = new URL(`https://www.youtube-nocookie.com/embed/${videoId}`);
    if (autoplay) url.searchParams.append('autoplay', '1');
    return url.toString();
  }
}
