import { Injectable } from '@angular/core';
import { ALERT_MSG, ERROR_MSG, INFO_MSG, NOTIFICATION_MSG, SUCCESS_MSG, WARNING_MSG } from 'components/toasts/messages';
import { IMsg } from 'magma/common/interfaces';
import { ToastService } from 'magma/services/toast.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { ITrackService } from 'magma/services/track.service.interface';

@Injectable({ providedIn: 'root' })
export class PortalToastService implements ToastService {
  private toasts$ = new BehaviorSubject<IMsg[]>([]);
  readonly loadingSwitchDelay = 500;

  constructor(private trackService: ITrackService) { }

  push(toast: IMsg): IMsg {
    toast.onUpdate = new Subject();
    this.toasts$.next([...this.toasts$.value, toast]);
    return toast;
  }

  updateToSuccess(toast: IMsg, update: Partial<IMsg>) {
    setTimeout(() => {
      this.update(toast, { ...SUCCESS_MSG, ...update });
    }, this.loadingSwitchDelay);
  }

  updateToError(toast: IMsg, update: Partial<IMsg>) {
    setTimeout(() => {
      this.update(toast, { ...ERROR_MSG, ...update });
    }, this.loadingSwitchDelay);
  }

  private update(toast: IMsg, update: Partial<IMsg>) {
    Object.assign(toast, update);
    toast.onUpdate?.next();
  }

  getToasts() {
    return this.toasts$.asObservable();
  }

  dismiss(toast: IMsg) {
    this.toasts$.next([...this.toasts$.value.filter(t => t !== toast)]);
  }

  loading(toast: Partial<IMsg>): IMsg {
    return this.push({ ...INFO_MSG, icon: 'spinner', timeout: 0, closable: false, ...toast });
  }

  success(toast: Partial<IMsg>): IMsg {
    return this.push({ ...SUCCESS_MSG, ...toast });
  }

  error(toast: Partial<IMsg>): IMsg {
    return this.push({ ...ERROR_MSG, ...toast });
  }

  info(toast: Partial<IMsg>): IMsg {
    return this.push({ ...INFO_MSG, ...toast });
  }

  warning(toast: Partial<IMsg>): IMsg {
    return this.push({ ...WARNING_MSG, ...toast });
  }

  alert(toast: Partial<IMsg>): IMsg {
    return this.push({ ...ALERT_MSG, ...toast });
  }

  notification(toast: Partial<IMsg>): IMsg {
    return this.push({ ...NOTIFICATION_MSG, ...toast });
  }
}
