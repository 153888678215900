import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { getRoleIcon, getRoleTitle } from '../../../common/userRole';
import { User, UserDataLive } from '../../../common/interfaces';
import { emptyIcon, faCheck, faMicrophoneSlash, faPhone } from '../../../common/icons';
import { VoiceChatService } from '../../../services/voiceChatService';
import { Model, userHasPermission } from '../../../services/model';

type UserType = User | (UserDataLive & { inVoiceCall?: boolean; muted?: boolean; });

@Component({
  selector: 'user-button-mini',
  templateUrl: 'user-button-mini.pug',
  styleUrls: [
    '../user-button/user-button-shared.scss',
    'user-button-mini.scss',
  ],
})
export class UserButtonMini implements AfterViewInit, OnDestroy {
  readonly emptyIcon = emptyIcon;
  readonly checkIcon = faCheck;
  readonly speakingIcon = faPhone;
  readonly mutedIcon = faMicrophoneSlash;
  @Input() user!: UserType;
  @Input() voice = false;
  @Input() hasTooltip = true;
  @ViewChild('box', { static: true }) box!: ElementRef<HTMLDivElement>;
  constructor(private model: Model, private voiceChat: VoiceChatService) {
  }
  get roleIcon() {
    return getRoleIcon(this.user);
  }
  get roleTitle() {
    return getRoleTitle(this.user);
  }
  get inVoiceCall() {
    return this.voice && this.voiceChat.joined.has(this.user.uniqId);
  }
  get mutedText() {
    if (this.voiceChat.isUserMuted(this.user)) return 'muted by you';
    if (this.voiceChat.mutedByAdmin.has(this.user.uniqId)) return 'muted by admin';
    if (this.voiceChat.muted.has(this.user.uniqId)) return 'muted';
    if (!userHasPermission(this.model, this.user as User, 'voiceTalk')) return 'muted';
    return undefined;
  }
  ngAfterViewInit() {
    if (this.voice) {
      this.voiceChat.addUserElement(this.user.uniqId, this.box.nativeElement);
    }
  }
  ngOnDestroy() {
    if (this.voice) {
      this.voiceChat.removeUserElement(this.user.uniqId, this.box.nativeElement);
    }
  }
}
