import { Component, ElementRef, Input, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { keyboarLayoutChanged, updateShortcut } from '../../../common/input';

@Component({
  selector: 'kbd-short',
  template: '<span></span>',
  styles: [':host { white-space: nowrap; }'],
  host: {
    '[class.highlight]': 'highlight',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KbdShortcut implements OnDestroy {
  @Input() highlight = false;
  private _shortcut: string | undefined = undefined;
  private subscription: Subscription;
  constructor(private element: ElementRef<HTMLElement>) {
    this.subscription = keyboarLayoutChanged.subscribe(() => this.update());
  }
  @Input()
  set val(value: string | undefined) {
    if (this._shortcut !== value) {
      this._shortcut = value;
      this.update();
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  update() {
    updateShortcut(this.element.nativeElement, this._shortcut);
  }
}
