import type { DisabledOnMobileModalData } from '../components/shared/modals/disabled-on-mobile-modal/disabled-on-mobile-modal';
import { canDrawOnActiveLayer, canEditLayer, Editor, isFilterActive } from './editor';
import { copyViewport, createViewport } from '../common/viewport';
import { ITool, ToolId, ToolSource } from '../common/interfaces';
import { toolIdToString } from '../common/toolIdUtils';
import { asOpaque, getAlpha } from '../common/color';
import { isMaskEmpty } from '../common/mask';
import { canvasToBlob, createCanvas, getContext2d } from '../common/canvasUtils';
import { getTransformedSelection } from '../common/user';
import { scheduleSaveSettings } from './settingsService';
import { TransformTo } from '../common/tools/transformTool';
import { rotateCanvasToView } from './copyPasteActions';
import { setEditorColor } from './editorUtils';
import { isViewTool } from '../common/update';
import { isMobile } from '../common/utils';
import { logAction } from '../common/actionLog';

export function pickColorAt(editor: Editor, x: number, y: number, activeLayer: boolean, secondary: boolean, alphaTo: string) {
  const color = editor.renderer.pickColor(editor.drawing, editor.activeLayer, x, y, activeLayer);

  if (color) {
    editor.apply(() => {
      setEditorColor(editor, asOpaque(color), !secondary);

      // TODO: this actually doesn't work at all, since our selected tool is eyedropper at this moment
      const tool: any = editor.selectedTool;

      if (alphaTo && tool[alphaTo] !== undefined) {
        tool[alphaTo] = getAlpha(color) / 0xff;
      }
    });
  }
}

export function moveBy(editor: Editor, dx: number, dy: number) {
  if (canDrawOnActiveLayer(editor) && !editor.drawingInProgress) {
    logAction(`[local] moveBy (${dx}, ${dy})`);
    copyViewport(editor.moveTool.view!, createViewport());
    editor.moveTool.start!(0, 0, 0);
    editor.moveTool.end!(dx, dy, 0);
  }
}

export function selectTool(editor: Editor, tool: ITool | undefined, source: ToolSource) {
  if (editor.drawingInProgress && !(isFilterActive(editor) && tool?.id && isViewTool(tool.id))) return;
  const isStaging = window.location.host === 'dev.magma.com'; // TODO: remove later
  if ((isMobile && !isStaging) && tool?.id === ToolId.Text) {
    launchDisabledOnMobileModal(editor, {
      disabledThingName: editor.textTool.name,
      description: `Magma’s new Text Tool functionality is currently in beta stage and is only available on desktop devices. We will notify you as soon as it becomes available on mobile devices.`,
      badge: 'beta',
      video: 'assets/videos/text.mp4',
    });
    return;
  }

  editor.selectedTool = tool;
  editor.selectedTool?.onSelect?.();
  editor.toolSource = source;

  if (editor.model.activeSlot && tool) {
    editor.model.activeSlot.activeTool = toolIdToString(tool.id);
  }

  scheduleSaveSettings(editor.model);
}

export async function createAvatarFromSelection(editor: Editor) {
  const selection = getTransformedSelection(editor.model.user);
  if (isMaskEmpty(selection)) return false;

  let canvas = editor.renderer.getDrawingSnapshot(editor.drawing, selection);
  if (!canvas || !canvas.width || !canvas.height) return false;

  canvas = rotateCanvasToView(canvas, editor.view);

  const size = Math.max(canvas.width, canvas.height);

  let result = createCanvas(size, size);
  const context = getContext2d(result);

  if (editor.drawing.background) {
    context.fillStyle = editor.drawing.background;
    context.fillRect(0, 0, size, size);
  }

  context.drawImage(canvas, (size - canvas.width) / 2, (size - canvas.height) / 2);

  while (result.width >= 512) {
    const halfSize = Math.floor(result.width / 2);
    const halfCanvas = createCanvas(halfSize, halfSize);
    getContext2d(halfCanvas).drawImage(result, 0, 0, result.width, result.height, 0, 0, halfSize, halfSize);
    result = halfCanvas;
  }

  const blob = await canvasToBlob(result);
  if (!blob) return false;

  await editor.model.changeAvatar(blob);
  return true;
}

export function transformRotate(editor: Editor, angle: number) {
  if (canEditLayer(editor, editor.activeLayer)) {
    editor.transformTool.rotate(angle);
  }
}

export function transformScale(editor: Editor, x: number, y: number) {
  if (canEditLayer(editor, editor.activeLayer)) {
    editor.transformTool.scale(x, y);
  }
}

export function transformFit(editor: Editor) {
  if (canEditLayer(editor, editor.activeLayer)) {
    editor.transformTool.transformTo(TransformTo.Fit);
  }
}

export function transformCover(editor: Editor) {
  if (canEditLayer(editor, editor.activeLayer)) {
    editor.transformTool.transformTo(TransformTo.Cover);
  }
}

export function transformFull(editor: Editor) {
  if (canEditLayer(editor, editor.activeLayer)) {
    editor.transformTool.transformTo(TransformTo.Full);
  }
}

export function launchDisabledOnMobileModal(editor: Editor, data: DisabledOnMobileModalData) {
  editor.model.modals.disabledOnMobile(data).catch((e) => {
    DEVELOPMENT && console.error(e);
    if (editor.model.modals.isOpen('disabledOnMobile')) {
      editor.model.modals.closeByName('disabledOnMobile');
    }
  });
}
