import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ShareResponse } from 'shared/responses';
import { Collaborator, FlowchartData, SearchRequest, SearchResponse } from 'shared/interfaces';
import { memoize$ } from 'shared/decorators';
import { AppStore } from './app.store';
import { UserService } from './user.service';

export interface ShareServiceLinks {
  shareReadOnlyLink: string;
  shareLink: string | null;
}

@Injectable()
export class AppService {
  constructor(
    private httpClient: HttpClient,
    private appStore: AppStore,
    private userService: UserService,
  ) {
  }

  membersToNotify$ = new BehaviorSubject<string[]>([]);

  static getRelativePath(absolutePath: string) {
    return new URL(absolutePath).pathname;
  }

  static getPrivateId(absolutePath: string) {
    return AppService.getRelativePath(absolutePath).slice(1);
  }

  save(flowchart: FlowchartData) {
    return this.httpClient.post<ShareResponse>('/save', flowchart);
  }

  static generateLinks(shortId: string, privateId: string | null | false | undefined) {
    return {
      shareReadOnlyLink: `${location.origin}/${shortId}`,
      shareLink: privateId ? `${location.origin}/${privateId}` : null,
    };
  }

  generateLinks(shortId: string, privateId: string | null | false | undefined) {
    return AppService.generateLinks(shortId, privateId);
  }

  uncachedShareApiCall(flowchart: FlowchartData) {
    const { name, input: text, diagramAppearanceOptions: options } = flowchart;
    return this.httpClient.post<ShareResponse>('/share', {
      type: 'Flowchart',
      name,
      text,
      options,
    });
  }

  // prevent same flowchart from generating a different set of links
  @memoize$({
    normalizer: ([{ name, text, options }]) => {
      return JSON.stringify({ name, text, options });
    }, max: 100
  })
  shareApiCall(flowchart: FlowchartData) {
    return this.uncachedShareApiCall(flowchart);
  }

  share(flowchart: FlowchartData) {
    return this.shareApiCall(flowchart)
      .pipe(tap(links => {
        this.appStore.update(state => {
          return {
            ...state,
            flowchart: {
              ...flowchart,
              ...links,
            },
          };
        });
      }));
  }

  makeCopyOfName(name: string) {
    if (!name) { return ''; }
    let str = `Clone of ${name}`;
    const m = name.match(/^(?:Clone of )(.+?)(?: \((\d+)\))?$/);
    if (m) {
      str = `Clone of ${m[1]}`;
      str += ` (${parseInt(m[2] || '0', 10) + 1})`;
    }
    return str;
  }

  clone({ name, input: text, diagramAppearanceOptions: options }: FlowchartData): Observable<string> {
    if (name) {
      name = this.makeCopyOfName(name);
    }
    return this.httpClient.post<ShareResponse>('/share', { name, text, options })
      .pipe(map((data) => {
        const links = this.generateLinks(data.shortId, data.privateId);

        if (links.shareLink === null) {
          return '/';
        }
        return AppService.getRelativePath(links.shareLink);
      }));
  }

  removeCollaborator(flowchart: FlowchartData, collaborator: Collaborator) {
    return this.httpClient.delete<any>(`/api/entities/${flowchart._id}/collaborator/${collaborator._id}`);
  }

  // TODO: remove
  updatePermissions(flowchart: FlowchartData) {
    return new Observable();
  }

  search(payload: SearchRequest) {
    let params = new HttpParams();
    const validEntries = Object.entries(payload).filter(entry => !!entry[1]);
    for (const entry of validEntries) {
      params = params.append(entry[0], entry[1]);
    }
    return this.httpClient.get<SearchResponse>(`/api/search`, { params });
  }

  async onContactSupport() {
    await this.userService.get().toPromise();
    window.Intercom?.('showNewMessage');
    if (window.Tawk_API) {
      window.Tawk_API.showWidget();
    }
  }
}
