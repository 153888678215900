import { IFiltersValues, IToolEditor, IToolModel, ToolId } from '../interfaces';
import { safeFloat } from '../toolUtils';
import { BaseFilterTool, IFilterData } from './baseFilterTool';

export class CurvesTool extends BaseFilterTool {
  id = ToolId.Curves;
  name = 'Curves';
  constructor(editor: IToolEditor, model: IToolModel) {
    super(editor, model, 'curves');
  }
  protected applyFilter() {
    if (!this.layer) throw new Error(`[${this.toolName}] Missing layer`);
    this.editor.renderer.applyCurvesFilter(this.srcData, this.model.user.surface, this.values);
  }
  protected parseData(data?: IFilterData): IFiltersValues {
    const curvePoints = data?.curvePoints || [];
    for (const curve of curvePoints) {
      if (!curve || typeof curve !== 'object') {
        curvePoints.length = 0;
        break;
      }
      if (!Array.isArray(curve.points)) {
        curve.points = [];
      }
      for (const point of curve.points) {
        if (!point || typeof point !== 'object') {
          curvePoints.length = 0;
          break;
        }
        point.x = safeFloat(point.x, 0, 1);
        point.y = safeFloat(point.y, 0, 1);
      }
    }
    return { curvePoints };
  }
}
