import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { pushOpenedBy, urlToDrawingId } from '../../../common/clientUtils';

@Directive({ selector: '[openedBy]' })
export class OpenedBy {
  @Input() openedBy = '';
  constructor(private element: ElementRef<HTMLAnchorElement>) {
  }
  @HostListener('click')
  click() {
    const shortId = urlToDrawingId(this.element.nativeElement.href);
    if (shortId) pushOpenedBy(shortId, this.openedBy);
  }
}
