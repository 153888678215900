import { Component, EventEmitter, Output } from '@angular/core';
import { Model } from '../../../../services/model';

@Component({
  selector: 'drawing-deleted',
  templateUrl: './drawing-deleted.pug',
})
export class DrawingDeleted {
  @Output() close = new EventEmitter<void>();
  team: string | undefined = undefined;
  project: string | undefined = undefined;
  folder: string | undefined = undefined;

  constructor(private model: Model) {
    if (this.model.editor?.drawing) {
      Object.assign(this, model.allowedFolderProjectTeam(this.model.editor?.drawing));
    }
  }

  closeModal() {
    this.close.emit();
  }
}
