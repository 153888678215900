import { Component, EventEmitter, Output } from '@angular/core';
import { ManageService } from '../../../../services/manageService';
import { infoIcon } from '../../../../common/icons';


@Component({
  selector: 'ai-quota-reached-modal',
  templateUrl: 'ai-quota-reached-modal.pug',
  styleUrls: ['./ai-quota-reached-modal.scss'],
})
export class AiQuotaReachedModalComponent {
  @Output() close = new EventEmitter<boolean | undefined>();

  readonly infoIcon = infoIcon;

  constructor(private manageService: ManageService) { }

  async contactUs() {
    await this.manageService.contactUs();
  }

  get aiQuotaReset() {
    return 60 - new Date().getMinutes();
  }

  onClose() {
    this.close.emit(undefined);
  }
}
