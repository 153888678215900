import type { Types } from 'mongoose';
import { getEntityPassword } from 'magma/common/clientUtils';
import { EntityType, EntityData } from './interfaces';
import { pathToProject } from './utils';

// type is messy because it's used both on client and server
export interface EntityDataForPath {
  type: EntityType;
  shortId?: string;
  _id?: string | Types.ObjectId;
  team?: string | Types.ObjectId;
  project?: string | null | Types.ObjectId | { _id?: string; };
}

export function getDefaultName(type: string) {
  return PRODUCT_INFO.entities[type]?.defaultName || 'Untitled';
}

export function getPathForEntity(type: string) {
  return PRODUCT_INFO.entities[type]?.path || 'unsupported';
}

// for folders "getQueryParamsForFolder" needs to be used for a full URL or use getEntityUrl
export function getEntityPath(entity: EntityDataForPath, team?: { slug: string }) {
  if (entity.type === EntityType.Folder) {
    // folder ID is added using getQueryParamsForFolder

    // hacky conversion because typesafe version is way too complicated
    const anyProject = entity.project as any;
    const project: string | undefined = (anyProject?._id ?? anyProject)?.toString();
    
    if (DEVELOPMENT && entity.team && !team?.slug) {
      console.warn('Getting entity path for folder without team slug', entity);
    }

    if (team && project) {
      return `${pathToProject(team.slug, project)}`;
    } else {
      return `/my/${PRODUCT_INFO.home?.route}`;
    }
  }

  return `/${getPathForEntity(entity.type)}/${entity.shortId}`;
}

export function getEntityUrl(entity: EntityDataForPath, team?: { slug: string }) {
  const query: any = getQueryParamsForFolder(entity);
  const queryString = Object.keys(query).map(key => `key=${encodeURIComponent(query[key])}`).join('&');
  const path = getEntityPath(entity, team);
  return `${path}${queryString ? `?${queryString}` : ``}`;
}

export function getEntityAbsoluteUrlWithPassword(entity: EntityData) {
  if (entity.type === EntityType.Folder) {
    throw new Error('getEntityAbsoluteUrlWithPassword: Folders are not supported');
  }
  let query = '';
  if (entity.hasPassword) {
    const password = entity.password ?? getEntityPassword(entity.shortId);
    if (password) {
      query += `?pass=${password}`;
    }
  }
  return `${window.location.origin}${getEntityUrl(entity)}${query}`;
}

export function getQueryParamsForFolder(entity: { type: EntityType; _id?: any; }) {
  if (entity.type === EntityType.Folder) {
    return { folder: entity._id };
  }
  return {};
}
