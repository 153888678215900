import { decodeCoord, encodeCoord } from './compressor';
import { decodeString, encodeStringTo, stringLengthInBytes } from './utf8';

// Reader

export interface BinaryReader {
  buffer: Uint8Array;
  offset: number;
}

export function createReader(buffer: Uint8Array, offset = 0): BinaryReader {
  return { buffer, offset };
}

export function readUint8(reader: BinaryReader) {
  if (DEVELOPMENT && reader.offset >= reader.buffer.byteLength) {
    throw new Error(`Reading past buffer end`);
  }

  const value = reader.buffer[reader.offset];
  reader.offset++;
  return value;
}

export function readUint16(reader: BinaryReader) {
  if (DEVELOPMENT && (reader.offset + 1) >= reader.buffer.byteLength) {
    throw new Error(`Reading past buffer end`);
  }

  const value = reader.buffer[reader.offset] |
    (reader.buffer[reader.offset + 1] << 8);
  reader.offset += 2;
  return value;
}

export function readUint32(reader: BinaryReader) {
  if (DEVELOPMENT && (reader.offset + 3) >= reader.buffer.byteLength) {
    throw new Error(`Reading past buffer end`);
  }

  const value = (reader.buffer[reader.offset] |
    (reader.buffer[reader.offset + 1] << 8) |
    (reader.buffer[reader.offset + 2] << 16) |
    (reader.buffer[reader.offset + 3] << 24)) >>> 0;
  reader.offset += 4;
  return value;
}

export function readString(reader: BinaryReader) {
  const length = readUint16(reader);
  const offset = reader.offset;
  reader.offset += length;
  return decodeString(reader.buffer, offset, length);
}

// Writer

export interface BinaryWriter {
  buffer: Uint8Array;
  offset: number;
  capacity: number;
}

export function createWriter(capacity = 16): BinaryWriter {
  return {
    buffer: new Uint8Array(capacity),
    offset: 0,
    capacity,
  };
}

export function getWriterBuffer(writer: BinaryWriter) {
  return writer.buffer.subarray(0, writer.offset);
}

function ensureCapacity(writer: BinaryWriter, space: number) {
  if (writer.capacity < (writer.offset + space)) {
    const buffer = new Uint8Array(writer.capacity * 2);
    buffer.set(writer.buffer);
    writer.buffer = buffer;
    writer.capacity = buffer.length;
  }
}

export function writeUint8At(writer: BinaryWriter, value: number, offset: number) {
  writer.buffer[offset] = value;
}

export function writeUint8(writer: BinaryWriter, value: number) {
  ensureCapacity(writer, 1);
  writer.buffer[writer.offset] = value;
  writer.offset++;
}

export function writeUint16(writer: BinaryWriter, value: number) {
  ensureCapacity(writer, 2);
  writer.buffer[writer.offset] = value & 0xff;
  writer.buffer[writer.offset + 1] = (value >> 8) & 0xff;
  writer.offset += 2;
}

export function writeUint32(writer: BinaryWriter, value: number) {
  ensureCapacity(writer, 4);
  writer.buffer[writer.offset] = value & 0xff;
  writer.buffer[writer.offset + 1] = (value >> 8) & 0xff;
  writer.buffer[writer.offset + 2] = (value >> 16) & 0xff;
  writer.buffer[writer.offset + 3] = (value >> 24) & 0xff;
  writer.offset += 4;
}

export function writeString(writer: BinaryWriter, value: string) {
  const bytes = stringLengthInBytes(value);
  ensureCapacity(writer, 2 + bytes);
  writeUint16(writer, bytes);
  encodeStringTo(writer.buffer, writer.offset, value);
  writer.offset += bytes;
}

// moves

export function writeMovesEntry(buffer: Uint8Array, offset: number, x: number, y: number, p: number) {
  const view = new DataView(buffer.buffer, buffer.byteOffset, buffer.byteLength);
  view.setInt32(offset, encodeCoord(x), true);
  view.setInt32(offset + 4, encodeCoord(y), true);
  view.setUint16(offset + 8, p, true);
}

export function readMovesEntry(view: DataView, offset: number) {
  const x = decodeCoord(view.getInt32(offset + 0, true));
  const y = decodeCoord(view.getInt32(offset + 4, true));
  const p = view.getUint16(offset + 8, true);
  return { x, y, p };
}
