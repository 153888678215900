<ng-template #userTooltip>
  <div class="user-name ellipsis">{{user?.name}}<span class="text-muted" *ngIf="isAnonymous">#{{anonymousNumber}}</span></div>
  <div *ngIf="isAnonymous" class="text-muted">anonymous user</div>
</ng-template>

<img
  [src]="avatarSrc"
  [style.height.px]="size"
  [style.width.px]="size"
  [style.border-radius.px]="round ? 50 : cornerRadius"
  [style.border-width.px]="borderWidth"
  [style.border-color]="borderColor"
  style="display: block; background-color: #ddd;"
  [tooltip]="user ? userTooltip : undefined"
  boundariesElement="window"
  draggable="false"
  loading="lazy"
/>
