import { Mat2d } from './interfaces';

export function createMat2d(): Mat2d {
  const out = new Float32Array(6);
  out[0] = 1;
  out[3] = 1;
  return out;
}

export function setMat2d(out: Mat2d, a: number, b: number, c: number, d: number, tx: number, ty: number) {
  out[0] = a;
  out[1] = b;
  out[2] = c;
  out[3] = d;
  out[4] = tx;
  out[5] = ty;
  return out;
}

export function copyMat2d(out: Mat2d | number[], a: Mat2d | number[]) {
  out[0] = a[0];
  out[1] = a[1];
  out[2] = a[2];
  out[3] = a[3];
  out[4] = a[4];
  out[5] = a[5];
  return out;
}

export function identityMat2d(out: Mat2d) {
  out[0] = 1;
  out[1] = 0;
  out[2] = 0;
  out[3] = 1;
  out[4] = 0;
  out[5] = 0;
  return out;
}

export function translateMat2d(out: Mat2d, a: Mat2d, x: number, y: number) {
  const a0 = a[0], a1 = a[1], a2 = a[2], a3 = a[3], a4 = a[4], a5 = a[5];
  out[0] = a0;
  out[1] = a1;
  out[2] = a2;
  out[3] = a3;
  out[4] = a0 * x + a2 * y + a4;
  out[5] = a1 * x + a3 * y + a5;
  return out;
}

export function scaleMat2d(out: Mat2d, a: Mat2d, x: number, y: number) {
  const a0 = a[0], a1 = a[1], a2 = a[2], a3 = a[3], a4 = a[4], a5 = a[5];
  out[0] = a0 * x;
  out[1] = a1 * x;
  out[2] = a2 * y;
  out[3] = a3 * y;
  out[4] = a4;
  out[5] = a5;
  return out;
}

export function rotateMat2d(out: Mat2d, a: Mat2d, rad: number) {
  const a0 = a[0], a1 = a[1], a2 = a[2], a3 = a[3], a4 = a[4], a5 = a[5];
  const s = Math.sin(rad);
  const c = Math.cos(rad);
  out[0] = a0 * c + a2 * s;
  out[1] = a1 * c + a3 * s;
  out[2] = a0 * -s + a2 * c;
  out[3] = a1 * -s + a3 * c;
  out[4] = a4;
  out[5] = a5;
  return out;
}

export function multiplyMat2d(out: Mat2d, a: Mat2d, b: Mat2d) {
  const a0 = a[0], a1 = a[1], a2 = a[2], a3 = a[3], a4 = a[4], a5 = a[5];
  const b0 = b[0], b1 = b[1], b2 = b[2], b3 = b[3], b4 = b[4], b5 = b[5];
  out[0] = a0 * b0 + a2 * b1;
  out[1] = a1 * b0 + a3 * b1;
  out[2] = a0 * b2 + a2 * b3;
  out[3] = a1 * b2 + a3 * b3;
  out[4] = a0 * b4 + a2 * b5 + a4;
  out[5] = a1 * b4 + a3 * b5 + a5;
  return out;
}

export function invertMat2d(out: Mat2d, a: Mat2d) {
  const aa = a[0], ab = a[1], ac = a[2], ad = a[3];
  const atx = a[4], aty = a[5];
  let det = aa * ad - ab * ac;

  if (!det) return null;

  det = 1.0 / det;

  out[0] = ad * det;
  out[1] = -ab * det;
  out[2] = -ac * det;
  out[3] = aa * det;
  out[4] = (ac * aty - ad * atx) * det;
  out[5] = (ab * atx - aa * aty) * det;
  return out;
}

export function isMat2dIdentity(m: Mat2d) {
  return m[0] === 1 && m[1] === 0 && m[2] === 0 && m[3] === 1 && m[4] === 0 && m[5] === 0;
}

export function isMat2dTranslation(m: Mat2d) {
  return m[0] === 1 && m[1] === 0 && m[2] === 0 && m[3] === 1;
}

export function isMat2dIntegerTranslation(m: Mat2d) {
  return m[0] === 1 && m[1] === 0 && m[2] === 0 && m[3] === 1 && ((m[4] | 0) === m[4]) && ((m[5] | 0) === m[5]);
}

export function getMat2dX(m: Mat2d) {
  return m[4];
}

export function getMat2dY(m: Mat2d) {
  return m[5];
}

export function decomposeMat2d(mat2d: Mat2d) {
  const a = mat2d[0], b = mat2d[1], c = mat2d[2], d = mat2d[3], e = mat2d[4], f = mat2d[5];
  const delta = a * d - b * c;
  const result = {
    translateX: e,
    translateY: f,
    rotate: 0,
    scaleX: 0,
    scaleY: 0,
  };

  if (a || b) {
    const r = Math.sqrt(a * a + b * b);
    result.rotate = b > 0 ? Math.acos(a / r) : -Math.acos(a / r);
    result.scaleX = r;
    result.scaleY = delta / r;
    // result.skew = { Math.atan((a * c + b * d) / (r * r)), 0 };
  } else if (c || d) {
    const s = Math.sqrt(c * c + d * d);
    result.rotate = Math.PI / 2 - (d > 0 ? Math.acos(-c / s) : -Math.acos(c / s));
    result.scaleX = delta /s;
    result.scaleY = s;
    // result.skew = { 0, atanf((a * c + b * d) / (s * s)) };
  } else {
    // a = b = c = d = 0;
  }

  return result;
}
