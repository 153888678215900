import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { RecentEntity } from 'shared/interfaces';

export interface RecentEntitiesState extends EntityState<RecentEntity, string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'recentEntities', idKey: '_id',  deepFreezeFn: noReadOnly, resettable: true })
export class RecentEntitiesStore extends EntityStore<RecentEntitiesState> {
  constructor() {
    super();
  }
}

function noReadOnly(state: RecentEntity) {
  return state;
}
