import { Injectable } from '@angular/core';
import { QueryConfig, QueryEntity } from '@datorama/akita';
import { RecentEntity } from 'shared/interfaces';
import { RecentEntitiesState, RecentEntitiesStore } from './recent-entities.store';

const sortBy = (recentEntity1: RecentEntity, recentEntity2: RecentEntity) => {
  const date1 = new Date(recentEntity1.updatedAt as string);
  const date2 = new Date(recentEntity2.updatedAt as string);

  return (+recentEntity2.isLive - +recentEntity1.isLive) || (date2.getTime() - date1.getTime());
};

@Injectable({ providedIn: 'root' })
@QueryConfig({ sortBy })
export class RecentEntitiesQuery extends QueryEntity<RecentEntitiesState, RecentEntity> {
  constructor(protected store: RecentEntitiesStore) {
    super(store);
  }
}
