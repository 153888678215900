import { UserData } from 'shared/interfaces';
import { UserStore } from './user.store';
import { Query } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { EntitiesQuery } from './entities.query';
import { combineLatest } from 'rxjs';
import { FREE_USER_FLOWCHART_LIMIT, UserPlans } from 'shared/constants';

export interface UserState {
  user?: UserData;
}

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<UserState> {
  // TODO: remove all of these fields, they're only used right now in some code2flow components
  user$ = this.select(state => state.user).pipe(filter((user): user is UserData => !!user));
  userPlan$ = this.select(state => state.user && state.user?.plan);
  isFreePlan$ = this.userPlan$.pipe(map(plan => plan === UserPlans.FREE));
  isProPlan$ = this.userPlan$.pipe(map(plan => plan === UserPlans.PRO));
  isFreeUserOverFlowchartLimit$ = combineLatest([this.isFreePlan$, this.flowchartQuery.selectCount()]).pipe(
    map(([isFree, flowchartQuantity]) => isFree && flowchartQuantity >= FREE_USER_FLOWCHART_LIMIT),
  );

  constructor(protected store: UserStore, protected flowchartQuery: EntitiesQuery) {
    super(store);
  }
}
