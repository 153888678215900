export function clamp(value: number, min: number, max: number): number {
  return value < max ? (value > min ? value : min) : max;
}

export function arrayIndexSafe(value: number, array: any[]): number {
  return clamp(value, 0, array.length - 1);
}

export function rangeMap(a1: number, a2: number, b1: number, b2: number, s: number) {
  const slope = (b2 - b1) / (a2 - a1);
  // Scaling up an order, and then down, to bypass a potential,
  // precision issue with negative numbers.
  return ((slope * (s - a1) * 10) + (10 * b1)) / 10;
}

export function round5(value: number): number {
  return Math.round(value + 0.5) - 0.5;
}

export function deg2rad(deg: number): number {
  return deg * 0.017453292519943295;
}

export function rad2deg(rad: number): number {
  return rad * 57.29577951308232;
}

export function distance(x1: number, y1: number, x2: number, y2: number): number {
  const dx = x1 - x2;
  const dy = y1 - y2;
  return Math.sqrt(dx * dx + dy * dy);
}

export function distancePointToRect(x: number, y: number, rx: number, ry: number, rw: number, rh: number) {
  const rr = rx + rw;
  const rb = ry + rh;
  const dx = (x < rx) ? rx - x : ((x > rr) ? x - rr : 0);
  const dy = (y < ry) ? ry - y : ((y > rb) ? y - rb : 0);
  return Math.sqrt(dx * dx + dy * dy);
}

export function sum(array: number[]) {
  let result = 0;
  for (let i = 0; i < array.length; i++) {
    result += array[i];
  }
  return result;
}

export function lerp(a: number, b: number, factor: number): number {
  return a * (1 - factor) + b * factor;
}

export function generateNumbersArray (start: number, end: number): number[] {
  const array = [];
  for (let i = start; i <= end; i++) {
    array.push(i);
  }
  return array;
}

export function max(array: number[]): number {
  let max = array[0];
  for (const entry of array) {
    if (entry > max) max = entry;
  }
  return max;
}

export function min(array: number[]): number {
  let min = array[0];
  for (const entry of array) {
    if (entry < min) min = entry;
  }
  return min;
}

export function isEven(n: number): boolean {
  return !(n % 2);
}

export function isOdd(n: number): boolean {
  return !!(n % 2);
}
