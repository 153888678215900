import { Component, ChangeDetectionStrategy, Output, Input, EventEmitter } from '@angular/core';

let id = 1;

@Component({
  selector: 'custom-switch',
  templateUrl: 'custom-switch.pug',
  styleUrls: ['custom-switch.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomSwitch {
  @Input() disabled = false;
  @Input() checked = false;
  @Output() checkedChange = new EventEmitter<boolean>();
  id = `custom-switch-${++id}`;
}
