import {
  BinaryReader, BinaryWriter, writeUint8, writeUint16, writeUint8At, writeUint32, readUint32,
  createWriter, getWriterBuffer, createReader
} from './binary';
import { createCanvas, getContext2d } from './canvasUtils';
import { Rect, CreateImageData } from './interfaces';
import { MAX_IMAGE_WIDTH, MAX_IMAGE_HEIGHT } from './constants';
import { colorToRGBA } from './color';

export function compressImageDataRLE(data: ImageData, skipAlpha = false) {
  const writer = createWriter(1024);
  writeUint32(writer, data.width);
  writeUint32(writer, data.height);
  compressRLE(writer, data.data, 0, 4);
  compressRLE(writer, data.data, 1, 4);
  compressRLE(writer, data.data, 2, 4);
  if (!skipAlpha) compressRLE(writer, data.data, 3, 4);
  return getWriterBuffer(writer);
}

export function decompressImageDataRLE(buffer: Uint8Array, createImageData: CreateImageData, skipAlpha = false): ImageData {
  const reader = createReader(buffer);
  const width = readUint32(reader);
  const height = readUint32(reader);

  if (width === 0 || height === 0 || width > MAX_IMAGE_WIDTH || height > MAX_IMAGE_HEIGHT)
    throw new Error(`Invalid RLE size ${width}x${height}`);

  const data = createImageData(width, height, undefined);
  data.data.fill(255, 0, data.data.byteLength);
  decompressRLE(reader, data.data, 0, 4);
  decompressRLE(reader, data.data, 1, 4);
  decompressRLE(reader, data.data, 2, 4);

  if (!skipAlpha) decompressRLE(reader, data.data, 3, 4);

  return data;
}

export function compressImageRLE(writer: BinaryWriter, canvas: HTMLCanvasElement) {
  const context = getContext2d(canvas);
  const data = context.getImageData(0, 0, canvas.width, canvas.height);
  writeUint32(writer, data.width);
  writeUint32(writer, data.height);
  compressRLE(writer, data.data, 0, 4);
  compressRLE(writer, data.data, 1, 4);
  compressRLE(writer, data.data, 2, 4);
  compressRLE(writer, data.data, 3, 4);
}

export function decompressImageRLE(reader: BinaryReader) {
  const width = readUint32(reader);
  const height = readUint32(reader);

  if (width < 1 || height < 1 || width > MAX_IMAGE_WIDTH || height > MAX_IMAGE_HEIGHT)
    throw new Error(`Invalid size ${width}x${height}`);

  const canvas = createCanvas(width, height);
  const context = getContext2d(canvas);
  const data = context.createImageData(width, height);
  decompressRLE(reader, data.data, 0, 4);
  decompressRLE(reader, data.data, 1, 4);
  decompressRLE(reader, data.data, 2, 4);
  decompressRLE(reader, data.data, 3, 4);
  context.putImageData(data, 0, 0);
  return canvas;
}

export function compressImageAlphaRLE(imageData: ImageData, rect: Rect) {
  const writer = createWriter(4096);
  compressImageAlpha(writer, imageData, rect);
  return getWriterBuffer(writer);
}

function compressImageAlpha(writer: BinaryWriter, imageData: ImageData, rect: Rect) {
  const { x, y, w, h } = rect;
  const { data, width } = imageData;

  if (w <= 0 || h <= 0 || width <= 0) {
    throw new Error(`Invalid data for compression (${w}, ${h}, ${width})`);
  }

  const temp = new Uint8Array(w * h);

  for (let iy = 0; iy < h; iy++) {
    const src = (((y + iy) * width) * 4 + 3) | 0;
    const dst = (iy * w) | 0;

    for (let ix = 0; ix < w; ix++) {
      temp[(dst + ix) | 0] = data[(src + (x + ix) * 4) | 0];
    }
  }

  compressRLE(writer, temp, 0, 1);
}

export function decompressImageAlphaRLE(
  data: Uint8Array, width: number, height: number, color: number | undefined, createImageData: CreateImageData
) {
  const reader = createReader(data);
  const imageData = createImageData(width, height, undefined);
  decompressRLE(reader, imageData.data, 3, 4);

  if (color !== undefined) {
    const { r, g, b } = colorToRGBA(color);
    const pixels = imageData.data;

    for (let i = 0; i < pixels.length; i = (i + 4) | 0) {
      pixels[(i) | 0] = r;
      pixels[(i + 1) | 0] = g;
      pixels[(i + 2) | 0] = b;
    }
  }

  return imageData;
}

export function compressRLE(writer: BinaryWriter, data: Uint8Array | Uint8ClampedArray, offset: number, step: number) {
  const lastIndex = data.length - step + offset;

  for (let i = offset; i < data.length; i += step) {
    const value = data[i];
    let count = 1;

    if (i === lastIndex) {
      writeUint8(writer, count + 127);
      writeUint8(writer, value);
    } else {
      i += step;

      if (value === data[i]) {
        while (i < data.length && count < 0xffffffff && data[i] === value) {
          i += step;
          count++;
        }

        i -= step;

        if (count <= 126) {
          writeUint8(writer, count - 2);
          writeUint8(writer, value);
        } else if (count <= 382) { // 0xff + 127
          writeUint8(writer, 0x7d);
          writeUint8(writer, count - 127);
          writeUint8(writer, value);
        } else if (count <= 65153) { // 0xffff + 382
          writeUint8(writer, 0x7e);
          writeUint16(writer, count - 382);
          writeUint8(writer, value);
        } else {
          writeUint8(writer, 0x7f);
          writeUint32(writer, count); // TODO: can do 0xffff more
          writeUint8(writer, value);
        }
      } else {
        let last = data[i];
        let last2 = last;
        let pushLast = true;
        const countIndex = writer.offset;
        count++;

        writeUint8(writer, 0);
        writeUint8(writer, value);

        for (i += step; i < data.length; i += step) {
          last2 = data[i];

          if (last2 === last) {
            i -= 2 * step;
            count--;
            pushLast = false;
            break;
          } else if (count === 128) {
            i -= step;
            break;
          } else {
            writeUint8(writer, last);
            count++;
            last = last2;
          }
        }

        writeUint8At(writer, count + 127, countIndex);

        if (pushLast) {
          writeUint8(writer, last);
        }
      }
    }
  }
}

export function decompressRLE(reader: BinaryReader, result: Uint8Array | Uint8ClampedArray, offset: number, step: number) {
  const length = result.length >>> 0;
  const buffer = reader.buffer;
  let off = reader.offset | 0;
  step = step >>> 0;

  for (let o = offset >>> 0; o < length;) {
    const value = buffer[off] >>> 0;
    off = (off + 1) | 0;

    if ((value & 0x80) === 0) {
      let count: number;

      if (value === 0x7d) {
        count = (buffer[off] + 127) >>> 0;
        off = (off + 1) | 0;
      } else if (value === 0x7e) {
        count = ((buffer[off] | (buffer[(off + 1) | 0] << 8)) + 382) >>> 0;
        off = (off + 2) | 0;
      } else if (value === 0x7f) {
        count = (buffer[off] | (buffer[(off + 1) | 0] << 8) | (buffer[(off + 2) | 0] << 16) | (buffer[(off + 3) | 0] << 24)) >>> 0;
        off = (off + 4) | 0;
      } else {
        count = (value + 2) >>> 0;
      }

      if (count === 0 || ((o + ((((count - 1) >>> 0) * step) >>> 0)) >>> 0) > length) throw new Error('Invalid RLE');

      const entry = buffer[off] >>> 0;
      off = (off + 1) | 0;

      for (let j = 0 >>> 0; j < count; j = (j + 1) >>> 0) {
        result[o] = entry;
        o = (o + step) >>> 0;
      }
    } else {
      const count = (value - 127) >>> 0;

      if (count === 0 || ((o + ((((count - 1) >>> 0) * step) >>> 0)) >>> 0) > length) throw new Error('Invalid RLE');

      for (let j = 0 >>> 0; j < count; j = (j + 1) >>> 0) {
        result[o] = buffer[off] >>> 0;
        off = (off + 1) | 0;
        o = (o + step) >>> 0;
      }
    }
  }

  if (off > (buffer.byteLength + 1)) throw new Error('Reading past buffer end');

  reader.offset = off;
}
