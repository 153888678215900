import { StoreConfig, EntityStore } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ProjectState } from './projects.query';
import { ProjectData } from 'shared/interfaces';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'project', resettable: true, idKey: '_id' })
export class ProjectStore extends EntityStore<ProjectState, ProjectData, string> {
  constructor() {
    super([]);
  }
}
