import type { NgZone } from '@angular/core';

export interface AutoClose {
  autoClose?: { success?: true, error?: string; };
}

export function handleAutoClose(popupWindow: Window, zone: NgZone) {
  return new Promise<void>((resolve, reject) => {
    zone.runOutsideAngular(() => {
      const messageHandler = (event: MessageEvent) => {
        if (event.source === popupWindow && event.data.autoClose) {
          popupWindow.close();
          done(event.data.autoClose.error);
        }
      };
      window.addEventListener('message', messageHandler);

      const interval = setInterval(() => {
        if (popupWindow.closed) {
          done('Cancelled');
        }
      }, 500);

      function done(error: string | undefined) {
        clearInterval(interval);
        window.removeEventListener('message', messageHandler);

        if (error) {
          zone.run(() => reject(new Error(error)));
        } else {
          zone.run(() => resolve());
        }
      }
    });
  });
}

export function openPopup(url: string, w: number, h: number) {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
  const elem = document.documentElement;
  const width = window.innerWidth ? window.innerWidth : elem.clientWidth ? elem.clientWidth : screen.width;
  const height = window.innerHeight ? window.innerHeight : elem.clientHeight ? elem.clientHeight : screen.height;
  const left = dualScreenLeft + (width - w) / 2;
  const top = dualScreenTop + (height - h) / 2;
  return window.open(url, '_blank', `scrollbars=yes,width=${w},height=${h},top=${top},left=${left}`);
}
