import { RpcService } from 'services/rpc.service';
import { Injectable } from '@angular/core';
import { IThreadService, ThreadsFilter } from 'magma/services/thread.service.interface';
import { NewThread, Thread, UpdateComment, NewComment, UpdateThread, Point, Comment, Analytics, ThreadStatus, Suggestion } from 'magma/common/interfaces';
import { Observable, BehaviorSubject, Subscription, defer, Subject } from 'rxjs';
import { ITrackService } from 'magma/services/track.service.interface';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { retryAfter } from 'shared/rxjs';

@Injectable()
export class PortalThreadService extends IThreadService {
  newThread$ = new BehaviorSubject<Point | undefined>(undefined);
  threadsVisibility = new BehaviorSubject(true); // TODO store in local storage initial value
  threadsFilter = new BehaviorSubject<ThreadsFilter>(ThreadsFilter.ALL); // TODO store in local storage initial value

  subjects = new Map<string, Observable<Thread[]>>();
  subscriptions = new Map<string, Subscription>();

  suggestionsBuffer = new Subject<Suggestion[]>();
  isFetchingSuggestions = false;
  suggestions: Suggestion[] | undefined;
  entityShortId: string | undefined;

  constructor(private rpc: RpcService, private track: ITrackService) {
    super();
  }

  private trackComments(threads: Thread[]) {
    this.track.event(Analytics.ThreadLoadComments, {
      numberOfThreads: threads.length,
      numberOfUnresolvedThreads: threads.reduce((acc, thread) => acc + thread.status === ThreadStatus.UNRESOLVED ? 1 : 0, 0),
      numberOfUnreadThreads: threads.reduce((acc, thread) => acc + (thread.wasRead ? 0 : 1), 0),
      commentsVisible: this.threadsVisibility.getValue(),
      commentsFilter: this.threadsFilter.getValue(),
    });
  }

  observeThreads(entityShortId: string): Observable<Thread[]> {
    this.entityShortId = entityShortId;
    this.suggestions = undefined;
    this.suggestionsBuffer = new Subject<Suggestion[]>();

    if (!this.subjects.has(entityShortId)) {
      this.subjects.set(entityShortId, defer(async () => this.rpc.threads.observeThreads(entityShortId)).pipe(
        switchMap(obs => obs),
        map((threads, i) => {
          if (i === 0) this.trackComments(threads);
          return threads;
        }),
        retryAfter(1000),
        shareReplay({ refCount: true, bufferSize: 1 }),
      ));
    }

    return this.subjects.get(entityShortId)!;
  }

  observeComments(threadShortId: string): Promise<Observable<Comment[]>> {
    return this.rpc.threads.observeComments(threadShortId);
  }

  async getThreads(entityId: string): Promise<Thread[]> {
    return this.rpc.threads.getThreads(entityId);
  }

  async createThread(newThread: NewThread): Promise<string> {
    return this.rpc.threads.createThread(newThread);
  }

  updateThread(threadShortId: string, updateThread: UpdateThread): Promise<void> {
    return this.rpc.threads.updateThread(threadShortId, updateThread);
  }

  deleteThread(threadShortId: string): Promise<void> {
    return this.rpc.threads.deleteThread(threadShortId);
  }

  markThreadAsRead(entityId: string, threadShortId: string): Promise<void> {
    return this.rpc.threads.markThreadAsRead(entityId, threadShortId);
  }

  async markAllThreadsAsRead(entityId: string): Promise<void> {
    this.track.event(Analytics.ThreadsMarkAllCommentsAsResolved, {});
    return this.rpc.threads.markAllThreadsAsRead(entityId);
  }

  createComment(threadShortId: string, newComment: NewComment): Promise<void> {
    return this.rpc.threads.createComment(threadShortId, newComment);
  }

  updateComment(threadShortId: string, commentShortId: string, updatedComment: UpdateComment): Promise<void> {
    return this.rpc.threads.updateComment(threadShortId, commentShortId, updatedComment);
  }

  deleteComment(threadShortId: string, commentShortId: string): Promise<void> {
    return this.rpc.threads.deleteComment(threadShortId, commentShortId);
  }

  toggleThreadsVisibility(): void {
    const newValue = !this.threadsVisibility.getValue();
    this.track.event(Analytics.ThreadsChangeViewFilter, { selectedOption: newValue ? 'on' : 'off' });
    this.threadsVisibility.next(newValue);
  }

  observeThreadsVisibility(): Observable<boolean> {
    return this.threadsVisibility.asObservable();
  }

  setThreadVisibility(visible: boolean) {
    if (visible !== this.threadsVisibility.getValue()) {
      this.threadsVisibility.next(visible);
    }
  }

  getThreadVisibility() {
    return this.threadsVisibility.getValue();
  }

  setThreadsFilter(filter: ThreadsFilter): void {
    if (filter !== this.threadsFilter.getValue()) {
      this.track.event(Analytics.ThreadsChangeViewFilter, { selectedOption: filter });
      this.threadsFilter.next(filter);
    }
  }

  observeThreadsFilter(): Observable<ThreadsFilter> {
    return this.threadsFilter.asObservable();
  }

  newThread(thread: Point): void {
    if (this.threadsVisibility.getValue() === false) {
      this.threadsVisibility.next(true);
      this.newThread$.next(thread);
    } else {
      this.newThread$.next(thread);
    }
  }

  onNewThread(): Observable<Point | undefined> {
    return this.newThread$.asObservable();
  }

  // avoid sending 10 requests when comment with 10 mentions is opened (CommentPipe will download suggestions when needed)
  bufferGetSuggestions(entityShortId: string) {
    if (!this.isFetchingSuggestions) {
      this.isFetchingSuggestions = true;
      this.rpc.threads.getMentionSuggestions(entityShortId).then(s => {
        this.suggestionsBuffer.next(s);
        this.suggestionsBuffer.complete();
        this.isFetchingSuggestions = false;
      }).catch(e => {
        DEVELOPMENT && console.error(e);
        this.isFetchingSuggestions = false;
        this.suggestionsBuffer.next([]);
        this.suggestionsBuffer.complete();
      });
    }
    return this.suggestionsBuffer.asObservable();
  }

  async getMentionSuggestions(): Promise<Suggestion[]> {
    if (!this.entityShortId) return [];
    if (this.suggestions === undefined) {
      this.suggestions = await this.bufferGetSuggestions(this.entityShortId).toPromise();
    }
    return this.suggestions || [];
  }
}
