import { voiceChatUrl } from '../common/data';
import { injectionTokenBase } from '../common/di';
import type { DrawingPermissions, UserRole } from '../common/interfaces';
import { hasPermission } from '../common/userRole';
import type { ClientActions } from './clientActions';
import type { RealModel } from './real-model';

export abstract class Model extends injectionTokenBase<RealModel>() { }

export function userHasPermission(model: Model, user: { role: UserRole; isSuperAdmin: boolean; }, key: keyof DrawingPermissions) {
  return hasPermission(model.editor!.drawing, user, key);
}

export function havePermission(model: Model, key: keyof DrawingPermissions) {
  return userHasPermission(model, model.user, key);
}

export function isActiveClientActions(model: Model, clientActions: ClientActions) {
  return model.connectionService.sockets[0] === clientActions.socket;
}

export function hasVoiceChat(model: Model) {
  if (!voiceChatUrl) return false;

  return IS_HOSTED || model.voiceChat.session || !!model.user.pro;
}
