import { Directive, Input, HostBinding } from '@angular/core';

let id = 1;

@Directive({ selector: '[labelFor]' })
export class LabelFor {
  @HostBinding() for?: string;
  @Input() set labelFor(value: HTMLElement | undefined) {
    this.for = value ? (value.id = value.id || `label-for-${++id}`) : undefined;
  }
}
